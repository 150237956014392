import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { EnhancedDisplayType } from '../../../components/enhanced-form/EnhancedDisplay';
import { IEntityInfoView } from '../../../components/widgets/entity-info/EntityInfoFields';
import EntityInfoWidget from '../../../components/widgets/entity-info/EntityInfoWidget';

export const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: 'none !important',
  },
  updateBtn: {
    margin: '15px auto 0',
  },
}));

interface IBusinessPartnerEntityInfoWidgetProps {
  data: Record<string, any>;
}

const BusinessPartnerEntityInfoWidget: React.FC<
  IBusinessPartnerEntityInfoWidgetProps
> = ({ data }) => {
  const entityView = useMemo(() => {
    const result: IEntityInfoView = {
      name: 'section1',
      title: 'Person Details',
      iconUrl: '',
      sections: [
        {
          name: 'businessPartnerDetailsSection1',
          title: '',
          properties: {
            name: {
              value: data?.name,
              multiline: false,
              name: 'name',
              title: 'Full Name',
              type: EnhancedDisplayType.RelativeLink,
              tooltip: false,
              url: '/salesforce/persons/' + data?.personId,
            },
            businessPartnerCode: {
              value: data?.businessPartnerCode,
              multiline: false,
              name: 'businessPartnerCode',
              title: 'Business Partner Code',
            },
            dateOfBirth: {
              value: data?.dateOfBirth,
              multiline: false,
              name: 'dateOfBirth',
              title: 'Date Of Birth',
            },
            email: {
              value: data?.email,
              multiline: false,
              name: 'email',
              title: 'Email',
            },
            phoneType: {
              value: data?.phoneType,
              multiline: false,
              name: 'phoneType',
              title: 'Phone Type',
            },
            phoneNumber: {
              value: data?.phoneNumber,
              multiline: false,
              name: 'phoneNumber',
              title: 'Phone Number',
            },
            taxable: {
              value: data?.taxable,
              multiline: false,
              name: 'taxable',
              title: 'Taxable',
            },
          },
        },
        {
          name: 'businessPartnerDetailsSection2',
          title: '',
          properties: {
            createdBy: {
              value: data?.createdBy,
              multiline: false,
              name: 'createdBy',
              title: 'Created By',
            },
            createdOn: {
              value: data?.createdOn,
              multiline: false,
              type: EnhancedDisplayType.Date,
              name: 'createdOn',
              title: 'Created On',
            },
            modifiedBy: {
              value: data?.modifiedBy,
              multiline: false,
              name: 'modifiedBy',
              title: 'Modified By',
            },
            modifiedOn: {
              value: data?.modifiedOn,
              multiline: false,
              type: EnhancedDisplayType.Date,
              name: 'modifiedOn',
              title: 'Modified On',
            },
          },
        },
      ],
      socialMedia: {
        facebook: '',
        linkedIn: '',
        twitter: '',
      },
    };

    return result;
  }, [data]);

  return (
    <EntityInfoWidget
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: '' }}
      progress={100}
      supportsClassification={true}
      supportsProgressBar={true}
      title={data.name}
      view={entityView}
    ></EntityInfoWidget>
  );
};

export default BusinessPartnerEntityInfoWidget;
