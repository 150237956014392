import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: Record<string, IGenericTableData> = {};

  data.Insurance.queries.listAgencies.items.forEach(
    (obj: IGenericTableData, i: number) => {
      list[i] = {
        insurance_Agency_Id: obj.insurance_Agency_Id,
        insurance_Agency_AgencyName: obj.insurance_Agency_AgencyName,
        insurance_Agency_AgencyStatus: capitalizeFirstLetter(
          obj.insurance_Agency_AgencyStatus
        ),
        insurance_Agency_AgencyType: obj.insurance_Agency_AgencyType?.Title,
        insurance_Agency_createdOn: obj.insurance_Agency_createdOn,
        insurance_Agency_modifiedOn: obj.insurance_Agency_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Insurance.queries.listAgencies?.paging?.pageSize,
    pageNumber: data.Insurance.queries.listAgencies?.paging?.pageNumber,
    totalCount: data.Insurance.queries.listAgencies?.paging?.totalCount,
  };
}
