import { gql } from '@apollo/client';

export function getRelatedCompanies() {
  return gql`
    query relatedCompaniesGet {
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getAccountLayersByCompany() {
  return gql`
    query GetChartOfAccountGroupLayers123ByCompany($SelectedCompany: String) {
      Accounting {
        queries {
          GetChartOfAccountGroupLayers123ByCompany(
            SelectedCompany: $SelectedCompany
          ) {
            accounting_ChartOfAccountsGroup_Id
            accounting_ChartOfAccountsGroup_AccountGroup
            accounting_ChartOfAccountsGroup_AccountGroupName
            layer2_Id
            layer2_AccountGroup
            layer2_AccountGroupName
            layer3_Id
            layer3_AccountGroup
            layer3_AccountGroupName
          }
        }
      }
    }
  `;
}

export function checkCodeExistsQuery() {
  return gql`
    query CheckIfCategoryCodeExists($Code: String!, $Company: String) {
      Accounting {
        queries {
          CheckIfCategoryCodeExists(Code: $Code, Company: $Company) {
            accounting_ChartOfAccountsGroup_Id
          }
        }
      }
    }
  `;
}

export function createCategoryMutation() {
  return gql`
    mutation createCategory(
      $SelectedCompany: String!
      $ParentCategory: String!
      $CategoryCode: String!
      $CategoryName: String!
    ) {
      accounting {
        actions {
          createAccountCategory(
            SelectedCompany: $SelectedCompany
            ParentCategory: $ParentCategory
            CategoryCode: $CategoryCode
            CategoryName: $CategoryName
          ) {
            id
          }
        }
      }
    }
  `;
}
