import React from 'react';
import { IProductionDetailsSummary } from '..';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { capitalizeFirstLetterLowerOthers } from '../../../../../utils/formatting-utils';

interface IProductionPolicyMotorDetailsWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionPolicyMotorDetailsWidget: React.FC<
  IProductionPolicyMotorDetailsWidgetProps
> = ({ data }) => {
  const dynamicView: IDynamicDiplayView = {
    name: 'MotorDetails',
    sections: [
      {
        name: 'MotorDetails',
        title: 'Motor Details',
        properties: {
          nameOnLicenseEn: {
            multiline: false,
            value: data.NameOnLicenseEn,
            name: 'nameOnLicenseEn',
            title: 'Name on License (EN)',
          },
          nameOnLicenseAr: {
            multiline: false,
            value: data.NameOnLicenseAr,
            name: 'nameOnLicenseAr',
            title: 'Name on License (AR)',
          },
          vignetteCode: {
            multiline: false,
            value: data.PolicyMotor.VignetteCode,
            name: 'vignetteCode',
            title: 'Vignette Code',
            hidden: Number(data.LineId.ExternalCode) !== 50,
          },
          vignette: {
            multiline: false,
            value: data.PolicyMotor.Vignette?.toString(),
            name: 'vignette',
            title: 'Vignette',
            hidden: Number(data.LineId.ExternalCode) !== 50,
          },
          plateCode: {
            multiline: false,
            value: data.Vehicle.PlateCode,
            name: 'plateCode',
            title: 'Plate Code',
          },
          plateNumber: {
            multiline: false,
            value: data.Vehicle.PlateNumber?.toString(),
            name: 'plateNumber',
            title: 'Plate Number',
          },
          usageType: {
            multiline: false,
            value: data.Vehicle.UsageType,
            name: 'usageType',
            title: 'Usage',
          },
          bodyType: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data.Vehicle.BodyType || ''
            ),
            name: 'bodyType',
            title: 'Body',
          },
          engineType: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data.Vehicle.EngineType || ''
            ),
            name: 'engineType',
            title: 'Engine Type',
          },
          brand: {
            multiline: false,
            value: data.Vehicle.Brand,
            name: 'brand',
            title: 'Make',
          },
          model: {
            multiline: false,
            value: data.Vehicle.Model,
            name: 'model',
            title: 'Model',
          },
          color: {
            multiline: false,
            value: '',
            name: 'color',
            title: 'Color',
          },
          yearOfMake: {
            multiline: false,
            value: data.Vehicle.YearOfMake?.toString(),
            name: 'yearOfMake',
            title: 'Year of Make',
          },
          motor: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data.Vehicle?.Motor || '',
              '_'
            ),
            name: 'motor',
            title: 'Engine',
          },
          weight: {
            multiline: false,
            value: data.Vehicle.Weight?.toString(),
            name: 'weight',
            title: 'Weight (in Tons)',
            hidden:
              data.Vehicle.BodyType !== 'VAN' &&
              data.Vehicle.BodyType !== 'PICKUP' &&
              data.Vehicle.BodyType !== 'TANKER',
          },
          engineSize: {
            multiline: false,
            value: data.Vehicle.EngineSize?.toString(),
            name: 'engineSize',
            title: 'Engine Size (in cc)',
            hidden: data.Vehicle.BodyType !== 'MOTORCYCLE',
          },
          seats: {
            multiline: false,
            value: data.Vehicle.Seats?.toString(),
            name: 'seats',
            title: 'Seating Capacity',
          },
          chassis: {
            multiline: false,
            value: data.Vehicle.Chassis?.toString(),
            name: 'chassis',
            title: 'Chassis',
          },
          horsePower: {
            multiline: false,
            value: data.Vehicle.HorsePower?.toString(),
            name: 'horsePower',
            title: 'Horse Power',
          },
        },
      },
    ],
  };

  return <DynamicWidget hasFourFields={true} view={dynamicView} />;
};

export default ProductionPolicyMotorDetailsWidget;
