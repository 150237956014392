import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../components/Loader';
import ProposalDetailsPage from '../../../../../forms/proposal-details-page/ProposalDetailsPage';
import ProposaExpatlDetailsPage from '../expat/ProposalExpatPage';
import { extractExternalCode } from './content';
import { getProposalDetailsMainInfo } from './queries';
import ProposalGenericPage from '../generic/ProposalGenericPage';
import ProposalMedicalPage from '../medical/ProposalMedicalPage';
import ProposalTravelDetailsPage from '../travel/ProposalTravelDetailsPage';

const MainProposalPage = () => {
  const params = useParams();
  const entityId = params.id;
  const [loading, setLoading] = useState<boolean>(true);
  const [currentLine, setCurrentLine] = useState<string>();

  const [getProposalDetailsLazy] = useLazyQuery(getProposalDetailsMainInfo());

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setLoading(true);
    const result: { data: any } = await getProposalDetailsLazy({
      variables: { id: entityId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    const line = extractExternalCode(result);
    setCurrentLine(line);
    setLoading(false);
  };

  const renderContent = () => {
    switch (currentLine) {
      case '4':
      case '50':
      case '51':
        return <ProposalDetailsPage />;
      case '43':
        return <ProposaExpatlDetailsPage />;
      case '48':
        return <ProposalTravelDetailsPage />;
      case '19':
      case '6':
        return <ProposalMedicalPage currentLineId={currentLine} />;
      default:
        return <ProposalGenericPage />;
    }
  };

  if (loading || !currentLine) {
    return <Loader />;
  }

  return renderContent();
};

export default MainProposalPage;
