import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  insurance_Agent_FirstName: {
    name: 'insurance_Agent_FirstName',
    title: 'First Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  insurance_Agent_LastName: {
    name: 'insurance_Agent_LastName',
    title: 'Last Name',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_Agent_AgenciesNames: {
    name: 'insurance_Agent_AgenciesNames',
    title: 'Business Partner',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_Agent_AgentType: {
    name: 'insurance_Agent_AgentType',
    title: 'Type',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_Agent_AgentStatus: {
    name: 'insurance_Agent_AgentStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_Agent_createdOn: {
    name: 'insurance_Agent_createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  insurance_Agent_modifiedOn: {
    name: 'insurance_Agent_modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        agency: {
          name: 'agency',
          title: 'Business Partners',
          type: FormInputTypes.chips,
          value: filterValues?.agency || null,
          placeholder: 'Business Partners',
          triggerUpdate: true,
          selectOptions: { ...lovs.agency },
          multiple: false,
          canClearSingleValueSelection: true,
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        type: {
          name: 'type',
          title: 'Type',
          type: FormInputTypes.chips,
          value: filterValues?.type || [],
          placeholder: 'Type',
          triggerUpdate: true,
          selectOptions: { ...lovs.type },
        },
        access: {
          name: 'access',
          title: 'Access',
          type: FormInputTypes.chips,
          value: filterValues?.access || [],
          placeholder: 'Access',
          triggerUpdate: true,
          selectOptions: { ...lovs.access },
        },
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+ New',
    onClick: () => undefined,
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
];
