import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query ProductsList(
      $CreatedOnFromDate: Date
      $CreatedOnToDate: Date
      $DescendingField: Boolean
      $KeywordSearch: String
      $OrderByField: String
      $SelectedBuySell: [Accounting_BuySellList]
      $SelectedCompany: String!
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      Accounting {
        queries {
          ProductsList(
            CreatedOnFromDate: $CreatedOnFromDate
            CreatedOnToDate: $CreatedOnToDate
            DescendingField: $DescendingField
            KeywordSearch: $KeywordSearch
            OrderByField: $OrderByField
            SelectedBuySell: $SelectedBuySell
            SelectedCompany: $SelectedCompany
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            items {
              accounting_Product_Id
              accounting_Product_ProductName
              accounting_Product_ProductDescription
              accounting_Product_ProductPrice
              accounting_Product_BuySell
              accounting_Product_RelatedIncomeAccount {
                id
              }
              accounting_Product_RelatedExpenseAccount {
                id
              }
              accounting_Product_RelatedCompany {
                Id
              }
              accounting_Product_createdOn
              accounting_Product_modifiedOn
            }
            paging {
              pageSize
              pageNumber
              totalCount
            }
          }
        }
      }
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
    }
  `;
}

export function GetProductAuditTrails() {
  return gql`
    query GetProductAuditTrails($id: String!) {
      Accounting {
        queries {
          GetProductAuditTrails(ProductID: $id) {
            accounting_AuditTrail_Id
            accounting_AuditTrail_Title
            accounting_AuditTrail_Message
            accounting_AuditTrail_createdOn
          }
        }
      }
    }
  `;
}
