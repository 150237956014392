import _, { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import EnhancedChipInput from '../../../components/enhanced-form/EnhancedChipInput';
import EnhancedInput from '../../../components/enhanced-form/EnhancedInput';
import EnhancedNumberInput from '../../../components/enhanced-form/EnhancedNumberInput';
import {
  IProposalCover,
  IProposalMotorDetails,
  IProposalPageFormState,
} from './form';
import dayjs from 'dayjs';
import { IProposalDetailsSummary } from './index2';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { getModelsByBrand, validateMotorVignette } from '../queries';
import { lookupListAsRecordObject } from '../../../utils/graph-utils';
import { useParams } from 'react-router-dom';
import { validateMotorDetailsField } from '../validation';
import DataService from '../../../services/dataService';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../constants';
import { filteredBodyTypeList, filteredUsageTypeList } from '../utils';

interface IProposalMotorDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  defaultPlanCoversList: Record<string, IProposalCover>;
  allowPlanCoverEdits: boolean;
  status: string;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  section: {
    padding: '23px 0 35px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 23.5%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    margin: '0px!important',
    padding: '0px!important',
  },
  labelDropdown: {
    color: '#000000',
    margin: '5px 0 0 0 !important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const ProposalMotorDetailsWidget: React.FC<
  IProposalMotorDetailsWidgetProps
> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  data,
  lovs,
  defaultPlanCoversList,
  allowPlanCoverEdits,
  status,
}) => {
  const { classes } = useStyles();
  const params = useParams();

  const values = pageState.values.motorDetails;
  const errors = pageState.errors.motorDetails;
  const touched = pageState.touched.motorDetails;

  const [getModelsByBrandsLazy] = useLazyQuery(getModelsByBrand());

  const [modelLovs, setModelLovs] =
    useState<Record<string, Record<string, string>>>(null);
  const [loadingNameOnLicenseArState, setLoadingNameOnLicenseArState] =
    useState<boolean>(false);
  const [filteredUsageTypes, setFilteredUsageTypes] = useState<
    Record<string, string>
  >(lovs.usageTypes);
  const [filteredBodyTypes, setFilteredBodyTypes] = useState<
    Record<string, string>
  >(lovs.bodyTypes);

  const [validateVignetteLazy] = useMutation(validateMotorVignette(), {});

  const initialize = async () => {
    if (values.brand) {
      const result = await getModelsByBrandsLazy({
        variables: { modelId: values.brand || null },
      });
      const models = result.data;
      const modelsToLookup = lookupListAsRecordObject(
        models?.Insurance?.lookups?.models
      );
      setModelLovs({ model: modelsToLookup });
    }
    if (values.plateCode) {
      const filteredUsage = filteredUsageTypeList(
        values.plateCode,
        lovs.usageTypes
      );
      setFilteredUsageTypes(filteredUsage);
    }

    if (values.usageType) {
      const filteredBody = filteredBodyTypeList(
        values.usageType,
        lovs.bodyTypes
      );
      setFilteredBodyTypes(filteredBody);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const onFieldBlur = async (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.motorDetails[fieldName] = true;

    if (fieldName === 'brand') {
      if (values.brand) {
        const result = await getModelsByBrandsLazy({
          variables: { modelId: values.brand || null },
        });
        const models = result.data;
        const modelsToLookup = lookupListAsRecordObject(
          models?.Insurance?.lookups?.models
        );
        setModelLovs({ model: modelsToLookup });
      }
    }

    if (fieldName === 'vignetteCode') {
      if (!isEmpty(values.vignette)) {
        validateVignetteLazy({
          variables: {
            businessPartnerID: data.PrimaryBPID.Id,
            entityId: params.id,
            effectiveDate: dayjs(new Date(data.PolicyEffectiveDate)).format(
              'YYYY-MM-DD'
            ),
            vignetteCode: values.vignetteCode,
            vignette: Number(values.vignette),
          },
        }).catch((error) => {
          if (
            error instanceof ApolloError &&
            error.message === 'Vignette Number is not assigned to you'
          ) {
            const vignetteAlreadyExistsErrorMessage = error.message;

            newPageState.errors.motorDetails['vignetteCode'] =
              vignetteAlreadyExistsErrorMessage;
          } else if (
            error instanceof ApolloError &&
            error.message === 'Vignette already in use'
          ) {
            const vignetteAlreadyExistsErrorMessage = error.message;

            newPageState.errors.motorDetails['vignetteCode'] =
              vignetteAlreadyExistsErrorMessage;
          } else {
            newPageState.errors.motorDetails['vignetteCode'] = '';
          }
        });
      }
    }
    if (fieldName === 'vignette') {
      validateVignetteLazy({
        variables: {
          businessPartnerID: data.PrimaryBPID.Id,
          entityId: params.id,
          effectiveDate: dayjs(new Date(data.PolicyEffectiveDate)).format(
            'YYYY-MM-DD'
          ),
          vignetteCode: values.vignetteCode,
          vignette: Number(values.vignette),
        },
      }).catch((error) => {
        if (
          error instanceof ApolloError &&
          error.message === 'Vignette Number is not assigned to you'
        ) {
          const vignetteAlreadyExistsErrorMessage = error.message;

          newPageState.errors.motorDetails['vignette'] =
            vignetteAlreadyExistsErrorMessage;
        } else if (
          error instanceof ApolloError &&
          error.message === 'Vignette already in use'
        ) {
          const vignetteAlreadyExistsErrorMessage = error.message;

          newPageState.errors.motorDetails['vignette'] =
            vignetteAlreadyExistsErrorMessage;
        } else {
          newPageState.errors.motorDetails['vignette'] = '';
        }
      });
    }

    if (fieldName === 'nameOnLicenseEn') {
      autoSetNameOnLicenseAr(values.nameOnLicenseEn);
    }

    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IProposalMotorDetails,
    value: any,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);

    (newPageState.values.motorDetails as any)[fieldName] = value;

    if (fieldName === 'plateCode') {
      const filteredUsage = filteredUsageTypeList(value, lovs.usageTypes);
      setFilteredUsageTypes(filteredUsage);
    }

    if (fieldName === 'usageType') {
      const filteredBody = filteredBodyTypeList(value, lovs.bodyTypes);
      setFilteredBodyTypes(filteredBody);
    }

    if (fieldName === 'brand') {
      newPageState.values.motorDetails.model = '';
      newPageState.errors.motorDetails.model = '';
      newPageState.touched.motorDetails.model = false;
    }
    (newPageState.errors.motorDetails as any)[fieldName] =
      validateMotorDetailsField(
        fieldName,
        value,
        newPageState.values.motorDetails
      );

    if (touched) {
      newPageState.touched.costs[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  const autoSetNameOnLicenseAr = (enTxt: string) => {
    const newPageState = _.cloneDeep(pageState);
    setLoadingNameOnLicenseArState(true);
    DataService.get(`/api/prx/transliterate.qcri.org/en2ar/` + enTxt)
      .then(async (response) => {
        if (response.ok) {
          const result: { results: string } = await response.json();
          newPageState.values.motorDetails.nameOnLicenseAr = result.results;
          onFieldUpdate('nameOnLicenseAr', result.results);
          onPageStateUpdate(newPageState);
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        newPageState.values.motorDetails.nameOnLicenseAr = enTxt;
      })
      .finally(() => {
        setLoadingNameOnLicenseArState(false);
      });
    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Motor Details"
        hasTitleSpecificDesign={false}
        classes={{
          container: classes.section,
        }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <EnhancedInput
            key="nameOnLicenseEn"
            name="nameOnLicenseEn"
            title="Name on License (EN)*"
            placeholder="Name on License (EN)"
            value={values.nameOnLicenseEn}
            error={touched.nameOnLicenseEn ? errors.nameOnLicenseEn : ''}
            onBlur={(v) => onFieldBlur('nameOnLicenseEn')}
            onChange={(v) => onFieldUpdate('nameOnLicenseEn', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            type=""
          />

          <EnhancedInput
            key="nameOnLicenseAr"
            name="nameOnLicenseAr"
            title="Name on License (AR)*"
            placeholder="Name on License (AR)"
            value={values.nameOnLicenseAr}
            error={touched.nameOnLicenseAr ? errors.nameOnLicenseAr : ''}
            onBlur={(v) => onFieldBlur('nameOnLicenseAr')}
            onChange={(v) => onFieldUpdate('nameOnLicenseAr', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            loader={loadingNameOnLicenseArState}
            rtlContent
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            type=""
          />

          <EnhancedInput
            key="vignetteCode"
            name="vignetteCode"
            title="Vignette Code*"
            placeholder="Vignette Code"
            value={values.vignetteCode}
            error={touched.vignetteCode ? errors.vignetteCode : ''}
            onBlur={(v) => onFieldBlur('vignetteCode')}
            onChange={(v) => onFieldUpdate('vignetteCode', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) ||
              data.LineId.ExternalCode != '50' ||
              disabledForm
            }
            material={true}
            hidden={data.LineId.ExternalCode != '50'}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            type=""
          />

          <EnhancedNumberInput
            key="vignette"
            name="vignette"
            title="Vignette*"
            placeholder="Vignette"
            value={values.vignette}
            error={touched.vignette ? errors.vignette : ''}
            onBlur={(v) => onFieldBlur('vignette')}
            onChange={(v) => onFieldUpdate('vignette', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) ||
              data.LineId.ExternalCode != '50' ||
              disabledForm
            }
            material={true}
            hidden={data.LineId.ExternalCode != '50'}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedChipInput
            key="plateCode"
            name="plateCode"
            title="Plate Code"
            placeholder="Plate Code"
            value={values.plateCode}
            error={touched.plateCode ? errors.plateCode : ''}
            onChange={(v) => onFieldUpdate('plateCode', v, true)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            selectOptions={lovs.plateCodes}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
          />

          <EnhancedNumberInput
            key="plateNumber"
            name="plateNumber"
            title="Plate Number"
            placeholder="Plate Number"
            value={values.plateNumber}
            error={touched.plateNumber ? errors.plateNumber : ''}
            onBlur={(v) => onFieldBlur('plateNumber')}
            onChange={(v) => onFieldUpdate('plateNumber', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedChipInput
            key="usageType"
            name="usageType"
            title="Usage*"
            placeholder="Usage"
            value={values.usageType}
            error={touched.usageType ? errors.usageType : ''}
            onChange={(v) => onFieldUpdate('usageType', v, true)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            selectOptions={filteredUsageTypes}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
          />

          <EnhancedChipInput
            key="bodyType"
            name="bodyType"
            title="Body*"
            placeholder="Body"
            value={values.bodyType}
            error={touched.bodyType ? errors.bodyType : ''}
            onChange={(v) => onFieldUpdate('bodyType', v, true)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            selectOptions={filteredBodyTypes}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
          />

          <EnhancedChipInput
            key="engineType"
            name="engineType"
            title="Engine Type*"
            placeholder="Engine Type"
            value={values.engineType}
            error={touched.engineType ? errors.engineType : ''}
            onChange={(v) => onFieldUpdate('engineType', v, true)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            selectOptions={lovs.engineTypes}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
          />

          <EnhancedChipInput
            key="brand"
            name="brand"
            title="Make*"
            placeholder="Make"
            value={values.brand}
            error={touched.brand ? errors.brand : ''}
            onChange={(v) => onFieldUpdate('brand', v, true)}
            onBlur={(v) => onFieldBlur('brand')}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            selectOptions={lovs.brands}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
          />

          <EnhancedChipInput
            key="model"
            name="model"
            title="Model*"
            placeholder="Model"
            value={values.model}
            error={touched.model ? errors.model : ''}
            onChange={(v) => onFieldUpdate('model', v, true)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            selectOptions={modelLovs?.model || {}}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
          />

          <EnhancedChipInput
            key="color"
            name="color"
            title="Color"
            placeholder="Color"
            value={values.color}
            error={touched.color ? errors.color : ''}
            onChange={(v) => onFieldUpdate('color', v, true)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            selectOptions={lovs?.colors || {}}
            required={false}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
          />

          <EnhancedNumberInput
            key="yearOfMake"
            name="yearOfMake"
            title="Year of Make*"
            placeholder="Year of Make"
            value={values.yearOfMake}
            error={touched.yearOfMake ? errors.yearOfMake : ''}
            onBlur={(v) => onFieldBlur('yearOfMake')}
            onChange={(v) => onFieldUpdate('yearOfMake', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="motor"
            name="motor"
            title="Engine*"
            placeholder="Engine"
            value={values.motor}
            error={touched.motor ? errors.motor : ''}
            onBlur={(v) => onFieldBlur('motor')}
            onChange={(v) => onFieldUpdate('motor', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            type=""
          />

          <EnhancedNumberInput
            key="weight"
            name="weight"
            title="Weight (in Tons)*"
            placeholder="Weight (in Tons)"
            value={values.weight}
            error={touched.weight ? errors.weight : ''}
            onBlur={(v) => onFieldBlur('weight')}
            onChange={(v) => onFieldUpdate('weight', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            hidden={!['VAN', 'PICKUP', 'F_TANKER'].includes(values.bodyType)}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedNumberInput
            key="engineSize"
            name="engineSize"
            title="Engine Size (in cc)*"
            placeholder="Engine Size (in cc)"
            value={values.engineSize}
            error={touched.engineSize ? errors.engineSize : ''}
            onBlur={(v) => onFieldBlur('engineSize')}
            onChange={(v) => onFieldUpdate('engineSize', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            hidden={!['MOTORCYCLE'].includes(values.bodyType)}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedNumberInput
            key="seats"
            name="seats"
            title="Seating Capacity*"
            placeholder="Seating Capacity"
            value={values.seats}
            error={touched.seats ? errors.seats : ''}
            onBlur={(v) => onFieldBlur('seats')}
            onChange={(v) => onFieldUpdate('seats', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="chassis"
            name="chassis"
            title="Chassis*"
            placeholder="Chassis"
            value={values.chassis}
            error={touched.chassis ? errors.chassis : ''}
            onBlur={(v) => onFieldBlur('chassis')}
            onChange={(v) => onFieldUpdate('chassis', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            type=""
          />

          <EnhancedNumberInput
            key="horsePower"
            name="horsePower"
            title="Horse Power*"
            placeholder="Horse Power"
            value={values.horsePower}
            error={touched.horsePower ? errors.horsePower : ''}
            onBlur={(v) => onFieldBlur('horsePower')}
            onChange={(v) => onFieldUpdate('horsePower', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedNumberInput
            key="carValue"
            name="carValue"
            title="Car Value*"
            placeholder="Car Value"
            value={values.carValue}
            error={touched.carValue ? errors.carValue : ''}
            onBlur={(v) => onFieldBlur('carValue')}
            onChange={(v) => onFieldUpdate('carValue', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            hidden={data.LineId.ExternalCode != '51'}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedNumberInput
            key="agencyRepairYear"
            name="agencyRepairYear"
            title="Agency Repair Years*"
            placeholder="Agency Repair Years"
            value={values.agencyRepairYear}
            error={touched.agencyRepairYear ? errors.agencyRepairYear : ''}
            onBlur={(v) => onFieldBlur('agencyRepairYear')}
            onChange={(v) => onFieldUpdate('agencyRepairYear', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            hidden={data.LineId.ExternalCode != '51'}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedNumberInput
            key="depreciationYears"
            name="depreciationYears"
            title="Depreciation Years*"
            placeholder="Depreciation Years"
            value={values.depreciationYears}
            error={touched.depreciationYears ? errors.depreciationYears : ''}
            onBlur={(v) => onFieldBlur('depreciationYears')}
            onChange={(v) => onFieldUpdate('depreciationYears', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            hidden={data.LineId.ExternalCode != '51'}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalMotorDetailsWidget;
