import React from 'react';

const FilterIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.609"
    height="10.667"
    viewBox="0 0 10.609 10.667"
  >
    <path
      id="Path_19117"
      data-name="Path 19117"
      d="M4.667,4h6.667L7.993,8.2ZM2.833,3.74C4.18,5.467,6.667,8.667,6.667,8.667v4a.669.669,0,0,0,.667.667H8.667a.669.669,0,0,0,.667-.667v-4s2.48-3.2,3.827-4.927a.665.665,0,0,0-.527-1.073H3.36A.665.665,0,0,0,2.833,3.74Z"
      transform="translate(-2.692 -2.667)"
    />
  </svg>
);

export default FilterIcon;
