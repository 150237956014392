import { makeStyles } from 'tss-react/mui';
import React from 'react';
import {
  HEADER_HEIGHT,
  MAIN_ONE_THEME,
  contentFontFamilyRegular,
} from '../../../constants';
import HeaderContainer from '../HeaderContainer';

interface IGenericDrawerHeaderProps {
  background: string;
  title: string;
  onClose?: () => void;
}

const useStyles = makeStyles()(() => ({
  header: {
    justifyContent: 'space-between !important',
  },
  title: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: contentFontFamilyRegular,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    lineHeight: '19px',
    margin: '0',
    fontWeight: 'bold',
  },
  close: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: contentFontFamilyRegular,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    lineHeight: '19px',
    margin: '0 30px 0 0',
    background: 'unset',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
}));

const GenericDrawerHeader: React.FC<IGenericDrawerHeaderProps> = ({
  background,
  title,
  onClose,
}) => {
  const { classes } = useStyles();
  return (
    <HeaderContainer
      className={classes.header}
      height={HEADER_HEIGHT}
      background={background}
    >
      <figure
        style={{
          height: '20px',
          width: '20px',
          margin: '0px 0 0 30px',
          background:
            "url('https://ufa-cdn.azureedge.net/ufa-app-portal/images/app-launcher.svg')",
          backgroundSize: '20px 20px',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <span className={classes.title}>{title}</span>
      <button className={classes.close} type="button" onClick={onClose}>
        X
      </button>
    </HeaderContainer>
  );
};

export default GenericDrawerHeader;
