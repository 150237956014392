import { IAbstractRecord } from '../../models';

export interface IPaymentTermsPopupProps {
  policyId: string;
  policyNumber: string;
  data: IAbstractRecord;
  currencySymbol: string;
  open: boolean;
  onClose: (success: boolean) => void;
}

export interface IPaymentTermsFormsProps {
  data: IAbstractRecord;
  policyId: string;
  currencySymbol: string;
  onClose: (success: boolean) => void;
}

export interface IPaymentTermsPopupFormState {
  values: {
    policyNumber: string;
    policyIssuanceDate: string;
    policyExpiryDate: string;
    paymentType: string;
    effectiveFrom: string;
    frequency: string;
    numOfPayments: string;
    bills: Array<IAbstractRecord>;
  };
  errors: {
    policyIssuanceDate: string;
    policyExpiryDate: string;
    paymentType: string;
    effectiveFrom: string;
    frequency: string;
    numOfPayments: string;
    amountDue: string;
  };
  touched: {
    policyIssuanceDate: boolean;
    policyExpiryDate: boolean;
    paymentType: boolean;
    effectiveFrom: boolean;
    frequency: boolean;
    numOfPayments: boolean;
  };
}

export interface IPaymentSimulationResponseData {
  AmountDue: number;
  BillNumber: number;
  BillStatus: string;
  DueDate: string;
  OutstandingBalance: number;
  TotalCommission: number;
  TotalPremium: number;
}

export interface IPaymentSimulationTableData {
  billNumber: number;
  dueDate: string;
  totalPremium: number;
  totalCommission: number;
  amountDue: string;
  outstandingAmount: number;
  status: string;
  disabled: boolean;
  Currency: string;
}

export interface ILovsTypes {
  paymentTypes: Record<string, string>;
  frequencies: Record<string, string>;
  numOfPayments: Record<string, number>;
  billStatuses: Record<string, string>;
}

export const initialErrors = {
  policyIssuanceDate: '',
  policyExpiryDate: '',
  paymentType: '',
  effectiveFrom: '',
  frequency: '',
  numOfPayments: '',
  amountDue: '',
};
