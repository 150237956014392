import React, { useEffect, useState } from 'react';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import {
  GetAccountAuditTrails,
  getAccountSubCategoryLovs,
  getCompanyLovs,
  listQuery,
} from './queries';
import { convertAuditTrailsToActivities, mapToListingData } from './utils';
import { filterSectionsContent, headers } from './content';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../../utils/filter-utils';
import { toast } from 'react-toastify';
import { IEnhanceTableHeaderClickable } from '../../../components/enhanced-table';
import {
  contentFontFamilyBold,
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../../constants';
import dayjs from 'dayjs';
import AccountDrawer from '../../../forms/account-drawer/AccountDrawer';
import AccountImportDrawer from '../../../forms/account-import-drawer/AccountImportDrawer';
import { makeStyles } from 'tss-react/mui';
import CustomActivities from '../../../activities/CustomActivities';
import { IActivityEntityBase } from '../../../activities';
import CategoryDrawer from '../../../forms/category-drawer/CategoryDrawer';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { lookupListAsRecordObject } from '../../../utils/graph-utils';
import { isEmpty } from '../../../utils/validationUtils';
import ListingCollapsibleTable from '../../../components/form-fields/collapsible-table/ListingCollapsibleTable';
import SimpleActionBar from '../../../components/form-fields/menu/SimpleActionBar';
import { IEnhancedMenuItem } from '../../../components/form-fields/table';

const useStyles = makeStyles()(() => ({
  header: {
    margin: '0 0 2em',
    display: 'grid',
    gridTemplateAreas: `'title actions'`,
    gridGap: '1em',
  },
  title: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: contentFontFamilyBold,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: 'normal',
    margin: 0,
    gridArea: 'title',
    alignSelf: 'center',
  },
  actions: {
    display: 'flex',
    gridArea: 'actions',
    justifyContent: 'flex-end',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '37px',
  },
  button: {
    backgroundColor: '#C00020',
    '&:focus': {
      outline: 'none',
    },
  },
}));

interface IAccountPageAuditTrailInfo {
  title: string;
  items: IActivityEntityBase[];
  loading: boolean;
}

const AccountsPage: React.FC = () => {
  const { classes } = useStyles();

  const [categoryDrawerOpen, setCategoryDrawerOpen] = useState<boolean>(false);
  const [accountDrawerOpen, setAccountDrawerOpen] = useState<boolean>(false);
  const [accountImportDrawerOpen, setAccountImportDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [auditTrailInfo, setAuditTrailInfo] =
    useState<IAccountPageAuditTrailInfo>();

  const [accounts, setAccountsData] = useState<
    Record<string, Record<string, IAccountsGenericTableData>>
  >({});

  const actions: IEnhancedMenuItem[] = [
    {
      name: '+ New Auxiliary',
      title: '+ New Auxiliary',
      isPrimary: true,
      onClick: () => {
        setChosenEditId(undefined);
        setAccountDrawerOpen(true);
      },
    },
    {
      name: 'New Ledger',
      title: 'New Ledger',
      onClick: () => {
        setCategoryDrawerOpen(true);
      },
    },
    {
      name: 'Export Accounts',
      title: 'Export Accounts',
      onClick: () => {
        setChosenEditId(undefined);
        setAccountDrawerOpen(true);
      },
    },
  ];

  const FILTER_SESSION_KEY = 'accountsFilter';

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          createdOn: [],
          company: '41',
          accountCategory: '',
          accountSubCategory: '',
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [getAccountsLazy] = useLazyQuery(listQuery());
  const [getCompanies] = useLazyQuery(getCompanyLovs());
  const [getAccountSubCategories] = useLazyQuery(getAccountSubCategoryLovs());
  const [getAccountAuditTrails] = useLazyQuery(GetAccountAuditTrails());

  const initialize = async (values: IAbstractRecord = filterValues) => {
    setLoading(true);
    setAccountDrawerOpen(false);
    setCategoryDrawerOpen(false);
    setAccountImportDrawerOpen(false);
    const lovs: Record<string, Record<string, string>> = {
      company: {},
      accountSubCategory: {},
    };
    let savedFilters = getFilter(FILTER_SESSION_KEY);
    if (!savedFilters || !Object.keys(savedFilters).length) {
      savedFilters = _.cloneDeep(values);
    }

    try {
      const companysResult = await getCompanies();

      if (!companysResult) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      lovs.company = lookupListAsRecordObject(
        companysResult.data?.SalesforceManagement?.lookups?.relatedCompanies
      );

      if (isEmpty(savedFilters.company)) {
        savedFilters.company = Object.keys(lovs.company)[0];
      }

      const accountSubCategoriesResult = await getAccountSubCategories({
        variables: {
          SelectedCompany: savedFilters.company,
        },
      });

      accountSubCategoriesResult.data?.Accounting?.queries?.GetAccountGroupLayer3ByCompany?.forEach(
        (element: { [key: string]: string }) => {
          if (element.accounting_ChartOfAccountsGroup_Id) {
            lovs.accountSubCategory[
              element.accounting_ChartOfAccountsGroup_Id
            ] =
              element.accounting_ChartOfAccountsGroup_AccountGroup +
              ' ' +
              element.accounting_ChartOfAccountsGroup_AccountGroupName;
          }
        }
      );

      if (
        isEmpty(savedFilters.accountSubCategory) ||
        isEmpty(lovs.accountSubCategory[savedFilters.accountSubCategory])
      ) {
        savedFilters.accountSubCategory = Object.keys(
          lovs.accountSubCategory
        )[0];
      }

      const result = await getAccountsLazy({
        variables: {
          SelectedCompany: savedFilters.company,
          CreatedOnFromDate: savedFilters.createdOn?.[0]
            ? dayjs(savedFilters.createdOn[0]).format(
                SEND_TO_BACKEND_DATE_FORMAT
              )
            : null,
          CreatedOnToDate: savedFilters.createdOn?.[1]
            ? dayjs(savedFilters.createdOn[1])
                .add(1, 'day')
                .format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          SelectedAccountSubCategory: savedFilters.accountSubCategory || '',
        },
      });

      if (!result || !result.data) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const tableData = mapToListingData(result.data);
      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterValues(savedFilters);
      setFilterSections(newFilterSections);
      setAccountsData(tableData);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewHistoryClick = async (id: string, title: string) => {
    setAuditTrailInfo({
      items: [],
      loading: true,
      title: title,
    });

    const result = await getAccountAuditTrails({
      variables: {
        id: id,
      },
    });

    if (result.data) {
      setAuditTrailInfo({
        items: convertAuditTrailsToActivities(result.data),
        loading: false,
        title: title,
      });
    }
  };

  (
    headers.accounting_ChartOfAccounts_AccountID as IEnhanceTableHeaderClickable
  ).callback = (payload: IAbstractRecord) => {
    setChosenEditId(payload.columns.accounting_ChartOfAccounts_Id);
    setAccountDrawerOpen(true);
  };

  (headers.viewHistory as IEnhanceTableHeaderClickable).callback = (
    payload: IAbstractRecord
  ) => {
    const id = payload.key;
    viewHistoryClick(
      id,
      payload.columns.accounting_ChartOfAccounts_AccountName
    );
  };

  const renderMainChildren = () => {
    return (
      <>
        <section className={classes.header}>
          {/* <h1 className={classes.title}>{title}</h1> */}
          <div className={classes.actions}>
            <SimpleActionBar items={actions} />
          </div>
        </section>
        {Object.entries(accounts)?.map(([key, value]) => (
          <ListingCollapsibleTable
            key={key}
            name={key}
            title={key}
            orderByAscendingByDefault
            // loading={loadingState}
            disableSelection
            data={{
              pagedItems: value,
              pageNumber: 1,
              pageSize: 10,
              totalCount: Object.keys(value).length,
            }}
            headers={headers}
          />
        ))}

        {categoryDrawerOpen && (
          <CategoryDrawer
            open={categoryDrawerOpen}
            onClose={() => setCategoryDrawerOpen(false)}
            onSuccess={() => initialize()}
          />
        )}

        {accountDrawerOpen && (
          <AccountDrawer
            open={accountDrawerOpen}
            onClose={() => setAccountDrawerOpen(false)}
            onSuccess={() => {
              // handlePageChange(0);
              initialize();
            }}
            accountId={chosenEditId}
            accountInfo={undefined}
          />
        )}
        {accountImportDrawerOpen && (
          <AccountImportDrawer
            open={accountImportDrawerOpen}
            onClose={() => setAccountImportDrawerOpen(false)}
            onSuccess={() => {
              // handlePageChange(0);
              initialize();
            }}
          />
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: IAbstractRecord) => {
    const newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    initialize(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading}
      name={'Applications'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
      rightChildren={
        <CustomActivities
          loader={auditTrailInfo?.loading || false}
          title={auditTrailInfo?.title || ''}
          items={auditTrailInfo?.items || []}
        />
      }
      onRightCollapseClick={() => {
        if (auditTrailInfo) {
          setAuditTrailInfo(undefined);
        }
      }}
      config={{
        leftColumn: {
          backgroundColor: '#FFFFFF',
          collapsable: false,
          collapsed: false,
          width: 2,
        },
        mainColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: false,
          collapsed: false,
          width: auditTrailInfo ? 7 : 10,
        },
        rightColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: true,
          collapsed: !auditTrailInfo,
          width: auditTrailInfo ? 3 : 0,
        },
      }}
    />
  );
};
export default AccountsPage;
