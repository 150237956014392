import { gql } from '@apollo/client';

export function getRangeEnums() {
  return gql`
    query getRangeEnums {
      Insurance_RangeTypes: __type(name: "Insurance_RangeTypes") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function createPolicyNumberRange() {
  return gql`
    mutation createPolicyNumberRange(
      $rangeFrom: Long!
      $rangeTo: Long!
      $rangeType: Insurance_RangeTypes!
    ) {
      insurance {
        actions {
          createPolicyNumberRange(
            rangeFrom: $rangeFrom
            rangeTo: $rangeTo
            rangeType: $rangeType
          ) {
            views {
              Insurance_all {
                properties {
                  RangeFrom
                  RangeTo
                  RangeType
                }
              }
            }
          }
        }
      }
    }
  `;
}
