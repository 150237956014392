import { gql } from '@apollo/client';

export function getSalesforceVignetteRangeInfo() {
  return gql`
    query getSalesforceVignetteRangeInfo($id: String!) {
      SalesforceManagement {
        entities {
          businessPartnerVignetteRange(id: $id) {
            id
            views {
              SalesforceManagement_all {
                properties {
                  RangeFrom
                  RangeTo
                  RangeStatus
                  RangeCode
                  RangeYear
                  BusinessPartnerID {
                    id
                    views {
                      SalesforceManagement_all {
                        properties {
                          Code
                          Type {
                            Code
                            Title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createPartnerVignetteRange() {
  return gql`
    mutation createPartnerVignetteRange(
      $entityId: String!
      $rangeFrom: Long!
      $rangeTo: Long!
      $rangeStatus: SalesforceManagement_VignetteRangeStatuses!
      $rangeCode: String!
      $rangeYear: Long!
    ) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              createVignetteRange(
                entityId: $entityId
                rangeFrom: $rangeFrom
                rangeTo: $rangeTo
                rangeStatus: $rangeStatus
                rangeCode: $rangeCode
                rangeYear: $rangeYear
              ) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      RangeFrom
                      RangeTo
                      RangeStatus
                      RangeCode
                      RangeYear
                      BusinessPartnerID {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updatePartnerVignetteRange() {
  return gql`
    mutation updatePartnerVignetteRange(
      $entityId: String!
      $selectedBusinessPartnerVignetteRangeId: String!
      $rangeFrom: Long!
      $rangeTo: Long!
      $rangeStatus: SalesforceManagement_VignetteRangeStatuses!
      $rangeCode: String!
      $rangeYear: Long!
    ) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              updateVignetteRange(
                entityId: $entityId
                selectedBusinessPartnerVignetteRangeId: $selectedBusinessPartnerVignetteRangeId
                rangeFrom: $rangeFrom
                rangeTo: $rangeTo
                rangeStatus: $rangeStatus
                rangeCode: $rangeCode
                rangeYear: $rangeYear
              ) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      RangeFrom
                      RangeTo
                      RangeStatus
                      RangeCode
                      RangeYear
                      BusinessPartnerID {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getSalesforceVignetteStatusesEnums() {
  return gql`
    query getSalesforceVignetteStatusesEnums {
      SalesforceManagement_VignetteRangeStatuses: __type(
        name: "SalesforceManagement_VignetteRangeStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
