import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  accounting_Product_ProductName: {
    name: 'accounting_Product_ProductName',
    title: 'Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  accounting_Product_ProductDescription: {
    name: 'accounting_Product_ProductDescription',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  accounting_Product_ProductPrice: {
    name: 'accounting_Product_ProductPrice',
    title: 'Price',
    type: EnhancedTableHeaderType.Currency,
  },
  accounting_Product_BuySell: {
    name: 'accounting_Product_BuySell',
    title: 'Sell/Buy',
    type: EnhancedTableHeaderType.BadgeArray,
    badgeConfig: {
      Buy: {
        color: '#397E21',
        backgroundColor: '#E5F7DF',
      },
      Sell: {
        color: '#C67D44',
        backgroundColor: '#FAEADD',
      },
    },
  },
  accounting_Product_createdOn: {
    name: 'accounting_Product_createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  accounting_Product_modifiedOn: {
    name: 'accounting_Product_modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
  viewHistory: {
    name: 'viewHistory',
    title: ' ',
    buttonLabel: 'View History',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        company: {
          name: 'company',
          title: 'Company',
          type: FormInputTypes.chips,
          value: filterValues?.company || null,
          placeholder: 'Company',
          triggerUpdate: true,
          selectOptions: { ...lovs.company },
          multiple: false,
          canClearSingleValueSelection: false,
        },
        SellBuy: {
          name: 'SellBuy',
          title: 'Sell/Buy',
          type: FormInputTypes.chips,
          value: filterValues?.SellBuy || null,
          placeholder: 'Category',
          triggerUpdate: true,
          selectOptions: { ...lovs.SellBuy },
          multiple: false,
          canClearSingleValueSelection: true,
        },
        createdOn: {
          name: 'createdOn',
          title: 'Date',
          type: FormInputTypes.daterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+New Product',
    onClick: () => undefined,
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
];
