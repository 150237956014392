import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  assignedLine: '',
  commissionType: '',
  lineCommissionValue: '',
  ValueCurrency: '',
  lineCommissionRate: '',
  ApplicableOn: '',
};

export const getInputs = (
  values: typeof initialValues = initialValues,
  lovs: Record<string, Record<string, any>> = {},
  isValue: boolean
): Record<string, DynamicFormInputType> => ({
  assignedLine: {
    name: 'assignedLine',
    title: 'Line',
    type: FormInputTypes.text,
    placeholder: 'Line',
    value: values.assignedLine,
    required: true,
    disabled: true,
  },
  commissionType: {
    name: 'commissionType',
    title: 'Commission Type',
    placeholder: 'Commission Type',
    type: FormInputTypes.chips,
    value: values.commissionType,
    multiple: false,
    required: true,
    selectOptions: lovs?.commissionType || {},
  },
  lineCommissionValue: {
    name: 'lineCommissionValue',
    title: 'Commission',
    type: FormInputTypes.formattedNumber,
    placeholder: '',
    value: values.lineCommissionValue,
    required: isValue,
    disabled: !isValue,
    hidden: !isValue,
    hasBetweenValidation: true,
    minValue: 0,
  },
  ValueCurrency: {
    name: 'ValueCurrency',
    title: 'Commission Currency',
    placeholder: 'Commission Currency',
    type: FormInputTypes.chips,
    value: !isEmpty(values.ValueCurrency) ? values.ValueCurrency : '',
    multiple: false,
    required: isValue,
    disabled: !isValue,
    hidden: !isValue,
    selectOptions: lovs?.ValueCurrency || {},
  },
  lineCommissionRate: {
    name: 'lineCommissionRate',
    title: 'Commission Rate',
    type: FormInputTypes.percentage,
    placeholder: 'Commission Rate',
    value: values.lineCommissionRate,
    required: !isValue,
    disabled: isValue,
    hidden: isValue,
    hasBetweenValidation: true,
  },
  ApplicableOn: {
    name: 'ApplicableOn',
    title: 'Commission Applicable On',
    placeholder: 'Commission Applicable On',
    type: FormInputTypes.chips,
    value: !isEmpty(values.ApplicableOn) ? values.ApplicableOn : '',
    multiple: false,
    required: !isValue,
    disabled: isValue,
    hidden: isValue,
    selectOptions: lovs?.ApplicableOn || {},
  },
});
