/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isEmpty } from '../../utils/validationUtils';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { contentFontFamilyRegular, MAIN_ONE_THEME } from '../../constants';

export interface IEnhancedSelectProps {
  name: string;
  title?: string;
  error?: string;
  disabled?: boolean;
  disabledOption?: boolean;
  allowClear?: boolean;
  items: Record<string, string | number>;
  multiple?: boolean;
  value?: string | number;
  maxTagCount?: number;
  values?: Array<string | number>;
  placeholder?: string;
  inputOnly?: boolean;
  onBlur?: () => void;
  onChange: (value: string | string[], name: string) => void;
  onSearch?: (value: string, name: string) => Promise<void>;
  loader?: boolean;
  sortByKey?: boolean;
  displayNoData?: boolean;
  icons?: Record<string, string>;
  cdnUrl?: string;
  enableSelectAllOption?: boolean;
  selectAllOptionTitle?: string;
  required?: boolean;
  customStyles?: {
    containerStyles?: string;
    labelStyles?: string;
    inputStyles?: string;
    errorStyles?: string;
  };
  displayTooltipNextToTitle?: boolean;
  description?: string;
  hideError?: boolean;
  advancedFilter?: boolean;
  material?: boolean;
  showSelectAll?: boolean;
  freeSolo?: boolean;
  clearValueOnSearch?: boolean;
  hideWrapperHeader?: boolean;
  supportLink?: string;
  onLinkClick?: (e: any) => void;
}

const useStyles = makeStyles<{
  error?: string;
  tooltipExists?: boolean;
  multiple?: boolean;
  canSelectAll: boolean;
  canClearAll: boolean;
}>()(
  (theme, { error, tooltipExists, multiple, canSelectAll, canClearAll }) => ({
    inputContainerStyles: {
      width: '100%',
      marginBottom: '5px',
      fontFamily: contentFontFamilyRegular,
    },
    inputStyles: {
      width: '100%',
      outline: 'none',
      backgroundColor: '#ffffff !important',
      '& .MuiInputBase-input': {
        '&::placeholder': {
          fontStyle: 'italic',
          opacity: '22%',
          fontFamily: contentFontFamilyRegular,
          fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
          backgroundColor: 'none',
        },
      },
      '& .MuiInputBase-root': {
        fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
        fontFamily: contentFontFamilyRegular,
        lineHeight: '15px !important',
        minHeight: '35px !important',
        width: '100% !important',
        padding: '0 25px 0 15px!important',
        margin: '0 auto !important',
        backgroundColor: `${MAIN_ONE_THEME.palette.secondary4.main} !important`,
        outlineColor: error ? MAIN_ONE_THEME.palette.error.main : undefined,
        border: error
          ? `1px solid ${MAIN_ONE_THEME.palette.error.main} !important`
          : `1px solid ${MAIN_ONE_THEME.palette.secondary3.main} !important`,
        boxSizing: 'border-box',
        borderRadius: '5px',
        '&::before': {
          display: 'none !important',
        },
        '&::after': {
          display: 'none !important',
        },
      },
    },
    selectMaterial: {
      width: '100%',
      '& .MuiInput-input': {
        fontFamily: contentFontFamilyRegular,
        fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
        lineHeight: '15px',
        color: `${MAIN_ONE_THEME.palette.primary2.main}`,
        paddingBottom: '0px !important',
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.20)',
          fontStyle: 'italic',
          opacity: '10',
          fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
        },
      },
      '& .MuiInputBase-root:before': {
        border: 'none',
        backgroundColor: 'unset !important',
        borderRadius: '0 !important',
        borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
      },
      '& .MuiInput-root': {
        height: '34px',
        paddingBottom: '0px !important',
      },
      '& .MuiInput-root.Mui-disabled:before': {
        borderBottomStyle: 'solid',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
        {
          borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
        },
    },
    optionDesign: {
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg3.fontSize}px !important`,
      lineHeight: '15px !important',
      color: MAIN_ONE_THEME.palette.primary1.main,
      fontFamily: contentFontFamilyRegular,
    },
    chipDesign: {
      fontSize: '13px',
      fontFamily: 'OpenSans-Regular',
      height: 'unset',
      color: '#717C8F',
      marginRight: '10px',
      padding: '0',
      backgroundColor: '#ECECEF',
    },
    chipMaxTagDesign: {
      fontSize: '13px',
      fontFamily: 'OpenSans-Regular',
      height: 'unset',
      color: '#717C8F',
      marginRight: '10px',
      backgroundColor: '#ECECEF',
      padding: '0 13px',
      borderRadius: '6px',
    },
    selectError: {
      '& .MuiInputBase-root:focus': {
        borderColor: theme.palette.error.main,
        outlineColor: theme.palette.error.main,
        boxShadow: 'none',
        borderRadius: 0,
      },
      '& .MuiInputBase-root': {
        borderColor: `${theme.palette.error.main} !important`,
        outlineColor: `${theme.palette.error.main} !important`,
      },
    },
    trueIcon: {
      position: 'absolute',
      top: '50%',
      right: '12px',
      fontWeight: '700',
      fontSize: '12px',
      textShadow: '0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0',
      webkitTransform: 'translateY(-50%)',
      transform: 'translateY(-50%)',
      transition: 'all .2s',
      color: 'rgba(0,0,0,.87)',
      display: 'none',
    },
    arrowIcon: {
      color: '#B8C0CA',
      fontSize: '20px',
    },
    closeIcon: {
      display: 'inline-block',
      fontStyle: 'normal',
      lineHeight: '0',
      height: '20px',
      textAlign: 'center',
      textTransform: 'none',
      textRendering: 'optimizeLegibility',
      color: 'rgba(0,0,0,.25)',
      fontSize: '15px',
      transformOrigin: '50% 50%',
      position: 'absolute',
      right: '-7px',
    },
    optionImage: {
      paddingRight: '13px',
      verticalAlign: 'text-top',
    },
    deleteIcon: {
      '& i': {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
      },
      '& svg': {
        color: '#6E7C91',
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
    disabledInput: {
      '& .MuiInputBase-root.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.5)!important',
        opacity: `0.6 !important`,
        textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
      },
      '& .MuiInputBase-input.Mui-disabled': {
        cursor: 'not-allowed',
        color: 'rgba(0, 0, 0, 0.5)!important',
        opacity: `0.6 !important`,
        textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.9) !important`,
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.20)',
          fontStyle: 'italic',
          opacity: '0.3',
        },
      },
    },
    chipStyle: {
      height: 'unset',
      color: '#fff',
      backgroundColor: MAIN_ONE_THEME.palette.primary1.main,
      borderRadius: '0px',
      margin: '3px',
      '& .MuiChip-deleteIcon': {
        color: 'white !important',
      },
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
    },
    actionButton: {
      textDecoration: 'none',
      border: 0,
      outline: 0,
      background: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.7,
      },
    },
    selectAllButton: {
      pointerEvents: canSelectAll ? undefined : 'none',
      opacity: canSelectAll ? undefined : 0.4,
    },
    clearAllButton: {
      pointerEvents: canClearAll ? undefined : 'none',
      opacity: canClearAll ? undefined : 0.4,
    },
    supportLinkContainer: {
      textAlign: 'right',
      marginTop: '10px',
    },
  })
);

const NewChipsInput: React.FC<IEnhancedSelectProps> = ({
  name,
  title,
  placeholder,
  value = '',
  maxTagCount,
  error,
  onChange,
  onSearch,
  onBlur = () => undefined,
  disabled,
  items = {},
  values = [],
  inputOnly,
  loader,
  multiple = false,
  allowClear,
  sortByKey,
  displayNoData = false,
  icons = {},
  cdnUrl,
  enableSelectAllOption = false,
  selectAllOptionTitle,
  required = false,
  customStyles = {},
  displayTooltipNextToTitle = false,
  description,
  hideError = false,
  advancedFilter = false,
  material,
  showSelectAll,
  clearValueOnSearch = true,
  freeSolo = true,
  hideWrapperHeader = false,
  supportLink = '',
  onLinkClick = () => undefined,
}) => {
  const canSelectAll = useMemo(
    () =>
      multiple &&
      showSelectAll &&
      Object.keys(items).some(
        (option) => Array.isArray(value) && !value.includes(option)
      ),
    [multiple, showSelectAll, items, value]
  );
  const canClearAll = useMemo(
    () => multiple && showSelectAll && Array.isArray(value) && value.length > 0,
    [multiple, showSelectAll, items, value]
  );

  const { classes, cx } = useStyles({
    error,
    multiple,
    canSelectAll,
    canClearAll,
  });
  const [inputValue, setInputValue] = useState<string>('');
  const textInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    const initialSelectedValues =
      textInputRef.current?.parentElement?.querySelector(
        '.MuiInputBase-root [data-tag-index="0"]'
      );

    if (textInputRef.current && initialSelectedValues) {
      textInputRef.current.placeholder = '';
    }
  }, []);

  useEffect(() => {
    if (!multiple) {
      if (isEmpty(value)) {
        setInputValue('');
      }
    }
  }, [value]);

  const arrow = 'arrow';
  const clear = 'clear';

  const [loading, _setLoading] = useState<boolean>(false);
  const [iconView, setIconView] = useState<string>(arrow);

  let setSearchTimeout: NodeJS.Timeout;

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      if (onSearch) {
        onSearch(val, name).finally(() => {
          if (clearValueOnSearch) {
            onChange(multiple ? [] : '', name);
          }
        });
      }
    }, 250);
  };

  const getItems = () => {
    return items;
  };

  const getCurrentValue = () => {
    if (!multiple) {
      return value || '';
    }
    return values || null;
  };

  const originalItemsKeys = sortByKey
    ? Object.keys(items).sort()
    : Object.keys(items);

  const hasSearchCallback = onSearch !== undefined;
  const allowClearResult = hasSearchCallback
    ? !loading && allowClear
    : allowClear;

  const getItemKeys = () => {
    return originalItemsKeys;
  };

  function renderOptionIcon(option: string) {
    return (
      icons !== undefined &&
      !isEmpty(icons[option]) &&
      !isEmpty(cdnUrl) && (
        <span>
          <img
            alt=""
            src={`${cdnUrl}/${icons[option]}`}
            className={classes.optionImage}
          />
        </span>
      )
    );
  }

  const visibleItems = getItems();
  const visibleItemKeys = getItemKeys();

  const renderItems = (
    option: string,
    optionProperties: any
  ): React.ReactNode => {
    return (
      <>
        <li {...optionProperties} key={visibleItems[option]}>
          <span className={classes.optionDesign}>
            {renderOptionIcon(option)}
            {visibleItems[option]}
          </span>
        </li>
      </>
    );
  };

  const renderClearButton = (selectedValues?: any) => {
    if (multiple && selectedValues && selectedValues.length > 0) {
      setIconView(clear);
    } else if (typeof selectedValues === 'string' && selectedValues) {
      setIconView(clear);
    } else {
      setIconView(arrow);
    }
  };

  const renderArrowButton = () => {
    setIconView(arrow);
  };

  const getActions = () => {
    if (!showSelectAll || !multiple) return <></>;
    return (
      <div className={classes.actionsContainer}>
        <button
          type="button"
          onClick={() => {
            if (multiple) {
              onChange(Object.keys(items || {}), name);
            }
          }}
          className={cx(classes.actionButton, classes.selectAllButton)}
        >
          Select All
        </button>
        <button
          type="button"
          onClick={() => {
            if (multiple) {
              onChange([], name);
            }
          }}
          className={cx(classes.actionButton, classes.clearAllButton)}
        >
          Clear All
        </button>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <>
        <FormControl
          error={!!error}
          disabled={!!disabled}
          className={classes.inputContainerStyles}
          size="small"
          variant="outlined"
        >
          <Autocomplete
            disableClearable={!allowClearResult}
            clearIcon={
              iconView === clear && multiple ? (
                <CloseIcon className={classes.closeIcon} />
              ) : (
                <ClearIcon className={classes.closeIcon} />
              )
            }
            onInputChange={(e, newInputValue, reason) => {
              setInputValue(newInputValue);
              if (isEmpty(newInputValue) && reason === 'input') {
                onChange(multiple ? [] : '', name);
                renderArrowButton();
              }

              if (reason === 'input') {
                delaySearch(newInputValue);
              }

              if (reason === 'clear') {
                renderArrowButton();
              }
            }}
            limitTags={maxTagCount}
            getLimitTagsText={(props) => (
              <div className={classes.chipMaxTagDesign}>+{props}</div>
            )}
            freeSolo={freeSolo}
            multiple={multiple}
            disabled={!!disabled}
            options={visibleItemKeys}
            filterOptions={(options, state) => {
              if (advancedFilter) {
                if (state.inputValue.length > 2) {
                  return options
                    .filter((option) =>
                      `${items[option as any]}`
                        .toLowerCase()
                        .trim()
                        .includes(state.inputValue.toLowerCase().trim())
                    )
                    .slice(0, 50);
                } else {
                  return [];
                }
              } else {
                return options
                  .filter((option) =>
                    `${items[option as any]}`
                      .toLowerCase()
                      .trim()
                      .includes(state.inputValue.toLowerCase().trim())
                  )
                  .slice(0, 50);
              }
            }}
            onChange={(event, newValue: any) => {
              if (newValue && newValue.length > 0 && textInputRef.current) {
                textInputRef.current.placeholder = '';
              } else if (textInputRef.current) {
                textInputRef.current.placeholder = placeholder || '';
              }

              if (
                textInputRef.current &&
                document.activeElement !== textInputRef.current
              ) {
                textInputRef.current.focus();
              }
              if (onChange) {
                onChange(newValue, name);
              }

              renderClearButton(newValue);
            }}
            onBlur={onBlur}
            value={getCurrentValue()}
            getOptionLabel={(option) => {
              if (!option) {
                return '';
              }
              return option && isEmpty(items[option as any])
                ? `${option}`
                : `${items[option as any]}`;
            }}
            selectOnFocus
            disableCloseOnSelect={multiple}
            renderOption={(props, option) => renderItems(`${option}`, props)}
            forcePopupIcon={true}
            // popupIcon={<ExpandMoreIcon className={classes.arrowIcon} />}
            renderTags={(value: readonly any[], getTagProps) => {
              return value.map((option: string, index: number) => (
                <Tooltip
                  key={option}
                  title={!isEmpty(items[option]) ? items[option] : option}
                >
                  {!isEmpty(items[option]) && (
                    <Chip
                      variant="filled"
                      label={!isEmpty(items[option]) ? items[option] : option}
                      {...getTagProps({ index })}
                      key={index}
                      className={classes.chipStyle}
                    />
                  )}
                </Tooltip>
              ));
            }}
            noOptionsText={
              advancedFilter
                ? inputValue.length < 3
                  ? 'Less than 3 characters'
                  : 'No results'
                : 'No results'
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                className={cx(
                  material ? classes.selectMaterial : classes.inputStyles,
                  error ? classes.selectError : undefined,
                  disabled ? classes.disabledInput : undefined
                )}
                variant="standard"
                disabled={!!disabled}
                inputRef={textInputRef}
                placeholder={!isEmpty(placeholder) ? placeholder : ''}
                InputProps={{
                  ...params.InputProps,
                  style: disabled
                    ? {
                        color: 'rgba(0, 0, 0, 0.5) !important',
                        opacity: 1,
                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.5) !important',
                        WebkitTextEmphasisColor:
                          'rgba(0, 0, 0, 0.5) !important',
                      }
                    : {},
                }}
                error={!!error}
              />
            )}
            loading={loading || loader}
            onMouseOver={() => renderClearButton(multiple ? values : value)}
            onFocus={() => renderClearButton(multiple ? values : value)}
          />
        </FormControl>
        {supportLink && (
          <div className={classes.supportLinkContainer}>
            <a
              href="#"
              style={{
                textDecoration: 'underline',
                color: 'rgba(211, 13, 43, 0.8)',
                fontSize: '14px',
                fontFamily: contentFontFamilyRegular,
              }}
              onClick={onLinkClick}
              onMouseEnter={(e) => {
                const target = e.target as HTMLElement;
                target.style.color = 'rgba(115, 0, 0, 0.8)';
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLElement;
                target.style.color = 'rgba(211, 13, 43, 0.8)';
              }}
            >
              {supportLink}
            </a>
          </div>
        )}
      </>
    );
  };

  return inputOnly ? (
    renderInput()
  ) : (
    <>
      <EnhancedInputsWrapper
        title={title || ''}
        description={description || ''}
        error={error || ''}
        name={name}
        customStyles={customStyles}
        actions={getActions()}
        hideError={hideError}
        hideHeader={hideWrapperHeader}
      >
        <div style={{ position: 'relative', width: '100%' }}>
          {renderInput()}
          {loader && <CircularProgress size={20} thickness={3} />}
        </div>
      </EnhancedInputsWrapper>
    </>
  );
};

export default NewChipsInput;
