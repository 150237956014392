import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { IEnhancedCommonProps, IEnhancedMenuItem } from '.';
import EnhancedButton from './EnhancedButton';

export interface IActionBarProps extends IEnhancedCommonProps {
  buttons?: JSX.Element;
  items?: IEnhancedMenuItem[];
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
  classes?: { container?: string; button?: string };
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: '0 0 0 0.5em',
  },
}));

const ActionBar: React.FC<IActionBarProps> = ({
  buttons,
  className,
  style,
  items = [],
  classes = {},
  color,
  backgroundColor,
}) => {
  const { classes: actionClasses } = useStyles();
  return (
    <section
      title={'Actions'}
      className={clsx(actionClasses.container, classes.container, className)}
      style={style}
    >
      {!!items &&
        items
          .filter((i) => !i.hidden)
          .map((item) => (
            <EnhancedButton
              key={item.title}
              className={clsx(actionClasses.button, classes.button)}
              disabled={item.disabled}
              color={color || (item.isSecondary && '#fff')}
              backgroundColor={backgroundColor || (item.isSecondary && '#000')}
              onClick={item.onClick}
              hasImage={item.icon ? true : false}
            >
              {item.icon ? (
                <img
                  src={item.icon}
                  alt={item.title}
                  style={{ height: '16px', width: '16px' }}
                />
              ) : (
                item.title
              )}
            </EnhancedButton>
          ))}
      {buttons}
    </section>
  );
};

export default ActionBar;
