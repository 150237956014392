import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import { enumListAsRecordObject } from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getPlanList.items.forEach(
    (obj: IGenericPlansTableData, i: number) => {
      const sublineName =
        obj.planConfigManagement_Plan_SublineID?.views?.PlanConfigManagement_all
          ?.properties?.Name;
      const lineName =
        obj.planConfigManagement_Plan_LineID?.views?.PlanConfigManagement_all
          ?.properties?.Name;
      list[i] = {
        planConfigManagement_Plan_Id: obj.planConfigManagement_Plan_Id,
        planConfigManagement_Plan_Name: obj.planConfigManagement_Plan_Name,
        planConfigManagement_Plan_SublineID: sublineName,
        planConfigManagement_Plan_LineID: lineName,
        planConfigManagement_Plan_ExternalCode:
          obj.planConfigManagement_Plan_ExternalCode,
        planConfigManagement_Plan_NameArabic:
          obj.planConfigManagement_Plan_NameArabic,
        planConfigManagement_Plan_EffectiveFrom:
          obj.planConfigManagement_Plan_EffectiveFrom,
        planConfigManagement_Plan_EffectiveTo:
          obj.planConfigManagement_Plan_EffectiveTo,
        planConfigManagement_Plan_createdOn:
          obj.planConfigManagement_Plan_createdOn,
        planConfigManagement_Plan_modifiedOn:
          obj.planConfigManagement_Plan_modifiedOn,
        planConfigManagement_Plan_PlanStatus: capitalizeFirstLetter(
          obj.planConfigManagement_Plan_PlanStatus?.replaceAll('_', ' ')
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.PlanConfigManagement.queries.getPlanList?.paging?.pageSize,
    pageNumber:
      data.PlanConfigManagement.queries.getPlanList?.paging?.pageNumber,
    totalCount:
      data.PlanConfigManagement.queries.getPlanList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    line: {},
    subline: {},
    status: {},
  };

  data?.PlanConfigManagement?.queries?.allLines?.forEach(
    (obj: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lovs.line[obj.planConfigManagement_Line_Id] =
        obj.planConfigManagement_Line_Name;
    }
  );

  data?.PlanConfigManagement?.queries?.allSublines?.forEach(
    (obj: {
      planConfigManagement_Subline_Id: string;
      planConfigManagement_Subline_Name: string;
    }) => {
      lovs.subline[obj.planConfigManagement_Subline_Id] =
        obj.planConfigManagement_Subline_Name;
    }
  );

  lovs.status = enumListAsRecordObject(
    data?.PlanConfigManagement_PlanStatuses?.enumValues
  );

  return lovs;
}
