import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query LIST_EXPATPOLICIES(
      $currentPage: Int!
      $currentPageSize: Int!
      $agencyId: [String]
      $effectiveDateFrom: Date
      $effectiveDateTo: Date
      $issueDateFrom: Date
      $issueDateTo: Date
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
      $agentType: Insurance_AgentTypes
    ) {
      Insurance {
        queries {
          listDetailsExpatPolicy(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            AgencyID: $agencyId
            EffectiveDateFrom: $effectiveDateFrom
            EffectiveDateTo: $effectiveDateTo
            IssueFrom: $issueDateFrom
            IssueTo: $issueDateTo
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
            AgentType: $agentType
          ) {
            paging {
              pageSize
              pageSize
              totalCount
            }
            items {
              insurance_Policy_Id
              insuranceCustomer_FullName
              insurance_Policy_FullPolicyNumber
              insurance_Policy_LineOfBusiness {
                Code
                Title
              }
              insuranceAgency_AgencyName
              insuranceAgent_FirstName
              insuranceAgent_LastName
              insurance_Policy_TotalAnnualPremium
              insurance_Policy_PolicyStatus {
                Code
                Title
              }
              insurance_Policy_PolicyEffectiveDate
              insurance_Policy_PolicyExpiryDate
              insurance_Policy_createdOn
              insurance_Policy_modifiedOn
              insuranceCustomer_FullName
              insurance_Policy_PolicyIssueDate
              insurance_Policy_FullName
              insurance_Policy_AssignedAgencyID {
                id
                views {
                  Insurance_all {
                    properties {
                      Id
                      AgencyName
                    }
                  }
                }
              }
              insurance_Policy_AssignedAgentID {
                id
                views {
                  Insurance_all {
                    properties {
                      FirstName
                      LastName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function filterLovsQuery() {
  return gql`
    query EXPATPOLICY_LISTING_LOVS {
      Insurance {
        queries {
          allAgencies {
            insurance_Agency_Id
            insurance_Agency_AgencyName
          }
        }
        lookups {
          products(Insurance_LinesOfBusiness: "Expat") {
            Id
            Code
            Title
          }
        }
      }
    }
  `;
}

export function exportExpatPolicies() {
  return gql`
    query exportExpatPolicies(
      $effectiveDateFrom: Date
      $effectiveDateTo: Date
      $issueFrom: Date
      $issueTo: Date
      $products: [String]
      $lineOfBusiness: String!
    ) {
      Insurance {
        queries {
          ExportPolicies(
            EffectiveDateFrom: $effectiveDateFrom
            EffectiveDateTo: $effectiveDateTo
            IssueFrom: $issueFrom
            IssueTo: $issueTo
            Products: $products
            LineOfBusiness: $lineOfBusiness
          ) {
            insurance_Policy_FullPolicyNumber
            insurance_Policy_modifiedOn
            insurance_Policy_AgencyCommission
            insurance_Policy_LineOfBusiness {
              Title
            }
            insurancePolicyExpatDetails_Id
            insurancePolicyExpatDetails_Product {
              Title
            }
            insurancePolicyExpatDetails_InsuredName
            insurancePolicyExpatDetails_InsuredFirstName
            insurancePolicyExpatDetails_InsuredLastName
            insurancePolicyExpatDetails_InsuredMiddleName
            insurancePolicyExpatDetails_InsuredDateOfBirth
            insurancePolicyExpatDetails_InsuredAge
            insurancePolicyExpatDetails_InsuredNationality {
              Title
            }
            insurancePolicyExpatDetails_InsuredGender
            insurancePolicyExpatDetails_InsuredPassportNumber
            insurancePolicyExpatDetails_InsuredOccupation {
              Title
            }
            insuranceCustomer_FullName
            insuranceAgency_AgencyName
            insuranceAgent_FirstName
            insuranceAgent_LastName
            insurance_Policy_PolicyStatus {
              Title
            }
            insurance_Policy_PolicyEffectiveDate
            insurance_Policy_PolicyExpiryDate
            insurance_Policy_TotalAnnualPremium
            insurance_Policy_PolicyIssueDate
            insurance_Policy_createdOn
          }
        }
      }
    }
  `;
}

export function getExpatAgentInfo() {
  return gql`
    query getExpatAgentInfo($id: String!) {
      Insurance {
        entities {
          agent(id: $id) {
            views {
              Insurance_all {
                properties {
                  CanViewCommission
                }
              }
            }
          }
        }
      }
    }
  `;
}
