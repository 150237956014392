import _ from 'lodash';
import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import EnhancedChipInput from '../../../components/enhanced-form/EnhancedChipInput';
import EnhancedInput from '../../../components/enhanced-form/EnhancedInput';
import { IProposalCover } from './form';
import {
  IProposalExpatDetails,
  IProposalPageFormState,
} from '../../../modules/production/proposal/page/expat/form';
import { validateExpatDetailsField } from '../../../modules/production/proposal/page/expat/validation';
import { IProposalDetailsSummary } from '../../../modules/production/proposal/page/expat/index2';

interface IProposalExpatDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  defaultPlanCoversList: Record<string, IProposalCover>;
  allowPlanCoverEdits: boolean;
  status: string;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  section: {
    padding: '23px 0 25px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 23.5%)',
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // alignContent: "center",
  },
  fullFieldRow: {
    gridColumn: '1 / -1', // Span across all columns
    width: '100%', // Ensure it takes the full width available
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const ProposalExpatDetailsWidget: React.FC<
  IProposalExpatDetailsWidgetProps
> = ({ pageState, onPageStateUpdate, disabledForm, data, lovs }) => {
  const { classes } = useStyles();
  // const params = useParams();

  const values = pageState.values.expatDetails;
  const errors = pageState.errors.expatDetails;
  const touched = pageState.touched.expatDetails;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const initialize = async () => {};

  useEffect(() => {
    initialize();
  }, []);

  const onFieldBlur = async (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.expatDetails[fieldName] = true;

    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IProposalExpatDetails,
    value: any,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);

    (newPageState.values.expatDetails as any)[fieldName] = value;

    (newPageState.errors.expatDetails as any)[fieldName] =
      validateExpatDetailsField(fieldName, value);

    if (touched) {
      newPageState.touched.costs[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Expat Details"
        hasTitleSpecificDesign={false}
        classes={{
          container: classes.section,
        }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <EnhancedChipInput
            key="medicalClass"
            name="medicalClass"
            title="Medical Class*"
            placeholder="Medical Class"
            value={values.medicalClass}
            error={touched.medicalClass ? errors.medicalClass : ''}
            onChange={(v) => onFieldUpdate('medicalClass', v, true)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            // customStyles={input.customStyles}
            selectOptions={lovs.medicalClasses}
            required={true}
            // freeSolo={input.freeSolo}
            multiple={false}
            material={true}
            // loader={loadingFields[input.name] || false}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />
          <EnhancedChipInput
            key="emergency"
            name="emergency"
            title="Emergency*"
            placeholder="Emergency"
            value={values.emergency}
            error={touched.emergency ? errors.emergency : ''}
            onChange={(v) => onFieldUpdate('emergency', v, true)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            // customStyles={input.customStyles}
            selectOptions={lovs.emergencyOptions}
            required={true}
            // freeSolo={input.freeSolo}
            multiple={false}
            material={true}
            // loader={loadingFields[input.name] || false}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />
          <EnhancedChipInput
            key="continuity"
            name="continuity"
            title="Continuity"
            placeholder="Continuity"
            value={values.continuity}
            error={touched.usageType ? errors.continuity : ''}
            onChange={(v) => onFieldUpdate('continuity', v, true)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            // customStyles={input.customStyles}
            selectOptions={lovs.continuityOptions}
            required={true}
            // freeSolo={input.freeSolo}
            multiple={false}
            material={true}
            // loader={loadingFields[input.name] || false}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="continuityNumber"
            name="continuityNumber"
            title="Continuity Number"
            type={'text'}
            placeholder="Continuity Number"
            value={values.continuityNumber}
            error={touched.continuityNumber ? errors.continuityNumber : ''}
            onBlur={(v) => onFieldBlur('continuityNumber')}
            onChange={(v) => onFieldUpdate('continuityNumber', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="exclusion"
            name="exclusion"
            title="Exclusion"
            placeholder="Exclusion"
            value={values.exclusion}
            error={touched.exclusion ? errors.exclusion : ''}
            onBlur={(v) => onFieldBlur('exclusion')}
            onChange={(v) => onFieldUpdate('exclusion', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            type={''}
            className={classes.fullFieldRow}
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="remarks"
            name="remarks"
            title="Remarks"
            placeholder="Remarks"
            value={values.remarks}
            error={touched.remarks ? errors.remarks : ''}
            onBlur={(v) => onFieldBlur('remarks')}
            onChange={(v) => onFieldUpdate('remarks', v.target.value)}
            disabled={
              ['CLOSED_WON, CLOSED_LOST'].includes(data.Status) || disabledForm
            }
            type={''}
            className={classes.fullFieldRow}
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />
        </div>
      </WidgetSection>
      {/* <button style={{ display: "none" }} onClick={() => validateForm()} >Submit</button> */}
    </WidgetPaper>
  );
};

export default ProposalExpatDetailsWidget;
