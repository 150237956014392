import { gql } from '@apollo/client';

export function getBusinessPartnerDetailsInfo() {
  return gql`
    query getBusinessPartnerDetailsInfo($id: String!) {
      SalesforceManagement {
        entities {
          businessPartner(id: $id) {
            queries {
              salesforceManagement {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
            views {
              SalesforceManagement_all {
                properties {
                  Code
                  Status
                  Type {
                    Id
                    Title
                  }
                  TaxAccount
                  Account
                  RegisterNumber
                  IsRegistered
                  IsLocal
                  IsCompany
                  AllowAccessToAllInsured
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                  MaxCreditLimitAllowedCurrency {
                    Id
                    Title
                  }
                  MaxCreditLimitAllowed
                  Category {
                    Id
                    Title
                  }
                  RelatedCompany {
                    Id
                    Title
                  }
                  PersonID {
                    id
                    views {
                      SalesforceManagement_all {
                        properties {
                          FullName
                          Email
                          PersonCode
                          DateOfBirth
                          PhoneDetails {
                            PhoneType
                            PhoneNumber
                          }
                          IsTaxable
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function activateBusinessPartner() {
  return gql`
    mutation activateBusinessPartner($entityId: String!) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              activateBusinessPartner(entityId: $entityId) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function deactivateBusinessPartner() {
  return gql`
    mutation deactivateBusinessPartner($entityId: String!) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              deactivateBusinessPartner(entityId: $entityId) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getBusinessPartnerLinesList() {
  return gql`
    query getBusinessPartnerLinesList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedBusinessPartnerID: String
      $SelectedLineId: String
      $SelectedStatuses: [SalesforceManagement_BusinessPartnerLineStatuses]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      SalesforceManagement {
        queries {
          getBusinessPartnerLinesList(
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            SelectedLineId: $SelectedLineId
            SelectedStatuses: $SelectedStatuses
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_BusinessPartnerLine_Id
              salesforceManagement_BusinessPartnerLine_Status
              salesforceManagement_BusinessPartnerLine_BusinessPartnerID {
                id
              }
              salesforceManagement_BusinessPartnerLine_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      Name
                      Status
                    }
                  }
                }
              }
              salesforceManagement_BusinessPartnerLine_LineCommissionType
              salesforceManagement_BusinessPartnerLine_ValueCurrency {
                Id
                Title
              }
              salesforceManagement_BusinessPartnerLine_LineCommissionRate
              salesforceManagement_BusinessPartnerLine_LineCommissionValue
              salesforceManagement_BusinessPartnerLine_ApplicableOn {
                Id
                Title
              }
              salesforceManagement_BusinessPartnerLine_CreditLimit
              salesforceManagement_BusinessPartnerLine_CreditLimitCurrency {
                Id
                Title
              }
              salesforceManagement_BusinessPartnerLine_GracePeriod
              salesforceManagement_BusinessPartnerLine_Status
              salesforceManagement_BusinessPartnerLine_createdOn
              salesforceManagement_BusinessPartnerLine_modifiedOn
            }
          }
        }
      }

      SalesforceManagement_BusinessPartnerStatuses: __type(
        name: "SalesforceManagement_BusinessPartnerStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function getLineDetailsInfo() {
  return gql`
    query getLineDetailsInfo($id: String!) {
      PlanConfigManagement {
        entities {
          line(id: $id) {
            views {
              PlanConfigManagement_all {
                properties {
                  Name
                  Status
                  ExternalCode
                  Abbreviation
                  NameArabic
                  createdBy
                  createdOn
                  modifiedBy
                  modifiedOn
                }
              }
            }
            queries {
              planConfigManagement {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function activateBusinessPartnerLine() {
  return gql`
    mutation activateBusinessPartnerLine($entityId: String!) {
      salesforceManagement {
        entities {
          businessPartnerLine {
            salesforceManagement {
              activateBusinessPartnerLine(entityId: $entityId) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function deactivateBusinessPartnerLine() {
  return gql`
    mutation deactivateBusinessPartnerLine($entityId: String!) {
      salesforceManagement {
        entities {
          businessPartnerLine {
            salesforceManagement {
              deactivateBusinessPartnerLine(entityId: $entityId) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getBusinessPartnerPlanList() {
  return gql`
    query getBusinessPartnerPlanList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedBusinessPartnerID: String
      $selectedBusinessPartnerLineID: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      SalesforceManagement {
        queries {
          getBusinessPartnerPlanList(
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            selectedBusinessPartnerLineID: $selectedBusinessPartnerLineID
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_BusinessPartnerPlan_Id

              salesforceManagement_BusinessPartnerPlan_BusinessPartnerID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }

              salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                      LineCommissionType
                      LineCommissionValue
                      ValueCurrency {
                        Id
                        Code
                        Title
                      }
                      LineCommissionRate
                      ApplicableOn {
                        Id
                        Code
                        Title
                      }
                    }
                  }
                }
              }

              line_ExternalCode
              line_Name
              line_Status
              plan_ExternalCode
              plan_Name
              plan_PlanStatus

              salesforceManagement_BusinessPartnerPlan_PlanCommissionType
              salesforceManagement_BusinessPartnerPlan_PlanCommissionValue
              salesforceManagement_BusinessPartnerPlan_ValueCurrency {
                Code
                Title
              }
              salesforceManagement_BusinessPartnerPlan_PlanCommissionRate

              salesforceManagement_BusinessPartnerPlan_ApplicableOn {
                Code
                Title
              }
              salesforceManagement_BusinessPartnerPlan_ActiveFrom
              salesforceManagement_BusinessPartnerPlan_ActiveTo
              salesforceManagement_BusinessPartnerPlan_Status
              salesforceManagement_BusinessPartnerPlan_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      Status
                    }
                  }
                }
              }
              salesforceManagement_BusinessPartnerPlan_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      PlanStatus
                      SpecialCommissionApplicable
                      SpecialCommissionType
                      SpecialCommissionValue
                      SpecialCommissionValueCurrency {
                        Id
                        Code
                        Title
                      }
                      SpecialCommissionRate
                      SpecialCommissionApplicableOn {
                        Id
                        Code
                        Title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      SalesforceManagement_BusinessPartnerStatuses: __type(
        name: "SalesforceManagement_BusinessPartnerStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function getBusinessPartnerLinesListWithoutPagination() {
  return gql`
    query getBusinessPartnerLinesListWithoutPagination(
      $selectedBusinessPartnerID: String
    ) {
      SalesforceManagement {
        queries {
          getBusinessPartnerLinesListWithoutPagination(
            selectedBusinessPartnerID: $selectedBusinessPartnerID
          ) {
            salesforceManagement_BusinessPartnerLine_Id
            salesforceManagement_BusinessPartnerLine_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getBusinessPartnerVignetteRangeList() {
  return gql`
    query getBusinessPartnerVignetteRangeList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedBusinessPartnerID: String!
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      SalesforceManagement {
        queries {
          getBusinessPartnerVignetteRange(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              totalCount
            }
            items {
              salesforceManagement_BusinessPartnerVignetteRange_Id
              salesforceManagement_BusinessPartnerVignetteRange_BusinessPartnerID {
                id
              }
              salesforceManagement_BusinessPartnerVignetteRange_createdOn
              salesforceManagement_BusinessPartnerVignetteRange_createdBy
              salesforceManagement_BusinessPartnerVignetteRange_modifiedOn
              salesforceManagement_BusinessPartnerVignetteRange_modifiedBy
              salesforceManagement_BusinessPartnerVignetteRange_RangeFrom
              salesforceManagement_BusinessPartnerVignetteRange_RangeTo
              salesforceManagement_BusinessPartnerVignetteRange_RangeStatus
              salesforceManagement_BusinessPartnerVignetteRange_RangeYear
              salesforceManagement_BusinessPartnerVignetteRange_RangeCode
            }
          }
        }
      }
    }
  `;
}

export function activateBusinessPartnerPlan() {
  return gql`
    mutation activateBusinessPartnerPlan(
      $entityId: String!
      $selectedBusinessPartnerPlanIds: [String!]!
    ) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              activateBusinessPartnerPlan(
                entityId: $entityId
                selectedBusinessPartnerPlanIds: $selectedBusinessPartnerPlanIds
              )
            }
          }
        }
      }
    }
  `;
}

export function deactivateBusinessPartnerPlan() {
  return gql`
    mutation deactivateBusinessPartnerPlan(
      $entityId: String!
      $selectedBusinessPartnerPlanIds: [String!]!
    ) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              deactivateBusinessPartnerPlan(
                entityId: $entityId
                selectedBusinessPartnerPlanIds: $selectedBusinessPartnerPlanIds
              )
            }
          }
        }
      }
    }
  `;
}

export function getPolicyBillsThroughBusinessPartnerQuery() {
  return gql`
    query getPolicyBillsThroughBusinessPartner(
      $businessPartnerId: String!
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      Accounting {
        queries {
          GetPolicyBillsThroughBusinessPartner(
            BusinessPartnerID: $businessPartnerId
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            items {
              businessPartnerPolicy_Id
              businessPartnerPolicy_PolicyName
              businessPartnerPolicy_PolicyNumber
              receivableBills_GrossPremium
              receivableBills_NetPremium
              receivableBills_TotalPremium
              receivableBills_OutstandingBalance
              payableBill_TotalCommission
              payableBill_TaxOnCommission
              receivableBills_TotalCommission
              receivableBills_TaxOnCommission
              receivablePayment_AmountReceived
              payablePayment_AmountReceived
              payableBill_OutstandingBalance
              businessPartnerPolicy_PolicyCurrency {
                Symbol
              }
            }
            paging {
              pageSize
              pageNumber
              totalCount
            }
          }
        }
      }
    }
  `;
}
