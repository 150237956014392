import { Drawer, ModalProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import GenericDrawerHeader from './GenericDrawerHeader';

interface IGenericDrawersProps {
  onClose?: () => void;
  isOpen: boolean;
  anchor?: 'left' | 'top' | 'right' | 'bottom';
  children: JSX.Element;
  title: string;
}

const useStyles = makeStyles()(() => ({
  paperAnchorBottom: {
    width: 450,
    minWidth: 300,
    maxHeight: '100%',
    overflowY: 'auto',
    left: 'unset !important',
    right: '!important',
    '@media only screen and (min-height: 400px)': {
      minHeight: 400,
    },
  },
  widgetsSection: {
    padding: '20px 15px',
    overflowY: 'auto',
  },
}));

const GenericDrawer: React.FC<IGenericDrawersProps> = ({
  isOpen,
  title,
  onClose,
  anchor = 'bottom',
  children,
}) => {
  const { classes } = useStyles();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.body.style.overflow = 'auto';
      }, 0);
    }
  }, [open]);

  const modalProps: ModalProps = {
    open: true,
    children: <div></div>,
    BackdropProps: {
      invisible: false,
      onClick: () => undefined,
    },
  };

  return (
    <Drawer
      open={isOpen}
      anchor={anchor}
      onClose={onClose}
      classes={{ paperAnchorBottom: classes.paperAnchorBottom }}
      ModalProps={modalProps}
    >
      <GenericDrawerHeader
        background={'#D30D2B'}
        title={title}
        onClose={onClose}
      />
      <section className={classes.widgetsSection}>{children}</section>
    </Drawer>
  );
};

export default GenericDrawer;
