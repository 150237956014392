import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query LIST_HEALTHAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $assignedAgencyID: [String]
      $product: [String]
      $effectiveDateFrom: Date
      $effectiveDateTo: Date
      $createdDateFrom: Date
      $createdDateTo: Date
    ) {
      Insurance {
        queries {
          listApplicationDetailsHealth(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            AssignedAgencyID: $assignedAgencyID
            Products: $product
            EffectiveDateFrom: $effectiveDateFrom
            EffectiveDateTo: $effectiveDateTo
            CreatedOnFrom: $createdDateFrom
            CreatedOnTo: $createdDateTo
          ) {
            paging {
              pageSize
              pageSize
              totalCount
            }
            items {
              insurance_Application_Id
              insurance_Application_ApplicationName
              insuranceCustomer_FullName
              insuranceAgency_AgencyName
              insuranceAgent_FirstName
              insuranceAgent_LastName
              insurance_Application_EffectiveDate
              insurance_Application_ExpiryDate
              insurance_Application_createdOn
              insurance_Application_modifiedOn
              insurance_Application_Status
              insurance_Application_PlanCount
            }
          }
        }
        actions {
          able_To_CreateApplicationAsAgent
          able_To_CreateApplicationAsUnderwriter
        }
      }
    }
  `;
}

export function filterLovsQuery() {
  return gql`
    query HEALTH_APPLICATIONS_LISTING_LOVS {
      Insurance {
        queries {
          allAgencies {
            insurance_Agency_Id
            insurance_Agency_AgencyName
          }
        }
        lookups {
          products(Insurance_LinesOfBusiness: "Health") {
            Id
            Code
            Title
          }
        }
      }
    }
  `;
}

export function planDetailsInHealthQuery() {
  return gql(/* GraphQL */ `
    query healthApplication($id: String!) {
      Insurance {
        entities {
          application(id: $id) {
            queries {
              insurance {
                applicationQuotes {
                  insuranceQuote_ApplicationID {
                    id
                    __typename
                  }
                  insuranceQuote_Id
                  insuranceQuoteHealthDetails_Id
                  insuranceQuote_Product {
                    Code
                    Title
                    __typename
                  }
                  healthPlanInputs_StandardInHospitalClass {
                    Code
                    Title
                  }
                  healthPlanInputs_OptimumInHospitalClass {
                    Code
                    Title
                  }
                  healthPlanInputs_StandardAmbulatoryCoverage {
                    Code
                    Title
                  }
                  healthPlanInputs_OptimumAmbulatoryCoverage {
                    Code
                    Title
                  }
                  insuranceQuote_TotalAnnualPremium
                  insuranceQuote_Status
                  insuranceQuote_StatusReason

                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `);
}
