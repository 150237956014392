import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import LeftSideColumn from './components/shared/LeftSideStaticColumn';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import MainColumn from './components/MainColumn';
import { getBusinessPartnerInfo } from './queries';

const ReceivablesPayablesDetailsPage: React.FC = () => {
  const { id } = useParams();
  const { data } = useQuery(getBusinessPartnerInfo, {
    variables: { id },
  });

  const businessPartnerFullName =
    data?.SalesforceManagement.entities.businessPartner.views
      .SalesforceManagement_all.properties.PersonID.views
      .SalesforceManagement_all.properties.FullName;

  const LefSideComponent = (
    <LeftSideColumn title={businessPartnerFullName} fields={[]} />
  );

  return (
    <StaticLayout
      name={'Receivables & Payables'}
      leftChildren={LefSideComponent}
      mainChildren={<MainColumn mainEntityId={id} />}
    />
  );
};

export default ReceivablesPayablesDetailsPage;
