import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getInputs, initialValues } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { IMedicalPlanRatesDrawerProps } from '.';
import {
  createPlanSpecificMedical,
  fetchedClausesList,
  fetchedPolicyCoverList,
  getMedicalPlanRatesEnums,
  getPlanSpecificMedicalInfo,
  updatePlanSpecificMedical,
} from './queries';
import {
  LookupToList,
  extractClauses,
  extractPolicyCovers,
  graphqlToMedicalPlanRatesInfo,
} from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { isZeroValue } from '../../utils/validationUtils';
import _ from 'lodash';

const MedicalPlanRatesDrawer: React.FC<IMedicalPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  lineId,
  planCurrency,
  medicalPlanRateId,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [values, setValues] = useState<Record<string, any>>();

  const [lists, setLists] = useState<Record<string, Record<string, string>>>({
    policyCover: {},
    currencies: {},
    medicalPlanClasses: {},
    coNSSFOptions: {},
    medicalLevels: {},
    tpaApplicableOnOptions: {},
  });

  const [fetchedPolicyCoverListLazy] = useLazyQuery(fetchedPolicyCoverList());
  const [fetchedClausesLazy] = useLazyQuery(fetchedClausesList());

  const [medicalPlanRatesResultQuery] = useLazyQuery(
    getMedicalPlanRatesEnums()
  );

  const [medicalPlanRatesAction] = useMutation(
    medicalPlanRateId
      ? updatePlanSpecificMedical()
      : createPlanSpecificMedical()
  );

  const [medicalPlanRatesInfoQuery] = useLazyQuery(
    getPlanSpecificMedicalInfo()
  );

  const loadLovList = async () => {
    const data = await medicalPlanRatesResultQuery();
    const newMedicalPlanRatesEnums = LookupToList(data.data);
    const result: Record<string, Record<string, string>> = {
      currencies: newMedicalPlanRatesEnums['currencies'],
      medicalPlanClasses: newMedicalPlanRatesEnums['medicalPlanClasses'],
      coNSSFOptions: newMedicalPlanRatesEnums['coNSSFOptions'],
      tpaApplicableOnOptions:
        newMedicalPlanRatesEnums['tpaApplicableOnOptions'],
      medicalLevels: newMedicalPlanRatesEnums['medicalLevels'],
      policyCover: {},
      clauseReference: {},
    };

    return result;
  };

  const getEntityInfo = async () => {
    if (medicalPlanRateId) {
      const apiResult = await medicalPlanRatesInfoQuery({
        variables: { id: medicalPlanRateId },
      });
      if (apiResult.data) {
        const medicalPlanRateEntity = graphqlToMedicalPlanRatesInfo(
          apiResult.data
        );
        return medicalPlanRateEntity;
      }
    }

    return null;
  };

  const getPolicyCoverList = async (inputValue: string) => {
    if (inputValue !== null && inputValue.length >= 3) {
      const data = await fetchedPolicyCoverListLazy({
        variables: {
          selectedLineIds: [lineId],
          searchKeyword: inputValue,
        },
      });

      if (data.data) {
        const extractedFetchedPolicyCovers = extractPolicyCovers(data.data);

        return extractedFetchedPolicyCovers;
      }
    }

    return null;
  };

  const getClauseList = async (inputValue: any, newValues: any) => {
    if (inputValue !== null && inputValue.length >= 3) {
      const data = await fetchedClausesLazy({
        variables: {
          selectedLineIds: [lineId],
          searchKeyword: inputValue,
        },
      });

      if (data.data) {
        const extractedFetchedPolicyCovers = extractClauses(data.data);

        return extractedFetchedPolicyCovers;
      }
    }
    return null;
  };

  const initialize = async () => {
    try {
      const [medicalPlanRatesEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      let newValues = initialValues;

      if (medicalPlanRateId) {
        if (
          medicalPlanRatesEntity.policyCover &&
          medicalPlanRatesEntity.policyCoverTitle
        ) {
          lovData.policyCover[medicalPlanRatesEntity.policyCover] =
            medicalPlanRatesEntity.policyCoverTitle;
        }

        if (
          medicalPlanRatesEntity.clauseReference &&
          medicalPlanRatesEntity.clauseReferenceTitle
        ) {
          lovData.clauseReference[medicalPlanRatesEntity.clauseReference] =
            medicalPlanRatesEntity.clauseReferenceTitle;
        }

        newValues = {
          planCurrency: planCurrency,
          policyCover: medicalPlanRatesEntity.policyCover,
          isMain: medicalPlanRatesEntity.isMain,
          isMandatory: medicalPlanRatesEntity.isMandatory,
          isEditable: medicalPlanRatesEntity.isEditable,
          nbOfAdherent: medicalPlanRatesEntity.nbOfAdherent,
          class: medicalPlanRatesEntity.class,
          ageFrom: medicalPlanRatesEntity.ageFrom,
          ageTo: medicalPlanRatesEntity.ageTo,
          coNssf: medicalPlanRatesEntity.coNssf,
          level: medicalPlanRatesEntity.level,
          sumInsured: medicalPlanRatesEntity.sumInsured,
          sumInsuredIsAdditive: medicalPlanRatesEntity.sumInsuredIsAdditive,
          netPremium: medicalPlanRatesEntity.netPremium,
          tpaFeesPercentage: medicalPlanRatesEntity.tpaFeesPercentage,
          tpaFeesApplicableOn: medicalPlanRatesEntity.tpaFeesApplicableOn,
          minTPAFees: medicalPlanRatesEntity.minTPAFees,
          maxTPAFees: medicalPlanRatesEntity.maxTPAFees,
          excessOnClaimType: medicalPlanRatesEntity.excessOnClaimType,
          excessOnClaimAmount: medicalPlanRatesEntity.excessOnClaimAmount,
          excessOnClaimPercentage:
            medicalPlanRatesEntity.excessOnClaimPercentage,
          excessOnClaimDays: medicalPlanRatesEntity.excessOnClaimDays,
          clauseReference: medicalPlanRatesEntity.clauseReference,
        };
      }

      setValues(newValues);
      setLists(lovData);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const onCustomChange = async (
    fieldName: string,
    value: any,
    allValues: Record<string, any>
  ) => {
    setValues(allValues);
  };

  const onSearch = async (fieldName: string, value: string) => {
    const newLovs = _.cloneDeep(lists);
    if (fieldName === 'policyCover') {
      newLovs.policyCover =
        (await getPolicyCoverList(value)) || lists.policyCover;
    } else if (fieldName === 'clauseReference') {
      await getClauseList(value, {});
      newLovs.clauseReference =
        (await getClauseList(value, {})) || lists.clauseReference;
    }
    setLists({
      ...lists,
      policyCover: newLovs.policyCover,
      clauseReference: newLovs.clauseReference,
    });
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      getInputs(undefined, lists, planCurrency),
      values
    );

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      let variablesMutation = {};
      variablesMutation = {
        planSpecificMedicalInputs: {
          planID: planId,
          ageFrom: Number(data?.ageFrom),
          ageTo: Number(data?.ageTo),
          class: data?.class,
          coNssf: data?.coNssf,
          nbOfAdherent: isZeroValue(data?.nbOfAdherent)
            ? 0
            : Number(data?.nbOfAdherent) || null,
          policyCoverID: values.policyCover || '',
          netPremium: Number(data?.netPremium),
          maxTPAFees: Number(data?.maxTPAFees),
          minTPAFees: Number(data?.minTPAFees),
          tpaFeesPercentage: Number(data?.tpaFeesPercentage) / 100,
          tpaApplicableOn: data?.tpaFeesApplicableOn || null,
          isMain: data?.isMain,
          isMandatory: data?.isMandatory,
          isEditable: data?.isEditable,
          level: data?.level,
          sumInsured: Number(data?.sumInsured),
          sumInsuredIsAdditive: data?.sumInsuredIsAdditive,
          excessOnClaimType: data?.excessOnClaimType,
          excessOnClaimAmount: Number(data?.excessOnClaimAmount),
          excessOnClaimPercentage: Number(data?.excessOnClaimPercentage) / 100,
          excessOnClaimDays: Number(data?.excessOnClaimDays),
          clauseID: data?.clauseReference || null,
        },
      };

      const res = await medicalPlanRatesAction({
        variables: medicalPlanRateId
          ? {
              ...variablesMutation,
              entityId: medicalPlanRateId,
            }
          : variablesMutation,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {medicalPlanRateId
              ? 'Plan Rate successfully updated.'
              : 'Plan Rate successfully added.'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        setFormDisabled(false);
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
      setFormDisabled(false);
    }
  };

  const inputForm = useMemo(() => {
    const result = getInputs(
      values || (initialValues as any),
      lists,
      planCurrency
    );
    return result;
  }, [values, lists]);

  return (
    <GenericDrawer
      title={medicalPlanRateId ? 'Modify Plan Rate' : 'Add Plan Rate'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={!!submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onChange={onCustomChange}
            onSearch={async (f, v) => {
              await onSearch(f, v);
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default MedicalPlanRatesDrawer;
