import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import SectionShell from './SectionShell';
import { IEnhancedCommonProps } from '..';
import { Tooltip } from '@mui/material';
import { MAIN_ONE_THEME, contentFontFamilyBold } from '../../constants';

interface IWidgetSectionProps extends IEnhancedCommonProps {
  actions?: JSX.Element; //TODO: each section must have it is own button
  middle?: JSX.Element;
  classes?: {
    header?: string;
    headerContainer?: string;
    container?: string;
    middle?: string;
    title?: string;
  };
  title?: string;
  hasTitleSpecificDesign?: boolean;
  specificTitleDesign?: JSX.Element;
  subtitle?: JSX.Element;
  useSeparator?: boolean;
  children?: React.ReactNode;
  showActionButton?: boolean;
  narrowSpacing?: boolean;
  isCollapsible?: boolean;
  collapsibleSectionTitle?: string;
}

const useStyles = makeStyles<{
  narrowSpacing: boolean;
}>()((theme, { narrowSpacing }) => ({
  sectionHeader: {
    display: 'flex',
    alignItems: narrowSpacing ? 'flex-end' : 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: narrowSpacing ? '0 0 0.3em 0' : '0 0 0.5em 0',
  },
  leftHeader: {
    margin: '0 0.5em 0 0',
  },
  sectionTitle: {
    display: 'block',
    minWidth: 'fit-content',
    // margin: '0 0 0.5em',
    fontFamily: contentFontFamilyBold,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg4.fontSize}px !important`,
  },
  subtitleContainer: {
    display: 'block',
    margin: '0.5em 0 0',
  },
  itemsContainer: {
    margin: '0 0 0.5em',
  },
}));

const WidgetSection: React.FC<IWidgetSectionProps> = ({
  actions,
  children,
  classes = {},
  middle,
  title,
  subtitle,
  useSeparator,
  className,
  style,
  showActionButton = true,
  narrowSpacing = false,
  hasTitleSpecificDesign = false,
  specificTitleDesign,
  isCollapsible = false,
  collapsibleSectionTitle = 'Widget',
}) => {
  const { classes: sectionClasses } = useStyles({ narrowSpacing });

  return (
    <SectionShell
      classes={{
        header: clsx(sectionClasses.sectionHeader, classes.headerContainer),
        container: classes.container,
      }}
      className={className}
      style={style}
      useSeparator={useSeparator}
      header={
        title ? (
          <>
            <div className={clsx(sectionClasses.leftHeader, classes.header)}>
              {title.length > 25 ? (
                <Tooltip title={title}>
                  <span
                    className={clsx(sectionClasses.sectionTitle, classes.title)}
                  >
                    {title}
                  </span>
                </Tooltip>
              ) : (
                <span
                  className={clsx(sectionClasses.sectionTitle, classes.title)}
                >
                  {title}
                </span>
              )}
              {!!subtitle && (
                <span className={sectionClasses.subtitleContainer}>
                  {subtitle}
                </span>
              )}
            </div>
            {middle && (
              <div
                className={clsx(sectionClasses.itemsContainer, classes.middle)}
              >
                {middle}
              </div>
            )}
            {actions && showActionButton && (
              <div className={sectionClasses.itemsContainer}>{actions}</div>
            )}
          </>
        ) : hasTitleSpecificDesign ? (
          <>
            <div className={clsx(sectionClasses.leftHeader, classes.header)}>
              <span
                className={clsx(sectionClasses.sectionTitle, classes.title)}
              >
                {specificTitleDesign}
              </span>
              {!!subtitle && (
                <span className={sectionClasses.subtitleContainer}>
                  {subtitle}
                </span>
              )}
            </div>
            {middle && (
              <div
                className={clsx(sectionClasses.itemsContainer, classes.middle)}
              >
                {middle}
              </div>
            )}
            {actions && showActionButton && (
              <div className={sectionClasses.itemsContainer}>{actions}</div>
            )}
          </>
        ) : undefined
      }
    >
      {children}
    </SectionShell>
  );
};

export default WidgetSection;
