/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Paper, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  paginationControls: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
  },
}));

interface CustomPaperProps {
  children: React.ReactNode;
  handlePageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
  className?: string;
}

const CustomPaper: React.FC<CustomPaperProps> = ({
  children,
  handlePageChange,
  currentPage,
  totalPages,
  className,
  ...other
}) => {
  const { classes } = useStyles();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    event.stopPropagation();
    event.preventDefault();
    handlePageChange(page);
  };

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Paper className={className} {...other}>
      {children}
      <div className={classes.paginationControls} onMouseDown={handleMouseDown}>
        <Button
          onClick={(event) => handleClick(event, 1)}
          disabled={currentPage === 1}
        >
          {'<'}
        </Button>
        {/* <Button onClick={(event) => handleClick(event, currentPage - 1)} disabled={currentPage === 1}>{'<'}</Button>
        <Button onClick={(event) => handleClick(event, currentPage + 1)} disabled={currentPage >= totalPages}>{'>'}</Button> */}
        <Button
          onClick={(event) => handleClick(event, totalPages)}
          disabled={currentPage >= totalPages}
        >
          {'>'}
        </Button>
      </div>
    </Paper>
  );
};

export default CustomPaper;
