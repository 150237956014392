import { gql } from '@apollo/client';

export function getClauseEnums() {
  return gql`
    query getClauseEnums {
      PlanConfigManagement_ClauseStatuses: __type(
        name: "PlanConfigManagement_ClauseStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
      PlanConfigManagement {
        queries {
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
          }
        }
      }
    }
  `;
}

export function checkClauseExists() {
  return gql`
    query checkClauseExists(
      $lineId: String
      $name: String
      $externalCode: String
      $clauseId: String
    ) {
      PlanConfigManagement {
        queries {
          checkClauseExists(
            lineId: $lineId
            clauseId: $clauseId
            name: $name
            externalCode: $externalCode
          ) {
            planConfigManagement_Clause_Id
          }
        }
      }
    }
  `;
}

export function createClause() {
  return gql`
    mutation createClause($clauseInputs: PlanConfigManagement_ClauseInputs!) {
      planConfigManagement {
        actions {
          createClause(clauseInputs: $clauseInputs) {
            id
          }
        }
      }
    }
  `;
}

export function getClauseInfo() {
  return gql`
    query getClauseInfo($id: String!) {
      PlanConfigManagement {
        entities {
          clause(id: $id) {
            views {
              PlanConfigManagement_all {
                properties {
                  ExternalCode
                  Name
                  Description
                  Status
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updateClause() {
  return gql`
    mutation updateClause(
      $entityId: String!
      $clauseInputs: PlanConfigManagement_ClauseInputs!
    ) {
      planConfigManagement {
        entities {
          clause {
            planConfigManagement {
              updateClause(entityId: $entityId, clauseInputs: $clauseInputs) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
