import { Backdrop, Dialog, DialogProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

export interface IEnhancedDialogProps extends DialogProps {
  blur?: boolean;
  dialogClassName?: string;
}

const useStyles = makeStyles()(() => ({
  modalDocument: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  backdrop: {
    '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
      background: 'rgba(250, 250, 250, 0.1) 0% 0% no-repeat padding-box',
      backdropFilter: 'blur(18px)',
    },
    '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))':
      {
        background: 'rgba(255, 255, 255, 0.9) 0% 0% no-repeat padding-box',
      },
  },
}));

const EnhancedDialog: React.FC<IEnhancedDialogProps> = ({
  open = false,
  children,
  onClose,
  blur = false,
  dialogClassName,
  ...props
}) => {
  const { classes } = useStyles();

  const backdrop = (
    <Backdrop
      open={open}
      classes={{
        root: classes.backdrop,
      }}
    />
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // hideBackdrop
      slots={{
        backdrop: blur ? () => backdrop : Backdrop,
      }}
      fullWidth
      transitionDuration={500}
      scroll="body"
      maxWidth="xl"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      disableEnforceFocus // to fix the issue of unfocusable fields when we have dialog inside dialog
    >
      <section className={clsx(classes.modalDocument, dialogClassName)}>
        {children}
      </section>
    </Dialog>
  );
};

export default EnhancedDialog;
