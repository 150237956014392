import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import EnhancedInput from '../../../components/enhanced-form/EnhancedInput';
import EnhancedChipInput from '../../../components/enhanced-form/EnhancedChipInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import _ from 'lodash';
import {
  IProposalPageFormState,
  IProposalRiskDetails,
} from '../../../modules/production/proposal/page/generic/form';
import { validateRiskDetailsField } from '../../../modules/production/proposal/page/generic/validation';
import { removeObjectAtIndex } from '../../../utils/helper-utils';
import EnhancedFormattedNumberInput from '../../../components/enhanced-form/EnhancedFormattedNumberInput';
import { Tabs, Tab, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const initialValues = {
  initialError: {
    riskDescription: '',
    geoLocation: '',
    location: '',
    planCoverID: '',
    riskRate: '',
    sumInsured: '',
    premium: '',
  },
  initialTouched: {
    riskDescription: false,
    geoLocation: false,
    location: false,
    planCoverID: false,
    riskRate: false,
    sumInsured: false,
    premium: false,
  },
};

interface IProposalRiskDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '20px 2px 10px 25px',
    marginBottom: '14px',
    position: 'relative',
  },
  section: {
    padding: '23px 0 25px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 23.5%)',
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  label: {
    color: '#000000',
    margin: '0px 0 -1.5px 0 !important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelDropdown: {
    color: '#000000',
    margin: '0px!important',
    marginTop: '4px!important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelNumber: {
    color: '#000000',
    margin: '0px!important',
    marginTop: '8px!important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  removeBtn: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  fullFieldRow: {
    gridColumn: '1 / -1',
    width: '100%',
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`risk-tabpanel-${index}`}
      aria-labelledby={`risk-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const ProposalPropertyDescriptionDetailsWidget: React.FC<
  IProposalRiskDetailsWidgetProps
> = ({ pageState, onPageStateUpdate, disabledForm, lovs }) => {
  const { classes } = useStyles();
  const values = pageState.values.riskDetails;
  const errors = pageState.errors.riskDetails;
  const touched = pageState.touched.riskDetails;

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const onFieldBlur = (
    fieldName: keyof IProposalRiskDetails,
    index: number
  ) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.riskDetails[index][fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IProposalRiskDetails,
    value: any,
    index: number,
    touchedField = false
  ) => {
    const newPageState = _.cloneDeep(pageState);
    (newPageState.values.riskDetails[index] as any)[fieldName] = value;
    newPageState.errors.riskDetails[index][fieldName] =
      validateRiskDetailsField(fieldName, value);

    if (touchedField) {
      newPageState.touched.riskDetails[index][fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  const renderRow = (row: IProposalRiskDetails, index: number) => {
    return (
      <div key={index} className={classes.container}>
        {values.length > 1 && !disabledForm && (
          <button
            className={classes.removeBtn}
            onClick={() => onRowDelete(index)}
            type="button"
            aria-label="Remove Risk Detail"
          >
            <ClearIcon fontSize="small" />
          </button>
        )}
        <div className={classes.fieldRow}>
          <EnhancedInput
            key={`riskDescription-${index}`}
            name={`riskDescription-${index}`}
            title="Risk Description*"
            placeholder="Risk Description"
            value={row.riskDescription}
            error={
              touched[index]?.riskDescription
                ? errors[index]?.riskDescription
                : ''
            }
            onBlur={() => onFieldBlur('riskDescription', index)}
            onChange={(e) =>
              onFieldUpdate('riskDescription', e.target.value, index, true)
            }
            disabled={disabledForm}
            type="text"
            material
            customStyles={{ labelStyles: classes.label }}
          />
          <EnhancedChipInput
            key={`geoLocation-${index}`}
            name={`geoLocation-${index}`}
            title="Geographical Location*"
            placeholder="Geographical Location"
            value={row.geoLocation}
            error={
              touched[index]?.geoLocation ? errors[index]?.geoLocation : ''
            }
            onChange={(v) => onFieldUpdate('geoLocation', v, index, true)}
            disabled={disabledForm}
            selectOptions={lovs.geoLocations}
            required
            multiple={false}
            material
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
          />
          <EnhancedInput
            key={`location-${index}`}
            name={`location-${index}`}
            title="Location"
            placeholder="Location"
            value={row.location}
            error={touched[index]?.location ? errors[index]?.location : ''}
            onBlur={() => onFieldBlur('location', index)}
            onChange={(e) =>
              onFieldUpdate('location', e.target.value, index, true)
            }
            disabled={disabledForm}
            type="textarea"
            material
            customStyles={{ labelStyles: classes.label }}
            className={classes.fullFieldRow}
          />
        </div>
        <WidgetSection title="Cover Distribution">
          <div className={classes.fieldRow}>
            <EnhancedChipInput
              key={`planCoverID-${index}`}
              name={`planCoverID-${index}`}
              title="Cover*"
              placeholder="Cover"
              value={row.planCoverID}
              error={
                touched[index]?.planCoverID ? errors[index]?.planCoverID : ''
              }
              onChange={(v) => onFieldUpdate('planCoverID', v, index, true)}
              disabled={disabledForm}
              selectOptions={lovs.planCovers}
              required
              multiple={false}
              material
              canClearSingleValueSelection={false}
              customStyles={{
                labelStyles: classes.labelDropdown,
              }}
            />
            <EnhancedFormattedNumberInput
              key={`riskRate-${index}`}
              name={`riskRate-${index}`}
              title="Fire Rate*"
              placeholder="Fire Rate"
              value={row.riskRate}
              error={touched[index]?.riskRate ? errors[index]?.riskRate : ''}
              onBlur={() => onFieldBlur('riskRate', index)}
              onChange={(e) =>
                onFieldUpdate(
                  'riskRate',
                  parseFloat(e.target.value),
                  index,
                  true
                )
              }
              disabled={disabledForm}
              material
              customStyles={{ labelStyles: classes.labelNumber }}
            />
            <EnhancedFormattedNumberInput
              key={`sumInsured-${index}`}
              name={`sumInsured-${index}`}
              title="Sum Insured"
              placeholder="Sum Insured"
              value={row.sumInsured}
              error={
                touched[index]?.sumInsured ? errors[index]?.sumInsured : ''
              }
              onBlur={() => onFieldBlur('sumInsured', index)}
              onChange={(e) =>
                onFieldUpdate(
                  'sumInsured',
                  parseFloat(e.target.value),
                  index,
                  true
                )
              }
              disabled={disabledForm}
              material
              customStyles={{ labelStyles: classes.labelNumber }}
            />
            <EnhancedFormattedNumberInput
              key={`premium-${index}`}
              name={`premium-${index}`}
              title="Premium"
              placeholder="Premium"
              value={row.premium}
              error={touched[index]?.premium ? errors[index]?.premium : ''}
              onBlur={() => onFieldBlur('premium', index)}
              onChange={(e) =>
                onFieldUpdate(
                  'premium',
                  parseFloat(e.target.value),
                  index,
                  true
                )
              }
              disabled={disabledForm}
              material
              customStyles={{ labelStyles: classes.labelNumber }}
            />
          </div>
        </WidgetSection>
      </div>
    );
  };

  const onRowAdd = () => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.riskDetails.push({
      riskDescription: '',
      geoLocation: '',
      location: '',
      planCoverID: '',
      riskRate: 0,
      sumInsured: 0,
      premium: 0,
    });

    newPageState.errors.riskDetails.push({ ...initialValues.initialError });
    newPageState.touched.riskDetails.push({ ...initialValues.initialTouched });
    onPageStateUpdate(newPageState);
    setSelectedTab(newPageState.values.riskDetails.length - 1);
  };

  const onRowDelete = (index: number) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.riskDetails = removeObjectAtIndex(
      newPageState.values.riskDetails,
      index
    );
    newPageState.errors.riskDetails = removeObjectAtIndex(
      newPageState.errors.riskDetails,
      index
    );
    newPageState.touched.riskDetails = removeObjectAtIndex(
      newPageState.touched.riskDetails,
      index
    );
    onPageStateUpdate(newPageState);

    if (selectedTab >= newPageState.values.riskDetails.length) {
      setSelectedTab(Math.max(0, newPageState.values.riskDetails.length - 1));
    }
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection title="Property Risk Details">
        <div className={classes.tabsContainer}>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            aria-label="Risk Details Tabs"
          >
            {values.map((_, index) => (
              <Tab
                key={index}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {`Risk ${index + 1}`}
                    {!disabledForm && values.length > 1 && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onRowDelete(index);
                        }}
                        aria-label="Remove Risk Detail"
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                }
              />
            ))}
          </Tabs>
          {!disabledForm && (
            <button className={classes.addBtn} onClick={onRowAdd}>
              <AddCircleOutlineIcon fontSize="small" />
            </button>
          )}
        </div>
        {values.map((riskDetail, index) => (
          <TabPanel value={selectedTab} index={index} key={index}>
            {renderRow(riskDetail, index)}
          </TabPanel>
        ))}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalPropertyDescriptionDetailsWidget;
