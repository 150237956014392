import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const additionalInfoEnums: Record<string, Record<string, string>> = {};

  additionalInfoEnums['genders'] = enumListAsRecordObject(
    data?.Insurance_Genders?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );
  additionalInfoEnums['maritalStatuses'] = enumListAsRecordObject(
    data?.Core_MaritalStatuses?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  additionalInfoEnums['countries'] = lookupListAsRecordObject(
    data.Insurance.lookups.countries
  );

  additionalInfoEnums['nationalities'] = lookupListAsRecordObject(
    data.Insurance.lookups.nationalities
  );

  return additionalInfoEnums;
}

export function graphqlEntityToAdditionalInfo(
  data: any
): IPersonAdditionalInformationInfo {
  return {
    gender:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.Gender,
    maritalStatus:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.MaritalStatus,
    fatherName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.FatherName,
    motherName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.MotherName,
    countryOfBirth:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.CountryOfBirth?.Id,
    cityOfBirth:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.CityOfBirth,
    nationalityID:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityID,
    passportNumber:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.PassportNumber,
    secondNationality:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityTwo?.Id,
  };
}
