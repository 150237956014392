import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const personAddressEnums: Record<string, Record<string, string>> = {};

  personAddressEnums['addressType'] = enumListAsRecordObject(
    data?.Core_AddressTypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  personAddressEnums['countries'] = lookupListAsRecordObject(
    data.Insurance.lookups.countries
  );

  return personAddressEnums;
}

export function graphqlEntityToPersonAddressInfo(
  data: any
): IPersonAddressInfo {
  const addressInfo =
    data?.SalesforceManagement?.entities?.personAddress?.views
      .SalesforceManagement_all?.properties;
  return {
    personId: '',
    personAddressId: '',
    addressType: addressInfo?.AddressType,
    country: addressInfo?.Address?.Country?.Title,
    countryId: addressInfo?.Address?.Country?.Id,
    zone: addressInfo?.Address?.Zone?.Title,
    city: addressInfo?.Address?.City,
    street: addressInfo?.Address?.Street,
    building: addressInfo?.Address?.Building,
    addressDetails: addressInfo?.Address?.AddressDetails,
    addressDetailsArabic: addressInfo?.Address?.AddressDetailsArabic,
    isPrimary: addressInfo?.IsPrimary,
  };
}
