import React from 'react';
import { IEnhancedCommonProps } from '../..';
import { IEnhancedMenuItem } from '../table';
import EnhancedMenu from './EnhancedMenu';

interface IActionMenuProps extends IEnhancedCommonProps {
  actions: IEnhancedMenuItem[];
  title: string;
}

const ActionMenu: React.FC<IActionMenuProps> = ({ actions, title }) => {
  return <EnhancedMenu title={title} items={actions}></EnhancedMenu>;
};

export default ActionMenu;
