/* eslint-disable @typescript-eslint/no-empty-function */
import { FormInputTypes } from '../../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  Accounting_Transactions_TransactionNumber: {
    name: 'Accounting_Transactions_TransactionNumber',
    title: 'Transaction #',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  Accounting_Transactions_ReferenceNumber: {
    name: 'Accounting_Transactions_ReferenceNumber',
    title: 'Reference #',
    type: EnhancedTableHeaderType.Text,
  },
  Accounting_Transactions_TransactionDate: {
    name: 'Accounting_Transactions_TransactionDate',
    title: 'Transaction Date',
    type: EnhancedTableHeaderType.Date,
  },
  Accounting_Transactions_TransactionType: {
    name: 'Accounting_Transactions_TransactionType',
    title: 'Transaction Type',
    type: EnhancedTableHeaderType.Text,
  },
  Accounting_Transactions_Description: {
    name: 'Accounting_Transactions_Description',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  Accounting_Transactions_createdOn: {
    name: 'Accounting_Transactions_createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  viewHistory: {
    name: 'viewHistory',
    title: ' ',
    buttonLabel: 'View History',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        company: {
          name: 'company',
          title: 'Company',
          type: FormInputTypes.newselect,
          value: filterValues?.company || null,
          placeholder: 'Company',
          triggerUpdate: true,
          selectOptions: { ...lovs.company },
        },
        account: {
          name: 'account',
          title: 'Account',
          type: FormInputTypes.newmultiselect,
          value: filterValues?.account || null,
          placeholder: 'Select an account',
          triggerUpdate: true,
          selectOptions: { ...lovs.account },
        },
        type: {
          name: 'type',
          title: 'Transaction Type',
          type: FormInputTypes.newmultiselect,
          value: filterValues?.type || null,
          placeholder: 'Select a transaction type',
          triggerUpdate: true,
          selectOptions: { ...lovs.type },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.newdaterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: 'New Transaction',
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
];
