import React from 'react';
import ThreeColumnLayout from '../three-column-layout/ThreeColumnLayout';
import { IThreeColumnLayout, IThreeColumnLayoutBase, LayoutType } from '..';
import PageLayoutLoader from '../PageLayoutLoader';
import { MAIN_ONE_THEME } from '../../constants';

interface IStaticLayoutProps {
  name: string;
  config?: IThreeColumnLayoutBase;
  leftChildren?: JSX.Element;
  rightChildren?: JSX.Element;
  mainChildren: JSX.Element;
  loading?: boolean;
  onRightCollapseClick?: (value: boolean) => void;
}

const StaticLayout: React.FC<IStaticLayoutProps> = ({
  name,
  loading = false,
  config = {
    leftColumn: {
      backgroundColor: '#FFFFFF',
      collapsable: false,
      collapsed: false,
      width: 2,
    },
    mainColumn: {
      backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
      collapsable: false,
      collapsed: false,
      width: 10,
    },
    rightColumn: {
      backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
      collapsable: false,
      collapsed: false,
      width: 0,
    },
  },
  leftChildren,
  rightChildren,
  mainChildren,
  onRightCollapseClick,
}) => {
  const layout: IThreeColumnLayout = {
    typeLogicalId: LayoutType.threecolumns,
    leftColumn: {
      ...config.leftColumn,
      backgroundColor: '#FFFFFF',
    },
    mainColumn: config.mainColumn,
    rightColumn: config.rightColumn,
  };

  const renderLayout = () => {
    return (
      <>
        <ThreeColumnLayout
          name={name}
          layout={layout}
          leftChildren={leftChildren}
          rightChildren={rightChildren}
          mainChildren={mainChildren}
          onRightCollapseClick={onRightCollapseClick}
        />
      </>
    );
  };

  return (
    <>
      <div style={{ zIndex: loading ? -1 : 'unset' }}>
        {/* {loading ? <></> : renderLayout()} */}
        {renderLayout()}
      </div>
      <PageLayoutLoader show={loading} />
    </>
  );
};

export default StaticLayout;
