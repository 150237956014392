import { IReceiptVouchersResponseData, IReceiptVouchersTableData } from '.';
import { formatDate } from '../../../../../../utils/formatting-utils';
import { isEmpty } from '../../../../../../utils/validationUtils';
import {
  IAccountResponseData,
  ITransactionResponseData,
  ITransactionTableData,
  IVoucherPopupResponseData,
  IVoucherPopupTableData,
} from '../shared/types';

export const mapToPageListingData = (
  data: IReceiptVouchersResponseData[],
  paymentMethods: Record<string, string>,
  currencies: Record<string, string>
) => {
  const list: Record<string, IReceiptVouchersTableData> = {};

  data.forEach((item: IReceiptVouchersResponseData) => {
    list[item.accounting_Payments_Id] = {
      paymentId: item.accounting_Payments_Id,
      paymentDate: formatDate(item.accounting_Payments_PaymentDate),
      paymentMethod: paymentMethods[item.accounting_Payments_PaymentMethod],
      referenceNumber: item.accounting_Payments_ReferenceNo,
      amountReceived: item.accounting_Payments_TotalAmountReceived,
      createdBy: item.accounting_Payments_CreatedByName,
      createdOn: item.accounting_Payments_createdOn,
      Currency: currencies[item.accounting_Payments_PaymentCurrency],
    };
  });

  return list;
};

export const mapToPopupListingData = (
  data: IVoucherPopupResponseData[],
  currenciesSymbols: Record<string, string>
) => {
  if (isEmpty(data)) {
    return {};
  }
  const list: Record<string, IVoucherPopupTableData> = {};
  const orderedData = data.sort((bill1, bill2) => {
    const compareA = bill1.PolicyNumber.localeCompare(bill2.PolicyNumber);
    if (compareA === 0) {
      return bill1.BillNumber.toString().localeCompare(
        bill2.BillNumber.toString()
      );
    } else {
      return compareA;
    }
  });

  orderedData?.forEach((item: IVoucherPopupResponseData, index: number) => {
    list[index] = {
      policyId: item.PolicyID,
      policyNum: item.PolicyNumber,
      billId: item.BillID,
      billNum: item.BillNumber,
      dueDate: item.DueDate,
      amountDue: item.AmountDue,
      amountOutstanding: item.AmountOutstanding,
      amountOutstandingCurrency: item.AmountOutstandingCurrency,
      amount: null,
      Currency: currenciesSymbols[item.BillCurrency],
    };
  });

  return list;
};

export const CurrencySymbolsAsRecordObject = (
  lookupList: { [key: string]: string }[]
): Record<string, string> => {
  const returnedObject: Record<string, string> = {};

  lookupList.forEach((element: { [key: string]: string }) => {
    returnedObject[element.Code] = element.Symbol;
  });

  return returnedObject;
};

export const mapToTransactionListingData = (
  data: ITransactionResponseData[]
) => {
  const transactions: ITransactionTableData[] = [];

  data.forEach((item: ITransactionResponseData) => {
    const hasAccount = item.AccountName !== '--';
    const hasDescription = item.Description !== '--';
    transactions.push({
      accountId: item.AccountID,
      accountName: hasAccount ? item.AccountName : '',
      accountNameDisabled: hasAccount,
      description: hasDescription ? item.Description : '',
      descriptionDisabled: hasDescription,
      debitOrCredit: item.DebitOrCredit,
      accountDebit: item.AccountDebit,
      accountCredit: item.AccountCredit,
      systemDebit: item.SystemDebit,
      systemCredit: item.SystemCredit,
      systemSecondaryDebit: item.SystemSecondaryDebit,
      systemSecondaryCredit: item.SystemSecondaryCredit,
      transactionCurrency: item.TransactionCurrency,
    });
  });

  const totalDebit = data.reduce(
    (total: number, currentItem: ITransactionResponseData) => {
      return total + Number(currentItem.SystemDebit);
    },
    0
  );

  const totalCredit = data.reduce(
    (total: number, currentItem: ITransactionResponseData) => {
      return total + Number(currentItem.SystemCredit);
    },
    0
  );

  return { totalDebit, totalCredit, transactions };
};

export const mapToAccoutsLov = (data: IAccountResponseData[]) => {
  const accounts: Record<string, string> = {};

  data.forEach((item: IAccountResponseData) => {
    accounts[item.accounting_ChartOfAccounts_Id] =
      item.accounting_ChartOfAccounts_AccountID +
      ' ' +
      item.accounting_ChartOfAccounts_AccountName;
  });

  return accounts;
};
