/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import EnhancedStepper from '../../components/common/EnhancedStepper';
import EntityInfoWidget from '../../components/widgets/entity-info/EntityInfoWidget';
import { entityView, steps } from './content';
import { makeStyles } from 'tss-react/mui';
import { useMutation, useQuery } from '@apollo/client';
import {
  acceptQuotes,
  calculateAction,
  getApplicationDetails,
  sendQuotes,
} from './queries';
import { useParams } from 'react-router-dom';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import {
  addRelationValues,
  checkDate,
  checkEmail,
  checkPhoneNumber,
  checkSelectedRelationOnChange,
  clearDataForPreviousSelection,
  entityToApplicationDetails,
  extractLovs,
  getApplicationProgress,
  getApplicationStatus,
  graphqlEntityToQuoteDetails,
  reviewListOfInsuredRelations,
} from './utils';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import WidgetPaper from '../../components/common/WidgetPaper';
import WidgetSection from '../../components/common/WidgetSection';
import EnhancedButton from '../../components/EnhancedButton';
import { IApplicationDetails, initialValues } from '.';
import EnhancedInput from '../../components/enhanced-form/EnhancedInput';
import EnhancedDatePicker from '../../components/enhanced-form/EnhancedDatePicker';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getDefaultDate } from '../../utils/date-utils';
import { isEmpty } from '../../utils/validationUtils';
import EnhancedChipInput from '../../components/enhanced-form/EnhancedChipInput';
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from '../../constants';
import {
  dateStringToISOString,
  formatDateYMD,
} from '../../utils/formatting-utils';
import Separator from '../../components/common/Separator';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import dayjs from 'dayjs';
import { useAppSelector } from '../../redux/hooks';
import EnhancedInternationalPhoneInput from '../../components/enhanced-form/EnhancedInternationalPhoneInput';
import Loader from '../../components/Loader';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import QuotesCards, { quote } from '../../components/custom/QuotesCards';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '90%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  section: {
    display: 'grid',
    'grid-template-columns': '19.5% 19.5% 19.5% 19.5% 19.5%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& label': {
      '& span': {
        margin: '0 0 0',
      },
    },
  },
  sectionFullRow: {
    display: 'grid',
    'grid-template-columns': '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  row: {
    flexWrap: 'wrap',
    margin: '10px',
    alignItems: 'flex-start',
    display: 'flex',
    gap: '10px',
    '& label': {
      flexGrow: '1',
      flexBasis: '0',
      minWidth: '0',
      maxWidth: '25%',
    },
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    'grid-column-start': '1',
    'grid-column-end': '4',
  },
  field: {
    width: '96%',
    marginRight: '10px !important',
  },
  repeaterField: {
    margin: '0px!important',
  },
  arField: {
    width: '100%',
    marginRight: '10px !important',
    direction: 'rtl',
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    '&:hover': {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: '0',
  },
}));

const ApplicationDetailsPageHealth: React.FC = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const tenant = useAppSelector((state) => state.tenant);
  const params = useParams();
  //#endregion

  //#region component hooks and states
  const [applicationInfo, setApplicationInfo] =
    useState<IApplicationDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [lovs, setLovs] =
    useState<Record<string, Record<string, string>>>(null);

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [lob, setLob] = useState<string>('');

  const [listOfInsuredRelationsLovs, setListOfInsuredRelationsLovs] = useState<
    Record<string, string>[]
  >([]);

  const countryCode = useRef<string>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    reset,
    setValue,
  } = useForm<IApplicationDetails>({
    defaultValues: initialValues,
  });

  const planDetailsFieldArray = useFieldArray({
    control,
    name: 'planDetails',
  });

  const insuredDetailsFieldArray = useFieldArray({
    control,
    name: 'insuredDetails',
  });

  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [formDisabled, setFormDisabled] = useState(false);

  //#endregion

  //#region graphql hooks
  const entityResult = useQuery(getApplicationDetails(), {
    variables: { id: params.id },
    errorPolicy: 'all',
  });

  const [acceptAction] = useMutation(acceptQuotes(), {
    refetchQueries: [getApplicationDetails()],
  });

  const [sendQuoteAction] = useMutation(sendQuotes(), {
    refetchQueries: [getApplicationDetails()],
  });

  const [mutateFunction] = useMutation(calculateAction(), {
    refetchQueries: [getApplicationDetails()],
  });
  //#endregion

  //#region useEffect

  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const extractedLovs = extractLovs(entityResult?.data);

        setLovs(extractedLovs);
        const applicationDetails = entityToApplicationDetails(
          entityResult?.data
        );

        // set relation values based on graphql values
        const initialListOfInsuredRelations = reviewListOfInsuredRelations(
          applicationDetails.insuredDetails,
          new Array<Record<string, string>>(
            applicationDetails.insuredDetails.length
          ),
          extractedLovs.insuredRelation
        );
        setListOfInsuredRelationsLovs(initialListOfInsuredRelations);

        applicationDetails.createdOn = getDefaultDate(
          dayjs(
            applicationDetails.createdOn
              ? new Date(applicationDetails.createdOn)
              : new Date()
          ).toDate()
        ).toDateString();

        applicationDetails.effectiveDate = getDefaultDate(
          dayjs(
            applicationDetails.effectiveDate
              ? new Date(applicationDetails.effectiveDate)
              : new Date()
          ).toDate()
        ).toDateString();

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.stateReason.value =
          applicationDetails.statusReason;
        applicationProperties.properties.lineOfBusiness.value =
          applicationDetails.lineOfBusiness;
        applicationProperties.properties.agency.value =
          applicationDetails.agencyName;
        applicationProperties.properties.agentName.value =
          applicationDetails.agentName;
        applicationProperties.properties.customerName.value =
          applicationDetails.customerName;

        setLob(applicationDetails.lineOfBusiness);

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value =
          applicationDetails.createdBy;
        //before setting date in date picker, use dayjs to format the datetime in case the value is coming from BE
        systemProperties.properties.createdOn.value = dayjs(
          new Date(applicationDetails.createdOn)
        ).format('DD/MM/YYYY');
        systemProperties.properties.modifiedBy.value =
          applicationDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(applicationDetails.modifiedOn)
        ).format('DD/MM/YYYY');

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        if (applicationDetails.planDetails?.length == 0) {
          applicationDetails.planDetails = initialValues.planDetails;
        }

        setApplicationInfo(applicationDetails);
        setUpdatedLeftSideInfo(newLeftEntityView);

        reset({
          ...applicationDetails,
          mobileNumber: applicationDetails.mobileNumber
            ? applicationDetails.mobileNumber
            : countryCode.current,
        });
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  //#endregion

  //#region functions

  const onSubmit: SubmitHandler<IApplicationDetails> = (data) => {
    setFormDisabled(true);
    setSubmitButtonState('loading');

    const insuredInputs = data.insuredDetails;

    for (const insuredInput of insuredInputs) {
      insuredInput.insuredDOB = formatDateYMD(
        insuredInput.insuredDOB as string
      );
      insuredInput.insuredRelation = insuredInput.insuredRelation?.toString();
    }

    mutateFunction({
      variables: {
        entityId: params.id,
        effectiveDate: dateStringToISOString(data.effectiveDate),
        isRenewal: data.isRenewal,
        renewalNumber: data.renewalNumber,
        insuredDetailsInputs: insuredInputs,
        applicationContactInputs: {
          email: data.email,
          mobileNumber:
            data.mobileNumber && data.mobileNumber.length > 3
              ? data.mobileNumber.charAt(0) == '+'
                ? data.mobileNumber
                : '+' + data.mobileNumber
              : undefined,
          country: data.country,
          city: data.city,
          street: data.street,
          building: data.building || null,
          title: data.title,
          fullName: data.fullName,
          phoneType: data.phoneType,
        },
        plansInputs: data.planDetails,
      },
    })
      .then((data) => {
        setSubmitButtonState('success');
        toast.success(<ToastSuccessMessage>Success</ToastSuccessMessage>);
      })
      .catch((error) => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}.</ToastErrorMessage>
        );
      })
      .finally(() => {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
      });
  };

  const renderMainChildren = () => {
    const applicationStatus =
      entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.Status.toLowerCase();

    const quotes: quote[] = [];
    entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.HealthDetailsID?.queries?.insurance?.plans?.map(
      (plan: any, i: number) => {
        const quoteEntity = graphqlEntityToQuoteDetails(
          entityResult?.data,
          i,
          tenant.currencySymbol,
          lob
        );
        quotes.push(quoteEntity);
      }
    );

    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <EnhancedStepper
            activeStep={getApplicationStatus(
              entityResult?.data?.Insurance?.entities?.application?.views
                ?.Insurance_all?.properties?.Status
            )}
            steps={steps}
            className={classes.stepper}
          />
          {applicationStatus != 'new' && (
            <>
              <QuotesCards
                entityId={params.id}
                applicationStatus={applicationStatus}
                quotes={quotes}
                sendQuoteAction={sendQuoteAction}
                acceptAction={acceptAction}
                customerEmail={getValues('email')}
                disabled={formDisabled}
                lineOfBusiness={lob}
                userCanSend={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_SendQuotes
                }
                userCanAccept={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_AcceptQuotes
                }
              />
              <Separator className={classes.thickSeperator} />
            </>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <WidgetPaper>
              <WidgetSection
                title="Application Details"
                style={{ margin: '-20px 0' }}
                narrowSpacing={true}
                classes={{ container: classes.section }}
                useSeparator={false}
                actions={
                  entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.Status.toLowerCase() !=
                    'closed' &&
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_SimulateAndSaveHealth && (
                    <EnhancedButton
                      type="submit"
                      state={submitButtonState}
                      backgroundColor={`${MAIN_ONE_THEME.palette.primary1.main}`}
                      color="#FFF"
                      style={{ margin: '0 0 -15px 0' }}
                    >
                      Calculate
                    </EnhancedButton>
                  )
                }
              >
                <Controller
                  name="createdOn"
                  control={control}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <EnhancedDatePicker
                      name={'createdOn'}
                      onBlur={onBlur}
                      onDateChange={(e: any) => {
                        onChange(e);
                      }}
                      title="Created On Date*"
                      material
                      disabled
                      format={tenant.dateFormat}
                      value={!isEmpty(value) ? value : initialValues.createdOn}
                      className={classes.field}
                    />
                  )}
                />
                <Controller
                  name="effectiveDate"
                  control={control}
                  rules={{
                    required: 'Required',
                    validate: (value) => checkDate(value) || 'Invalid Date',
                  }}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <EnhancedDatePicker
                      name={'effectiveDate'}
                      onBlur={onBlur}
                      onDateChange={(e: any) => {
                        onChange(e);
                        trigger('effectiveDate' as keyof typeof initialValues);

                        const effectiveDate = dayjs(e).toDate().toDateString();

                        setValue('effectiveDate', effectiveDate);
                      }}
                      title="Effective Date*"
                      material
                      format={tenant.dateFormat}
                      value={
                        !isEmpty(value) ? value : initialValues.effectiveDate
                      }
                      disabled={formDisabled}
                      className={classes.field}
                      error={errors?.effectiveDate?.message}
                    />
                  )}
                />
              </WidgetSection>
              <WidgetSection
                title="Customer Details"
                narrowSpacing={true}
                classes={{ container: classes.section }}
                useSeparator={false}
                style={{ margin: '-4px 0' }}
              >
                {lovs != null && (
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <EnhancedChipInput
                        name={`title`}
                        title="Title*"
                        placeholder={'Title'}
                        value={!isEmpty(value) ? value : ''}
                        onChange={(e) => {
                          onChange(e as string);
                          trigger(`title` as keyof typeof initialValues);
                        }}
                        onBlur={(e) => {
                          onBlur();
                          trigger('title' as keyof typeof initialValues);
                        }}
                        selectOptions={lovs?.titles}
                        error={
                          errors.title != undefined && !isEmpty(errors?.title)
                            ? errors?.title?.message
                            : null
                        }
                        multiple={false}
                        material
                        disabled={formDisabled}
                        className={classes.field}
                      />
                    )}
                  />
                )}

                <Controller
                  name="fullName"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type="text"
                      name="fullName"
                      title="Full Name*"
                      placeholder={'Full Name'}
                      onChange={(e) => {
                        trigger('fullName' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger('fullName' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.fullName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  rules={{
                    validate: (value) =>
                      checkEmail(value) || 'Invalid email format',
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type="text"
                      name="email"
                      title="Email"
                      placeholder={'Email'}
                      onChange={(e) => {
                        trigger('email' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger('email' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.email?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />

                <Controller
                  name="phoneType"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedChipInput
                      name={`phoneType`}
                      title="Phone Type*"
                      placeholder={'Phone Type'}
                      value={!isEmpty(value) ? value : ''}
                      onChange={(e) => {
                        onChange(e as string);
                        trigger(`phoneType` as keyof typeof initialValues);
                      }}
                      onBlur={(e) => {
                        onBlur();
                        trigger('phoneType' as keyof typeof initialValues);
                      }}
                      selectOptions={lovs?.phoneTypes}
                      error={
                        errors.phoneType != undefined &&
                        !isEmpty(errors?.phoneType)
                          ? errors?.phoneType?.message
                          : null
                      }
                      multiple={false}
                      material
                      disabled={formDisabled}
                      className={classes.field}
                    />
                  )}
                />

                <Controller
                  name="mobileNumber"
                  control={control}
                  rules={{
                    validate: {
                      requried: (value) =>
                        checkPhoneNumber(value) || 'required',
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInternationalPhoneInput
                      name={'mobileNumber'}
                      title={'Phone Number*'}
                      placeholder={'Phone Number'}
                      onChange={(e) => {
                        onChange(e);
                        trigger('mobileNumber' as keyof typeof initialValues);

                        if (isEmpty(e)) {
                          setValue('mobileNumber', undefined);
                        }
                      }}
                      onBlur={(e) => {
                        onBlur();

                        if (isEmpty(e)) {
                          setValue('mobileNumber', undefined);
                        }

                        trigger('mobileNumber' as keyof typeof initialValues);
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.mobileNumber?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                      disableDropDown={false}
                      editCountryCode={false}
                      defaultCountry={'lb'}
                    />
                  )}
                />

                {lovs != null && (
                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <EnhancedChipInput
                        name={`country`}
                        title="Country*"
                        placeholder={'Country'}
                        value={!isEmpty(value) ? value : ''}
                        onChange={(e) => {
                          onChange(e as string);
                          trigger(`country` as keyof typeof initialValues);
                        }}
                        onBlur={(e) => {
                          onBlur();
                          trigger('country' as keyof typeof initialValues);
                        }}
                        selectOptions={lovs?.countries}
                        error={
                          errors.country != undefined &&
                          !isEmpty(errors?.country)
                            ? errors?.country?.message
                            : null
                        }
                        multiple={false}
                        material
                        disabled={formDisabled}
                        className={classes.field}
                      />
                    )}
                  />
                )}
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type="text"
                      name="city"
                      title="City*"
                      placeholder={'City'}
                      onChange={(e) => {
                        trigger('city' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger('city' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.city?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />

                <Controller
                  name="street"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type="text"
                      name="street"
                      title="Street*"
                      placeholder={'Street'}
                      onChange={(e) => {
                        trigger('street' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger('street' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.street?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name="building"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type="text"
                      name="building"
                      title="Building"
                      placeholder={'Building'}
                      onChange={(e) => {
                        trigger('building' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger('building' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : null}
                      error={errors?.building?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
              </WidgetSection>

              <WidgetSection
                title="Insured Details"
                narrowSpacing={true}
                classes={{ container: classes.sectionFullRow }}
                useSeparator={false}
                style={{ margin: '-4px 0' }}
              >
                {lovs != null &&
                  insuredDetailsFieldArray.fields.map((f, index) => (
                    <div className={classes.row} key={f.id}>
                      <Controller
                        name={
                          `insuredDetails.${index}.insuredName` as keyof typeof initialValues
                        }
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedInput
                            type="text"
                            name={`insuredDetails.${index}.insuredName`}
                            title="Name*"
                            placeholder={'Name'}
                            onChange={(e) => {
                              onChange(e);
                              trigger(
                                `insuredDetails.${index}.insuredName` as keyof typeof initialValues
                              );
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger(
                                `insuredDetails.${index}.insuredName` as keyof typeof initialValues
                              );
                            }}
                            value={(value as string) || ''}
                            error={
                              errors.insuredDetails != undefined &&
                              !isEmpty(
                                errors?.insuredDetails[index]?.insuredName
                              )
                                ? errors?.insuredDetails[index]?.insuredName
                                    ?.message
                                : null
                            }
                            disabled={formDisabled}
                            material
                            className={classes.field}
                            style={{ height: '88px', margin: '-10px 0' }}
                            customStyles={{
                              labelStyles: classes.repeaterField,
                            }}
                          />
                        )}
                      />

                      <Controller
                        name={
                          `insuredDetails.${index}.insuredRelation` as keyof typeof initialValues
                        }
                        control={control}
                        rules={{
                          required: 'Required',
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedChipInput
                            name={`insuredDetails.${index}.insuredRelation`}
                            title="Relation*"
                            placeholder={'Relation'}
                            value={(value as string) || ''}
                            onChange={(e) => {
                              onChange(e as string);
                              trigger(
                                `insuredDetails.${index}.insuredRelation` as keyof typeof initialValues
                              );

                              const insuredDetails =
                                getValues('insuredDetails');
                              setListOfInsuredRelationsLovs(
                                checkSelectedRelationOnChange(
                                  value as string,
                                  insuredDetails as Record<string, string>[],
                                  listOfInsuredRelationsLovs,
                                  index
                                )
                              );
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger(
                                `insuredDetails.${index}.insuredRelation` as keyof typeof initialValues
                              );
                            }}
                            selectOptions={listOfInsuredRelationsLovs[index]}
                            material
                            error={
                              errors.insuredDetails != undefined &&
                              !isEmpty(
                                errors?.insuredDetails[index]?.insuredRelation
                              )
                                ? errors?.insuredDetails[index]?.insuredRelation
                                    ?.message
                                : null
                            }
                            multiple={false}
                            disabled={formDisabled}
                            className={classes.field}
                            style={{ margin: '-10px 0' }}
                            customStyles={{
                              labelStyles: classes.repeaterField,
                            }}
                          />
                        )}
                      />

                      <Controller
                        name={
                          `insuredDetails.${index}.insuredDOB` as keyof typeof initialValues
                        }
                        control={control}
                        rules={{
                          required: 'Required',
                          validate: (value) =>
                            checkDate(value as string) || 'Invalid Date',
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedDatePicker
                            name={`insuredDetails.${index}.insuredDOB`}
                            title="Date Of Birth*"
                            placeholder={'Date Of Birth'}
                            onDateChange={(e: any) => {
                              onChange(e);
                              trigger(
                                `insuredDetails.${index}.insuredDOB` as keyof typeof initialValues
                              );
                            }}
                            onBlur={() => {
                              onBlur();
                              trigger(
                                `insuredDetails.${index}.insuredDOB` as keyof typeof initialValues
                              );
                            }}
                            value={
                              !isEmpty(value)
                                ? (value as string)
                                : (`insuredDetails.${index}.insuredDOB` as keyof typeof initialValues)
                            }
                            error={
                              errors.insuredDetails != undefined &&
                              !isEmpty(
                                errors?.insuredDetails[index]?.insuredDOB
                              )
                                ? errors?.insuredDetails[index]?.insuredDOB
                                    ?.message
                                : null
                            }
                            disabled={formDisabled}
                            material
                            format={tenant.dateFormat}
                            style={{ margin: '-24px 0' }}
                            className={classes.field}
                          />
                        )}
                      />

                      <div
                        className="btn-box"
                        style={{
                          marginTop: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyItems: 'stretch',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {!formDisabled &&
                          insuredDetailsFieldArray.fields.length < 12 &&
                          insuredDetailsFieldArray.fields.length - 1 ===
                            index && (
                            <span
                              style={{
                                paddingRight: '10px',
                                color: '#3F3F3F',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                insuredDetailsFieldArray.append({
                                  ...initialValues.insuredDetails[0],
                                });

                                const insuredDetails = getValues(
                                  'insuredDetails'
                                ) as Record<string, string>[];

                                const relationTypesPerRecord = cloneDeep(
                                  listOfInsuredRelationsLovs
                                );
                                if (
                                  relationTypesPerRecord &&
                                  lovs?.insuredRelation &&
                                  insuredDetails.length > 1
                                ) {
                                  relationTypesPerRecord.push(
                                    lovs.insuredRelation
                                  );
                                  setListOfInsuredRelationsLovs(
                                    checkSelectedRelationOnChange(
                                      '',
                                      insuredDetails,
                                      relationTypesPerRecord
                                    )
                                  );
                                }
                              }}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </span>
                          )}
                        {!formDisabled &&
                          insuredDetailsFieldArray.fields.length !== 1 && (
                            <span
                              style={{ color: 'red', cursor: 'pointer' }}
                              onClick={() => {
                                const listOfInsuredRelation =
                                  listOfInsuredRelationsLovs.filter(
                                    (_, relationIndex) => {
                                      return relationIndex != index;
                                    }
                                  );
                                const insuredDetails = getValues(
                                  'insuredDetails'
                                ) as Record<string, string>[];

                                const deletedValue = insuredDetails[index][
                                  'insuredRelation'
                                ] as string;

                                insuredDetailsFieldArray.remove(index);

                                const updatedInsuredDetails = getValues(
                                  'insuredDetails'
                                ) as Record<string, string>[];

                                if (
                                  listOfInsuredRelation &&
                                  lovs?.insuredRelation &&
                                  updatedInsuredDetails.length > 1
                                ) {
                                  setListOfInsuredRelationsLovs(
                                    addRelationValues(
                                      deletedValue,
                                      updatedInsuredDetails,
                                      listOfInsuredRelation
                                    )
                                  );
                                }
                              }}
                            >
                              <HighlightOffIcon fontSize="small" />
                            </span>
                          )}
                      </div>
                    </div>
                  ))}
              </WidgetSection>

              <WidgetSection
                title="Product Details"
                narrowSpacing={true}
                classes={{ container: classes.sectionFullRow }}
                useSeparator={false}
                style={{ margin: '-4px 0' }}
              >
                {lovs != null &&
                  planDetailsFieldArray.fields.map((f, index) => (
                    <div className={classes.row} key={f.id}>
                      <Controller
                        name={
                          `planDetails.${index}.product` as keyof typeof initialValues
                        }
                        control={control}
                        rules={{
                          required: 'Required',
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedChipInput
                            name={`planDetails.${index}.product`}
                            title="Product*"
                            placeholder={'Product'}
                            value={(value as string) || ''}
                            onChange={(e) => {
                              const selectedValue = e as string;
                              onChange(selectedValue);
                              trigger(
                                `planDetails.${index}.product` as keyof typeof initialValues
                              );
                              const planDetails = getValues('planDetails');
                              planDetails[index] =
                                clearDataForPreviousSelection(
                                  planDetails[index],
                                  selectedValue
                                );
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger(
                                `planDetails.${index}.product` as keyof typeof initialValues
                              );
                            }}
                            selectOptions={lovs.products}
                            material
                            error={
                              errors.planDetails != undefined &&
                              !isEmpty(errors?.planDetails[index]?.product)
                                ? errors?.planDetails[index]?.product?.message
                                : null
                            }
                            multiple={false}
                            disabled={formDisabled}
                            className={classes.field}
                            style={{ margin: '-10px 0' }}
                            customStyles={{
                              labelStyles: classes.repeaterField,
                            }}
                          />
                        )}
                      />
                      {getValues('planDetails')[index].product == 'Standard' ? (
                        <>
                          <Controller
                            name={
                              `planDetails.${index}.class` as keyof typeof initialValues
                            }
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <EnhancedChipInput
                                name={`planDetails.${index}.class`}
                                title="Class*"
                                placeholder={'Class'}
                                value={(value as string) || ''}
                                onChange={(e) => {
                                  onChange(e as string);
                                  trigger(
                                    `planDetails.${index}.standardInHospitalClass` as keyof typeof initialValues
                                  );
                                }}
                                onBlur={() => {
                                  onBlur();
                                  trigger(
                                    `planDetails.${index}.class` as keyof typeof initialValues
                                  );
                                }}
                                selectOptions={lovs.standardInHospitalClasses}
                                material
                                error={
                                  errors.planDetails != undefined &&
                                  !isEmpty(
                                    errors?.planDetails[index]
                                      ?.standardInHospitalClass
                                  )
                                    ? errors?.planDetails[index]?.class?.message
                                    : null
                                }
                                multiple={false}
                                disabled={formDisabled}
                                className={classes.field}
                                style={{ margin: '-10px 0' }}
                                customStyles={{
                                  labelStyles: classes.repeaterField,
                                }}
                              />
                            )}
                          />
                          <Controller
                            name={
                              `planDetails.${index}.ambulatory` as keyof typeof initialValues
                            }
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <EnhancedChipInput
                                name={`planDetails.${index}.ambulatory`}
                                title="Ambulatory Coverage*"
                                placeholder={'Ambulatory Coverage'}
                                value={(value as string) || ''}
                                onChange={(e) => {
                                  onChange(e as string);
                                  trigger(
                                    `planDetails.${index}.standardAmbulatoryCoverage` as keyof typeof initialValues
                                  );
                                }}
                                onBlur={() => {
                                  onBlur();
                                  trigger(
                                    `planDetails.${index}.standardAmbulatoryCoverage` as keyof typeof initialValues
                                  );
                                }}
                                selectOptions={lovs.standardAmbulatoryCoverages}
                                material
                                error={
                                  errors.planDetails != undefined &&
                                  !isEmpty(
                                    errors?.planDetails[index]
                                      ?.standardAmbulatoryCoverage
                                  )
                                    ? errors?.planDetails[index]?.ambulatory
                                        ?.message
                                    : null
                                }
                                multiple={false}
                                disabled={formDisabled}
                                className={classes.field}
                                style={{ margin: '-10px 0' }}
                                customStyles={{
                                  labelStyles: classes.repeaterField,
                                }}
                              />
                            )}
                          />
                        </>
                      ) : getValues('planDetails')[index].product ==
                        'Optimum' ? (
                        <>
                          <Controller
                            name={
                              `planDetails.${index}.class` as keyof typeof initialValues
                            }
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <EnhancedChipInput
                                name={`planDetails.${index}.class`}
                                title="Class*"
                                placeholder={'Class'}
                                value={(value as string) || ''}
                                onChange={(e) => {
                                  onChange(e as string);
                                  trigger(
                                    `planDetails.${index}.optimumInHospitalClass` as keyof typeof initialValues
                                  );
                                }}
                                onBlur={() => {
                                  onBlur();
                                  trigger(
                                    `planDetails.${index}.optimumInHospitalClass` as keyof typeof initialValues
                                  );
                                }}
                                selectOptions={lovs.optimumInHospitalClasses}
                                material
                                error={
                                  errors.planDetails != undefined &&
                                  !isEmpty(
                                    errors?.planDetails[index]
                                      ?.optimumInHospitalClass
                                  )
                                    ? errors?.planDetails[index]?.class?.message
                                    : null
                                }
                                multiple={false}
                                disabled={formDisabled}
                                className={classes.field}
                                style={{ margin: '-10px 0' }}
                                customStyles={{
                                  labelStyles: classes.repeaterField,
                                }}
                              />
                            )}
                          />
                          <Controller
                            name={
                              `planDetails.${index}.ambulatory` as keyof typeof initialValues
                            }
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <EnhancedChipInput
                                name={`planDetails.${index}.ambulatory`}
                                title="Ambulatory Coverage*"
                                placeholder={'Ambulatory Coverage'}
                                value={(value as string) || ''}
                                onChange={(e) => {
                                  onChange(e as string);
                                  trigger(
                                    `planDetails.${index}.optimumAmbulatoryCoverage` as keyof typeof initialValues
                                  );
                                }}
                                onBlur={() => {
                                  onBlur();
                                  trigger(
                                    `planDetails.${index}.optimumAmbulatoryCoverage` as keyof typeof initialValues
                                  );
                                }}
                                selectOptions={lovs.optimumAmbulatoryCoverages}
                                material
                                error={
                                  errors.planDetails != undefined &&
                                  !isEmpty(
                                    errors?.planDetails[index]
                                      ?.optimumAmbulatoryCoverage
                                  )
                                    ? errors?.planDetails[index]?.ambulatory
                                        ?.message
                                    : null
                                }
                                multiple={false}
                                disabled={formDisabled}
                                className={classes.field}
                                style={{ margin: '-10px 0' }}
                                customStyles={{
                                  labelStyles: classes.repeaterField,
                                }}
                              />
                            )}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      <div
                        className="btn-box"
                        style={{
                          marginTop: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyItems: 'stretch',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {!formDisabled &&
                          planDetailsFieldArray.fields.length - 1 === index && (
                            <span
                              style={{
                                paddingRight: '10px',
                                color: '#3F3F3F',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                planDetailsFieldArray.append({
                                  ...initialValues.planDetails[0],
                                })
                              }
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </span>
                          )}
                        {!formDisabled &&
                          planDetailsFieldArray.fields.length !== 1 && (
                            <span
                              style={{ color: 'red', cursor: 'pointer' }}
                              onClick={() =>
                                planDetailsFieldArray.remove(index)
                              }
                            >
                              <HighlightOffIcon fontSize="small" />
                            </span>
                          )}
                      </div>
                    </div>
                  ))}
              </WidgetSection>
            </WidgetPaper>
          </form>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="linear-gradient(55deg, #4E4E4E 0%, #858585 60%,rgb(168, 48, 110) 90%, rgb(190, 25, 35) 100%)"
          classification={{ classifications: {}, value: '' }}
          progress={getApplicationProgress(
            entityResult?.data?.Insurance?.entities?.application?.views
              ?.Insurance_all?.properties?.Status
          )}
          supportsClassification={true}
          supportsProgressBar={true}
          title={applicationInfo.applicationName}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };
  //#endregion

  return loadingState ? (
    <Loader />
  ) : (
    <StaticLayout
      name={'Application Details'}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ApplicationDetailsPageHealth;
