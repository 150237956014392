import { gql } from '@apollo/client';

export function getListForm() {
  return gql`
    query getProvidersInfoEnums {
      Configuration_ProviderStatuses: __type(
        name: "Configuration_ProviderStatuses"
      ) {
        name
        enumValues {
          name
        }
      }

      Configuration {
        lookups {
          providerTypes {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function createProvider() {
  return gql`
    mutation createProvider(
      $personId: String!
      $type: String!
      $status: Configuration_ProviderStatuses!
      $isCompany: Boolean
      $isLocal: Boolean
      $effectiveDate: Date!
      $expiryDate: Date
    ) {
      configuration {
        actions {
          createProvider(
            effectiveDate: $effectiveDate
            expiryDate: $expiryDate
            isCompany: $isCompany
            isLocal: $isLocal
            personId: $personId
            status: $status
            type: $type
          ) {
            id
            views {
              Configuration_all {
                properties {
                  Code
                  ProviderCode
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updateProvider() {
  return gql`
    mutation updateProvider(
      $entityId: String!
      $isCompany: Boolean
      $isLocal: Boolean
      $effectiveDate: Date!
    ) {
      configuration {
        entities {
          provider {
            configuration {
              updateProvider(
                entityId: $entityId
                isCompany: $isCompany
                isLocal: $isLocal
                effectiveDate: $effectiveDate
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function fetchedPersons() {
  return gql`
    query fetchedPersons($searchKeyword: String) {
      SalesforceManagement {
        queries {
          AllActivePersons(searchKeyword: $searchKeyword) {
            salesforceManagement_Person_Id
            salesforceManagement_Person_FullName
            salesforceManagement_Person_PersonCode
          }
        }
      }
    }
  `;
}
