import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query LIST_INSUREDLISTS(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedBusinessPartners: [String]
      $Attribute: String!
      $Descending: Boolean!
      $FromCreatedOn: Date
      $ToCreatedOn: Date
    ) {
      Production {
        queries {
          getInsuredList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            FromCreatedOn: $FromCreatedOn
            ToCreatedOn: $ToCreatedOn
            selectedBusinessPartners: $selectedBusinessPartners
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              production_Insured_Id
              production_Insured_InsuredCode
              production_Insured_FullName
              production_Insured_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Email
                    }
                  }
                }
              }
              production_Insured_AssignedToBusinessPartnerID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Code
                      PersonID {
                        id
                        views {
                          SalesforceManagement_all {
                            properties {
                              FullName
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              production_Insured_LinkedBusinessPartnerIDs {
                views {
                  SalesforceManagement_all {
                    properties {
                      Id
                      Code
                      PersonID {
                        id
                        views {
                          SalesforceManagement_all {
                            properties {
                              FullName
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              production_Insured_PhoneDetails {
                PhoneType
                PhoneNumber
              }
              production_Insured_createdOn
              production_Insured_modifiedOn
            }
          }
        }
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query getAssignedBusinessPartnerList {
      Production {
        queries {
          getAssignedBPListWithoutPagination {
            salesforceManagement_AssignedBP_Id
            salesforceManagement_AssignedBP_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    Code
                    PersonID {
                      id
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
