/* eslint-disable no-useless-escape */
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import DataService from '../services/dataService';

export const REGEX = {
  letterAndspaces: /^[a-zA-Z\s]*$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  alphanumeric: /^[\w\\\/\-]*$/,
  usPhoneNumber:
    /^(\+1)?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})[ ]?((?:x|ext\.?|\#)\d{3,4})?$/i,
  url: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  numbersOnly: /^[0-9]*$/,
  lettersOnly: /^[a-zA-Z]+$/,
  validPassword:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[+=_#?!@$%^&*-]).{8,}$/,
};

export function isEmpty(input: any): boolean {
  if (Array.isArray(input)) {
    if (input.length === 0) {
      return true;
    }
  }
  return input === undefined || input === '' || input === null;
}

export function isEmptyValue(input: any): boolean {
  return input === undefined || input === '' || input === null;
}

export function isZeroValue(input: any): boolean {
  return (
    (typeof input === 'number' && input === 0) ||
    (typeof input === 'string' && input === '0')
  );
}

export function isValidNumber(input: any): boolean {
  return !isEmpty(input) && !isNaN(input);
}

export function regexTestInput(regex: RegExp, input: string) {
  return !isEmpty(input) && regex.test(input);
}

export function isValidPercentage(value: string | number): boolean {
  return (
    (Number(value) >= 0 && Number(value) < 100) ||
    (Number(value) > 0 && Number(value) <= 100)
  );
}

export function isValidNumberInput(
  value: number,
  minValue: number,
  maxValue: number
): boolean {
  return value >= minValue && value <= maxValue;
}

export function isValidDecimalCount(value: number, decimalCount: number) {
  return (value.toString().split('.')[1] || []).length <= decimalCount;
}

export function isValidUsPhoneNumber(value: string) {
  return REGEX.usPhoneNumber.test(value);
}

export function isValidZipCode(value: string) {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
}

export function isValidEmail(value: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
}

export function isValidUrl(value: string) {
  return REGEX.url.test(value);
}

export async function validatePasswordAsync(password: string) {
  try {
    const response = await DataService.post(`api/Users/password/valid`, {
      password,
    });
    if (response.ok) {
      const result: { succeeded: boolean; message: string } =
        await response.json();
      if (result.succeeded) {
        return '';
      } else {
        return result.message;
      }
    } else {
      return 'Please try again';
    }
  } catch {
    return 'Please try again';
  }
}

export function validatePhoneNumber(phoneNumber: string) {
  const formattedPhoneNumber =
    phoneNumber.indexOf('+') != 0 ? '+' + phoneNumber : phoneNumber;
  if (isPossiblePhoneNumber(formattedPhoneNumber)) {
    return true;
  }

  return false;
}

export const isArabicText = (text: string) => {
  const arabicPattern = /^[\u0600-\u06FF\s]+$/;
  return arabicPattern.test(text);
};

export function isGuid(value: string): boolean {
  const guidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return guidRegex.test(value);
}
