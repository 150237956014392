import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query LIST_APPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $agencyId: [String]
      $product: [String]
      $effectiveDateFrom: Date
      $effectiveDateTo: Date
      $createdDateFrom: Date
      $createdDateTo: Date
      $selectedStatuses: [Insurance_ApplicationStatuses]
    ) {
      Insurance {
        queries {
          listApplicationDetailsMotor(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            AgencyID: $agencyId
            Products: $product
            EffectiveDateFrom: $effectiveDateFrom
            EffectiveDateTo: $effectiveDateTo
            CreatedOnFrom: $createdDateFrom
            CreatedOnTo: $createdDateTo
            selectedStatuses: $selectedStatuses
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              insurance_Application_Id
              insurance_Application_ApplicationName
              insuranceCustomer_FullName
              insuranceApplicationMotorDetails_PlateCode {
                Id
                Title
              }
              insuranceApplicationMotorDetails_PlateNumber
              insuranceAgency_AgencyName
              insuranceAgent_FirstName
              insuranceAgent_LastName
              insurance_Application_EffectiveDate
              insurance_Application_ExpiryDate
              insurance_Application_createdOn
              insurance_Application_modifiedOn
              insurance_Application_Status
              insurance_Application_StatusReason
              insurance_Application_Policies {
                EntityId
                Title
              }
              insurance_Application_PlanCount
            }
          }
        }
        actions {
          able_To_CreateApplicationAsAgent
          able_To_CreateApplicationAsUnderwriter
        }
      }
    }
  `;
}

export function filterLovsQuery() {
  return gql`
    query APPLICATIONS_LISTING_LOVS {
      Insurance_ApplicationStatuses: __type(
        name: "Insurance_ApplicationStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
      Insurance {
        queries {
          allAgencies {
            insurance_Agency_Id
            insurance_Agency_AgencyName
          }
        }
        lookups {
          products(Insurance_LinesOfBusiness: "Motor") {
            Id
            Code
            Title
          }
        }
      }
    }
  `;
}

export function planDetailsQuery() {
  return gql`
    query application($id: String!) {
      Insurance {
        entities {
          application(id: $id) {
            queries {
              insurance {
                applicationQuotes {
                  insuranceQuote_ApplicationID {
                    id
                  }
                  insuranceQuote_Id
                  insuranceQuote_Product {
                    Code
                    Title
                  }
                  insuranceQuote_TotalAnnualPremium
                  insuranceQuote_Status
                  insuranceQuote_StatusReason
                  insuranceQuoteMotorDetails_SumInsured
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createActionAsAgent() {
  return gql`
    mutation createActionAsAgent(
      $agencyId: String
      $agentId: String
      $lineOfBusiness: String!
    ) {
      insurance {
        actions {
          createApplicationAsAgent(
            lineOfBusiness: $lineOfBusiness
            agencyId: $agencyId
            agentId: $agentId
          ) {
            id
          }
        }
      }
    }
  `;
}
