import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  company: '',
  name: '',
  description: '',
  price: '',
  incomeAccount: '',
  expenseAccount: '',
  BuySell: '',
  BuyThis: '',
  SellThis: '',
};

export const inputs = (
  mode: 'add' | 'edit' = 'add',
  values: Record<string, any> = initialValues,
  lovs: Record<string, Record<string, string>>,
  incomeAccounts: Record<string, string>,
  expenseAccounts: Record<string, string>
): Record<string, DynamicFormInputType> => ({
  company: {
    name: 'company',
    title: 'Company',
    type: FormInputTypes.chips,
    disabled: mode === 'edit',
    value: !isEmpty(values.company) ? values.company : [],
    selectOptions: lovs?.relatedCompanies,
    required: true,
    multiple: false,
  },
  name: {
    name: 'name',
    title: 'Product Name',
    type: FormInputTypes.text,
    value: values.name,
    required: true,
  },
  description: {
    name: 'description',
    title: 'Description',
    type: FormInputTypes.text,
    value: values.description,
    required: false,
  },
  price: {
    name: 'price',
    title: 'Price',
    type: FormInputTypes.currency,
    value: values.price,
    required: false,
    disabled: false,
  },
  SellThis: {
    name: 'SellThis',
    title: 'Sell This',
    type: FormInputTypes.checkbox,
    value: values.SellThis,
    required: false,
    disabled: mode === 'edit',
  },
  incomeAccount: {
    name: 'incomeAccount',
    title: 'Income Account',
    type: FormInputTypes.chips,
    value: values.incomeAccount,
    required: values.SellThis,
    multiple: false,
    hidden: !values.SellThis,
    disabled: mode === 'edit',
    selectOptions: incomeAccounts,
  },
  BuyThis: {
    name: 'BuyThis',
    title: 'Buy This',
    type: FormInputTypes.checkbox,
    value: values.BuyThis,
    required: false,
    disabled: mode === 'edit',
  },
  expenseAccount: {
    name: 'expenseAccount',
    title: 'Expense Account',
    type: FormInputTypes.chips,
    value: values.expenseAccount,
    required: values.BuyThis,
    multiple: false,
    hidden: !values.BuyThis,
    disabled: mode === 'edit',
    selectOptions: expenseAccounts,
  },
});
