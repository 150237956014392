import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import {
  MAIN_ONE_THEME,
  contentFontFamilyBold,
  contentFontFamilyRegular,
} from '../constants';

interface IEnhancedToolbar {
  onChange: (value: string) => void;
  cdnUrl: string;
  notes?: boolean;
  title?: string;
  separator?: boolean;
  filterElem?: JSX.Element;
  classes?: { leftSection?: string; rightSection?: string };
  forceClearSearchValue?: boolean;
  children?: React.ReactNode;
  specificDesign?: boolean;
  fieldNextToTitle?: JSX.Element;
  keywordSearch?: string;
  showInlineFilter?: boolean;
}

const useStyles = makeStyles<{
  cdnUrl: string;
  notes: boolean;
  separator: boolean;
  specificDesign: boolean;
  fieldNextToTitle?: JSX.Element;
}>()(
  (theme, { cdnUrl, notes, separator, specificDesign, fieldNextToTitle }) => ({
    root: {
      padding: notes ? '0' : '1px 16px',
      minHeight: '20px!important',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '& .MuiButton-root': {
        textTransform: specificDesign ? 'capitalize' : 'uppercase',
        color: specificDesign ? 'black' : 'white',
        fontFamily: contentFontFamilyRegular,
        backgroundColor: 'white',
        border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.light} !important`,
        padding: '0px 12px',
        margin: '8px',
        '&:hover': {
          backgroundColor: ` ${MAIN_ONE_THEME.palette.secondary3.light} !important`,
        },
      },
      '& .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
        {
          width: '50%',
        },
    },
    searchbar: {
      color: MAIN_ONE_THEME.palette.primary2.main,
      height: '23px',
      minWidth: '150px',
      width: '50%',
      border: 'unset',
      outline: 'unset',
      backgroundColor: 'transparent',
      font: 'normal 13px/15px',
      fontFamily: contentFontFamilyRegular,
      '&:focus': {
        '-webkit-input-placeholder': { color: 'transparent' },
      },
      marginRight: !separator ? 'calc(2em + 10px)' : '0',
    },
    titleWrapper: {
      marginTop: fieldNextToTitle ? '-29px' : '3px',
      marginBottom: fieldNextToTitle ? '-29px' : '3px',
      flexGrow: 1,
      flexShrink: 1,
      minWidth: '30%',
      display: 'flex',
      alignItems: fieldNextToTitle ? 'center' : '',
    },
    title: {
      flexGrow: 1,
      flexShrink: 1,
      fontFamily: contentFontFamilyBold,
      fontSize: MAIN_ONE_THEME.typography.regular.reg4.fontSize,
      color: MAIN_ONE_THEME.palette.primary1.main,
      alignSelf: 'center',
      width: '25px',
    },
    separator: {
      width: '1px',
      height: '25px',
      opacity: 0.5,
      backgroundColor: MAIN_ONE_THEME.palette.secondary3.main,
      marginLeft: 'calc(1em + 5px)',
      marginRight: 'calc(1em + 5px)',
      display: 'inline-block',
      verticalAlign: 'text-bottom',
      alignSelf: 'center',
    },
    filterContainer: {
      backgroundSize: '16px 16px',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      paddingLeft: '40px',
      backgroundImage: `url(${cdnUrl}/icons/filter-thick-primary-new.svg)`,
      backgroundPosition: 'top 4px left 0',
      boxSizing: 'border-box',
    },
    autocompleteInput: {
      margin: '-10px 0 15px 0',
    },
  })
);

const EnhancedToolbar: React.FC<IEnhancedToolbar> = ({
  onChange,
  cdnUrl,
  classes = {},
  filterElem,
  notes = false,
  children,
  title,
  separator = false,
  forceClearSearchValue = false,
  specificDesign = false,
  fieldNextToTitle,
  keywordSearch,
  showInlineFilter = true,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { classes: toolbarClasses } = useStyles({
    cdnUrl,
    notes,
    separator,
    specificDesign,
    fieldNextToTitle,
  });

  useEffect(() => {
    if (forceClearSearchValue) {
      setSearchValue('');
    }
  }, [forceClearSearchValue]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    onChange(event.target.value ? event.target.value.trim() : '');
  };

  const renderFilterInput = () => (
    <div
      className={toolbarClasses.filterContainer}
      style={{ width: filterElem ? 'max-content' : 'min-content' }}
    >
      {filterElem ? (
        <>
          {filterElem}
          <span className={toolbarClasses.separator} />
        </>
      ) : (
        <></>
      )}
      <input
        type="text"
        className={toolbarClasses.searchbar}
        placeholder={'Search'}
        onChange={handleChange}
        onFocus={(e) => {
          e.target.placeholder = '';
        }}
        onBlur={(e) => {
          e.target.placeholder = 'Search';
        }}
        value={searchValue || keywordSearch}
      />
    </div>
  );

  return (
    <Toolbar className={toolbarClasses.root}>
      <>
        {fieldNextToTitle ? (
          <div
            className={clsx(toolbarClasses.titleWrapper, classes.leftSection)}
          >
            {title && (
              <>
                <div className={toolbarClasses.title}>{title}</div>
                <div className={toolbarClasses.autocompleteInput}>
                  {fieldNextToTitle}
                </div>
              </>
            )}
            {showInlineFilter ? renderFilterInput() : null}
          </div>
        ) : (
          <div
            className={clsx(toolbarClasses.titleWrapper, classes.leftSection)}
          >
            {title && <div className={toolbarClasses.title}>{title}</div>}
            {showInlineFilter ? renderFilterInput() : null}
          </div>
        )}

        <div className={classes.rightSection} style={{ display: 'flex' }}>
          {separator && <span className={toolbarClasses.separator} />}
          {children}
        </div>
      </>
    </Toolbar>
  );
};

export default EnhancedToolbar;
