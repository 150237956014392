import { enumListAsRecordObject } from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const clauseEnums: Record<string, Record<string, string>> = {};
  const lines: Record<string, string> = {};
  const clauseStatus: Record<string, string> = {};

  clauseEnums[data.PlanConfigManagement_ClauseStatuses.name] =
    enumListAsRecordObject(data.PlanConfigManagement_ClauseStatuses.enumValues);

  data.PlanConfigManagement.queries.allLines.forEach(
    (element: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lines[element.planConfigManagement_Line_Id] =
        element.planConfigManagement_Line_Name;
    }
  );

  clauseEnums['lines'] = lines;

  clauseEnums['clauseStatus'] = clauseStatus;

  return clauseEnums;
}

export function graphqlEntityToClauseInfo(
  data: any,
  lineId: string,
  lineName: string
): IClauseInfo {
  return {
    clauseId: '',
    lineId: lineId,
    lineName: lineName,
    clauseName:
      data?.PlanConfigManagement?.entities?.clause?.views
        ?.PlanConfigManagement_all?.properties?.Name,
    clauseExternalCode:
      data?.PlanConfigManagement?.entities?.clause?.views
        ?.PlanConfigManagement_all?.properties?.ExternalCode,
    clauseStatus:
      data?.PlanConfigManagement?.entities?.clause?.views
        ?.PlanConfigManagement_all?.properties?.Status,
    clauseDescription:
      data?.PlanConfigManagement?.entities?.clause?.views
        ?.PlanConfigManagement_all?.properties?.Description,
  };
}
