import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../../../components/widgets/custom-listing-filter';
import { FormInputTypes } from '../../../../DynamicForm';
import { IAbstractRecord } from '../../../../models';

export function getHeaders(
  navigate: (path: string) => void
): Record<string, EnhancedTableHeader> {
  return {
    production_Insured_InsuredCode: {
      name: 'production_Insured_InsuredCode',
      title: 'Code',
      type: EnhancedTableHeaderType.Clickable,
      callback: (payload: any) => {
        navigate(
          '/production/insured/' + payload.columns.production_Insured_Id
        );
      },
    },
    production_Insured_FullName: {
      name: 'production_Insured_FullName',
      title: 'Name',
      type: EnhancedTableHeaderType.Clickable,
      callback: (payload: any) => {
        navigate(
          '/production/insured/' + payload.columns.production_Insured_Id
        );
      },
    },
    BusinessPartner_FullName: {
      name: 'BusinessPartner_FullName',
      title: 'Business Partner',
      type: EnhancedTableHeaderType.Text,
    },
    production_Insured_Email: {
      name: 'production_Insured_Email',
      title: 'Email',
      type: EnhancedTableHeaderType.Text,
    },
    production_Insured_PhoneNumber: {
      name: 'production_Insured_PhoneNumber',
      title: 'Phone Number',
      type: EnhancedTableHeaderType.IntPhoneNumber,
    },
    production_Insured_createdOn: {
      name: 'production_Insured_createdOn',
      title: 'Created On',
      type: EnhancedTableHeaderType.Date,
    },
    production_Insured_modifiedOn: {
      name: 'production_Insured_modifiedOn',
      title: 'Modified On',
      type: EnhancedTableHeaderType.Date,
    },
  };
}

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        businessPartner: {
          name: 'businessPartner',
          title: 'Business Partner',
          type: FormInputTypes.chips,
          value: filterValues?.businessPartner || [],
          placeholder: 'Business Partner',
          triggerUpdate: true,
          selectOptions: { ...lovs.businessPartner },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues?.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
