import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import NewChipsInput from '../../../components/enhanced-form/NewChipsInput';
import { IBeneficiaryData, IProposalPageFormState } from './form';
import { validatePolicyBeneficiaryField } from '../validation';
import ProposalPolicyPersonDrawer from '../../proposal-policy-person-drawer/ProposalPolicyPersonDrawer';
import PersonAddressDrawer from '../../person-address-drawer/PersonAddressDrawer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { removeObjectAtIndex } from '../../../utils/helper-utils';
import { useLazyQuery } from '@apollo/client';
import { fetchedPersonAddressList, fetchedPersons } from '../queries';
import { extractPersonsAddressesFixed, extractPersonsFixed } from '../utils';
import { useParams } from 'react-router-dom';
import _, { isArray, isEmpty } from 'lodash';
import EnhancedPercentageInput from '../../../components/enhanced-form/EnhancedPercentageInput';
import { IProposalDetailsSummary } from './index2';
import { isGuid } from '../../../utils/validationUtils';

interface IPolicyDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  maxNumber: number;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '33px 23px 23px',
    marginBottom: '14px',
    position: 'relative',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: '39% 39% 18%',
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    alignContent: 'center',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '8px',
    right: '0',
    margin: '0',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 !important',
  },
  label: {
    color: '#000000',
    margin: '0px!important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  field: {
    width: '100%',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const PolicyBeneficiaryWidget: React.FC<IPolicyDetailsWidgetProps> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  maxNumber,
  data,
}) => {
  const { classes } = useStyles();
  const params = useParams<{ id: string }>();
  const entityId = params.id;

  const [loaded, setLoaded] = useState<boolean>(false);

  const [newPersonDrawerOpen, setNewPersonDrawerOpen] = useState<number>(-1);
  const [newAddressDrawerOpen, setNewAddressDrawerOpen] = useState<number>(-1);
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  const [lists, setLists] = useState<{
    id: Record<number, Record<string, string>>;
    beneficiaryAddresses: Record<number, Record<string, string>>;
    primaryAddresses: Record<number, string>;
  }>({
    id: {},
    beneficiaryAddresses: {},
    primaryAddresses: {},
  });

  const beneficiaryValues = pageState.values.policyPersons.beneficiaries;
  const beneficiaryErrors = pageState.errors.policyPersons.beneficiaries;
  const beneficiaryTouched = pageState.touched.policyPersons.beneficiaries;

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons());
  const [getPersonAddressListLazy] = useLazyQuery(fetchedPersonAddressList());

  const handleOpenDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewPersonDrawerOpen(rowIndex);
  };

  const handleOpenAddressDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewAddressDrawerOpen(rowIndex);
  };

  const handleBeneficiaryAddresses = async (personId: string) => {
    const result = await getPersonAddressListLazy({
      variables: { selectedPersonID: personId },
      errorPolicy: 'all',
    });
    return extractPersonsAddressesFixed(result.data);
  };

  const handleBeneficiarySearch = async (inputValue: string) => {
    if (inputValue?.length > 3) {
      const newData = await fetchedPersonsLazy({
        variables: {
          searchKeyword: inputValue,
          selectedBusinessPartners: [data?.PrimaryBPID?.Id],
        },
      });

      return extractPersonsFixed(newData.data);
    }

    return {} as Record<string, string>;
  };

  const initialize = () => {
    const newList: Record<number, Record<string, string>> = {};
    beneficiaryValues.forEach((beneficiary, index) => {
      if (!newList[index]) {
        newList[index] = {};
      }
      newList[index][beneficiary.address] = beneficiary.addressDetails;
    });
    setLists((prevLists) => ({
      ...prevLists,
      beneficiaryAddresses: newList,
    }));
    setLoaded(true);
  };

  useEffect(() => {
    initialize();
  }, []);

  const onFieldBlur = (fieldName: string, rowIndex: number) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.policyPersons.beneficiaries[rowIndex][fieldName] =
      true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = async (
    fieldName: string,
    value: any,
    rowIndex: number,
    touched = false,
    updatedList: any = lists
  ) => {
    const newPageState = _.cloneDeep(pageState);
    const newLists = { ...updatedList };

    if (fieldName === 'id') {
      const mainValue: string = Array.isArray(value) ? value[0] : value;

      if (isEmpty(mainValue) || isGuid(mainValue)) {
        newPageState.values.policyPersons.beneficiaries[rowIndex][fieldName] =
          mainValue;
        newPageState.errors.policyPersons.beneficiaries[rowIndex][fieldName] =
          validatePolicyBeneficiaryField(fieldName, mainValue);
        if (touched) {
          newPageState.touched.policyPersons.beneficiaries[rowIndex][
            fieldName
          ] = true;
        }

        if (isGuid(mainValue)) {
          const searchAddressResult =
            await handleBeneficiaryAddresses(mainValue);

          const beneficiaryAddresses = searchAddressResult;
          const extractedAddresses: any = {};
          if (beneficiaryAddresses) {
            Object.keys(beneficiaryAddresses).forEach((key) => {
              extractedAddresses[key] = beneficiaryAddresses[key].address;
              if (beneficiaryAddresses[key].isPrimary) {
                newPageState.values.policyPersons.beneficiaries[
                  rowIndex
                ].address = key;
                newPageState.values.policyPersons.beneficiaries[
                  rowIndex
                ].addressDetails = beneficiaryAddresses[key].address;
              }
            });
            newPageState.errors.policyPersons.beneficiaries[
              rowIndex
            ].beneficiaryAddress = '';
            newLists['beneficiaryAddresses'][rowIndex] = extractedAddresses;
          }
        }
      }
    } else {
      (newPageState.values.policyPersons.beneficiaries[rowIndex] as any)[
        fieldName
      ] = value;

      newPageState.errors.policyPersons.beneficiaries[rowIndex][fieldName] =
        validatePolicyBeneficiaryField(fieldName, value);
      if (touched) {
        newPageState.touched.policyPersons.beneficiaries[rowIndex][fieldName] =
          true;
      }
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onPersonAddition = async (person: any, personFullName?: any) => {
    const rowIndex = newPersonDrawerOpen;

    const newPageState = _.cloneDeep(pageState);

    const newLists = _.cloneDeep(lists);

    newPageState.values.policyPersons.beneficiaries[rowIndex]['id'] = person;
    newPageState.errors.policyPersons.beneficiaries[rowIndex]['id'] = '';
    newPageState.touched.policyPersons.beneficiaries[rowIndex]['id'] = true;

    newLists.id[rowIndex] = {};
    newLists.id[rowIndex][person] = personFullName;

    const searchAddressResult = await handleBeneficiaryAddresses(person);

    const beneficiaryAddresses = searchAddressResult;
    const extractedAddresses: any = {};
    if (beneficiaryAddresses) {
      Object.keys(beneficiaryAddresses).forEach((key) => {
        extractedAddresses[key] = beneficiaryAddresses[key].address;
        if (beneficiaryAddresses[key].isPrimary) {
          newPageState.values.policyPersons.beneficiaries[rowIndex].address =
            key;
          newPageState.values.policyPersons.beneficiaries[
            rowIndex
          ].addressDetails = beneficiaryAddresses[key].address;
        }
      });
      newPageState.errors.policyPersons.beneficiaries[
        rowIndex
      ].beneficiaryAddress = '';
      newLists['beneficiaryAddresses'][rowIndex] = extractedAddresses;
    }

    if (Object.keys(newLists['beneficiaryAddresses'][rowIndex]).length === 1) {
      newPageState.values.policyPersons.beneficiaries[rowIndex]['address'] =
        Object.keys(newLists['beneficiaryAddresses'][rowIndex])[0];
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onAddressAddition = async (address: any, addressFullName?: any) => {
    const rowIndex = newAddressDrawerOpen;

    const newPageState = _.cloneDeep(pageState);

    const newLists = _.cloneDeep(lists);

    newPageState.values.policyPersons.beneficiaries[rowIndex]['address'] =
      address;
    newPageState.errors.policyPersons.beneficiaries[rowIndex]['address'] = '';
    newPageState.touched.policyPersons.beneficiaries[rowIndex]['address'] =
      true;

    newLists.beneficiaryAddresses[rowIndex] = {};
    newLists.beneficiaryAddresses[rowIndex][address] = addressFullName;

    newPageState.values.policyPersons.beneficiaries[rowIndex].address = address;
    newPageState.values.policyPersons.beneficiaries[rowIndex].addressDetails =
      addressFullName;

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldSearch = async (
    fieldName: string,
    value: any,
    rowIndex: number,
    touched = false,
    updatedList: any = lists
  ) => {
    const newLists = { ...updatedList };

    if (fieldName === 'id') {
      const result: Record<
        string,
        Record<string, string>
      > = (await handleBeneficiarySearch(value)) as any;
      newLists['id'][rowIndex] = result;
    }

    setLists(newLists);
  };

  const getDefaultPersonListIfEmpty = (
    list: Record<string, string>,
    beneficiary: IBeneficiaryData
  ) => {
    if (Object.keys(list || {}).length > 0 || !beneficiary.fullName) {
      return list;
    }

    const newList: Record<string, string> = {
      [beneficiary.id]: beneficiary.fullName,
    };

    return newList;
  };

  const renderBeneficiaryRow = (row: IBeneficiaryData) => {
    const rowIndex = beneficiaryValues.indexOf(row);
    return (
      <div
        className={
          maxNumber === 1 && beneficiaryValues.length === 1
            ? undefined
            : classes.container
        }
        key={rowIndex}
      >
        <div className={classes.clearBtn}>
          {!disabledForm && beneficiaryValues.length > 1 && (
            <button
              className={classes.removeBtn}
              onClick={() => onBeneficiaryRowDelete(rowIndex)}
            >
              <ClearIcon fontSize="small" />
            </button>
          )}
        </div>
        <div className={classes.fieldRow}>
          <NewChipsInput
            key="id"
            name="id"
            title="Beneficiary"
            placeholder="Beneficiary"
            value={beneficiaryValues[rowIndex].id}
            error={
              beneficiaryTouched[rowIndex].id
                ? beneficiaryErrors[rowIndex].id
                : ''
            }
            onChange={(v) => {
              const value = isArray(v) ? v[0] : v;
              onFieldUpdate('id', value, rowIndex);
            }}
            onSearch={async (v) => {
              const value = isArray(v) ? v[0] : v;
              onFieldSearch('id', value, rowIndex);
            }}
            clearValueOnSearch={false}
            disabled={disabledForm}
            items={getDefaultPersonListIfEmpty(
              lists?.id[rowIndex],
              beneficiaryValues[rowIndex]
            )}
            required={true}
            multiple={false}
            material={true}
            supportLink={disabledForm ? '' : 'Add Person'}
            onLinkClick={(e) => {
              e.preventDefault();
              handleOpenDrawer('beneficiary', rowIndex);
            }}
            customStyles={{
              labelStyles: classes.label,
            }}
          />

          <NewChipsInput
            key="address"
            name="address"
            title="Beneficiary Address"
            placeholder="Beneficiary Address"
            value={beneficiaryValues[rowIndex].address}
            clearValueOnSearch={false}
            error={
              beneficiaryTouched[rowIndex].address
                ? beneficiaryErrors[rowIndex].address
                : ''
            }
            onChange={(v) => onFieldUpdate('address', v, rowIndex)}
            disabled={disabledForm}
            items={lists.beneficiaryAddresses[rowIndex]}
            required={true}
            multiple={false}
            material={true}
            supportLink={
              disabledForm && !isGuid(beneficiaryValues?.[rowIndex]?.id)
                ? ''
                : 'Add Address'
            }
            onLinkClick={(e) => {
              e.preventDefault();
              if (isGuid(beneficiaryValues?.[rowIndex]?.id)) {
                handleOpenAddressDrawer('beneficiary', rowIndex);
              }
            }}
            customStyles={{
              labelStyles: classes.label,
            }}
          />

          <EnhancedPercentageInput
            key="percentage"
            name="percentage"
            title="Percentage"
            placeholder="Percentage"
            value={beneficiaryValues[rowIndex].percentage}
            error={
              beneficiaryTouched[rowIndex].percentage
                ? beneficiaryErrors[rowIndex].percentage
                : ''
            }
            onBlur={() => onFieldBlur('percentage', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('percentage', v.target.value, rowIndex)
            }
            disabled={disabledForm}
            material={true}
            className={classes.field}
            customStyles={{
              labelStyles: classes.label,
            }}
          />
        </div>
      </div>
    );
  };

  const onBeneficiaryRowAdd = () => {
    if (!disabledForm && beneficiaryValues.length < maxNumber) {
      const newPageState = _.cloneDeep(pageState);
      newPageState.values.policyPersons.beneficiaries.push({
        id: '',
        address: '',
        percentage: 0,
      });

      newPageState.errors.policyPersons.beneficiaries.push({
        id: '',
        percentage: '',
        address: '',
      });

      newPageState.touched.policyPersons.beneficiaries.push({
        id: false,
        percentage: false,
        address: false,
      });

      onPageStateUpdate(newPageState);
    }
  };

  const onBeneficiaryRowDelete = (rowIndex: number) => {
    if (!disabledForm) {
      const newPageState = _.cloneDeep(pageState);
      newPageState.values.policyPersons.beneficiaries = removeObjectAtIndex(
        newPageState.values.policyPersons.beneficiaries,
        rowIndex
      );
      newPageState.errors.policyPersons.beneficiaries = removeObjectAtIndex(
        newPageState.errors.policyPersons.beneficiaries,
        rowIndex
      );
      newPageState.touched.policyPersons.beneficiaries = removeObjectAtIndex(
        newPageState.touched.policyPersons.beneficiaries,
        rowIndex
      );

      setLists((prevLists) => {
        const newId: Record<number, Record<string, string>> = {};
        const newBeneficiaryAddresses: Record<
          number,
          Record<string, string>
        > = {};

        Object.keys(prevLists.id).forEach((key) => {
          const keyNum = Number(key);
          if (keyNum < rowIndex) {
            newId[keyNum] = prevLists.id[keyNum];
            newBeneficiaryAddresses[keyNum] =
              prevLists.beneficiaryAddresses[keyNum];
          } else if (keyNum > rowIndex) {
            newId[keyNum - 1] = prevLists.id[keyNum];
            newBeneficiaryAddresses[keyNum - 1] =
              prevLists.beneficiaryAddresses[keyNum];
          }
        });

        return {
          id: newId,
          beneficiaryAddresses: newBeneficiaryAddresses,
          primaryAddresses: {},
        };
      });

      onPageStateUpdate(newPageState);
    }
  };

  if (!loaded) {
    return null;
  }

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Beneficiary"
        hasTitleSpecificDesign={false}
        useSeparator={false}
      >
        {beneficiaryValues.map((v) => renderBeneficiaryRow(v))}
        {newPersonDrawerOpen !== -1 && currentSection === 'beneficiary' && (
          <ProposalPolicyPersonDrawer
            open={newPersonDrawerOpen !== -1}
            onClose={() => setNewPersonDrawerOpen(-1)}
            section={currentSection}
            planId={''}
            lineExternalCode={data?.LineId?.ExternalCode}
            onSuccess={() => undefined}
            proposalId={entityId}
            onSubmitSuccess={onPersonAddition}
          />
        )}
        {newAddressDrawerOpen !== -1 && currentSection === 'beneficiary' && (
          <PersonAddressDrawer
            open={newAddressDrawerOpen !== -1}
            onClose={() => setNewAddressDrawerOpen(-1)}
            personId={beneficiaryValues?.[newAddressDrawerOpen]?.id || ''}
            onSuccess={() => undefined}
            onSubmitSuccess={onAddressAddition}
          />
        )}
        {beneficiaryValues.length < maxNumber && (
          <button
            className={classes.addBtn}
            onClick={() => onBeneficiaryRowAdd()}
          >
            <AddCircleOutlineIcon color="error" fontSize="small" />
          </button>
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default PolicyBeneficiaryWidget;
