import { IExpatPlanRatesDrawerInfo } from '.';
import { getIdsFromMultiselectData } from '../../utils/formatting-utils';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const expatPlanRatesEnums: Record<string, Record<string, string>> = {};

  expatPlanRatesEnums['currencies'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  expatPlanRatesEnums['genders'] = enumListAsRecordObject(
    data?.Insurance_Genders?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  expatPlanRatesEnums['medicalClasses'] = lookupListAsRecordObject(
    data?.Core?.lookups?.medicalClasses
  );

  expatPlanRatesEnums['emergencyOptions'] = enumListAsRecordObject(
    data?.PlanConfigManagement_EmergencyOptions?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  expatPlanRatesEnums['occupations'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.occupationClasses
  );

  expatPlanRatesEnums['nationalities'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.nationalities
  );

  return expatPlanRatesEnums;
}

export function graphqlToExpatPlanRatesInfo(
  data: any
): IExpatPlanRatesDrawerInfo {
  const expatPlanRates =
    data?.PlanConfigManagement?.entities?.planSpecificExpat?.views
      ?.PlanConfigManagement_all?.properties;

  return {
    ageFrom: expatPlanRates?.AgeFrom,
    ageTo: expatPlanRates?.AgeTo,
    gender: expatPlanRates?.Gender,
    nationality: getIdsFromMultiselectData(expatPlanRates?.Nationality),
    occupationClass: expatPlanRates?.OccupationClass?.Id,
    premium: expatPlanRates?.Premium,
    medicalClass: expatPlanRates?.MedicalClass?.Id,
    emergency: expatPlanRates?.Emergency,
  };
}
