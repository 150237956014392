export interface IValidator {
  type: ValidatorType;
}

export interface ITextValidator extends IValidator {
  compareTo: string;
  error: string;
  type: ValidatorType.Equal;
}

export enum ValidatorType {
  Equal = 'Equal',
}

export type ValidatorTypes = ITextValidator;
