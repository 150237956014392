import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const defaultCommissionEnums: Record<string, Record<string, string>> = {};

  defaultCommissionEnums['commissionTypes'] = enumListAsRecordObject(
    data?.Core_CommissionTypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  defaultCommissionEnums['currencies'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  defaultCommissionEnums['commissionApplicableOnOptions'] =
    lookupListAsRecordObject(
      data?.Core?.lookups?.commissionApplicableOnOptions
    );

  const yesOrNoArray = [
    { Id: 'Yes', Title: 'Yes' },
    { Id: 'No', Title: 'No' },
  ];
  defaultCommissionEnums['specialCommissionApplicable'] =
    lookupListAsRecordObject(yesOrNoArray);

  return defaultCommissionEnums;
}
