import { IPersistDocument } from '.';

export interface ITransaction {
  company: string;
  transactionType: string;
  reference: string;
  number: string;
  date: string;
  description: string;
  note: string;
  TransactionDocuments: IPersistDocument[];
}

export interface IAccountCurrency {
  id: string;
  accountName: string;
  description: string;
  accountDebit: number;
  accountCredit: number;
  systemDebit: number;
  systemCredit: number;
  systemSecondaryDebit: number;
  systemSecondaryCredit: number;
}

export interface ITransactionFormState {
  values: {
    transaction: ITransaction;
    account: IAccountCurrency[];
    delete: string[];
  };
  errors: {
    transaction: Record<string, string>;
    account: Record<string, string>[];
  };
  touched: {
    transaction: Record<string, boolean>;
    account: Record<string, boolean>[];
  };
}

export const initialFormValues: ITransaction = {
  company: '',
  transactionType: '',
  reference: '',
  number: '',
  date: '',
  description: '',
  note: '',
  TransactionDocuments: [],
};
