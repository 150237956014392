import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  TextField,
  IconButton,
  Box,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { IEnhancedFormInputBaseProps } from '.';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export interface IAutocompleteFormFieldProps
  extends IEnhancedFormInputBaseProps {
  value: string;
  loader?: boolean;
  freeSolo?: boolean;
  onChange?: (value: string) => void;
  classes?: { input?: string; wrapper?: string };
  maxItemsDisplayed?: number;
  fetchOptions: (
    query: string,
    pagination: { pageNumber: number; pageSize: number }
  ) => Promise<{
    options: Record<string, string>;
    totalCount: number;
  }>;
}

const useStyles = makeStyles<{
  error?: string;
}>()((theme, { error }) => ({
  inputContainerStyles: {
    width: '100%',
    marginBottom: '5px',
  },
  inputWrapper: {
    position: 'relative',
  },
  inputStyles: {
    width: '100%',
    border: '1px solid #E3E3E3',
    borderRadius: '5px',
    outline: 'none',
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontStyle: 'italic',
        fontFamily: contentFontFamilyRegular,
        color: '#33333333',
        opacity: 1,
        fontSize: '14px',
      },
    },
    '& .MuiInputBase-root': {
      fontSize: `13px`,
      lineHeight: '15px',
      minHeight: '35px',
      width: '100%',
      padding: '0 25px 0 15px!important',
      margin: '0 auto',
      backgroundColor: `#f9f9f9`,
      outlineColor: error ? theme.palette.error.main : undefined,
      border: error
        ? `1px solid ${theme.palette.error.main}`
        : `0px solid #DFE3EB`,
      boxSizing: 'border-box',
      borderRadius: '5px',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  optionDesign: {
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
    lineHeight: '15px',
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontFamily: contentFontFamilyRegular,
  },
  paginationIcons: {
    display: 'flex',
    gap: '5px',
    justifyContent: 'end',
    alignItems: 'center',
    fontSize: '14px',
    padding: '5px 7px 0 0',
    '& .MuiSvgIcon-root': {
      width: '0.8em',
    },
    '& .MuiIconButton-root': {
      padding: '2px',
    },
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    right: '3%',
    opacity: 1,
    height: '25px',
    width: '25px',
  },
  selectMaterial: {
    '& .MuiInput-input': {
      fontSize: '13px',
      lineHeight: '15px',
      color: `#07295A`,
    },
    '& .MuiInputBase-root:before': {
      border: 'none',
      backgroundColor: 'unset',
      borderRadius: '0',
      borderBottom: `1px solid #DFE3EB`,
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid #DFE3EB`,
      },
  },
  selectError: {
    '& .MuiInputBase-root:before': {
      borderBottom: `1px solid ${theme.palette.error.main}`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid ${theme.palette.error.main}`,
      },
    '& .MuiInputBase-root:focus': {
      borderColor: theme.palette.error.main,
      outlineColor: theme.palette.error.main,
      boxShadow: 'none',
      borderRadius: 0,
    },
    '& .MuiInputBase-root': {
      borderColor: `${theme.palette.error.main}`,
      outlineColor: `${theme.palette.error.main}`,
    },
  },
}));

export interface IAutocompletePagination {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

function getPaginationRange(
  pageNumber: number,
  pageSize: number,
  totalCount: number
) {
  const from = (pageNumber - 1) * pageSize + 1;
  const to = Math.min(pageNumber * pageSize, totalCount);
  return `${from} – ${to} of ${totalCount}`;
}

const AutocompleteFormField: React.FC<IAutocompleteFormFieldProps> = ({
  name,
  title,
  placeholder,
  className,
  style,
  classes = {},
  value,
  error,
  onChange,
  disabled,
  loader,
  inputOnly,
  freeSolo = false,
  material,
  maxItemsDisplayed = 10,
  fetchOptions,
}) => {
  const { classes: inputClasses } = useStyles({ error });
  const [options, setOptions] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IAutocompletePagination>({
    pageNumber: 1,
    pageSize: maxItemsDisplayed,
    totalCount: 0,
  });
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    getOptions('', pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOptions = (
    value: string,
    paginationValues: IAutocompletePagination
  ) => {
    setLoading(true);
    fetchOptions(value, {
      pageSize: paginationValues.pageSize,
      pageNumber: paginationValues.pageNumber,
    })
      .then(({ options, totalCount }) => {
        setOptions(options);
        setPagination({
          ...paginationValues,
          totalCount,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderInput = () => {
    const optionsLength = Object.keys(options).length;

    return (
      <div className={inputClasses.inputContainerStyles}>
        <FormControl
          error={!!error}
          disabled={!!disabled}
          className={inputClasses.inputContainerStyles}
          size="small"
          variant="outlined"
        >
          <Autocomplete
            freeSolo={freeSolo}
            multiple={false}
            disabled={!!disabled}
            disablePortal={false}
            options={Object.keys(options)}
            onInputChange={(event, newInputValue) => {
              if (newInputValue !== inputValue) {
                setInputValue(newInputValue);
                if (event?.type !== 'click') {
                  getOptions(newInputValue, {
                    pageNumber: 1,
                    pageSize: maxItemsDisplayed,
                    totalCount: pagination.totalCount,
                  });
                }
                if (isEmpty(newInputValue)) {
                  onChange?.(newInputValue);
                  getOptions(newInputValue, {
                    pageNumber: 1,
                    pageSize: maxItemsDisplayed,
                    totalCount: pagination.totalCount,
                  });
                }
              }
            }}
            onBlur={() => {
              if (!Object.keys(options)?.length || isEmpty(options[value])) {
                setInputValue('');
                onChange?.('');
                getOptions('', {
                  pageNumber: 1,
                  pageSize: maxItemsDisplayed,
                  totalCount: pagination.totalCount,
                });
              }
            }}
            onChange={(event, value) => {
              onChange?.(value);
            }}
            value={value}
            getOptionLabel={(option) => options[option] || option}
            disableClearable={true}
            selectOnFocus
            loading={loading}
            noOptionsText={loading ? 'Fetching data...' : 'No data'}
            renderOption={(props, option, state) => {
              const showPagination =
                optionsLength < pagination.totalCount &&
                state.index === optionsLength - 1;
              return (
                <>
                  <li {...props} key={option}>
                    <span className={inputClasses.optionDesign}>
                      {options[option]}
                    </span>
                  </li>
                  {showPagination && (
                    <Box className={inputClasses.paginationIcons}>
                      {getPaginationRange(
                        pagination.pageNumber,
                        pagination.pageSize,
                        pagination.totalCount
                      )}
                      <IconButton
                        size="small"
                        disabled={pagination.pageNumber === 1 || loading}
                        onClick={(e) => {
                          e.stopPropagation();
                          const pageNumber = Math.max(
                            pagination.pageNumber - 1,
                            1
                          );
                          getOptions(inputValue, {
                            pageSize: pagination.pageSize,
                            pageNumber,
                            totalCount: pagination.totalCount,
                          });
                        }}
                      >
                        <NavigateBeforeIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        disabled={loading}
                        onClick={(e) => {
                          e.stopPropagation();
                          const pageNumber = pagination.pageNumber + 1;
                          getOptions(inputValue, {
                            pageSize: pagination.pageSize,
                            pageNumber,
                            totalCount: pagination.totalCount,
                          });
                        }}
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </Box>
                  )}
                </>
              );
            }}
            renderTags={(value, getTagProps) =>
              Array.isArray(value) &&
              (value as readonly string[]).map(
                (option: string, index: number) =>
                  !isEmpty(options[option]) ? (
                    <Chip
                      variant="filled"
                      label={options[option]}
                      style={{
                        height: 'unset',
                        color: '#fff',
                        backgroundColor: '#243746',
                        borderRadius: '0px',
                      }}
                      {...getTagProps({ index })}
                      key={index}
                    />
                  ) : (
                    <Chip
                      variant="filled"
                      style={{
                        height: 'unset',
                        color: '#fff',
                        backgroundColor: '#243746',
                        borderRadius: '0px',
                      }}
                      label={option}
                      {...getTagProps({ index })}
                      key={index}
                    />
                  )
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                placeholder={placeholder}
                className={clsx(
                  !material && inputClasses.inputStyles,
                  material && inputClasses.selectMaterial,
                  error && inputClasses.selectError
                )}
                variant="standard"
              />
            )}
          />
        </FormControl>
      </div>
    );
  };

  return inputOnly ? (
    renderInput()
  ) : (
    <>
      <EnhancedInputsWrapper
        title={title}
        error={error}
        name={name}
        className={clsx(classes.wrapper, className)}
        style={style}
      >
        {renderInput()}
        {loader && (
          <CircularProgress
            className={inputClasses.loader}
            size={20}
            thickness={3}
          />
        )}
      </EnhancedInputsWrapper>
    </>
  );
};

export default AutocompleteFormField;
