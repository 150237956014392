import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  lineOfBusiness: '',
  agencyId: '',
  agentId: '',
};

export const lineOfBusinessLovs: Record<string, string> = {
  Motor: 'Motor',
  Health: 'Health',
  Expat: 'Expat',
};

export const inputs: Record<string, DynamicFormInputType> = {
  lineOfBusiness: {
    name: 'lineOfBusiness',
    title: 'Line Of Business',
    type: FormInputTypes.chips,
    placeholder: 'Line Of Business',
    value: !isEmpty(initialValues.lineOfBusiness)
      ? initialValues.lineOfBusiness
      : [],
    required: true,
    disabled: true,
    selectOptions: lineOfBusinessLovs,
    multiple: false,
  },
  agency: {
    name: 'agency',
    title: 'Business Partner',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.agencyId) ? initialValues.agencyId : [],
    required: true,
    multiple: false,
  },
  agent: {
    name: 'agent',
    title: 'Business User',
    type: FormInputTypes.text,
    value: !isEmpty(initialValues.agentId) ? initialValues.agentId : '',
    required: true,
    disabled: true,
  },
};
