import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getError } from '../../utils/graph-utils';
import { currencyLookup, updateSystemCurrency } from './queries';
import { getEnvironmentSettings } from '../settings/queries';
import { cloneDeep } from 'lodash';
import { LookupToList } from './utils';
import { getSystemCurrencies } from '../system-currency-config/queries';

const SystemCurrencyConfigDrawer: React.FC<ISystemCurrencyConfigDrawer> = ({
  open,
  systemCurrencyId,
  basicCurrencyId,
  basicCurrencyTitle,
  secondaryCurrencyId,
  secondaryCurrencyTitle,
  onSuccess,
  onClose,
}) => {
  const [booted, setBooted] = useState<boolean>(false);

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [currencyLookupResultsQuery] = useLazyQuery(currencyLookup());

  const [updateSystemCurrencyAction] = useMutation(updateSystemCurrency(), {
    refetchQueries: [getSystemCurrencies()],
    awaitRefetchQueries: true,
  });

  const loadLovList = async () => {
    const result = await currencyLookupResultsQuery({
      fetchPolicy: 'no-cache',
    });

    const newSystemCurrencyLookups = LookupToList(result.data);

    return newSystemCurrencyLookups;
  };

  const [runMutation] = useMutation(getEnvironmentSettings(), {
    update(cache, { data }) {
      cache.writeQuery({
        query: getEnvironmentSettings(),
        data: {
          insurance: data.insurance,
        },
      });
    },
    awaitRefetchQueries: true,
  });

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const newCurrencyLookup = await loadLovList();

      (updatedInputs.basicCurrency as IFormSelectDynamicProps).selectOptions =
        newCurrencyLookup['currencies'];
      (
        updatedInputs.secondaryCurrency as IFormSelectDynamicProps
      ).selectOptions = newCurrencyLookup['currencies'];

      updatedInputs.basicCurrency.value = basicCurrencyId;
      updatedInputs.secondaryCurrency.value = secondaryCurrencyId;

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const mutationResult = await updateSystemCurrencyAction({
        variables: {
          entityId: systemCurrencyId,
          basicCurrency: data.basicCurrency,
          secondaryCurrency: data.secondaryCurrency,
        },
      });

      if (!mutationResult.data.errors) {
        await runMutation();

        toast.success(
          <ToastSuccessMessage>
            {'System Currency details successfully updated.'}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setSubmitButtonState('success');
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{getError(mutationResult.data)}</ToastErrorMessage>
        );
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <GenericDrawer
      title={'Modify System Currency'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Update'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SystemCurrencyConfigDrawer;
