import React, { useEffect, useState } from 'react';
import EnhancedStepper from '../../components/common/EnhancedStepper';
import EntityInfoWidget from '../../components/widgets/entity-info/EntityInfoWidget';
import { entityView, policyTabs, steps } from './content';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  generateCommissionDocument,
  getPolicyDetails,
  regeneratePolicyDocument,
} from './queries';
import { useParams } from 'react-router-dom';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';

import { IPolicyDetails, initialValues } from '.';

import { getDefaultDate } from '../../utils/date-utils';

import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from '../../constants';

import dayjs from 'dayjs';
import TabsLayout from '../../page-layout/tabs-layout/TabsLayout';
import { useAppSelector } from '../../redux/hooks';
import { entityToPolicyDetails, graphqlEntityToQuoteDetails } from './utils';
import { IDynamicDiplayView } from '../../components/widgets/dynamic-display';
import DynamicWidget from '../../components/widgets/dynamic-display/DynamicWidget';
import {
  capitalizeFirstLetter,
  formatDate,
} from '../../utils/formatting-utils';
import QuotesCards, { quote } from '../../components/custom/QuotesCards';
import DocumentWidget from '../../components/widgets/file-card/DocumentWidget';
import {
  FileType,
  FileUploadStatus,
  IFile,
  IFileCardProps,
} from '../../models/file';
import EnhancedButton, {
  EnhancedButtonStatus,
} from '../../components/EnhancedButton';
import PolicyDetailsDrawer from '../policy-details-drawer/PolicyDetailsDrawer';
import { IPolicyDetailsDrawerInfo } from '../policy-details-drawer';
import ShowForUser from '../../components/user/ShowForUser';
import Chip from '@mui/material/Chip';
import { isEmpty } from 'lodash';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { getError } from '../../utils/graph-utils';
import Separator from '../../components/common/Separator';
// import { Insurance_FileDownload } from '../../gql/__generated__/graphql';
import DataService from '../../services/dataService';
import { generateDownloadLink } from '../../utils/file-utils';
import { gridWidthToPercentage } from '../../utils/grid-utils';
import { IEnhancedMenuItem } from '../../components';
import SimpleActionBar from '../../components/SimpleActionBar';
import CancelPolicyDrawer from '../cancel-policy-drawer/CancelPolicyDrawer';
import _ from 'lodash';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '95%',
    width: '95%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  section: {
    display: 'grid',
    'grid-template-columns': '32% 32% 32%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  sectionFullRow: {
    display: 'grid',
    'grid-template-columns': '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  row: {
    flexWrap: 'wrap',
    margin: '10px',
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
    '& label': {
      flexGrow: '1',
      flexBasis: '0',
      minWidth: '0',
      maxWidth: '25%',
    },
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    'grid-column-start': '1',
    'grid-column-end': '4',
  },
  field: {
    width: '100%',
    marginRight: '10px !important',
  },
  arField: {
    width: '100%',
    marginRight: '10px !important',
    direction: 'rtl',
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    '&:hover': {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: '0',
  },
  tabPanelStyle: {
    padding: 'unset',
    marginTop: '20px',
  },
  dirtyChip: {
    margin: 0,
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
  actionButton: {
    display: 'inline-block',
    marginRight: '5px',
  },
}));

const PolicyDetailsPage: React.FC = () => {
  const { classes } = useStyles();
  const [showStepperBtn, setShowStepperBtn] = useState<boolean>(false);
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);

  const isAdmin = user.userRoles.includes('Insurance-Admin');

  const [policyInfo, setPolicyInfo] = useState<IPolicyDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [policyDrawerOpen, setPolicyDrawerOpen] = useState<boolean>(false);

  const [cancelDrawerOpen, setCancelDrawerOpen] = useState<boolean>(false);

  const [booted, setBooted] = useState<boolean>(false);

  const [regenerateButtonState, setRegenerateButtonState] =
    useState<EnhancedButtonStatus>();
  const [_generateButtonState, setGenerateButtonState] =
    useState<EnhancedButtonStatus>();

  const [getPolicyDetailsLazy, entityResult] = useLazyQuery(
    getPolicyDetails(),
    {
      variables: { id: params.id },
    }
  );

  const [regeneratePolicyDocumentAction] = useMutation(
    regeneratePolicyDocument(),
    {
      variables: {
        id: params.id,
      },
      refetchQueries: [getPolicyDetails()],
    }
  );

  const [generateCommissionDocumentAction] = useMutation(
    generateCommissionDocument(),
    {
      variables: {
        id: params.id,
      },
    }
  );

  const [summaryViewConfig, setSummaryViewConfig] =
    useState<IDynamicDiplayView>();

  const documentWidgetFiles: IFileCardProps[] = [];
  const additionalDocumentWidgetFiles: IFileCardProps[] = [];

  useEffect(() => {
    getPolicyDetailsLazy();
  }, []);

  useEffect(() => {
    try {
      setBooted(false);
      const isIssuingAgent = user.userRoles.includes('Insurance-IssuingAgent');

      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        if (
          isIssuingAgent &&
          entityResult?.data?.Insurance?.entities?.policy?.views?.Insurance_all
            ?.properties?.AssignedAgentID?.views?.Insurance_all?.properties
            ?.CanViewCommission
        ) {
          setShowStepperBtn(true);
        }
        const policyDetails = entityToPolicyDetails(entityResult?.data);

        policyDetails.createdOn = getDefaultDate(
          dayjs(
            policyDetails.createdOn
              ? new Date(policyDetails.createdOn)
              : new Date()
          ).toDate()
        ).toDateString();

        //get policy section properties
        const policyProperties = entityView.sections[0];
        policyProperties.properties.stateReason.value =
          policyDetails.statusReason;
        policyProperties.properties.lineOfBusiness.value =
          policyDetails.lineOfBusiness;
        policyProperties.properties.productCode.value = policyDetails.plan;
        policyProperties.properties.agency.value = policyDetails.agencyName;
        policyProperties.properties.agentName.value = policyDetails.agentName;

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value = policyDetails.createdBy;
        //before setting date in date picker, use dayjs to format the datetime in case the value is coming from BE
        systemProperties.properties.createdOn.value = dayjs(
          new Date(policyDetails.createdOn)
        ).format('DD/MM/YYYY');
        systemProperties.properties.modifiedBy.value = policyDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(policyDetails.modifiedOn)
        ).format('DD/MM/YYYY');

        const newLeftEntityView = {
          ...entityView,
          sections: [policyProperties, systemProperties],
        };

        setPolicyInfo(policyDetails);
        setUpdatedLeftSideInfo(newLeftEntityView);

        const status =
          entityResult?.data?.Insurance?.entities?.policy?.views?.Insurance_all
            ?.properties?.PolicyStatus?.Code;

        const dynamicView: IDynamicDiplayView = {
          name: 'Company',
          sections: [
            {
              name: 'policyDetails',
              title: 'Policy Details',
              middle: policyDetails?.pendingDocumentRegeneration ? (
                <Chip
                  label={
                    'Policy successfully updated. Regenerate the documents to reflect the changes'
                  }
                  className={classes.dirtyChip}
                />
              ) : (
                <></>
              ),
              properties: {
                policyNumber: {
                  multiline: false,
                  value: policyDetails.fullPolicyNumber,
                  name: 'policyNumber',
                  title: 'Policy Number',
                },
                isRenewal: {
                  multiline: false,
                  value: capitalizeFirstLetter(policyDetails.isRenewal),
                  name: 'isRenewal',
                  title: 'Renewal',
                },
                renewalNumber: {
                  multiline: false,
                  value: policyDetails.renewalNumber,
                  name: 'renewalNumber',
                  title: 'Renewal Number',
                  hidden: policyDetails.isRenewal == 'NO',
                },
                policyEffectiveDate: {
                  multiline: false,
                  value: formatDate(policyDetails.policyEffectiveDate),
                  name: 'policyEffectiveDate',
                  title: 'Policy Effective Date',
                },
                policyIssueDate: {
                  multiline: false,
                  value: formatDate(policyDetails.policyIssueDate),
                  name: 'policyIssueDate',
                  title: 'Policy Issue Date',
                },
                policyExpiryDate: {
                  multiline: false,
                  value: formatDate(policyDetails.policyExpiryDate),
                  name: 'policyExpiryDate',
                  title: 'Policy Expiry Date',
                },
                policyCancelationDate: {
                  multiline: false,
                  value: formatDate(policyDetails.cancelationEffectiveDate),
                  name: 'policyCancelationDate',
                  title: 'Policy Cancelation Effective Date',
                  hidden: status !== 'Canceled',
                },
                plan: {
                  multiline: false,
                  value: policyDetails.plan,
                  name: 'plan',
                  title: 'Product',
                },
                towingService: {
                  multiline: false,
                  value: policyDetails.towingService,
                  name: 'towingService',
                  title: 'Towing Service',
                },
                vignetteCode: {
                  multiline: false,
                  value: policyDetails.vignetteCode,
                  name: 'vignetteCode',
                  title: 'Vignette Code',
                  hidden: policyDetails.productCode != 'BodilyInjury',
                },
                vignette: {
                  multiline: false,
                  value: policyDetails.vignette,
                  name: 'vignette',
                  title: 'Vignette',
                  hidden: policyDetails.productCode != 'BodilyInjury',
                },
              },
            },
            {
              name: 'customerDetails',
              title: 'Customer Details',
              showActionButtons: false,
              properties: {
                Country: {
                  multiline: false,
                  value: policyDetails.nameOnLicenseEn,
                  name: 'NameOnLicenseEn',
                  title: 'Name on License (EN)',
                },
                nameOnLicenseAr: {
                  multiline: false,
                  value: policyDetails.nameOnLicenseAr,
                  name: 'NameOnLicenseAr',
                  title: 'Name on License (AR)',
                },
                email: {
                  multiline: false,
                  value: policyDetails.email,
                  name: 'email',
                  title: 'Email',
                },
                phoneType: {
                  multiline: false,
                  value: policyDetails.detailsPhoneType,
                  name: 'phoneType',
                  title: 'Phone Type',
                },
                mobileNumber: {
                  multiline: false,
                  value: policyDetails.mobileNumber,
                  name: 'mobilenumber',
                  title: 'Phone Number',
                },
                country: {
                  multiline: false,
                  value: policyDetails.country,
                  name: 'country',
                  title: 'Country',
                },
                city: {
                  multiline: false,
                  value: policyDetails.city,
                  name: 'city',
                  title: 'City',
                },
                street: {
                  multiline: false,
                  value: policyDetails.street,
                  name: 'street',
                  title: 'Street',
                },
                building: {
                  multiline: false,
                  value: policyDetails.building,
                  name: 'building',
                  title: 'Building',
                },
              },
            },
            {
              name: 'vehicleDetails',
              title: 'Vehicle Details',
              showActionButtons: false,
              properties: {
                plateCode: {
                  multiline: false,
                  value: policyDetails.plateCode,
                  name: 'plateCode',
                  title: 'Plate Code',
                },
                plateNumber: {
                  multiline: false,
                  value: policyDetails.plateNumber,
                  name: 'plateNumber',
                  title: 'Plate Number',
                },
                usageType: {
                  multiline: false,
                  value: capitalizeFirstLetter(policyDetails.usageType),
                  name: 'usageType',
                  title: 'Usage',
                },
                bodyType: {
                  multiline: false,
                  value: policyDetails.bodyType,
                  name: 'bodyType',
                  title: 'Body',
                },
                engineType: {
                  multiline: false,
                  value: capitalizeFirstLetter(policyDetails.engineType),
                  name: 'engineType',
                  title: 'Engine Type',
                },
                brand: {
                  multiline: false,
                  value: capitalizeFirstLetter(policyDetails.brand),
                  name: 'brand',
                  title: 'Make',
                },
                model: {
                  multiline: false,
                  value: capitalizeFirstLetter(policyDetails.model),
                  name: 'model',
                  title: 'Model',
                },
                yearOfMake: {
                  multiline: false,
                  value: policyDetails.yearOfMake,
                  name: 'yearOfMake',
                  title: 'Year of Make',
                },
                motor: {
                  multiline: false,
                  value: policyDetails.motor,
                  name: 'motor',
                  title: 'Engine',
                },
                weight: {
                  multiline: false,
                  value: policyDetails.weight,
                  name: 'weight',
                  title: 'Weight (in Tons)',
                },
                engineSize: {
                  multiline: false,
                  value: policyDetails.engineSize,
                  name: 'engineSize',
                  title: 'Engine Size (in cc)',
                },
                seats: {
                  multiline: false,
                  value: policyDetails.seats,
                  name: 'seats',
                  title: 'Seating Capacity',
                },
                chassis: {
                  multiline: false,
                  value: policyDetails.chassis,
                  name: 'chassis',
                  title: 'Chassis',
                },
                horsePower: {
                  multiline: false,
                  value: policyDetails.horsePower,
                  name: 'horsePower',
                  title: 'Horse Power',
                },
              },
            },
          ],
        };
        setSummaryViewConfig(dynamicView);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    setBooted(true);
  }, [entityResult?.data]);

  const getPolicyStatus = () => {
    switch (
      entityResult?.data?.Insurance?.entities?.policy?.views?.Insurance_all
        ?.properties?.PolicyStatus?.Code
    ) {
      case 'Bound':
        return steps[0];
      case 'Closed':
        return steps[1];
      case 'Canceled':
        return 'Canceled';
      default:
        return steps[0];
    }
  };

  const addFileToDocumentWidgetFiles = (
    file: IFile,
    propertyId: string,
    entityTypeId: string,
    hideReviewedLabel = true,
    createdOn?: string,
    version?: number,
    isNewPolicyDocuments = false,
    documentCode = ''
  ) => {
    const newFile: IFileCardProps = {
      title: isNewPolicyDocuments ? documentCode : file.fileName,
      status: FileUploadStatus.Uploaded,
      createdOn: createdOn,
      version: version,
      fileInfo: {
        id: file?.id,
        location: file?.location,
        path: file?.path,
        fileName: file?.fileName,
        length: file?.length,
        contentType: file?.contentType,
      },
      allowedFileTypes: !isNewPolicyDocuments
        ? [FileType.pdf]
        : [FileType.pdf, FileType.docx, FileType.jpeg, FileType.png],
      entityViewId: 'Insurance-download',
      propertyId: propertyId,
      entityId: file?.path?.split('/')[2],
      entityTypeId: entityTypeId,
      hideReviewedLabel: hideReviewedLabel,
    };
    isNewPolicyDocuments
      ? additionalDocumentWidgetFiles.push(newFile)
      : documentWidgetFiles.push(newFile);
  };

  const renderActions = () => {
    if (!booted) {
      return <></>;
    }

    const status =
      entityResult?.data?.Insurance?.entities?.policy?.views?.Insurance_all
        ?.properties?.PolicyStatus?.Code;
    const actions: IEnhancedMenuItem[] = [
      {
        title: 'View Commission',
        onClick: () => {
          setGenerateButtonState('loading');
          generateCommissionDocumentAction()
            .then((result) => {
              if (isEmpty(result.errors)) {
                setGenerateButtonState('success');

                const listOfFiles =
                  result?.data?.insurance?.entities?.policy?.insurance
                    ?.generateCommissionDocument?.Items;

                if (listOfFiles) {
                  listOfFiles.forEach(
                    async (fileElement: any) =>
                      await DataService.downloadAndOpenFileWithToken(
                        generateDownloadLink(
                          fileElement.File.id,
                          fileElement.EntityTypeId,
                          fileElement.EntityId,
                          fileElement.EntityViewId,
                          fileElement.PropertyId
                        )
                      )
                  );
                }

                toast.success(
                  <ToastSuccessMessage>
                    Commission Document successfully downloaded
                  </ToastSuccessMessage>
                );
              } else {
                setGenerateButtonState(undefined);
                toast.error(
                  <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
                );
              }
            })
            .finally(() => {
              setGenerateButtonState(undefined);
            });
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        hidden: !showStepperBtn,
        disabled: !!_generateButtonState,
      },
      {
        title: 'Cancel Policy',
        onClick: () => {
          setCancelDrawerOpen(true);
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        disabled: false,
        hidden:
          !(isAdmin || user.info.agentType === 'INTERNAL') ||
          !status ||
          status === 'Canceled',
      },
    ];

    return <SimpleActionBar items={actions} />;
  };

  const renderMainChildren = () => {
    const status =
      entityResult?.data?.Insurance?.entities?.policy?.views?.Insurance_all
        ?.properties?.PolicyStatus?.Code;
    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ width: `${gridWidthToPercentage(10)}%` }}>
              <EnhancedStepper
                activeStep={getPolicyStatus()}
                steps={status === 'Canceled' ? ['Bound', 'Canceled'] : steps}
                className={classes.stepper}
                showStepperButton={false}
              />
            </div>
            <div style={{ width: `${gridWidthToPercentage(2)}%` }}>
              {renderActions()}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: '' }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={policyInfo.customerName}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };

  const tabs = () => {
    const quotes: quote[] = [
      graphqlEntityToQuoteDetails(entityResult?.data, tenant.currencySymbol),
    ];

    const applicationStatus =
      entityResult?.data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties?.PolicyStatus?.Title.toLowerCase();

    if (
      policyInfo.regeneratedDocuments &&
      policyInfo.regeneratedDocuments.length > 0
    ) {
      policyInfo.regeneratedDocuments.map((policyDocument) => {
        addFileToDocumentWidgetFiles(
          policyDocument.file,
          'PolicyDocument',
          'Insurance-PolicyDocuments',
          true,
          policyDocument.createdOn,
          policyDocument.version,
          false
        );
      });
    }

    if (
      policyInfo.additionalDocuments &&
      policyInfo.additionalDocuments.length > 0
    ) {
      policyInfo.additionalDocuments.map((policyDocument) => {
        addFileToDocumentWidgetFiles(
          policyDocument.file,
          'PolicyDocument',
          'Insurance-PolicyDocuments',
          true,
          policyDocument.createdOn,
          policyDocument.version,
          true,
          policyDocument.code
        );
      });
    }

    if (policyInfo.policyDocument.id) {
      addFileToDocumentWidgetFiles(
        policyInfo.policyDocument,
        'PolicyDocument',
        'Insurance-Policy',
        true,
        policyInfo.createdOn,
        1
      );
    }
    if (policyInfo.additionalDocument.id) {
      addFileToDocumentWidgetFiles(
        policyInfo.additionalDocument,
        'AdditionalDocument',
        'Insurance-PolicyMotorDetails',
        true,
        policyInfo.createdOn,
        1
      );
    }

    const newTabs = _.cloneDeep(policyTabs);

    newTabs.tabs[0].widgets[0].children = summaryViewConfig && (
      <DynamicWidget
        hasFourFields={true}
        view={summaryViewConfig}
        actions={
          <ShowForUser
            allowedRoles={['Insurance-Admin', 'Insurance-IssuingAgent']}
          >
            <div>
              <EnhancedButton
                type="button"
                backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                color="#FFF"
                onClick={() => setPolicyDrawerOpen(true)}
                className={classes.actionButton}
              >
                Modify
              </EnhancedButton>
              {policyInfo?.pendingDocumentRegeneration && (
                <EnhancedButton
                  type="button"
                  state={regenerateButtonState}
                  backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                  color="#FFF"
                  className={classes.actionButton}
                  onClick={() => {
                    setRegenerateButtonState('loading');
                    regeneratePolicyDocumentAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setRegenerateButtonState('success');
                          toast.success(
                            <ToastSuccessMessage>
                              Documents successfully regenerated
                            </ToastSuccessMessage>
                          );
                        } else {
                          setRegenerateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setRegenerateButtonState(undefined);
                      });
                  }}
                  style={{ display: 'inline-block' }}
                >
                  Regenerate Policy Document
                </EnhancedButton>
              )}
            </div>
          </ShowForUser>
        }
      />
    );

    newTabs.tabs[1].widgets[0].children = (
      <QuotesCards
        entityId={params.id}
        applicationStatus={applicationStatus}
        quotes={quotes}
        disabled
      />
    );

    if (isAdmin || user.userRoles.includes('Insurance-IssuingAgent')) {
      newTabs.tabs[2].widgets[0].children = (
        <>
          <DocumentWidget
            title="Policy Documents"
            cdnUrl={tenant.cdnUrl}
            files={documentWidgetFiles}
          />
          <Separator className={classes.thickSeperator} />
          <DocumentWidget
            title="Additional Documents"
            cdnUrl={tenant.cdnUrl}
            files={additionalDocumentWidgetFiles}
            disabledAddDocumentAction={false}
            userCanUploadAdditionalDocument={
              policyInfo?.userCanUploadAdditionalDocument &&
              (isAdmin || user.userRoles.includes('Insurance-IssuingAgent'))
            }
            ondocumentUploaded={() => {
              getPolicyDetailsLazy();
            }}
            policyEntityId={params.id}
          />
        </>
      );
    } else {
      delete newTabs.tabs[2];
    }

    return newTabs;
  };

  return loadingState ? (
    <Loader />
  ) : (
    <>
      <div>
        {policyDrawerOpen && (
          <PolicyDetailsDrawer
            open={policyDrawerOpen}
            onClose={() => setPolicyDrawerOpen(false)}
            onSuccess={() => {
              getPolicyDetailsLazy();
            }}
            policyDetailsInfo={
              policyInfo as unknown as IPolicyDetailsDrawerInfo
            }
            policyId={params.id}
          />
        )}

        {cancelDrawerOpen && (
          <CancelPolicyDrawer
            open={cancelDrawerOpen}
            onClose={() => setCancelDrawerOpen(false)}
            onSuccess={() => {
              getPolicyDetailsLazy();
            }}
            id={params.id}
          />
        )}
      </div>
      <TabsLayout
        name="policyPageDetails"
        layout={tabs()}
        theme={tenant.theme}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        cdnUrl={tenant.cdnUrl}
        userInfo={user['info']}
        tabPanelClassName={classes.tabPanelStyle}
        firstTabAsActiveTab={true}
      />
    </>
  );
};

export default PolicyDetailsPage;
