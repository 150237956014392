import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { IEnhancedCommonProps } from '../..';
import { Tooltip } from '@mui/material';
import { MAIN_ONE_THEME, contentFontFamilyBold } from '../../../constants';

interface ICustomCheckboxDropdownProps extends IEnhancedCommonProps {
  options: string[];
  selectedOptions: string[];
  value: string | JSX.Element;
  disabled: boolean;
  name: string;
  onClear?: () => void;
  onOptionClick?: (name: string, value: boolean) => void;
  classes?: { container?: string; button?: string; arrow?: string };
}

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'inline-block',
    height: '100%',
  },
  input: {
    backgroundColor: 'transparent',
    border: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    outline: 0,
    width: '100%',
    padding: '0',
    margin: '0 4px',
  },
  arrow: {
    color: MAIN_ONE_THEME.palette.primary1.main,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  inputValue: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  options: {
    border: '1px solid rgba(228, 228, 228, 0.79)',
    borderRadius: '5px',
    position: 'absolute',
    width: '95%',
    right: '2.5%',
    left: '2.5%',
    boxShadow: '0px 0px 6px #C7C7C7',
    backgroundColor: '#fff',
    zIndex: 10,
    boxSizing: 'border-box',
    padding: '5px',
    minWidth: '250px',
  },
  optionButton: {
    backgroundColor: '#fff',
    color: MAIN_ONE_THEME.palette.primary1.main,
    display: 'block',
    margin: '0 auto',
    border: 0,
    outline: 0,
    fontSize: '12px',
    padding: '7px',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: MAIN_ONE_THEME.palette.secondary5.main,
    },
  },
  checkboxContainer: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'start',
    position: 'relative',
    color: MAIN_ONE_THEME.palette.primary1.main,
    margin: '2px 0',
  },
  checkbox: {
    margin: '0 5px',
    position: 'relative',
    width: '15px',
    height: '15px',
    cursor: 'pointer',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    borderRadius: '3px',
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxShadow: `inset 0 0 2px ${MAIN_ONE_THEME.palette.secondary2.main}`,
    '&:hover': {
      backgroundColor: MAIN_ONE_THEME.palette.secondary2.main,
    },
    '&:focus': {
      outline: 0,
    },
  },
  checkboxChecked: {
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
  },
  checkboxCheckIcon: {
    height: '15px',
    width: '15px',
    color: '#fff',
    '&:focus': {
      outline: 0,
    },
  },
  checkboxTitle: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  clearSection: {
    width: '100%',
    margin: '0 auto',
    padding: '10px 0 0',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(0,0,0,.06)',
  },
  clearButton: {
    backgroundColor: 'transparent',
    color: MAIN_ONE_THEME.palette.primary1.main,
    display: 'flex',
    alignItems: 'center',
    border: 0,
    outline: 0,
    fontSize: '12px',
    padding: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  clearText: {
    fontSize: '12px',
    color: MAIN_ONE_THEME.palette.primary1.main,
    lineHeight: '14px',
    fontFamily: contentFontFamilyBold,
  },
  clearIcon: {
    color: MAIN_ONE_THEME.palette.primary1.main,
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '15px',
  },
}));

const CustomCheckboxDropdown: React.FC<ICustomCheckboxDropdownProps> = ({
  value,
  options,
  disabled,
  onOptionClick = () => undefined,
  onClear = () => undefined,
  classes = {},
  style,
  selectedOptions,
}) => {
  const { classes: dropdownClasses } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  let dropdownElement: HTMLDivElement;

  const handleClickOutside = (event: any) => {
    if (open && !dropdownElement.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div
      ref={(el: HTMLDivElement) => {
        dropdownElement = el;
      }}
      className={clsx(dropdownClasses.container, classes.container)}
      style={style}
    >
      <button
        type="button"
        className={clsx(dropdownClasses.input, classes.button)}
        onClick={() => !disabled && setOpen(!open)}
      >
        <span className={dropdownClasses.inputValue}>{value}</span>
        {options.length > 1 &&
          !disabled &&
          (open ? (
            <ExpandLessIcon
              className={clsx(dropdownClasses.arrow, classes.arrow)}
            />
          ) : (
            <ExpandMoreIcon
              className={clsx(dropdownClasses.arrow, classes.arrow)}
            />
          ))}
      </button>
      {open && options && options.length > 1 && (
        <div className={dropdownClasses.options}>
          {options.map((option) => {
            const selected = selectedOptions.includes(option);
            return (
              <div className={dropdownClasses.checkboxContainer} key={option}>
                <div
                  className={clsx(
                    dropdownClasses.checkbox,
                    selected ? dropdownClasses.checkboxChecked : undefined
                  )}
                  onClick={() => onOptionClick(option, !selected)}
                  onKeyDown={() => onOptionClick(option, !selected)}
                  tabIndex={0}
                  role="checkbox"
                  aria-checked={selected}
                >
                  {selected && (
                    <CheckIcon className={dropdownClasses.checkboxCheckIcon} />
                  )}
                </div>
                <Tooltip title={option}>
                  <span className={dropdownClasses.checkboxTitle}>
                    {option}
                  </span>
                </Tooltip>
              </div>
            );
          })}

          <div className={dropdownClasses.clearSection}>
            <button
              type="button"
              onClick={() => {
                onClear();
                setOpen(false);
              }}
              className={dropdownClasses.clearButton}
            >
              <CloseIcon className={dropdownClasses.clearIcon} />
              <span className={dropdownClasses.clearText}>{'Clear'}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomCheckboxDropdown;
