import React, { useMemo } from 'react';
import { IDynamicDiplayView } from '../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../components/widgets/dynamic-display/DynamicWidget';
import { capitalizeFirstLetter } from '../../../utils/formatting-utils';

interface IAdditionalInformationDetailsWidgetProps {
  data: Record<string, any>;
  actions?: JSX.Element;
}

const AdditionalInformationDetailsWidget: React.FC<
  IAdditionalInformationDetailsWidgetProps
> = ({ data, actions }) => {
  const view = useMemo(() => {
    const dynamicView: IDynamicDiplayView = {
      name: 'Additional Information',
      sections: [
        {
          name: 'additionalInformation',
          title: 'Additional Information',
          showActionButtons: true,
          properties: {
            gender: {
              multiline: false,
              value: capitalizeFirstLetter(data?.gender),
              name: 'gender',
              title: 'Gender',
            },
            maritalStatus: {
              multiline: false,
              value: capitalizeFirstLetter(data?.maritalStatus),
              name: 'maritalStatus',
              title: 'Marital Status',
            },
            fatherName: {
              multiline: false,
              value: data?.fatherName,
              name: 'fatherName',
              title: 'Father Name',
            },
            motherName: {
              multiline: false,
              value: data?.motherName,
              name: 'motherName',
              title: 'Mother Name',
            },
            countryOfBirth: {
              multiline: false,
              value: capitalizeFirstLetter(data?.countryOfBirth),
              name: 'countryOfBirth',
              title: 'Country of Birth',
            },
            cityOfBirth: {
              multiline: false,
              value: data?.cityOfBirth,
              name: 'cityOfBirth',
              title: 'City of Birth',
            },
            nationalityID: {
              multiline: false,
              value: data?.nationalityID,
              name: 'nationalityID',
              title: 'Nationality ID',
            },
            passportNumber: {
              multiline: false,
              value: data?.passportNumber,
              name: 'passportNumber',
              title: 'Passport Number',
            },
            nationalityTwo: {
              multiline: false,
              value: capitalizeFirstLetter(data?.nationalityTwo),
              name: 'nationalityTwo',
              title: 'Second Nationality',
            },
          },
        },
      ],
    };

    return dynamicView;
  }, [data]);

  return <DynamicWidget hasFourFields={true} view={view} actions={actions} />;
};

export default AdditionalInformationDetailsWidget;
