// TODO Destroy
import { Stepper } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { IActivityEntityBase } from '.';
import Loader from '../components/Loader';
import { contentFontFamilyRegular, MAIN_ONE_THEME } from '../constants';
import { useAppSelector } from '../redux/hooks';
import ActivityTemplate from './ActivityTemplate';

export interface ICustomActivitiesProps {
  title: string;
  onCloseAction?: () => void;
  items: IActivityEntityBase[];
  loader?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    paddingTop: '15px',
    width: '100%',
  },
  sectionHeader: {
    width: '50%',
    margin: `0 auto`,
    borderBottom: `2px solid ${MAIN_ONE_THEME.palette.primary2.main}`,
  },
  sectionTitle: {
    textAlign: 'center',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg1.fontSize}!important`,
    fontFamily: contentFontFamilyRegular,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary2.main,
    opacity: 1,
    margin: '0 0 15px',
    display: 'block',
  },
  stepper: {
    minWidth: 'fit-content',
    overflowY: 'auto',
    textAlign: 'left',
    width: '85%',
    margin: '10px 0 10px 0',
    padding: '24px',
  },
  empty: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-15% 0 0 -20%',
    color: MAIN_ONE_THEME.palette.secondary2.main,
  },
}));

const CustomActivities: React.FC<ICustomActivitiesProps> = ({
  title,
  onCloseAction,
  items,
  loader,
}) => {
  const { classes } = useStyles();

  const tenant = useAppSelector((state) => state.tenant);

  const renderActivities = () => {
    const output: JSX.Element[] = [];
    if (items.length > 0) {
      items.forEach((activity) => {
        output.push(
          <ActivityTemplate
            key={activity.id}
            activity={activity}
            tenant={tenant}
          />
        );
      });
    }
    return output;
  };

  const renderContent = () => {
    if (loader) {
      return (
        <span className={classes.empty}>
          <Loader />
        </span>
      );
    }
    if (items.length > 0) {
      return (
        <Stepper orientation="vertical" className={classes.stepper}>
          {renderActivities()}
        </Stepper>
      );
    }
    return <span className={classes.empty}>{'No Data'}</span>;
  };

  return (
    <section className={classes.container}>
      <div className={classes.sectionHeader}>
        <h1 className={classes.sectionTitle}>{title}</h1>
      </div>
      {renderContent()}
    </section>
  );
};

export default CustomActivities;
