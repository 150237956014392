import { isEmpty } from 'lodash';
import { quote } from '../../../../components/custom/QuotesCards';

export function graphqlEntityToQuoteDetails(
  data: any,
  currencySymbol: string
): quote {
  return {
    id: data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
      ?.QuoteID?.id,
    title:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.Name,
    status:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.Status,
    statusReason:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.StatusReason,
    createdOn:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.createdOn,
    modifiedOn:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.modifiedOn,
    hasCoverage: !isEmpty(
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.MotorDetailsID
    ),
    coverageAmount:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.MotorDetailsID?.views
        ?.Insurance_all?.properties?.SumInsured,
    coverageAmountCurrencySymbol: currencySymbol,
    premiumAmount:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.TotalAnnualPremium,
    premiumAmountCurrencySymbol: currencySymbol,
    towingServiceTitle:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties
        ?.ApplicationMotorPlanDetailsID?.views?.Insurance_all?.properties
        ?.TowingService?.Title,
    vignetteCode:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.views?.Insurance_all?.properties?.VignetteCode,
    vignette:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.views?.Insurance_all?.properties?.Vignette,
  };
}

export function getPlanCoversLov(data: any): Record<string, string> {
  const result: Record<string, string> = {};

  data.PlanConfigManagement.queries.getPlanSpecificMedicalCovers.forEach(
    (planCover: any) => {
      const id =
        planCover.planConfigManagement_PlanSpecificMedical_PolicyCoverID?.id;
      const policyCover =
        planCover.planConfigManagement_PlanSpecificMedical_PolicyCoverID.views
          .PlanConfigManagement_all.properties;
      result[id] = `${policyCover.ExternalCode} - ${policyCover.Name}`;
    }
  );

  return result;
}
