import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AccountCurrency from '../../shared/AccountCurrency';
import DifferenceSection from '../../shared/DifferenceSection';
import EnhancedTinyMCEEditor from '../../../../../../../components/enhanced-form/EnhancedTinyMCEEditor';
import SupportingDocuments from '../../shared/SupportingDocuments';
import RVPopupFormSection from './RVPopupFormSection';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import EnhancedButton from '../../../../../../../components/EnhancedButton';
import { cloneDeep } from 'lodash';
import { enumListAsRecordObject } from '../../../../../../../utils/graph-utils';
import { CurrencySymbolsAsRecordObject, mapToPopupListingData } from '../utils';
import {
  createRVPaymentMutation,
  getPaymentReceivablesMutation,
} from '../queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../../../components/ToastErrorMessage';
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../../../../../../constants';
import { formatDate } from '../../../../../../../utils/formatting-utils';
import { IListingData } from '../../../../../../../models/listing';
import { getUserToken } from '../../../../../../../utils/userUtils';
import { resolveGraphqlBaseUrl } from '../../../../../../../utils/tenant-utils';
import ToastSuccessMessage from '../../../../../../../components/ToastSuccessMessage';
import {
  IAddVoucherLOVs,
  IAddVoucherFormPopup,
  IVoucherPopupValues,
} from '../../shared/types';
import Loader from '../../../../../../../components/Loader';
import { modifyPaymentMutation } from '../../shared/queries';
import { mapToPaymentDetails } from '../../shared/utils';
import { isEmpty } from '../../../../../../../utils/validationUtils';
import { IPersistDocument } from '../../../../../../../forms/transaction-popup';
import { IAbstractRecord } from '../../../../../../../models';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles()(() => ({
  inputsRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 23.5%)`,
    gap: '2%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelSelector: {
    lineHeight: '21px !important',
    marginBottom: '12.54px !important',
    fontSize: '15px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  inputSelector: {
    height: '41px',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
    alignItems: 'center',
  },
  section: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    gridColumnGap: '21px',
  },
  uploadedFileContainer: {
    border: '1px solid #E8E8E8',
    padding: '8px 13px 8px 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    margin: '0 0 10px',
  },
  uploadedFileName: {
    color: '#231F20',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'SourceSansPro-Medium',
    margin: '0 auto 0 0',
  },
  uploadedFileClearIcon: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    padding: '0',
    margin: '0 0 0 20px',
  },
}));

const ReceiptVoucherForm: React.FC<IAddVoucherFormPopup> = ({
  onSuccess,
  onClose,
  isEdit = false,
  currentPaymentId,
  detailsLoading,
  data,
}) => {
  const businessPartnerId = useParams().id;
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [values, setValues] = useState<IVoucherPopupValues>({
    dateOfPayment: '',
    currency: '',
    paymentMethod: '',
    referenceNum: '',
    totalAmount: null,
    totalAmountUnAllocated: null,
    note: '',
    incomingDocuments: [],
    supportingDocuments: [],
    totalCredit: 0,
    totalDebit: 0,
    transactions: [],
    companyId: null,
    systemCurrencies: {
      primary: {},
      secondary: {},
    },
  });

  const [receiptVouchersTableData, setReceiptVouchersTableData] =
    useState<IListingData>({
      pagedItems: [],
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
    });

  const [errors, setErrors] = useState<Record<string, string>>({
    dateOfPayment: '',
    currency: '',
    paymentMethod: '',
    referenceNum: '',
    totalAmount: '',
    totalAmountUnAllocated: '',
    tableAmountReceived: '',
  });

  const [lovs, setLovs] = useState<IAddVoucherLOVs>({
    currenciesSymbols: {},
    paymentMethods: {},
    accounts: {},
  });

  const [getPaymentReceivables] = useMutation(getPaymentReceivablesMutation());

  const handleChange = (name: string, value: any) => {
    const newValues = cloneDeep(values);
    (newValues as any)[name] = value;

    setValues(newValues);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      if (isEdit && currentPaymentId) {
        formData.append(
          'operations',
          JSON.stringify({
            query: modifyPaymentMutation,
            variables: {
              currentPaymentID: currentPaymentId,
              note: values.note,
              PersistDocuments: values.incomingDocuments.map(
                (doc: IPersistDocument) => doc.id
              ),
              UploadedDocuments: Array.from(
                { length: values.supportingDocuments.length },
                () => null
              ).fill(null),
            },
          })
        );
      } else {
        formData.append(
          'operations',
          JSON.stringify({
            query: createRVPaymentMutation,
            variables: {
              paymentDate: formatDate(
                values.dateOfPayment,
                SEND_TO_BACKEND_DATE_FORMAT
              ),
              paymentCurrency: values.currency,
              paymentType: 'RECEIVABLE',
              paymentMethod: values.paymentMethod,
              referenceNumber: values.referenceNum,
              totalAmount: Number(values.totalAmount),
              totalAmountUnallocated: values.totalAmountUnAllocated,
              currentBusinessPartnerID: businessPartnerId,
              totalDebit: values.totalDebit,
              totalCredit: values.totalCredit,
              note: values.note,
              rvList: Object.keys(receiptVouchersTableData.pagedItems)
                .filter(
                  (key: string) =>
                    !isEmpty(receiptVouchersTableData.pagedItems[key].amount)
                )
                .map((key: string) => {
                  const item = receiptVouchersTableData.pagedItems[key];
                  return {
                    AmountDue: item.amountDue,
                    AmountOutstanding: item.amountOutstanding,
                    AmountOutstandingCurrency: item.amountOutstandingCurrency,
                    AmountReceived: Number(item.amount),
                    BillID: item.billId,
                    BillNumber: item.billNum,
                    DueDate: formatDate(
                      item.dueDate,
                      SEND_TO_BACKEND_DATE_FORMAT
                    ),
                    PolicyID: item.policyId,
                    PolicyNumber: item.policyNum,
                  };
                }),
              transactionList: values.transactions.map((item) => ({
                SelectedAccount: item.accountId,
                EnteredDescription: item.description,
                DebitOrCredit: item.debitOrCredit,
                AccountDebit: item.accountDebit,
                AccountCredit: item.accountCredit,
                SystemDebit: item.systemDebit,
                SystemCredit: item.systemCredit,
                SystemSecondaryDebit: item.systemSecondaryDebit,
                SystemSecondaryCredit: item.systemSecondaryCredit,
                TransactionCurrency:
                  item.transactionCurrency ||
                  values.systemCurrencies.primary.Code,
              })),
              UploadedDocuments: Array.from(
                { length: values.supportingDocuments.length },
                () => null
              ).fill(null),
            },
          })
        );
      }

      if (!isEmpty(values.supportingDocuments)) {
        const fileMap = values.supportingDocuments.reduce(
          (acc: Record<number, string[]>, file, index) => {
            acc[index] = [`variables.UploadedDocuments.${index}`];
            return acc;
          },
          {}
        );

        formData.append('map', JSON.stringify(fileMap));

        values.supportingDocuments.forEach((file, index) => {
          formData.append(index.toString(), file, file.name);
        });
      } else {
        formData.append('map', JSON.stringify({}));
      }

      const options = {
        method: 'POST',
        headers: {
          authorization: `Bearer ${getUserToken()}` || null,
          'GraphQL-preflight': '1',
        },
        body: formData,
      };

      const response = await fetch(
        `${resolveGraphqlBaseUrl()}/graphql`,
        options
      );
      const data = await response.json();

      if (!data.errors) {
        toast.success(
          <ToastSuccessMessage>
            {isEdit
              ? 'Payment successfully updated!'
              : 'Payment successfully created!'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          onSuccess();
          onClose();
        }, 500);
      } else {
        toast.error(
          <ToastErrorMessage>{data.errors[0].message}</ToastErrorMessage>
        );
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const initialize = async () => {
    try {
      setLoading(true);

      const res = data.values;

      if (!res) return;

      const currenciesSymbols = CurrencySymbolsAsRecordObject(
        res.Core.lookups.currencies
      );

      const paymentMethods = enumListAsRecordObject(
        res.Accounting_PaymentMethodList.enumValues
      );

      const currencies = res.Core.queries.getSystemCurrencies[0];

      let newValues = cloneDeep(values);

      if (isEdit && data.paymentDetails) {
        const paymentDetails = mapToPaymentDetails(data.paymentDetails);

        newValues = {
          ...newValues,
          ...paymentDetails,
        };

        setReceiptVouchersTableData({
          pagedItems: paymentDetails.payments,
          pageNumber: 0,
          pageSize: 10,
          totalCount: Object.keys(paymentDetails.payments).length,
        });
        setIsSubmitDisabled(false);
      } else if (!isEdit) {
        const res = await getPaymentReceivables({
          variables: {
            selectedCurrency: null,
            selectedPaymentDate: null,
            currentBusinessPartnerID: businessPartnerId,
          },
          errorPolicy: 'all',
        });

        if (res.errors && res.errors.length > 0) {
          if (res.errors[0]?.extensions?.code === 'CurrencyExchangeRateNull') {
            toast.error(
              <ToastErrorMessage>{res.errors[0].message}</ToastErrorMessage>
            );
            setReceiptVouchersTableData({
              pagedItems: [],
              pageNumber: 0,
              pageSize: 10,
              totalCount: 0,
            });
          }
          return;
        }

        const popupData = mapToPopupListingData(
          res.data.accounting.actions.getPaymentReceivables.PaymentBill,
          currenciesSymbols
        );
        setReceiptVouchersTableData({
          pagedItems: popupData,
          pageNumber: 0,
          pageSize: 10,
          totalCount: Object.keys(popupData).length,
        });
      }

      newValues.companyId =
        res.SalesforceManagement.entities.businessPartner.views.SalesforceManagement_all.properties.RelatedCompany.Id;

      newValues.systemCurrencies = {
        primary: currencies.core_SystemCurrency_BasicCurrency,
        secondary: currencies.core_SystemCurrency_SecondaryCurrency,
      };

      setValues(newValues);

      setLovs((prev) => ({
        ...prev,
        currenciesSymbols,
        paymentMethods,
      }));
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validateForm = () => {
    // Check if any of the fields are invalid
    // If so, return true to disable the submit button
    const invalid =
      values.totalAmountUnAllocated !== 0 ||
      Math.abs(Number(values.totalDebit) - Number(values.totalCredit)) !== 0 ||
      isSubmitDisabled;

    return invalid;
  };

  if (loading || (detailsLoading && isEdit)) {
    return <Loader />;
  }

  return (
    <>
      <RVPopupFormSection
        isEdit={isEdit}
        lovs={lovs}
        setLovs={setLovs}
        values={values}
        setValues={setValues}
        errors={errors}
        setErrors={setErrors}
        tableData={receiptVouchersTableData}
        setTableData={setReceiptVouchersTableData}
        setIsSubmitDisabled={setIsSubmitDisabled}
        businessPartnerId={businessPartnerId}
        classes={classes}
        disabled={isEdit}
      />
      <div>
        <AccountCurrency
          title={'Transactions'}
          lovs={lovs}
          values={values}
          setValues={setValues}
          systemCurrency={values.systemCurrencies.primary.Symbol}
          systemSecondaryCurrency={values.systemCurrencies.secondary.Symbol}
          disabled={isEdit}
        />
        <div className={clsx('container', classes.section)}>
          <div className="item">
            <SupportingDocuments
              values={values}
              setValues={setValues}
              currentPaymentId={currentPaymentId}
              classes={classes}
            />
          </div>
          <div className="item">
            <DifferenceSection
              credit={values.totalCredit}
              debit={values.totalDebit}
              systemCurrency={values.systemCurrencies.primary.Symbol}
            />
          </div>
        </div>
        <EnhancedTinyMCEEditor
          key="note"
          name="note"
          title="Note"
          placeholder="Enter a note..."
          value={values.note || ''}
          onChange={(name, value) => {
            handleChange(name, value);
          }}
          customStyles={{
            labelStyles: classes.labelSelector,
            inputStyles: classes.inputSelector,
          }}
          width="100%"
        />
        <div className={classes.buttonsContainer}>
          <EnhancedButton
            type="submit"
            onClick={handleSubmit}
            disabled={validateForm()}
            backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
            color="rgba(255, 255, 255, 1)"
            variant="outlined"
          >
            Submit
          </EnhancedButton>
        </div>
      </div>
    </>
  );
};

export default ReceiptVoucherForm;
