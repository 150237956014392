import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  planCurrency: '',
  ageFrom: '',
  ageTo: '',
  gender: '',
  travelDurationFrom: '',
  travelDurationTo: '',
  travelDestination: '',
  nbOfAdherent: '',
  premium: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  planCurrency: {
    name: 'planCurrency',
    title: 'Plan Currency',
    type: FormInputTypes.chips,
    placeholder: 'Plan Currency',
    value: !isEmpty(initialValues.planCurrency)
      ? initialValues.planCurrency
      : '',
    multiple: false,
    disabled: true,
    required: true,
  },
  ageFrom: {
    name: 'ageFrom',
    title: 'Age From',
    placeholder: 'Age From',
    type: FormInputTypes.number,
    value: initialValues.ageFrom,
    required: true,
    minNumber: 0,
    maxNumber: 50,
    customValidator: (values) => {
      const { ageFrom, ageTo } = values;
      const fromAge = Number(ageFrom);
      const toAge = Number(ageTo);
      if (fromAge != 0 && toAge != 0 && fromAge > toAge) {
        return 'Age From must be less than Age To';
      }
      return '';
    },
  },
  ageTo: {
    name: 'ageTo',
    title: 'Age To',
    placeholder: 'Age To',
    type: FormInputTypes.number,
    value: initialValues.ageTo,
    required: true,
    maxNumber: 50,
    customValidator: (values) => {
      const { ageFrom, ageTo } = values;
      const fromAge = Number(ageFrom);
      const toAge = Number(ageTo);
      if (fromAge != 0 && toAge != 0 && fromAge > toAge) {
        return 'Age To must be greater than Age From';
      }
      return '';
    },
  },
  gender: {
    name: 'gender',
    title: 'Gender',
    type: FormInputTypes.chips,
    placeholder: 'Gender',
    value: !isEmpty(initialValues.gender) ? initialValues.gender : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  travelDurationFrom: {
    name: 'travelDurationFrom',
    title: 'Travel Duration From (in days)',
    placeholder: 'Travel Duration From',
    type: FormInputTypes.number,
    value: initialValues.travelDurationFrom,
    required: true,
    minNumber: 0,
    customValidator: (values) => {
      const { travelDurationFrom, travelDurationTo } = values;
      const fromTravelDuration = Number(travelDurationFrom);
      const toTravelDuration = Number(travelDurationTo);
      if (
        fromTravelDuration != 0 &&
        toTravelDuration != 0 &&
        fromTravelDuration > toTravelDuration
      ) {
        return 'Travel Duration From must be less than Travel Duration To';
      }
      return '';
    },
  },
  travelDurationTo: {
    name: 'travelDurationTo',
    title: 'Travel Duration To (in days)',
    placeholder: 'Travel Duration To',
    type: FormInputTypes.number,
    value: initialValues.travelDurationTo,
    required: true,
    customValidator: (values) => {
      const { ageFrom, ageTo } = values;
      const fromAge = Number(ageFrom);
      const toAge = Number(ageTo);
      if (fromAge != 0 && toAge != 0 && fromAge > toAge) {
        return 'Travel Duration To must be greater than Travel Duration From';
      }
      return '';
    },
  },
  travelDestination: {
    name: 'travelDestination',
    title: 'Travel Destination',
    type: FormInputTypes.chips,
    placeholder: 'Travel Destination',
    value: !isEmpty(initialValues.travelDestination)
      ? initialValues.travelDestination
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  nbOfAdherent: {
    name: 'nbOfAdherent',
    title: 'Number Of Adherent',
    placeholder: 'Number Of Adherent',
    type: FormInputTypes.number,
    value: initialValues.nbOfAdherent,
    required: true,
    minNumber: 0,
  },
  premium: {
    name: 'premium',
    title: 'Premium',
    placeholder: 'Premium',
    type: FormInputTypes.formattedNumber,
    value: initialValues.premium,
    required: true,
    minValue: 0,
  },
};
