import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import NewChipsInput from '../../../components/enhanced-form/NewChipsInput';
import { IInsuredData, IProposalPageFormState } from './form';
import { validatePolicyInsuredField } from '../validation';
import ProposalPolicyPersonDrawer from '../../proposal-policy-person-drawer/ProposalPolicyPersonDrawer';
import PersonAddressDrawer from '../../person-address-drawer/PersonAddressDrawer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { removeObjectAtIndex } from '../../../utils/helper-utils';
import { useLazyQuery } from '@apollo/client';
import { fetchedPersonAddressList, fetchedPersons } from '../queries';
import { extractPersonsAddressesFixed, extractPersonsFixed } from '../utils';
import { useParams } from 'react-router-dom';
import _, { isArray, isEmpty } from 'lodash';
import { IProposalDetailsSummary } from './index2';
import { isGuid } from '../../../utils/validationUtils';
import EnhancedFormattedNumberInput from '../../../components/enhanced-form/EnhancedFormattedNumberInput';

interface IPolicyDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  maxNumber: number;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '33px 23px 23px',
    marginBottom: '14px',
    position: 'relative',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 49%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '8px',
    right: '0',
    margin: '0',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 !important',
  },
  label: {
    color: '#000000',
    margin: '9px 0px 0!important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelCurrency: {
    color: '#000000',
    margin: '0px 0 -2px 0 !important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const PolicyInsuredWidget: React.FC<IPolicyDetailsWidgetProps> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  maxNumber,
  lovs,
  data,
}) => {
  const { classes } = useStyles();
  const params = useParams<{ id: string }>();
  const entityId = params.id;
  const isInsuredFieldVisible = [
    '5',
    '9',
    '10',
    '13',
    '21',
    '35',
    '11',
    '15',
    '16',
    '25',
    '27',
    '29',
    '30',
    '31',
    '37',
    '38',
    '39',
    '42',
    '46',
  ].includes(data?.LineId?.ExternalCode);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [newPersonDrawerOpen, setNewPersonDrawerOpen] = useState<number>(-1);
  const [newAddressDrawerOpen, setNewAddressDrawerOpen] = useState<number>(-1);
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  const [lists, setLists] = useState<{
    id: Record<number, Record<string, string>>;
    insuredAddresses: Record<number, Record<string, string>>;
  }>({
    id: {},
    insuredAddresses: {},
  });

  const insuredValues = pageState.values.policyPersons.insured;
  const insuredErrors = pageState.errors.policyPersons.insured;
  const insuredTouched = pageState.touched.policyPersons.insured;

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons());
  const [getPersonAddressListLazy] = useLazyQuery(fetchedPersonAddressList());

  const handleOpenDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewPersonDrawerOpen(rowIndex);
  };

  const handleOpenAddressDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewAddressDrawerOpen(rowIndex);
  };

  const handleInsuredAddresses = async (personId: string) => {
    const result = await getPersonAddressListLazy({
      variables: { selectedPersonID: personId },
      errorPolicy: 'all',
    });
    return extractPersonsAddressesFixed(result.data);
  };

  const handleInsuredSearch = async (inputValue: string) => {
    if (inputValue?.length > 3) {
      const newData = await fetchedPersonsLazy({
        variables: {
          searchKeyword: inputValue,
          selectedBusinessPartners: [data?.PrimaryBPID?.Id],
        },
      });

      return extractPersonsFixed(newData.data);
    }

    return {} as Record<string, string>;
  };

  const initialize = () => {
    const newList: Record<number, Record<string, string>> = {};
    insuredValues.forEach((insured, index) => {
      if (!newList[index]) {
        newList[index] = {};
      }
      newList[index][insured.address] = insured.addressDetails;
    });
    setLists((prevLists) => ({
      ...prevLists,
      insuredAddresses: newList,
    }));
    setLoaded(true);
  };

  useEffect(() => {
    initialize();
  }, []);

  const onFieldUpdate = async (
    fieldName: string,
    value: any,
    rowIndex: number,
    touched = false,
    updatedList: any = lists
  ) => {
    const newPageState = _.cloneDeep(pageState);
    const newLists = { ...updatedList };

    if (fieldName === 'id') {
      const mainValue: string = Array.isArray(value) ? value[0] : value;

      if (isEmpty(mainValue) || isGuid(mainValue)) {
        newPageState.values.policyPersons.insured[rowIndex][fieldName] =
          mainValue;
        newPageState.errors.policyPersons.insured[rowIndex][fieldName] =
          validatePolicyInsuredField(fieldName, mainValue);
        if (touched) {
          newPageState.touched.policyPersons.insured[rowIndex][fieldName] =
            true;
        }

        if (isGuid(mainValue)) {
          const searchAddressResult = await handleInsuredAddresses(mainValue);

          const insuredAddresses = searchAddressResult;
          const extractedAddresses: any = {};
          if (insuredAddresses) {
            Object.keys(insuredAddresses).forEach((key) => {
              extractedAddresses[key] = insuredAddresses[key].address;
              if (insuredAddresses[key].isPrimary) {
                newPageState.values.policyPersons.insured[rowIndex].address =
                  key;
                newPageState.values.policyPersons.insured[
                  rowIndex
                ].addressDetails = insuredAddresses[key].address;
              }
            });
            newPageState.errors.policyPersons.insured[rowIndex].insuredAddress =
              '';
            newLists['insuredAddresses'][rowIndex] = extractedAddresses;
          }
        }
      }
    } else {
      (newPageState.values.policyPersons.insured[rowIndex] as any)[fieldName] =
        value;

      newPageState.errors.policyPersons.insured[rowIndex][fieldName] =
        validatePolicyInsuredField(fieldName, value);
      if (touched) {
        newPageState.touched.policyPersons.insured[rowIndex][fieldName] = true;
      }
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onPersonAddition = async (person: any, personFullName?: any) => {
    const rowIndex = newPersonDrawerOpen;

    const newPageState = _.cloneDeep(pageState);

    const newLists = _.cloneDeep(lists);

    newPageState.values.policyPersons.insured[rowIndex]['id'] = person;
    newPageState.errors.policyPersons.insured[rowIndex]['id'] = '';
    newPageState.touched.policyPersons.insured[rowIndex]['id'] = true;

    newLists.id[rowIndex] = {};
    newLists.id[rowIndex][person] = personFullName;

    const searchAddressResult = await handleInsuredAddresses(person);

    const insuredAddresses = searchAddressResult;
    const extractedAddresses: any = {};
    if (insuredAddresses) {
      Object.keys(insuredAddresses).forEach((key) => {
        extractedAddresses[key] = insuredAddresses[key].address;
        if (insuredAddresses[key].isPrimary) {
          newPageState.values.policyPersons.insured[rowIndex].address = key;
          newPageState.values.policyPersons.insured[rowIndex].addressDetails =
            insuredAddresses[key].address;
        }
      });
      newPageState.errors.policyPersons.insured[rowIndex].insuredAddress = '';
      newLists['insuredAddresses'][rowIndex] = extractedAddresses;
    }

    if (Object.keys(newLists['insuredAddresses'][rowIndex]).length === 1) {
      newPageState.values.policyPersons.insured[rowIndex]['address'] =
        Object.keys(newLists['insuredAddresses'][rowIndex])[0];
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onAddressAddition = async (address: any, addressFullName?: any) => {
    const rowIndex = newAddressDrawerOpen;

    const newPageState = _.cloneDeep(pageState);

    const newLists = _.cloneDeep(lists);

    newPageState.values.policyPersons.insured[rowIndex]['address'] = address;
    newPageState.errors.policyPersons.insured[rowIndex]['address'] = '';
    newPageState.touched.policyPersons.insured[rowIndex]['address'] = true;

    newLists.insuredAddresses[rowIndex] = {};
    newLists.insuredAddresses[rowIndex][address] = addressFullName;

    newPageState.values.policyPersons.insured[rowIndex].address = address;
    newPageState.values.policyPersons.insured[rowIndex].addressDetails =
      addressFullName;

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldSearch = async (
    fieldName: string,
    value: any,
    rowIndex: number,
    touched = false,
    updatedList: any = lists
  ) => {
    const newLists = { ...updatedList };

    if (fieldName === 'id') {
      const result: Record<
        string,
        Record<string, string>
      > = (await handleInsuredSearch(value)) as any;
      newLists['id'][rowIndex] = result;
    }

    setLists(newLists);
  };

  const getDefaultPersonListIfEmpty = (
    list: Record<string, string>,
    insured: IInsuredData
  ) => {
    if (Object.keys(list || {}).length > 0 || !insured.fullName) {
      return list;
    }

    const newList: Record<string, string> = {
      [insured.id]: insured.fullName,
    };

    return newList;
  };

  const renderInsuredRow = (row: IInsuredData) => {
    const rowIndex = insuredValues.indexOf(row);

    return (
      <div
        className={
          maxNumber === 1 && insuredValues.length === 1
            ? undefined
            : classes.container
        }
        key={rowIndex}
      >
        <div className={classes.clearBtn}>
          {!disabledForm && insuredValues.length > 1 && (
            <button
              className={classes.removeBtn}
              onClick={() => onInsuredRowDelete(rowIndex)}
            >
              <ClearIcon fontSize="small" />
            </button>
          )}
        </div>
        <div className={classes.fieldRow}>
          <NewChipsInput
            key="id"
            name="id"
            title="Insured"
            placeholder="Insured"
            value={insuredValues[rowIndex].id}
            error={
              insuredTouched[rowIndex].id ? insuredErrors[rowIndex].id : ''
            }
            onChange={(v) => {
              const value = isArray(v) ? v[0] : v;
              onFieldUpdate('id', value, rowIndex);
            }}
            onSearch={async (v) => {
              const value = isArray(v) ? v[0] : v;
              onFieldSearch('id', value, rowIndex);
            }}
            clearValueOnSearch={false}
            disabled={disabledForm}
            items={getDefaultPersonListIfEmpty(
              lists?.id[rowIndex],
              insuredValues[rowIndex]
            )}
            required={true}
            multiple={false}
            material={true}
            supportLink={disabledForm ? '' : 'Add Person'}
            onLinkClick={(e) => {
              e.preventDefault();
              handleOpenDrawer('insured', rowIndex);
            }}
            customStyles={{
              labelStyles: classes.label,
            }}
          />
          {!isInsuredFieldVisible && (
            <NewChipsInput
              key="address"
              name="address"
              title="Insured Address"
              placeholder="Insured Address"
              value={insuredValues[rowIndex].address}
              clearValueOnSearch={false}
              error={
                insuredTouched[rowIndex].address
                  ? insuredErrors[rowIndex].address
                  : ''
              }
              onChange={(v) => onFieldUpdate('address', v, rowIndex)}
              disabled={disabledForm}
              items={lists.insuredAddresses[rowIndex]}
              required={true}
              multiple={false}
              material={true}
              supportLink={
                disabledForm && !isGuid(insuredValues?.[rowIndex]?.id)
                  ? ''
                  : 'Add Address'
              }
              onLinkClick={(e) => {
                e.preventDefault();
                if (isGuid(insuredValues?.[rowIndex]?.id)) {
                  handleOpenAddressDrawer('insured', rowIndex);
                }
              }}
              customStyles={{
                labelStyles: classes.label,
              }}
            />
          )}
          <EnhancedFormattedNumberInput
            key="limitOfCover"
            name="limitOfCover"
            title="Limit of Cover"
            placeholder="Enter Limit of Cover"
            value={insuredValues[rowIndex].limitOfCover || ''}
            error={
              insuredTouched[rowIndex]?.limitOfCover
                ? insuredErrors[rowIndex]?.limitOfCover
                : ''
            }
            onChange={(v) =>
              onFieldUpdate('limitOfCover', v.target.value, rowIndex)
            }
            disabled={
              ['CLOSED_WON', 'CLOSED_LOST'].includes(data.Status) ||
              disabledForm
            }
            material={true}
            hidden={!isInsuredFieldVisible}
            customStyles={{
              labelStyles: disabledForm ? classes.label : classes.labelCurrency,
            }}
          />
        </div>
      </div>
    );
  };

  const onInsuredRowAdd = () => {
    if (!disabledForm && insuredValues.length < maxNumber) {
      const newPageState = _.cloneDeep(pageState);
      const newRowIndex = newPageState.values.policyPersons.insured.length;
      newPageState.values.policyPersons.insured.push({
        id: '',
        order: `${newRowIndex + 1}`,
        address: '',
      });

      newPageState.errors.policyPersons.insured.push({
        id: '',
        order: '',
        address: '',
      });

      newPageState.touched.policyPersons.insured.push({
        id: false,
        order: false,
        address: false,
      });

      newPageState.values.policyPersons.insured.forEach((insured, index) => {
        insured.order = `${index + 1}`;
      });

      onPageStateUpdate(newPageState);
    }
  };

  const onInsuredRowDelete = (rowIndex: number) => {
    if (!disabledForm) {
      const newPageState = _.cloneDeep(pageState);
      newPageState.values.policyPersons.insured = removeObjectAtIndex(
        newPageState.values.policyPersons.insured,
        rowIndex
      );
      newPageState.errors.policyPersons.insured = removeObjectAtIndex(
        newPageState.errors.policyPersons.insured,
        rowIndex
      );
      newPageState.touched.policyPersons.insured = removeObjectAtIndex(
        newPageState.touched.policyPersons.insured,
        rowIndex
      );

      newPageState.values.policyPersons.insured.forEach((insured, index) => {
        insured.order = `${index + 1}`;
      });

      setLists((prevLists) => {
        // Create new objects to store the updated values
        const newId: Record<number, Record<string, string>> = {};
        const newInsuredAddresses: Record<number, Record<string, string>> = {};

        // Iterate over the keys of the previous id object
        Object.keys(prevLists.id).forEach((key) => {
          const keyNum = Number(key);
          if (keyNum < rowIndex) {
            // Copy the data as-is if the index is before the one to delete
            newId[keyNum] = prevLists.id[keyNum];
            newInsuredAddresses[keyNum] = prevLists.insuredAddresses[keyNum];
          } else if (keyNum > rowIndex) {
            // Shift the indices down by 1 for the entries after the deleted index
            newId[keyNum - 1] = prevLists.id[keyNum];
            newInsuredAddresses[keyNum - 1] =
              prevLists.insuredAddresses[keyNum];
          }
        });

        return {
          id: newId,
          insuredAddresses: newInsuredAddresses,
        };
      });

      onPageStateUpdate(newPageState);
    }
  };

  if (!loaded) {
    return null;
  }

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Insured"
        hasTitleSpecificDesign={false}
        useSeparator={false}
      >
        {(insuredValues || []).map((v) => renderInsuredRow(v))}
        {newPersonDrawerOpen !== -1 && currentSection === 'insured' && (
          <ProposalPolicyPersonDrawer
            open={newPersonDrawerOpen !== -1}
            onClose={() => setNewPersonDrawerOpen(-1)}
            section={currentSection}
            planId={''}
            onSuccess={() => undefined}
            proposalId={entityId}
            lineExternalCode={data?.LineId?.ExternalCode}
            onSubmitSuccess={onPersonAddition}
          />
        )}
        {newAddressDrawerOpen !== -1 && currentSection === 'insured' && (
          <PersonAddressDrawer
            open={newAddressDrawerOpen !== -1}
            onClose={() => setNewAddressDrawerOpen(-1)}
            personId={insuredValues?.[newAddressDrawerOpen]?.id || ''}
            onSuccess={() => undefined}
            onSubmitSuccess={onAddressAddition}
          />
        )}
        {insuredValues.length < maxNumber && (
          <button className={classes.addBtn} onClick={() => onInsuredRowAdd()}>
            <AddCircleOutlineIcon color="error" fontSize="small" />
          </button>
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default PolicyInsuredWidget;
