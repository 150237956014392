import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { contentFontFamilyBold, MAIN_ONE_THEME } from '../../../constants';

export interface CollapsibleTableToolbarProps {
  children?: JSX.Element;
  title: string;
}

const useStyles = makeStyles<{
  expanded: boolean;
}>()((theme, { expanded }) => ({
  mainContainer: {
    backgroundColor: '#D9E0E5',
    padding: expanded ? '0' : '0',
    borderRadius: '0',
    margin: '0 !important',
  },
  filtersTitle: {
    fontFamily: contentFontFamilyBold,
    fontSize: '18px',
    lineHeight: '25px',
    color: MAIN_ONE_THEME.palette.primary1.main,
    margin: '0',
  },
  accordionSummary: {
    minHeight: 'unset!important',
    margin: '0',
    padding: '12px 17px 12px 36px',
    '& .MuiAccordionSummary-content': {
      padding: '0',
      margin: expanded ? '0 !important' : '0',
    },
  },
  accordionDetails: {
    padding: '0',
  },
  titleWrapper: {
    marginTop: '3px',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: '30%',
    display: 'flex',
    marginLeft: '5px',
  },
}));

const CollapsibleTableToolbar: React.FC<CollapsibleTableToolbarProps> = ({
  children,
  title,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { classes } = useStyles({ expanded });

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return children ? (
    <Accordion
      elevation={0}
      className={classes.mainContainer}
      onChange={() => handleChange()}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <div className={classes.titleWrapper}>
          <span className={classes.filtersTitle}>{title}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

export default CollapsibleTableToolbar;
