import React, { useEffect, useState } from 'react';
import { IListingData } from '../../../models/listing';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery } from './queries';
import { mapToListingData, toLookupsData } from './utils';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../../utils/filter-utils';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { IEnhancedMenuItem } from '../../../components';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../../constants';
import dayjs from 'dayjs';
import TransferDrawer from '../../../forms/transfers-drawer/TransferDrawer';
import Loader from '../../../components/Loader';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITransfersPage {}

const TransfersPage: React.FC<ITransfersPage> = () => {
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [transfersDrawerOpen, setTransfersDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');
  const actions: IEnhancedMenuItem[] = [
    {
      title: '+ New Transfer',
      onClick: () => {
        setChosenEditId(undefined);
        setTransfersDrawerOpen(true);
      },
      isSecondary: true,
      backgroundColor: '#000',
      color: '#fff',
    },
  ];

  const FILTER_SESSION_KEY = 'transfersFilter';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          createdOn: [],
          company: '41',
          Type: '',
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getTransfersLazy] = useLazyQuery(listQuery());

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoading(true);
    try {
      const result = await getTransfersLazy({
        variables: {
          SelectedCompany:
            filterV?.company && filterV?.company?.length > 0
              ? filterV?.company
              : '41',
          TransferFromDate: filterV?.createdOn?.[0]
            ? dayjs(filterV.createdOn[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          TransferToDate: filterV?.createdOn?.[1]
            ? dayjs(filterV.createdOn[1])
                .add(1, 'day')
                .format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          TransferType: filterV?.Type ? filterV?.Type : null,
          pageNumber: currentPage + 1,
          pageSize: pageSize,
        },
        fetchPolicy: 'no-cache',
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;

      // Update table data
      const newTableData = mapToListingData(data);
      setTableData({ ...newTableData, pageNumber: currentPage, pageSize });

      // Update filters
      const lovs = toLookupsData(data);

      const savedFilters = getFilter(FILTER_SESSION_KEY) || filterV;
      setFilterValues(savedFilters);

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterV?.company;
      }

      if (!Object.keys(lovs.Type).length) {
        lovs.Type = filterV?.Type;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);

      setFilter(savedFilters, FILTER_SESSION_KEY);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, tableData.pageSize, savedFilters);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = async (page: number) => {
    await loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = async (numberOfRecordsPerPage: number) => {
    await loadData(0, numberOfRecordsPerPage, filterValues);
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    if (_.isEqual(newFilters, filterValues)) {
      // Do nothing if filters are the same
      return;
    }
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    await loadData(0, tableData.pageSize, newFilters);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: '20px' }}>
      <EnhancedTable
        title="Transfers"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        hideToolbar
        currentPage={tableData.pageNumber}
        disableSelection
        usePagination={true}
        actions={actions}
        loader={loading}
        showCellFullData={true}
      />
      {transfersDrawerOpen && (
        <TransferDrawer
          open={transfersDrawerOpen}
          onClose={() => setTransfersDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
          }}
          transferId={chosenEditId}
          transferInfo={undefined}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={'Transfers'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default TransfersPage;
