import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  planCurrency: '',
  carAgeFrom: '',
  carAgeTo: '',
  carValueFrom: '',
  carValueTo: '',
  carCategory: '',
  minimumPremium: '',
  rate: '',
  agencyYear: '',
  noDepreciationYear: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  planCurrency: {
    name: 'planCurrency',
    title: 'Plan Currency',
    type: FormInputTypes.chips,
    placeholder: 'Plan Currency',
    value: !isEmpty(initialValues.planCurrency)
      ? initialValues.planCurrency
      : '',
    multiple: false,
    disabled: true,
    required: true,
  },
  carValueFrom: {
    name: 'carValueFrom',
    title: 'Car Value From',
    placeholder: 'Car Value From',
    type: FormInputTypes.formattedNumber,
    value: initialValues.carValueFrom,
    required: true,
    minValue: 0,
  },
  carValueTo: {
    name: 'carValueTo',
    title: 'Car Value To',
    placeholder: 'Car Value To',
    type: FormInputTypes.formattedNumber,
    value: initialValues.carValueTo,
    required: true,
  },
  carAgeFrom: {
    name: 'carAgeFrom',
    title: 'Car Age From',
    placeholder: 'Car Age From',
    type: FormInputTypes.number,
    value: initialValues.carAgeFrom,
    required: true,
    maxNumber: 50,
    maxDecimalPrecision: 0,
  },
  carAgeTo: {
    name: 'carAgeTo',
    title: 'Car Age To',
    placeholder: 'Car Age To',
    type: FormInputTypes.number,
    value: initialValues.carAgeTo,
    required: true,
    maxNumber: 50,
    maxDecimalPrecision: 0,
  },
  carCategory: {
    name: 'carCategory',
    title: 'Car Category',
    type: FormInputTypes.chips,
    placeholder: 'Car Category',
    value: !isEmpty(initialValues.carCategory) ? initialValues.carCategory : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  minimumPremium: {
    name: 'minimumPremium',
    title: 'Minimum Premium',
    placeholder: 'Minimum Premium',
    type: FormInputTypes.formattedNumber,
    value: initialValues.minimumPremium,
    required: true,
    minValue: 0,
  },
  rate: {
    name: 'rate',
    title: 'Rate',
    type: FormInputTypes.percentage,
    placeholder: 'rate',
    value: initialValues.rate,
    required: true,
    disabled: false,
  },
  agencyYear: {
    name: 'agencyYear',
    title: 'Agency Year',
    placeholder: 'Agency Year',
    type: FormInputTypes.number,
    value: initialValues.agencyYear,
    required: false,
    maxDecimalPrecision: 0,
  },
  noDepreciationYear: {
    name: 'noDepreciationYear',
    title: 'Depreciation Year',
    placeholder: 'Depreciation Year',
    type: FormInputTypes.number,
    value: initialValues.noDepreciationYear,
    required: false,
    maxDecimalPrecision: 0,
  },
};
