export interface ISalesforceBusinessUserDetails {
  name: string;
  businessPartnerName: string;
  lineName: string;
  planName: string;
}

export const initialValues: ISalesforceBusinessUserDetails = {
  name: '',
  businessPartnerName: '',
  lineName: '',
  planName: '',
};

export const initialBusinessUserValues = {};

export interface IGenericBusinessPartnersPlansTableData {
  salesforceManagement_AssignedBPPlan_Id: string;
  salesforceManagement_AssignedBPPlan_BusinessPartnerID: {
    id: string;
    views: {
      SalesforceManagement_all: {
        properties: {
          Code: string;
        };
      };
    };
  };
  businessPartner_PersonID: {
    id: string;
    views: {
      SalesforceManagement_all: {
        properties: {
          FullName: string;
        };
      };
    };
  };
  salesforceManagement_AssignedBPPlan_LineID: {
    id: string;
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
          Name: string;
        };
      };
    };
  };

  salesforceManagement_AssignedBPPlan_PlanID: {
    id: string;
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
          Name: string;
        };
      };
    };
  };

  salesforceManagement_AssignedBPPlan_BusinessPartnerLineID: {
    id: string;
    views: {
      SalesforceManagement_all: {
        properties: {
          Status: string;
        };
      };
    };
  };
  salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID: {
    id: string;
    views: {
      SalesforceManagement_all: {
        properties: {
          Status: string;
        };
      };
    };
  };

  salesforceManagement_AssignedBPPlan_Status: string;
}
