import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query CustomersList(
      $CreatedOnFromDate: Date
      $CreatedOnToDate: Date
      $DescendingField: Boolean
      $KeywordSearch: String
      $OrderByField: String
      $SelectedCompany: String!
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      Accounting {
        queries {
          CustomersList(
            CreatedOnFromDate: $CreatedOnFromDate
            CreatedOnToDate: $CreatedOnToDate
            DescendingField: $DescendingField
            KeywordSearch: $KeywordSearch
            OrderByField: $OrderByField
            SelectedCompany: $SelectedCompany
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            items {
              accounting_Customers_Id
              accounting_Customers_BalanceDue
              accounting_Customers_createdOn
              parentAccount_AccountName
              accounting_Customers_RelatedCompany {
                Id
                Title
                Code
              }
              accounting_Customers_CustomerName
              accounting_Customers_ReferenceNumber
              accounting_Customers_Website
              accounting_Customers_Notes
            }
            paging {
              pageSize
              pageNumber
              totalCount
            }
          }
        }
      }
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
    }
  `;
}
