import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery } from './queries';
import { mapToListingData, toLookups } from './utils';
import { filterSectionsContent, getHeaders } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { IEnhancedTableMenuItem } from '../../components/enhanced-table';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import dayjs from 'dayjs';
import { useAppSelector } from '../../redux/hooks';
import ProviderDrawer from '../../forms/providers-drawer/ProviderDrawer';
import Loader from '../../components/Loader';

const ProvidersPage: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [booted, setBooted] = useState<boolean>(false);
  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);

  const [providersDrawerOpen, setProvidersDrawerOpen] =
    useState<boolean>(false);

  const isAdmin = user.userRoles.includes('Insurance-Admin');
  const actions: IEnhancedTableMenuItem[] = [
    {
      title: '+ New',
      onClick: () => {
        setProvidersDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: '',
      hidden: !isAdmin,
    },
  ];

  const FILTER_SESSION_KEY = 'providersFilter';

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          isCompany: false,
          isLocal: false,
          selectedTypes: [],
          providerStatus: [],
          createdOn: [],
          effectiveDate: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const loadData = async (
    currentPage: number = tableData.pageNumber,
    currentPageSize: number = tableData.pageSize,
    filterV: Record<string, any> = filterValues
  ) => {
    setTableLoadingState(true);
    try {
      const result = await loadDataQuery({
        variables: {
          currentPage: currentPage + 1,
          currentPageSize: currentPageSize,
          selectedTypes:
            filterV?.type && filterV?.type?.length > 0 ? filterV?.type : [],
          isCompany:
            filterV?.isCompany && filterV?.isCompany?.length > 0
              ? filterV?.isCompany.map((bool: string) => {
                  return bool === 'true';
                })
              : [],
          isLocal:
            filterV?.isLocal && filterV?.isLocal?.length > 0
              ? filterV?.isLocal.map((bool: string) => {
                  return bool === 'true';
                })
              : [],
          providerStatus:
            filterV?.status && filterV?.status?.length > 0
              ? filterV?.status
              : [],
          createdDateFrom: filterV?.createdOn?.[0]
            ? dayjs(filterV.createdOn?.[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          createdDateTo: filterV?.createdOn?.[1]
            ? dayjs(filterV.createdOn?.[1]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          effectiveDateFrom: filterV?.effectiveDate?.[0]
            ? dayjs(filterV.effectiveDate?.[0]).format(
                SEND_TO_BACKEND_DATE_FORMAT
              )
            : null,
          effectiveDateTo: filterV?.effectiveDate?.[1]
            ? dayjs(filterV.effectiveDate?.[1]).format(
                SEND_TO_BACKEND_DATE_FORMAT
              )
            : null,
        },
      });

      const newTableData = mapToListingData(result.data);
      const lovs = toLookups(result.data);

      const newFilterSections = filterSectionsContent(lovs, filterV);
      newTableData.pageNumber = newTableData.pageNumber - 1;
      setTableData(newTableData);
      setFilter(filterV, FILTER_SESSION_KEY);
      setFilterValues(filterV);
      setFilterSections(newFilterSections);
    } catch (e) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setTableLoadingState(false);
    }
  };

  const [loadDataQuery] = useLazyQuery(listQuery());

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, 10, savedFilters);
    setBooted(true);
  };

  useEffect(() => {
    initialize();
  }, []);

  function handlePageChange(page: number) {
    loadData(page, tableData.pageSize, filterValues);
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    loadData(0, numberOfRecordsPerPage, filterValues);
  }

  const onFilterUpdate = async (v: Record<string, any>) => {
    if (_.isEqual(v, filterValues)) {
      return;
    }

    const newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    loadData(0, tableData.pageSize, newFilters);
  };

  const headers = getHeaders(navigate);

  const renderMainChildren = () => {
    return (
      <>
        {!booted ? (
          <div></div>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <EnhancedTable
              title="ProviderS"
              name="count"
              orderByAscendingByDefault
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              currentPage={tableData.pageNumber}
              hideToolbar
              usePagination
              disableSelection
              actions={actions}
              loader={tableLoadingState}
              showCellFullData={true}
            />
            {providersDrawerOpen && (
              <ProviderDrawer
                open={providersDrawerOpen}
                onClose={() => setProvidersDrawerOpen(false)}
                onSuccess={() => {
                  handlePageChange(0);
                }}
                providerDetailsInfo={undefined}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
          disabled={tableLoadingState}
        ></ListingFilterWidget>
      )
    );
  };

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={'Applications'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ProvidersPage;
