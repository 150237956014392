import { gql } from '@apollo/client';

export function getDefaultCommissionEnums() {
  return gql`
    query getDefaultCommissionEnums {
      Core_CommissionTypes: __type(name: "Core_CommissionTypes") {
        name
        enumValues {
          name
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
          commissionApplicableOnOptions {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getAssignedLineInfo() {
  return gql`
    query getAssignedLinesInfo($id: String!) {
      SalesforceManagement {
        entities {
          businessPartnerLine(id: $id) {
            id
            views {
              SalesforceManagement_all {
                properties {
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                        }
                      }
                    }
                  }
                  CreditLimit
                  CreditLimitCurrency {
                    Id
                    Title
                  }
                  GracePeriod
                  BusinessPartnerID {
                    id
                  }
                  Status
                  LineCommissionType
                  LineCommissionValue
                  ValueCurrency {
                    Id
                    Title
                  }
                  LineCommissionRate
                  ApplicableOn {
                    Id
                    Title
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function setBusinessPartnerLineCommission() {
  return gql`
    mutation setBusinessPartnerLineCommission(
      $entityId: String!
      $lineCommissionInputs: SalesforceManagement_LineCommissionInputs!
    ) {
      salesforceManagement {
        entities {
          businessPartnerLine {
            salesforceManagement {
              setBusinessPartnerLineCommission(
                entityId: $entityId
                lineCommissionInputs: $lineCommissionInputs
              ) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      LineCommissionType
                      LineCommissionValue
                      ValueCurrency {
                        Code
                        Title
                      }
                      LineCommissionRate
                      ApplicableOn {
                        Code
                        Title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
