import React from 'react';
import { EnhancedDisplayType } from '../../../components/enhanced-form/EnhancedDisplay';
import { IDynamicDiplayView } from '../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../components/widgets/dynamic-display/DynamicWidget';
import { IProposalDetailsSummary } from './index2';

interface IPolicyDetailsWidgetProps {
  data: IProposalDetailsSummary;
}

export const PolicyDetailsWidget: React.FC<IPolicyDetailsWidgetProps> = ({
  data,
}) => {
  const dynamicView: IDynamicDiplayView = {
    name: 'PolicyDetails',
    sections: [
      {
        name: 'PolicyDetails',
        title: 'Policy Details',
        properties: {
          Plan: {
            multiline: false,
            value: data.PlanID.Name,
            name: 'Plan',
            title: 'Plan',
            tooltip: false,
          },
          PolicyEffectiveDate: {
            multiline: false,
            value: data.PolicyEffectiveDate,
            name: 'PolicyEffectiveDate',
            title: 'Policy Effective Date',
            tooltip: false,
            type: EnhancedDisplayType.Date,
          },
          PolicyEndDate: {
            multiline: false,
            value: data.PolicyExpiryDate,
            name: 'PolicyEndDate',
            title: 'Policy End Date',
            tooltip: false,
            type: EnhancedDisplayType.Date,
          },
          PolicyCurrency: {
            multiline: false,
            value: data.PolicyCurrency.Title,
            name: 'PolicyCurrency',
            title: 'Policy Currency',
            tooltip: false,
          },
        },
      },
    ],
  };

  return <DynamicWidget hasFourFields={true} view={dynamicView} />;
};
