import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';

export const steps = ['Inactive', 'Active'];

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Person Details',
  iconUrl: '',
  sections: [
    {
      name: 'personDetailsSection1',
      title: '',
      properties: {
        status: {
          value: '',
          multiline: false,
          name: 'status',
          title: 'Invoice Status',
        },
        customer: {
          value: '',
          multiline: false,
          name: 'customer',
          title: 'Customer',
        },
        balance: {
          value: '',
          multiline: false,
          name: 'balance',
          title: 'Balance',
        },
        dueOn: {
          value: '',
          multiline: false,
          name: 'dueOn',
          title: 'Due On',
        },
      },
    },
    {
      name: 'policyCoverDetailsSection2',
      title: '',
      properties: {
        createdOn: {
          value: '',
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
        },
        createdBy: {
          value: '',
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        modifiedOn: {
          value: '',
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
        },
        modifiedBy: {
          value: '',
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

export const headers: Record<string, EnhancedTableHeader> = {
  invoiceRecord_Product: {
    name: 'invoiceRecord_Product',
    title: 'Product',
    type: EnhancedTableHeaderType.Text,
  },
  invoiceRecord_Description: {
    name: 'invoiceRecord_Description',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  invoiceRecord_Amount: {
    name: 'invoiceRecord_Amount',
    title: 'Amount',
    type: EnhancedTableHeaderType.Text,
  },
  invoiceRecord_SystemAmount: {
    name: 'invoiceRecord_SystemAmount',
    title: 'System Amount',
    type: EnhancedTableHeaderType.Text,
  },
};
