import React from 'react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import { IEnhancedFormInputBaseProps } from '.';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import TextAreaFormField from './TextAreaFormField';

export interface ITextInputFormFieldProps extends IEnhancedFormInputBaseProps {
  value: string;
  loader?: boolean;
  multiline?: boolean;
  hideError?: boolean;
  classes?: {
    labelWrapper?: string;
  };
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const useStyles = makeStyles()(() => ({
  input: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: '15px',
    height: 35,
    width: '100%',
    padding: '0 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.5)!important',
      opacity: `0.5 !important`,
      textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
    },
    '&::placeholder': {
      fontStyle: 'italic',
      fontFamily: contentFontFamilyRegular,
      color: '#33333333',
      opacity: 1,
      fontSize: '14px',
    },
    fontFamily: contentFontFamilyRegular,
    '&:focus': {
      outline: 'none',
    },
    '::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    right: '3%',
    opacity: 1,
    height: '25px',
    width: '25px',
  },
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
  },
  inputError: {
    borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
  },
  inputContainer: {
    position: 'relative',
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)!important',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)!important',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)!important',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)!important',
    },
  },
}));

const TextInputFormField: React.FC<ITextInputFormFieldProps> = ({
  error,
  name,
  title,
  value,
  placeholder,
  disabled,
  material,
  loader,
  inputOnly,
  hideError,
  multiline = false,
  classes,
  onChange,
  onFocus,
  onBlur,
}) => {
  const { classes: inputClasses } = useStyles();

  const renderInput = () =>
    multiline ? (
      <TextAreaFormField
        name={name}
        title={disabled && !isEmpty(value) ? value.toString() : title}
        value={value}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
        disabled={disabled || loader}
        placeholder={placeholder}
        error={error}
      />
    ) : (
      <div className={inputClasses.inputContainer}>
        <input
          className={clsx(inputClasses.input, {
            [inputClasses.materialInput]: material,
            [inputClasses.inputError]: error,
            [inputClasses.disabledInput]: disabled,
          })}
          title={disabled && !isEmpty(value) ? value.toString() : title}
          id={name}
          name={name}
          aria-invalid={!!error}
          aria-describedby={`errorMsg-${name}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type={'text'}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
    );

  return inputOnly || multiline ? (
    renderInput()
  ) : (
    <EnhancedInputsWrapper
      title={title}
      error={error}
      name={name}
      className={classes?.labelWrapper}
      hideError={hideError}
    >
      {renderInput()}
      {loader && (
        <CircularProgress
          className={inputClasses.loader}
          size={20}
          thickness={3}
        />
      )}
    </EnhancedInputsWrapper>
  );
};

export default TextInputFormField;
