import { gql } from '@apollo/client';

export function getEnums() {
  return gql`
    query GetInvoiceEnums {
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Code
            Title
          }
        }
        queries {
          getSystemCurrencies {
            core_SystemCurrency_Id
            core_SystemCurrency_BasicCurrency {
              Id
              Code
              Title
              Symbol
            }
          }
        }
      }
      Accounting {
        queries {
          GetSellAndBuySellProducts {
            accounting_Product_Id
            accounting_Product_ProductName
            accounting_Product_RelatedIncomeAccount {
              id
              views {
                Accounting_all {
                  properties {
                    AccountCurrency {
                      Id
                      Code
                      Title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getCustomersOfCompany() {
  return gql`
    query GetCustomersOfCompany($SelectedCompanyID: String) {
      Accounting {
        queries {
          GetCustomersOfCompany(SelectedCompanyID: $SelectedCompanyID) {
            accounting_Customers_Id
            accounting_Customers_CustomerName
          }
        }
      }
    }
  `;
}

export function convertAccountToSystemCurrency() {
  return gql`
    mutation convertAccountToSystemCurrencyInvoice(
      $transactionDate: Date!
      $accountDebitAmount: Decimal
      $accountCreditAmount: Decimal
      $accountCurrency: [String]
    ) {
      accounting {
        actions {
          convertAccountToSystemCurrency(
            TransactionDate: $transactionDate
            AccountDebitAmount: $accountDebitAmount
            AccountCreditAmount: $accountCreditAmount
            AccountCurrency: $accountCurrency
          ) {
            AccountToSystemRate
            SystemDebit
            SystemCredit
          }
        }
      }
    }
  `;
}

export function createInvoice() {
  return gql`
    mutation createInvoice(
      $EnteredReferenceNumber: String
      $SelectedCompany: String!
      $TotalAmount: Decimal!
      $SelectedCustomer: String!
      $SelectedCurrency: String!
      $InvoiceDate: Date!
      $PaymentDate: Date!
      $Note: String
      $InvoiceRecords: [Accounting_InvoiceRecordsList!]!
    ) {
      accounting {
        actions {
          createInvoice(
            SelectedCompany: $SelectedCompany
            EnteredReferenceNumber: $EnteredReferenceNumber
            TotalAmount: $TotalAmount
            SelectedCustomer: $SelectedCustomer
            SelectedCurrency: $SelectedCurrency
            InvoiceDate: $InvoiceDate
            PaymentDate: $PaymentDate
            InvoiceRecords: $InvoiceRecords
            Note: $Note
          ) {
            id
          }
        }
      }
    }
  `;
}

export function getTransactionInfo() {
  return gql`
    query getInvoiceListInfo($id: String!) {
      Accounting {
        entities {
          transactions(id: $id) {
            id
            views {
              Accounting_all {
                properties {
                  RelatedCompany {
                    Id
                    Code
                  }
                  TransactionNumber
                  ReferenceNumber
                  TransactionDate
                  Description
                  TransactionDocuments {
                    id
                    location
                    path
                    fileName
                    length
                    contentType
                  }
                  Note
                  TotalSystemDebit
                  TotalSystemCredit
                }
              }
            }
            queries {
              accounting {
                GetJournalTransactions {
                  accounting_Transactions_Id
                  journalTransactions_Id
                  journalTransactions_RelatedAccount {
                    id
                  }
                  journalTransactions_Description
                  journalTransactions_AccountDebit
                  journalTransactions_AccountCredit
                  journalTransactions_SystemDebit
                  journalTransactions_SystemCredit
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function modifyTransaction() {
  return gql`
    mutation modifyInvoice(
      $CurrentTransactionID: String!
      $ReferenceNumber: String
      $DeletedTransactionListID: [String]
      $PersistDocuments: [String]
      $TotalCredit: Decimal!
      $TotalDebit: Decimal!
      $TransactionDate: Date!
      $TransactionDescription: String
      $TransactionList: [Accounting_TransactionList!]!
      $TransactionNote: String
      $UploadedDocuments: [Upload]
    ) {
      accounting {
        actions {
          modifyTransaction(
            CurrentTransactionID: $CurrentTransactionID
            ReferenceNumber: $ReferenceNumber
            TotalCredit: $TotalCredit
            TotalDebit: $TotalDebit
            TransactionDate: $TransactionDate
            TransactionDescription: $TransactionDescription
            TransactionList: $TransactionList
            TransactionNote: $TransactionNote
            UploadedDocuments: $UploadedDocuments
            DeletedTransactionListID: $DeletedTransactionListID
            PersistDocuments: $PersistDocuments
          )
        }
      }
    }
  `;
}
