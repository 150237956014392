import React, { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Step, StepLabel, Stepper, Grid } from '@mui/material';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import EnhancedButton, { EnhancedButtonStatus } from '../EnhancedButton';

export interface IEnhancedStepperProps {
  activeStep: string;
  steps: string[];
  className?: string;
  style?: CSSProperties;
  showStepperButton?: boolean;
  buttonTitle?: string;
  buttonOnClick?: () => void;
  buttonColor?: string;
  buttonState?: EnhancedButtonStatus;
  reverseButton?: boolean;
}

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    padding: 0,
    width: '100%', // Ensuring the Stepper takes full width initially
  },
  step: {
    '& .MuiStepIcon-root': {
      height: 26,
      width: 26,
      color: MAIN_ONE_THEME.palette.secondary3.main,
    },
    '& .MuiStepIcon-active, .MuiStepIcon-completed': {
      color: MAIN_ONE_THEME.palette.primary2.main,
      '& .MuiStepIcon-text': {
        fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
        lineHeight: '12px',
        fill: 'white',
      },
    },
    '& .MuiStepIcon-text': {
      fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
      lineHeight: '12px',
      fill: 'white',
    },
    '& .MuiStepConnector-alternativeLabel': {
      left: 'calc(-50% + 35px)',
      right: 'calc(50% + 35px)',
    },
  },
  stepLabel: {
    '& .MuiStepLabel-label': {
      fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
      lineHeight: '13px',
      fontFamily: contentFontFamilyRegular,
      margin: '1.5em 0 0 0',
      color: MAIN_ONE_THEME.palette.secondary2.main,
    },
    '& .MuiStepLabel-active, .MuiStepLabel-completed': {
      color: MAIN_ONE_THEME.palette.primary3.main,
    },
  },
}));

const EnhancedStepper: React.FC<IEnhancedStepperProps> = ({
  steps,
  activeStep,
  className,
  style,
  showStepperButton = false,
  buttonTitle = 'Modify',
  buttonOnClick,
  buttonColor = MAIN_ONE_THEME.palette.primary1.main,
  buttonState = undefined,
  reverseButton = false,
}) => {
  const { classes } = useStyles();

  return (
    <Grid container alignItems="center" spacing={showStepperButton ? 2 : 2}>
      {showStepperButton && reverseButton && (
        <Grid item xs={2} justifyContent="center">
          <EnhancedButton
            type="button"
            backgroundColor={buttonColor}
            color="#FFF"
            onClick={buttonOnClick}
            style={{ marginTop: '-15px' }}
            state={buttonState}
          >
            {buttonTitle}
          </EnhancedButton>
        </Grid>
      )}
      <Grid item xs={showStepperButton ? 10 : 10}>
        <Stepper
          activeStep={steps.indexOf(activeStep)}
          alternativeLabel
          classes={{ root: classes.stepper }}
          className={className}
          style={style}
        >
          {steps.map((label) => (
            <Step key={label} classes={{ root: classes.step }}>
              <StepLabel classes={{ root: classes.stepLabel }}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      {showStepperButton && !reverseButton && (
        <Grid item xs={2} justifyContent="flex-end">
          <EnhancedButton
            type="button"
            backgroundColor={buttonColor}
            color="#FFF"
            onClick={buttonOnClick}
            style={{ marginTop: '-15px' }}
            state={buttonState}
          >
            {buttonTitle}
          </EnhancedButton>
        </Grid>
      )}
    </Grid>
  );
};

export default EnhancedStepper;
