import React from 'react';
import EnhancedModal from '../../../../../../../components/common/generic-modal/EnhancedModal';
import { IAddVoucherPopupShellProps } from '../../shared/types';
import PaymentVoucherPopup from './PaymentVoucherPopup';

const PaymentVoucherPopupShell: React.FC<IAddVoucherPopupShellProps> = ({
  open,
  onSuccess,
  onClose,
  isEdit = false,
  currentPaymentId,
}) => {
  return (
    <EnhancedModal
      open={open}
      blur={true}
      onClose={onClose}
      title={isEdit ? 'Modify Payment Vouchers' : 'Add Payment Vouchers'}
    >
      <PaymentVoucherPopup
        onSuccess={onSuccess}
        onClose={onClose}
        isEdit={isEdit}
        currentPaymentId={currentPaymentId}
      />
    </EnhancedModal>
  );
};

export default PaymentVoucherPopupShell;
