import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  insurance_Policy_FullName: {
    name: 'insurance_Policy_FullName',
    title: 'Customer',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  insurance_Policy_FullPolicyNumber: {
    name: 'insurance_Policy_FullPolicyNumber',
    title: 'Policy Number',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_Policy_LineOfBusiness: {
    name: 'insurance_Policy_LineOfBusiness',
    title: 'Line Of Business',
    type: EnhancedTableHeaderType.Text,
  },
  insurancePolicyMotorDetails_Product: {
    name: 'insurancePolicyMotorDetails_Product',
    title: 'Plan',
    type: EnhancedTableHeaderType.Text,
  },
  insuranceAgency_AgencyName: {
    name: 'insuranceAgency_AgencyName',
    title: 'Business Partner',
    type: EnhancedTableHeaderType.Text,
  },
  insuranceAgent_FirstName: {
    name: 'insuranceAgent_FirstName',
    title: 'Business User',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_Policy_TotalAnnualPremium: {
    name: 'insurance_Policy_TotalAnnualPremium',
    title: 'Premium',
    type: EnhancedTableHeaderType.Currency,
  },
  insurance_Policy_PolicyStatus: {
    name: 'insurance_Policy_PolicyStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  insurance_Policy_PolicyEffectiveDate: {
    name: 'insurance_Policy_PolicyEffectiveDate',
    title: 'Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  insurance_Policy_PolicyExpiryDate: {
    name: 'insurance_Policy_PolicyExpiryDate',
    title: 'Expiry Date',
    type: EnhancedTableHeaderType.Date,
  },
  insurance_Policy_PolicyIssueDate: {
    name: 'insurance_Policy_PolicyIssueDate',
    title: 'Issue Date',
    type: EnhancedTableHeaderType.Date,
  },
  insurance_Policy_createdOn: {
    name: 'insurance_Policy_createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  insurance_Policy_modifiedOn: {
    name: 'insurance_Policy_modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord,
  userRoles: string[]
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        ...((userRoles.includes('Insurance-Admin') ||
          userRoles.includes('Insurance-Underwriter')) && {
          agency: {
            name: 'agency',
            title: 'Business Partner',
            type: FormInputTypes.chips,
            value: filterValues?.agency || [],
            placeholder: 'Business Partner',
            triggerUpdate: true,
            selectOptions: { ...lovs.agency },
          },
        }),
        plan: {
          name: 'plan',
          title: 'Plan',
          type: FormInputTypes.chips,
          value: filterValues.plan || [],
          placeholder: 'Plan',
          triggerUpdate: true,
          selectOptions: { ...lovs.plan },
        },
        effectiveDate: {
          name: 'effectiveDate',
          title: 'Effective Date',
          type: FormInputTypes.daterange,
          value: filterValues.effectiveDate || [],
          placeholder: 'Effective Date',
          triggerUpdate: true,
        },
        issueDate: {
          name: 'issueDate',
          title: 'Issue Date',
          type: FormInputTypes.daterange,
          value: filterValues.issueDate || [],
          placeholder: 'issue Date',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
