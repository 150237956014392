import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query GET_AGENCIES(
      $currentPage: Int!
      $currentPageSize: Int!
      $agencyStatus: [Insurance_AgencyStatuses!]
      $agencyType: [String!]
    ) {
      Insurance {
        queries {
          listAgencies(
            AgencyStatus: $agencyStatus
            AgencyType: $agencyType
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              insurance_Agency_Id
              insurance_Agency_AgencyName
              insurance_Agency_AgencyStatus
              insurance_Agency_AgencyType {
                Title
              }
              insurance_Agency_createdOn
              insurance_Agency_modifiedOn
            }
          }
        }
        lookups {
          agencyTypes {
            Id
            Title
          }
        }
      }
      Insurance_AgentStatuses: __type(name: "Insurance_AgentStatuses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
