import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const defaultCommissionEnums: Record<string, Record<string, string>> = {};

  defaultCommissionEnums['commissionTypes'] = enumListAsRecordObject(
    data?.Core_CommissionTypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  defaultCommissionEnums['currencies'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  defaultCommissionEnums['commissionApplicableOnOptions'] =
    lookupListAsRecordObject(
      data?.Core?.lookups?.commissionApplicableOnOptions
    );

  return defaultCommissionEnums;
}

export function graphqlToDefaultCommissionInfo(
  data: any
): ISetDefaultCommissionInfo {
  const businessPartnerLine =
    data?.SalesforceManagement?.entities?.businessPartnerLine?.views
      ?.SalesforceManagement_all?.properties;
  return {
    assignedLineId: '',
    assignedLine:
      businessPartnerLine?.LineID?.views?.PlanConfigManagement_all?.properties
        ?.Name,
    commissionType: businessPartnerLine?.LineCommissionType,
    lineCommissionValue: businessPartnerLine?.LineCommissionValue,
    ValueCurrency: businessPartnerLine?.ValueCurrency?.Id,
    lineCommissionRate: businessPartnerLine?.LineCommissionRate,
    ApplicableOn: businessPartnerLine?.ApplicableOn?.Id,
  };
}
