import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query getCurrencyExchangeRateList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedCurrencies: [String]
      $selectedDate: Date
      $isSelectedDate: Boolean!
    ) {
      Core {
        queries {
          getCurrencyExchangeRateList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedCurrencies: $selectedCurrencies
            SelectedDate: $selectedDate
            isSelectedDate: $isSelectedDate
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              core_CurrencyExchangeRate_Id
              core_CurrencyExchangeRate_CurrencyID {
                Code
                Title
              }
              core_CurrencyExchangeRate_Rate
              core_CurrencyExchangeRate_EffectiveFrom
              core_CurrencyExchangeRate_EffectiveTo
              core_CurrencyExchangeRate_IsLatest
            }
          }
        }
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query CURRENCIES_LISTING_LOVS {
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
    }
  `;
}
