import React from 'react';
import { IProductionDetailsSummary } from '..';
import InformationBox from '../../../../../components/information-box/InformationBox';
import OfferCoverage from '../../../../../forms/proposal-details-page/custom-widgets/OfferCoverage';
import { isValidDate } from '../../../../../utils/date-utils';
import { formatDate } from '../../../../../utils/formatting-utils';
import { convertToTitleCase } from '../../../../../utils/helper-utils';

interface IPolicyProposalQuoteWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionPolicyQuoteWidget: React.FC<
  IPolicyProposalQuoteWidgetProps
> = ({ data }) => {
  const coversData: Record<string, any> = {};
  const coverHeaders: Record<string, any> = {};
  const currency = data.PricingOption.PricingOptionCurrencySymbol;

  data.Covers.forEach((cover, index) => {
    const coverName = cover.name || 'Cover' + index;
    coversData[coverName] = cover.coverPremiumValue;
    coverHeaders[coverName] = {
      name: coverName,
      title: coverName,
      type: 'Currency',
      urlTemplate: null,
      actionConfig: null,
      classificationPropertyName: null,
      iconUrl: null,
      openUrlInNewTab: false,
      tooltipSettings: null,
      textTrimLimit: null,
      currencySymbol: currency,
    };
  });

  const tabs = [
    {
      title: 'Offer Details',
      name: 'Details',
      data: {
        Details: {
          Plan: data.PlanID.Name,
          PolicyEffectiveDate: data.PolicyEffectiveDate,
          PolicyExpiryDate: data.PolicyExpiryDate,
        },
      },
      supportsGrouping: false,
      groupingValues: ['Details'],
      groupingTitle: null,
      headers: {
        Plan: {
          name: 'Plan',
          title: 'Plan',
          type: 'Text',
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
        },
        PolicyEffectiveDate: {
          name: 'PolicyEffectiveDate',
          title: 'Effective Date',
          type: 'Date',
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
        },
        PolicyExpiryDate: {
          name: 'PolicyExpiryDate',
          title: 'Expiry Date',
          type: 'Date',
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
        },
      },
      supportCycleDivision: false,
    },
    {
      title: 'Covers',
      name: 'Covers',
      data: {
        Covers: coversData,
      },
      supportsGrouping: false,
      groupingValues: ['Covers'],
      groupingTitle: null,
      headers: coverHeaders,
      supportCycleDivision: false,
    },
    {
      title: 'Breakdown',
      name: 'Breakdown',
      data: {
        Breakdown: {
          BasicPremium: data.PricingOption.BasicPremium,
          NetPremium: data.PricingOption.NetPremium,
          ChargesFees:
            data.PricingOption.PolicyCost +
            data.PricingOption.ChargesAmount +
            data.PricingOption.TPATotalFeesAmount,
          TotalPremium: data.PricingOption.TotalPremium,
          GrossPremium: data.PricingOption.GrossPremium,
          Taxes:
            data.PricingOption.FixedStampAmount +
            data.PricingOption.MunicipalityTaxAmount +
            data.PricingOption.ProportionalStampAmount,
        },
      },
      supportsGrouping: false,
      groupingValues: ['Breakdown'],
      groupingTitle: null,
      headers: {
        BasicPremium: {
          name: 'BasicPremium',
          title: 'Basic Premium',
          type: 'Currency',
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        NetPremium: {
          name: 'NetPremium',
          title: 'Net Premium',
          type: 'Currency',
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        ChargesFees: {
          name: 'ChargesFees',
          title: 'Charges & Fees',
          type: 'Currency',
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        GrossPremium: {
          name: 'GrossPremium',
          title: 'Gross Premium',
          type: 'Currency',
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        Taxes: {
          name: 'Taxes',
          title: 'Taxes',
          type: 'Currency',
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        TotalPremium: {
          name: 'TotalPremium',
          title: 'Total Premium',
          type: 'Currency',
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
      },
      supportCycleDivision: false,
    },
  ] as any[];

  return (
    <InformationBox
      visibleRows={0}
      title={'Offer Details'}
      status={convertToTitleCase(data.PricingOption.Status)}
      statusReason={convertToTitleCase(data.PricingOption.StatusReason || '')}
      statusIcon={''}
      statusColor={''}
      reviewedOn={''}
      subtitle={`Created On: ${isValidDate(data.PricingOption.CreatedOn) ? formatDate(data.PricingOption.CreatedOn) : '--'}`}
      header={
        <>
          <OfferCoverage
            disabled={false}
            premium={data.PricingOption.TotalPremium}
            currency={currency}
            premiumTitle={''}
            coverageTitle={'Coverage'}
            coverage={data.PricingOption.SumInsured}
            hidePremium={false}
          />
        </>
      }
      tabs={tabs}
      collapsible={false}
      initiallyCollapsed={false}
    />
  );
};

export default ProductionPolicyQuoteWidget;
