import { gql } from '@apollo/client';

export function updateEnvironmentSettings() {
  return gql`
    mutation updateEnvironmentSettings($issueDateReference: Date!) {
      insurance {
        actions {
          updateEnvironmentSettings(issueDateReference: $issueDateReference) {
            IssueDateReference
          }
        }
      }
    }
  `;
}
