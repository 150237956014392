import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  planConfigManagement_PolicyCover_Name: {
    name: 'planConfigManagement_PolicyCover_Name',
    title: 'Policy Cover Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  planConfigManagement_PolicyCover_LineID: {
    name: 'planConfigManagement_PolicyCover_LineID',
    title: 'Line',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_ExternalCode: {
    name: 'planConfigManagement_PolicyCover_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_NameArabic: {
    name: 'planConfigManagement_PolicyCover_NameArabic',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_PrintingOrder: {
    name: 'planConfigManagement_PolicyCover_PrintingOrder',
    title: 'Printing Order',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_PolicyCoverDescription: {
    name: 'planConfigManagement_PolicyCover_PolicyCoverDescription',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_createdOn: {
    name: 'planConfigManagement_PolicyCover_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_PolicyCover_modifiedOn: {
    name: 'planConfigManagement_PolicyCover_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        line: {
          name: 'line',
          title: 'Line',
          type: FormInputTypes.chips,
          value: filterValues?.line || [],
          placeholder: 'Line',
          triggerUpdate: true,
          selectOptions: { ...lovs.line },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues?.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
