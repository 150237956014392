import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAppVisibilityDetails, LoadAppVisibilitySuccessAction } from './types';

const initialState: IAppVisibilityDetails = {
  isPlanConfigurationEnabled: false,
  isSalesForceAppEnabled: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    LoadAppVisibilitySuccess(
      state,
      action: PayloadAction<LoadAppVisibilitySuccessAction>
    ) {
      state = {
        ...action.payload.app,
        isPlanConfigurationEnabled:
          action.payload.app.isPlanConfigurationEnabled,
        isSalesForceAppEnabled: action.payload.app.isSalesForceAppEnabled,
      };
      return state;
    },
  },
});

export const { LoadAppVisibilitySuccess } = appSlice.actions;

const appReducer = appSlice.reducer;
export default appReducer;
