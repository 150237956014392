import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { initialValues, inputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { ISalesForceBusinessPartnerDrawerProps } from '.';
import {
  getListForm,
  checkBusinessPartnerByPerson,
  createBusinessPartner,
  fetchedPersons,
  updateBusinessPartne,
} from './queries';
import { extractPersons, toLookups } from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

const useStyles = makeStyles()(() => ({
  isRegisteredStyle: {
    marginBottom: '17px',
  },
}));

const SalesForceBusinessPartnerDrawer: React.FC<
  ISalesForceBusinessPartnerDrawerProps
> = ({
  open,
  onSuccess,
  onClose,
  salesForceBusinessPartnerDetailsInfo,
  businessPartnerId,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [businessPartnersDetailsListResults] = useLazyQuery(getListForm());

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});
  const [values, setValues] = useState<Record<string, any>>();

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons());

  const [checkBusinessPartnerByPersonLazy] = useLazyQuery(
    checkBusinessPartnerByPerson(),
    {}
  );

  const [salesForceBusinessPartnerAction] = useMutation(
    businessPartnerId ? updateBusinessPartne() : createBusinessPartner()
  );

  const getLovs = async () => {
    const data = await businessPartnersDetailsListResults({});
    const newAssignPolicyCoverEnums = toLookups(data.data);
    const result: Record<string, Record<string, string>> = {
      SalesforceManagement_BusinessPartnerStatuses:
        newAssignPolicyCoverEnums[
          'SalesforceManagement_BusinessPartnerStatuses'
        ],
      relatedCompany: newAssignPolicyCoverEnums['relatedCompany'],
      type: newAssignPolicyCoverEnums['type'],
      category: newAssignPolicyCoverEnums['category'],
      maxCreditLimitAllowedCurrency:
        newAssignPolicyCoverEnums['maxCreditLimitAllowedCurrency'],
      person: {},
    };

    return result;
  };

  async function getPersonList(
    inputValue: any
  ): Promise<Record<string, string>> {
    try {
      if (inputValue !== null && inputValue.length >= 3) {
        const result = await fetchedPersonsLazy({
          variables: {
            searchKeyword: inputValue,
          },
        });
        if (result.data) {
          const extractedFetchedPersons = extractPersons(result.data);
          return extractedFetchedPersons;
        }
      }
    } catch (err) {
      //
    }

    return {};
  }

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const newLovs = await getLovs();

      setLovs(newLovs);

      setValues(salesForceBusinessPartnerDetailsInfo || initialValues);

      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const validateBusinessPartnerExistsOnPersonChange = async (
    selectedPersonID: string
  ) => {
    try {
      if (!isEmpty(selectedPersonID)) {
        const result = await checkBusinessPartnerByPersonLazy({
          variables: {
            selectedPersonID: selectedPersonID,
          },
        });

        if (
          result.data.SalesforceManagement?.queries
            ?.checkBusinessPartnerByPerson.length > 0
        ) {
          return `Business Partner already created for 
        ${result.data.SalesforceManagement?.queries?.checkBusinessPartnerByPerson[0]?.salesforceManagement_BusinessPartner_PersonID?.views?.SalesforceManagement_all?.properties?.PersonCode}
         - 
        ${result.data.SalesforceManagement?.queries?.checkBusinessPartnerByPerson[0]?.salesforceManagement_BusinessPartner_PersonID?.views?.SalesforceManagement_all?.properties?.FullName}`;
        }
      }
    } catch (err) {
      //
    }

    return '';
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      inputs(businessPartnerId, undefined, classes, lovs),
      values
    );

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      let variablesMutation = {};
      if (!businessPartnerId) {
        variablesMutation = {
          businessPartnerInputs: {
            personID: data.person,
            relatedCompany: data.relatedCompany,
            type: data.type,
            category: data.category || null,
            isCompany: data.isCompany ? data.isCompany : false,
            isLocal: data.isLocal ? data.isLocal : false,
            allowAccessToAllInsured: data.allowAccessToAllInsured || false,
            isRegistered: data.isRegistered ? data.isRegistered : false,
            registerNumber: data.registerNumber || null,
            taxAccount: data.taxAccount || null,
            account: data.account || null,
            maxCreditLimitAllowedCurrency:
              data.maxCreditLimitAllowedCurrency || null,
            maxCreditLimitAllowed: parseInt(
              data.maxCreditLimitAllowed as unknown as string
            ),
            status: data.SalesforceManagement_BusinessPartnerStatuses,
          },
        };
      } else {
        variablesMutation = {
          businessPartnerUpdateInputs: {
            relatedCompany: data.relatedCompany,
            type: data.type,
            category: data.category || null,
            isCompany: data.isCompany ? data.isCompany : false,
            isLocal: data.isLocal ? data.isLocal : false,
            allowAccessToAllInsured: data.allowAccessToAllInsured || false,
            isRegistered: data.isRegistered ? data.isRegistered : false,
            registerNumber: data.registerNumber || null,
            taxAccount: data.taxAccount || null,
            account: data.account || null,
          },
        };
      }

      const result = await salesForceBusinessPartnerAction({
        variables: businessPartnerId
          ? {
              ...variablesMutation,
              entityId: businessPartnerId,
            }
          : variablesMutation,
        errorPolicy: 'all',
      });
      if (isEmpty(result.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {businessPartnerId
              ? 'Business Partner successfully updated'
              : 'Business Partner successfully created'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          onSuccess();
          onClose();
          if (!businessPartnerId) {
            const newBusinessPartnerId =
              result.data.salesforceManagement.actions.createBusinessPartner.id;
            navigate(`/salesforce/business-partners/` + newBusinessPartnerId);
          }
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const onSearch = async (fieldName: string, value: string) => {
    const newLovs = _.cloneDeep(lovs);
    if (fieldName === 'person') {
      newLovs.person = await getPersonList(value);
    }
    setLovs(newLovs);
  };

  const onChange = async (
    fieldName: string,
    value: any,
    values: Record<string, any>,
    errors: Record<string, string>,
    touched: Record<string, boolean>
  ) => {
    values[fieldName] = value;

    switch (fieldName) {
      case 'person':
        errors.person =
          (await validateBusinessPartnerExistsOnPersonChange(value)) || '';
        touched.person = true;
        break;
      case 'type':
        if (value?.toLowerCase() === 'reinsurer') {
          values.isCompany = true;
          values.isRegistered = true;
          values.allowAccessToAllInsured = false;
          values.maxCreditLimitAllowedCurrency = null;
          values.maxCreditLimitAllowed = null;
        } else {
          values.isCompany = false;
          values.isRegistered = false;
          values.maxCreditLimitAllowedCurrency = '39735';
          values.maxCreditLimitAllowed = 0;
        }
        break;
      default:
        break;
    }
    setValues(values);
  };

  const inputForm = useMemo(() => {
    const result = inputs(businessPartnerId, values, classes, lovs);

    return result;
  }, [
    businessPartnerId,
    salesForceBusinessPartnerDetailsInfo,
    classes,
    lovs,
    values?.type,
  ]);

  return (
    <GenericDrawer
      title={
        businessPartnerId ? 'Modify Business Partner' : 'New Business Partner'
      }
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onSearch={onSearch}
            onChange={onChange}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesForceBusinessPartnerDrawer;
