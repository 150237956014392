import { IPaymentTermGenericTableData } from '.';
import { IAbstractRecord } from '../../../../../models';
import { IListingData } from '../../../../../models/listing';
import { formattedCurrency } from '../../../../../utils/helper-utils';
import { isEmpty } from '../../../../../utils/validationUtils';

export function mapToListingData(data: IAbstractRecord): IListingData {
  const bills = data.Accounting.queries.GetPolicyBills?.items;
  const list: Record<string, IPaymentTermGenericTableData> = {};

  const outstandingReceivable = bills
    .filter(
      (bill: IAbstractRecord) => bill.accounting_Bills_BillType === 'RECEIVABLE'
    )
    .reduce((total: number, bill: IAbstractRecord) => {
      return total + bill.accounting_Bills_OutstandingBalance;
    }, 0);
  const outstandingPayable = bills.reduce(
    (total: number, bill: IAbstractRecord) => {
      return total + bill.payableBills_OutstandingBalance;
    },
    0
  );
  const payableAmountReceived = bills.reduce(
    (total: number, bill: IAbstractRecord) => {
      return total + bill.payablePayment_AmountReceived;
    },
    0
  );
  const taxOnCommission = bills.reduce(
    (total: number, bill: IAbstractRecord) => {
      return total + bill.payableBills_TaxOnCommission;
    },
    0
  );

  bills?.forEach((obj: IPaymentTermGenericTableData) => {
    list[obj.accounting_Bills_Id] = {
      id: obj.accounting_Bills_Id,
      accounting_Bills_Id: obj.accounting_Bills_Id,
      accounting_Bills_BillNumber: obj.accounting_Bills_BillNumber,
      accounting_Bills_DueDate: obj.accounting_Bills_DueDate,
      accounting_Bills_TotalPremium: obj.accounting_Bills_TotalPremium,
      receivablePayment_AmountReceived: formattedCurrency(
        obj.receivablePayment_AmountReceived,
        2,
        true
      ),
      accounting_Bills_OutstandingBalance: formattedCurrency(
        outstandingReceivable,
        2,
        true
      ),
      accounting_Bills_TotalCommission: formattedCurrency(
        obj.accounting_Bills_TotalCommission,
        2,
        true
      ),
      payableBills_TaxOnCommission: formattedCurrency(
        isEmpty(obj.payableBills_TaxOnCommission) ? 0 : taxOnCommission,
        2,
        true
      ),
      payablePayment_AmountReceived: formattedCurrency(
        isEmpty(obj.payablePayment_AmountReceived) ? 0 : payableAmountReceived
      ),
      payableBills_OutstandingBalance: formattedCurrency(
        isEmpty(obj.payableBills_OutstandingBalance) ? 0 : outstandingPayable
      ),
      totalBalance:
        Number(obj.accounting_Bills_OutstandingBalance) - outstandingPayable,
      Currency: obj.accounting_Bills_Currency?.Symbol,
    };
  });

  const paging = data.Accounting.queries.GetPolicyBills?.paging;
  return {
    pagedItems: list,
    pageSize: paging.pageSize,
    pageNumber: paging.pageNumber,
    totalCount: paging.totalCount,
  };
}
