import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';

import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import {
  QueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  agencyAgents,
  allAgencies,
  createActionAsUnderwriter,
} from './queries';
import Loader from '../../components/Loader';
import { extractAgents, extractApplicationDrawerData } from './utils';
import { cloneDeep } from 'lodash';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { useNavigate } from 'react-router-dom';

const ApplicationDrawer: React.FC<IApplicationDrawer> = ({
  open,
  lineOfBusiness = 'Motor',
  onSuccess,
  onClose,
}) => {
  const navigate = useNavigate();

  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  inputs['lineOfBusiness'].value = lineOfBusiness;
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const [selectedAgentId, setSelectedAgentId] = useState<string>();

  const [loadingFields, setLoadingFields] = useState<Record<string, boolean>>({
    agent: false,
  });

  const [formDisabled, setFormDisabled] = useState(false);

  const agenciesQueryRes: QueryResult = useQuery(allAgencies());

  const [agencyAgentsLazy, agentQueryRes] = useLazyQuery(agencyAgents(), {
    variables: { agentId: selectedAgentId },
  });

  const [mutateFunction] = useMutation(createActionAsUnderwriter());

  useEffect(() => {
    if (agenciesQueryRes.loading) setLoadingState(true);
    if (agenciesQueryRes.error) {
      toast.error(
        <ToastErrorMessage>Something wrong happened</ToastErrorMessage>
      );
    }
    if (agenciesQueryRes.data) {
      const updatedInputs = cloneDeep(inputsForm);

      const extractedData = extractApplicationDrawerData(agenciesQueryRes.data);

      (updatedInputs.agency as IFormSelectDynamicProps).selectOptions =
        extractedData['agencies'];

      (updatedInputs.agency as IFormSelectDynamicProps).onSelect = (option) =>
        handleAgencySelected(option);

      setInputsForm(updatedInputs);
      setLoadingState(false);
    }
  }, [agenciesQueryRes]);

  useEffect(() => {
    if (agentQueryRes.loading)
      setLoadingFields({ ...loadingFields, agent: true });
    if (agentQueryRes.error) {
      toast.error(
        <ToastErrorMessage>Something wrong happened</ToastErrorMessage>
      );
    }
    if (agentQueryRes.data) {
      const updatedInputs = cloneDeep(inputsForm);

      const agents = extractAgents(agentQueryRes.data);

      (updatedInputs.agent as IFormSelectDynamicProps).selectOptions = agents;
      //TODO: remove the below line
      (updatedInputs.agency as IFormSelectDynamicProps).value = selectedAgentId;

      setInputsForm({ ...updatedInputs });
      setLoadingFields({ ...loadingFields, agent: false });
    }
  }, [agentQueryRes]);

  function handleAgencySelected(selectedOption: any) {
    setSelectedAgentId(selectedOption);
    agencyAgentsLazy({ variables: { agentId: selectedOption } });
  }

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState('loading');
    try {
      mutateFunction({
        variables: {
          agencyId: data.agency,
          agentId: data.agent,
          lineOfBusiness: lineOfBusiness,
        },
      })
        .then((data) => {
          toast.success(
            <ToastSuccessMessage>
              {'Application created successfully'}
            </ToastSuccessMessage>
          );
          const newApplicationId =
            data.data.insurance.actions.createApplicationAsUnderwriter.id;
          setTimeout(() => {
            setSubmitButtonState('success');
            // onSuccess();
            setFormDisabled(false);
            navigate(
              `/sales/applications/${lineOfBusiness.toLowerCase()}/` +
                newApplicationId
            );
            onClose();
          }, 500);
        })
        .catch((error) => {
          setSubmitButtonState(undefined);
          setFormDisabled(false);
          toast.error(
            <ToastErrorMessage>Something wrong happened.</ToastErrorMessage>
          );
        });
    } catch {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(
        <ToastErrorMessage>Something wrong happened.</ToastErrorMessage>
      );
    } finally {
      //
    }
  };

  return (
    <GenericDrawer
      title={'New Application'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {loadingState && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            loadingFields={loadingFields}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ApplicationDrawer;
