import React from 'react';
import EnhancedUploader from '../../../../../../components/enhanced-form/EnhancedUploader';
import { useAppSelector } from '../../../../../../redux/hooks';
import { Download } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import {
  downloadFileFromUrl,
  generateDownloadLink,
} from '../../../../../../utils/file-utils';
import { cloneDeep } from 'lodash';
import { IPersistDocument } from '../../../../../../forms/transaction-popup';
import { IVoucherPopupValues } from './types';

interface ISupportingDocuments {
  values: IVoucherPopupValues;
  setValues: (state: IVoucherPopupValues) => void;
  currentPaymentId: string;
  classes: Record<string, string>;
}

const SupportingDocuments: React.FC<ISupportingDocuments> = ({
  values,
  setValues,
  currentPaymentId,
  classes,
}) => {
  const tenant = useAppSelector((state) => state.tenant);

  const handleUploadFile = (file: File) => {
    const sizeInMB = file.size / (1024 * 1024);
    if (sizeInMB > 10) {
      toast.error(
        <ToastErrorMessage>Max file size should be 10 MBs</ToastErrorMessage>
      );
    } else {
      const newValues = cloneDeep(values);
      newValues.supportingDocuments.push(file);
      setValues(newValues);
    }
  };

  const handleDeleteFile = (index: number) => {
    const newValues = cloneDeep(values);
    newValues.supportingDocuments.splice(index, 1);
    setValues(newValues);
  };

  const handleDeleteSupportingfile = (index: number) => {
    const newValues = cloneDeep(values);
    newValues.incomingDocuments.splice(index, 1);
    setValues(newValues);
  };

  const downloadDocument = (fileId: string, fileName: string) => {
    const url = generateDownloadLink(
      fileId,
      'Accounting-Payments',
      currentPaymentId,
      'Accounting-all',
      'PaymentDocuments'
    );

    downloadFileFromUrl(url, fileName);
  };

  return (
    <>
      <EnhancedUploader
        type={''}
        value={''}
        onUpload={(file) => {
          handleUploadFile(file);
        }}
        name={'supportingDocuments'}
        title={'Upload Supporting Documents'}
        customStyles={{
          labelStyles: classes.labelSelector,
          inputStyles: classes.inputSelector,
        }}
        // error={touched.supportingDocuments ? errors.supportingDocuments : ""}
        // disabled={formDisabled || submitButtonState === "loading"}
        placeholder="Upload a document"
        iconUrl={`${tenant.cdnUrl}/icons/upload-field-primary.svg`}
        allowedFileTypes={[]}
      />
      {values.incomingDocuments.map((file: IPersistDocument, index: number) => (
        <div className={classes.uploadedFileContainer} key={index}>
          <span className={classes.uploadedFileName}>{file.fileName}</span>

          <button
            className={classes.uploadedFileClearIcon}
            onClick={() => {
              downloadDocument(file.id, file.fileName);
            }}
          >
            <Download htmlColor="#727272" fontSize="small" />
          </button>

          <button
            className={classes.uploadedFileClearIcon}
            onClick={() => {
              handleDeleteSupportingfile(index);
            }}
          >
            <ClearIcon htmlColor="#727272" fontSize="small" />
          </button>
        </div>
      ))}
      {values.supportingDocuments.map((file: File, index: number) => (
        <div className={classes.uploadedFileContainer} key={index}>
          <span className={classes.uploadedFileName}>{file.name}</span>
          <button
            className={classes.uploadedFileClearIcon}
            onClick={() => {
              handleDeleteFile(index);
            }}
          >
            <ClearIcon htmlColor="#727272" fontSize="small" />
          </button>
        </div>
      ))}
    </>
  );
};

export default SupportingDocuments;
