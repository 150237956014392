import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import * as React from 'react';
import { contentFontFamilyRegular, MAIN_ONE_THEME } from '../../constants';

export interface IEnhancedMenuItem {
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  onClick: () => void;
}

export interface IEnhancedMenuProps {
  title: string;
  items: IEnhancedMenuItem[];
  specificDesign?: boolean;
}

export const EnhancedMenu: React.FC<IEnhancedMenuProps> = ({
  title,
  items,
  specificDesign = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // Close the Popper when scrolling
  React.useEffect(() => {
    const handleScroll = () => {
      setOpen(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {title}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 100 }}
        placement={'bottom-end'}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {items &&
                    items.length > 0 &&
                    items.map(
                      (item) =>
                        !item.hidden && (
                          <MenuItem
                            disabled={item.disabled}
                            key={item.title}
                            onClick={(e) => {
                              item.onClick();
                              handleClose(e);
                            }}
                            disableRipple
                            sx={{
                              fontFamily: contentFontFamilyRegular,
                              fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        )
                    )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default EnhancedMenu;
