import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import _ from 'lodash';
import UserDrawer from '../../forms/user-drawer/UserDrawer';
import { listQuery } from './queries';
import { useLazyQuery } from '@apollo/client';
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { mapToListingData } from './utils';
import Loader from '../../components/Loader';

const FILTER_SESSION_KEY = 'usersFilter';

const UsersPage: React.FC = () => {
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userDrawerOpen, setUserDrawerOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const [tableData, setTableData] = useState<IListingData<IUsersTableData>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          role: ['ADMIN'],
          status: ['ACTIVE'],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [actions, setActions] = useState<IEnhancedTableMenuItem[]>([]);

  const [listQueryLazy] = useLazyQuery(listQuery());

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoading(true);
    try {
      const result = await listQueryLazy({
        variables: {
          currentPage: currentPage + 1,
          currentPageSize: pageSize,
          userRole:
            filterV?.role && filterV?.role?.length > 0 ? filterV.role : null,
          userStatus:
            filterV?.status && filterV?.status?.length > 0
              ? filterV.status
              : null,
        },
        fetchPolicy: 'no-cache',
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;

      if (data) {
        const mappedData = mapToListingData(data);
        setTableData({ ...mappedData, pageNumber: currentPage, pageSize });

        const savedFilters = getFilter(FILTER_SESSION_KEY) || filterV;

        setFilterValues(savedFilters);
        setFilter(savedFilters, FILTER_SESSION_KEY);

        const newFilterSections = filterSectionsContent(data, savedFilters);
        setFilterSections(newFilterSections);

        let newActions: IEnhancedTableMenuItem[] = [];

        if (data?.Insurance?.actions?.able_To_CreateUser) {
          newActions = [
            {
              title: '+ New',
              onClick: () => {
                setUserDrawerOpen(true);
              },
              isEntity: false,
              isBulk: false,
              iconUrl: '',
            },
          ];
        }

        setActions(newActions);

        if (data?.Insurance?.actions?.able_To_UpdateUser) {
          (
            headers.system_User_firstName as IEnhanceTableHeaderClickable
          ).callback = (payload: any) => {
            setSelectedUserId(payload.columns.system_User_Id);
            setUserDrawerOpen(true);
          };
        }
      }
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      const savedFilters = getFilter(FILTER_SESSION_KEY);
      await loadData(0, tableData.pageSize, savedFilters);
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page: number) => {
    loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = (numberOfRecordsPerPage: number) => {
    loadData(0, numberOfRecordsPerPage, filterValues);
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    if (_.isEqual(newFilters, filterValues)) {
      return;
    }
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    await loadData(0, tableData.pageSize, newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={'Users Filter'}
          filters={filterSections}
          onApplyFilter={onFilterUpdate}
        ></ListingFilterWidget>
      )
    );
  };

  const renderMainChildren = () => {
    return (
      <div style={{ marginTop: '20px' }}>
        <EnhancedTable
          title={'USERS'}
          name="count"
          orderByAscendingByDefault
          defaultOrderByColumn="firstName"
          inline={false}
          data={tableData}
          headers={headers}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handlePageChange={handlePageChange}
          currentPage={tableData.pageNumber}
          hideToolbar
          usePagination
          disableSelection
          actions={actions}
          loader={loading}
        />
        {userDrawerOpen && (
          <UserDrawer
            open={userDrawerOpen}
            onClose={() => {
              setSelectedUserId('');
              setUserDrawerOpen(false);
            }}
            onSuccess={() => {
              setSelectedUserId('');
              setUserDrawerOpen(false);
              handlePageChange(0);
            }}
            id={selectedUserId}
          />
        )}
      </div>
    );
  };

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      loading={!booted}
      name={'Users'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default UsersPage;
