import { isEmpty, isValidNumber } from '../../../../../utils/validationUtils';
import {
  IProposalCostCharges,
  IProposalCover,
  IProposalPageFormState,
} from './form';
import { IProposalDetailsSummary } from './index2';

export const validateCostWidgetField = (
  fieldName: keyof IProposalCostCharges,
  value: any,
  cover: IProposalCostCharges
): string => {
  if (fieldName === 'policyCost') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  // if (fieldName === "tpaFeesType") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  if (fieldName === 'chargesPercentage') {
    if (isEmpty(value)) {
      return 'Required';
    } else {
      if (!isValidNumber(value)) {
        return 'Invalid Number';
      }

      const numberValue = Number(value);

      if (numberValue < 0 || numberValue > 100) {
        return 'Value should be between 0 and 100';
      }
    }
  }

  if (fieldName === 'tpaFeesPercentage') {
    if (cover.tpaFeesType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'tpaFeesAmount') {
    if (cover.tpaFeesType === 'AMOUNT') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  return '';
};

export const validateCoverRepeaterField = (
  fieldName: keyof IProposalCover,
  value: any,
  cover: IProposalCover
): string => {
  if (fieldName === 'policyCover') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'coverPremiumType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'currency') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'coverPremiumPercentage') {
    if (cover.coverPremiumType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'coverPremiumValue') {
    if (cover.coverPremiumType === 'VALUE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'coverSumInsured') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  if (fieldName === 'excessOnClaimType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'excessOnClaimPercentage') {
    if (cover.excessOnClaimType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'excessOnClaimAmount') {
    if (cover.excessOnClaimType === 'VALUE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'excessOnClaimDays') {
    if (cover.excessOnClaimType === 'DAYS') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  return '';
};

export const validatePolicyPayerField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (fieldName === 'payerId') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'payerAddress') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validatePolicyOwnerField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (fieldName === 'ownerId') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'ownerAddress') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validatePolicyInsuredField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (fieldName === 'id') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'order') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'address') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validatePolicyBeneficiaryField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (fieldName === 'id') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'percentage') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'address') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validateExpatDetailsField = (
  fieldName: keyof IProposalPageFormState['values']['expatDetails'],
  value: any
): string => {
  if (fieldName === 'medicalClass') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }
  if (fieldName === 'emergency') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }
  return '';
};

export const validateProposalPageForm = (
  data: IProposalDetailsSummary,
  values: IProposalPageFormState['values']
) => {
  const { policyPersons, covers, costs, expatDetails } = values;

  const errors: IProposalPageFormState['errors'] = {
    policyPersons: {
      payer: {
        payerId: validatePolicyPayerField(
          'payerId',
          policyPersons.payer.payerId
        ),
        payerAddress: validatePolicyPayerField(
          'payerAddress',
          policyPersons.payer.payerAddress
        ),
        ownerIsDifferent: validatePolicyPayerField(
          'ownerIsDifferent',
          policyPersons.payer.ownerIsDifferent
        ),
      },
      owner: {
        ownerId: policyPersons.payer.ownerIsDifferent
          ? validatePolicyOwnerField('ownerId', policyPersons.owner.ownerId)
          : '',
        ownerAddress: policyPersons.payer.ownerIsDifferent
          ? validatePolicyOwnerField(
              'ownerAddress',
              policyPersons.owner.ownerAddress
            )
          : '',
      },
      insured: policyPersons.insured.map((insured) => ({
        id: validatePolicyInsuredField('id', insured.id),
        order: validatePolicyInsuredField('order', insured.order),
        address: validatePolicyInsuredField('address', insured.address),
      })),
      beneficiaries: policyPersons.beneficiaries.map((ben) => ({
        id: validatePolicyBeneficiaryField('id', ben.id),
        percentage: validatePolicyBeneficiaryField(
          'percentage',
          ben.percentage
        ),
        address: validatePolicyBeneficiaryField('address', ben.address),
      })),
    },
    covers: covers.map((c) => ({
      isMain: validateCoverRepeaterField('isMain', c.isMain, c),
      isMandatory: validateCoverRepeaterField('isMandatory', c.isMandatory, c),
      coverPremiumType: validateCoverRepeaterField(
        'coverPremiumType',
        c.coverPremiumType,
        c
      ),
      coverPremiumPercentage: validateCoverRepeaterField(
        'coverPremiumPercentage',
        c.coverPremiumPercentage,
        c
      ),
      coverPremiumValue: validateCoverRepeaterField(
        'coverPremiumValue',
        c.coverPremiumValue,
        c
      ),
      currency: validateCoverRepeaterField('currency', c.currency, c),
      coverSumInsured: validateCoverRepeaterField(
        'coverSumInsured',
        c.coverSumInsured,
        c
      ),
      sumInsuredIsAdditive: validateCoverRepeaterField(
        'sumInsuredIsAdditive',
        c.sumInsuredIsAdditive,
        c
      ),
      excessOnClaimType: validateCoverRepeaterField(
        'excessOnClaimType',
        c.excessOnClaimType,
        c
      ),
      excessOnClaimAmount: validateCoverRepeaterField(
        'excessOnClaimAmount',
        c.excessOnClaimAmount,
        c
      ),
      excessOnClaimPercentage: validateCoverRepeaterField(
        'excessOnClaimPercentage',
        c.excessOnClaimPercentage,
        c
      ),
      excessOnClaimDays: validateCoverRepeaterField(
        'excessOnClaimDays',
        c.excessOnClaimDays,
        c
      ),
      policyCover: validateCoverRepeaterField(
        'policyCover',
        c.excessOnClaimDays,
        c
      ),
    })),
    costs: {
      policyCost: validateCostWidgetField(
        'policyCost',
        costs.policyCost,
        costs
      ),
      chargesPercentage: validateCostWidgetField(
        'chargesPercentage',
        costs.chargesPercentage,
        costs
      ),
      tpaFeesType: validateCostWidgetField(
        'tpaFeesType',
        costs.tpaFeesType,
        costs
      ),
      tpaFeesAmount: validateCostWidgetField(
        'tpaFeesAmount',
        costs.tpaFeesAmount,
        costs
      ),
      tpaFeesPercentage: validateCostWidgetField(
        'tpaFeesPercentage',
        costs.tpaFeesPercentage,
        costs
      ),
      tpaFeesApplicableOn: validateCostWidgetField(
        'tpaFeesApplicableOn',
        costs.tpaFeesApplicableOn,
        costs
      ),
    },
    expatDetails: {
      medicalClass: validateExpatDetailsField(
        'medicalClass',
        expatDetails.medicalClass
      ),
      emergency: validateExpatDetailsField('emergency', expatDetails.emergency),
    },
  };

  const touched: IProposalPageFormState['touched'] = {
    policyPersons: {
      payer: {
        payerId: true,
        payerAddress: true,
        ownerIsDifferent: true,
      },
      owner: {
        ownerId: true,
        ownerAddress: true,
      },
      insured: policyPersons.insured.map((insured) => ({
        id: true,
        order: true,
        address: true,
      })),
      beneficiaries:
        policyPersons?.beneficiaries?.map((ben) => ({
          id: true,
          order: true,
          address: true,
        })) || [],
    },
    covers: covers.map((c) => ({
      isMain: true,
      isMandatory: true,
      coverPremiumType: true,
      coverPremiumPercentage: true,
      coverPremiumValue: true,
      currency: true,
      coverSumInsured: true,
      sumInsuredIsAdditive: true,
      excessOnClaimType: true,
      excessOnClaimAmount: true,
      excessOnClaimPercentage: true,
      excessOnClaimDays: true,
      policyCover: true,
    })),
    costs: {
      policyCost: true,
      chargesPercentage: true,
      tpaFeesType: true,
      tpaFeesAmount: true,
      tpaFeesPercentage: true,
      tpaFeesApplicableOn: true,
    },
    // motorDetails: {
    //   nameOnLicenseEn: true,
    //   nameOnLicenseAr: true,
    //   vignetteCode: true,
    //   vignette: true,
    //   plateCode: true,
    //   plateNumber: true,
    //   usageType: true,
    //   bodyType: true,
    //   engineType: true,
    //   brand: true,
    //   model: true,
    //   yearOfMake: true,
    //   motor: true,
    //   weight: true,
    //   engineSize: true,
    //   seats: true,
    //   chassis: true,
    //   horsePower: true,
    //   carValue: true,
    //   agencyRepairYear: true,
    // },
    expatDetails: {
      medicalClass: true,
      emergency: true,
      continuity: true,
      continuityNumber: true,
      exclusion: true,
      remarks: true,
    },
  };

  const isValid =
    Object.values(errors.costs).every((e) => isEmpty(e)) &&
    Object.values(errors.covers).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    ) &&
    Object.values(errors.policyPersons.payer).every((e) => isEmpty(e)) &&
    Object.values(errors.policyPersons.owner).every((e) => isEmpty(e)) &&
    Object.values(errors.policyPersons.insured).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    ) &&
    Object.values(errors.policyPersons.beneficiaries).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    );
  // Object.values(errors.motorDetails).every((e) => isEmpty(e));

  return { errors, touched, isValid };
};
