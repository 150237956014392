import { isEmpty } from 'lodash';
import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';

export const initialValues = {
  planCurrency: '',
  policyCover: '',
  isMain: false,
  isMandatory: false,
  isEditable: false,
  nbOfAdherent: '',
  class: '',
  coNssf: '',
  level: '',
  ageFrom: '',
  ageTo: '',
  sumInsured: '',
  sumInsuredIsAdditive: false,
  netPremium: '',
  tpaFeesPercentage: '',
  tpaFeesApplicableOn: '',
  minTPAFees: '',
  maxTPAFees: '',
  excessOnClaimType: '',
  excessOnClaimAmount: '',
  excessOnClaimPercentage: '',
  excessOnClaimDays: '',
  clauseReference: '',
};

export const getInputs = (
  values: typeof initialValues = initialValues,
  lovs: Record<string, Record<string, any>> = {},
  planCurrency: string
): Record<string, DynamicFormInputType> => {
  return {
    planCurrency: {
      name: 'planCurrency',
      title: 'Plan Currency',
      type: FormInputTypes.chips,
      placeholder: 'Plan Currency',
      value: values.planCurrency || planCurrency,
      multiple: false,
      disabled: true,
      required: true,
      selectOptions: lovs?.currencies || {},
    },
    policyCover: {
      name: 'policyCover',
      title: 'Policy Cover',
      type: FormInputTypes.chips,
      placeholder: 'Type to search for Policy Cover',
      value: !isEmpty(values.policyCover) ? values.policyCover : '',
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs?.policyCover || {},
    },
    isMain: {
      name: 'isMain',
      title: 'Main',
      type: FormInputTypes.switch,
      value: values.isMain,
      required: true,
    },
    isMandatory: {
      name: 'isMandatory',
      title: 'Mandatory',
      type: FormInputTypes.switch,
      value: values.isMandatory,
      required: true,
    },
    isEditable: {
      name: 'isEditable',
      title: 'Editable',
      type: FormInputTypes.switch,
      value: values.isEditable,
      required: true,
    },
    nbOfAdherent: {
      name: 'nbOfAdherent',
      title: 'Nb Of Adherent',
      placeholder: 'Nb Of Adherent',
      type: FormInputTypes.number,
      value: values.nbOfAdherent,
      required: true,
      minNumber: 0,
    },
    class: {
      name: 'class',
      title: 'Class',
      type: FormInputTypes.chips,
      placeholder: 'Class',
      value: values.class,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs.medicalPlanClasses,
    },
    coNssf: {
      name: 'coNssf',
      title: 'Co-Nssf',
      type: FormInputTypes.chips,
      placeholder: 'Co-Nssf',
      value: values.coNssf,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs?.coNSSFOptions || {},
    },
    level: {
      name: 'level',
      title: 'Level',
      type: FormInputTypes.chips,
      placeholder: 'Level',
      value: values.level,
      multiple: false,
      required: true,
      selectOptions: lovs.medicalLevels || {},
    },
    ageFrom: {
      name: 'ageFrom',
      title: 'Age From',
      placeholder: 'Age From',
      type: FormInputTypes.number,
      value: values.ageFrom,
      required: true,
      minNumber: 0,
      customValidator: (values) => {
        const { ageFrom, ageTo } = values;
        const fromAge = Number(ageFrom);
        const toAge = Number(ageTo);
        if (fromAge != 0 && toAge != 0 && fromAge > toAge) {
          return 'Age From must be less than Age To';
        }
        return '';
      },
    },
    ageTo: {
      name: 'ageTo',
      title: 'Age To',
      placeholder: 'Age To',
      type: FormInputTypes.number,
      value: values.ageTo,
      required: true,
      customValidator: (values) => {
        const { ageFrom, ageTo } = values;
        const fromAge = Number(ageFrom);
        const toAge = Number(ageTo);
        if (fromAge != 0 && toAge != 0 && fromAge > toAge) {
          return 'Age To must be greater than Age From';
        }
        return '';
      },
    },
    sumInsured: {
      name: 'sumInsured',
      title: 'SumInsured',
      placeholder: 'SumInsured',
      type: FormInputTypes.formattedNumber,
      value: values.sumInsured,
      required: true,
      minValue: 0,
    },
    sumInsuredIsAdditive: {
      name: 'sumInsuredIsAdditive',
      title: 'Additive SumInsured',
      type: FormInputTypes.switch,
      value: values.sumInsuredIsAdditive,
      required: true,
    },
    netPremium: {
      name: 'netPremium',
      title: 'Net Premium',
      placeholder: 'Net Premium',
      type: FormInputTypes.formattedNumber,
      value: values.netPremium,
      required: true,
      minValue: 0,
    },
    tpaFeesPercentage: {
      name: 'tpaFeesPercentage',
      title: 'TPA Fees Percentage',
      placeholder: 'TPA Fees Percentage',
      type: FormInputTypes.percentage,
      value: values.tpaFeesPercentage,
      required: false,
    },
    tpaFeesApplicableOn: {
      name: 'tpaFeesApplicableOn',
      title: 'TPA Fees Applicable On',
      type: FormInputTypes.chips,
      placeholder: 'TPA Fees ApplicableOn',
      value: !isEmpty(values.tpaFeesApplicableOn)
        ? values.tpaFeesApplicableOn
        : '',
      multiple: false,
      disabled: false,
      required: false,
      selectOptions: lovs?.tpaApplicableOnOptions || {},
    },
    minTPAFees: {
      name: 'minTPAFees',
      title: 'Min TPA Fees',
      placeholder: 'Min TPA Fees',
      type: FormInputTypes.formattedNumber,
      value: values.minTPAFees,
      required: false,
      minValue: 0,
      customValidator: (values) => {
        const { minTPAFees, maxTPAFees } = values;
        const tPAFeesMin = Number(minTPAFees);
        const tPAFeesMax = Number(maxTPAFees);
        if (tPAFeesMin != 0 && tPAFeesMax != 0 && tPAFeesMin > tPAFeesMax) {
          return 'Min TPA Fees must be less than Max TPA Fees';
        }
        return '';
      },
    },
    maxTPAFees: {
      name: 'maxTPAFees',
      title: 'Max TPA Fees',
      placeholder: 'Max TPA Fees',
      type: FormInputTypes.formattedNumber,
      value: values.maxTPAFees,
      required: false,
      customValidator: (values) => {
        const { minTPAFees, maxTPAFees } = values;
        const tPAFeesMin = Number(minTPAFees);
        const tPAFeesMax = Number(maxTPAFees);
        if (tPAFeesMin != 0 && tPAFeesMax != 0 && tPAFeesMin > tPAFeesMax) {
          return 'Max TPA Fees must be greater than Min TPA Fees';
        }
        return '';
      },
    },
    excessOnClaimType: {
      name: 'excessOnClaimType',
      title: 'Excess On Claim Type',
      placeholder: 'Excess On Claim Type',
      type: FormInputTypes.chips,
      value: values.excessOnClaimType,
      multiple: false,
      required: true,
      selectOptions: {
        PERCENTAGE: 'Percentage',
        VALUE: 'Value',
        DAYS: 'Days',
      },
    },
    excessOnClaimAmount: {
      name: 'excessOnClaimAmount',
      title: 'Excess On Claim Amount',
      placeholder: 'Excess On Claim Amount',
      type: FormInputTypes.formattedNumber,
      value: values.excessOnClaimAmount,
      required: values.excessOnClaimType === 'VALUE',
      minValue: 0,
      hidden: values.excessOnClaimType != 'VALUE',
    },
    excessOnClaimPercentage: {
      name: 'excessOnClaimPercentage',
      title: 'Excess On Claim (Percentage)',
      placeholder: 'Excess On Claim (Percentage)',
      type: FormInputTypes.percentage,
      value: values.excessOnClaimPercentage,
      required: values.excessOnClaimType === 'PERCENTAGE',
      hidden: values.excessOnClaimType != 'PERCENTAGE',
    },
    excessOnClaimDays: {
      name: 'excessOnClaimDays',
      title: 'Excess On Claim (Days)',
      placeholder: 'Excess On Claim (Days)',
      type: FormInputTypes.number,
      value: values.excessOnClaimDays,
      required: values.excessOnClaimType === 'DAYS',
      hidden: values.excessOnClaimType != 'DAYS',
      minNumber: 0,
    },
    clauseReference: {
      name: 'clauseReference',
      title: 'Clause Reference',
      placeholder: 'Clause Reference',
      type: FormInputTypes.chips,
      value: values.clauseReference,
      required: false,
      hasPagination: false,
      itemsPerPage: 15,
      multiple: false,
      order: 15,
      selectOptions: lovs?.clauseReference || {},
    },
  };
};
