// import { IAbstractRecord } from "../../models";

export interface IApplicationDetails {
  applicationName: string;
  effectiveDate: string;
  expiryDate: string;
  isRenewal: string;
  renewalNumber: string;
  plateCode: string;
  plateNumber: number;
  usageType: string;
  bodyType: string;
  engineType: string;
  brand: string;
  model: string;
  yearOfMake: number;
  motor: string;
  weight: number;
  engineSize: number;
  seats: number;
  chassis: string;
  horsePower: number;
  status: string;
  statusReason: string;
  lineOfBusiness: string;
  agencyName: string;
  agentName: string;
  customerName: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  planDetails: {
    planId?: string;
    name: string;
    product: string;
    towingService?: string;
    vignetteCode?: string;
    vignette?: number;
    coverageType?: string;
    carValue?: number;
    includeAgencyRepair?: string;
  }[];
  nameOnLicenseEn: string;
  nameOnLicenseAr: string;
  email: string;
  mobileNumber: string;
  country: string;
  city: string;
  street: string;
  building: string;
  title: string;
  fullName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneType: string;
}

export const initialValues: IApplicationDetails = {
  applicationName: '',
  effectiveDate: '',
  expiryDate: '',
  isRenewal: 'NO',
  renewalNumber: '',
  plateCode: '',
  plateNumber: undefined,
  usageType: '',
  bodyType: '',
  engineType: 'Fuel',
  brand: '',
  model: '',
  yearOfMake: undefined,
  motor: '',
  weight: undefined,
  engineSize: undefined,
  seats: undefined,
  chassis: '',
  horsePower: undefined,
  status: '',
  statusReason: '',
  lineOfBusiness: '',
  agencyName: '',
  agentName: '',
  customerName: '',
  createdBy: '',
  createdOn: '',
  modifiedBy: '',
  modifiedOn: '',
  planDetails: [
    {
      planId: undefined,
      name: '',
      product: '',
      towingService: undefined,
      vignetteCode: '',
      vignette: undefined,
      coverageType: undefined,
      carValue: undefined,
      includeAgencyRepair: undefined,
    },
  ],
  nameOnLicenseEn: '',
  nameOnLicenseAr: '',
  email: '',
  mobileNumber: '',
  country: 'LB',
  city: '',
  street: '',
  building: '',
  title: '',
  fullName: '',
  firstName: '',
  middleName: '',
  lastName: '',
  phoneType: 'MOBILE',
};
export interface IPlan {
  input_Id: string;
  quote_Name: string;
  input_Product: {
    Code: string;
    Title: string;
  };
  input_TowingService: {
    Id: string;
  };
  input_CoverageType: string;
  input_VignetteCode: string;
  input_Vignette: number;
  input_CarValue: number;
  input_IncludeAgencyRepair: string;
}

export interface IPlanDetailsProductsCounter {
  AllRisk: number;
  BodilyInjury: number;
  MaterialDamage: number;
  TotalLoss: number;
}

export const initValuesPlanDetailsProductsCounter: IPlanDetailsProductsCounter =
  {
    AllRisk: 0,
    BodilyInjury: 0,
    MaterialDamage: 0,
    TotalLoss: 0,
  };
