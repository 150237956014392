import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  salesforceManagement_Person_PersonCode: {
    name: 'salesforceManagement_Person_PersonCode',
    title: 'Code',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  salesforceManagement_Person_FullName: {
    name: 'salesforceManagement_Person_FullName',
    title: 'Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  salesforceManagement_Person_Title: {
    name: 'salesforceManagement_Person_Title',
    title: 'Title',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_Person_PersonType: {
    name: 'salesforceManagement_Person_PersonType',
    title: 'Type',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_Person_Email: {
    name: 'salesforceManagement_Person_Email',
    title: 'Email',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_Person_PhoneDetails: {
    name: 'salesforceManagement_Person_PhoneDetails',
    title: 'Phone Number',
    type: EnhancedTableHeaderType.PhoneNumber,
  },
  salesforceManagement_Person_Status: {
    name: 'salesforceManagement_Person_Status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_Person_createdOn: {
    name: 'salesforceManagement_Person_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  salesforceManagement_Person_modifiedOn: {
    name: 'salesforceManagement_Person_modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        type: {
          name: 'type',
          title: 'Type',
          type: FormInputTypes.chips,
          value: filterValues?.type || [],
          placeholder: 'Type',
          triggerUpdate: true,
          selectOptions: { ...lovs.type },
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues?.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
