import React from 'react';
import { EnhancedUpdatedTableHeader } from '.';
import EnhancedTableBodyCell from './EnhancedTableBodyCell';
import { ITableRowSettings } from '.';
import EnhancedCheckbox from './shared/EnhancedCheckbox';
import { TableBody, TableRow, TableCell } from '@mui/material';

interface IEnhancedBodyProps {
  disableSelection?: boolean | DisableSelectionType;
  emptyRows: number;
  handleClick(name: string, checked: boolean): void;
  headers: Record<string, EnhancedUpdatedTableHeader>;
  rowSettings?: ITableRowSettings;
  isSelected: (key: string) => boolean;
  rows: IEnhancedRow[];
  currencySymbol?: string;
  dateFormat?: string;
  // phoneNumberPattern: string;
  entityName?: string;
  loader?: boolean;
  tableClasses?: {
    bodyRootClass?: string;
    cellRootClass?: string;
  };
}

export enum DisableSelectionType {
  'Hide' = 'Hide',
}

export interface IEnhancedRow {
  key: string;
  columns: Record<string, string>;
  index: number;
}

const EnhancedTableBody: React.FC<IEnhancedBodyProps> = ({
  emptyRows,
  handleClick,
  headers,
  isSelected,
  rows,
  currencySymbol,
  dateFormat,
  rowSettings,
  disableSelection,
  tableClasses,
  entityName,
  loader,
}) => {
  //const classes = useStyles();
  return (
    <TableBody>
      {rows.length > 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.key);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.key}
              selected={isItemSelected}
            >
              {!disableSelection && (
                <TableCell>
                  <EnhancedCheckbox
                    checked={isItemSelected}
                    name={row.key}
                    onChange={handleClick}
                    checkboxOnly
                  />
                </TableCell>
              )}
              {}
              {Object.values(headers).map((header, headerIndex) => {
                return (
                  <EnhancedTableBodyCell
                    selected={isSelected(row.key)}
                    key={header.name + labelId}
                    row={row}
                    header={header}
                    currencySymbol={currencySymbol}
                    dateFormat={dateFormat}
                    //phoneNumberPattern={phoneNumberPattern}
                  />
                );
              })}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell
            rowSpan={2}
            colSpan={
              Object.keys(headers).length > 0
                ? Object.keys(headers).length + 1
                : 1
            }
            align="center"
          >
            {loader ? '' : 'No Data'}
          </TableCell>
        </TableRow>
      )}
      {emptyRows > 0 && <TableRow style={{ height: 34 * emptyRows }} />}
    </TableBody>
  );
};

export default EnhancedTableBody;
