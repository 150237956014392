import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  planCurrency: '',
  ageFrom: '',
  ageTo: '',
  gender: '',
  nationality: '',
  occupationClass: '',
  premium: '',
  medicalClass: '',
  emergency: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  planCurrency: {
    name: 'planCurrency',
    title: 'Plan Currency',
    type: FormInputTypes.chips,
    placeholder: 'Plan Currency',
    value: !isEmpty(initialValues.planCurrency)
      ? initialValues.planCurrency
      : '',
    multiple: false,
    disabled: true,
    required: true,
  },
  ageFrom: {
    name: 'ageFrom',
    title: 'Age From',
    placeholder: 'Age From',
    type: FormInputTypes.number,
    value: initialValues.ageFrom,
    required: true,
    minNumber: 0,
    customValidator: (values) => {
      const { ageFrom, ageTo } = values;
      const fromAge = Number(ageFrom);
      const toAge = Number(ageTo);
      if (fromAge != 0 && toAge != 0 && fromAge > toAge) {
        return 'Age From must be less than Age To';
      }
      return '';
    },
  },
  ageTo: {
    name: 'ageTo',
    title: 'Age To',
    placeholder: 'Age To',
    type: FormInputTypes.number,
    value: initialValues.ageTo,
    required: true,
    customValidator: (values) => {
      const { ageFrom, ageTo } = values;
      const fromAge = Number(ageFrom);
      const toAge = Number(ageTo);
      if (fromAge != 0 && toAge != 0 && fromAge > toAge) {
        return 'Age To must be greater than Age From';
      }
      return '';
    },
  },
  gender: {
    name: 'gender',
    title: 'Gender',
    type: FormInputTypes.chips,
    placeholder: 'Gender',
    value: !isEmpty(initialValues.gender) ? initialValues.gender : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  occupationClass: {
    name: 'occupationClass',
    title: 'Occupation Class',
    type: FormInputTypes.chips,
    placeholder: 'Occupation Class',
    value: !isEmpty(initialValues.occupationClass)
      ? initialValues.occupationClass
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  nationality: {
    name: 'nationality',
    title: 'Nationality',
    type: FormInputTypes.chips,
    placeholder: 'Nationality',
    value: !isEmpty(initialValues.nationality) ? initialValues.nationality : '',
    multiple: true,
    disabled: false,
    required: true,
    showSelectAll: true,
  },
  medicalClass: {
    name: 'medicalClass',
    title: 'Medical Class',
    type: FormInputTypes.chips,
    placeholder: 'Medical Class',
    value: !isEmpty(initialValues.medicalClass)
      ? initialValues.medicalClass
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  emergency: {
    name: 'emergency',
    title: 'Emergency Included',
    type: FormInputTypes.chips,
    placeholder: 'Emergency Included',
    value: !isEmpty(initialValues.emergency) ? initialValues.emergency : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  premium: {
    name: 'premium',
    title: 'Premium',
    placeholder: 'Premium',
    type: FormInputTypes.formattedNumber,
    value: initialValues.premium,
    required: true,
    minValue: 0,
  },
};
