import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Separator from '../../common/Separator';
import EnhancedDisplayWrapper from '../../enhanced-form/EnhancedDisplayWrapper';
import { IEnhancedDynamicDisplay } from '../dynamic-display';
import { isEmpty } from '../../../utils/validationUtils';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../../constants';

export interface IEntityInfoFieldsProps {
  view: IEntityInfoView;
}

export interface IEntityInfoView {
  name?: string;
  title?: string;
  iconUrl: string;
  sections: IDynamicSection[];
  socialMedia: {
    facebook: string;
    twitter: string;
    linkedIn: string;
  };
}

interface IDynamicSection {
  name: string;
  properties: Record<string, IEnhancedDynamicDisplay>;
  title: string;
  hidden?: boolean;
}

const useStyles = makeStyles()(() => ({
  infoContainer: {
    width: '100%',
  },
  sectionTitle: {
    textAlign: 'left',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}!important`,
    fontFamily: contentFontFamilyRegular,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary2.main,
    opacity: 1,
    margin: '0 0 7px',
    display: 'block',
  },
  separator: {
    height: '4px !important',
    margin: '15px 0 !important',
  },
  propertyTitle: {
    fontWeight: 'bold',
  },
  socialMediaContainer: {
    height: '24px',
    margin: '5px 0 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  socialMediaIcon: {
    margin: '0 4px 0 5px',
    color: '#f5533d',
  },
}));

const EntityInfoFields: React.FC<IEntityInfoFieldsProps> = ({ view }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.infoContainer}>
      {view.sections
        .filter((s) => !s.hidden)
        .map((section, index) => (
          <div key={section.name}>
            {index !== 0 && <Separator className={classes.separator} />}
            {!!section.title && (
              <span className={classes.sectionTitle}>{section.title}</span>
            )}
            {Object.values(section.properties)
              .filter((p) => !p.hidden)
              .map((property) => {
                return (
                  <EnhancedDisplayWrapper
                    key={property.name}
                    displayInline
                    classes={{
                      propertyTitle: classes.propertyTitle,
                    }}
                    {...property}
                  />
                );
              })}
          </div>
        ))}
      <div className={classes.socialMediaContainer}>
        {!isEmpty(view.socialMedia.linkedIn) && (
          <a href={view.socialMedia.linkedIn} target="_blank" rel="noreferrer">
            <LinkedInIcon className={classes.socialMediaIcon} />
          </a>
        )}
        {!isEmpty(view.socialMedia.facebook) && (
          <a href={view.socialMedia.facebook} target="_blank" rel="noreferrer">
            <FacebookIcon className={classes.socialMediaIcon} />
          </a>
        )}
        {!isEmpty(view.socialMedia.twitter) && (
          <a href={view.socialMedia.twitter} target="_blank" rel="noreferrer">
            <TwitterIcon className={classes.socialMediaIcon} />
          </a>
        )}
      </div>
    </div>
  );
};

export default EntityInfoFields;
