import usePagination from '@mui/material/usePagination';
import { useMemo } from 'react';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

interface IEnhancedTablePaginationProps {
  onPageChange: (pageNumber: number) => void;
  page: number;
  count: number;
  pageSize?: number;
}

const useStyles = makeStyles()(() => ({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    padding: '20px 24px',
    borderTop: '0.5px solid #EAECF0',
  },
  actionButton: {
    width: '114px',
    color: '#344054',
    border: '1px solid #D0D5DD',
    borderRadius: '7.5px',
    backgroundColor: '#fff',
    fontSize: '15px',
    '&:hover': { backgroundColor: '#26B6E0', color: '#fff' },
  },
  listButton: {
    width: '40px',
    height: '40px',
    fontSize: '14px',
    color: '#637087',
    fontFamily: 'OpenSans-Regular',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  selectedListButton: {
    backgroundColor: '#F9FAFB',
    color: '#19293A',
  },
  ellipsis: {
    display: 'block',
    fontSize: '14px',
    lineHeight: '40px',
    color: '#637087',
  },
}));

const EnhancedTablePagination: React.FC<IEnhancedTablePaginationProps> = ({
  page,
  onPageChange,
  count,
  pageSize = 10,
}) => {
  const { classes, cx } = useStyles();

  const pageCount = useMemo(() => {
    return Math.ceil(count / pageSize);
  }, [count, pageSize]);

  const { items } = usePagination({
    count: pageCount,
    page: page,
    defaultPage: 1,
    siblingCount: 1,
    onChange: (_, pageNumber) => {
      onPageChange(pageNumber);
    },
  });

  return (
    <nav>
      <div className={classes.container}>
        {/* {previousButton()} */}
        <ul className={classes.list}>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              children = (
                <span
                  key={`table-pagination-index-${index}`}
                  className={classes.ellipsis}
                >
                  {'…'}
                </span>
              );
            } else if (type === 'page') {
              children = (
                <button
                  type="button"
                  key={`table-pagination-index-${index}`}
                  className={cx(
                    classes.listButton,
                    selected ? classes.selectedListButton : undefined
                  )}
                  {...item}
                >
                  {page}
                </button>
              );
            } else {
              return <></>;
            }

            return <li key={`table-pagination-index-${index}`}>{children}</li>;
          })}
        </ul>
        {/* {nextButton()} */}
      </div>
    </nav>
  );
};

export default EnhancedTablePagination;
