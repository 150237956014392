import React, { PropsWithChildren } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { USER_ROLES } from '../../redux/user/types';

interface IShowForUserProps {
  allowedRoles: (keyof typeof USER_ROLES)[];
  allowedAgentTypes?: string[];
}

const ShowForUser: React.FC<PropsWithChildren<IShowForUserProps>> = ({
  allowedRoles,
  allowedAgentTypes = [],
  children,
}) => {
  const user = useAppSelector((state) => state.user);
  const {
    userRoles,
    info: { agentType: userAgentType },
  } = user;

  const hasAllowedRole = userRoles.some((userRole) =>
    allowedRoles.includes(userRole)
  );
  const hasAllowedAgentType =
    allowedAgentTypes.length === 0 ||
    (userRoles.includes('Insurance-IssuingAgent') &&
      allowedAgentTypes.includes(userAgentType));

  return hasAllowedRole || hasAllowedAgentType ? <>{children}</> : null;
};

export default ShowForUser;
