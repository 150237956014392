import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { mainFontFamilyMedium } from '../../../../../../constants';

interface IPageTitleProps {
  text: string;
}

const useStyles = makeStyles()(() => ({
  title: {
    fontSize: 22,
    fontFamily: mainFontFamilyMedium,
  },
}));

const PageTitle: React.FC<IPageTitleProps> = ({ text }) => {
  const { classes } = useStyles();
  return <h1 className={classes.title}>{text}</h1>;
};

export default PageTitle;
