import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';

export const steps = ['Inactive', 'Active'];

export const claimCoverHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_ClaimCover_Name: {
    name: 'planConfigManagement_ClaimCover_Name',
    title: 'Claim Cover Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_ExternalCode: {
    name: 'planConfigManagement_ClaimCover_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_PolicyCoverID: {
    name: 'planConfigManagement_ClaimCover_PolicyCoverID',
    title: 'Policy Cover',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_NameArabic: {
    name: 'planConfigManagement_ClaimCover_NameArabic',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_PrintingOrder: {
    name: 'planConfigManagement_ClaimCover_PrintingOrder',
    title: 'Printing Order',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_ClaimCoverDescription: {
    name: 'planConfigManagement_ClaimCover_ClaimCoverDescription',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_createdOn: {
    name: 'planConfigManagement_ClaimCover_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_ClaimCover_modifiedOn: {
    name: 'planConfigManagement_ClaimCover_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};
