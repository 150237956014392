import { gql } from '@apollo/client';

export function getLastModifiedOnModifiedByTransferLinksQuery() {
  return gql`
    query GetData($pageNumber: Int!, $pageSize: Int!) {
      Accounting {
        queries {
          GetTransferLinksModifiedByModifiedOn(
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            items {
              accounting_AccountingTransferLinks_modifiedBy
              accounting_AccountingTransferLinks_modifiedOn
            }
          }
        }
      }
    }
  `;
}

export function getTransferLinksFilterConfig() {
  return gql`
    query getTransferLinksFilterConfig {
      Accounting {
        lookups {
          transferLink {
            Code
            Id
            Title
          }
          transferType {
            Code
            Id
            Title
          }
        }
      }
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getTransferLinks() {
  return gql`
    query getTransferLinks(
      $pageNumber: Int!
      $pageSize: Int!
      $company: String!
      $transferType: String!
      $links: [String!]!
    ) {
      Accounting {
        queries {
          GetTransferLinks(
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
            Company: $company
            TransferType: $transferType
            Link: $links
          ) {
            paging {
              pageNumber
              pageSize
              totalCount
            }
            items {
              accounting_AccountingTransferLinks_Id
              accounting_AccountingTransferLinks_ChartofAccount
              line_Name
              accounting_AccountingTransferLinks_TransferLink {
                Code
                Title
              }
            }
          }
        }
      }
    }
  `;
}

export function getAccountIdsByCompany() {
  return gql`
    query getAccountIdsByCompany($selectedCompanyID: String) {
      Accounting {
        queries {
          GetAccountsOfCompany(SelectedCompanyID: $selectedCompanyID) {
            accounting_ChartOfAccounts_Id
            accounting_ChartOfAccounts_AccountName
            accounting_ChartOfAccounts_AccountID
          }
        }
      }
    }
  `;
}

export function updateTransferLinksMutation() {
  return gql`
    mutation UpdateData($transferLinks: [Accounting_TransferLinks!]!) {
      accounting {
        actions {
          updateTransferLink(TransferLinks: $transferLinks)
        }
      }
    }
  `;
}

export function getUserFullName() {
  return gql`
    query getUserFullName($id: String!) {
      System {
        entities {
          user(id: $id) {
            views {
              System_all {
                properties {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  `;
}
