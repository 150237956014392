import { gql } from '@apollo/client';

export function createNssfCodeRange() {
  return gql`
    mutation createNSSFCodeRange($rangeFrom: Long!, $rangeTo: Long!) {
      insurance {
        actions {
          createNSSFCodeRange(rangeFrom: $rangeFrom, rangeTo: $rangeTo) {
            views {
              Insurance_all {
                properties {
                  RangeFrom
                  RangeTo
                }
              }
            }
          }
        }
      }
    }
  `;
}
