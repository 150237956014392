import {
  Button,
  ButtonProps,
  CircularProgress,
  Fade,
  lighten,
} from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import CheckIcon from '@mui/icons-material/Check';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../../constants';

export type EnhancedButtonStatus = 'loading' | 'success';

export interface IEnhancedButtonProps extends Omit<ButtonProps, 'color'> {
  state?: EnhancedButtonStatus;
  isPrimary?: boolean;
}

const useStyles = makeStyles<{
  backgroundColor: string;
  color: string;
  state: EnhancedButtonStatus;
  isPrimary: boolean;
}>()((theme, { backgroundColor, color, state, isPrimary }) => ({
  root: {
    position: 'relative',
    borderRadius: 3,
    minHeight: 32,
    minWidth: 80,
    border: isPrimary
      ? '0'
      : `1px ${MAIN_ONE_THEME.palette.secondary3.main} solid`,
    boxSizing: 'border-box',
    padding: '0 15px',
    backgroundColor: backgroundColor,
    fontFamily: contentFontFamilyRegular,
    textTransform: 'capitalize',
    color: color || MAIN_ONE_THEME.palette.primary1.main,
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: '15px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: lighten(backgroundColor, 0.25),
      '&:disabled': {
        backgroundColor: lighten(backgroundColor, 0.25),
      },
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.4,
      pointerEvents: 'all',
      color: color || MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  buttonProgress: {
    color: color || MAIN_ONE_THEME.palette.primary1.main,
    position: 'absolute',
    left: 'calc(50% - 10px)',
    top: 'calc(50% - 10px)',
  },
  labelProgress: {
    visibility:
      state === 'loading' || state === 'success' ? 'hidden' : 'visible',
  },
  buttonIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    background: backgroundColor,
    color: color || MAIN_ONE_THEME.palette.primary1.main,
    width: '100%',
    height: '100%',
  },
}));

const EnhancedButton: React.FC<IEnhancedButtonProps> = ({
  state,
  disabled,
  isPrimary,
  children,
  ...props
}) => {
  const { classes: buttonClasses } = useStyles({
    backgroundColor: isPrimary
      ? MAIN_ONE_THEME.palette.primary1.main
      : 'rgba(255, 255, 255, 1)',
    color: isPrimary ? '#fff' : MAIN_ONE_THEME.palette.primary1.main,
    state,
    isPrimary,
  });
  return (
    <Button
      classes={{
        root: buttonClasses.root,
      }}
      disabled={state === 'loading' || state === 'success' || disabled}
      {...props}
    >
      <span className={buttonClasses.labelProgress}>{children}</span>
      {state === 'loading' && (
        <CircularProgress
          className={buttonClasses.buttonProgress}
          size={20}
          thickness={3}
        />
      )}

      <Fade in={state === 'success'} timeout={250}>
        <CheckIcon className={buttonClasses.buttonIcon} />
      </Fade>
    </Button>
  );
};

export default EnhancedButton;
