import { gql } from '@apollo/client';

export function loadValuesQuery() {
  return gql`
    query loadValues($id: String!) {
      SalesforceManagement {
        entities {
          businessPartner(id: $id) {
            views {
              SalesforceManagement_all {
                properties {
                  RelatedCompany {
                    Id
                  }
                }
              }
            }
          }
        }
      }
      Accounting_PaymentMethodList: __type(
        name: "Accounting_PaymentMethodList"
      ) {
        name
        enumValues {
          name
        }
      }
      Accounting_BillTypeList: __type(name: "Accounting_BillTypeList") {
        name
        enumValues {
          name
        }
      }
      Core {
        queries {
          getSystemCurrencies {
            core_SystemCurrency_Id
            core_SystemCurrency_BasicCurrency {
              Id
              Code
              Title
              Symbol
            }
            core_SystemCurrency_SecondaryCurrency {
              Id
              Code
              Title
              Symbol
            }
          }
        }
        lookups {
          currencies {
            Id
            Title
            Symbol
            Code
          }
        }
      }
    }
  `;
}

export function getAccountsByCompanyAndCurrencyQuery() {
  return gql`
    query getAccountsByCompanyAndCurrency(
      $selectedCompanyID: String
      $accountCurrency: String
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      Accounting {
        queries {
          GetAccountsByCompanyandCurrency(
            SelectedCompany: $selectedCompanyID
            AccountCurrency: $accountCurrency
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            paging {
              totalCount
              pageSize
              pageNumber
            }
            items {
              accounting_ChartOfAccounts_AccountID
              accounting_ChartOfAccounts_AccountName
              accounting_ChartOfAccounts_Id
            }
          }
        }
      }
    }
  `;
}

export function getPaymentDetailsQuery() {
  return gql`
    query getPaymentDetails($paymentId: String!) {
      Accounting {
        queries {
          GetPaymentDetails(PaymentID: $paymentId) {
            accounting_Payments_Id
            accounting_Payments_PaymentDate
            accounting_Payments_PaymentCurrency
            accounting_Payments_PaymentMethod
            accounting_Payments_ReferenceNo
            accounting_Payments_TotalAmountReceived
            policyDetails_PolicyNumber
            billDetails_BillNumber
            paymentDetails_BillID {
              id
              views {
                Accounting_all {
                  properties {
                    Currency {
                      Symbol
                      Code
                    }
                  }
                }
              }
            }
            billDetails_DueDate
            billDetails_AmountDue
            paymentDetails_AmountOutstanding
            paymentDetails_AmountOutstandingCurrency
            paymentDetails_AmountReceived
            transactionDetails_RelatedAccount {
              views {
                Accounting_all {
                  properties {
                    AccountID
                    AccountName
                    AccountCurrency {
                      Id
                      Code
                      Symbol
                    }
                  }
                }
              }
            }
            transactionDetails_Id
            transactionDetails_Description
            transactionDetails_AccountDebit
            transactionDetails_AccountCredit
            transactionDetails_SystemDebit
            transactionDetails_SystemCredit
            transactionDetails_SystemSecondaryDebit
            transactionDetails_SystemSecondaryCredit
            transaction_TotalSystemDebit
            transaction_TotalSystemCredit
            accounting_Payments_PaymentDocuments {
              contentType
              fileName
              id
              length
              location
              path
            }
            accounting_Payments_Note
          }
        }
      }
    }
  `;
}

export const modifyPaymentMutation = `
  mutation modifyPayment(
    $currentPaymentID: String!
    $note: String
    $UploadedDocuments: [Upload]
    $PersistDocuments: [String]
  ) {
    accounting {
      actions {
        modifyPayment(
          CurrentPaymentID: $currentPaymentID
          Note: $note
          UploadedDocuments: $UploadedDocuments
          PersistDocuments: $PersistDocuments
        )
      }
    }
  }
`;
