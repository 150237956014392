import dayjs from 'dayjs';
import {
  ILovsTypes,
  IPaymentSimulationResponseData,
  IPaymentSimulationTableData,
  IPaymentTermsPopupFormState,
} from '.';
import { formatDate } from '../../utils/formatting-utils';

export const getPaymentTermsPopupFormState =
  (): IPaymentTermsPopupFormState => ({
    values: {
      policyNumber: null,
      policyIssuanceDate: null,
      policyExpiryDate: null,
      paymentType: null,
      effectiveFrom: null,
      frequency: null,
      numOfPayments: null,
      bills: [],
    },
    errors: {
      policyIssuanceDate: null,
      policyExpiryDate: null,
      paymentType: null,
      effectiveFrom: null,
      frequency: null,
      numOfPayments: null,
      amountDue: null,
    },
    touched: {
      policyIssuanceDate: false,
      policyExpiryDate: false,
      paymentType: false,
      effectiveFrom: false,
      frequency: false,
      numOfPayments: false,
    },
  });

export const FREQUENCY_LOVS: Record<string, string> = {
  Month: 'Monthly',
  Quarter: 'Quarterly',
  SemiAnnual: 'Semesterly',
  Annual: 'Yearly',
};

export const frequencyArrayToList = (arr: string[]) => {
  const list: Record<string, any> = {};

  arr.forEach((element: string) => {
    list[element] = FREQUENCY_LOVS[element];
  });

  return list;
};

export const numToList = (num: number) => {
  const list: Record<string, any> = {};

  for (let i = 1; i <= num; i++) {
    list[i] = i;
  }

  return list;
};

export const mapToListingData = (
  data: Array<IPaymentSimulationResponseData>,
  lovs: ILovsTypes,
  currencySymbol: string
) => {
  const list: Record<string, IPaymentSimulationTableData> = {};

  data.forEach((obj: IPaymentSimulationResponseData, i: number) => {
    list[i] = {
      billNumber: obj.BillNumber,
      dueDate: obj.DueDate,
      totalPremium: obj.TotalPremium,
      totalCommission: obj.TotalCommission,
      amountDue: obj.AmountDue.toFixed(2),
      outstandingAmount: obj.OutstandingBalance,
      status: lovs.billStatuses[obj.BillStatus],
      disabled: obj.BillStatus.toLowerCase() === 'paid',
      Currency: currencySymbol,
    };
  });

  // sort by due date ascending using dayjs
  const sortedList = Object.values(list).sort((a, b) => {
    return dayjs(a.dueDate).diff(dayjs(b.dueDate));
  });

  return sortedList;
};
