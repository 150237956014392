import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export function getHeaders(
  navigate: (path: string) => void
): Record<string, EnhancedTableHeader> {
  return {
    policyNumber: {
      name: 'policyNumber',
      title: 'Policy Number',
      type: EnhancedTableHeaderType.Clickable,
      callback: (payload: any) => {
        navigate('/production/policies/' + payload.columns.id);
      },
    },
    name: {
      name: 'name',
      title: 'Name',
      type: EnhancedTableHeaderType.Clickable,
      callback: (payload: any) => {
        navigate('/production/policies/' + payload.columns.id);
      },
    },
    line: {
      name: 'line',
      title: 'Line',
      type: EnhancedTableHeaderType.Text,
    },
    subline: {
      name: 'subline',
      title: 'Subline',
      type: EnhancedTableHeaderType.Text,
    },
    plan: {
      name: 'plan',
      title: 'Plan',
      type: EnhancedTableHeaderType.Text,
    },
    insured: {
      name: 'insured',
      title: 'Insured',
      type: EnhancedTableHeaderType.Text,
    },
    issueDate: {
      name: 'issueDate',
      title: 'Issue Date',
      type: EnhancedTableHeaderType.Date,
    },
    effectiveDate: {
      name: 'effectiveDate',
      title: 'Effective Date',
      type: EnhancedTableHeaderType.Date,
    },
    status: {
      name: 'status',
      title: 'Status',
      type: EnhancedTableHeaderType.Text,
    },
    statusReason: {
      name: 'statusReason',
      title: 'Status Reason',
      type: EnhancedTableHeaderType.Text,
    },
  };
}

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        line: {
          name: 'line',
          title: 'Line',
          type: FormInputTypes.chips,
          value: filterValues?.line || [],
          placeholder: 'Line',
          triggerUpdate: true,
          selectOptions: { ...lovs.line },
        },
        subline: {
          name: 'subline',
          title: 'Subline',
          type: FormInputTypes.chips,
          value: filterValues?.subline || [],
          placeholder: 'Subline',
          triggerUpdate: true,
          selectOptions: { ...lovs.subline },
        },
        plan: {
          name: 'plan',
          title: 'Plan',
          type: FormInputTypes.chips,
          value: filterValues?.plan || [],
          placeholder: 'Plan',
          triggerUpdate: true,
          selectOptions: { ...lovs.plan },
        },
        businessPartner: {
          name: 'businessPartner',
          title: 'Business Partner',
          type: FormInputTypes.chips,
          value: filterValues?.businessPartner || [],
          placeholder: 'Business Partner',
          triggerUpdate: true,
          selectOptions: { ...lovs.businessPartner },
        },
        insured: {
          name: 'insured',
          title: 'Insured',
          type: FormInputTypes.chips,
          value: filterValues?.insured || [],
          placeholder: 'Insured',
          triggerUpdate: true,
          selectOptions: { ...lovs.insured },
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        statusReason: {
          name: 'statusReason',
          title: 'Status Reason',
          type: FormInputTypes.chips,
          value: filterValues?.statusReason || [],
          placeholder: 'Status Reason',
          triggerUpdate: true,
          selectOptions: { ...lovs.statusReason },
        },
        issueDate: {
          name: 'issueDate',
          title: 'Issue Date',
          type: FormInputTypes.daterange,
          value: filterValues.issueDate || [],
          placeholder: 'Issue Date',
          triggerUpdate: true,
        },
        effectiveDate: {
          name: 'effectiveDate',
          title: 'Effective Date',
          type: FormInputTypes.daterange,
          value: filterValues.effectiveDate || [],
          placeholder: 'Effective Date',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
