import { gql } from '@apollo/client';

export function getListForm() {
  return gql(/* GraphQL */ `
    query getPolicyDetailsLookups {
      Insurance_PhoneType: __type(name: "Insurance_PhoneType") {
        name
        enumValues {
          name
        }
      }
      Insurance_RenewalStatus: __type(name: "Insurance_RenewalStatus") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          countries {
            Id
            Title
          }
          brands {
            Id
            Title
          }
          plateCodes {
            Id
            Title
          }
          engineTypes {
            Id
            Title
          }
        }
      }
    }
  `);
}

export function getModelsByBrands() {
  return gql(/* GraphQL */ `
    query getModelsByBrands($modelId: String!) {
      Insurance {
        lookups {
          models(Insurance_Brands: $modelId) {
            Id
            Title
          }
          countries {
            Id
            Title
          }
        }
      }
    }
  `);
}

export function updatePolicy() {
  return gql`
    mutation updatePolicyDetails(
      $entityId: String!
      $isRenewal: Insurance_RenewalStatus!
      $renewalNumber: String
      $nameOnLicenseEn: String!
      $nameOnLicenseAr: String!
      $firstName: String!
      $middleName: String!
      $lastName: String!
      $phoneType: Insurance_PhoneType!
      $mobileNumber: String!
      $country: String!
      $city: String
      $street: String
      $building: String
      $brand: String!
      $model: String!
      $motor: String!
      $chassis: String!
      $horsePower: Decimal!
      $plateCode: String
      $plateNumber: String
      $vignetteCode: String
      $vignette: Long
      $effectiveDate: Date!
      $yearOfMake: Long!
      $engineType: String!
      $seats: Long
    ) {
      insurance {
        entities {
          policy {
            insurance {
              updatePolicy(
                entityId: $entityId
                country: $country
                city: $city
                street: $street
                building: $building
                mobileNumber: $mobileNumber
                isRenewal: $isRenewal
                renewalNumber: $renewalNumber
                brand: $brand
                model: $model
                chassis: $chassis
                motor: $motor
                nameOnLicenseAr: $nameOnLicenseAr
                nameOnLicenseEn: $nameOnLicenseEn
                firstName: $firstName
                middleName: $middleName
                lastName: $lastName
                horsePower: $horsePower
                phoneType: $phoneType
                plateCode: $plateCode
                plateNumber: $plateNumber
                vignetteCode: $vignetteCode
                vignette: $vignette
                effectiveDate: $effectiveDate
                yearOfMake: $yearOfMake
                engineType: $engineType
                seats: $seats
              )
            }
          }
        }
      }
    }
  `;
}
