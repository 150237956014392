import React, { useCallback, useEffect, useState } from 'react';
import { IListingData } from '../../../models/listing';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import {
  getBusinessPartnersQuery,
  getCompaniesListQuery,
  getPolicyBusinessPartnerListQuery,
} from './queries';
import { mapToListingData, toLookupsData } from './utils';
import { filterSectionsContent, headers } from './content';
import ListingWidget from '../../../components/form-fields/listing-widget/ListingWidget';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../../models';
import { getFilter, setFilter } from '../../../utils/filter-utils';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT } from '../../../constants';
import {
  IEnhanceTableHeaderClickable,
  IEnhanceTableHeaderCurrency,
} from '../../../components/enhanced-table';
import { useNavigate } from 'react-router-dom';

const PAGE_CONTEXT = 'ReceivablesPayables';
const FILTER_SESSION_KEY = 'receivablesPayablesFilter';

const ReceivablesPayablesPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          company: '41',
          businessPartner: [],
        };
  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [getCompaniesListLazy] = useLazyQuery(getCompaniesListQuery());
  const [getBusinessPartnersLazy] = useLazyQuery(getBusinessPartnersQuery());
  const [getPolicyBusinessPartnerListLazy] = useLazyQuery(
    getPolicyBusinessPartnerListQuery()
  );

  const loadCompanies = async () => {
    const companiesListResult = await getCompaniesListLazy();
    return companiesListResult;
  };

  const loadBusinessPartners = async (companyId: string) => {
    const businessPartnersResult = await getBusinessPartnersLazy({
      variables: { companyId, pageNumber: 1, pageSize: 99999999 },
    });
    return businessPartnersResult;
  };

  const loadFilterLovs = useCallback(async () => {
    try {
      const companiesListResult = await loadCompanies();
      const businessPartnersResult = await loadBusinessPartners(
        filterValues.company || '41'
      );
      const lovs = toLookupsData(
        companiesListResult.data,
        businessPartnersResult.data
      );
      const savedFilters = filterValues || getFilter(FILTER_SESSION_KEY);
      setFilterValues(savedFilters);

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterValues?.company;
      }

      if (!Object.keys(lovs.businessPartner).length) {
        lovs.businessPartner = filterValues?.businessPartner;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);

      setFilter(savedFilters, FILTER_SESSION_KEY);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadFilterLovs();
    getListingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = useCallback(
    (page: number, filterModel = filterValues) => {
      return new Promise<void>(() =>
        getListingData({
          ...filterModel,
          pagination: {
            ...filterModel.pagination,
            pageNumber: page,
          },
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onFilterUpdate = async (filters: IAbstractRecord) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };
    setFilter(filters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    getListingData(newFilters);
  };

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      setLoading(false);
      return {};
    }

    const filters = filter?.namedFilters || filter;
    const pagination = filter.pagination;

    setLoading(true);
    return getPolicyBusinessPartnerListLazy({
      variables: {
        selectedBroker: filters.businessPartner || [],
        selectedCompany: filters.company || '41',
        KeywordSearch: filter.searchKey,
        OrderByField: filter.orderBy,
        DescendingField: filter.descending,
        pageNumber: 1,
        pageSize: 10000000,
      },
    })
      .then(({ data }) => {
        if (data) {
          const currencies = data.Core.queries.getSystemCurrencies?.[0];
          (
            headers.totalBalanceBasicCurrency as IEnhanceTableHeaderCurrency
          ).currency = currencies.core_SystemCurrency_BasicCurrency?.Symbol;
          (
            headers.totalBalanceSecondaryCurrency as IEnhanceTableHeaderCurrency
          ).currency = currencies.core_SystemCurrency_SecondaryCurrency?.Symbol;
          const newTableData = mapToListingData(data);
          setTableData({
            ...newTableData,
            pageNumber: newTableData.pageNumber,
            pageSize: newTableData.pageSize,
            totalCount: newTableData.totalCount,
          });
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  (
    headers.SalesforceManagement_BusinessPartner_FullName as IEnhanceTableHeaderClickable
  ).callback = (payload: IAbstractRecord) => {
    navigate(
      '/accounting/receivables-payables/' +
        payload.columns.SalesforceManagement_BusinessPartner_Id
    );
  };

  const renderMainChildren = () => (
    <ListingWidget
      name="receivables-payables"
      title="Receivables & Payables By Partner"
      orderByAscendingByDefault
      loading={loading}
      data={tableData}
      tableSettings={{ headers }}
      actions={[]}
      pageContext={PAGE_CONTEXT}
      // onPageChange={(filterModel) => {
      //   const newFilterModel = {
      //     ...filterValues,
      //     ...filterModel,
      //   };
      //   const page = filterModel.pagination.pageNumber;
      //   return handlePageChange(page, newFilterModel);
      // }}
      disableSelection
    />
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  return (
    <StaticLayout
      name="ReceivablesPayables"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ReceivablesPayablesPage;
