import { lookupListAsRecordObject } from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const accountEnums: Record<string, Record<string, string>> = {};
  accountEnums['Type'] = lookupListAsRecordObject(
    data.Accounting.lookups.accountType
  );
  accountEnums['Currency'] = lookupListAsRecordObject(
    data.Core.lookups.currencies
  );
  accountEnums['relatedCompanies'] = lookupListAsRecordObject(
    data.SalesforceManagement.lookups.relatedCompanies
  );
  accountEnums['countries'] = lookupListAsRecordObject(
    data.Insurance.lookups.countries
  );
  const accounts: Record<string, string> = {};
  data?.Accounting?.queries?.GetAccountsOfCompany?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_ChartOfAccounts_Id) {
        accounts[element.accounting_ChartOfAccounts_Id] =
          element.accounting_ChartOfAccounts_AccountName;
      }
      accountEnums['accounts'] = accounts;
    }
  );

  return accountEnums;
}

export function EntityToList(data: any) {
  const lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.GetAccountsThatAreNotSubType?.forEach(
    (element: { [key: string]: string }) => {
      if (
        element.accounting_ChartOfAccounts_Id &&
        element.accounting_ChartOfAccounts_AccountName
      ) {
        lookuplistAsRecordObject[element.accounting_ChartOfAccounts_Id] =
          element.accounting_ChartOfAccounts_AccountName;
      }
    }
  );

  return lookuplistAsRecordObject;
}

export function EntityCustomerToList(data: any) {
  const lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.EnteredCustomerNameExists?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_Customers_Id) {
        lookuplistAsRecordObject[element.accounting_Customers_Id] =
          element.accounting_Customers_Id;
      }
    }
  );

  return lookuplistAsRecordObject;
}

export function UpdateEntityAccountToList(data: any) {
  const lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.UpdatedAccountNameOrIDExists?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_ChartOfAccounts_Id) {
        lookuplistAsRecordObject[element.accounting_ChartOfAccounts_Id] =
          element.accounting_ChartOfAccounts_Id;
      }
    }
  );

  return lookuplistAsRecordObject;
}

export function graphqlEntityToAccountInfo(data: any): IAccountInfo {
  return {
    accountId: data?.Accounting?.entities?.chartOfAccounts?.id,
    id: data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
      ?.properties?.AccountID,
    company:
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.AccountCompany?.Id,
    name: data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
      ?.properties?.AccountName,
    currency:
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.AccountCurrency?.Id,
    parentAccountLayer2:
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer2?.id,
    parentAccountLayer3:
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer3?.id,
    parentAccountLayer4:
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer4?.id,
    subCategoryCode:
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer4?.views?.Accounting_all?.properties
        ?.AccountGroup,
    parentCategory:
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer2?.views?.Accounting_all?.properties
        ?.AccountGroup +
      ' ' +
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer2?.views?.Accounting_all?.properties
        ?.AccountGroupName,
    category:
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer3?.views?.Accounting_all?.properties
        ?.AccountGroup +
      ' ' +
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer3?.views?.Accounting_all?.properties
        ?.AccountGroupName,
    subCategory:
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer4?.views?.Accounting_all?.properties
        ?.AccountGroup +
      ' ' +
      data?.Accounting?.entities?.chartOfAccounts?.views?.Accounting_all
        ?.properties?.ParentAccountLayer4?.views?.Accounting_all?.properties
        ?.AccountGroupName,
  };
}
