import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query TransfersList(
      $TransferFromDate: Date
      $TransferToDate: Date
      $DescendingField: Boolean
      $KeywordSearch: String
      $OrderByField: String
      $TransferType: [String]
      $SelectedCompany: String!
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      Accounting {
        queries {
          TransfersList(
            TransferFromDate: $TransferFromDate
            TransferToDate: $TransferToDate
            DescendingField: $DescendingField
            KeywordSearch: $KeywordSearch
            OrderByField: $OrderByField
            TransferType: $TransferType
            SelectedCompany: $SelectedCompany
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            items {
              accounting_AccountingTransfers_Id
              accounting_AccountingTransfers_RelatedCompany {
                Id
                Code
                Title
              }
              accounting_AccountingTransfers_TransferType {
                Id
                Title
                Code
              }
              accounting_AccountingTransfers_TransferNumber
              accounting_AccountingTransfers_TransferMonth
              accounting_AccountingTransfers_TransferYear
              accounting_AccountingTransfers_TransferPeriodFrom
              accounting_AccountingTransfers_TransferPeriodTo
              accounting_AccountingTransfers_createdOn
              accounting_AccountingTransfers_modifiedOn
            }
            paging {
              pageSize
              pageNumber
              totalCount
            }
          }
        }
        lookups {
          transferType {
            Id
            Title
            Code
          }
        }
      }
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
    }
  `;
}
