import React, { useEffect, useState } from 'react';
import EnhancedButton, {
  EnhancedButtonStatus,
} from '../components/EnhancedButton';
import DialogConfirmationTemplate from '../components/common/DialogConfirmationTemplate';
import { IConfirmation } from '../redux/confirmation/types';
import EnhancedDialog from '../components/common/EnhancedDialog';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch } from '../redux/hooks';
import { CloseConfirmationAction } from '../redux/confirmation/actions';
import { MAIN_ONE_THEME } from '../constants';

interface IStoreConfirmationProps {
  confirmation: IConfirmation;
}

const useStyles = makeStyles()(() => ({
  modal: {
    width: 350,
  },
  content: {
    overflowY: 'visible',
    overflow: 'Visible',
  },
  footer: {
    width: '100%',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'end',
  },
  submitButton: {
    minWidth: '0px',
    marginLeft: '10px!important',
  },
  cancelButton: {
    minWidth: '0px',
  },
}));

const StoreConfirmation: React.FC<IStoreConfirmationProps> = ({
  confirmation,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitState, setSubmitState] = useState<
    EnhancedButtonStatus | undefined
  >(undefined);

  const submit = () => {
    if (confirmation.callback) {
      setSubmitState('loading');
      setIsSubmitting(true);
      confirmation.callback().finally(() => {
        setSubmitState('success');
        setTimeout(() => {
          setIsSubmitting(false);
          setSubmitState(undefined);
          dispatch(CloseConfirmationAction());
        }, 250);
      });
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <EnhancedDialog
      open={!!confirmation.open}
      onClose={() => dispatch(CloseConfirmationAction())}
      maxWidth="sm"
    >
      <DialogConfirmationTemplate
        title={confirmation.title}
        className={classes.modal}
        contentClass={classes.content}
        footer={
          <section className={classes.footer}>
            <EnhancedButton
              onClick={() => dispatch(CloseConfirmationAction())}
              type="button"
              name={'Cancel'}
              disabled={isSubmitting}
              classes={{
                root: classes.cancelButton,
              }}
            >
              {confirmation.cancelButtonText || 'Cancel'}
            </EnhancedButton>
            <EnhancedButton
              type="submit"
              disabled={isSubmitting}
              classes={{
                root: classes.submitButton,
              }}
              backgroundColor={MAIN_ONE_THEME.palette.primary5.main}
              color="rgba(255, 255, 255, 1)"
              name={'Submit'}
              onClick={submit}
              state={submitState}
            >
              {confirmation.submitButtonText || 'Submit'}
            </EnhancedButton>
          </section>
        }
      >
        {confirmation.message}
      </DialogConfirmationTemplate>
    </EnhancedDialog>
  );
};

export default StoreConfirmation;
