import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getInputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createAssignedBPPlan,
  getBPPlanListByAssignedLines,
  getBusinessPartnerListWithoutPagination,
} from './queries';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import { getError } from '../../utils/graph-utils';
import { extractBusinessPartners, extractLines, extractPlans } from './utils';
import { getBusinessPartnerLinesListWithoutPagination } from '../salesforce-business-partner-details-page/queries';
import Loader from '../../components/Loader';
import { ISalesforceAssignedBPPlanDrawerProps } from '.';

const SalesforceAssignedBPPlanDrawer: React.FC<
  ISalesforceAssignedBPPlanDrawerProps
> = ({ open, onSuccess, onClose, businessUserId }) => {
  const [assignedBPPlanAction] = useMutation(createAssignedBPPlan());
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [values, setValues] = useState<Record<string, any>>({});

  const [loading, setLoading] = useState<boolean>(true);

  const [getBusinessPartnersQuery] = useLazyQuery(
    getBusinessPartnerListWithoutPagination()
  );
  const [getAssignedLineListLazy] = useLazyQuery(
    getBusinessPartnerLinesListWithoutPagination()
  );
  const [getBPPlanListByAssignedLinesLazy] = useLazyQuery(
    getBPPlanListByAssignedLines()
  );

  const [lists, setLists] = useState<Record<string, Record<string, string>>>({
    primaryBP: {},
    line: {},
    plan: {},
  });

  const initialize = async () => {
    setLoading(true);

    const businessPartnersLov = await getBusinessPartners();
    const newList: Record<string, Record<string, string>> = {
      primaryBP: businessPartnersLov,
      line: {},
      plan: {},
    };

    setLists(newList);
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  const getBusinessPartners = async () => {
    const result = await getBusinessPartnersQuery({
      variables: {
        businessPartnerStatus: ['ACTIVE'],
      },
    });

    const extractedFetchedBusinessPartners = extractBusinessPartners(
      result.data
    );
    return extractedFetchedBusinessPartners || {};
  };

  const handleBusinessPartnerSelection = async (selectedOption: string) => {
    if (!isEmpty(selectedOption)) {
      const newData = await getAssignedLineListLazy({
        variables: {
          selectedBusinessPartnerID: selectedOption,
        },
        errorPolicy: 'all',
      });
      return extractLines(newData.data);
    }
    return {} as Record<string, string>;
  };

  const handleLineSelection = async (
    selectedOption: string,
    businessPartner: string
  ) => {
    if (!isEmpty(selectedOption)) {
      const newData = await getBPPlanListByAssignedLinesLazy({
        variables: {
          selectedBusienssUserID: businessUserId,
          selectedBusinessPartnerID: businessPartner,
          selectedLineID: selectedOption,
        },
        errorPolicy: 'all',
      });
      return extractPlans(newData.data);
    }
    return {} as Record<string, string>;
  };

  const onCustomChange = async (
    fieldName: string,
    value: any,
    allValues: Record<string, any>
  ) => {
    const newLists = { ...lists };

    if (fieldName === 'businessPartner') {
      newLists['line'] = await handleBusinessPartnerSelection(value);
      allValues['line'] = '';

      allValues['plan'] = '';
      newLists['plan'] = {};
    }

    if (fieldName === 'line') {
      newLists['plan'] = await handleLineSelection(
        value,
        allValues.businessPartner
      );
      allValues['plan'] = '';
    }

    setValues(allValues);
    setLists(newLists);
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      getInputs(values as any, lists),
      values
    );
    setFormDisabled(true);
    setSubmitButtonState('loading');
    try {
      const variables = {
        selectedBusinessPartnerID: data.businessPartner || null,
        selectedBusinessPartnerLineID: data.line || null,
        selectedBusinessPartnerPlanIDs: data.plan || [],
        selectedBusinessUserID: businessUserId,
      };

      const res = await assignedBPPlanAction({
        variables: variables,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Business Partner and Plan(s) successfully assigned.'}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setFormDisabled(false);
          setSubmitButtonState('success');
          onSuccess();
          onClose();
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      //
    }
  };

  const inputForm = useMemo(() => {
    const result = getInputs((values as any) || undefined, lists);
    return result;
  }, [values, lists]);

  return (
    <GenericDrawer
      title={'Assign Business Partner and Plan'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            onChange={onCustomChange}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesforceAssignedBPPlanDrawer;
