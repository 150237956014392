import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import { IInformationBoxData } from '.';
import InformationBoxHeader from './InformationBoxHeader';
import InformationBoxBody from './InformationBoxBody';

import { IEnhancedCommonProps } from '..';
import InformationBoxWidgetSection from './InformationBoxWidgetSection';
import { isEmpty } from '../../utils/validationUtils';
import WidgetPaper from '../common/WidgetPaper';
import Tooltip from '@mui/material/Tooltip';
import { Tab, Tabs } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ITheme } from '../../redux/tenant/types';
import { useAppSelector } from '../../redux/hooks';

interface IInformationBoxProps
  extends IInformationBoxData,
    IEnhancedCommonProps {
  header?: JSX.Element;
  footer?: JSX.Element;
  visibleRows: number;
  isSelectable?: boolean;
  selected?: boolean;
  showFullTitle?: boolean;
  onSelect?: () => void;
  scrollWhenColumnsMore?: number;
}

const useStyles = makeStyles<{
  theme: ITheme;
  rowCount: number;
  tabCount: number;
  selected: boolean;
}>()((_, { theme, rowCount, tabCount, selected }) => ({
  container: {
    display: 'block',
    width: '100%',
    overflow: 'auto',
  },
  widgetContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    padding: '23px 0 0 0',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'visible',
  },
  selectHeader: {
    paddingLeft: '25px',
  },
  sectionHeader: {
    alignItems: 'flex-start',
  },
  icon: {
    width: '14px',
    height: '14px',
    backgroundSize: 'contain',
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginRight: '7px',
    marginBottom: '15px',
  },
  title: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary1.main,
    fontFamily: 'HelveticaNeue-Medium',
    width: '100%',
    display: 'inline-block',
    textAlign: 'left',
  },
  row: {
    padding: '12px 0',
    borderBottom: '1px solid #DFE3EBC9',
    textAlign: 'left',
    display: 'grid',
    gridTemplateColumns: `40% repeat(${rowCount}, 1fr)`,
    gridGap: '5px',
    gridAutoRows: 'auto',
  },
  headerTitle: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    minWidth: '100px',
    minHeight: '22px',
  },
  headerTitleFullWidth: {
    width: '100%',
    minWidth: '100px',
    minHeight: '22px',
  },
  informationTitle: {
    color: theme.palette.primary1.main,
    display: 'inline-block',
    textAlign: 'left',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  informationText: {
    color: theme.palette.primary1.main,
    display: 'inline-block',
    textAlign: 'left',
    fontSize: '11px',
  },
  informationContainer: {
    display: 'block',
    margin: '20px auto',
    overflowX: 'auto',
  },
  informationRow: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: theme.palette.secondary3.main,
    padding: '12px 0',
  },
  noDataMessage: {
    textAlign: 'center',
    color: theme.palette.secondary2.main,
  },
  seeMore: {
    display: 'block',
    margin: '10px auto',
    textAlign: 'center',
    textDecoration: 'underline',
    color: theme.palette.primary1.main,
  },
  tabsRoot: {
    margin: '0 auto 20px',
  },
  indicator: {
    backgroundColor: theme.palette.primary2.main,
  },
  tabsFlexContainer: {
    width: '100%',
    minWidth: 'fit-content',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: theme.palette.primary2.main,
    },
  },
  subTitle: {
    color: '#9BA2B0',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'left',
  },
  scrollButtons: {
    width: 'fit-content',
  },
  tabRoot: {
    textAlign: 'center',
    font: 'normal 13px/16px HelveticaNeue-Medium',
    color: theme.palette.primary2.main,
    opacity: 1,
    textTransform: 'unset',
    width: `calc((100% / ${tabCount}) - 5px)`,
    minWidth: 'fit-content',
  },
  statusSection: {
    margin: '0',
    maxWidth: '40%',
  },
  statusContainer: {
    margin: '0 0 0.5em',
    display: 'block',
    minWidth: 'fit-content',
    minHeight: '21px',
    textAlign: 'right',
  },
  status: {
    fontSize: '16px',
    lineHeight: '20px',
    display: 'inline-block',
    verticalAlign: 'middle',
    // textAlign: 'right',
    color: theme.palette.primary1.main,
    maxWidth: '100px',
  },
  statusReason: {
    fontSize: '13px',
    float: 'right',
    lineHeight: '19px',
    color: theme.palette.primary1.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    maxWidth: '100%',
  },
  statusReasonContainer: {
    margin: '0.5em 0 0',
    textAlign: 'right',
  },
  statusIcon: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '15px',
    width: '15px',
    border: 'none',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary1.main,
    margin: '0 5px 0 0',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  checkbox: {
    marginLeft: '2px',
    position: 'absolute',
    width: '18px',
    height: '18px',
    top: '13px',
    left: '3%',
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary4.main,
    borderRadius: '3px',
    border: `1px solid ${theme.palette.secondary3.main}`,
    boxShadow: `inset 0 0 2px ${theme.palette.secondary2.main}`,
    '&:hover': {
      backgroundColor: theme.palette.secondary2.main,
    },
  },
  checkboxCheckIcon: {
    marginLeft: '4px',
    width: '6px',
    height: '12px',
    border: `solid ${theme.palette.primary2.main}`,
    borderWidth: '0 4px 4px 0',
    transform: 'rotate(45deg)',
    visibility: selected ? 'visible' : 'hidden',
  },
}));

const InformationBox: React.FC<IInformationBoxProps> = ({
  className,
  style,
  header,
  footer,
  status,
  statusReason,
  statusColor,
  statusIcon,
  tabs,
  title,
  subtitle,
  isSelectable = false,
  selected = false,
  onSelect,
  collapsible,
  initiallyCollapsed,
  visibleRows,
  description,
  showFullTitle = false,
  scrollWhenColumnsMore = 3,
  hint,
  premiumColor,
  hidePremiumBreakdown,
  hintIconNumber,
}) => {
  const tenant = useAppSelector((state) => state.tenant);
  const [collapsed, setCollapsed] = useState<boolean>(initiallyCollapsed);
  const [colCount, setColCount] = useState<number>(1);
  const [infoTabs, setInfoTabs] = useState<string[]>([]);
  const [currentTab, setCurrentTab] = useState<string>('');

  const { cdnUrl, dateFormat } = tenant;
  const currency = tenant.currencySymbol;

  const classes = useStyles({
    rowCount: colCount || 1,
    tabCount: infoTabs.length || 1,
    selected,
    theme: tenant.theme,
  }).classes;

  const tabMinWidth: Record<string, string> = {};

  function handleChange(event: React.ChangeEvent, tabname: string) {
    const newTab = tabs && tabs.find((tab) => tab.name === tabname);
    if (newTab) {
      setCurrentTab(newTab.name);
    }
  }

  useEffect(() => {
    const newTabs = tabs ? tabs.map((tab) => tab.name) : [];
    setInfoTabs(newTabs);
    setColCount(Object.keys(newTabs).length || 1);
  }, [tabs]);

  useEffect(() => {
    if (infoTabs.length > 0) {
      if (!currentTab || !infoTabs.includes(currentTab)) {
        setCurrentTab(infoTabs[0]);
      }
    } else if (!isEmpty(currentTab)) {
      setCurrentTab('');
    }
  }, [infoTabs]);

  const renderStatus = () => {
    return (
      <div>
        {status && (
          <div
            className={
              statusReason && statusReason.trim()
                ? classes.statusContainer
                : undefined
            }
          >
            <figure
              className={classes.statusIcon}
              style={{
                backgroundImage: `url(${statusIcon})`,
                backgroundColor: statusIcon ? 'transparent' : statusColor,
              }}
            />
            <span className={classes.status} style={{ color: statusColor }}>
              {status}
            </span>
          </div>
        )}
        {statusReason && statusReason.trim() && (
          <div className={classes.statusReasonContainer}>
            <Tooltip title={statusReason}>
              <span
                className={classes.statusReason}
                style={{
                  color: statusColor,
                }}
              >
                {statusReason}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const fillStyledTabs = () => {
    if (tabs && tabs.length > 0) {
      tabs.forEach((tabInfo) => {
        if (tabInfo.data && tabInfo.data !== undefined) {
          //in case the table inside the body has three or more columns
          //approach done in order to give the more width to specefic tabs
          if (Object.keys(tabInfo?.data).length > scrollWhenColumnsMore) {
            tabMinWidth[tabInfo.name] =
              (150 * Object.keys(tabInfo.data).length).toString() + 'px';
          } else {
            tabMinWidth[tabInfo.name] = 'auto';
          }
        } else {
          tabMinWidth[tabInfo.name] = 'auto';
        }
      });
    }
  };

  fillStyledTabs();

  return (
    <WidgetPaper className={className} style={style}>
      {isSelectable && (
        <div
          className={classes.checkbox}
          onClick={onSelect}
          onKeyDown={onSelect}
          tabIndex={0}
          role="checkbox"
          aria-checked={selected}
        >
          <div className={classes.checkboxCheckIcon} />
        </div>
      )}
      <InformationBoxWidgetSection
        className={classes.widgetContainer}
        classes={{
          header: isSelectable ? classes.selectHeader : '',
          headerContainer: classes.sectionHeader,
          container: classes.body,
          middle: classes.statusSection,
          title: showFullTitle
            ? classes.headerTitleFullWidth
            : classes.headerTitle,
        }}
        middle={renderStatus()}
        title={title}
        hint={hint}
        hintIconNumber={hintIconNumber}
        hidePremiumBreakdown={hidePremiumBreakdown}
        premiumColor={premiumColor}
        showFullTitle={showFullTitle}
        subtitle={
          subtitle ? (
            <span
              className={clsx({
                [classes.subTitle]: typeof subtitle === 'string',
              })}
            >
              {subtitle}
            </span>
          ) : (
            <></>
          )
        }
        bottom={<>{description}</>}
      >
        <div className={classes.container}>
          {infoTabs && infoTabs.length > 1 && (
            <Tabs
              classes={{
                root: classes.tabsRoot,
                indicator: classes.indicator,
                flexContainer: classes.tabsFlexContainer,
                scrollButtons: classes.scrollButtons,
              }}
              value={currentTab || infoTabs[0]}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
            >
              {infoTabs.map((tab) => (
                <Tab
                  key={tab}
                  classes={{ root: classes.tabRoot }}
                  label={tab}
                  value={tab}
                />
              ))}
            </Tabs>
          )}
          {header}
          <div
            className={classes.informationContainer}
            style={{ minWidth: tabMinWidth[currentTab] }}
          >
            {!isEmpty(currentTab) &&
              infoTabs.includes(currentTab) &&
              tabs.length > 0 && (
                <>
                  {(visibleRows > 0 || !collapsed) && (
                    <InformationBoxHeader
                      tab={tabs[infoTabs.indexOf(currentTab)]}
                    />
                  )}
                  <InformationBoxBody
                    collapsible={collapsible}
                    collapsed={collapsed}
                    visibleRows={visibleRows}
                    tab={tabs[infoTabs.indexOf(currentTab)]}
                    currency={currency}
                    onCollapseClick={(collapse: boolean) => {
                      setCollapsed(collapse);
                    }}
                    cdnUrl={cdnUrl}
                    dateFormat={dateFormat}
                  />
                </>
              )}
          </div>
        </div>
        {footer}
      </InformationBoxWidgetSection>
    </WidgetPaper>
  );
};

export default InformationBox;
