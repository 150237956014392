import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IPolicyCoverDetailsPageProps } from '.';
import Loader from '../../components/Loader';
import { MAIN_ONE_THEME, contentFontFamilyBold } from '../../constants';
import { claimCoverHeaders, steps } from './content';
import {
  getPolicyCoverClaimCoverList,
  getPolicyCoverDetailsInfo,
} from './queries';
import {
  entityToPolicyCoverDetails,
  mapToClaimCoversListingData,
} from './utils';
import { useAppSelector } from '../../redux/hooks';
import EnhancedStepperSpecificDesign from '../../components/common/EnhancedStepperSpecificDesign';
import { makeStyles } from 'tss-react/mui';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import PolicyCoverDrawer from '../policy-cover-drawer/PolicyCoverDrawer';
import { IListingData } from '../../models/listing';
import { IEnhancedTableMenuItem } from '../../components/enhanced-table';
import WidgetSection from '../../components/common/WidgetSection';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import ClaimCoverDrawer from '../claim-cover-drawer/ClaimCoverDrawer';
import { capitalizeFirstCharacter } from '../../utils/formatting-utils';
import { ITableOrder, TableSortOrder } from '../../utils/table-utils';
import _ from 'lodash';
import PolicyCoverEntityInfoWidget from './widgets/PolicyCoverEntityInfoWidget';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '90%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '.Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  section: {
    display: 'grid',
    'grid-template-columns': '19.5% 19.5% 19.5% 19.5% 19.5%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& label': {
      '& span': {
        margin: '-2px 0',
      },
    },
  },
  sectionTitle: {
    padding: '-10x 10x!important',
  },
  sectionFullRow: {
    display: 'grid',
    'grid-template-columns': '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  row: {
    flexWrap: 'wrap',
    margin: '15px 20px 10px 0px',
    alignItems: 'flex-start',
    display: 'grid',
    gap: '5px',
    '& label': {
      flexGrow: '1',
      flexBasis: '0',
      minWidth: '0',
    },
    'grid-template-columns': '19.5% 19.5% 19.5% 19% 16% 3% 3%',
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    'grid-column-start': '1',
    'grid-column-end': '4',
  },
  field: {
    width: '96%',
    marginRight: '10px !important',
  },
  repeaterField: {
    margin: '0px!important',
  },
  arField: {
    width: '96%',
    marginRight: '10px !important',
    direction: 'rtl',
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    '&:hover': {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: '0',
  },
  dirtyChip: {
    margin: '0 0 -15px 0',
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
  tabPanelStyle: {
    padding: 'unset',
    marginTop: '20px',
  },
}));

const PolicyCoverDetailsPage: React.FC<IPolicyCoverDetailsPageProps> = () => {
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);

  const [booted, setBooted] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState('');
  let setSearchTimeout: NodeJS.Timeout;
  const [tableOrders, setTableOrders] = useState<Record<string, ITableOrder>>({
    cover: {
      orderBy: '',
      orderDirection: 'asc',
    },
  });

  const [policyCoverDrawerOpen, setPolicyCoverDrawerOpen] =
    useState<boolean>(false);

  const [selectedClaimCoverItems, setSelectedClaimCoverItems] =
    useState<number>(0);

  const [policyCoverDetailsInfo, setPolicyCoverDetailsInfo] =
    useState<Record<string, any>>();

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const entityId = params.id;

  const [claimCoverTableData, setClaimCoverTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [entityResultQuery] = useLazyQuery(getPolicyCoverDetailsInfo());

  const [coverListResultQuery] = useLazyQuery(getPolicyCoverClaimCoverList());

  const loadCoverList = async (
    currentPage = 0,
    pageSize = claimCoverTableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword = keywordSearch
  ) => {
    const result = await coverListResultQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        SelectedLineId: policyCoverDetailsInfo?.lineId,
        selectedPolicyCoverID: params.id,
        Attribute: capitalizeFirstCharacter(
          orders?.cover?.orderBy || 'PlanConfigManagement_ClaimCover_Name'
        ),
        Descending: orders?.cover?.orderDirection == 'asc' ? false : true,
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: 'all',
    });

    return result;
  };

  const loadEntityData = async () => {
    const result = await entityResultQuery({
      variables: { id: entityId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return result;
  };

  const initialize = async () => {
    const [entityData, coverData] = await Promise.all([
      loadEntityData(),
      loadCoverList(0, 10),
    ]);

    const coverDetails = await entityToPolicyCoverDetails(entityData.data);
    const mappedPlanList = await mapToClaimCoversListingData(coverData.data);

    setPolicyCoverDetailsInfo(coverDetails);
    setClaimCoverTableData({ ...mappedPlanList, pageNumber: 0 });

    setBooted(true);
    setLoadingState(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  const [selectedClaimCoverEntityId, setSelectedClaimCoverEntityId] =
    useState('');
  const [claimCoverDrawerOpen, setClaimCoverDrawerOpen] =
    useState<boolean>(false);
  const [actionClicked, setActionClicked] = useState(null);

  const isAdmin = user.userRoles.includes('Insurance-Admin');
  let claimCoverListingActions: IEnhancedTableMenuItem[] = [];
  claimCoverListingActions = [
    {
      title: 'New',
      onClick: () => undefined,
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + '/icons/add-primary-red.svg',
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: 'Edit',
      onClick: () => undefined,
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + '/icons/edit-secondary.svg',
      hidden: !isAdmin,
      disabled: selectedClaimCoverItems !== 1,
    },
  ];

  claimCoverListingActions[0].onClick = () => {
    setActionClicked('new');
    setClaimCoverDrawerOpen(true);
  };
  claimCoverListingActions[1].onClick = () => {
    setActionClicked('edit');
    setClaimCoverDrawerOpen(true);
  };

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handleClaimCoverPageChange(
        0,
        claimCoverTableData.pageSize,
        tableOrders,
        val
      );
    }, 500);
  };

  function handleSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.cover.orderBy = orderBy;
    newOrder.cover.orderDirection = order as any;
    handleClaimCoverPageChange(
      claimCoverTableData.pageNumber,
      claimCoverTableData.pageSize,
      newOrder
    );

    setTableOrders(newOrder);
  }
  async function handleClaimCoverPageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = keywordSearch
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadCoverList(page, pageSize, orders, searchKeyword);
      const mappedSubList = await mapToClaimCoversListingData(result.data);
      setClaimCoverTableData({
        ...mappedSubList,
        pageNumber: page,
        pageSize: pageSize,
      });

      setLoadingState(false);
    }
  }

  const renderMainChildren = () => {
    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <EnhancedStepperSpecificDesign
            activeStep={''}
            steps={steps}
            className={classes.stepper}
            showStepperButton={false}
            style={{ visibility: 'hidden' }}
          />
          {policyCoverDrawerOpen && (
            <PolicyCoverDrawer
              open={policyCoverDrawerOpen}
              onClose={() => setPolicyCoverDrawerOpen(false)}
              onSuccess={() => {
                initialize();
              }}
              isLineNameDisabled={true}
              lineId={policyCoverDetailsInfo?.lineId}
              canNavigateToDetailsPage={false}
              policyCoverId={entityId}
            />
          )}
          <WidgetSection style={{ margin: '-23px 0 0 0' }}>
            <form>
              {
                <div
                  style={{
                    marginTop: '20px',
                    padding: '-23px 0 0 0 !important',
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter('Claim Covers')}
                    inlineTitle={capitalizeFirstLetter('Claim Covers')}
                    name="count"
                    entityName={'ClaimCover'}
                    entityIdColumnName={'planConfigManagement_ClaimCover_Id'}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={claimCoverTableData}
                    headers={claimCoverHeaders}
                    handlePageChange={(page: number) =>
                      handleClaimCoverPageChange(
                        page,
                        claimCoverTableData.pageSize
                      )
                    }
                    handleRowsPerPageChange={(pageSize: number) =>
                      handleClaimCoverPageChange(0, pageSize)
                    }
                    handleSort={(orderBy: string, order: string) =>
                      handleSortChange(orderBy, order)
                    }
                    handleSearchChange={delaySearch}
                    currentPage={claimCoverTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={claimCoverListingActions}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedClaimCoverItems(newDetailedSelection.length);
                      if (newDetailedSelection.length > 0) {
                        setSelectedClaimCoverEntityId(
                          newDetailedSelection[0].id
                        );
                      } else {
                        setSelectedClaimCoverEntityId('');
                      }
                    }}
                    ordering={
                      tableOrders.cover.orderDirection as TableSortOrder
                    }
                    orderingBy={tableOrders.cover.orderBy}
                  />
                  {claimCoverDrawerOpen && (
                    <ClaimCoverDrawer
                      open={claimCoverDrawerOpen}
                      lineId={policyCoverDetailsInfo?.lineId}
                      lineName={policyCoverDetailsInfo?.lineName}
                      claimCoverId={
                        actionClicked === 'new'
                          ? null
                          : selectedClaimCoverEntityId
                      }
                      onClose={() => setClaimCoverDrawerOpen(false)}
                      policyCoverId={entityId}
                      isPolicyCoverNameDisabled={true}
                      onSuccess={() => {
                        handleClaimCoverPageChange(0, 10);
                      }}
                    />
                  )}
                </div>
              }
            </form>
          </WidgetSection>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <PolicyCoverEntityInfoWidget
        data={policyCoverDetailsInfo}
        tenant={tenant}
        handleIconClick={() => {
          setPolicyCoverDrawerOpen(true);
        }}
      />
    );
  };

  //#endregion

  return !booted ? (
    <Loader />
  ) : (
    <StaticLayout
      name={'Policy Cover Details'}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PolicyCoverDetailsPage;
