import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  sublineId: '',
  lineId: '',
  lineName: '',
  sublineName: '',
  sublineExternalCode: '',
  arabicName: '',
  sublineCategory: '',
  sublineStatus: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  lineName: {
    name: 'lineName',
    title: 'Line',
    type: FormInputTypes.chips,
    placeholder: 'Line',
    value: !isEmpty(initialValues.lineName) ? initialValues.lineName : '',
    disabled: false,
    multiple: false,
    required: true,
  },
  sublineName: {
    name: 'sublineName',
    title: 'Subline Name',
    type: FormInputTypes.text,
    placeholder: 'Subline Name',
    value: !isEmpty(initialValues.sublineName) ? initialValues.sublineName : '',
    required: true,
  },
  sublineExternalCode: {
    name: 'sublineExternalCode',
    title: 'External Code',
    type: FormInputTypes.text,
    placeholder: 'External Code',
    value: !isEmpty(initialValues.sublineExternalCode)
      ? initialValues.sublineExternalCode
      : '',
    required: true,
  },
  arabicName: {
    name: 'arabicName',
    title: 'Arabic Name',
    type: FormInputTypes.text,
    placeholder: 'Arabic Name',
    value: !isEmpty(initialValues.arabicName) ? initialValues.arabicName : '',
    required: true,
  },
  sublineCategory: {
    name: 'sublineCategory',
    title: 'Category',
    placeholder: 'Category',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.sublineCategory)
      ? initialValues.sublineCategory
      : '',
    required: true,
    multiple: false,
    hidden: false,
  },
  sublineStatus: {
    name: 'sublineStatus',
    title: 'Status',
    placeholder: 'Status',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.sublineStatus)
      ? initialValues.sublineStatus
      : 'INACTIVE',
    required: false,
    multiple: false,
    hidden: true,
  },
};
