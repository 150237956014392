import { IAddDocumentDrawerProps } from '.';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import React, { useState } from 'react';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { DynamicFormInputType } from '../../DynamicForm';
import { inputs } from './content';
import { EnhancedButtonStatus } from '../../components/EnhancedButton';
import { resolveGraphqlBaseUrl } from '../../utils/tenant-utils';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getError } from '../../utils/graph-utils';
import { getUserToken } from '../../utils/userUtils';
import { DEFAULT_ERROR_TEXT } from '../../constants';

const AddDocumentDrawer: React.FC<IAddDocumentDrawerProps> = ({
  id,
  open,
  onSuccess,
  onClose,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm] = useState<Record<string, DynamicFormInputType>>(inputs);

  const allowedExtensions = ['pdf', 'docx', 'jpeg', 'jpg', 'png'];

  const simulateFileUpload = async (
    documentName: string,
    file: File
  ): Promise<void> => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    setSubmitButtonState('loading');
    setFormDisabled(true);

    try {
      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        toast.error(
          <ToastErrorMessage>
            {
              'Invalid file format. Please upload a PDF, DOCX, JPEG, or PNG file.'
            }
          </ToastErrorMessage>
        );
        throw new Error(
          'Invalid file format. Please upload a PDF, DOCX, JPEG, or PNG file.'
        );
      }

      const formData = new FormData();
      formData.append('formFile', file, file.name);
      formData.append(
        'operations',
        JSON.stringify({
          query: `mutation($uploadedDocument: Upload!) {
            insurance {
              entities {
                policy {
                  insurance {
                    uploadAdditionalDocument(entityId: "${id}"
                    documentName: "${documentName}"
                    uploadedDocument: $uploadedDocument) {
                      id
                    }
                  }
                }
              }
            }
          }`,
          variables: {
            uploadedDocument: null as string,
          },
        })
      );
      formData.append(
        'map',
        JSON.stringify({ formFile: ['variables.uploadedDocument'] })
      );

      const options = {
        method: 'POST',
        headers: {
          authorization: `Bearer ${getUserToken()}` || null,
          'GraphQL-preflight': '1',
        },
        body: formData,
      };

      const response = await fetch(
        `${resolveGraphqlBaseUrl()}/graphql`,
        options
      );

      if (response.ok) {
        toast.success(
          <ToastSuccessMessage>
            {'Document successfully uploaded'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{getError(response)}</ToastErrorMessage>
        );
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    if (values && values.documentName && values.document) {
      await simulateFileUpload(values.documentName, values.document);
    }
  };

  return (
    <GenericDrawer
      title={'Add Document'}
      onClose={() => onClose()}
      isOpen={open}
    >
      <>
        <DynamicForm
          inputs={inputsForm}
          onSubmit={(values) => submitForm(values)}
          buttonText={'Submit'}
          submitButtonState={submitButtonState}
          disableForm={formDisabled}
          title="Documents"
        />
      </>
    </GenericDrawer>
  );
};

export default AddDocumentDrawer;
