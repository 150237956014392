import { ITenant } from '../redux/tenant/types';
import {
  addZeroesAndSeparatevalue,
  valueCommaSeparated,
} from './formatting-utils';
import { get } from 'lodash';
import { getTimeDifference } from './date-utils';
import FormattingServices from '../services/formating-services';
import { IActivityEntityBase } from '../activities';

interface IState {
  pointer: number;
  calls: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  functions: Record<string, Function>;
}

function resolveValueOnFE(
  path: string,
  tenant: ITenant,
  activity: IActivityEntityBase
) {
  const exposedValues = {
    cdnUrl: tenant.cdnUrl,
    currencySymbol: tenant.currencySymbol,
    createdAt: activity.createdAt,
    ...tenant.theme,
  };
  return get(exposedValues, path, '');
}

function formatDateTime(value: string) {
  return FormattingServices.formatDateTime(value);
}

const functions = {
  resolveValueOnFE,
  formatDateTime,
  addZeroesAndSeparatevalue,
  valueCommaSeparated,
  getTimeDifference,
};

function resolveFunctions(
  activity: IActivityEntityBase,
  tenant: ITenant,
  state: IState = {
    pointer: 0,
    calls: 0,
    functions,
  }
): string {
  state.calls++;
  const { htmlBody } = activity;
  const functionsKeys = Object.keys(state.functions);
  const currentFunctionName = functionsKeys[state.pointer];
  const currentFunction = state.functions[currentFunctionName];

  if (state.calls > 25) return htmlBody;

  function goNext() {
    state.pointer++;
    const allChecked = state.pointer >= functionsKeys.length;
    if (allChecked) return htmlBody;
    return resolveFunctions(activity, tenant, state);
  }

  const functionIndex = htmlBody.indexOf(currentFunctionName);
  if (functionIndex === -1) return goNext();

  const paramsStartAt = functionIndex + currentFunctionName.length + 1;
  const paramsEndAt =
    paramsStartAt + htmlBody.substring(paramsStartAt).indexOf(')');
  if (paramsEndAt === -1) return goNext();

  const params = htmlBody.substring(paramsStartAt, paramsEndAt);
  const expression = `${currentFunctionName}(${params})`;

  const resolvedValue = currentFunction(params, tenant, activity);
  const resolvedHtml = htmlBody.replaceAll(expression, resolvedValue);

  activity.htmlBody = resolvedHtml;

  return resolveFunctions(activity, tenant, state);
}

export function parseActivityHTML(
  activity: IActivityEntityBase,
  tenant: ITenant
) {
  return resolveFunctions(activity, tenant);
}
