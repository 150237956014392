import React, { useMemo } from 'react';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { IProductionDetailsSummary } from '..';

interface IPolicyDetailsWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionPolicyMarineHullDetailsWidget: React.FC<
  IPolicyDetailsWidgetProps
> = ({ data }) => {
  const dynamicView = useMemo(
    (): IDynamicDiplayView => ({
      name: 'PolicyMarineHullDetails',
      sections: [
        {
          name: 'MarineHullDetails',
          title: 'Marine Hull Details',
          properties: {
            vesselName: {
              multiline: false,
              value: data?.MarineHull?.VesselName,
              name: 'vesselName',
              title: 'Vessel Name',
            },
            connectionPort: {
              multiline: false,
              value: data?.MarineHull?.ConnectionPort,
              name: 'connectionPort',
              title: 'Connection Port',
            },
            territorialWaters: {
              multiline: false,
              value: data?.MarineHull?.TerritorialWaters,
              name: 'territorialWaters',
              title: 'Territorial Waters',
            },
          },
        },
        {
          name: 'BodyDetails',
          title: 'Body Details',
          properties: {
            usage: {
              multiline: false,
              value: data?.MarineHull?.Usage,
              name: 'usage',
              title: 'Usage',
            },
            builder: {
              multiline: false,
              value: data?.MarineHull?.Builder,
              name: 'builder',
              title: 'Builder',
            },
            constructionYear: {
              multiline: false,
              value: data?.MarineHull?.ConstructionYear,
              name: 'constructionYear',
              title: 'Year of Construction',
            },
            registryPort: {
              multiline: false,
              value: data?.MarineHull?.RegisteryPort,
              name: 'registryPort',
              title: 'Port of Registery & Number',
            },
            length: {
              multiline: false,
              value: data?.MarineHull?.Length,
              name: 'length',
              title: 'Length',
            },
            weight: {
              multiline: false,
              value: data?.MarineHull?.Weight,
              name: 'weight',
              title: 'Weight',
            },
            height: {
              multiline: false,
              value: data?.MarineHull?.Height,
              name: 'height',
              title: 'Height',
            },
          },
        },
        {
          name: 'EngineDetails',
          title: 'Engine Details',
          properties: {
            engineType: {
              multiline: false,
              value: data?.MarineHull?.EngineType,
              name: 'engineType',
              title: 'Type',
            },
            engineNumber: {
              multiline: false,
              value: data?.MarineHull?.EngineNumber,
              name: 'engineNumber',
              title: 'Engine Number',
            },
            engineYearBuilt: {
              multiline: false,
              value: data?.MarineHull?.EngineYearBuilt,
              name: 'engineYearBuilt',
              title: 'Year Built',
            },
            engineHorsePower: {
              multiline: false,
              value: data?.MarineHull?.EngineHorsePower,
              name: 'engineHorsePower',
              title: 'Horse Power',
            },
            engineMaxDesignedSpeed: {
              multiline: false,
              value: data?.MarineHull?.EngineMaxDesignedSpeed,
              name: 'engineMaxDesignedSpeed',
              title: 'Max. Designed Speed',
            },
            engineFuel: {
              multiline: false,
              value: data?.MarineHull?.EngineFuel,
              name: 'engineFuel',
              title: 'Fuel',
            },
            remarks: {
              multiline: true,
              value: data?.MarineHull?.Remarks,
              name: 'remarks',
              title: 'Remarks',
              fullLine: true,
            },
          },
        },
      ],
    }),
    [data]
  );

  return <DynamicWidget hasFourFields={true} view={dynamicView} />;
};

export default ProductionPolicyMarineHullDetailsWidget;
