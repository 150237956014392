import { gql } from '@apollo/client';

export function getPlanDetailsInfo() {
  return gql`
    query getPlanDetailsInfo($id: String!) {
      PlanConfigManagement {
        entities {
          plan(id: $id) {
            views {
              PlanConfigManagement_all {
                properties {
                  SublineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          Status
                        }
                      }
                    }
                  }
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                  PlanCurrency {
                    Id
                    Title
                  }
                  SpecialCommissionApplicable
                  SpecialCommissionType
                  SpecialCommissionValue
                  SpecialCommissionValueCurrency {
                    Id
                    Title
                  }
                  SpecialCommissionRate
                  SpecialCommissionApplicableOn {
                    Id
                    Title
                  }
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          Status
                          ExternalCode
                        }
                      }
                    }
                  }
                  ExternalCode
                  Name
                  NameArabic
                  Abbreviation
                  EffectiveFrom
                  EffectiveTo
                  PlanStatus
                  MaxNbInsured
                  MaxNbBeneficiary
                  ClauseEditable
                  PlanCurrency {
                    Id
                    Title
                  }
                  AcalCategory {
                    Id
                    Title
                  }
                  Scratching
                  SOSService
                  ReplacementCarApplied
                  ReplacementCarValue
                  ReplacementCarDays
                  PolicyCost
                  ChargesPercentage
                  TPAFeesType
                  TPAFeesAmount
                  TPAFeesPercentage
                  TPAFeesApplicableOn
                  MinTPA
                  MaxTPA
                  GRLimitDays
                }
              }
            }
            queries {
              planConfigManagement {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function activatePlan() {
  return gql`
    mutation activatePlan($entityId: String!) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              activatePlan(entityId: $entityId) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function deactivatePlan() {
  return gql`
    mutation deactivatePlan($entityId: String!) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              deactivatePlan(entityId: $entityId) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function getPlanPolicyCoverList() {
  return gql`
    query getPlanPolicyCoverList(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
    ) {
      PlanConfigManagement {
        queries {
          getPolicyCoverList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            SelectedLineId: $SelectedLineId
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_PolicyCover_Id
              planConfigManagement_PolicyCover_createdOn
              planConfigManagement_PolicyCover_modifiedOn
              planConfigManagement_PolicyCover_ExternalCode
              planConfigManagement_PolicyCover_Name
              planConfigManagement_PolicyCover_NameArabic
              planConfigManagement_PolicyCover_NameFrench
              planConfigManagement_PolicyCover_PrintingOrder
              planConfigManagement_PolicyCover_PolicyCoverDescription
              planConfigManagement_PolicyCover_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      Name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getPlanSpecificAllRiskList() {
  return gql`
    query getPlanSpecificAllRiskList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedPlanIds: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getPlanSpecificAllRiskList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedPlanIds: $selectedPlanIds
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              totalCount
            }
            items {
              planConfigManagement_PlanSpecificAllRisk_Id
              planConfigManagement_PlanSpecificAllRisk_PlanID {
                id
              }
              planConfigManagement_PlanSpecificAllRisk_CarAgeFrom
              planConfigManagement_PlanSpecificAllRisk_CarAgeTo
              planConfigManagement_PlanSpecificAllRisk_CarValueFrom
              planConfigManagement_PlanSpecificAllRisk_CarValueTo
              planConfigManagement_PlanSpecificAllRisk_CarCategory {
                Id
                Title
              }
              planConfigManagement_PlanSpecificAllRisk_MinimumPremium
              planConfigManagement_PlanSpecificAllRisk_Rate
              planConfigManagement_PlanSpecificAllRisk_AgencyYear
              planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear
            }
          }
        }
      }
    }
  `;
}

export function getPlanSpecificExpatList() {
  return gql`
    query getPlanSpecificExpatList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedPlanIds: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getPlanSpecificExpatList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedPlanIds: $selectedPlanIds
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              totalCount
            }
            items {
              planConfigManagement_PlanSpecificExpat_Id
              planConfigManagement_PlanSpecificExpat_AgeFrom
              planConfigManagement_PlanSpecificExpat_AgeTo
              planConfigManagement_PlanSpecificExpat_Gender
              planConfigManagement_PlanSpecificExpat_OccupationClass {
                Code
                Title
              }
              planConfigManagement_PlanSpecificExpat_Nationality {
                Code
                Title
              }
              planConfigManagement_PlanSpecificExpat_Premium
              planConfigManagement_PlanSpecificExpat_MedicalClass {
                Code
                Title
              }
              planConfigManagement_PlanSpecificExpat_Emergency
            }
          }
        }
      }
    }
  `;
}

export function getPlanSpecificTravelList() {
  return gql`
    query getPlanSpecificTravelList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedPlanIds: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getPlanSpecificTravelList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedPlanIds: $selectedPlanIds
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              totalCount
            }
            items {
              planConfigManagement_PlanSpecificTravel_Id
              planConfigManagement_PlanSpecificTravel_PlanID {
                id
              }
              planConfigManagement_PlanSpecificTravel_AgeFrom
              planConfigManagement_PlanSpecificTravel_AgeTo
              planConfigManagement_PlanSpecificTravel_TravelDurationFrom
              planConfigManagement_PlanSpecificTravel_TravelDurationTo
              planConfigManagement_PlanSpecificTravel_TravelDestination {
                Id
                Title
              }
              planConfigManagement_PlanSpecificTravel_Premium
              planConfigManagement_PlanSpecificTravel_NbOfAdherent
              planConfigManagement_PlanSpecificTravel_Gender
            }
          }
        }
      }
    }
  `;
}

export function getPlanSpecificMedicalList() {
  return gql`
    query getPlanSpecificMedicalList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedPlanIds: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getPlanSpecificMedicalList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedPlanIds: $selectedPlanIds
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_PlanSpecificMedical_Id
              planConfigManagement_PlanSpecificMedical_Class {
                Code
                Title
              }
              planConfigManagement_PlanSpecificMedical_CoNssf
              planConfigManagement_PlanSpecificMedical_NetPremium
              planConfigManagement_PlanSpecificMedical_TPAFeesPercentage
              planConfigManagement_PlanSpecificMedical_MinTPAFees
              planConfigManagement_PlanSpecificMedical_MaxTPAFees
              planConfigManagement_PlanSpecificMedical_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                    }
                  }
                }
              }
              planConfigManagement_PlanSpecificMedical_PolicyCoverID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                    }
                  }
                }
              }
              planConfigManagement_PlanSpecificMedical_AgeFrom
              planConfigManagement_PlanSpecificMedical_AgeTo
              planConfigManagement_PlanSpecificMedical_NbOfAdherent
              planConfigManagement_PlanSpecificMedical_Level {
                Code
                Id
                Title
              }
              planConfigManagement_PlanSpecificMedical_IsMain
              planConfigManagement_PlanSpecificMedical_IsMandatory
              planConfigManagement_PlanSpecificMedical_IsEditable
              planConfigManagement_PlanSpecificMedical_SumInsured
              planConfigManagement_PlanSpecificMedical_SumInsuredIsAdditive
              planConfigManagement_PlanSpecificMedical_ExcessOnClaimType
              planConfigManagement_PlanSpecificMedical_ExcessOnClaimAmount
              planConfigManagement_PlanSpecificMedical_ExcessOnClaimPercentage
              planConfigManagement_PlanSpecificMedical_ExcessOnClaimDays
              planConfigManagement_PlanSpecificMedical_ClauseID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      Name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getPlanCoversRatesList() {
  return gql`
    query getPlanCoversRatesList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedPlanIds: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getPlanCoverList(
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedPlanIds: $selectedPlanIds
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_PlanCover_Id
              planConfigManagement_PlanCover_IsMandatory
              planConfigManagement_PlanCover_CoverPremiumType
              planConfigManagement_PlanCover_CoverPremiumPercentage
              planConfigManagement_PlanCover_CoverPremiumValue
              planConfigManagement_PlanCover_ExcessOnClaimType
              planConfigManagement_PlanCover_ExcessOnClaimAmount
              planConfigManagement_PlanCover_ExcessOnClaimPercentage
              planConfigManagement_PlanCover_ExcessOnClaimDays
              planConfigManagement_PlanCover_PlanID {
                views {
                  PlanConfigManagement_all {
                    properties {
                      PlanCurrency {
                        Id
                        Code
                        Title
                      }
                    }
                  }
                }
              }
              planConfigManagement_PlanCover_ClauseID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                    }
                  }
                }
              }
              planConfigManagement_PlanCover_createdOn
              planConfigManagement_PlanCover_PolicyCoverID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      Name
                    }
                  }
                }
              }
              policyCover_Id
              policyCover_Name
              planConfigManagement_PlanCover_IsMain
              planConfigManagement_PlanCover_IsMandatory
              planConfigManagement_PlanCover_IsEditable
              planConfigManagement_PlanCover_CoverSumInsured
              planConfigManagement_PlanCover_SumInsuredIsAdditive
            }
          }
        }
      }
    }
  `;
}
