import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { initialValues, inputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { cloneDeep, isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { IExpatPlanRatesDrawerProps } from '.';
import {
  createPlanSpecificExpat,
  getExpatPlanRatesEnums,
  getPlanSpecificExpatInfo,
  updatePlanSpecificExpat,
} from './queries';
import { LookupToList, graphqlToExpatPlanRatesInfo } from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';

const ExpatPlanRatesDrawer: React.FC<IExpatPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  planCurrency,
  expatPlanRateId,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [expatPlanRatesAction] = useMutation(
    expatPlanRateId ? updatePlanSpecificExpat() : createPlanSpecificExpat()
  );

  const [expatPlanRatesResultQuery] = useLazyQuery(getExpatPlanRatesEnums());

  const [expatPlanRatesInfoQuery] = useLazyQuery(getPlanSpecificExpatInfo());

  const loadLovList = async () => {
    const result = await expatPlanRatesResultQuery();
    const newExpatPlanRatesEnums = LookupToList(result.data);

    return newExpatPlanRatesEnums;
  };

  const getEntityInfo = async () => {
    if (expatPlanRateId) {
      const apiResult = await expatPlanRatesInfoQuery({
        variables: { id: expatPlanRateId },
      });
      if (apiResult.data) {
        const expatPlanRateEntity = graphqlToExpatPlanRatesInfo(apiResult.data);
        return expatPlanRateEntity;
      }
    }

    return initialValues;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);
      updatedInputs.planCurrency.value = planCurrency;

      const [expatPlanRatesEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      if (expatPlanRatesEntity) {
        updatedInputs.ageFrom.value = expatPlanRatesEntity.ageFrom;
        updatedInputs.ageTo.value = expatPlanRatesEntity.ageTo;
        updatedInputs.gender.value = expatPlanRatesEntity.gender;
        updatedInputs.occupationClass.value =
          expatPlanRatesEntity.occupationClass;
        updatedInputs.nationality.value = expatPlanRatesEntity.nationality;
        updatedInputs.premium.value = expatPlanRatesEntity.premium;
        updatedInputs.medicalClass.value = expatPlanRatesEntity.medicalClass;
        updatedInputs.emergency.value = expatPlanRatesEntity.emergency;
      }

      (updatedInputs.planCurrency as IFormSelectDynamicProps).selectOptions =
        lovData['currencies'];
      (updatedInputs.gender as IFormSelectDynamicProps).selectOptions =
        lovData['genders'];
      (updatedInputs.emergency as IFormSelectDynamicProps).selectOptions =
        lovData['emergencyOptions'];
      (updatedInputs.occupationClass as IFormSelectDynamicProps).selectOptions =
        lovData['occupations'];
      (updatedInputs.medicalClass as IFormSelectDynamicProps).selectOptions =
        lovData['medicalClasses'];
      (updatedInputs.nationality as IFormSelectDynamicProps).selectOptions =
        lovData['nationalities'];

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variablesMutation = {
        nationalities: data?.nationality,
        planSpecificExpatInputs: {
          planID: planId,
          ageFrom: Number(data?.ageFrom),
          ageTo: Number(data?.ageTo),
          gender: data?.gender,
          occupationClass: data?.occupationClass,
          medicalClass: data?.medicalClass,
          emergency: data?.emergency,
          premium: Number(data?.premium),
        },
      };

      const res = await expatPlanRatesAction({
        variables: expatPlanRateId
          ? {
              ...variablesMutation,
              entityId: expatPlanRateId,
            }
          : variablesMutation,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {expatPlanRateId
              ? 'Plan Rate successfully updated.'
              : 'Plan Rate successfully added.'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        setFormDisabled(false);
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={expatPlanRateId ? 'Modify Plan Rate' : 'Add Plan Rate'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={!!submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ExpatPlanRatesDrawer;
