import { IGenericClaimCoverTableData } from '.';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';

export function entityToPolicyCoverDetails(data: any): any {
  const policyCoverDetailsInfo =
    data?.PlanConfigManagement?.entities?.policyCover?.views
      ?.PlanConfigManagement_all?.properties;

  return {
    policyCoverName: policyCoverDetailsInfo?.Name,
    lineName:
      policyCoverDetailsInfo?.LineID?.views?.PlanConfigManagement_all
        ?.properties?.Name,
    lineId: policyCoverDetailsInfo?.LineID?.id,
    externalCode: policyCoverDetailsInfo?.ExternalCode,
    arabicName: policyCoverDetailsInfo?.NameArabic,
    printingOrder: policyCoverDetailsInfo?.PrintingOrder,
    policyCoverDescription: policyCoverDetailsInfo?.PolicyCoverDescription,
    createdBy: `${data?.PlanConfigManagement?.entities?.policyCover?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_firstName} ${data?.PlanConfigManagement?.entities?.policyCover?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: policyCoverDetailsInfo?.createdOn,
    modifiedBy: `${data?.PlanConfigManagement?.entities?.policyCover?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_firstName} ${data?.PlanConfigManagement?.entities?.policyCover?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: policyCoverDetailsInfo?.modifiedOn,
  };
}

export function mapToClaimCoversListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getClaimCoverList.items.forEach(
    (obj: IGenericClaimCoverTableData, i: number) => {
      list[i] = {
        planConfigManagement_ClaimCover_Id:
          obj.planConfigManagement_ClaimCover_Id,
        planConfigManagement_ClaimCover_Name:
          obj.planConfigManagement_ClaimCover_Name,
        planConfigManagement_ClaimCover_ExternalCode:
          obj.planConfigManagement_ClaimCover_ExternalCode,
        planConfigManagement_ClaimCover_PolicyCoverID:
          obj.planConfigManagement_ClaimCover_PolicyCoverID?.views
            ?.PlanConfigManagement_all?.properties?.Name,
        planConfigManagement_ClaimCover_NameArabic:
          obj.planConfigManagement_ClaimCover_NameArabic,
        planConfigManagement_ClaimCover_PrintingOrder:
          obj.planConfigManagement_ClaimCover_PrintingOrder,
        planConfigManagement_ClaimCover_ClaimCoverDescription:
          obj.planConfigManagement_ClaimCover_ClaimCoverDescription,
        planConfigManagement_ClaimCover_createdOn:
          obj.planConfigManagement_ClaimCover_createdOn,
        planConfigManagement_ClaimCover_modifiedOn:
          obj.planConfigManagement_ClaimCover_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getClaimCoverList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getClaimCoverList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getClaimCoverList?.paging
        ?.totalCount,
  };
}
