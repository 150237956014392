import { IPlanAdditionalFeesAndChargesDrawerInfo } from '.';
import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues: IPlanAdditionalFeesAndChargesDrawerInfo = {
  planCurrency: '',
  policyCost: '',
  chargesPercentage: '',
  tPAFeesType: 'AMOUNT',
  tPAFeesAmount: '',
  tPAFeesPercentage: '',
  tPAFeesApplicableOn: '',
  minTPA: '',
  maxTPA: '',
};

export const getInputs = (
  values: IPlanAdditionalFeesAndChargesDrawerInfo = initialValues,
  lovs: Record<string, Record<string, string>>,
  externalCode: string
): Record<string, DynamicFormInputType> => ({
  planCurrency: {
    name: 'planCurrency',
    title: 'Currency',
    type: FormInputTypes.chips,
    placeholder: 'Currency',
    value: !isEmpty(values.planCurrency) ? values.planCurrency : '',
    multiple: false,
    disabled: true,
    required: true,
    selectOptions: lovs.planCurrency || {},
  },
  policyCost: {
    name: 'policyCost',
    title: 'Policy Cost',
    type: FormInputTypes.formattedNumber,
    placeholder: 'Policy Cost',
    value: values.policyCost,
    required: true,
    disabled: false,
    minValue: 0,
  },
  chargesPercentage: {
    name: 'chargesPercentage',
    title: 'Charges Percentage',
    type: FormInputTypes.percentage,
    placeholder: 'Charges Percentage',
    value: values.chargesPercentage,
    required: true,
    disabled: false,
  },
  tPAFeesType: {
    name: 'tPAFeesType',
    title: 'TPA Fees Type',
    type: FormInputTypes.chips,
    placeholder: 'TPA Fees Type',
    value: !isEmpty(values.tPAFeesType) ? values.tPAFeesType : null,
    multiple: false,
    disabled: false,
    required: externalCode === '6',
    hidden: externalCode != '6',
    selectOptions: lovs.tPAFeesType || {},
  },
  tPAFeesAmount: {
    name: 'tPAFeesAmount',
    title: 'TPA Fees Amount',
    type: FormInputTypes.formattedNumber,
    placeholder: 'TPA Fees Amount',
    value: values.tPAFeesAmount,
    required: values.tPAFeesType === 'AMOUNT',
    hidden: values.tPAFeesType !== 'AMOUNT',
    disabled: false,
    minValue: 0,
  },
  tPAFeesPercentage: {
    name: 'tPAFeesPercentage',
    title: 'TPA Fees Percentage',
    type: FormInputTypes.percentage,
    placeholder: 'TPA Fees Percentage',
    value: values.tPAFeesPercentage,
    required: values.tPAFeesType === 'PERCENTAGE',
    hidden: values.tPAFeesType !== 'PERCENTAGE',
    disabled: false,
  },
  tPAFeesApplicableOn: {
    name: 'tPAFeesApplicableOn',
    title: 'TPA Fees ApplicableOn',
    type: FormInputTypes.chips,
    placeholder: 'TPA Fees ApplicableOn',
    value: !isEmpty(values.tPAFeesApplicableOn)
      ? values.tPAFeesApplicableOn
      : '',
    multiple: false,
    disabled: false,
    required: values.tPAFeesType === 'PERCENTAGE',
    hidden: values.tPAFeesType !== 'PERCENTAGE',
    selectOptions: lovs.tPAFeesApplicableOn || {},
  },
  minTPA: {
    name: 'minTPA',
    title: 'Min TPA',
    type: FormInputTypes.formattedNumber,
    placeholder: 'Min TPA',
    value: values.minTPA,
    required: values.tPAFeesType === 'PERCENTAGE',
    hidden: values.tPAFeesType !== 'PERCENTAGE',
    disabled: false,
  },
  maxTPA: {
    name: 'maxTPA',
    title: 'Max TPA',
    type: FormInputTypes.formattedNumber,
    placeholder: 'Max TPA',
    value: values.maxTPA,
    required: values.tPAFeesType === 'PERCENTAGE',
    hidden: values.tPAFeesType !== 'PERCENTAGE',
    disabled: false,
  },
});
