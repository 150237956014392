import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { IEnhancedFormInputBaseProps } from '.';
import { isEmpty } from '../../utils/validationUtils';
import { FileType, FILE_TYPES_META } from '../../models/file';
import { deriveMimeTypes, isFile } from '../../utils/file-utils';
import { MAIN_ONE_THEME } from '../../constants';

export interface IEnhancedInputProps extends IEnhancedFormInputBaseProps {
  type: string;
  classes?: { input?: string; wrapper?: string };
  value: string | File;
  loader?: boolean;
  inline?: boolean;
  allowedFileTypes?: FileType[];
  onUpload: (file: File) => void;
  onBlur?: (name: string) => void;
  iconUrl?: string;
  downloadLinks?: {
    displayName: string;
    downloadUrl: string;
    action?: () => Response;
  }[];
}

const useStyles = makeStyles()(() => ({
  hiddenFileInput: {
    height: 0,
    width: 0,
    margin: 0,
    padding: 0,
    visibility: 'hidden',
    border: 'none',
    display: 'block',
  },
  inputShell: {
    position: 'relative',
    fontSize: '14px',
    lineHeight: '15px',
    height: 34,
    width: '100%',
    padding: '9px 34px 9px 15px',
    margin: '0 auto',
    background: 'unset',
    border: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontFamily: 'SourceSansPro-Regular',
  },
  // TODO make style common using JSS
  inputDisabled: {
    cursor: 'not-allowed',
    opacity: 0.4,
  },
  inputPlaceholder: {
    color: 'rgba(0, 0, 0, 0.20)',
    fontStyle: 'italic',
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
  },
  iconContainer: {
    top: 'calc(50% - 15px)',
    right: '2px',
    width: '30px',
    height: '30px',
    margin: 0,
    position: 'absolute',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '80% 80%',
  },
  downloadLink: {
    fontSize: '11px',
    lineHeight: '12px',
    margin: '0 0 0 5px',
    padding: 0,
    display: 'inline-block',
    border: 'none',
    color: MAIN_ONE_THEME.palette.primary1.main,
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  placeholderStyle: {
    fontWeight: 100,
    color: MAIN_ONE_THEME.palette.secondary3.light,
    fontStyle: 'italic',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  valueStyle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  inputProgress: {
    opacity: 1,
  },
  uploadingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    right: '5px',
    top: 'calc(50% - 10px)',
    height: '20px',
  },
  progress: {
    color: MAIN_ONE_THEME.palette.primary2.main,
    fontSize: '10px',
    lineHeight: '11px',
  },
  loader: {
    display: 'block',
    width: '25px',
    height: '20px',
  },
  downloadIconContainer: {
    alignSelf: 'center',
    width: '30px',
    height: '30px',
  },
  inputDownload: {
    width: 'calc(100% - 30px)',
  },
  wrapper: {
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    borderRadius: '5px',
  },
  materialWrapper: {
    border: 'none',
    background: 'unset',
    borderRadius: 0,
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    marginBottom: '5px',
  },
  inputError: {
    borderColor: MAIN_ONE_THEME.palette.error.main,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
  },
}));

const EnhancedUploader: React.FC<IEnhancedInputProps> = ({
  classes = {},
  className,
  error,
  name,
  style,
  title,
  description,
  value,
  disabled,
  onUpload,
  onBlur,
  loader,
  allowedFileTypes,
  inline,
  iconUrl,
  downloadLinks = [],
  customStyles,
  material,
}) => {
  const { classes: inputClasses } = useStyles();
  const [fileError, setFileError] = useState<string>('');
  let uploadInputElement: HTMLInputElement;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && event.target.files && event.target.files.length > 0) {
      const uploadedFile = event.target.files[0];
      const validationError = validateFile(uploadedFile);
      setFileError(validationError);
      if (validationError) {
        uploadInputElement.value = '';
      } else {
        uploadInputElement.value = '';
        onUpload(uploadedFile);
      }
    }
  };

  const validateFile = (file: File): string | undefined => {
    // const sizeMB = +(file.size / (1024 * 1024)).toFixed(2);
    // if (maxFileSizeInMB && sizeMB > maxFileSizeInMB) {
    //   return dictionary['general.invalid-file-size'];
    // }
    if (isEmpty(allowedFileTypes)) {
      return undefined;
    }
    if (
      !allowedFileTypes.some((t) => FILE_TYPES_META[t].mimetype === file.type)
    ) {
      return 'Invalid File Type';
    }
    return undefined;
  };

  const handleClick = () => {
    uploadInputElement.click();
  };

  const renderInput = () => {
    return (
      <>
        <div
          className={clsx(inputClasses.wrapper, {
            [inputClasses.materialWrapper]: material,
            [inputClasses.inputError]: error || fileError,
          })}
        >
          <button
            type="button"
            title={
              disabled && !isEmpty(value)
                ? isFile(value)
                  ? (value as File).name
                  : (value as string)
                : 'Upload File'
            }
            onClick={handleClick}
            disabled={disabled}
            className={clsx(inputClasses.inputShell)}
            onBlur={() => onBlur && onBlur(name)}
          >
            {!isEmpty(value)
              ? isFile(value)
                ? (value as File).name
                : (value as string)
              : iconUrl && (
                  // eslint-disable-next-line react/jsx-indent
                  <figure
                    className={inputClasses.iconContainer}
                    title={'Upload File'}
                    style={{ backgroundImage: `url(${iconUrl})` }}
                  />
                )}
          </button>
        </div>
        <input
          type="file"
          name={name}
          className={inputClasses.hiddenFileInput}
          accept={deriveMimeTypes(allowedFileTypes)}
          ref={(el: HTMLInputElement) => {
            uploadInputElement = el;
          }}
          onChange={handleChange}
          disabled={disabled}
        />
      </>
    );
  };

  const renderDownloadLinks = () =>
    downloadLinks &&
    downloadLinks.map((link) => (
      <button
        key={link.downloadUrl}
        type="button"
        className={inputClasses.downloadLink}
        onClick={async () => {
          if (link.action) {
            const response: Response = await link.action();
            if (response.ok) {
              const blob = await response.blob();
              const fileURL = window.URL.createObjectURL(blob);
              const fileName = (
                response.headers.get('content-disposition') || ''
              ).split(`''`)[1];
              const linkElement = document.createElement('a');
              linkElement.href = fileURL;
              linkElement.download = fileName;
              linkElement.click();
            }
          } else {
            window.open(link.downloadUrl);
          }
        }}
      >
        {link.displayName}
      </button>
    ));

  return inline ? (
    renderInput()
  ) : (
    <>
      <EnhancedInputsWrapper
        title={title}
        description={description}
        error={error || fileError}
        name={name}
        className={clsx(classes.wrapper, className)}
        style={style}
        customStyles={customStyles}
      >
        <div style={{ position: 'relative' }}>
          {renderInput()}
          {renderDownloadLinks()}
          {loader && (
            <CircularProgress
              className={inputClasses.loader}
              size={20}
              thickness={3}
            />
          )}
        </div>
      </EnhancedInputsWrapper>
    </>
  );
};

export default EnhancedUploader;
