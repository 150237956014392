import { Checkbox } from '@mui/material';
import React, { ComponentProps, useCallback } from 'react';
// import clsx from 'clsx';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { IEnhancedCommonProps } from '.';
import { MAIN_ONE_THEME } from '../constants';

interface IEnhancedCheckboxProps extends IEnhancedCommonProps {
  name: string;
  checked: boolean;
  title?: string | JSX.Element;
  error?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  checkboxOnly?: boolean;
  onChange: (name: string, value: boolean) => void;
  classes?: {
    checkbox?: ComponentProps<typeof Checkbox>['classes'];
    error?: string;
    title?: string;
  };
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    padding: '6px 6px 6px 0',
    '& svg': {
      stroke: '#C7CCD6',
      color: MAIN_ONE_THEME.palette.primary2.main,
    },
  },
  title: {
    fontFamily: 'HelveticaNeue-Regular',
    fontSize: '14px',
    lineHeight: '16px',
    color: MAIN_ONE_THEME.palette.primary5.main,
  },
}));

const EnhancedCheckbox: React.FC<IEnhancedCheckboxProps> = ({
  name,
  title,
  checked,
  error,
  disabled,
  indeterminate,
  checkboxOnly,
  onChange,
  style,
  className,
  classes,
}) => {
  const checkboxClasses = useStyles().classes;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, inputChecked: boolean) => {
      onChange(name, inputChecked);
    },
    [name, onChange]
  );

  const renderCheckbox = () => (
    <Checkbox
      color="primary"
      classes={{
        root: checkboxClasses.root,
        ...classes?.checkbox,
      }}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      indeterminate={indeterminate}
      size="small"
    />
  );

  return checkboxOnly ? (
    renderCheckbox()
  ) : (
    <label htmlFor={name} className={className} style={style}>
      <div className={checkboxClasses.container}>
        {renderCheckbox()}
        <span className={clsx(checkboxClasses.title, classes?.title)}>
          {title}
        </span>
      </div>
      <span id={`errorMsg-${name}`} className={clsx(classes?.error)}>
        {error}
      </span>
    </label>
  );
};

export default EnhancedCheckbox;
