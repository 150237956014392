import { gql } from '@apollo/client';

export function getApplicationDetails() {
  return gql`
    query getMotorApplicationDetails($id: String!) {
      Insurance {
        entities {
          application(id: $id) {
            actions {
              able_To_SimulateAndSaveMotor
              able_To_SendQuotes
              able_To_AcceptQuotes
              able_To_IssuePolicy
              able_To_PreviewQuotes
              able_To_PreviewPolicies
            }
            views {
              Insurance_all {
                properties {
                  createdBy
                  modifiedBy
                  createdOn
                  modifiedOn
                  ApplicationName
                  Status
                  StatusReason
                  EffectiveDate
                  ExpiryDate
                  IsRenewal
                  RenewalNumber
                  LineOfBusiness {
                    Title
                  }
                  AssignedAgencyID {
                    views {
                      Insurance_all {
                        properties {
                          AgencyName
                        }
                      }
                    }
                  }
                  AssignedAgentID {
                    views {
                      Insurance_all {
                        properties {
                          FirstName
                          LastName
                        }
                      }
                    }
                  }
                  ApplicationContactDetailsID {
                    views {
                      Insurance_all {
                        properties {
                          Title {
                            Id
                            Title
                          }
                          FirstName
                          MiddleName
                          LastName
                          FullName
                          Email
                          MobileNumber
                          Address {
                            Country {
                              Id
                              Title
                            }
                            City
                            Street
                            Building
                          }
                          PhoneType
                        }
                      }
                    }
                  }
                  MotorDetailsID {
                    views {
                      Insurance_all {
                        properties {
                          PlateCode {
                            Id
                            Title
                          }
                          NameOnLicenseEn
                          NameOnLicenseAr
                          Weight
                          PlateNumber
                          UsageType {
                            Id
                            Title
                          }
                          BodyType {
                            Id
                            Title
                          }
                          EngineType {
                            Id
                            Title
                          }
                          Brand {
                            Id
                            Title
                          }
                          Model {
                            Id
                            Title
                          }
                          YearOfMake
                          Motor
                          EngineSize
                          Seats
                          Chassis
                          HorsePower
                        }
                      }
                    }
                    queries {
                      insurance {
                        plans {
                          input_Id
                          input_Product {
                            Code
                            Title
                          }
                          input_TowingService {
                            Id
                            Title
                          }
                          input_VignetteCode
                          input_Vignette
                          input_CoverageType
                          input_CarValue
                          input_IncludeAgencyRepair
                          quote_Id
                          quote_Name
                          quote_Status
                          quote_StatusReason
                          quote_TotalBasePremium
                          motorquote_SumInsured
                          motorquote_MinPremium
                          policy_Id
                          policy_FullPolicyNumber
                        }
                      }
                    }
                  }
                }
              }
            }
            queries {
              insurance {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
        lookups {
          products(Insurance_LinesOfBusiness: "Motor") {
            Id
            Title
          }
          plateCodes {
            Id
            Title
          }
          usageTypes {
            Id
            Title
          }
          bodyTypes {
            Id
            Title
          }
          engineTypes {
            Id
            Title
          }
          brands {
            Id
            Title
          }
          countries {
            Id
            Title
          }
          towingServices {
            Id
            Title
          }
          titles {
            Id
            Title
          }
        }
      }
      Insurance_CoverageTypes: __type(name: "Insurance_CoverageTypes") {
        name
        enumValues {
          name
        }
      }

      Insurance_RenewalStatus: __type(name: "Insurance_RenewalStatus") {
        name
        enumValues {
          name
        }
      }

      Insurance_PhoneType: __type(name: "Insurance_PhoneType") {
        name
        enumValues {
          name
        }
      }
      Insurance_AgencyRepairs: __type(name: "Insurance_AgencyRepairs") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function getModelsByBrands() {
  return gql`
    query getModelsByBrands($modelId: String!) {
      Insurance {
        lookups {
          models(Insurance_Brands: $modelId) {
            Id
            Title
          }
          countries {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function calculateAction() {
  return gql`
    mutation motorCalculateAction(
      $entityId: String!
      $effectiveDate: Date!
      $vehicleInputs: Insurance_VehicleInputs!
      $applicationContactInputs: Insurance_CustomerInputs!
      $plansInputs: [Insurance_MotorPlanInputs!]!
      $isRenewal: Insurance_RenewalStatus!
      $renewalNumber: String
    ) {
      insurance {
        entities {
          application {
            insurance {
              simulateAndSaveMotor(
                entityId: $entityId
                effectiveDate: $effectiveDate
                vehicleInputs: $vehicleInputs
                applicationContactInputs: $applicationContactInputs
                plansInputs: $plansInputs
                isRenewal: $isRenewal
                renewalNumber: $renewalNumber
              ) {
                Items
              }
            }
          }
        }
      }
    }
  `;
}

export function checkPlateNumberHasActiveProductPolicy() {
  return gql`
    query checkPlateNumberHasActiveProductPolicy(
      $plateCode: String
      $plateNumber: String
      $product: String!
      $effectiveDate: Date!
      $chassis: String
    ) {
      Insurance {
        queries {
          checkPlateNumberHasActiveProductPolicy(
            plateCode: $plateCode
            plateNumber: $plateNumber
            product: $product
            effectiveDate: $effectiveDate
            chassis: $chassis
          ) {
            insurancePolicyMotorDetails_Product {
              Id
              Title
            }
            insurance_Policy_FullPolicyNumber
            insurance_Policy_PolicyExpiryDate
          }
        }
      }
    }
  `;
}

export function acceptQuotes() {
  return gql`
    mutation acceptQuotes($entityId: String!, $quoteIds: [String!]!) {
      insurance {
        entities {
          application {
            insurance {
              acceptQuotes(entityId: $entityId, quoteIds: $quoteIds)
            }
          }
        }
      }
    }
  `;
}

export function sendQuotes() {
  return gql`
    mutation motorSendQuotes(
      $entityId: String!
      $quoteIds: [String!]!
      $customerEmail: String
    ) {
      insurance {
        entities {
          application {
            insurance {
              sendQuotes(
                entityId: $entityId
                quoteIds: $quoteIds
                customerEmail: $customerEmail
              )
            }
          }
        }
      }
    }
  `;
}

export function issuePolicy() {
  return gql`
    mutation issuePolicy($entityId: String!, $quoteIds: [String!]!) {
      insurance {
        entities {
          application {
            insurance {
              issuePolicy(entityId: $entityId, quoteIds: $quoteIds) {
                Items {
                  EntityTypeId
                  EntityId
                  EntityViewId
                  PropertyId
                  File {
                    id
                    location
                    path
                    fileName
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function downloadQuote() {
  return gql`
    mutation downloadQuote(
      $entityId: String!
      $customerEmail: String!
      $quoteIds: [String!]!
      $applicationContactInputs: Insurance_CustomerInputs
    ) {
      insurance {
        entities {
          application {
            insurance {
              previewQuotes(
                entityId: $entityId
                customerEmail: $customerEmail
                quoteIds: $quoteIds
                applicationContactInputs: $applicationContactInputs
              ) {
                Items {
                  id
                  location
                  path
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function previewPolicies() {
  return gql`
    mutation previewPolicies($entityId: String!, $quoteIds: [String!]!) {
      insurance {
        entities {
          application {
            insurance {
              previewPolicies(entityId: $entityId, quoteIds: $quoteIds) {
                Items {
                  id
                  location
                  path
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function validateVignette() {
  return gql`
    mutation validateVignette(
      $entityId: String!
      $effectiveDate: Date!
      $vignetteCode: String!
      $vignette: Long!
    ) {
      insurance {
        entities {
          application {
            insurance {
              validateVignette(
                entityId: $entityId
                effectiveDate: $effectiveDate
                vignetteCode: $vignetteCode
                vignette: $vignette
              )
            }
          }
        }
      }
    }
  `;
}

export function getCopyPolicyDetailsQuery() {
  return gql`
    query getCopyPolicyDetails(
      $applicationId: String!
      $enteredPolicyNumber: String!
    ) {
      Insurance {
        queries {
          getPolicyByNumber(
            applicationId: $applicationId
            enteredPolicyNumber: $enteredPolicyNumber
          ) {
            insurance_Policy_Id
            insurance_Policy_TotalAnnualPremium
            insurance_Policy_PolicyIssueDate
            insurance_Policy_createdOn
            insurancePolicyHolder_Title {
              Code
              Title
            }
            insurancePolicyHolder_FirstName
            insurancePolicyHolder_MiddleName
            insurancePolicyHolder_LastName
            insurancePolicyHolder_FullName
            insurancePolicyHolder_Email
            insurancePolicyHolder_MobileNumber
            insurancePolicyHolder_PhoneType
            insurancePolicyHolder_Address {
              Country {
                Code
                Title
              }
              Zone {
                Code
                Title
              }
              City
              Street
              Building
            }
            insurancePolicyHolder_Gender
            insurancePolicyMotorDetails_Id
            insurancePolicyMotorDetails_Product {
              Code
              Title
            }
            insurancePolicyMotorDetails_NameOnLicenseEn
            insurancePolicyMotorDetails_NameOnLicenseAr
            insurancePolicyMotorDetails_UsageType {
              Code
              Title
            }
            insurancePolicyMotorDetails_BodyType {
              Code
              Title
            }
            insurance_Policy_PolicyNumber
            insurancePolicyMotorDetails_EngineType {
              Code
              Title
            }
            insurancePolicyMotorDetails_Brand {
              Code
              Title
            }
            insurancePolicyMotorDetails_Model {
              Code
              Title
            }
            insurancePolicyMotorDetails_YearOfMake
            insurancePolicyMotorDetails_PlateNumber
            insurancePolicyMotorDetails_PlateCode {
              Code
              Title
            }
            insurancePolicyMotorDetails_Motor
            insurancePolicyMotorDetails_Weight
            insurancePolicyMotorDetails_EngineSize
            insurancePolicyMotorDetails_Seats
            insurancePolicyMotorDetails_Chassis
            insurancePolicyMotorDetails_HorsePower
          }
        }
      }
    }
  `;
}
