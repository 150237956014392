import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  salesforceManagement_BusinessUser_Code: {
    name: 'salesforceManagement_BusinessUser_Code',
    title: 'Code',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  person_FullName: {
    name: 'person_FullName',
    title: 'Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  salesforceManagement_BusinessUser_Type: {
    name: 'salesforceManagement_BusinessUser_Type',
    title: 'Type',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessUser_Access: {
    name: 'salesforceManagement_BusinessUser_Access',
    title: 'Access',
    type: EnhancedTableHeaderType.Text,
  },
  person_Email: {
    name: 'person_Email',
    title: 'Email',
    type: EnhancedTableHeaderType.Text,
  },
  person_PhoneDetails: {
    name: 'person_PhoneDetails',
    title: 'Phone Number',
    type: EnhancedTableHeaderType.IntPhoneNumber,
  },
  salesforceManagement_BusinessUser_Status: {
    name: 'salesforceManagement_BusinessUser_Status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessUser_createdOn: {
    name: 'salesforceManagement_BusinessUser_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  salesforceManagement_BusinessUser_modifiedOn: {
    name: 'salesforceManagement_BusinessUser_modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        type: {
          name: 'type',
          title: 'Type',
          type: FormInputTypes.chips,
          value: filterValues?.type || [],
          placeholder: 'Type',
          triggerUpdate: true,
          selectOptions: { ...lovs.type },
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        access: {
          name: 'access',
          title: 'Access',
          type: FormInputTypes.chips,
          value: filterValues?.access || [],
          placeholder: 'Access',
          triggerUpdate: true,
          selectOptions: { ...lovs.access },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues?.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
