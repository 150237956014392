import { gql } from '@apollo/client';

export function getCompaniesListQuery() {
  return gql`
    query getCompaniesListQuery {
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
    }
  `;
}

export function getBusinessPartnersQuery() {
  return gql`
    query GetAllBusinessPartners(
      $companyId: String!
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      SalesforceManagement {
        queries {
          GetAllBusinessPartners(
            Company: $companyId
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            items {
              salesforceManagement_BusinessPartner_Id
              salesforceManagement_BusinessPartner_FullName
            }
          }
        }
      }
    }
  `;
}

export function getPolicyBusinessPartnerListQuery() {
  return gql`
    query GetPolicyBusinessPartnerList(
      $keywordSearch: String
      $selectedBroker: [String]
      $selectedCompany: String!
      $pageSize: Int!
      $pageNumber: Int!
    ) {
      Accounting {
        queries {
          PolicyBusinessPartnerList(
            KeywordSearch: $keywordSearch
            SelectedBroker: $selectedBroker
            SelectedCompany: $selectedCompany
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            items {
              salesforceManagement_BusinessPartner_Id
              salesforceManagement_BusinessPartner_FullName
              policyBusinessPartnerDetails_PolicyID {
                id
              }
              receivableBills_OutstandingBalanceBasicSystemCurrency
              receivableBills_OutstandingBalanceSecondarySystemCurrency
              payableBills_OutstandingBalanceBasicSystemCurrency
              payableBills_OutstandingBalanceSecondarySystemCurrency
              policyBusinessPartnerDetails_ValueCurrency {
                Symbol
              }
            }
            paging {
              pageSize
              pageNumber
              totalCount
            }
          }
        }
      }
      Core {
        queries {
          getSystemCurrencies {
            core_SystemCurrency_Id
            core_SystemCurrency_BasicCurrency {
              Code
              Symbol
            }
            core_SystemCurrency_SecondaryCurrency {
              Code
              Symbol
            }
          }
        }
      }
    }
  `;
}
