import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import {
  trimTrailingZeros,
  valueCommaSeparated,
} from '../../../utils/formatting-utils';

const useStyles = makeStyles()(() => ({
  container: {
    border: '1px solid #E8E8E8',
    borderRadius: '5px',
    height: 'fit-content',
    minWidth: '344px',
    backgroundColor: '#F5F5F5',
    padding: '28px 27px 12px',
    boxSizing: 'border-box',
  },
  compareContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '16px',
    color: '#A8A8A8',
    fontFamily: 'SourceSansPro-Medium',
    marginRight: '3px',
  },
  value: {
    fontSize: '18px',
    fontFamily: 'SourceSansPro-SemiBold',
  },
  differenceContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: '13px',
  },
  differenceValueNull: {
    color: ' #5DB77A',
  },
  differenceValueDiff: {
    color: '#C67D44',
  },
  icon: {
    margin: '31px 10px 5px',
  },
  systemCurrency: {
    marginRight: '5px',
  },
}));

interface IDifferenceSectionProps {
  systemCurrency: string;
  debit: number;
  credit: number;
}

const DifferenceSection: React.FC<IDifferenceSectionProps> = ({
  systemCurrency,
  debit,
  credit,
}) => {
  const { classes } = useStyles();

  const difference = Math.abs(debit - credit);

  return (
    <section className={classes.container}>
      <div className={classes.compareContainer}>
        <div>
          <span className={classes.title}>Total Debit</span>
          <br />
          <span className={classes.value}>
            <span className={classes.systemCurrency}>{systemCurrency}</span>
            {valueCommaSeparated(trimTrailingZeros(debit))}
          </span>
        </div>
        <div className={classes.icon}>
          {difference === 0 ? (
            <span>=</span>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.719"
              height="11.859"
              viewBox="0 0 11.719 11.859"
            >
              <g id="noun-not-equal-2342357" transform="translate(-1.7 -1.175)">
                <g
                  id="Group_4291"
                  data-name="Group 4291"
                  transform="translate(2 1.596)"
                >
                  <path
                    id="Path_18532"
                    data-name="Path 18532"
                    d="M3.681,12.613l-.836-.65,1.72-2.211H2V8.694H5.389L7.86,5.517H2V4.458H8.683L10.908,1.6l.837.65-1.72,2.211h3.095V5.517H9.2L6.73,8.694h6.389V9.753H5.906Z"
                    transform="translate(-2 -1.596)"
                    stroke="#000"
                    strokeWidth="0.6"
                    fillRule="evenodd"
                  />
                </g>
              </g>
            </svg>
          )}
        </div>
        <div>
          <span className={classes.title}>Total Credit</span>
          <br />
          <span className={classes.value}>
            <span className={classes.systemCurrency}>{systemCurrency}</span>
            {valueCommaSeparated(trimTrailingZeros(credit))}
          </span>
        </div>
      </div>
      <div className={classes.differenceContainer}>
        <span className={classes.title}>Difference:</span>
        <span
          className={clsx(
            classes.value,
            difference === 0
              ? classes.differenceValueNull
              : classes.differenceValueDiff
          )}
        >
          {valueCommaSeparated(trimTrailingZeros(difference))}
        </span>
      </div>
    </section>
  );
};

export default DifferenceSection;
