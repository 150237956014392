import React from 'react';
import { IProposalDetailsSummary } from '../generic/index2';
import EnhancedModal from '../../../../../components/common/generic-modal/EnhancedModal';
import PreviewProposalClauseModal from './PreviewProposalClauseModal';

interface IPreviewProposalClauseModalShellProps {
  data: IProposalDetailsSummary;
  onClose?: () => void;
  onSuccess?: () => void;
}

const PreviewProposalClauseModalShell: React.FC<
  IPreviewProposalClauseModalShellProps
> = ({ data, onClose = () => undefined, onSuccess }) => {
  return (
    <EnhancedModal
      onClose={onClose}
      open={true}
      blur={true}
      title={'Preview Clause'}
    >
      <PreviewProposalClauseModal data={data} onSuccess={onSuccess} />
    </EnhancedModal>
  );
};

export default PreviewProposalClauseModalShell;
