import React, { useEffect } from 'react';
import XLSX from 'xlsx-js-style';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

interface DownloadExcelProps {
  data: any[];
  filename: string;
  headers: {
    [key: string]: { label: string; visible: boolean; isDate: boolean };
  };
  specialHeaders?: { [key: string]: string[] };
}

const DownloadJsonasExcel: React.FC<DownloadExcelProps> = ({
  data,
  filename,
  headers,
  specialHeaders,
}) => {
  const formatDate = (dateString: string) => {
    const date = dayjs(new Date(dateString)).format('DD/MM/YYYY');

    return date;
  };

  const getFieldValue = (item: any, field: string, isDate?: boolean) => {
    const value = item[field];
    if (value && typeof value === 'object' && 'Title' in value) {
      return value.Title;
    }

    return isDate && value ? formatDate(value) : value;
  };

  const MAX_WIDTH = 30;

  const downloadFile = () => {
    if (!data || data.length === 0) {
      console.error('No data provided.');
      return;
    }

    const transformedData = data.map((item) => {
      const transformedItem: { [key: string]: any } = {};

      Object.keys(headers).forEach((key) => {
        if (headers[key].visible) {
          transformedItem[headers[key].label] = getFieldValue(
            item,
            key,
            headers[key].isDate
          );
        }
      });

      if (specialHeaders) {
        Object.keys(specialHeaders).forEach((specialKey) => {
          const fields = specialHeaders[specialKey];
          transformedItem[specialKey] = fields
            .map((field) => getFieldValue(item, field))
            .join(' ');
        });
      }

      return transformedItem;
    });

    const newWorkbook = XLSX.utils.book_new();
    const newWorksheet = XLSX.utils.json_to_sheet(transformedData);

    const headerRow = Object.values(headers)
      .filter((header) => header.visible)
      .map((header) => header.label);

    XLSX.utils.sheet_add_aoa(newWorksheet, [headerRow], { origin: 'A1' });

    const headerStyle = {
      font: { sz: 12, bold: true, color: { rgb: 'FFFFFF' } },
      fill: { fgColor: { rgb: '215C98' } },
      // alignment: { horizontal: "center", vertical: "center" },
    };

    const range = XLSX.utils.decode_range(newWorksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ c: C, r: 0 });
      if (!newWorksheet[cellAddress]) continue;
      newWorksheet[cellAddress].s = headerStyle;
    }

    const columnWidths = headerRow.map((headerLabel) => {
      const maxHeaderLength = headerLabel.length;
      const maxContentLength = Math.max(
        ...transformedData.map((row) =>
          row[headerLabel] ? row[headerLabel].toString().length : 0
        )
      );
      const calculatedWidth = Math.max(maxHeaderLength, maxContentLength) + 2;
      return { wch: Math.min(calculatedWidth, MAX_WIDTH) };
    });

    newWorksheet['!cols'] = columnWidths;

    XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, 'Sheet1');

    const excelBuffer = XLSX.write(newWorkbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (!isEmpty(filename)) {
      downloadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filename]);

  return null;
};

export default DownloadJsonasExcel;
