import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import SectionDynamicForm from '../../DynamicForm/SectionDynamicForm';
import { IDynamicSection } from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getSections } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createCustomer,
  getCustomerEnums,
  updateAccount,
  getAccountInfo,
  validateCustomerName,
  validateNameOnUpdate,
} from './queries';
import {
  LookupToList,
  graphqlEntityToAccountInfo,
  EntityCustomerToList,
  UpdateEntityAccountToList,
} from './utils';
import Loader from '../../components/Loader';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { isEmpty } from '../../utils/validationUtils';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getError } from '../../utils/graph-utils';
import { useNavigate } from 'react-router-dom';

const CustomerDrawer: React.FC<ICustomerDrawerProps> = ({
  customerId,
  open,
  onSuccess,
  onClose,
}) => {
  const navigate = useNavigate();

  // const accountEnumResults = useQuery(getAccountingEnums(), {
  //   fetchPolicy: "no-cache",
  // });
  const [validationQuery] = useLazyQuery(validateCustomerName());
  const [validationQueryOnUpdate] = useLazyQuery(validateNameOnUpdate());

  const [accountEnumResultsQeury] = useLazyQuery(getCustomerEnums());

  const [accountAction] = useMutation(
    customerId ? updateAccount() : createCustomer()
  );

  const [accountInfoQuery] = useLazyQuery(getAccountInfo());

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    type: {},
    currency: {},
    relatedCompanies: {},
    countries: {},
    accounts: {},
  });

  const [accounts, setAccounts] = useState<Record<string, string>>({});

  const [inputsForm, setInputsForm] =
    useState<Record<string, IDynamicSection>>();

  const [booted, setBooted] = useState<boolean>(false);

  const initialize = async () => {
    setBooted(false);
    try {
      const values = {
        company: '',
        name: '',
        accountLink: false,
        accountLinked: '',
        type: '',
        isSubAccount: false,
        parentAccount: '',
        currency: '',
        referenceNumber: '',
        website: '',
        notes: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        building: '',
        street: '',
        country: '',
        city: '',
      };
      let newLovs: Record<string, Record<string, string>> = {};
      let newAccounts: Record<string, string> = {};

      const promises: Promise<any>[] = [];
      const accountEnumResultsQeuryPromise = accountEnumResultsQeury({
        variables: { SelectedCompanyID: values.company },
        fetchPolicy: 'no-cache',
      });

      promises.push(accountEnumResultsQeuryPromise);

      if (customerId) {
        const accountInfoResultPromise = accountInfoQuery({
          variables: { id: customerId },
          fetchPolicy: 'no-cache',
        });
        promises.push(accountInfoResultPromise);
      }

      const result = await Promise.all(promises);

      if (result[0]?.data) {
        const newAccountEnums = LookupToList(result[0].data);
        newLovs = {
          type: newAccountEnums['Type'],
          currency: newAccountEnums['Currency'],
          relatedCompanies: newAccountEnums['relatedCompanies'],
          countries: newAccountEnums['countries'],
          accounts: newAccountEnums['accounts'],
        };
        newAccounts = newAccountEnums['accounts'];
      }
      if (customerId && result[1]?.data) {
        if (result[1]?.data) {
          const accountEntity = graphqlEntityToAccountInfo(result[1]?.data);
          if (accountEntity) {
            values.name = accountEntity.name;
            values.currency = accountEntity.currency;
            values.company = accountEntity.company;
          }
        }
      }
      const newInputForm = getSections(
        customerId ? 'edit' : 'add',
        values,
        newLovs,
        newLovs.accounts
      );
      setLovs(newLovs);
      setAccounts(newAccounts);
      setInputsForm(newInputForm);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setBooted(true);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variables = {
        EnteredAddressBuilding: values.building === '' ? null : values.building,
        EnteredAddressCity: values.city === '' ? null : values.city,
        EnteredAddressCountry: values.country === '' ? false : values.country,
        EnteredAddressDetails: values.address === '' ? null : values.address,
        EnteredAddressStreet: values.street === '' ? null : values.street,
        EnteredContactEmail: values.email === '' ? null : values.email,
        EnteredContactFirstName:
          values.firstName === '' ? null : values.firstName,
        EnteredContactLastName:
          values.lastName === '' ? false : values.lastName,
        EnteredContactPhone:
          values.phoneNumber === '' ? null : values.phoneNumber,
        EnteredCustomerName: values.name === '' ? null : values.name,
        EnteredNote: values.notes === '' ? null : values.notes,
        EnteredReferenceNumber:
          values.referenceNumber === '' ? null : values.referenceNumber,
        EnteredWebsite: values.website === '' ? null : values.website,
        LinkedAccount:
          values.accountLinked === '' ? null : values.accountLinked,
        LinkToExistingAccount:
          values.accountLink === '' ? false : values.accountLink,
        MakeThisASubAccount:
          values.isSubAccount === '' ? false : values.isSubAccount,
        SelectedAccountCurrency:
          values.currency === '' ? null : values.currency,
        SelectedAccountType: values.type === '' ? null : values.type,
        SelectedCompany: values.company === '' ? null : values.company,
        SelectedParentAccount:
          values.parentAccount === '' ? null : values.parentAccount,
      };

      accountAction({
        variables: customerId
          ? {
              UpdatedDescription: values.description,
              UpdatedID: values.id === '' ? null : values.id,
              UpdatedName: values.name,
              CurrentAccountID: customerId,
            }
          : variables,
        errorPolicy: 'all',
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {customerId
                ? 'Customer successfully updated'
                : 'Customer successfully created'}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState('success');
            onSuccess();
            onClose();
            if (!customerId) {
              navigate(`/accounting/customers`);
            }
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const onCustomChange = async (
    fieldName: string,
    value: any,
    allValues: Record<string, any>,
    errors: Record<string, any>
  ) => {
    const currentMode = customerId ? 'edit' : 'add';
    let newLovs = { ...lovs };
    let newAccounts = { ...accounts };

    if (
      fieldName === 'company' ||
      fieldName === 'accountLink' ||
      fieldName === 'isSubAccount'
    ) {
      setFormDisabled(true);
      const promises: Promise<any>[] = [];
      const accountEnumResultsQeuryPromise = accountEnumResultsQeury({
        variables: { SelectedCompanyID: allValues.company },
        fetchPolicy: 'no-cache',
      });

      promises.push(accountEnumResultsQeuryPromise);

      const result = await Promise.all(promises);
      if (result[0]?.data) {
        const newAccountEnums = LookupToList(result[0].data);
        (newLovs = {
          type: newAccountEnums['Type'],
          currency: newAccountEnums['Currency'],
          relatedCompanies: newAccountEnums['relatedCompanies'],
          countries: newAccountEnums['countries'],
          accounts: newAccountEnums['accounts'],
        }),
          (newAccounts = newAccountEnums['accounts']);
      }
      setFormDisabled(false);

      const newInputForm = getSections(
        currentMode,
        allValues,
        newLovs,
        newAccounts
      );

      setInputsForm(newInputForm);
    }

    if (fieldName === 'company') {
      if (currentMode === 'add' && allValues.name) {
        setFormDisabled(true);
        const promises: Promise<any>[] = [];

        const nameValidationPromise = validationQuery({
          variables: {
            EnteredCustomerName: allValues.name === '' ? null : allValues.name,
            Id: null,
            SelectedCompany:
              allValues.company.length === 0 ? null : allValues.company,
          },
          fetchPolicy: 'no-cache',
        });

        promises.push(nameValidationPromise);
        const result = await Promise.all(promises);
        if (result[0]?.data) {
          const sameAccounts = EntityCustomerToList(result[0].data);
          if (Object.keys(sameAccounts).length > 0)
            errors['name'] = 'Customer name already exists';
          else errors['name'] = null;
        }
      }
      setFormDisabled(false);
    }
  };

  const onCustomBlur = async (
    fieldName: string,
    values: Record<string, any>,
    errors: Record<string, any>
  ) => {
    const newValues = { ...values };
    const newErrors = { ...errors };

    const currentMode = customerId ? 'edit' : 'add';

    if (fieldName === 'name') {
      if (fieldName === 'name' && isEmpty(values.name)) {
        return { values: newValues, errors: newErrors };
      }

      if (currentMode === 'add') {
        setFormDisabled(true);
        const promises: Promise<any>[] = [];

        const nameValidationPromise = validationQuery({
          variables: {
            EnteredCustomerName: values.name === '' ? null : values.name,
            Id: null,
            SelectedCompany:
              values.company.length === 0 ? null : values.company,
          },
          fetchPolicy: 'no-cache',
        });

        promises.push(nameValidationPromise);
        const result = await Promise.all(promises);
        if (result[0]?.data && fieldName === 'name') {
          const sameAccounts = EntityCustomerToList(result[0].data);
          if (Object.keys(sameAccounts).length > 0)
            newErrors[fieldName] = 'Customer name already exists';
          else newErrors[fieldName] = null;
        }
      } else if (currentMode === 'edit') {
        setFormDisabled(true);
        const promises: Promise<any>[] = [];
        const nameValidationPromise = validationQueryOnUpdate({
          variables: {
            Name: values.name === '' ? null : values.name,
            Id: null,
            CurrentAccountID: customerId,
            SelectedCompanyID:
              values.company.length === 0 ? null : values.company,
          },
          fetchPolicy: 'no-cache',
        });

        promises.push(nameValidationPromise);
        const idValidationPromise = validationQueryOnUpdate({
          variables: {
            Name: null,
            Id: values.id === '' ? null : values.id,
            CurrentAccountID: customerId,
            SelectedCompanyID:
              values.company.length === 0 ? null : values.company,
          },
          fetchPolicy: 'no-cache',
        });

        promises.push(idValidationPromise);
        const result = await Promise.all(promises);
        if (result[0]?.data && fieldName === 'name') {
          const sameAccounts = UpdateEntityAccountToList(result[0].data);
          if (Object.keys(sameAccounts).length > 0)
            newErrors[fieldName] = 'Account Name already exists';
          else newErrors[fieldName] = null;
        }
      }

      setFormDisabled(false);
    }

    return { values: newValues, errors: newErrors };
  };

  return (
    <GenericDrawer
      title={customerId ? 'Modify Customer' : 'Add New Customer'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(!booted || !inputsForm) && open ? (
        <Loader />
      ) : (
        <>
          <SectionDynamicForm
            sections={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title=""
            onChange={onCustomChange}
            onCustomBlur={onCustomBlur}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default CustomerDrawer;
