import { gql } from '@apollo/client';

export function getListForm() {
  return gql`
    query getSalesForceBusinessPartnersInfoEnums {
      SalesforceManagement_BusinessPartnerStatuses: __type(
        name: "SalesforceManagement_BusinessPartnerStatuses"
      ) {
        name
        enumValues {
          name
        }
      }

      SalesforceManagement {
        lookups {
          relatedCompanies {
            Id
            Title
          }
          businessPartnerTypes {
            Id
            Title
          }
          businessPartnerCategories {
            Id
            Title
          }
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function updateMaxCreditLimit() {
  return gql`
    mutation updateMaxCreditLimit(
      $entityId: String!
      $maxCreditLimitAllowedCurrency: String!
      $maxCreditLimitAllowed: Long!
    ) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              updateMaxCreditLimit(
                entityId: $entityId
                maxCreditLimitAllowedCurrency: $maxCreditLimitAllowedCurrency
                maxCreditLimitAllowed: $maxCreditLimitAllowed
              ) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      MaxCreditLimitAllowedCurrency {
                        Code
                        Title
                      }
                      MaxCreditLimitAllowed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
