import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues: ICloseProposalInfo = {
  id: '',
  closingReason: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  closingReason: {
    name: 'closingReason',
    title: 'Closing Reason',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.closingReason)
      ? initialValues.closingReason
      : [],
    required: true,
    multiple: false,
    selectOptions: {
      R1: 'Premium is high',
      R2: 'Waiting for authority',
      R3: 'Above limits',
    },
  },
};
