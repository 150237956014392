import { gql } from '@apollo/client';

export function getListForm() {
  return gql`
    query getSalesForceBusinessPartnersInfoEnums {
      SalesforceManagement_BusinessPartnerStatuses: __type(
        name: "SalesforceManagement_BusinessPartnerStatuses"
      ) {
        name
        enumValues {
          name
        }
      }

      SalesforceManagement {
        lookups {
          relatedCompanies {
            Id
            Title
          }
          businessPartnerTypes {
            Id
            Title
          }
          businessPartnerCategories {
            Id
            Title
          }
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function updateLineCreditLimit() {
  return gql`
    mutation updateLineCreditLimit(
      $entityId: String!
      $selectedBusinessPartnerLineIds: [String!]!
      $creditLimit: Long!
      $creditLimitCurrency: String!
      $gracePeriod: Long!
    ) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              updateLineCreditLimit(
                entityId: $entityId
                selectedBusinessPartnerLineIds: $selectedBusinessPartnerLineIds
                creditLimit: $creditLimit
                creditLimitCurrency: $creditLimitCurrency
                gracePeriod: $gracePeriod
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
