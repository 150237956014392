import React, { useEffect, useState } from 'react';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingData } from '../../models/listing';
import { paymentTermsHeaders } from './content';
import { useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import Loader from '../../components/Loader';
import { mapToListingPaymentTermsData } from './utils';
import { makeStyles } from 'tss-react/mui';
import { getPolicyBillsThroughBusinessPartnerQuery } from './queries';

interface IPolicyPaymentTermsSchedule {
  businessPartnerId: string;
}

const useStyles = makeStyles()(() => ({
  paperClass: {
    color: 'red',
  },
}));

const BusinessPartnersPaymentTerms: React.FC<IPolicyPaymentTermsSchedule> = ({
  businessPartnerId,
}) => {
  const { classes } = useStyles();
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getPolicyBillsLazy] = useLazyQuery(
    getPolicyBillsThroughBusinessPartnerQuery()
  );

  const loadData = async (currentPage = 0, pageSize = tableData.pageSize) => {
    setLoading(true);

    try {
      const result = await getPolicyBillsLazy({
        variables: {
          businessPartnerId: businessPartnerId,
          pageNumber: 1,
          pageSize: tableData.pageSize,
        },
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;

      const newTableData = mapToListingPaymentTermsData(data);
      setTableData({ ...newTableData, pageNumber: currentPage, pageSize });
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    await loadData(0, tableData.pageSize);
  };

  useEffect(() => {
    initialize();
  }, []);

  const handlePageChange = async (page: number) => {
    await loadData(page, tableData.pageSize);
  };

  const handleRowsPerPageChange = async (numberOfRecordsPerPage: number) => {
    await loadData(0, numberOfRecordsPerPage);
  };

  if (!booted) {
    return <Loader />;
  }

  return (
    <>
      <EnhancedTable
        inlineTitle="Payment Terms Schedule"
        name="paymentTermsSchedule"
        data={tableData}
        headers={paymentTermsHeaders}
        handlePageChange={(page: number) => handlePageChange(page)}
        handleRowsPerPageChange={(page: number) => {
          handleRowsPerPageChange(page);
        }}
        currentPage={tableData.pageNumber}
        loader={loading}
        tableClasses={classes}
        showInlineFilter={false}
        hideToolbar={false}
        inline
        isTitlePascalCase
        orderByAscendingByDefault
        disableSelection
        usePagination
        showCellFullData
      />
    </>
  );
};

export default BusinessPartnersPaymentTerms;
