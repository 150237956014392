import dayjs, * as Dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isEmpty } from './validationUtils';

export function getAgeInYears(dob: string, startDate = ''): number {
  let age = 0;

  if (!isEmpty(dob)) {
    const now = startDate ? new Date(startDate) : new Date();
    const dobDate = new Date(dob);

    const nowInDays = Math.ceil(now.getTime() / (1000 * 60 * 60 * 24));
    const dobInDays = Math.ceil(dobDate.getTime() / (1000 * 60 * 60 * 24));

    const dateDiffDays = nowInDays - dobInDays;

    age = Math.floor(dateDiffDays / 365);
  }

  return age;
}

export const getDateDifferenceInYears = (
  startDate: Date,
  endDate: Date
): number => {
  const a = getDefaultDatejs(startDate);
  const b = getDefaultDatejs(endDate);
  const years = a.diff(b, 'year');
  return years;
};

export function calculateAge(date: string): number {
  return getDateDifferenceInYears(new Date(), new Date(date));
}

export function getDefaultDate(date: Date): Date {
  const d = Dayjs.default(date).toDate();
  return d;
}

export function getDefaultDatejs(date: Date): dayjs.Dayjs {
  return Dayjs.default(date);
}

export function subtractYears(date: Date, years: number): Date {
  return dayjs(date).subtract(years, 'years').toDate();
}

export function addYears(date: Date, years: number): Date {
  return dayjs(date).add(years, 'years').toDate();
}

export function addDays(date: Date, days: number): Date {
  return dayjs(date).add(days, 'days').toDate();
}

export function isValidDate(date: string | Date): boolean {
  return dayjs(date).isValid();
}

export function getTimeDifference(date: Date) {
  dayjs.extend(relativeTime);
  return dayjs(date, 'YYYYMMDD').fromNow();
}

export function AddMonths(date: Date, months: number): Date {
  return dayjs(date).add(months, 'months').toDate();
}

export function subtractMonths(date: Date, months: number): Date {
  return dayjs(date).subtract(months, 'months').toDate();
}

export function isDateBefore(date: Date, dateToCompare: Date): boolean {
  return dayjs(date).isBefore(dateToCompare);
}

export type DayjsDate = dayjs.Dayjs;
