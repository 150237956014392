import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Insurance.queries.listDetailsExpatPolicy.items.forEach(
    (obj: IGenericPolicyTableData, i: number) => {
      list[obj.insurance_Policy_Id] = {
        id: obj.insurance_Policy_Id,
        insurance_Policy_FullName: obj.insurance_Policy_FullName,
        insurance_Policy_FullPolicyNumber:
          obj.insurance_Policy_FullPolicyNumber,
        insurance_Policy_LineOfBusiness:
          obj.insurance_Policy_LineOfBusiness.Title,
        insuranceAgency_AgencyName:
          obj.insurance_Policy_AssignedAgencyID?.views?.Insurance_all
            ?.properties?.AgencyName,
        insuranceAgent_FirstName:
          obj?.insurance_Policy_AssignedAgentID?.views?.Insurance_all
            ?.properties?.FirstName +
          ' ' +
          obj?.insurance_Policy_AssignedAgentID?.views?.Insurance_all
            ?.properties?.LastName,
        insurance_Policy_TotalAnnualPremium:
          obj.insurance_Policy_TotalAnnualPremium,
        insurance_Policy_PolicyStatus: obj.insurance_Policy_PolicyStatus.Title,
        insurance_Policy_PolicyEffectiveDate:
          obj.insurance_Policy_PolicyEffectiveDate,
        insurance_Policy_PolicyExpiryDate:
          obj.insurance_Policy_PolicyExpiryDate,
        insurance_Policy_PolicyIssueDate: obj.insurance_Policy_PolicyIssueDate,
        insurance_Policy_createdOn: obj.insurance_Policy_createdOn,
        insurance_Policy_modifiedOn: obj.insurance_Policy_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Insurance.queries.listDetailsExpatPolicy?.paging?.pageSize,
    pageNumber:
      data.Insurance.queries.listDetailsExpatPolicy?.paging?.pageNumber,
    totalCount:
      data.Insurance.queries.listDetailsExpatPolicy?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    agency: {},
  };
  data?.Insurance?.queries?.allAgencies?.forEach(
    (obj: {
      insurance_Agency_Id: string;
      insurance_Agency_AgencyName: string;
    }) => {
      lovs.agency[obj.insurance_Agency_Id] = obj.insurance_Agency_AgencyName;
    }
  );

  return lovs;
}
