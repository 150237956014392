import _ from 'lodash';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import EnhancedChipInput from '../../../components/enhanced-form/EnhancedChipInput';
import EnhancedInput from '../../../components/enhanced-form/EnhancedInput';
import { IProposalPageFormState } from '../../../modules/production/proposal/page/generic/form';

interface IProposalDescriptionOfRiskWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  status: string;
  lineExternalCode: string;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  section: {
    padding: '23px 0 25px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 48%)`,
    gap: '4%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const ProposalDescriptionOfRiskWidget: React.FC<
  IProposalDescriptionOfRiskWidgetProps
> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  lovs,
  status,
  lineExternalCode,
}) => {
  const { classes } = useStyles();

  const values = pageState.values.descriptionOfRiskDetails;
  const errors = pageState.errors.descriptionOfRiskDetails;
  const touched = pageState.touched.descriptionOfRiskDetails;

  const onFieldBlur = (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.descriptionOfRiskDetails[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof typeof values,
    value: any,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);
    (newPageState.values.descriptionOfRiskDetails as any)[fieldName] = value;

    if (touched) {
      newPageState.touched.descriptionOfRiskDetails[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Description of Risk"
        hasTitleSpecificDesign={false}
        classes={{
          container: classes.section,
        }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <EnhancedInput
            key="descriptionOfRisk"
            name="descriptionOfRisk"
            title="Description of Risk"
            placeholder="Description of Risk"
            value={values.descriptionOfRisk}
            error={touched.descriptionOfRisk ? errors.descriptionOfRisk : ''}
            onBlur={() => onFieldBlur('descriptionOfRisk')}
            onChange={(v) => onFieldUpdate('descriptionOfRisk', v.target.value)}
            disabled={
              disabledForm ||
              status === 'CLOSED_WON' ||
              status === 'CLOSED_LOST'
            }
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedChipInput
            key="geoLocation"
            name="geoLocation"
            title="Geographical Location*"
            placeholder="Geographical Location"
            value={values.geoLocation}
            error={touched.geoLocation ? errors.geoLocation : ''}
            onChange={(v) => onFieldUpdate('geoLocation', v, true)}
            disabled={
              disabledForm ||
              status === 'CLOSED_WON' ||
              status === 'CLOSED_LOST'
            }
            selectOptions={lovs.geoLocations}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            hidden={
              ![
                '11',
                '15',
                '16',
                '25',
                '27',
                '29',
                '30',
                '31',
                '37',
                '38',
                '39',
                '42',
                '46',
              ].includes(lineExternalCode)
            }
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalDescriptionOfRiskWidget;
