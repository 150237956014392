import React from 'react';
import { ITransactionPopUpShellProps } from '.';
import EnhancedModal from '../../components/common/generic-modal/EnhancedModal';
import TransactionPopUp from './TransactionPopUp';

const TransactionPopUpShell: React.FC<ITransactionPopUpShellProps> = ({
  transactionId,
  open,
  onSuccess,
  onClose,
}) => {
  return (
    <EnhancedModal
      open={open}
      blur={true}
      onClose={onClose}
      title={transactionId ? 'Modify Transaction' : 'Add New Transaction'}
    >
      <TransactionPopUp
        transactionId={transactionId}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </EnhancedModal>
  );
};

export default TransactionPopUpShell;
