import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query TransactionsList(
      $CreatedOnFromDate: Date
      $CreatedOnToDate: Date
      $DescendingField: Boolean
      $KeywordSearch: String
      $OrderByField: String
      $SelectedAccounts: [String]
      $SelectedCompany: String!
      $SelectedType: [String]
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      Accounting {
        queries {
          TransactionsList(
            CreatedOnFromDate: $CreatedOnFromDate
            CreatedOnToDate: $CreatedOnToDate
            DescendingField: $DescendingField
            KeywordSearch: $KeywordSearch
            SelectedType: $SelectedType
            OrderByField: $OrderByField
            SelectedAccounts: $SelectedAccounts
            SelectedCompany: $SelectedCompany
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            items {
              accounting_Transactions_Id
              accounting_Transactions_createdOn
              accounting_Transactions_TransactionNumber
              accounting_Transactions_ReferenceNumber
              accounting_Transactions_Description
              accounting_Transactions_TransactionDate
              accounting_Transactions_TransactionType {
                Code
                LongTitle
              }
            }
            paging {
              pageSize
              pageNumber
              totalCount
            }
          }
          GetAccountsOfCompany(SelectedCompanyID: $SelectedCompany) {
            accounting_ChartOfAccounts_Id
            accounting_ChartOfAccounts_AccountID
            accounting_ChartOfAccounts_AccountName
          }
        }
        lookups {
          accountCategory {
            Id
            Title
            Code
          }
          transactionType {
            LongTitle
            Code
          }
        }
      }
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
    }
  `;
}

export function GetAccountsOfCompany() {
  return gql`
    query GetAccountsOfCompany($SelectedCompanyID: String) {
      Accounting {
        queries {
          GetAccountsOfCompany(SelectedCompanyID: $SelectedCompanyID) {
            accounting_ChartOfAccounts_Id
            accounting_ChartOfAccounts_AccountID
            accounting_ChartOfAccounts_AccountName
          }
        }
      }
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
    }
  `;
}

export function GetTransactionsAuditTrails() {
  return gql`
    query GetTransactionsAuditTrails($id: String!) {
      Accounting {
        queries {
          GetTransactionsAuditTrails(TransactionID: $id) {
            accounting_AuditTrail_Id
            accounting_AuditTrail_Title
            accounting_AuditTrail_Message
            accounting_AuditTrail_createdOn
          }
        }
      }
    }
  `;
}
