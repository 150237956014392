import React, { useMemo } from 'react';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { IProductionDetailsSummary } from '..';

interface IDescriptionOfRiskWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionDescriptionOfRiskDetailsWidget: React.FC<
  IDescriptionOfRiskWidgetProps
> = ({ data }) => {
  const dynamicView = useMemo(
    (): IDynamicDiplayView => ({
      name: 'DescriptionOfRiskDetails',
      sections: [
        {
          name: 'RiskDetails',
          title: 'Risk Details',
          properties: {
            descriptionOfRisk: {
              multiline: true,
              value: data?.DescriptionOfRisk || '',
              name: 'descriptionOfRisk',
              title: 'Description of Risk',
            },
            geoLocation: {
              multiline: false,
              value: data?.GeoLocation?.Title || '',
              name: 'geoLocation',
              title: 'Geographical Location',
              hidden: ![
                '11',
                '15',
                '16',
                '25',
                '27',
                '29',
                '30',
                '31',
                '37',
                '38',
                '39',
                '42',
                '46',
              ].includes(data?.LineId?.ExternalCode),
            },
          },
        },
      ],
    }),
    [data]
  );

  return <DynamicWidget hasFourFields={true} view={dynamicView} />;
};

export default ProductionDescriptionOfRiskDetailsWidget;
