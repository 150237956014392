import {
  EnhancedUpdatedTableHeader,
  EnhancedUpdatedTableHeaderType,
} from '../../../../components/updated-enhanced-table';

export const headers: Record<string, EnhancedUpdatedTableHeader> = {
  PolicyNumber: {
    name: 'PolicyNumber',
    title: 'Policy #',
    type: EnhancedUpdatedTableHeaderType.Link,
    urlTemplate: '/production/policies/{Id}',
    openUrlInNewTab: false,
  },
  TotalPremium: {
    name: 'TotalPremium',
    title: 'Total Premium',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Received',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  OutstandingBalanceReceivable: {
    name: 'OutstandingBalanceReceivable',
    title: 'Outstanding Receivable',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  TotalCommission: {
    name: 'TotalCommission',
    title: 'Commission',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  TaxOnCommission: {
    name: 'TaxOnCommission',
    title: 'Tax On Commission',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  AmountPaid: {
    name: 'AmountPaid',
    title: 'Amount Paid',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  OutstandingBalancePayable: {
    name: 'OutstandingBalancePayable',
    title: 'Outstanding Payable',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  OutstandingPayable: {
    name: 'OutstandingPayable',
    title: 'Total Balance',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
};

export const receivableBillsHeaders: Record<
  string,
  EnhancedUpdatedTableHeader
> = {
  BillNumber: {
    name: 'BillNumber',
    title: 'Bill #',
    type: EnhancedUpdatedTableHeaderType.Text,
  },
  DueDate: {
    name: 'DueDate',
    title: 'Due Date',
    type: EnhancedUpdatedTableHeaderType.Date,
  },
  NetPremium: {
    name: 'NetPremium',
    title: 'Net Premium',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  TotalPremium: {
    name: 'TotalPremium',
    title: 'Total Premium',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Received',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  OutstandingBalance: {
    name: 'OutstandingBalance',
    title: 'Outstanding Amount',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  BillStatus: {
    name: 'BillStatus',
    title: 'Status',
    type: EnhancedUpdatedTableHeaderType.Text,
  },
};

export const payableBillsHeaders: Record<string, EnhancedUpdatedTableHeader> = {
  BillNumber: {
    name: 'BillNumber',
    title: 'Bill #',
    type: EnhancedUpdatedTableHeaderType.Text,
  },
  DueDate: {
    name: 'DueDate',
    title: 'Due Date',
    type: EnhancedUpdatedTableHeaderType.Date,
  },
  Discount: {
    name: 'Discount',
    title: 'Discount',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  TotalCommission: {
    name: 'TotalCommission',
    title: 'Commission',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Paid',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  OutstandingBalance: {
    name: 'OutstandingBalance',
    title: 'Outstanding Amount',
    type: EnhancedUpdatedTableHeaderType.Currency,
  },
  BillStatus: {
    name: 'BillStatus',
    title: 'Status',
    type: EnhancedUpdatedTableHeaderType.Text,
  },
};

export const MOCK_DATA = [
  {
    policy_Id: 1,
    policy_PolicyName: 'test1',
    receivableBills_TotalPremium: 10,
    receivableBills_OutstandingBalance: 100,
    receivableBills_TotalCommission: 1000,
    receivableBills_TaxOnCommission: 10000,
    payableBills_OutstandingBalance: 100000,
  },
  {
    policy_Id: 2,
    policy_PolicyName: 'test2',
    receivableBills_TotalPremium: 20,
    receivableBills_OutstandingBalance: 200,
    receivableBills_TotalCommission: 2000,
    receivableBills_TaxOnCommission: 20000,
    payableBills_OutstandingBalance: 200000,
  },
  // ... existing records ...
  {
    policy_Id: 3,
    policy_PolicyName: 'test3',
    receivableBills_TotalPremium: 30,
    receivableBills_OutstandingBalance: 300,
    receivableBills_TotalCommission: 3000,
    receivableBills_TaxOnCommission: 30000,
    payableBills_OutstandingBalance: 300000,
  },
  {
    policy_Id: 4,
    policy_PolicyName: 'test4',
    receivableBills_TotalPremium: 40,
    receivableBills_OutstandingBalance: 400,
    receivableBills_TotalCommission: 4000,
    receivableBills_TaxOnCommission: 40000,
    payableBills_OutstandingBalance: 400000,
  },
  {
    policy_Id: 5,
    policy_PolicyName: 'test5',
    receivableBills_TotalPremium: 50,
    receivableBills_OutstandingBalance: 500,
    receivableBills_TotalCommission: 5000,
    receivableBills_TaxOnCommission: 50000,
    payableBills_OutstandingBalance: 500000,
  },
  {
    policy_Id: 6,
    policy_PolicyName: 'test6',
    receivableBills_TotalPremium: 60,
    receivableBills_OutstandingBalance: 600,
    receivableBills_TotalCommission: 6000,
    receivableBills_TaxOnCommission: 60000,
    payableBills_OutstandingBalance: 600000,
  },
  {
    policy_Id: 7,
    policy_PolicyName: 'test7',
    receivableBills_TotalPremium: 70,
    receivableBills_OutstandingBalance: 700,
    receivableBills_TotalCommission: 7000,
    receivableBills_TaxOnCommission: 70000,
    payableBills_OutstandingBalance: 700000,
  },
  {
    policy_Id: 8,
    policy_PolicyName: 'test8',
    receivableBills_TotalPremium: 80,
    receivableBills_OutstandingBalance: 800,
    receivableBills_TotalCommission: 8000,
    receivableBills_TaxOnCommission: 80000,
    payableBills_OutstandingBalance: 800000,
  },
  {
    policy_Id: 9,
    policy_PolicyName: 'test9',
    receivableBills_TotalPremium: 90,
    receivableBills_OutstandingBalance: 900,
    receivableBills_TotalCommission: 9000,
    receivableBills_TaxOnCommission: 90000,
    payableBills_OutstandingBalance: 900000,
  },
  {
    policy_Id: 10,
    policy_PolicyName: 'test10',
    receivableBills_TotalPremium: 100,
    receivableBills_OutstandingBalance: 1000,
    receivableBills_TotalCommission: 10000,
    receivableBills_TaxOnCommission: 100000,
    payableBills_OutstandingBalance: 1000000,
  },
  {
    policy_Id: 11,
    policy_PolicyName: 'test11',
    receivableBills_TotalPremium: 110,
    receivableBills_OutstandingBalance: 1100,
    receivableBills_TotalCommission: 11000,
    receivableBills_TaxOnCommission: 110000,
    payableBills_OutstandingBalance: 1100000,
  },
  {
    policy_Id: 12,
    policy_PolicyName: 'test12',
    receivableBills_TotalPremium: 120,
    receivableBills_OutstandingBalance: 1200,
    receivableBills_TotalCommission: 12000,
    receivableBills_TaxOnCommission: 120000,
    payableBills_OutstandingBalance: 1200000,
  },
  {
    policy_Id: 13,
    policy_PolicyName: 'test13',
    receivableBills_TotalPremium: 130,
    receivableBills_OutstandingBalance: 1300,
    receivableBills_TotalCommission: 13000,
    receivableBills_TaxOnCommission: 130000,
    payableBills_OutstandingBalance: 1300000,
  },
  {
    policy_Id: 14,
    policy_PolicyName: 'test14',
    receivableBills_TotalPremium: 140,
    receivableBills_OutstandingBalance: 1400,
    receivableBills_TotalCommission: 14000,
    receivableBills_TaxOnCommission: 140000,
    payableBills_OutstandingBalance: 1400000,
  },
  {
    policy_Id: 15,
    policy_PolicyName: 'test15',
    receivableBills_TotalPremium: 150,
    receivableBills_OutstandingBalance: 1500,
    receivableBills_TotalCommission: 15000,
    receivableBills_TaxOnCommission: 150000,
    payableBills_OutstandingBalance: 1500000,
  },
  {
    policy_Id: 16,
    policy_PolicyName: 'test16',
    receivableBills_TotalPremium: 160,
    receivableBills_OutstandingBalance: 1600,
    receivableBills_TotalCommission: 16000,
    receivableBills_TaxOnCommission: 160000,
    payableBills_OutstandingBalance: 1600000,
  },
  {
    policy_Id: 17,
    policy_PolicyName: 'test17',
    receivableBills_TotalPremium: 170,
    receivableBills_OutstandingBalance: 1700,
    receivableBills_TotalCommission: 17000,
    receivableBills_TaxOnCommission: 170000,
    payableBills_OutstandingBalance: 1700000,
  },
  {
    policy_Id: 18,
    policy_PolicyName: 'test18',
    receivableBills_TotalPremium: 180,
    receivableBills_OutstandingBalance: 1800,
    receivableBills_TotalCommission: 18000,
    receivableBills_TaxOnCommission: 180000,
    payableBills_OutstandingBalance: 1800000,
  },
  {
    policy_Id: 19,
    policy_PolicyName: 'test19',
    receivableBills_TotalPremium: 190,
    receivableBills_OutstandingBalance: 1900,
    receivableBills_TotalCommission: 19000,
    receivableBills_TaxOnCommission: 190000,
    payableBills_OutstandingBalance: 1900000,
  },
  {
    policy_Id: 20,
    policy_PolicyName: 'test20',
    receivableBills_TotalPremium: 200,
    receivableBills_OutstandingBalance: 2000,
    receivableBills_TotalCommission: 20000,
    receivableBills_TaxOnCommission: 200000,
    payableBills_OutstandingBalance: 2000000,
  },
  {
    policy_Id: 21,
    policy_PolicyName: 'test21',
    receivableBills_TotalPremium: 210,
    receivableBills_OutstandingBalance: 2100,
    receivableBills_TotalCommission: 21000,
    receivableBills_TaxOnCommission: 210000,
    payableBills_OutstandingBalance: 2100000,
  },
  {
    policy_Id: 22,
    policy_PolicyName: 'test22',
    receivableBills_TotalPremium: 220,
    receivableBills_OutstandingBalance: 2200,
    receivableBills_TotalCommission: 22000,
    receivableBills_TaxOnCommission: 220000,
    payableBills_OutstandingBalance: 2200000,
  },
  {
    policy_Id: 23,
    policy_PolicyName: 'test23',
    receivableBills_TotalPremium: 230,
    receivableBills_OutstandingBalance: 2300,
    receivableBills_TotalCommission: 23000,
    receivableBills_TaxOnCommission: 230000,
    payableBills_OutstandingBalance: 2300000,
  },
  {
    policy_Id: 24,
    policy_PolicyName: 'test24',
    receivableBills_TotalPremium: 240,
    receivableBills_OutstandingBalance: 2400,
    receivableBills_TotalCommission: 24000,
    receivableBills_TaxOnCommission: 240000,
    payableBills_OutstandingBalance: 2400000,
  },
  {
    policy_Id: 25,
    policy_PolicyName: 'test25',
    receivableBills_TotalPremium: 250,
    receivableBills_OutstandingBalance: 2500,
    receivableBills_TotalCommission: 25000,
    receivableBills_TaxOnCommission: 250000,
    payableBills_OutstandingBalance: 2500000,
  },
];
