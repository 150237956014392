import { gql } from '@apollo/client';

export function getPersonDetailsInfo() {
  return gql`
    query getPersonDetailsInfo($id: String!) {
      SalesforceManagement {
        entities {
          person(id: $id) {
            views {
              SalesforceManagement_all {
                properties {
                  UserID {
                    id
                  }
                  PersonType
                  PersonCode
                  CompanyName
                  Title {
                    Id
                    Code
                    Title
                  }
                  FirstName
                  FirstName
                  MiddleName
                  LastName
                  FullName
                  DateOfBirth
                  PhoneDetails {
                    PhoneNumber
                    PhoneType
                  }
                  Email
                  createdBy
                  createdOn
                  modifiedBy
                  modifiedOn
                  Status
                  FatherName
                  MotherName
                  CountryOfBirth {
                    Id
                    Title
                  }
                  CityOfBirth
                  Surname
                  Initials
                  NationalityOne {
                    Id
                    Title
                  }
                  NationalityTwo {
                    Id
                    Title
                  }
                  NationalityID
                  Gender
                  MaritalStatus
                  PassportNumber
                  OccupationClass {
                    Id
                    Title
                  }
                  Profession
                  Employer
                  DateOfEmployment
                  Salary
                  Smoker
                  Weight
                  Height
                  DateOfDeath
                  DateOfDeathNotified
                  Comments
                  DateOfSelfCertificateReceived
                  DateOfSelfCertificateValidated
                  Category {
                    Id
                    Title
                  }
                  DateOfKYC
                  FinancialReference
                  IsTaxable
                  RelativeOf
                }
              }
            }
            queries {
              salesforceManagement {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getPersonDetailsAddressList() {
  return gql`
    query getPersonDetailsAddressList(
      $selectedPersonID: String
      $currentPage: Int!
      $currentPageSize: Int!
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      SalesforceManagement {
        queries {
          getPersonAddressList(
            selectedPersonID: $selectedPersonID
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_PersonAddress_Id
              salesforceManagement_PersonAddress_createdOn
              salesforceManagement_PersonAddress_createdBy
              salesforceManagement_PersonAddress_modifiedOn
              salesforceManagement_PersonAddress_modifiedBy
              salesforceManagement_PersonAddress_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                    }
                  }
                }
              }
              salesforceManagement_PersonAddress_AddressType
              salesforceManagement_PersonAddress_Address {
                Country {
                  Title
                }
                Zone {
                  Title
                }
                City
                Street
                Building
                AddressDetails
                AddressDetailsArabic
              }
              salesforceManagement_PersonAddress_IsPrimary
            }
          }
        }
      }
    }
  `;
}

export function activatePerson() {
  return gql`
    mutation activatePerson($entityId: String!) {
      salesforceManagement {
        entities {
          person {
            salesforceManagement {
              activatePerson(entityId: $entityId) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function deactivatePerson() {
  return gql`
    mutation deactivatePerson($entityId: String!) {
      salesforceManagement {
        entities {
          person {
            salesforceManagement {
              deactivatePerson(entityId: $entityId) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
