import { gql } from '@apollo/client';
export function getAccountingEnums() {
  return gql(/* GraphQL */ `
    query GetProductEnums {
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
    }
  `);
}

export function GetIncomeAccounts() {
  return gql(
    `query GetIncomeAccounts($SelectedCompanyID: String){
            Accounting {
                queries {
                GetIncomeAccounts(SelectedCompanyID: $SelectedCompanyID) {
                    accounting_ChartOfAccounts_Id,
                    accounting_ChartOfAccounts_AccountName,
                    accounting_ChartOfAccounts_IsSubAccount,
                    accounting_ChartOfAccounts_AccountID
                }
                }
            }
            }
        `
  );
}

export function GetExpenseAccounts() {
  return gql(
    `query GetExpenseAccounts($SelectedCompanyID: String){
            Accounting {
                queries {
                GetExpenseAccounts(SelectedCompanyID: $SelectedCompanyID) {
                    accounting_ChartOfAccounts_Id,
                    accounting_ChartOfAccounts_AccountName,
                    accounting_ChartOfAccounts_IsSubAccount,
                    accounting_ChartOfAccounts_AccountID
                }
                }
            }
            }
        `
  );
}

export function validateName() {
  return gql(
    `query validateProductName($Name: String!, $ProductCompany: String) {
            Accounting {
                queries {
                EnteredProductNameExists(EnteredProductName : $Name, ProductCompany: $ProductCompany) {
                    accounting_Product_Id
                }
                }
            }
            }
        `
  );
}

export function validateNameOnUpdate() {
  return gql(
    `query validateProductNameOnUpdate($Name: String!, $ProductID: String!, $ProductCompany: String) {
            Accounting {
                queries {
                UpdatedProductNameExists(EnteredProductName: $Name, ProductID: $ProductID, ProductCompany: $ProductCompany) {
                    accounting_Product_Id
                }
                }
            }
            }
        `
  );
}

export function getProductInfo() {
  return gql(/* GraphQL */ `
    query GetProductInfo($id: String!) {
      Accounting {
        entities {
          product(id: $id) {
            id
            views {
              Accounting_all {
                properties {
                  ProductName
                  ProductDescription
                  ProductPrice
                  BuySell
                  RelatedIncomeAccount {
                    id
                  }
                  RelatedExpenseAccount {
                    id
                  }
                  RelatedCompany {
                    Id
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
}

export function createProduct() {
  return gql`
    mutation CreateProduct(
      $EnteredProductDescription: String
      $EnteredProductName: String!
      $EnteredProductPrice: Decimal
      $ProductBuy: Boolean!
      $ProductSell: Boolean!
      $RelatedExpenseAccount: String
      $RelatedIncomeAccount: String
      $SelectedCompany: String!
    ) {
      accounting {
        actions {
          createProduct(
            EnteredProductDescription: $EnteredProductDescription
            EnteredProductName: $EnteredProductName
            EnteredProductPrice: $EnteredProductPrice
            ProductBuy: $ProductBuy
            ProductSell: $ProductSell
            RelatedExpenseAccount: $RelatedExpenseAccount
            RelatedIncomeAccount: $RelatedIncomeAccount
            SelectedCompany: $SelectedCompany
          ) {
            id
          }
        }
      }
    }
  `;
}

export function updateProduct() {
  return gql`
    mutation UpdateProduct(
      $EnteredProductDescription: String
      $EnteredProductName: String!
      $EnteredProductPrice: Decimal
      $CurrentProductID: String!
    ) {
      accounting {
        actions {
          modifyProduct(
            EnteredProductDescription: $EnteredProductDescription
            EnteredProductName: $EnteredProductName
            EnteredProductPrice: $EnteredProductPrice
            CurrentProductID: $CurrentProductID
          )
        }
      }
    }
  `;
}
