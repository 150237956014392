import React, { useMemo } from 'react';
import { getBenefieryView, getInsuredView, getPayerView } from '.';
import { IProductionDetailsSummary } from '..';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';

const PersonsWidget: React.FC<{ data: IProductionDetailsSummary }> = ({
  data,
}) => {
  const elements = useMemo(() => {
    const items = [];

    const hasPayer = data?.Persons?.payer?.name;
    const hasOwner = data?.Persons?.owner?.name;
    const hasInsured = data?.Persons?.insured?.length > 0;
    const hasBeneficiary = data?.Persons?.beneficiaries?.length > 0;

    if (hasPayer || hasOwner) {
      items.push(
        <DynamicWidget
          key="payer"
          hasFourFields
          collapsibleSectionTitle="Payer"
          view={getPayerView(data.Persons.payer, data.Persons.owner)}
        />
      );
    }

    if (hasInsured) {
      if (items.length > 0)
        items.push(<div key="spacer-insured" style={{ height: '1em' }} />);
      items.push(
        <DynamicWidget
          key="insured"
          hasFourFields
          collapsibleSectionTitle="Insured"
          view={getInsuredView(
            data.Persons.insured,
            data?.LineId?.ExternalCode
          )}
        />
      );
    }

    if (hasBeneficiary) {
      if (items.length > 0)
        items.push(<div key="spacer-beneficiary" style={{ height: '1em' }} />);
      items.push(
        <DynamicWidget
          key="Beneficiary"
          hasFourFields
          collapsibleSectionTitle="Beneficiary"
          view={getBenefieryView(data.Persons.beneficiaries)}
        />
      );
    }

    return items;
  }, [data]);

  return elements.length > 0 ? <>{elements}</> : null;
};

export default PersonsWidget;
