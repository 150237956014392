import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { IAbstractRecord } from '../../models';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  company: '',
  type: '',
  name: '',
  id: '',
  isSubAccount: '',
  parentAccount: '',
  currency: '',
  description: '',
};

export const inputs = (
  mode: 'add' | 'edit' = 'add',
  values: IAbstractRecord = initialValues,
  lovs: Record<string, Record<string, string>>,
  layer2: Record<string, string>,
  layer3: Record<string, string>,
  layer4: Record<string, string>
): Record<string, DynamicFormInputType> => ({
  company: {
    name: 'company',
    placeholder: 'Select Company',
    title: 'Company',
    type: FormInputTypes.newselect,
    disabled: mode === 'edit',
    value: !isEmpty(values.company) ? values.company : [],
    selectOptions: lovs?.relatedCompanies,
    required: true,
  },
  subCategory: {
    name: 'subCategory',
    placeholder: 'Select Ledger',
    title: 'Ledger',
    disabled: mode === 'edit',
    type: FormInputTypes.newselect,
    value: !isEmpty(values.subCategory) ? values.subCategory : [],
    selectOptions: layer4,
    required: true,
  },
  subCategoryCode: {
    name: 'subCategoryCode',
    title: 'subCategoryCode',
    type: FormInputTypes.newtext,
    hidden: true,
    value: values.subCategoryCode,
  },
  id: {
    name: 'id',
    placeholder: 'Enter Account ID',
    title: 'Account ID',
    disabled: mode === 'edit',
    type: FormInputTypes.newsplitprefix,
    value: values.id,
    required: true,
    inputType: 'number',
    prefixValue: values.subCategoryCode,
  },
  name: {
    name: 'name',
    placeholder: 'Enter Account Name',
    title: 'Account Name',
    type: FormInputTypes.newtext,
    value: values.name,
    required: true,
    conditionalDisable(values) {
      return values.accountIdExists;
    },
  },
  currency: {
    name: 'currency',
    placeholder: 'Select Currency',
    title: 'Account Currency',
    type: FormInputTypes.newselect,
    value: !isEmpty(values.currency) ? values.currency : [],
    required: true,
    disabled: mode === 'edit',
    selectOptions: lovs?.currency,
  },
});
