import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery } from './queries';
import { mapToListingData } from './utils';
import { actions, filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import AgencyDrawer from '../../forms/agency-drawer/AgencyDrawer';
import { IEnhanceTableHeaderClickable } from '../../components/enhanced-table';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';

const AgenciesPage: React.FC = () => {
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [agencyDrawerOpen, setAgencyDrawerOpen] = useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');

  const navigate = useNavigate();

  const FILTER_SESSION_KEY = 'agenciesFilter';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          status: ['ACTIVE'],
          type: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [listQueryLazy] = useLazyQuery(listQuery());

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoading(true);
    try {
      const result = await listQueryLazy({
        variables: {
          currentPage: currentPage + 1,
          currentPageSize: pageSize,
          agencyStatus:
            filterV?.status && filterV?.status?.length > 0
              ? filterV.status
              : null,
          agencyType:
            filterV?.type && filterV?.type?.length > 0 ? filterV.type : null,
        },
        fetchPolicy: 'no-cache',
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;

      if (data) {
        const mappedData = mapToListingData(data);
        setTableData({ ...mappedData, pageNumber: currentPage });

        const savedFilters = getFilter(FILTER_SESSION_KEY) || filterV;
        setFilterValues(savedFilters);
        setFilter(savedFilters, FILTER_SESSION_KEY);

        const newFilterSections = filterSectionsContent(data, savedFilters);
        setFilterSections(newFilterSections);
      }
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, tableData.pageSize, savedFilters);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add open and close function for action button
  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setAgencyDrawerOpen(true);
  };

  (
    headers.insurance_Agency_AgencyName as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate('/business/partners/' + payload.columns.insurance_Agency_Id);
  };

  const handlePageChange = (page: number) => {
    loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = (numberOfRecordsPerPage: number) => {
    loadData(0, numberOfRecordsPerPage, filterValues);
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    if (_.isEqual(newFilters, filterValues)) {
      return;
    }
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    await loadData(0, tableData.pageSize, newFilters);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: '20px' }}>
      <EnhancedTable
        title="Business Partners"
        name="count"
        orderByAscendingByDefault
        defaultOrderByColumn="name"
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        currentPage={tableData.pageNumber}
        hideToolbar
        usePagination
        disableSelection
        actions={actions}
        loader={loading}
      />
      {agencyDrawerOpen && (
        <AgencyDrawer
          open={agencyDrawerOpen}
          onClose={() => setAgencyDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
            setAgencyDrawerOpen(false);
          }}
          agencyId={chosenEditId}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name={'Business Partners Filter'}
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={'Business Partners'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default AgenciesPage;
