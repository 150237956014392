import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { valueCommaSeparated } from '../../utils/formatting-utils';
import { lookupListAsRecordObject } from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Core.queries.getCurrencyExchangeRateList.items.forEach(
    (obj: IGenericCurrencyExchangeRatesTableData, i: number) => {
      list[i] = {
        core_CurrencyExchangeRate_Id: obj.core_CurrencyExchangeRate_Id,
        code: obj.core_CurrencyExchangeRate_CurrencyID.Code,
        currency: obj.core_CurrencyExchangeRate_CurrencyID.Title,
        rate: valueCommaSeparated(obj.core_CurrencyExchangeRate_Rate),

        effectiveFrom: obj.core_CurrencyExchangeRate_EffectiveFrom,
        effectiveTo: obj.core_CurrencyExchangeRate_EffectiveTo,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Core.queries.getCurrencyExchangeRateList?.paging?.pageSize,
    pageNumber:
      data.Core.queries.getCurrencyExchangeRateList?.paging?.pageNumber,
    totalCount:
      data.Core.queries.getCurrencyExchangeRateList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    currency: {},
  };

  lovs.currency = lookupListAsRecordObject(data?.Core?.lookups?.currencies);

  return lovs;
}
