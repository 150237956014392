import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  policyCover: '',
  currency: '',
  sumInsured: '',
  premiumType: 'VALUE',
  premiumPercentage: '',
  premium: '',
  additiveSumInsured: false,
  main: false,
  mandatory: false,
  editable: false,
  excessOnClaimType: 'PERCENTAGE',
  excessOnClaim: '',
  excessOnClaimPercentage: '',
  excessOnClaimDays: '',
  clauseReference: '',
};

export const getInputs = (
  values: typeof initialValues = initialValues,
  lovs: Record<string, Record<string, any>> = {},
  planLineExternalCode: string
): Record<string, DynamicFormInputType> => ({
  policyCover: {
    name: 'policyCover',
    title: 'Policy Cover',
    type: FormInputTypes.chips,
    placeholder: 'Policy Cover',
    value: !isEmpty(values.policyCover) ? values.policyCover : '',
    multiple: false,
    disabled: false,
    required: true,
    // hasPagination: true,
    // itemsPerPage: 15,
    order: 1,
    selectOptions: lovs?.policyCover || {},
  },
  currency: {
    name: 'currency',
    title: 'Currency',
    type: FormInputTypes.text,
    placeholder: 'Currency',
    value: !isEmpty(values.currency) ? values.currency : '',
    disabled: true,
    required: true,
    hidden: false,
    order: 2,
  },
  premiumType: {
    name: 'premiumType',
    title: 'Premium Type',
    placeholder: 'Premium Type',
    type: FormInputTypes.chips,
    value: !isEmpty(values.premiumType) ? values.premiumType : '',
    hidden: planLineExternalCode !== '51',
    required: planLineExternalCode === '51',
    multiple: false,
    order: 3,
    selectOptions: lovs?.premiumType || {},
  },
  premiumPercentage: {
    name: 'premiumPercentage',
    title: 'Premium Percentage',
    placeholder: 'Premium Percentage',
    type: FormInputTypes.percentage,
    value: values.premiumPercentage,
    required: values.premiumType?.toLowerCase() === 'percentage',
    hidden: values.premiumType?.toLowerCase() !== 'percentage',
    order: 4,
  },
  premium: {
    name: 'premium',
    title: 'Premium',
    placeholder: 'Premium',
    type: FormInputTypes.formattedNumber,
    value: values.premium,
    required: values.premiumType?.toLowerCase() === 'value',
    hidden: values.premiumType?.toLowerCase() !== 'value',
    minValue: 0,
    order: 5,
  },
  sumInsured: {
    name: 'sumInsured',
    title: 'Sum Insured',
    placeholder: 'Sum Insured',
    type: FormInputTypes.formattedNumber,
    value: values.sumInsured,
    required: true,
    minValue: 0,
    order: 6,
  },
  additiveSumInsured: {
    name: 'additiveSumInsured',
    title: 'Additive Sum Insured',
    placeholder: 'Additive Sum Insured',
    type: FormInputTypes.switch,
    value: values.additiveSumInsured,
    required: true,
    order: 7,
  },
  main: {
    name: 'main',
    title: 'Main',
    placeholder: 'Main',
    type: FormInputTypes.switch,
    value: values.main,
    required: true,
    order: 8,
  },
  mandatory: {
    name: 'mandatory',
    title: 'Mandatory',
    placeholder: 'Mandatory',
    type: FormInputTypes.switch,
    value: values.mandatory,
    required: true,
    order: 9,
  },
  editable: {
    name: 'editable',
    title: 'Editable',
    placeholder: 'Editable',
    type: FormInputTypes.switch,
    value: values.editable,
    required: true,
    order: 10,
  },
  excessOnClaimType: {
    name: 'excessOnClaimType',
    title: 'Excess On Claim Type',
    placeholder: 'Excess On Claim Type',
    type: FormInputTypes.chips,
    value: !isEmpty(values.excessOnClaimType) ? values.excessOnClaimType : '',
    required: true,
    multiple: false,
    order: 11,
    selectOptions: lovs?.excessOnClaimType || {},
  },
  excessOnClaim: {
    name: 'excessOnClaim',
    title: 'Excess On Claim',
    placeholder: 'Excess On Claim',
    type: FormInputTypes.formattedNumber,
    value: values.excessOnClaim,
    required: values.excessOnClaimType?.toLowerCase() === 'value',
    hidden: values.excessOnClaimType?.toLowerCase() !== 'value',
    minValue: 0,
    order: 12,
  },
  excessOnClaimPercentage: {
    name: 'excessOnClaimPercentage',
    title: 'Excess On Claim Percentage',
    placeholder: 'Excess On Claim (Percentage)',
    type: FormInputTypes.percentage,
    value: values.excessOnClaimPercentage,
    required: values.excessOnClaimType?.toLowerCase() === 'percentage',
    hidden: values.excessOnClaimType?.toLowerCase() !== 'percentage',
    order: 13,
  },
  excessOnClaimDays: {
    name: 'excessOnClaimDays',
    title: 'Excess On Claim Days',
    placeholder: 'Excess On Claim (Days)',
    type: FormInputTypes.number,
    value: values.excessOnClaimDays,
    required: values.excessOnClaimType?.toLowerCase() === 'days',
    hidden: values.excessOnClaimType?.toLowerCase() !== 'days',
    minNumber: 0,
    order: 14,
  },
  clauseReference: {
    name: 'clauseReference',
    title: 'Clause Reference',
    placeholder: 'Clause Reference',
    type: FormInputTypes.chips,
    value: values.clauseReference,
    required: false,
    hasPagination: false,
    itemsPerPage: 15,
    multiple: false,
    order: 15,
    selectOptions: lovs?.clauseReference || {},
  },
});
