import { ILineDetails } from '.';
import { EnhancedDisplayType } from '../../components/enhanced-form/EnhancedDisplay';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import { ITabsLayout, LayoutType } from '../../page-layout';

export const steps = ['Inactive', 'Active'];

export const getEntityView = (lineInfo: ILineDetails): IEntityInfoView => ({
  name: 'section1',
  title: 'Line Details',
  iconUrl: '',
  sections: [
    {
      name: 'lineDetailsSection1',
      title: '',
      properties: {
        externalCode: {
          value: lineInfo.externalCode,
          multiline: false,
          name: 'externalCode',
          title: 'External Code',
        },
        abbreviation: {
          value: lineInfo.abbreviation,
          multiline: false,
          name: 'abbreviation',
          title: 'Abbreviation',
        },
        arabicName: {
          value: lineInfo.arabicName,
          multiline: false,
          name: 'arabicName',
          title: 'Arabic Name',
        },
      },
    },
    {
      name: 'lineDetailsSection2',
      title: '',
      properties: {
        createdBy: {
          value: lineInfo.createdBy,
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        createdOn: {
          value: lineInfo.createdOn,
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
          type: EnhancedDisplayType.Date,
        },
        modifiedBy: {
          value: lineInfo.modifiedBy,
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
        modifiedOn: {
          value: lineInfo.modifiedOn,
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
          type: EnhancedDisplayType.Date,
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
});

export const lineDetailsTabs: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: '#F7F7F7',
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: 'sublinesandplans',
      title: 'Sublines & Plans',
      iconUrl: '',
      widgets: [{}],
    },
    {
      name: 'policyandclaimcovers',
      title: 'Policy & Claim Covers',
      iconUrl: '',
      widgets: [{}],
    },
    {
      name: 'clauses',
      title: 'Clauses',
      iconUrl: '',
      widgets: [{}],
    },
  ],
};

export const headers: Record<string, EnhancedTableHeader> = {
  planConfigManagement_Subline_Name: {
    name: 'planConfigManagement_Subline_Name',
    title: 'Subline Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  planConfigManagement_Subline_ExternalCode: {
    name: 'planConfigManagement_Subline_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Subline_NameArabic: {
    name: 'planConfigManagement_Subline_NameArabic',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Subline_Status: {
    name: 'planConfigManagement_Subline_Status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Subline_createdOn: {
    name: 'planConfigManagement_Subline_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Subline_modifiedOn: {
    name: 'planConfigManagement_Subline_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export const planHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_Plan_Name: {
    name: 'planConfigManagement_Plan_Name',
    title: 'Plan Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  planConfigManagement_Plan_SublineID: {
    name: 'planConfigManagement_Plan_SublineID',
    title: 'Subline Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_ExternalCode: {
    name: 'planConfigManagement_Plan_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_NameArabic: {
    name: 'planConfigManagement_Plan_NameArabic',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_EffectiveFrom: {
    name: 'planConfigManagement_Plan_EffectiveFrom',
    title: 'Effective From',
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Plan_EffectiveTo: {
    name: 'planConfigManagement_Plan_EffectiveTo',
    title: 'Effective To',
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Plan_PlanStatus: {
    name: 'planConfigManagement_Plan_PlanStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_createdOn: {
    name: 'planConfigManagement_Plan_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Plan_modifiedOn: {
    name: 'planConfigManagement_Plan_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export const claimCoverHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_ClaimCover_Name: {
    name: 'planConfigManagement_ClaimCover_Name',
    title: 'Claim Cover Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_ExternalCode: {
    name: 'planConfigManagement_ClaimCover_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_PolicyCoverID: {
    name: 'planConfigManagement_ClaimCover_PolicyCoverID',
    title: 'Policy Cover',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_NameArabic: {
    name: 'planConfigManagement_ClaimCover_NameArabic',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_PrintingOrder: {
    name: 'planConfigManagement_ClaimCover_PrintingOrder',
    title: 'Printing Order',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_ClaimCoverDescription: {
    name: 'planConfigManagement_ClaimCover_ClaimCoverDescription',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_ClaimCover_createdOn: {
    name: 'planConfigManagement_ClaimCover_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_ClaimCover_modifiedOn: {
    name: 'planConfigManagement_ClaimCover_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export const policyCoverHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_PolicyCover_Name: {
    name: 'planConfigManagement_PolicyCover_Name',
    title: 'Policy Cover Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  planConfigManagement_PolicyCover_ExternalCode: {
    name: 'planConfigManagement_PolicyCover_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_NameArabic: {
    name: 'planConfigManagement_PolicyCover_NameArabic',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_PrintingOrder: {
    name: 'planConfigManagement_PolicyCover_PrintingOrder',
    title: 'Printing Order',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_PolicyCoverDescription: {
    name: 'planConfigManagement_PolicyCover_PolicyCoverDescription',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_createdOn: {
    name: 'planConfigManagement_PolicyCover_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_PolicyCover_modifiedOn: {
    name: 'planConfigManagement_PolicyCover_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export const clauseHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_Clause_Name: {
    name: 'planConfigManagement_Clause_Name',
    title: 'Clause Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Clause_ExternalCode: {
    name: 'planConfigManagement_Clause_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Clause_Description: {
    name: 'planConfigManagement_Clause_Description',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Clause_Status: {
    name: 'planConfigManagement_Clause_Status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Clause_createdOn: {
    name: 'planConfigManagement_Clause_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Clause_modifiedOn: {
    name: 'planConfigManagement_Clause_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};
