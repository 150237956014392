import { CancelOutlined } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import React from 'react';
import { isEmpty } from '../../utils/validationUtils';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../constants';

export interface IGenericInputWrapperProps {
  description: string;
}

const useStyles = makeStyles()(() => ({
  tooltipBody: {
    backgroundColor: MAIN_ONE_THEME.palette.primary1.main,
    borderRadius: '5px',
  },
  iconFill: {
    fill: MAIN_ONE_THEME.palette.primary1.main,
  },
  tooltip: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 3px 6px #141414',
    border: '1px solid #EAEAEA',
    marginLeft: '4px',
    '& ::after': {
      position: 'absolute',
      content: ' ',
      top: '100%',
      left: '50%',
      marginLeft: '-8px',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '15px 13px 0 13px',
      borderColor: 'rgba(37, 36, 36, 0.9) transparent transparent transparent',
    },
  },
  tooltipIcon: {
    color: '#C7C7C7',
    width: '16px',
    height: '16px',
    verticalAlign: 'middle',
    cursor: 'pointer',
    marginLeft: '5px',
  },
}));

const EnhancedInputTooltip: React.FC<IGenericInputWrapperProps> = ({
  description,
}) => {
  const { classes } = useStyles();

  const renderTooltip = () => {
    if (!isEmpty(description)) {
      return (
        <Tooltip
          enterTouchDelay={25}
          disableFocusListener
          classes={{ tooltip: classes.tooltip }}
          title={<span> {description} </span>}
        >
          <CancelOutlined className={classes.tooltipIcon} />
        </Tooltip>
      );
    }
    return <></>;
  };

  return renderTooltip();
};

export default EnhancedInputTooltip;
