import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../constants';
import { OpenConfirmationAction } from '../../redux/confirmation/actions';
import { IConfirmation } from '../../redux/confirmation/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  capitalizeFirstLetter,
  formatNumber,
} from '../../utils/formatting-utils';
import { getError } from '../../utils/graph-utils';
import { isEmpty } from '../../utils/validationUtils';
import EnhancedButton, { EnhancedButtonStatus } from '../EnhancedButton';
import ToastErrorMessage from '../ToastErrorMessage';
import ToastSuccessMessage from '../ToastSuccessMessage';
import WidgetPaper from '../common/WidgetPaper';
import WidgetSection from '../common/WidgetSection';
import QuotesCardWidget, {
  IRow,
} from '../widgets/dynamic-cardview/QuotesCardWidget';
import { IAbstractRecord } from '../../models';
import { generateDownloadLink } from '../../utils/file-utils';
// import { Insurance_FileDownload } from '../../gql/__generated__/graphql';
import DataService from '../../services/dataService';

interface IQuotesCardsProps {
  applicationStatus: string;
  entityId: string;
  quotes: quote[];
  acceptAction?: (obj: any) => Promise<any>;
  sendQuoteAction?: (obj: any) => Promise<any>;
  issuePolicyAction?: (obj: any) => Promise<any>;
  downloadQuoteAction?: (obj: any) => Promise<any>;
  previewQuoteAction?: (obj: any) => Promise<any>;
  previewPoliciesAction?: (obj: any) => Promise<any>;
  customerEmail?: string;
  disabled?: boolean;
  userCanSend?: boolean;
  disabledSendButton?: boolean;
  disabledAcceptButton?: boolean;
  disabledIssuePolicyButton?: boolean;
  userCanAccept?: boolean;
  userCanIssue?: boolean;
  userCanDownloadQuote?: boolean;
  quoteInputs?: IAbstractRecord;
  lineOfBusiness?: string;
  userCanPreviewPolicies?: boolean;
  applicationCard?: boolean;
}

interface ILookupObject {
  Code: string;
  Title: string;
}
interface IAmount {
  amount: number;
  currencySymbol: string;
}
export interface quote {
  id: string;
  title: string;
  status: string;
  statusReason: string;
  createdOn: string;
  modifiedOn: string;
  hasCoverage: boolean;
  hasOnlyMainAmount?: boolean;
  coverageAmount: string;
  coverageAmountCurrencySymbol: string;
  premiumAmount: string;
  premiumAmountCurrencySymbol: string;
  carValue?: number;
  carValueCurrencySymbol?: string;
  vignetteCode?: string;
  vignette?: number;
  towingServiceTitle?: string;
  policyId?: string;
  fullPolicyNumber?: string;
  disabled?: boolean;
  product?: ILookupObject;
  standardInHospitalClass?: ILookupObject;
  optimumInHospitalClass?: ILookupObject;
  standardAmbulatoryCoverage?: ILookupObject;
  optimumAmbulatoryCoverage?: ILookupObject;
  InsuredAge?: string;
  InsuredGender?: string;
  InsuredNationality?: ILookupObject;
  insuredDetails?: { InsuredName: string }[];
  insuredPremiums?: {
    InsuredAge: number;
    InsuredInHospitalPremium: IAmount;
    InsuredAmbulatoryPremium: IAmount;
    InsuredTotalBasePremium: IAmount;
  }[];
  expatDetails?: {
    InsuredAge: string;
    InsuredGender: string;
    InsuredNationality: ILookupObject;
  };
  lineOfBusiness?: string;
}

const QuotesCards: React.FC<IQuotesCardsProps> = ({
  applicationStatus,
  entityId,
  acceptAction,
  sendQuoteAction,
  issuePolicyAction,
  downloadQuoteAction,
  previewQuoteAction,
  previewPoliciesAction,
  quotes,
  customerEmail,
  disabled,
  userCanSend,
  disabledSendButton,
  disabledAcceptButton,
  disabledIssuePolicyButton,
  userCanAccept,
  userCanIssue,
  userCanDownloadQuote,
  quoteInputs,
  lineOfBusiness = 'Motor',
  userCanPreviewPolicies,
  applicationCard = false,
}) => {
  const tenant = useAppSelector((state) => state.tenant);

  const dispatch = useAppDispatch();

  // key value pair, the key is the quote ID, and the value is boolean whether the quote is selected or not
  const [selectedQuotes, setSelectedQuotes] = useState<Record<string, boolean>>(
    {}
  );

  const [disabledAcceptBtn, setDisabledAcceptBtn] = useState<boolean>(true);

  const [disabledSendBtn, setDisabledSendBtn] = useState<boolean>(true);

  const [disabledIssuePolicyBtn, setDisabledIssuePolicyBtn] =
    useState<boolean>(true);

  const [disabledPreviewQuoteBtn, setDisabledPreviewQuoteBtn] =
    useState<boolean>(true);

  const [disabledPreviewPolicies, setDisabledPreviewPoliciesBtn] =
    useState<boolean>(true);

  const [acceptBtnState, setAcceptBtnState] = useState<EnhancedButtonStatus>();

  const [sendBtnState, setSendBtnState] = useState<EnhancedButtonStatus>();

  const [issueBtnState, setIssueBtnState] = useState<EnhancedButtonStatus>();

  const [previewQuoteBtnState, setPreviewQuoteBtnState] =
    useState<EnhancedButtonStatus>();

  const [PreviewPoliciesBtnState, setPreviewPoliciesBtnState] =
    useState<EnhancedButtonStatus>();

  const prevQuotes = useRef(quotes);

  useEffect(() => {
    const selectedPlans: Record<string, boolean> = {};
    if (
      disabled ||
      prevQuotes.current.length !== quotes.length ||
      prevQuotes.current.some(
        (prevQuote, index) => prevQuote.id !== quotes[index].id
      )
    ) {
      quotes?.forEach((quote, number: number) => {
        selectedPlans[quote.id] = false;
      });
      setSelectedQuotes(selectedPlans);
    }

    prevQuotes.current = quotes;
  }, [quotes]);

  useEffect(() => {
    if (
      selectedQuotes != undefined &&
      Object.keys(selectedQuotes).length >= 1
    ) {
      const validSelectedQuotes: Record<string, boolean> = {};
      Object.keys(selectedQuotes).forEach((quoteId: string) => {
        if (selectedQuotes[quoteId] == true) {
          quotes.forEach((plan, i: number) => {
            if (plan.id == quoteId) {
              validSelectedQuotes[plan.id] =
                plan.status.toLowerCase() != 'closed';
            }
          });
        }
      });

      const disabledAccept =
        Object.keys(validSelectedQuotes).length == 0 ||
        Object.values(validSelectedQuotes).indexOf(false) != -1 ||
        disabledAcceptButton;

      setDisabledAcceptBtn(disabledAccept);

      const disabledSend =
        Object.keys(validSelectedQuotes).length == 0 ||
        Object.values(validSelectedQuotes).indexOf(false) != -1 ||
        disabledSendButton;
      setDisabledSendBtn(disabledSend);

      const equivelantQuotes = Object.keys(validSelectedQuotes).filter(
        (key, i) => {
          const qoute = quotes.find((q) => q.id == key);
          return (
            qoute &&
            (qoute.product.Code == 'BodilyInjury' ||
              qoute.product.Code == 'MaterialDamage')
          );
        }
      );

      let disabledIssuePolicy =
        Object.keys(validSelectedQuotes).length == 0 ||
        Object.values(validSelectedQuotes).some((v) => !v);

      if (lineOfBusiness === 'Motor' && !disabledIssuePolicy) {
        if (
          equivelantQuotes.length != Object.keys(validSelectedQuotes).length
        ) {
          disabledIssuePolicy = true;
        }
      }
      setDisabledIssuePolicyBtn(disabledIssuePolicy);

      const disabledPreviewQuote =
        Object.keys(validSelectedQuotes).length == 0 ||
        Object.values(validSelectedQuotes).indexOf(false) != -1 ||
        Object.keys(validSelectedQuotes).length != 1;

      setDisabledPreviewQuoteBtn(disabledPreviewQuote);

      const disabledPreviewPolicies =
        lineOfBusiness == 'Motor'
          ? Object.keys(validSelectedQuotes).length == 0 ||
            Object.keys(validSelectedQuotes).length > 1 ||
            Object.values(validSelectedQuotes).indexOf(false) != -1 ||
            equivelantQuotes.length != Object.keys(validSelectedQuotes).length
          : Object.keys(validSelectedQuotes).length == 0 ||
            Object.keys(validSelectedQuotes).length > 1 ||
            Object.values(validSelectedQuotes).indexOf(false) != -1 ||
            Object.keys(validSelectedQuotes).length != 1;

      setDisabledPreviewPoliciesBtn(disabledPreviewPolicies);
    }
  }, [selectedQuotes]);

  useEffect(() => {
    if (
      disabledSendButton ||
      disabledAcceptButton ||
      disabledIssuePolicyButton
    ) {
      const qoutes = Object.fromEntries(
        Object.keys(selectedQuotes).map((key) => [key, false])
      );
      setSelectedQuotes(qoutes);
    }
  }, [disabledSendButton, disabledAcceptButton]);

  function handleCheckboxClick(id: string) {
    const updated = { ...selectedQuotes, [id]: !selectedQuotes[id] };
    setSelectedQuotes(updated);
  }

  function handleAcceptClicked(selectedQuotes: Record<string, boolean>) {
    const ids: string[] = [];
    Object.keys(selectedQuotes).forEach((quoteKey) => {
      if (selectedQuotes[quoteKey] == true) {
        ids.push(quoteKey);
      }
    });

    const variables = {
      entityId: entityId,
      quoteIds: ids,
    };

    const confirmation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: `Are you sure you want to accept the selected Quote(s)?`,
      callback: async () => {
        setAcceptBtnState('loading');
        acceptAction({
          variables: variables,
          errorPolicy: 'all',
        })
          .then((res) => {
            if (isEmpty(res.errors)) {
              setAcceptBtnState('success');
              toast.success(
                <ToastSuccessMessage>
                  {'Quote(s) successfully accepted'}
                </ToastSuccessMessage>
              );
              setTimeout(() => 500);
            } else {
              toast.error(
                <ToastErrorMessage>{getError(res)}</ToastErrorMessage>
              );
            }
          })
          .catch((err) => {
            toast.error(
              <ToastErrorMessage>
                {typeof err == 'string' ? err : DEFAULT_ERROR_TEXT}
              </ToastErrorMessage>
            );
          })
          .finally(() => {
            setTimeout(() => {
              setAcceptBtnState(undefined);
            }, 1000);
          });
      },
      submitButtonText: 'Yes',
      cancelButtonText: 'No',
    };
    dispatch(OpenConfirmationAction(confirmation));
  }

  function handleSendClickOpen(selectedQuotes: Record<string, boolean>) {
    const ids: string[] = [];
    Object.keys(selectedQuotes).forEach((quoteKey) => {
      if (selectedQuotes[quoteKey] == true) {
        ids.push(quoteKey);
      }
    });

    const variables = {
      entityId: entityId,
      quoteIds: ids,
      customerEmail: customerEmail,
    };

    const confirmation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: `Are you sure you want to send the selected Quote(s)?`,
      callback: async () => {
        setSendBtnState('loading');
        sendQuoteAction({
          variables: variables,
          errorPolicy: 'all',
        })
          .then((res) => {
            if (isEmpty(res.errors)) {
              setSendBtnState('success');

              toast.success(
                <ToastSuccessMessage>
                  {'Quote(s) successfully sent'}
                </ToastSuccessMessage>
              );
              setTimeout(() => 500);
            } else {
              toast.error(
                <ToastErrorMessage>{getError(res)}</ToastErrorMessage>
              );
            }
          })
          .catch((err) => {
            toast.error(
              <ToastErrorMessage>
                {typeof err == 'string' ? err : DEFAULT_ERROR_TEXT}
              </ToastErrorMessage>
            );
          })
          .finally(() => {
            setTimeout(() => {
              setSendBtnState(undefined);
            }, 1000);
          });
      },
      submitButtonText: 'Yes',
      cancelButtonText: 'No',
    };
    dispatch(OpenConfirmationAction(confirmation));
  }

  function handleIssuePolicyClicked(selectedQuotes: Record<string, boolean>) {
    const ids: string[] = [];
    Object.keys(selectedQuotes).forEach((quoteKey) => {
      if (selectedQuotes[quoteKey] == true) {
        ids.push(quoteKey);
      }
    });

    const variables = {
      entityId: entityId,
      quoteIds: ids,
    };

    const confirmation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: `Are you sure you want to issue the selected Policy(s)?`,
      callback: async () => {
        setIssueBtnState('loading');
        issuePolicyAction({
          variables: variables,
          errorPolicy: 'all',
        })
          .then((res) => {
            if (isEmpty(res.errors)) {
              setIssueBtnState('success');

              const listOfFiles =
                lineOfBusiness == 'Motor'
                  ? res?.data?.insurance?.entities?.application?.insurance
                      ?.issuePolicy?.Items
                  : res?.data?.insurance?.entities?.application?.insurance
                      ?.issueExpatPolicy?.Items;

              if (
                lineOfBusiness.toLowerCase() === 'expat' ||
                lineOfBusiness.toLowerCase() === 'motor'
              ) {
                toast.success(
                  <ToastSuccessMessage>
                    Policy issued successfully. Make sure to open latest version
                    downloaded.
                  </ToastSuccessMessage>
                );
              }

              if (listOfFiles) {
                listOfFiles.forEach(
                  async (fileElement: any) =>
                    await DataService.downloadAndOpenFileWithToken(
                      generateDownloadLink(
                        fileElement.File.id,
                        fileElement.EntityTypeId,
                        fileElement.EntityId,
                        fileElement.EntityViewId,
                        fileElement.PropertyId
                      )
                    )
                );
              }
            } else {
              toast.error(
                <ToastErrorMessage>{getError(res)}</ToastErrorMessage>
              );
            }
          })
          .catch((err) => {
            toast.error(
              <ToastErrorMessage>
                {typeof err == 'string' ? err : DEFAULT_ERROR_TEXT}
              </ToastErrorMessage>
            );
          })
          .finally(() => {
            setTimeout(() => {
              setIssueBtnState(undefined);
            }, 1000);
          });
      },
      submitButtonText: 'Yes',
      cancelButtonText: 'No',
    };
    dispatch(OpenConfirmationAction(confirmation));
  }

  function handlePreviewQuotesClicked(selectedQuotes: Record<string, boolean>) {
    const ids: string[] = [];
    Object.keys(selectedQuotes).forEach((quoteKey) => {
      if (selectedQuotes[quoteKey] == true) {
        ids.push(quoteKey);
      }
    });

    const variables = {
      entityId: entityId,
      quoteIds: ids,
      customerEmail: customerEmail,
      applicationContactInputs: quoteInputs[
        'applicationContactInputs'
      ] as Record<string, string>,
    };

    setPreviewQuoteBtnState('loading');
    downloadQuoteAction({
      variables: variables,
      errorPolicy: 'all',
    })
      .then(async (res) => {
        if (isEmpty(res.errors)) {
          setPreviewQuoteBtnState('success');
          const listOfFiles =
            res?.data?.insurance?.entities?.application?.insurance
              ?.previewQuotes?.Items;
          const fileElement = listOfFiles[0];

          await DataService.downloadAndOpenFileWithToken(
            generateDownloadLink(
              fileElement.id,
              'Insurance-Quote',
              ids[0],
              'Insurance-download',
              'QuoteDocument'
            )
          );
        } else {
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setPreviewQuoteBtnState(undefined);
        }, 1000);
      });
  }

  function handlePreviewPoliciesClicked(
    selectedQuotes: Record<string, boolean>
  ) {
    const ids: string[] = [];
    Object.keys(selectedQuotes).forEach((quoteKey) => {
      if (selectedQuotes[quoteKey] == true) {
        ids.push(quoteKey);
      }
    });

    const variables = {
      entityId: entityId,
      quoteIds: ids,
    };

    setPreviewPoliciesBtnState('loading');
    previewPoliciesAction({
      variables: variables,
      errorPolicy: 'all',
    })
      .then(async (res) => {
        if (isEmpty(res.errors)) {
          setPreviewPoliciesBtnState('success');
          const listOfFiles =
            res?.data?.insurance?.entities?.application?.insurance
              ?.previewPolicies?.Items;
          const fileElement = listOfFiles?.length > 0 ? listOfFiles[0] : null;

          if (fileElement) {
            await DataService.downloadAndOpenFileWithToken(
              generateDownloadLink(
                fileElement.id,
                'Insurance-Quote',
                ids[0],
                'Insurance-download',
                'SamplePolicyDocument'
              )
            );
          }
        } else {
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setPreviewPoliciesBtnState(undefined);
        }, 1000);

        if (
          lineOfBusiness.toLowerCase() === 'expat' ||
          lineOfBusiness.toLowerCase() === 'motor'
        ) {
          toast.success(
            <ToastSuccessMessage>
              Policy sample downloaded successfully. Make sure to open latest
              version downloaded.
            </ToastSuccessMessage>
          );
        }
      });
  }
  let premiumAmount = '0';
  function renderQuoteCard(quoteEntity: quote) {
    let rows: Record<string, IRow> = null;
    let tabs = null;
    if (lineOfBusiness == 'Motor') {
      const policyLink = `/plm/policies/${quoteEntity?.policyId}`;
      rows = {
        row1: {
          title: 'Policy Number',
          value: (
            <Link
              to={policyLink}
              style={{ color: MAIN_ONE_THEME.palette.primary5.main }}
            >
              {' '}
              {quoteEntity?.fullPolicyNumber}
            </Link>
          ),
          show: quoteEntity?.fullPolicyNumber != null,
        },
        row2: {
          title: 'Car Value',
          value: `${
            quoteEntity?.carValueCurrencySymbol +
            formatNumber(quoteEntity?.carValue)
          }`,
          show: quoteEntity?.carValue != null && quoteEntity?.carValue != 0,
        },
        row3: {
          title: 'Towing Services',
          value: quoteEntity?.towingServiceTitle,
          show: quoteEntity?.towingServiceTitle != null,
        },
        row4: {
          title: 'Vignette',
          value: quoteEntity.vignetteCode + quoteEntity?.vignette,
          show:
            quoteEntity?.vignetteCode != null &&
            quoteEntity?.vignette != null &&
            quoteEntity?.vignette != 0,
        },
      };
    } else if (lineOfBusiness == 'Expat') {
      premiumAmount =
        quoteEntity.premiumAmount != '0' ? quoteEntity.premiumAmount : '0';
      const policyLink = `/plm/expat-policies/${quoteEntity?.policyId}`;
      rows = {
        row1: {
          title: 'Policy Number',
          value: (
            <Link
              to={policyLink}
              style={{ color: MAIN_ONE_THEME.palette.primary5.main }}
            >
              {' '}
              {quoteEntity?.fullPolicyNumber}
            </Link>
          ),
          show: quoteEntity?.fullPolicyNumber != null,
        },
        row2: {
          title: 'Nationality',
          value: applicationCard
            ? quoteEntity?.expatDetails?.InsuredNationality?.Title
            : quoteEntity?.InsuredNationality?.Title,
          show: true,
        },
        row3: {
          title: 'Gender',
          value: applicationCard
            ? quoteEntity?.expatDetails?.InsuredGender
            : quoteEntity?.InsuredGender,
          show: true,
        },
        row4: {
          title: 'Age',
          value: applicationCard
            ? quoteEntity?.expatDetails?.InsuredAge
            : quoteEntity?.InsuredAge,
          show: true,
        },
      };
    } else {
      let tableRecords: Record<string | number, IRow[]> = null;

      quoteEntity?.insuredDetails?.forEach((insured, i) => {
        let newRecord: {
          title: string;
          value: string;
          show: boolean;
        }[] = [];

        if (quoteEntity.insuredPremiums != null) {
          newRecord = [
            {
              title: 'Insured',
              value: insured?.InsuredName,
              show: true,
            },
            {
              title: 'In',
              value: `${
                tenant.currencySymbol +
                quoteEntity?.insuredPremiums[i]?.InsuredInHospitalPremium
              }`,
              show: true,
            },
            {
              title: 'Out',
              value: `${
                tenant.currencySymbol +
                quoteEntity?.insuredPremiums[i]?.InsuredAmbulatoryPremium
              }`,
              show: true,
            },
            {
              title: 'Total',
              value: `${
                tenant.currencySymbol +
                quoteEntity?.insuredPremiums[i]?.InsuredTotalBasePremium
              }`,
              show: true,
            },
          ];
        }

        tableRecords = { ...tableRecords, [i]: newRecord };
      });

      tabs = {
        Details: {
          firstRowIsHeader: false,
          rows: {
            row1: [
              {
                title: 'Class',
                value:
                  quoteEntity?.product?.Code == 'Optimum'
                    ? quoteEntity?.optimumInHospitalClass?.Title
                    : quoteEntity?.standardInHospitalClass?.Title,
                show: true,
              },
              {
                title: 'Ambulatory Coverage',
                value:
                  quoteEntity?.product?.Code == 'Optimum'
                    ? quoteEntity?.optimumAmbulatoryCoverage?.Title
                    : quoteEntity?.standardAmbulatoryCoverage?.Title,
                show: true,
              },
            ],
          },
        },
        Breakdown: {
          firstRowIsHeader: true,
          rows: {
            0: [
              {
                title: 'Insured',
                value: 'Insured',
                show: true,
              },
              {
                title: 'In',
                value: 'In',
                show: true,
              },
              {
                title: 'Out',
                value: 'Out',
                show: true,
              },
              {
                title: 'Total',
                value: 'Total',
                show: true,
              },
            ],
          },
        },
      };

      tabs.Breakdown.rows = { ...tabs.Breakdown.rows, ...tableRecords };
    }

    return (
      <QuotesCardWidget
        key={quoteEntity.id}
        Id={quoteEntity.id}
        title={quoteEntity.title}
        status={capitalizeFirstLetter(quoteEntity.status)}
        statusReason={capitalizeFirstLetter(quoteEntity.statusReason, '_')}
        actionTitle={
          quoteEntity.modifiedOn != null ? 'Modified On' : 'Created On'
        }
        actionValue={
          quoteEntity.modifiedOn != null
            ? dayjs(new Date(quoteEntity.modifiedOn)).format('DD/MM/YYYY')
            : dayjs(new Date(quoteEntity.createdOn)).format('DD/MM/YYYY')
        }
        hasCoverage={quoteEntity.hasCoverage}
        hasOnlyMainAmount={quoteEntity.hasOnlyMainAmount}
        coverageAmountCurrencySymbol={quoteEntity.coverageAmountCurrencySymbol}
        coverageAmount={quoteEntity.coverageAmount}
        premiumAmountCurrencySymbol={quoteEntity.premiumAmountCurrencySymbol}
        premiumAmount={
          lineOfBusiness == 'Expat' ? premiumAmount : quoteEntity.premiumAmount
        }
        isSelectable={!disabled}
        dateFormat={tenant.dateFormat}
        handleSelect={handleCheckboxClick}
        selected={(selectedQuotes && selectedQuotes[quoteEntity.id]) || false}
        disabled={quoteEntity.disabled || disabled}
        rows={rows}
        tabs={tabs}
        mainAmountTitle={
          lineOfBusiness == 'Motor' ? 'Coverage' : 'Total Premium'
        }
        isMainAmountTitleSingleRow={lineOfBusiness != 'Motor'}
        lineOfBusiness={lineOfBusiness}
      />
    );
  }

  return (
    <form>
      <WidgetPaper>
        <WidgetSection
          title="Quotes"
          narrowSpacing={true}
          style={{ margin: '-20px 0 10px 0' }}
          actions={
            <>
              {applicationStatus != 'closed' &&
                issuePolicyAction &&
                userCanIssue && (
                  <EnhancedButton
                    type="button"
                    backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                    color="#FFF"
                    style={{ display: 'inline', margin: '0 0 -15px 0' }}
                    onClick={() => handleIssuePolicyClicked(selectedQuotes)}
                    disabled={disabledIssuePolicyBtn}
                    state={issueBtnState}
                  >
                    Issue Policy
                  </EnhancedButton>
                )}

              {applicationStatus != 'closed' &&
                sendQuoteAction &&
                userCanSend && (
                  <EnhancedButton
                    type="button"
                    onClick={() => handleSendClickOpen(selectedQuotes)}
                    disabled={disabledSendBtn}
                    backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                    color="#FFF"
                    style={{ display: 'inline', margin: '0 0 -15px 5px' }}
                    state={sendBtnState}
                  >
                    Send
                  </EnhancedButton>
                )}

              {(applicationStatus == 'quote_preparation' ||
                applicationStatus == 'quote_confirmation') &&
                acceptAction &&
                userCanAccept && (
                  <EnhancedButton
                    type="button"
                    disabled={disabledAcceptBtn}
                    onClick={() => handleAcceptClicked(selectedQuotes)}
                    backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                    color="#FFF"
                    style={{ display: 'inline', margin: '0 0 -15px 5px' }}
                    state={acceptBtnState}
                  >
                    Accept
                  </EnhancedButton>
                )}
              {downloadQuoteAction && userCanDownloadQuote && (
                <EnhancedButton
                  type="button"
                  disabled={disabledPreviewQuoteBtn}
                  onClick={() => handlePreviewQuotesClicked(selectedQuotes)}
                  backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                  color="#FFF"
                  style={{ display: 'inline', margin: '0 0 -15px 5px' }}
                  state={previewQuoteBtnState}
                >
                  Download Quote
                </EnhancedButton>
              )}
              {previewPoliciesAction && userCanPreviewPolicies && (
                <EnhancedButton
                  type="button"
                  disabled={disabledPreviewPolicies}
                  onClick={() => handlePreviewPoliciesClicked(selectedQuotes)}
                  backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                  color="#FFF"
                  style={{ display: 'inline', margin: '0 0 -15px 5px' }}
                  state={PreviewPoliciesBtnState}
                >
                  Download Policy Sample
                </EnhancedButton>
              )}
            </>
          }
        >
          {quotes.map((quote, i: number) => {
            return renderQuoteCard(quote);
          })}
        </WidgetSection>
      </WidgetPaper>
    </form>
  );
};

export default QuotesCards;
