import React from 'react';
import { IEnhancedCommonProps } from '../..';
import { makeStyles } from 'tss-react/mui';

interface IEnhancedLoader extends IEnhancedCommonProps {
  width?: number;
  height?: number;
  text?: string;
  containerClass?: string;
}

const useStyles = makeStyles()(() => ({
  circle: {
    fill: '#07295A',
  },
  loaderText: {
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular',
    letterSpacing: '0.24px',
    lineHeight: '32px',
    color: '#3B3B3B',
    marginTop: '-10px',
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
  },
}));

const EnhancedLoader: React.FC<IEnhancedLoader> = ({
  width = 100,
  height = 100,
  text,
  className,
  containerClass,
  style,
}) => {
  const { classes } = useStyles();

  return (
    <div className={`${containerClass} ${classes.loaderContainer}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width={width}
        height={height}
        fill="#e96823"
        className={className}
        style={style}
      >
        <circle
          className={classes.circle}
          transform="translate(8 0)"
          cx="0"
          cy="16"
          r="0"
        >
          <animate
            attributeName="r"
            values="0; 4; 0; 0"
            dur="1.2s"
            repeatCount="indefinite"
            begin="0"
            // keytimes="0;0.2;0.7;1"
            keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
            calcMode="spline"
          />
        </circle>
        <circle
          className={classes.circle}
          transform="translate(16 0)"
          cx="0"
          cy="16"
          r="0.0854297"
        >
          <animate
            attributeName="r"
            values="0; 4; 0; 0"
            dur="1.2s"
            repeatCount="indefinite"
            begin=".3"
            // keytimes="0;0.2;0.7;1"
            keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
            calcMode="spline"
          />
        </circle>
        <circle
          className={classes.circle}
          transform="translate(24 0)"
          cx="0"
          cy="16"
          r="2.23425"
        >
          <animate
            attributeName="r"
            values="0; 4; 0; 0"
            dur="1.2s"
            repeatCount="indefinite"
            begin=".6"
            // keytimes="0;0.2;0.7;1"
            keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
            calcMode="spline"
          />
        </circle>
      </svg>
      {text && <p className={classes.loaderText}>{text}</p>}
    </div>
  );
};

export default EnhancedLoader;
