import React, { useMemo } from 'react';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { IProductionDetailsSummary } from '..';

interface ITravelDetailsWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionPolicyTravelDetailsWidget: React.FC<
  ITravelDetailsWidgetProps
> = ({ data }) => {
  const dynamicView = useMemo(
    (): IDynamicDiplayView => ({
      name: 'PolicyTravelDetails',
      sections: [
        {
          name: 'TravelDetails',
          title: 'Travel Details',
          properties: {
            destination: {
              multiline: false,
              value: data?.Travel?.Destination,
              name: 'destination',
              title: 'Destination',
            },
            duration: {
              multiline: false,
              value: data?.Travel?.Duration,
              name: 'duration',
              title: 'Duration (in Days)',
            },
          },
        },
      ],
    }),
    [data]
  );

  return <DynamicWidget hasFourFields={false} view={dynamicView} />;
};

export default ProductionPolicyTravelDetailsWidget;
