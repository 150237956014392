import React, { useEffect, useState } from 'react';
import EntityInfoDetails, {
  IEntityInfoDetailsProps,
} from './EntityInfoDetails';

const EntityInfoWidget: React.FC<IEntityInfoDetailsProps> = ({
  view,
  ...props
}) => {
  const [updatedView, setUpdatedView] = useState<typeof view>(view);

  useEffect(() => {
    setUpdatedView({ ...view });
  }, [view]);

  return (
    <>
      {/* <EntityInfoActions actions={widgetActions} /> */}
      <EntityInfoDetails
        // actions={entityActions}
        view={updatedView}
        {...props}
      />
    </>
  );
};

export default EntityInfoWidget;
