import React, { useEffect, useState, useRef } from 'react';
import EnhancedTableHead from './EnhancedTableHead';
import { TableSortOrder, rowSort, getSorting } from '../../utils/table-utils';

import EnhancedTableBody from './EnhancedTableBody';
import { IListingData } from '../../models/listing';
import {
  EnhancedUpdatedTableHeader,
  IEnhancedUpdatedTableMenuItem,
  EnhancedUpdatedTableFilterType,
  EnhancedUpdatedTableHeaderType,
  ITableRowSettings,
} from '.';
// import { AppState } from '../../redux/rootReducer';
import { Search } from '@mui/icons-material';
import { IEnhancedMenuItem } from '.';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { makeStyles } from 'tss-react/mui';
import { Card, TableContainer, Table, TextField } from '@mui/material';
import EnhancedTablePagination from './EnhancedTablePagination';
import { isValidDate } from '../../utils/date-utils';
import { isValidNumber } from '../../utils/validationUtils';
import FormattingServices from '../../services/formating-services';

export interface IEnhancedTableProps {
  name: string;
  entityName?: string;
  headers: Record<string, EnhancedUpdatedTableHeader>;
  rowSettings?: ITableRowSettings;
  data: IListingData<any>;
  tableTitle?: string;
  defaultOrderByColumn?: string;
  orderByAscendingByDefault?: boolean;
  secondOrderByColumn?: string;
  secondOrderByAscendingByDefault?: boolean;
  disableSelection?: boolean;
  actions?: IEnhancedUpdatedTableMenuItem[];
  tableFilters?: Record<string, EnhancedUpdatedTableFilterType>;
  handlePageChange?: (page: number) => void;
  currentPage?: number;
  hideHeader?: boolean;
  pagination?: boolean;
  embeddedToolbar?: boolean;
  handleFilterChange?: (
    name: string,
    value: string[] | Date[],
    type: string
  ) => void;
  handleFilterAction?: () => void;
  handleFilterClear?: () => void;
  // onChangeFilteredItems?: (ids: ListingDataItemType<any>) => void;
}

const useStyles = makeStyles()((theme) => ({
  containerCard: {
    boxShadow: 'none',
    borderRadius: '0',
    backgroundColor: 'transparent',
  },
  tableContainer: {
    border: '1px solid #EAECF0',
    backgroundColor: '#FFF',
  },
  table: {
    border: 'none',
    borderRadius: '9px',
  },
  tableHeader: {
    padding: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 30px',
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleToolbar: {
    padding: '23px',
    backgroundColor: 'white',
    fontFamily: 'SourceSansPro-Bold',
    fontSize: '18px',
    color: '#231F20',
  },
  tableTitle: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#000000E6',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '33px',
  },
  searchInput: {
    width: '227px',
    outline: 'none',
    '& .MuiInputBase-input': {
      width: '100%',
      padding: '0 16px',
      fontSize: '13px',
      fontFamily: 'OpenSans-Regular',
      height: '33px',
      color: '#3F3F3F',
    },
    '& .MuiInputBase-root': {
      borderRadius: '0',
      borderTopLeftRadius: '13px',
      borderBottomLeftRadius: '13px',
      padding: '0 !important',
      backgroundColor: '#FFF',
    },
  },
  searchIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 13px',
    backgroundColor: '#CFD5DE',
    borderTopRightRadius: '13px',
    borderBottomRightRadius: '13px',
  },
  tableActions: {
    backgroundColor: '#FFF',
    padding: '17px 25px 13px',
    borderBottom: '1px solid #EAECF0',
    borderTopLeftRadius: '9px',
    borderTopRightRadius: '9px',
  },
  tableFilters: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    '& > div:first-child': {
      width: '100%',
    },
  },
  actionButton: {
    outline: 'none',
    border: '1px solid #D0D5DD',
    borderRadius: '7.5px',
    cursor: 'pointer',
    backgroundColor: '#FFF',
    margin: '0 15px 0 0',
    height: '39px',
    padding: '0 19px',
    fontFamily: 'OpenSans-Medium',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#344054',
    ':disabled': {
      cursor: 'not-allowed',
    },
  },
}));

const UpdatedEnhancedTable: React.FC<IEnhancedTableProps> = ({
  name,
  headers,
  rowSettings,
  data,
  tableTitle,
  defaultOrderByColumn,
  orderByAscendingByDefault,
  secondOrderByColumn,
  secondOrderByAscendingByDefault,
  // filterableNames,
  entityName = '',
  disableSelection,
  tableFilters,
  actions = [],
  handlePageChange = () => undefined,
  currentPage = 0,
  hideHeader,
  pagination = true,
  embeddedToolbar,
  handleFilterAction = () => undefined,
  handleFilterChange = () => undefined,
  handleFilterClear = () => undefined,
  // onChangeFilteredItems = () => undefined,
}) => {
  const { classes } = useStyles();
  let filterDelay: NodeJS.Timeout;
  const dateFormat = 'DD/MM/YYYY';
  const [order, setOrder] = useState<TableSortOrder>();
  const [orderBy, setOrderBy] = useState<string>();
  const [secondOrder, setSecondOrder] = useState<TableSortOrder>();
  const [page, setPage] = useState(data.pageNumber > 0 ? data.pageNumber : 0);
  const [rowsPerPage, _setRowsPerPage] = useState(data.pageSize);
  const [selected, setSelected] = useState<string[]>([]);
  const [filtered, setFiltered] = useState(data.pagedItems);
  const [filterValue, setFilterValue] = useState('');
  const currentName = useRef<string>();

  // Watches data.pagedItems, headers and fiterValue, and recalculates when one changes
  useEffect(() => {
    filterDelay = setTimeout(() => {
      if (headers) {
        const outKeys: string[] = Object.keys(data.pagedItems).filter(
          (itemKey) => {
            const curItem = data.pagedItems[itemKey];
            return Object.values(headers).some((header) => {
              const currentValue = curItem[header.name];
              if (currentValue) {
                if (
                  typeof currentValue === 'string' ||
                  typeof currentValue === 'number'
                ) {
                  if (
                    currentValue
                      .toString()
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())
                  ) {
                    return true;
                  }
                }

                if (
                  header.type === EnhancedUpdatedTableHeaderType.Currency ||
                  header.type === EnhancedUpdatedTableHeaderType.ColoredCurrency
                ) {
                  const formattedValue = isValidNumber(currentValue)
                    ? FormattingServices.formatCurrencyNumber(currentValue)
                    : '';
                  if (formattedValue.toString().includes(filterValue)) {
                    return true;
                  }
                } else if (
                  header.type === EnhancedUpdatedTableHeaderType.Date
                ) {
                  const formattedValue = isValidDate(currentValue)
                    ? FormattingServices.formatDate(currentValue)
                    : '';
                  if (
                    formattedValue
                      .toString()
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())
                  ) {
                    return true;
                  }
                } else if (
                  header.type === EnhancedUpdatedTableHeaderType.DateAndTime
                ) {
                  const formattedValue = isValidDate(currentValue)
                    ? FormattingServices.formatDateTime(currentValue)
                    : '';
                  if (
                    formattedValue
                      .toString()
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())
                  ) {
                    return true;
                  }
                }
              }
              return false;
            });
          }
        );
        const output: typeof data.pagedItems = {};
        outKeys.forEach((key) => {
          output[key] = data.pagedItems[key];
        });

        setSelected(selected.filter((key) => outKeys.includes(key)));
        setFiltered(output);
        // onChangeFilteredItems(output);
        setPage(currentPage);
      } else {
        setFiltered(data.pagedItems);
        // onChangeFilteredItems(data.pagedItems);
      }
    }, 0);
    return () => {
      clearTimeout(filterDelay);
    };
  }, [data.pagedItems, filterValue, headers]);

  useEffect(() => {
    if (currentName.current !== name && defaultOrderByColumn) {
      currentName.current = name;
      setOrder(orderByAscendingByDefault ? 'asc' : 'desc');
      setOrderBy(defaultOrderByColumn);
      if (secondOrderByColumn) {
        setSecondOrder(secondOrderByAscendingByDefault ? 'asc' : 'desc');
      }
    }
  }, [
    defaultOrderByColumn,
    name,
    orderByAscendingByDefault,
    secondOrderByAscendingByDefault,
    secondOrderByColumn,
  ]);

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: string
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(rowName: string, checked: boolean) {
    if (checked) {
      setSelected(Object.keys(filtered));
      return;
    }
    setSelected([]);
  }

  function handleClick(rowName: string) {
    const selectedIndex = selected.indexOf(rowName);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
    handlePageChange(newPage);
  }

  const handleChange = (search: string) => {
    setFilterValue(search);
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;

  const handleActionClick = (
    action: IEnhancedUpdatedTableMenuItem,
    returnAll = false
  ) => {
    if (returnAll) {
      const tempArray: any[] = [];
      selected.forEach((key) => tempArray.push(data.pagedItems[key]));
      action.onClick(tempArray);
    } else {
      action.onClick(selected);
    }
  };

  const getTableActions = (): IEnhancedMenuItem[] => {
    const tableMenuItems: IEnhancedMenuItem[] = [];
    actions.forEach((action) => {
      tableMenuItems.push({
        title: action.title,
        onClick: () => handleActionClick(action, action.returnAllData),
        disabled:
          ((action.isEntity || action.isBulk) && selected.length === 0) ||
          (action.isEntity && !action.isBulk && selected.length > 1) ||
          action.disabled ||
          (action.conditionalDisable && action.conditionalDisable(selected)),
      });
    });

    return tableMenuItems;
  };

  const renderActions = () => {
    return getTableActions().map((action) => {
      return (
        <button
          key={`${action.title}-action-button`}
          className={classes.actionButton}
          disabled={action.disabled}
          onClick={() => action.onClick()}
          type="button"
        >
          {action.title}
        </button>
      );
    });
  };

  const renderFilterToolbar = () => (
    <div>
      {tableTitle && (
        <div className={classes.toolbarContainer}>
          <div className={classes.titleToolbar}>{tableTitle}</div>
          {embeddedToolbar && renderActions()}
        </div>
      )}
      <EnhancedTableToolbar
        filters={tableFilters}
        handleFilterChange={(name, value, type) =>
          handleFilterChange(name, value, type)
        }
        onFilterClear={() => handleFilterClear()}
        onFilterAction={() => handleFilterAction()}
      />
    </div>
  );

  const emptyRows = pagination
    ? rowsPerPage -
      Math.min(rowsPerPage, Object.keys(filtered).length - page * rowsPerPage)
    : 0;

  return (
    <Card className={classes.containerCard} id={name ? name : ''}>
      {!hideHeader && (
        <div className={classes.tableHeader}>
          {tableTitle && (
            <span className={classes.tableTitle}>{tableTitle}</span>
          )}
          <div className={classes.searchContainer}>
            <TextField
              name={'search'}
              placeholder={'Search'}
              inputProps={{ 'aria-label': 'Search' }}
              onChange={(e) => {
                handleChange((e.target as any).value.trim() || '');
              }}
              className={classes.searchInput}
            />
            <div className={classes.searchIconContainer}>
              <Search />
            </div>
          </div>
        </div>
      )}

      <TableContainer className={classes.tableContainer}>
        {actions && actions.length > 0 && !embeddedToolbar && (
          <div className={classes.tableActions}>{renderActions()}</div>
        )}
        <div className={classes.tableFilters}>
          {tableFilters && <>{renderFilterToolbar()}</>}
        </div>
        <div>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={Object.keys(filtered).length}
              headRows={headers}
              disableSelection={disableSelection}
            />
            <EnhancedTableBody
              entityName={entityName}
              emptyRows={emptyRows}
              handleClick={handleClick}
              headers={headers}
              rowSettings={rowSettings}
              isSelected={isSelected}
              rows={rowSort(
                filtered,
                getSorting(
                  order,
                  orderBy,
                  headers,
                  secondOrder,
                  secondOrderByColumn
                )
              ).slice(
                pagination ? page * rowsPerPage : undefined,
                pagination ? page * rowsPerPage + rowsPerPage : undefined
              )}
              //currencySymbol={currencySymbol}
              dateFormat={dateFormat}
              //phoneNumberPattern={phoneNumberPattern}
              disableSelection={disableSelection}
            />
          </Table>
        </div>
        {pagination ? (
          <EnhancedTablePagination
            // rowsPerPageOptions={[5, 10, 25]}
            // component="div"
            count={Object.keys(filtered).length}
            // rowsPerPage={rowsPerPage}
            // page={page}
            // backIconButtonProps={{
            //   'aria-label': 'previous',
            // }}
            // nextIconButtonProps={{
            //   'aria-label': 'next',
            // }}
            page={page + 1}
            onPageChange={(p) => handleChangePage({}, p - 1)}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            // labelRowsPerPage=""
          />
        ) : (
          <></>
        )}
      </TableContainer>
    </Card>
  );
};

export default UpdatedEnhancedTable;
