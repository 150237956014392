import { IAssignedBPPlanInfo } from '.';
import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  businessPartner: '',
  line: '',
  plan: '',
};

export const getInputs = (
  values: IAssignedBPPlanInfo = initialValues,
  lovs: Record<string, Record<string, string>>
): Record<string, DynamicFormInputType> => ({
  businessPartner: {
    name: 'businessPartner',
    title: 'Business Partner',
    type: FormInputTypes.chips,
    placeholder: 'Business Partner',
    value: !isEmpty(values.businessPartner) ? values.businessPartner : '',
    required: true,
    multiple: false,
    selectOptions: lovs.primaryBP || {},
  },
  line: {
    name: 'line',
    title: 'Line',
    type: FormInputTypes.chips,
    placeholder: 'Line',
    value: !isEmpty(values.line) ? values.line : '',
    required: true,
    multiple: false,
    selectOptions: lovs.line || {},
  },
  plan: {
    name: 'plan',
    title: 'Plan',
    type: FormInputTypes.chips,
    placeholder: 'Plan',
    value: !isEmpty(values.plan) ? values.plan : '',
    required: true,
    multiple: true,
    selectOptions: lovs.plan || {},
  },
});
