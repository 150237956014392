import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const personEnums: Record<string, Record<string, string>> = {};

  personEnums[data.SalesforceManagement_PersonTypes.name] =
    enumListAsRecordObject(data.SalesforceManagement_PersonTypes.enumValues);

  if (personEnums[data.SalesforceManagement_PersonTypes.name]['PERSON']) {
    personEnums[data.SalesforceManagement_PersonTypes.name]['PERSON'] =
      'Individual';
  }

  personEnums['phoneType'] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  personEnums['titles'] = lookupListAsRecordObject(
    data.Insurance.lookups.titles
  );

  return personEnums;
}

export function graphqlEntityToPersonInfo(data: any): IPersonInfo {
  return {
    personId: '',
    type: data?.SalesforceManagement?.entities?.person?.views
      ?.SalesforceManagement_all?.properties?.PersonType,
    companyName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.CompanyName,
    title:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.Title?.Code,
    firstName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.FirstName,
    middleName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.MiddleName,
    lastName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.LastName,
    dateOfBirth:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.DateOfBirth,
    email:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.Email,
    phoneType:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.PhoneDetails?.PhoneType,
    phoneNumber:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.PhoneDetails?.PhoneNumber,
  };
}
