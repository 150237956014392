import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';

export const initialValues = {
  expiryDate: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  expiryDate: {
    name: 'expiryDate',
    title: 'Expiry Date',
    type: FormInputTypes.date,
    placeholder: 'Expiry Date',
    value: initialValues.expiryDate,
    required: true,
    disabled: false,
  },
};
