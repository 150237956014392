import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';
import { IEnhancedCommonProps } from '..';
import { MAIN_ONE_THEME } from '../../constants';

interface IEnhancedCheckboxProps extends IEnhancedCommonProps {
  name: string;
  checked: boolean;
  title?: string | JSX.Element;
  error?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  checkboxOnly?: boolean;
  onChange: (
    name: string,
    value: boolean,
    rowIndex?: string,
    rowId?: string
  ) => void;
  classes?: { root?: string; error?: string; label?: string };
}

const useStyles = makeStyles()(() => ({
  labelWrapper: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    lineHeight: '15px',
    color: MAIN_ONE_THEME.palette.primary1.main,
  },
  lableCheckCont: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    textAlign: 'left',
    minWidth: 'fit-content',
  },
  checkRoot: {
    height: 14,
    width: 14,
    '& svg': {
      height: 14,
      width: 14,
      color: MAIN_ONE_THEME.palette.primary2.main,
    },
  },
  error: {
    textAlign: 'right',
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    minHeight: '15px',
    display: 'block',
    color: MAIN_ONE_THEME.palette.error.main,
    marginLeft: '32px',
  },
}));

const EnhancedCheckbox: React.FC<IEnhancedCheckboxProps> = ({
  name,
  title,
  checked,
  error,
  disabled,
  indeterminate,
  checkboxOnly,
  onChange,
  className,
  classes = {},
  style,
}) => {
  const { classes: checkboxClasses } = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, inputChecked: boolean) => {
      onChange(name, inputChecked);
    },
    [name, onChange]
  );

  const renderCheckbox = () => (
    <Checkbox
      color="primary"
      classes={{
        root: clsx(checkboxClasses.checkRoot, classes.root),
      }}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      indeterminate={indeterminate}
    />
  );

  return checkboxOnly ? (
    renderCheckbox()
  ) : (
    <label
      htmlFor={name}
      className={clsx(checkboxClasses.labelWrapper, className)}
      style={style}
    >
      <div className={clsx(checkboxClasses.lableCheckCont, classes.label)}>
        {renderCheckbox()}
        <span className={checkboxClasses.label}>{title}</span>
      </div>
      <span
        id={`errorMsg-${name}`}
        className={clsx(checkboxClasses.error, classes.error)}
      >
        {error}
      </span>
    </label>
  );
};

export default EnhancedCheckbox;
