import { lookupListAsRecordObject } from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const proposalsEnums: Record<string, Record<string, string>> = {};

  proposalsEnums['policyCurrency'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  return proposalsEnums;
}

export function extractAssignedBP(data: any) {
  const assignedBP: Record<string, string> = {};

  data.SalesforceManagement.queries.getAssignedBPListWithoutPagination.forEach(
    (element: {
      businessPartner_Id: string;
      salesforceManagement_AssignedBP_BusinessPartnerID: {
        views: {
          SalesforceManagement_all: {
            properties: {
              Code: string;
              PersonID: {
                views: {
                  SalesforceManagement_all: {
                    properties: {
                      FullName: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      assignedBP[element.businessPartner_Id] =
        `${element.salesforceManagement_AssignedBP_BusinessPartnerID.views.SalesforceManagement_all.properties.Code} - ${element.salesforceManagement_AssignedBP_BusinessPartnerID.views.SalesforceManagement_all.properties.PersonID.views.SalesforceManagement_all.properties.FullName}`;
    }
  );

  return assignedBP;
}

export function extractAssignedBPWithPagination(data: any) {
  const assignedBP: Record<string, string> = {};

  data.SalesforceManagement.queries.getAssignedBPList.items.forEach(
    (element: {
      businessPartner_Id: string;
      salesforceManagement_AssignedBP_BusinessPartnerID: {
        views: {
          SalesforceManagement_all: {
            properties: {
              Code: string;
              PersonID: {
                views: {
                  SalesforceManagement_all: {
                    properties: {
                      FullName: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      assignedBP[element.businessPartner_Id] =
        `${element.salesforceManagement_AssignedBP_BusinessPartnerID.views.SalesforceManagement_all.properties.Code} - ${element.salesforceManagement_AssignedBP_BusinessPartnerID.views.SalesforceManagement_all.properties.PersonID.views.SalesforceManagement_all.properties.FullName}`;
    }
  );

  return assignedBP;
}

export function extractLines(data: any) {
  const lines: Record<string, string> = {};

  data?.SalesforceManagement?.queries?.getActiveAssignedBPPlanList?.items?.forEach(
    (element: {
      line_Id: string;
      salesforceManagement_AssignedBPPlan_LineID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
              ExternalCode: string;
            };
          };
        };
      };
    }) => {
      lines[element.line_Id] =
        `${element.salesforceManagement_AssignedBPPlan_LineID.views.PlanConfigManagement_all.properties.ExternalCode} - ${element.salesforceManagement_AssignedBPPlan_LineID.views.PlanConfigManagement_all.properties.Name}`;
    }
  );

  return lines;
}

export function extractSublines(data: any) {
  const sublines: Record<string, string> = {};

  data?.SalesforceManagement?.queries?.getActiveAssignedBPPlanList?.items?.forEach(
    (element: {
      subline_Id: string;
      salesforceManagement_AssignedBPPlan_PlanID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              SublineID: {
                views: {
                  PlanConfigManagement_all: {
                    properties: {
                      Name: string;
                      ExternalCode: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      sublines[element.subline_Id] =
        `${element.salesforceManagement_AssignedBPPlan_PlanID.views.PlanConfigManagement_all.properties.SublineID.views.PlanConfigManagement_all.properties.ExternalCode} - ${element.salesforceManagement_AssignedBPPlan_PlanID.views.PlanConfigManagement_all.properties.SublineID.views.PlanConfigManagement_all.properties.Name}`;
    }
  );

  return sublines;
}

export function extractPlans(data: any) {
  const lines: Record<string, string> = {};
  const planCurrencies: Record<string, string> = {};

  data?.SalesforceManagement?.queries?.getActiveAssignedBPPlanList?.items?.forEach(
    (element: {
      plan_Id: string;
      salesforceManagement_AssignedBPPlan_PlanID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
              ExternalCode: string;
              PlanCurrency: {
                Code: string;
              };
            };
          };
        };
      };
    }) => {
      lines[element.plan_Id] =
        `${element.salesforceManagement_AssignedBPPlan_PlanID.views.PlanConfigManagement_all.properties.ExternalCode} - ${element.salesforceManagement_AssignedBPPlan_PlanID.views.PlanConfigManagement_all.properties.Name}`;

      planCurrencies[element.plan_Id] =
        element.salesforceManagement_AssignedBPPlan_PlanID.views.PlanConfigManagement_all.properties.PlanCurrency?.Code;
    }
  );

  return { lines, planCurrencies };
}

export function extractPlanAssignBPPlan_Id(data: any) {
  const lines: Record<string, string> = {};

  data?.SalesforceManagement?.queries?.getActiveAssignedBPPlanList?.items?.forEach(
    (element: {
      plan_Id: string;
      salesforceManagement_AssignedBPPlan_Id: string;
    }) => {
      lines[element.plan_Id] = element.salesforceManagement_AssignedBPPlan_Id;
    }
  );

  return lines;
}
