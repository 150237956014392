import React from 'react';
import { IAddVoucherPopupShellProps } from '../../shared/types';
import EnhancedModal from '../../../../../../../components/common/generic-modal/EnhancedModal';
import ReceiptVoucherPopup from './ReceiptVoucherPopup';

const ReceiptVoucherPopupShell: React.FC<IAddVoucherPopupShellProps> = ({
  open,
  onSuccess,
  onClose,
  isEdit = false,
  currentPaymentId,
}) => {
  return (
    <EnhancedModal
      open={open}
      blur={true}
      onClose={onClose}
      title={isEdit ? 'Modify Receipt Vouchers' : 'Add Receipt Vouchers'}
    >
      <ReceiptVoucherPopup
        onSuccess={onSuccess}
        onClose={onClose}
        isEdit={isEdit}
        currentPaymentId={currentPaymentId}
      />
    </EnhancedModal>
  );
};

export default ReceiptVoucherPopupShell;
