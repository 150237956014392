import { makeStyles } from 'tss-react/mui';
import React from 'react';
import clsx from 'clsx';
import { IEnhancedCommonProps } from '..';

const useStyles = makeStyles()(() => ({
  separator: {
    background: `#f9f9f9 0% 0% no-repeat padding-box`,
    height: 10,
    width: '100%',
    margin: '10px 0',
  },
}));

const Separator: React.FC<IEnhancedCommonProps> = ({ className, style }) => {
  const { classes } = useStyles();

  return <div className={clsx(classes.separator, className)} style={style} />;
};

export default Separator;
