import React from 'react';
import { IEnhancedDynamicDisplay } from '.';
import { IEnhancedCommonProps } from '../..';
import EnhancedDisplayWrapper from '../../enhanced-form/EnhancedDisplayWrapper';

export interface IDynamicDisplayFieldProps extends IEnhancedCommonProps {
  property: IEnhancedDynamicDisplay;
  value: any;
  error?: any;
  disabled?: boolean;
  material?: boolean;
  cdnUrl: string;
  astAccountName?: string;
  dateFormat?: string;
  tooltip: boolean;
  currencySymbol?: string;
}

const DynamicDisplayField: React.FC<IDynamicDisplayFieldProps> = ({
  property,
  value,
  cdnUrl,
  astAccountName,
  dateFormat,
  tooltip,
  currencySymbol,
  ...props
}) => {
  function renderField() {
    const { title, ...restOfProperties } = property;
    // title = restOfProperties.required && !isEmpty(title) ? `${title}*` : title;

    return (
      <EnhancedDisplayWrapper
        dateFormat={dateFormat}
        currencySymbol={currencySymbol}
        phoneNumberPattern={''}
        {...props}
        title={title}
        {...restOfProperties}
        toolTip={tooltip}
      />
    );
  }

  return <>{renderField()}</>;
};

export default DynamicDisplayField;
