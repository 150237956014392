/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../components/common/WidgetSection';
import {
  contentFontFamilyBold,
  contentFontFamilyRegular,
} from '../../../../../constants';

interface IPolicyMedicalGroupDetailsWidgetProps {
  lovs: Record<string, Record<string, string>>;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    marginTop: '1em',
  },
  section: {
    padding: '23px 0 25px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(1, 100%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '20px',
    margin: '0 0 10px',
    fontFamily: contentFontFamilyBold,
  },
  list: {
    fontSize: '16px',
    lineHeight: '20px',
    margin: '0 0 10px',
    fontFamily: contentFontFamilyRegular,
  },
}));

const PolicyMedicalGroupDetailsWidget: React.FC<
  IPolicyMedicalGroupDetailsWidgetProps
> = ({ lovs }) => {
  const { classes } = useStyles();

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Group Medical Details "
        hasTitleSpecificDesign={false}
        classes={{
          container: classes.section,
        }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <div>
            <p className={classes.paragraph}>
              The plan selected includes the below covers:
            </p>
            <ul>
              {Object.values(lovs.policyCovers).map((cover) => (
                <li className={classes.list} key={cover}>
                  {cover}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default PolicyMedicalGroupDetailsWidget;
