import { IAbstractRecord } from '../../../models';

export interface IListingTableFilter {
  title: string;
  name: string;
  selectedValues: string[];
  options: string[];
}

export interface IEnhancedMenuItem {
  name: string;
  title: string;
  disabled?: boolean;
  target?: ActionTarget;
  iconUri?: string;
  isPrimary?: boolean;
  supportBulk?: boolean;
  displayActionPerRecord?: boolean;
  onClick: () => void;
}

export enum ActionTarget {
  Entity = 'Entity',
}

export interface IFilterModel {
  searchKey?: string;
  namedFilters?: IAbstractRecord;
  pagination?: IAbstractRecord;
  userId?: string;
  userRoles?: string[];
}
