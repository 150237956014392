import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { cloneDeep, isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { ITravelPlanRatesDrawerProps } from '.';
import {
  createPlanSpecificTravel,
  getTravelPlanRatesEnums,
  getPlanSpecificTravelInfo,
  updatePlanSpecificTravel,
} from './queries';
import { LookupToList, graphqlToTravelPlanRatesInfo } from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';

const TravelPlanRatesDrawer: React.FC<ITravelPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  planCurrency,
  travelPlanRateId,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [travelPlanRatesResultQuery] = useLazyQuery(getTravelPlanRatesEnums());

  const [travelPlanRatesInfoQuery] = useLazyQuery(getPlanSpecificTravelInfo());

  const [travelPlanRatesAction] = useMutation(
    travelPlanRateId ? updatePlanSpecificTravel() : createPlanSpecificTravel()
  );

  const loadLovList = async () => {
    const result = await travelPlanRatesResultQuery();
    const newTravelPlanRatesEnums = LookupToList(result.data);

    return newTravelPlanRatesEnums;
  };

  const getEntityInfo = async () => {
    if (travelPlanRateId) {
      const apiResult = await travelPlanRatesInfoQuery({
        variables: { id: travelPlanRateId },
      });
      if (apiResult.data) {
        const travelPlanRateEntity = graphqlToTravelPlanRatesInfo(
          apiResult.data
        );
        return travelPlanRateEntity;
      }
    }

    return null;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);
      updatedInputs.planCurrency.value = planCurrency;

      const [travelPlanRatesEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      (updatedInputs.planCurrency as IFormSelectDynamicProps).selectOptions =
        lovData['currencies'];

      (
        updatedInputs.travelDestination as IFormSelectDynamicProps
      ).selectOptions = lovData['travelDestinations'];

      (updatedInputs.gender as IFormSelectDynamicProps).selectOptions =
        lovData['gender'];

      if (travelPlanRatesEntity) {
        updatedInputs.ageFrom.value = travelPlanRatesEntity.ageFrom;
        updatedInputs.ageTo.value = travelPlanRatesEntity.ageTo;
        updatedInputs.gender.value = travelPlanRatesEntity.gender;
        updatedInputs.travelDurationFrom.value =
          travelPlanRatesEntity.travelDurationFrom;
        updatedInputs.travelDurationTo.value =
          travelPlanRatesEntity.travelDurationTo;
        updatedInputs.travelDestination.value =
          travelPlanRatesEntity.travelDestination;
        updatedInputs.nbOfAdherent.value = travelPlanRatesEntity.nbOfAdherent;
        updatedInputs.premium.value = travelPlanRatesEntity.premium;
      }

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      let variablesMutation = {};
      variablesMutation = {
        planSpecificTravelInputs: {
          planID: planId,
          ageFrom: Number(data?.ageFrom),
          ageTo: Number(data?.ageTo),
          gender: data?.gender,
          travelDurationFrom: Number(data?.travelDurationFrom),
          travelDurationTo: Number(data?.travelDurationTo),
          travelDestination: data?.travelDestination,
          nbOfAdherent: Number(data?.nbOfAdherent),
          premium: Number(data?.premium),
        },
      };

      travelPlanRatesAction({
        variables: travelPlanRateId
          ? {
              ...variablesMutation,
              entityId: travelPlanRateId,
            }
          : variablesMutation,
        errorPolicy: 'all',
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                {travelPlanRateId
                  ? 'Plan Rate successfully updated.'
                  : 'Plan Rate successfully added.'}
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState('success');
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .catch((err) => {
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
        });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <GenericDrawer
      title={travelPlanRateId ? 'Modify Plan Rate' : 'Add Plan Rate'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={!!submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default TravelPlanRatesDrawer;
