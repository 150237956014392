import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  name: {
    name: 'name',
    title: 'Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  expatContactDetails: {
    name: 'expatContactDetails',
    title: 'Customer',
    type: EnhancedTableHeaderType.Text,
  },
  expatInsuredDetails: {
    name: 'expatInsuredDetails',
    title: 'Insured',
    type: EnhancedTableHeaderType.Text,
  },
  policyNumber: {
    name: 'policyNumber',
    title: 'Policy Number',
    type: EnhancedTableHeaderType.JSXElement,
  },
  totalPremium: {
    name: 'totalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.Currency,
  },
  agency: {
    name: 'agency',
    title: 'Business Partner',
    type: EnhancedTableHeaderType.Text,
  },
  agent: {
    name: 'agent',
    title: 'Business User',
    type: EnhancedTableHeaderType.Text,
  },
  effectiveDate: {
    name: 'effectiveDate',
    title: 'Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  expiryDate: {
    name: 'expiryDate',
    title: 'Expiry Date',
    type: EnhancedTableHeaderType.Date,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
  status: {
    name: 'status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  statusReason: {
    name: 'statusReason',
    title: 'Status Reason',
    type: EnhancedTableHeaderType.Text,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord,
  userRoles: string[]
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        ...((userRoles.includes('Insurance-Admin') ||
          userRoles.includes('Insurance-Underwriter')) && {
          agency: {
            name: 'agency',
            title: 'Business Partner',
            type: FormInputTypes.chips,
            value: filterValues?.agency || [],
            placeholder: 'Business Partner',
            triggerUpdate: true,
            selectOptions: { ...lovs.agency },
          },
        }),
        effectiveDate: {
          name: 'effectiveDate',
          title: 'Effective Date',
          type: FormInputTypes.daterange,
          value: filterValues.effectiveDate || [],
          placeholder: 'Effective Date',
          triggerUpdate: true,
        },
        createdDate: {
          name: 'createdDate',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues.createdDate || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues.status || [],
          placeholder: 'Status',
          selectOptions: { ...lovs.status },
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
