import { gql } from '@apollo/client';

export function getBusinessUserDetailsInfo() {
  return gql`
    query getBusinessUserDetailsInfo($id: String!) {
      SalesforceManagement {
        entities {
          businessUser(id: $id) {
            queries {
              salesforceManagement {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
            views {
              SalesforceManagement_all {
                properties {
                  Code
                  Status
                  Type {
                    Id
                    Title
                  }
                  Account
                  Access {
                    Id
                    Title
                  }
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                  AllowPolicyEndDateEdits
                  AllowPlanCoverEdits
                  AllowClauseEdits
                  EditableLineChargesIDs {
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Id
                          Name
                        }
                      }
                    }
                  }
                  PersonID {
                    id
                    views {
                      SalesforceManagement_all {
                        properties {
                          FullName
                          Email
                          PersonCode
                          DateOfBirth
                          PhoneDetails {
                            PhoneType
                            PhoneNumber
                          }
                          IsTaxable
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function activateBusinessUser() {
  return gql`
    mutation activateBusinessUser($entityId: String!) {
      salesforceManagement {
        entities {
          businessUser {
            salesforceManagement {
              activateBusinessUser(entityId: $entityId) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function deactivateBusinessUser() {
  return gql`
    mutation deactivateBusinessUser($entityId: String!) {
      salesforceManagement {
        entities {
          businessUser {
            salesforceManagement {
              deactivateBusinessUser(entityId: $entityId) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getBusinessPartnersList() {
  return gql`
    query getBusinessPartnersList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedBusinessUserID: String
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedBusinessUserID: $selectedBusinessUserID
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_AssignedBP_Id
              businessPartner_Id
              businessPartner_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                    }
                  }
                }
              }
              salesforceManagement_AssignedBP_BusinessUserID {
                id
              }
              salesforceManagement_AssignedBP_BusinessPartnerID {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function getBusinessPartnersPlansList() {
  return gql`
    query getBusinessPartnersPlansList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedBusinessUserID: String
      $selectedBusinessPartnerID: [String]
      $selectedLineID: [String]
      $selectedPlanID: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPPlanList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedBusinessUserID: $selectedBusinessUserID
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            selectedLineID: $selectedLineID
            selectedPlanID: $selectedPlanID
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              businessPartner_Id
              businessPartner_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_Id
              salesforceManagement_AssignedBPPlan_BusinessPartnerID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Code
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_BusinessUserID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Code
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_BusinessPartnerLineID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_Status
            }
          }
        }
      }
    }
  `;
}

export function activateAssignedBPPlan() {
  return gql`
    mutation activateAssignedBPPlan(
      $businessUserID: String!
      $selectedAssignedBPPlanIds: [String!]!
    ) {
      salesforceManagement {
        actions {
          activateAssignedBPPlan(
            businessUserID: $businessUserID
            selectedAssignedBPPlanIds: $selectedAssignedBPPlanIds
          )
        }
      }
    }
  `;
}

export function deactivateAssignedBPPlan() {
  return gql`
    mutation deactivateAssignedBPPlan(
      $businessUserID: String!
      $selectedAssignedBPPlanIds: [String!]!
    ) {
      salesforceManagement {
        actions {
          deactivateAssignedBPPlan(
            businessUserID: $businessUserID
            selectedAssignedBPPlanIds: $selectedAssignedBPPlanIds
          )
        }
      }
    }
  `;
}

export function getAssignedBPLineListWithoutPagination() {
  return gql`
    query getAssignedBPLineListWithoutPagination(
      $selectedBusinessUserID: String!
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPListWithoutPagination(
            selectedBusinessUserID: $selectedBusinessUserID
          ) {
            salesforceManagement_AssignedBP_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    PersonID {
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          getAssignedBPPlanListWithoutPagination(
            selectedBusinessUserID: $selectedBusinessUserID
          ) {
            salesforceManagement_AssignedBPPlan_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getAssignedPlanListWithoutPagination() {
  return gql`
    query getAssignedPlanListWithoutPagination(
      $selectedBusinessUserID: String!
      $selectedLineID: [String]
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPPlanListWithoutPagination(
            selectedBusinessUserID: $selectedBusinessUserID
            selectedLineID: $selectedLineID
          ) {
            salesforceManagement_AssignedBPPlan_PlanID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
