import React from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import EnhancedChipInput from '../../../components/enhanced-form/EnhancedChipInput';
import EnhancedInput from '../../../components/enhanced-form/EnhancedInput';

interface IProposalTravelDetailsPageProps {
  pageState: any;
  onPageStateUpdate: (pageState: any) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  data: any;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  section: {
    padding: '23px 0 25px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 23.5%)',
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  fullFieldRow: {
    gridColumn: '1 / -1',
    width: '100%',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    margin: '0px!important',
    padding: '0px!important',
  },
  labelDropdown: {
    color: '#000000',
    margin: '5px 0 0 0 !important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const ProposalTravelDetailsWidget: React.FC<
  IProposalTravelDetailsPageProps
> = ({ pageState, onPageStateUpdate, disabledForm, data, lovs }) => {
  const { classes } = useStyles();

  const values = pageState.values.travelDetails;
  const errors = pageState.errors.travelDetails;
  const touched = pageState.touched.travelDetails;

  const onFieldBlur = (fieldName: string) => {
    const newPageState = { ...pageState };
    newPageState.touched.travelDetails[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (fieldName: string, value: any) => {
    const newPageState = { ...pageState };
    newPageState.values.travelDetails[fieldName] = value;
    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Travel Details"
        classes={{ container: classes.section }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <EnhancedChipInput
            key="destination"
            name="destination"
            title="Destination*"
            placeholder="Destination"
            value={values.destination}
            error={touched.destination ? errors.destination : ''}
            onChange={(v) => onFieldUpdate('destination', v)}
            disabled={disabledForm}
            selectOptions={lovs.destinations}
            required
            multiple={false}
            material
            customStyles={{ labelStyles: classes.labelDropdown }}
          />

          <EnhancedInput
            key="duration"
            name="duration"
            title="Duration (in Days)"
            placeholder="Duration"
            value={values.duration}
            error={touched.duration ? errors.duration : ''}
            onBlur={() => onFieldBlur('duration')}
            onChange={(e) => onFieldUpdate('duration', e.target.value)}
            disabled={disabledForm}
            type="number"
            material
            customStyles={{ labelStyles: classes.labelHeader }}
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalTravelDetailsWidget;
