import { gql } from '@apollo/client';

export function getDefaultCommissionEnums() {
  return gql`
    query getDefaultCommissionEnums {
      Core_CommissionTypes: __type(name: "Core_CommissionTypes") {
        name
        enumValues {
          name
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
          commissionApplicableOnOptions {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getBusinesspartnerAssignedLineInfo() {
  return gql`
    query getBusinesspartnerAssignedLineInfo($id: String!) {
      SalesforceManagement {
        entities {
          businessPartnerLine(id: $id) {
            id
            views {
              SalesforceManagement_all {
                properties {
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                        }
                      }
                    }
                  }
                  BusinessPartnerID {
                    id
                  }
                  Status
                  LineCommissionType
                  LineCommissionValue
                  ValueCurrency {
                    Id
                    Title
                  }
                  LineCommissionRate
                  ApplicableOn {
                    Id
                    Title
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function setBusinessPartnerLineCommission() {
  return gql`
    mutation setBusinessPartnerLineCommission(
      $entityId: String!
      $lineCommissionInputs: SalesforceManagement_LineCommissionInputs!
    ) {
      salesforceManagement {
        entities {
          businessPartnerLine {
            salesforceManagement {
              setBusinessPartnerLineCommission(
                entityId: $entityId
                lineCommissionInputs: $lineCommissionInputs
              ) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      LineCommissionType
                      LineCommissionValue
                      ValueCurrency {
                        Code
                        Title
                      }
                      LineCommissionRate
                      ApplicableOn {
                        Code
                        Title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getBusinessPartnerPlansLinesListWithoutPagination() {
  return gql`
    query getBusinessPartnerPlansLinesListWithoutPagination(
      $selectedBusinessPartnerID: String
      $SelectedStatuses: [SalesforceManagement_BusinessPartnerLineStatuses]
    ) {
      SalesforceManagement {
        queries {
          getBusinessPartnerLinesListWithoutPagination(
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            SelectedStatuses: $SelectedStatuses
          ) {
            salesforceManagement_BusinessPartnerLine_Id
            salesforceManagement_BusinessPartnerLine_LineCommissionType
            salesforceManagement_BusinessPartnerLine_LineCommissionRate
            salesforceManagement_BusinessPartnerLine_LineCommissionValue
            salesforceManagement_BusinessPartnerLine_ValueCurrency {
              Id
              Code
              Title
            }
            salesforceManagement_BusinessPartnerLine_ApplicableOn {
              Id
              Code
              Title
            }
            salesforceManagement_BusinessPartnerLine_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getPlanListByAssignedLines() {
  return gql`
    query getPlanListByAssignedLines(
      $selectedBusinessPartnerID: String
      $selectedLineID: String
      $SelectedStatuses: [PlanConfigManagement_PlanStatuses]
    ) {
      SalesforceManagement {
        queries {
          GetPlanListByAssignedLines(
            SelectedStatuses: $SelectedStatuses
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            selectedLineID: $selectedLineID
          ) {
            planConfigManagement_Plan_Id
            planConfigManagement_Plan_Name
            planConfigManagement_Plan_EffectiveFrom
            planConfigManagement_Plan_EffectiveTo
            planConfigManagement_Plan_SpecialCommissionApplicable
            planConfigManagement_Plan_SpecialCommissionType
            planConfigManagement_Plan_SpecialCommissionValue
            planConfigManagement_Plan_SpecialCommissionValueCurrency {
              Code
              Title
            }
            planConfigManagement_Plan_SpecialCommissionRate
            planConfigManagement_Plan_SpecialCommissionApplicableOn {
              Code
              Title
            }
          }
        }
      }
    }
  `;
}

export function createBusinessPartnerPlan() {
  return gql`
    mutation createBusinessPartnerPlan(
      $entityId: String!
      $selectedPlanIDs: [String!]!
      $businessPartnerLineID: String!
      $businessPartnerPlanInputs: SalesforceManagement_BusinessPartnerPlanInputs!
    ) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              createBusinessPartnerPlan(
                entityId: $entityId
                businessPartnerLineID: $businessPartnerLineID
                selectedPlanIDs: $selectedPlanIDs
                businessPartnerPlanInputs: $businessPartnerPlanInputs
              ) {
                Items
              }
            }
          }
        }
      }
    }
  `;
}
