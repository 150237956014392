import { useState, useEffect } from 'react';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../components/form-fields/table';

export default function useActionFilter(
  actions?: IEnhancedMenuItem[]
): [IEnhancedMenuItem[], IEnhancedMenuItem[]] {
  const [widgetActions, setWidgetActions] = useState<IEnhancedMenuItem[]>([]);
  const [entityActions, setEntityActions] = useState<IEnhancedMenuItem[]>([]);

  useEffect(() => {
    const curWidgetAct: IEnhancedMenuItem[] = [];
    const curEntityAct: IEnhancedMenuItem[] = [];
    if (actions) {
      actions.forEach((action) => {
        if (action.target === ActionTarget.Entity) {
          curEntityAct.push(action);
        } else {
          curWidgetAct.push(action);
        }
      });
      setWidgetActions(curWidgetAct);
      setEntityActions(curEntityAct);
    }
  }, [actions]);

  return [widgetActions, entityActions];
}
