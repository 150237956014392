import React, { useCallback, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { IEnhancedCommonProps } from '..';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME, TinyMCEApiKey } from '../../constants';
import clsx from 'clsx';

export interface IEnhancedEditor extends IEnhancedCommonProps {
  name: string;
  title: string;
  placeholder: string;
  error?: string;
  value: string;
  onChange: (name: string, value: string) => void;
  onBlur?: (name: string) => void;
  disabled?: boolean;
  htmlToAdd?: string;
  addButton?: boolean;
  customStyles?: {
    containerStyles?: string;
    labelStyles?: string;
    inputStyles?: string;
    errorStyles?: string;
  };
  width?: string;
}

const useStyles = makeStyles()((theme) => ({
  label: {
    display: 'block',
    color: MAIN_ONE_THEME.palette.primary1.main,
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.regular.reg1.fontSize,
    lineHeight: '15px',
    marginBottom: '13px',
  },
  error: {
    color: 'red',
    margin: '5px 0 0',
    display: 'block',
    fontSize: '11px',
    minHeight: '15px',
    textAlign: 'right',
  },
  editorContainer: {
    width: '100%',
    height: '150px',
    minHeight: '150px',
    maxHeight: '150px',
  },
}));

const EnhancedTinyMCEEditor: React.FC<IEnhancedEditor> = ({
  name,
  title,
  placeholder,
  error,
  value,
  onChange,
  disabled,
  htmlToAdd = '',
  onBlur = () => undefined,
  addButton = false,
  customStyles = {},
  width = '95%',
}) => {
  const { classes } = useStyles();
  const editorRef = useRef(null);
  const buttonRef = useRef({ onAction: () => undefined });

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const editor = editorRef.current;
      if (editor) {
        event.preventDefault();
        editor.execCommand('mceInsertContent', false, '<br />');

        // Move the cursor to the start of the new line
        const selection = editor.selection.getSel();
        const range = document.createRange();
        range.setStartAfter(editor.selection.getNode().nextSibling);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const insertHtml = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.execCommand('mceInsertContent', false, htmlToAdd);
    }
  }, [htmlToAdd]);

  useEffect(() => {
    const tinymce = (window as any).tinymce;
    if (tinymce && editorRef.current) {
      // Update the button action reference
      buttonRef.current.onAction = insertHtml;

      // Register the button if not already registered
      if (
        !tinymce.activeEditor.ui.registry.getAll().buttons.customInsertButton
      ) {
        tinymce.activeEditor.ui.registry.addButton('customInsertButton', {
          text: 'Add Clause',
          onAction: () => buttonRef.current.onAction(),
        });
      }

      // Attach the keydown event listener
      tinymce.activeEditor.on('keydown', handleKeyDown);
    }
  }, [htmlToAdd, insertHtml, handleKeyDown]);

  return (
    <div style={{ display: 'relative', height: '100%', width: width }}>
      <span className={clsx(classes.label, customStyles?.labelStyles)}>
        {title}
      </span>
      <div
        style={{
          height: '280px',
          minHeight: '280px',
          maxHeight: '280px',
          overflowY: 'auto',
        }}
      >
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          apiKey={TinyMCEApiKey}
          init={{
            directionality: 'ltr',
            plugins: 'lists',
            textpattern_text_alignments: 'Left Align',
            statusbar: false,
            placeholder,
            branding: false,
            setup: (editor) => {
              if (addButton) {
                editor.ui.registry.addButton('customInsertButton', {
                  text: 'Add Clause',
                  onAction: () => buttonRef.current.onAction(),
                });
              }
            },
            height: 280,
            menubar: false,
            resize: false,
            toolbar_mode: 'sliding',
            toolbar:
              'emoticons |  forecolor backcolor |' +
              'bold italic underline strikethrough | ' +
              'numlist bullist | customInsertButton',
            font_size_formats:
              '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt',
            font_family_formats:
              'Arial=arial; Arial Black=arial black; Courier New=courier new,courier; Georgia=georgia; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times;',
          }}
          value={value}
          onEditorChange={(stringifiedHtmlValue) => {
            onChange(name, stringifiedHtmlValue);
          }}
          disabled={disabled}
          onBlur={() => onBlur(name)}
        />
      </div>
      <span className={classes.error}>{error}</span>
    </div>
  );
};

export default EnhancedTinyMCEEditor;
