import { IGenericInvoiceRecordsTableData } from '.';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { valueCommaSeparated } from '../../utils/formatting-utils';
import { steps } from './content';

export function entityToInvoiceDetails(data: any): any {
  const invoiceDetailsInfo =
    data?.Accounting?.entities?.invoices?.views?.Accounting_all?.properties;

  const list: IAbstractRecord = {};

  data?.Accounting?.entities?.invoices?.queries.accounting?.GetInvoiceRecords.forEach(
    (obj: IGenericInvoiceRecordsTableData, i: number) => {
      list[i] = {
        invoiceRecord_Id: obj.invoiceRecord_Id,
        invoiceRecord_Description: obj.invoiceRecord_Description,
        invoiceRecord_Amount: `${invoiceDetailsInfo?.InvoiceCurrency?.Symbol} ${valueCommaSeparated(obj.invoiceRecord_Amount)}`,
        invoiceRecord_SystemAmount: `LBP ${valueCommaSeparated(obj.invoiceRecord_SystemAmount)}`,
        invoiceRecord_Product:
          obj.invoiceRecord_Product?.views?.Accounting_all?.properties
            ?.ProductName,
      };
    }
  );

  return {
    InvoiceNumber: invoiceDetailsInfo?.InvoiceNumber,
    CreatedOn: invoiceDetailsInfo?.createdOn,
    ModifiedOn: invoiceDetailsInfo?.modifiedOn,
    ModifiedBy:
      data?.Accounting?.entities?.invoices?.queries?.accounting?.relatedUsers[0]
        ?.modifiedBy_firstName +
      ' ' +
      data?.Accounting?.entities?.invoices?.queries?.accounting?.relatedUsers[0]
        ?.modifiedBy_lastName,
    CreatedBy:
      data?.Accounting?.entities?.invoices?.queries?.accounting?.relatedUsers[0]
        ?.createdBy_firstName +
      ' ' +
      data?.Accounting?.entities?.invoices?.queries?.accounting?.relatedUsers[0]
        ?.createdBy_lastName,
    status: invoiceDetailsInfo?.InvoiceStatus,
    balance: invoiceDetailsInfo?.InvoiceRemainingAmount,
    dueOn: invoiceDetailsInfo?.PaymentDate,
    customer:
      invoiceDetailsInfo?.RelatedCustomer?.views?.Accounting_all?.properties
        ?.CustomerName,
    address:
      data?.Accounting?.entities?.invoices?.queries?.accounting
        ?.GetCustomerDetails[0]?.customer_BillingAddress?.AddressDetails,
    country:
      data?.Accounting?.entities?.invoices?.queries?.accounting
        ?.GetCustomerDetails[0]?.customer_BillingAddress?.Country?.Title,
    city: data?.Accounting?.entities?.invoices?.queries?.accounting
      ?.GetCustomerDetails[0]?.customer_BillingAddress?.City,
    invoiceDate: invoiceDetailsInfo?.InvoiceDate,
    paymentDate: invoiceDetailsInfo?.PaymentDate,
    referenceNumber: invoiceDetailsInfo?.ReferenceNumber,
    currency: invoiceDetailsInfo?.InvoiceCurrency?.Title,
    currencySymbol: invoiceDetailsInfo?.InvoiceCurrency?.Symbol,
    note: invoiceDetailsInfo?.InvoiceNote,
    tableData: {
      pagedItems: list,
      pageSize: 5,
      pageNumber: 1,
      totalCount: 5,
    },
  };
}

export const getPersonStatus = (status: string) => {
  switch (status) {
    case 'inactive':
      return steps[0];
    case 'active':
      return steps[1];
    default:
      return steps[0];
  }
};

export function mapToPersonAddressesListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.Accounting?.entities?.invoices?.queries.accounting?.GetInvoiceRecords.forEach(
    (obj: IGenericInvoiceRecordsTableData, i: number) => {
      list[i] = {
        invoiceRecord_Id: obj.invoiceRecord_Id,
        invoiceRecord_Description: obj.invoiceRecord_Description,
        invoiceRecord_Amount: obj.invoiceRecord_Amount,
        invoiceRecord_SystemAmount: obj.invoiceRecord_SystemAmount,
        invoiceRecord_Product:
          obj.invoiceRecord_Product?.views?.Accounting_all?.properties
            ?.ProductName,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: 1,
    pageNumber: 1,
    totalCount: 10,
  };
}
