import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { Paper } from '@mui/material';
import { IEnhancedCommonProps } from '..';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';

interface IDialogConfirmationTemplateProps extends IEnhancedCommonProps {
  title?: string;
  description?: string;
  footer?: JSX.Element;
  contentClass?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles()(() => ({
  templateContainer: {
    width: '90%',
    margin: 'auto',
  },
  header: {
    maxWidth: 300,
  },
  title: {
    font: 'normal',
    fontFamily: contentFontFamilyRegular,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: '20px auto 0px 20px',
  },
  description: {
    textAlign: 'center',
    fontFamily: contentFontFamilyRegular,
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: '16px',
    color: MAIN_ONE_THEME.palette.primary1.main,
    margin: '15px auto 0',
  },
  paperRoot: {
    width: '100%',
    padding: '15px',
  },
  paperHeader: {
    textAlign: 'right',
    height: 65,
    padding: 10,
    boxSizing: 'border-box',
  },
  paperContent: {
    padding: '15px 20px 30px 20px',
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
  },
  paperFigure: {
    width: '40%',
  },
  paperChildren: {
    width: '60%',
  },
  footer: {
    margin: '0px 20px 20px 0px !important',
  },
}));

const DialogConfirmationTemplate: React.FC<
  IDialogConfirmationTemplateProps
> = ({
  title,
  description,
  footer,
  children,
  className,
  style,
  contentClass,
}) => {
  const { classes } = useStyles();

  return (
    <section
      className={clsx(classes.templateContainer, className)}
      style={style}
    >
      <Paper classes={{ root: classes.paperRoot }} className={contentClass}>
        {(title || description) && (
          <header className={classes.header}>
            {title && <h4 className={classes.title}>{title}</h4>}
            {description && (
              <p className={classes.description}>{description}</p>
            )}
          </header>
        )}
        <div className={classes.paperContent}>{children}</div>
        <div className={classes.footer}>{footer}</div>
      </Paper>
    </section>
  );
};

export default DialogConfirmationTemplate;
