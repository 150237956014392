import React from 'react';
import { IPaymentTermsPopupProps } from '.';
import EnhancedModal from '../../components/common/generic-modal/EnhancedModal';
import PaymentTermsForms from './PaymentTermsForms';

const PaymentTermsPopup: React.FunctionComponent<IPaymentTermsPopupProps> = ({
  policyId,
  policyNumber,
  currencySymbol,
  data,
  open,
  onClose,
}) => {
  return (
    <EnhancedModal
      open={open}
      blur={true}
      onClose={() => {
        onClose(false);
      }}
      title={`Change Payment Terms (${policyNumber})`}
    >
      <PaymentTermsForms
        policyId={policyId}
        currencySymbol={currencySymbol}
        onClose={onClose}
        data={data}
      />
    </EnhancedModal>
  );
};

export default PaymentTermsPopup;
