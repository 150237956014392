import React, { useMemo } from 'react';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { IProductionDetailsSummary } from '..';

interface IMedicalDetailsWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionPolicyMedicalDetailsWidget: React.FC<
  IMedicalDetailsWidgetProps
> = ({ data }) => {
  const dynamicView = useMemo(
    (): IDynamicDiplayView => ({
      name: 'PolicyMedicalDetails',
      sections: [
        {
          name: 'MedicalDetails',
          title: 'Medical Details',
          properties: {
            class: {
              multiline: false,
              value: data?.Medical?.Class,
              name: 'class',
              title: 'Class',
            },
            level: {
              multiline: false,
              value: data?.Medical?.Level,
              name: 'level',
              title: 'Level',
            },
            cnss: {
              multiline: false,
              value: data?.Medical?.Nssf,
              name: 'cnss',
              title: 'CNSS',
            },
            policyCover: {
              multiline: false,
              value: data?.Medical?.PolicyCover,
              name: 'policyCover',
              title: 'Policy Cover',
              fullLine: true,
            },
          },
        },
      ],
    }),
    [data]
  );

  return <DynamicWidget hasFourFields={false} view={dynamicView} />;
};

export default ProductionPolicyMedicalDetailsWidget;
