import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { initialValues, IInvoiceDetails } from '.';
import Loader from '../../components/Loader';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import EntityInfoWidget from '../../components/widgets/entity-info/EntityInfoWidget';
import WidgetSection from '../../components/common/WidgetSection';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingData } from '../../models/listing';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { entityView, headers } from './content';
import { getInvoiceInfo } from './queries';
import { entityToInvoiceDetails } from './utils';
import dayjs from 'dayjs';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { IDynamicDiplayView } from '../../components/widgets/dynamic-display';
import DynamicWidget from '../../components/widgets/dynamic-display/DynamicWidget';
import {
  valueCommaSeparated,
  capitalizeFirstLetter,
} from '../../utils/formatting-utils';
import PersonDetailsDrawer from '../personal-information-drawer/PersonDetailsDrawer';
import { IPersonDetailsDrawerInfo } from '../personal-information-drawer';
import { IGenericInvoiceRecordsTableData } from './index';

const InvoiceDetailsPage: React.FC = () => {
  //#region react and redux hooks
  const params = useParams();

  //const tenant = useAppSelector((state) => state.tenant);
  //const user = useAppSelector((state) => state.user);
  const [personInfoDrawerOpen, setPersonInfoDrawerOpen] =
    useState<boolean>(false);

  // const [selectedPersonAddressItems, setSelectedPersonAddressItems] =
  //   useState<number>(0);

  const [booted, setBooted] = useState<boolean>(false);

  // const [tableOrders, setTableOrders] = useState<Record<string, ITableOrder>>({
  //   address: {
  //     orderBy: "",
  //     orderDirection: "asc",
  //   },
  // });

  //#endregion

  //#region component hooks and states
  const [personDetailsInfo, setPersonDetailsInfo] =
    useState<IInvoiceDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const [personalInformationViewConfig, setPersonalInformationViewConfig] =
    useState<IDynamicDiplayView>();

  const [noteViewConfig, setNoteViewConfig] = useState<IDynamicDiplayView>();

  const [invoiceInformationViewConfig, setInvoiceInformationViewConfig] =
    useState<IDynamicDiplayView>();

  const [tableData, setTableData] = useState<
    IListingData<IGenericInvoiceRecordsTableData>
  >({
    pagedItems: {},
    pageSize: 5,
    pageNumber: 1,
    totalCount: 0,
  });

  const entityId = params.id;

  //#endregion

  // #region graphql hooks
  const [getPersonDetailsLazy, entityResult] = useLazyQuery(getInvoiceInfo(), {
    variables: { id: entityId },
  });

  // const [getInvoiceRecordsLazy, entityResult1] = useLazyQuery(
  //   getInvoiceInfo(),
  //   {
  //     variables: { id: entityId },
  //   }
  // );

  // useEffect(() => {
  //   if (!entityResult1.loading) {
  //     setLoadingState(false);
  //   }

  //   if (entityResult1?.data) {
  //     const tableData = mapToPersonAddressesListingData(
  //       entityResult1?.data
  //     );
  //     setTableData({ ...tableData, pageNumber: 0 });;
  //   }

  //   setBooted(true);
  //   setLoadingState(false);
  // }, []);

  //#endregion

  //#region useEffect
  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const invoiceDetails = entityToInvoiceDetails(entityResult?.data);

        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.status.value = invoiceDetails.status;
        applicationProperties.properties.customer.value =
          invoiceDetails.customer;
        applicationProperties.properties.balance.value = `LBP ${valueCommaSeparated(invoiceDetails.balance)}`;
        applicationProperties.properties.dueOn.value = invoiceDetails.dueOn;

        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value = invoiceDetails.CreatedBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(invoiceDetails.CreatedOn)
        ).format('DD/MM/YYYY');
        systemProperties.properties.modifiedBy.value =
          invoiceDetails.ModifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(invoiceDetails.ModifiedOn)
        ).format('DD/MM/YYYY');

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };
        setPersonDetailsInfo(invoiceDetails);
        setUpdatedLeftSideInfo(newLeftEntityView);

        const dynamicView: IDynamicDiplayView = {
          name: 'Personal Information',
          sections: [
            {
              name: 'billTo',
              title: 'Bill to',
              showActionButtons: false,
              showSection: true,
              properties: {
                name: {
                  multiline: false,
                  value: invoiceDetails.customer,
                  name: 'name',
                  title: 'Name',
                },
                address: {
                  multiline: false,
                  value: invoiceDetails.address,
                  name: 'address',
                  title: 'Address Lines',
                },
                country: {
                  multiline: false,
                  value: invoiceDetails.country,
                  name: 'country',
                  title: 'Country',
                },
                city: {
                  multiline: false,
                  value: invoiceDetails.city,
                  name: 'city',
                  title: 'City',
                },
                postalCode: {
                  multiline: false,
                  value: invoiceDetails.postalCode,
                  name: 'postalCode',
                  title: 'Postal Code',
                },
              },
            },
          ],
        };

        const dynamicView2: IDynamicDiplayView = {
          name: 'Invoice Details',
          sections: [
            {
              name: 'invoiceDetails',
              title: 'Invoice Details',
              showActionButtons: false,
              showSection: true,
              properties: {
                invoiceNumber: {
                  multiline: false,
                  value: invoiceDetails.InvoiceNumber,
                  name: 'invoiceNumber',
                  title: 'Invoice #',
                },
                referenceNumber: {
                  multiline: false,
                  value: invoiceDetails.referenceNumber,
                  name: 'address',
                  title: 'Reference Number #',
                },
                currency: {
                  multiline: false,
                  value: invoiceDetails.currency,
                  name: 'currency',
                  title: 'Currency',
                },
                invoiceDate: {
                  multiline: false,
                  value: invoiceDetails.invoiceDate,
                  name: 'invoiceDate',
                  title: 'Invoice Date',
                },
                paymentDate: {
                  multiline: false,
                  value: invoiceDetails.paymentDate,
                  name: 'paymentDate',
                  title: 'Payment Date',
                },
              },
            },
          ],
        };
        const dynamicView3: IDynamicDiplayView = {
          name: 'Notes',
          sections: [
            {
              name: 'notes',
              title: 'Notes',
              showActionButtons: false,
              showSection: true,
              properties: {
                note: {
                  multiline: true,
                  value: invoiceDetails.note,
                  name: 'note',
                  title: '',
                },
              },
            },
          ],
        };
        setPersonalInformationViewConfig(dynamicView);
        setInvoiceInformationViewConfig(dynamicView2);
        setNoteViewConfig(dynamicView3);
        setTableData({ ...invoiceDetails.tableData, pageNumber: 0 });

        setBooted(true);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  //#endregion

  //#region functions

  // useEffect(() => {
  //   getInvoiceRecordsLazy();
  // }, []);

  useEffect(() => {
    getPersonDetailsLazy();
  }, []);

  const renderMainChildren = () => {
    return (
      <>
        <div style={{ marginTop: '20px' }}>
          {personalInformationViewConfig && (
            <>
              <div style={{ width: '48%', display: 'inline-block' }}>
                <DynamicWidget
                  hasFourFields={false}
                  view={personalInformationViewConfig}
                />
              </div>
              <div
                style={{
                  width: '48%',
                  display: 'inline-block',
                  position: 'absolute',
                  right: 0,
                  marginRight: '1em',
                }}
              >
                <DynamicWidget
                  hasFourFields={false}
                  view={invoiceInformationViewConfig}
                />
              </div>
            </>
          )}
          <>
            <WidgetSection style={{ margin: '-23px 0 0 0' }}>
              <form>
                <div
                  style={{
                    marginTop: '20px',
                    padding: '-23px 0 0 0 !important',
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter('Invoice Records')}
                    inlineTitle={capitalizeFirstLetter('Invoice Records')}
                    name="count"
                    entityName={'InvoiceRecords'}
                    entityIdColumnName={'invoiceRecord_Id'}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={tableData}
                    headers={headers}
                    currentPage={tableData.pageNumber}
                    hideToolbar={false}
                    usePagination={false}
                    disableSelection={true}
                    loader={loadingState}
                    showTablePagination={false}
                    // ordering={
                    //   tableOrders.address.orderDirection as TableSortOrder
                    // }
                    // orderingBy={tableOrders.address.orderBy}
                  />
                </div>
              </form>
            </WidgetSection>
            <>
              <div style={{ width: '48%', display: 'inline-block' }}>
                <DynamicWidget hasFourFields={false} view={noteViewConfig} />
              </div>
            </>
          </>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: '' }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={personDetailsInfo.InvoiceNumber}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };

  //#endregion

  return !booted ? (
    <Loader />
  ) : (
    <>
      <div>
        {personInfoDrawerOpen && (
          <PersonDetailsDrawer
            open={personInfoDrawerOpen}
            onClose={() => setPersonInfoDrawerOpen(false)}
            onSuccess={() => {
              getPersonDetailsLazy();
            }}
            personDetailsInfo={
              personDetailsInfo as unknown as IPersonDetailsDrawerInfo
            }
            personId={params.id}
          />
        )}
      </div>
      <div></div>

      <StaticLayout
        name={'Person Details'}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
    </>
  );
};

export default InvoiceDetailsPage;
