import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function toLookups(data: any) {
  const personDetailsList: Record<string, Record<string, string>> = {};

  personDetailsList[data.SalesforceManagement_PersonTypes.name] =
    enumListAsRecordObject(data.SalesforceManagement_PersonTypes.enumValues);

  if (personDetailsList[data.SalesforceManagement_PersonTypes.name]['PERSON']) {
    personDetailsList[data.SalesforceManagement_PersonTypes.name]['PERSON'] =
      'Individual';
  }

  personDetailsList['phoneType'] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );
  personDetailsList['genders'] = enumListAsRecordObject(
    data?.Insurance_Genders?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  personDetailsList['maritalStatuses'] = enumListAsRecordObject(
    data?.Core_MaritalStatuses?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  personDetailsList['isSmoker'] = enumListAsRecordObject(
    data?.Core_IsSmoker?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  personDetailsList['titles'] = lookupListAsRecordObject(
    data.Insurance.lookups.titles
  );

  personDetailsList['nationalities'] = lookupListAsRecordObject(
    data.Insurance.lookups.nationalities
  );

  personDetailsList['countries'] = lookupListAsRecordObject(
    data.Insurance.lookups.countries
  );

  personDetailsList['occupationClasses'] = lookupListAsRecordObject(
    data.Insurance.lookups.occupationClasses
  );

  personDetailsList['personsCategories'] = lookupListAsRecordObject(
    data.SalesforceManagement.lookups.personsCategories
  );

  return personDetailsList;
}
