import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import React, { useState } from 'react';
import { IEnhancedCommonProps } from '..';
import { makeStyles } from 'tss-react/mui';
import { Collapse, IconButton } from '@mui/material';
import {
  contentFontFamilyBoldImportant,
  MAIN_ONE_THEME,
} from '../../constants';
import EnhancedButton from '../EnhancedButton';

interface ICollapsavleSectionProps extends IEnhancedCommonProps {
  title: string;
  open?: boolean;
  className?: string;
  classes?: { header?: string; content?: string };
  children?: React.ReactNode;
  actions?: JSX.Element;
  onButtonClick?: () => void;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    fontFamily: contentFontFamilyBoldImportant,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg4.fontSize}px !important`,
  },
  header: {
    cursor: 'pointer',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg4.fontSize} !important`,
    lineHeight: '27px',
    color: '#706e6b',
    '&:hover': {
      color: 'black',
    },
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    background: 'unset',
    border: 'none',
    width: '5000px',
  },
  content: {
    padding: '0px 12px 12px 44px',
  },
  collapse: {
    transform: 'rotate(0deg)',
    transition: '150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  collapseOpen: {
    transform: 'rotate(180deg)',
  },
  actions: {
    width: '20%',
    height: '50px',
    textAlign: 'center',
    backgroundColor: 'black',
    color: 'white',
  },
}));

const CollapsibleSection: React.FC<ICollapsavleSectionProps> = ({
  title,
  open,
  className,
  classes = {},
  children,
  actions,
  onButtonClick,
}) => {
  const { classes: collapsableClasses } = useStyles();
  const [collapse, setCollapse] = useState(!!open);

  function toggleCollapse() {
    setCollapse(!collapse);
  }

  return (
    <div className={collapsableClasses.container}>
      <div
        role="presentation"
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <IconButton
            aria-label={'Show More'}
            className={clsx(collapsableClasses.collapse, {
              [collapsableClasses.collapseOpen]: collapse,
            })}
            onClick={toggleCollapse}
          >
            <ExpandMoreIcon />
          </IconButton>
          {title}
        </div>
        {actions && (
          <EnhancedButton
            type="button"
            backgroundColor={'#000'}
            color={'white'}
            onClick={onButtonClick}
          >
            {'Modify'}
          </EnhancedButton>
        )}
      </div>
      <Collapse
        in={collapse}
        timeout={150}
        className={clsx(collapsableClasses.content, classes.content)}
        classes={
          className
            ? {
                wrapperInner: className,
              }
            : {}
        }
      >
        {/* {actions && (
          <>
            <EnhancedButton
              type="button"
              backgroundColor={'#000'}
              color={'white'}
            >
              {'Modify'}
            </EnhancedButton>
          </>
        )} */}
        {children}
      </Collapse>
    </div>
  );
};

export default CollapsibleSection;
