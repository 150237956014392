/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import AuthLayout from '../../layouts/AuthBody';
import WidgetPaper from '../../components/common/WidgetPaper';
import WidgetSection from '../../components/common/WidgetSection';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../constants';
import { Controller, useForm } from 'react-hook-form';
import EnhancedInput from '../../components/enhanced-form/EnhancedInput';
import { useQuery } from '@apollo/client';
import { getSystemCurrencies } from './queries';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ISystemCurrency, initialValues } from '.';
import SystemCurrencyConfigDrawer from '../system-currency-config-drawer/SystemCurrencyConfigDrawer';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: '15px',
  },
  header: {
    margin: '0 0 3em',
  },
  title: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: 'HelveticaNeue-Medium ',
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: 'normal',
    width: '103%',
    gridArea: 'title',
    alignSelf: 'center',
  },
  mainContainer: {
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'flex-start',
    width: '100%',
    backgroundColor: 'rgb(247, 247, 247)',
  },
  logoFig: {
    margin: '0 auto',
    lineHeight: '0',
  },
  loginBoxContainer: {
    width: '430px',
    margin: '46px auto 0',
    maxWidth: '491px',
  },
  formContainer: {
    padding: '24px 30px',
    width: '100%',
  },
  formError: {
    color: theme.palette.error.main,
    height: 'fit-content',
    marginTop: '8px',
    boxSizing: 'border-box',
    padding: '0px 12px 0px 12px',
    fontSize: '11px',
    textAlign: 'center',
  },
  button: {
    background: 'none',
    border: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0,
    paddingTop: '0px',
    marginTop: '-6px',
  },
  labelContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
  },
  labelText: {
    marginRight: '10px',
  },
  infoIcon: {
    width: '15px',
    height: '15px',
    border: '0',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    lineHeight: '16px',
    position: 'absolute',
    top: '0',
    right: '-10px',
    cursor: 'pointer',
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: '12px',
    padding: '10px',
  },
  arrow: {
    color: 'white',
    '&::before': {
      boxShadow: theme.shadows[1],
    },
  },
}));

const SystemCurrencyConfig: React.FC = () => {
  const { classes } = useStyles();
  const [sysCurrencyConfigDrawerOpen, setSysCurrencyConfigDrawerOpen] =
    useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(true);

  const entityResult = useQuery(getSystemCurrencies(), {
    variables: {},
  });

  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  const { control } = useForm<ISystemCurrency>({
    defaultValues: initialValues,
    shouldUnregister: true,
  });

  function openSysCurrencyConfigDrawer() {
    setSysCurrencyConfigDrawerOpen(true);
  }

  return loadingState ? (
    <Loader />
  ) : (
    <AuthLayout mainContainer={classes.mainContainer}>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <section className={classes.header}>
            <h1 className={classes.title}>{'System Currency'}</h1>
          </section>
        </div>
        <div className={classes.loginBoxContainer}>
          <WidgetPaper>
            <WidgetSection
              title="System Currency"
              narrowSpacing={false}
              style={{ margin: '0' }}
              useSeparator={false}
            >
              <Controller
                name="basicCurrency"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className={classes.labelContainer}>
                      <label className={classes.labelText}>
                        {'Basic Currency'}
                      </label>
                      <Tooltip
                        title="Local Currency"
                        classes={{
                          tooltip: classes.tooltip,
                          arrow: classes.arrow,
                        }}
                        arrow
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </div>
                    <EnhancedInput
                      type="text"
                      name="basicCurrency"
                      title=""
                      onChange={(e) => {
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        onBlur();
                      }}
                      value={
                        entityResult?.data?.Core?.queries
                          ?.getSystemCurrencies[0]
                          ?.core_SystemCurrency_BasicCurrency?.Title
                      }
                      specificMaterial
                      disabled
                    />
                  </div>
                )}
              />

              <Controller
                name="secondaryCurrency"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div className={classes.labelContainer}>
                        <label className={classes.labelText}>
                          {'Secondary Currency'}
                        </label>
                        <Tooltip
                          title="Foreign Currency"
                          classes={{
                            tooltip: classes.tooltip,
                            arrow: classes.arrow,
                          }}
                          arrow
                        >
                          <InfoIcon className={classes.infoIcon} />
                        </Tooltip>
                      </div>
                      <EnhancedInput
                        type="text"
                        name="secondaryCurrency"
                        title=""
                        onChange={(e) => {
                          onChange(e);
                        }}
                        onBlur={(e) => {
                          onBlur();
                        }}
                        value={
                          entityResult?.data?.Core?.queries
                            ?.getSystemCurrencies[0]
                            ?.core_SystemCurrency_SecondaryCurrency?.Title
                        }
                        specificMaterial
                        disabled
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'visible',
                      }}
                    >
                      <label style={{ marginRight: '10px' }}></label>
                      <button
                        type="button"
                        className={classes.button}
                        onClick={openSysCurrencyConfigDrawer}
                      >
                        {'Change'}
                      </button>
                      {sysCurrencyConfigDrawerOpen && (
                        <SystemCurrencyConfigDrawer
                          open={sysCurrencyConfigDrawerOpen}
                          systemCurrencyId={
                            entityResult?.data?.Core?.queries
                              ?.getSystemCurrencies[0]?.core_SystemCurrency_Id
                          }
                          basicCurrencyId={
                            entityResult?.data?.Core?.queries
                              ?.getSystemCurrencies[0]
                              ?.core_SystemCurrency_BasicCurrency?.Code
                          }
                          basicCurrencyTitle={
                            entityResult?.data?.Core?.queries
                              ?.getSystemCurrencies[0]
                              ?.core_SystemCurrency_BasicCurrency?.Title
                          }
                          secondaryCurrencyId={
                            entityResult?.data?.Core?.queries
                              ?.getSystemCurrencies[0]
                              ?.core_SystemCurrency_SecondaryCurrency?.Code
                          }
                          secondaryCurrencyTitle={
                            entityResult?.data?.Core?.queries
                              ?.getSystemCurrencies[0]
                              ?.core_SystemCurrency_SecondaryCurrency?.Title
                          }
                          onClose={() => {
                            setSysCurrencyConfigDrawerOpen(false);
                          }}
                          onSuccess={() => undefined}
                        />
                      )}
                    </div>
                  </div>
                )}
              />
            </WidgetSection>
          </WidgetPaper>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SystemCurrencyConfig;
