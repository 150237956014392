import React, { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
// import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { useLazyQuery, useMutation } from '@apollo/client';
import EnhancedTinyMCEEditor from '../../components/enhanced-form/EnhancedTinyMCEEditor';
import {
  createInvoice,
  modifyTransaction,
  getTransactionInfo,
  getEnums,
  getCustomersOfCompany,
} from './queries';
import {
  LookupToList,
  getCustomersOfCompanyToList,
  getInvoicePageFormState,
  graphqlEntityToTransactionInfo,
  getSystemCurrency,
} from './utils';
import Loader from '../../components/Loader';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { isEmpty } from '../../utils/validationUtils';
import { MAIN_ONE_THEME } from '../../constants';
import { getError } from '../../utils/graph-utils';
import EnhancedInput from '../../components/enhanced-form/EnhancedInput';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import EnhancedDatePicker from '../../components/enhanced-form/EnhancedDatePicker';
// import { useAppSelector } from "../../redux/hooks";
import NewChipsInput from '../../components/enhanced-form/NewChipsInput';
import EnhancedButton from '../../components/EnhancedButton';
import { makeStyles } from 'tss-react/mui';
import { IInvoice, IInvoiceFormState, IProduct } from './form';
import _ from 'lodash';
// import dayjs from "dayjs";
import Product, { initialValues } from './custom-section/products';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()(() => ({
  firstGrid: {
    display: 'grid',
    gridTemplateColumns: '3fr 3fr 3fr 3fr',
    gridColumnGap: '21px',
  },
  secondGrid: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    gridColumnGap: '21px',
  },
  documentGrid: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    gridColumnGap: '21px',
  },
  total: {
    display: 'flex',
    justifyContent: 'end',
  },
  dialogPaper: {
    height: '80%',
    width: '80%',
    maxWidth: '1539px',
  },
  title: {
    fontFamily: 'HelveticaNeue-Medium',
    fontSize: '22px',
    lineHeight: '27px',
    padding: '33px 30px 25px',
  },
  container: {
    fontFamily: 'HelveticaNeue-Medium',
    fontSize: '22px',
    lineHeight: '27px',
    padding: '0',
  },
  field: {
    height: '75.21px',
    '& span:first-of-type': {
      lineHeight: '21px',
      marginBottom: '12.54px',
      fontSize: '15px',
      fontFamily: 'SourceSansPro-SemiBold',
    },
    '& input': {
      height: '41px',
    },
  },
  labelSelector: {
    lineHeight: '21px',
    marginBottom: '12.54px',
    fontSize: '15px',
    fontFamily: 'SourceSansPro-SemiBold',
  },
  inputSelector: {
    height: '41px',
  },
  dateField: {
    '& span': {
      color: 'red',
      lineHeight: '21px',
      marginBottom: '12.54px',
    },
  },
  buttonsContainer: {
    width: '100%',
    margin: '10px auto 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: '0 63px 71px 30px',
  },
}));

const invoiceInitialValues = {
  initialValue: {
    company: '',
    reference: '',
    invoiceDate: '',
    paymentDate: '',
    customer: '',
    currency: '',
    note: '',
  },
  initialError: {
    company: '',
    reference: '',
    invoiceDate: '',
    paymentDate: '',
    customer: '',
    currency: '',
    note: '',
  },
  initialTouched: {
    company: false,
    reference: false,
    invoiceDate: false,
    paymentDate: false,
    customer: false,
    currency: false,
    note: false,
  },
};

const InvoicePopUp: React.FC<IInvoicePopUpProps> = ({
  invoiceId,
  open,
  onSuccess,
  onClose,
}) => {
  const navigate = useNavigate();
  //const tenant = useAppSelector((state) => state.tenant);
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    planCovers: {},
  });
  const [systemCurrency, setSystemCurrency] = useState<string>('');
  const [pageState, onPageStateUpdate] = useState<IInvoiceFormState>(
    getInvoicePageFormState()
  );

  const values = pageState.values.invoice;
  const errors = pageState.errors.invoice;
  const touched = pageState.touched.invoice;

  const [transactionEnumsQuery] = useLazyQuery(getEnums());
  const [customersOfCompanyQuery] = useLazyQuery(getCustomersOfCompany());

  const [transactionInfoQuery] = useLazyQuery(getTransactionInfo());

  const [createInvoiceMutation] = useMutation(
    invoiceId ? modifyTransaction() : createInvoice()
  );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const loadTransactionEnums = async () => {
    const result = await transactionEnumsQuery({
      fetchPolicy: 'no-cache',
    });

    return result.data;
  };

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setLoading(true);

    const transactionEnums = await loadTransactionEnums();
    const newTransactionEnums = LookupToList(transactionEnums);

    const newPageState = _.cloneDeep(pageState);

    newPageState.values.invoice = { ...invoiceInitialValues.initialValue };

    newPageState.errors.invoice = { ...invoiceInitialValues.initialError };

    newPageState.touched.invoice = { ...invoiceInitialValues.initialTouched };
    if (newPageState.values.product.length === 0) {
      for (let i = 0; i < 1; i++) {
        newPageState.values.product.push({
          id: null,
          productName: '',
          description: '',
          amount: 0,
          systemAmount: 0,
        });

        newPageState.errors.product.push({ ...initialValues.initialError });

        newPageState.touched.product.push({ ...initialValues.initialTouched });
      }
    }
    setSystemCurrency(getSystemCurrency(transactionEnums));
    onPageStateUpdate(newPageState);
    setLovs({
      companies: newTransactionEnums['relatedCompanies'],
      currencies: newTransactionEnums['currencies'],
      products: newTransactionEnums['products'],
      productCurrencies: newTransactionEnums['productCurrencies'],
    });

    if (invoiceId) {
      const data = await loadTransactionInfo();
      if (data.data) {
        const transactionEntity = graphqlEntityToTransactionInfo(data.data);
        if (transactionEntity) {
          const result = await customersOfCompanyQuery({
            variables: {
              SelectedCompanyID: transactionEntity.RelatedCompany.Id,
            },
            errorPolicy: 'all',
          });

          const customersLovs = getCustomersOfCompanyToList(result?.data);

          setLovs({
            companies: newTransactionEnums['relatedCompanies'],
            customers: customersLovs,
          });
        }
      }
    }

    setLoading(false);
  };

  const onFieldBlur = (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.invoice[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = async (
    fieldName: keyof IInvoice,
    value: any,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);

    if (fieldName === 'company') {
      const result = await customersOfCompanyQuery({
        variables: { SelectedCompanyID: value },
        errorPolicy: 'all',
      });

      const customersLovs = getCustomersOfCompanyToList(result?.data);

      newPageState.values.invoice.customer = '';

      setLovs({
        ...lovs,
        customers: customersLovs,
      });
    }

    (newPageState.values.invoice as any)[fieldName] = value;

    if (touched) {
      newPageState.touched.invoice[fieldName] = true;
    }
    onPageStateUpdate(newPageState);
  };

  const loadTransactionInfo = async () => {
    const result = await transactionInfoQuery({
      variables: { id: invoiceId },
    });
    return result;
  };

  const onSubmit = async (
    values: Record<string, any>,
    totalAmount: number,
    product: IProduct[]
  ) => {
    // const validationResult = validateProposalPageForm(data, pageState.values);

    setFormDisabled(true);
    setSubmitButtonState('loading');
    const newPageState = {
      ...pageState,
      // errors: validationResult.errors,
      // touched: validationResult.touched,
    };
    onPageStateUpdate(newPageState);

    const list: InvoiceRecord[] = [];

    if (product) {
      product.forEach((element) => {
        const record: InvoiceRecord = {
          Amount: element.amount ? Number(element.amount) : 0,
          SystemAmount: element.systemAmount ? Number(element.systemAmount) : 0,
          Id: element.id ? element.id : null,
          Description: element.description,
          SelectedProduct: element.productName,
        };

        list.push(record);
      });
    }

    const result = invoiceId
      ? await createInvoiceMutation({
          variables: {
            EnteredReferenceNumber: values.reference,
            SelectedCompany: values.company,
            TotalAmount: totalAmount,
            SelectedCustomer: values.customer,
            SelectedCurrency: values.currency,
            InvoiceDate: values.invoiceDate,
            PaymentDate: values.paymentDate,
            Note: values.note,
            InvoiceRecords: list,
          },
          errorPolicy: 'all',
        })
      : await createInvoiceMutation({
          variables: {
            EnteredReferenceNumber: values.reference,
            SelectedCompany: values.company,
            TotalAmount: totalAmount,
            SelectedCustomer: values.customer,
            SelectedCurrency: values.currency,
            InvoiceDate: values.invoiceDate,
            PaymentDate: values.paymentDate,
            InvoiceRecords: list,
          },
          errorPolicy: 'all',
        });

    if (isEmpty(result.errors)) {
      toast.success(
        <ToastSuccessMessage>
          {invoiceId
            ? 'Invoice successfully updated'
            : 'Invoice successfully created'}
        </ToastSuccessMessage>
      );
      setTimeout(() => {
        setSubmitButtonState('success');
        onSuccess();
        onClose();
        if (!invoiceId) {
          navigate(`/accounting/invoices/` + invoiceId);
        }
      }, 500);
    } else {
      toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
    }
    setFormDisabled(false);
    setSubmitButtonState(undefined);
  };

  const totalAmount = useMemo(
    () =>
      pageState.values.product.reduce(
        (accumulator, current) => accumulator + Number(current.systemAmount),
        0
      ),
    [pageState.values.product]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
      BackdropProps={{ style: { backdropFilter: 'blur(20px)' } }}
    >
      <DialogTitle className={classes.title}>
        {invoiceId ? 'Modify Invoice' : 'Add New Invoice'}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.container}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className={clsx('container', classes.firstGrid)}>
                {invoiceId === undefined ? (
                  <div className="item">
                    <NewChipsInput
                      key="company"
                      name="company"
                      title="Company*"
                      placeholder="Company"
                      required
                      value={values.company || ''}
                      error={touched.company ? errors.company : ''}
                      disabled={
                        formDisabled ||
                        submitButtonState === 'loading' ||
                        invoiceId !== undefined
                      }
                      items={lovs.companies}
                      onBlur={() => onFieldBlur('company')}
                      onChange={(v) => onFieldUpdate('company', v)}
                      customStyles={{
                        labelStyles: classes.labelSelector,
                        inputStyles: classes.inputSelector,
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className="item">
                  <NewChipsInput
                    key="customer"
                    name="customer"
                    title="Customer*"
                    placeholder="Customer"
                    required
                    value={values.customer || ''}
                    error={touched.customer ? errors.customer : ''}
                    disabled={
                      formDisabled ||
                      submitButtonState === 'loading' ||
                      invoiceId !== undefined
                    }
                    items={lovs.customers}
                    onBlur={() => onFieldBlur('customer')}
                    onChange={(v) => onFieldUpdate('customer', v)}
                    customStyles={{
                      labelStyles: classes.labelSelector,
                      inputStyles: classes.inputSelector,
                    }}
                  />
                </div>
                <div className="item">
                  <EnhancedInput
                    key="reference"
                    name="reference"
                    type="text"
                    title="Reference #"
                    placeholder="Reference #"
                    value={values.reference || ''}
                    error={touched.reference ? errors.reference : ''}
                    onBlur={(v) => onFieldBlur('reference')}
                    onChange={(v) => onFieldUpdate('reference', v.target.value)}
                    disabled={formDisabled || submitButtonState === 'loading'}
                    className={classes.field}
                  />
                </div>
                <div className="item">
                  <NewChipsInput
                    key="currency"
                    name="currency"
                    title="Currency*"
                    placeholder="Currency"
                    required
                    value={values.currency || ''}
                    error={touched.currency ? errors.currency : ''}
                    disabled={
                      formDisabled ||
                      submitButtonState === 'loading' ||
                      invoiceId !== undefined
                    }
                    items={lovs.currencies}
                    onBlur={() => onFieldBlur('currency')}
                    onChange={(v) => onFieldUpdate('currency', v)}
                    customStyles={{
                      labelStyles: classes.labelSelector,
                      inputStyles: classes.inputSelector,
                    }}
                  />
                </div>
                <div className="item">
                  <EnhancedDatePicker
                    key="invoiceDate"
                    name="date"
                    title="Invoice Date*"
                    onBlur={() => onFieldBlur('invoiceDate')}
                    onDateChange={(e) => {
                      (values.invoiceDate = e.toLocaleString()),
                        onFieldUpdate('invoiceDate', e.toLocaleString());
                    }}
                    value={values.invoiceDate}
                    error={touched.invoiceDate ? errors.invoiceDate : ''}
                    disabled={formDisabled || submitButtonState === 'loading'}
                    //format={tenant.dateFormat}
                    // value={!isEmpty(value) ? value : initialValues.createdOn}
                    canClearDate={false}
                    className={classes.field}
                  />
                </div>
                <div className="item">
                  <EnhancedDatePicker
                    key="paymentDate"
                    name="paymentDate"
                    title="Payment Date*"
                    onBlur={() => onFieldBlur('paymentDate')}
                    onDateChange={(g) => {
                      (values.paymentDate = g.toLocaleString()),
                        onFieldUpdate('paymentDate', g.toLocaleString());
                    }}
                    value={values.paymentDate}
                    error={touched.paymentDate ? errors.paymentDate : ''}
                    disabled={formDisabled || submitButtonState === 'loading'}
                    //format={tenant.dateFormat}
                    // value={!isEmpty(value) ? value : initialValues.createdOn}
                    canClearDate={false}
                    className={classes.field}
                  />
                </div>
              </div>
              <Product
                systemCurrency={systemCurrency}
                totalAmount={totalAmount}
                pageState={pageState}
                onPageStateUpdate={onPageStateUpdate}
                lovs={lovs}
              />
              <div className={clsx('container', classes.documentGrid)}>
                <div className="item"></div>
              </div>
              <div style={{ width: '100%' }}>
                <EnhancedTinyMCEEditor
                  key="note"
                  name="note"
                  title="Note"
                  placeholder="Enter a note..."
                  value={values.note || ''}
                  error={touched.note ? errors.note : ''}
                  onBlur={() => onFieldBlur('note')}
                  onChange={(name, value) => {
                    onFieldUpdate('note', value);
                  }}
                  disabled={formDisabled || submitButtonState === 'loading'}
                  customStyles={{
                    labelStyles: classes.labelSelector,
                    inputStyles: classes.inputSelector,
                  }}
                  width="100%"
                />
              </div>
              <div className={classes.buttonsContainer}>
                <EnhancedButton
                  // state={submitting ? "loading" : undefined}
                  state={submitButtonState}
                  backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                  color="rgba(255, 255, 255, 1)"
                  disabled={formDisabled || submitButtonState === 'loading'}
                  onClick={() => {
                    onSubmit(values, totalAmount, pageState.values.product);
                  }}
                >
                  Submit
                </EnhancedButton>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvoicePopUp;
