import { IGenericCurrencyTableData } from '.';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';

// #region Group Stage Listing
export function mapToCurrencyListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.Core?.queries?.getLatestCurrencyExchangeRateList?.items?.forEach(
    (obj: IGenericCurrencyTableData, i: number) => {
      list[i] = {
        core_CurrencyExchangeRate_Id: obj.core_CurrencyExchangeRate_Id,
        core_CurrencyExchangeRate_CurrencyID_Code:
          obj.core_CurrencyExchangeRate_CurrencyID?.Code,
        core_CurrencyExchangeRate_CurrencyID_Title:
          obj.core_CurrencyExchangeRate_CurrencyID?.Title,
        core_CurrencyExchangeRate_Rate: obj.core_CurrencyExchangeRate_Rate,
        core_CurrencyExchangeRate_EffectiveFrom:
          obj.core_CurrencyExchangeRate_EffectiveFrom,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.Core?.queries?.getLatestCurrencyExchangeRateList?.paging?.pageSize,
    pageNumber:
      data?.Core?.queries?.getLatestCurrencyExchangeRateList?.paging
        ?.pageNumber,
    totalCount:
      data?.Core?.queries?.getLatestCurrencyExchangeRateList?.paging
        ?.totalCount,
  };
}
// #endregion
