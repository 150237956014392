import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { useLazyQuery } from '@apollo/client';
import { getAccountsOfCompany } from './queries';
import {
  LookupToList,
  getAccountsOfCompanyToList,
  getSystemCurrency,
  getSystemSecondaryCurrency,
  getSystemSecondaryCurrencyCode,
  getTransactionPageFormState,
  graphqlEntityToTransactionInfo,
  getAccountsOfCompanyCurrenciesToList,
} from './utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../constants';
import EnhancedTinyMCEEditor from '../../components/enhanced-form/EnhancedTinyMCEEditor';
import EnhancedInput from '../../components/enhanced-form/EnhancedInput';
import EnhancedDatePicker from '../../components/enhanced-form/EnhancedDatePicker';
import NewChipsInput from '../../components/enhanced-form/NewChipsInput';
import EnhancedButton from '../../components/EnhancedButton';
import { makeStyles } from 'tss-react/mui';
import AccountCurrency, {
  initialValues,
} from './costom-section/AccountCurrency';
import DifferenceSection from './costom-section/DifferenceSection';
import {
  IAccountCurrency,
  initialFormValues,
  ITransaction,
  ITransactionFormState,
} from './form';
import _ from 'lodash';
import clsx from 'clsx';
import EnhancedUploader from '../../components/enhanced-form/EnhancedUploader';
import ClearIcon from '@mui/icons-material/Clear';
import { useAppSelector } from '../../redux/hooks';
import { getUserToken } from '../../utils/userUtils';
import { resolveGraphqlBaseUrl } from '../../utils/tenant-utils';
import { Download } from '@mui/icons-material';
import {
  downloadFileFromUrl,
  generateDownloadLink,
} from '../../utils/file-utils';
import {
  IPersistDocument,
  ITransactionFormPopUpProps,
  JournalTransaction,
} from '.';
import { isEmpty } from '../../utils/validationUtils';
import { IAbstractRecord } from '../../models';
import Loader from '../../components/Loader';

const useStyles = makeStyles()(() => ({
  firstGrid: {
    display: 'grid',
    gridTemplateColumns: '3fr 3fr 3fr 3fr',
    gridColumnGap: '21px',
  },
  secondGrid: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    gridColumnGap: '21px',
  },
  documentGrid: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    gridColumnGap: '21px',
    margin: '0 0 30px',
  },
  total: {
    display: 'flex',
    justifyContent: 'end',
  },
  dialogPaper: {
    height: '80%',
    width: '80%',
    maxWidth: '1539px',
  },
  title: {
    fontFamily: 'HelveticaNeue-Medium',
    fontSize: '22px',
    lineHeight: '27px',
    padding: '33px 30px 25px',
  },
  container: {
    fontFamily: 'HelveticaNeue-Medium',
    fontSize: '22px',
    lineHeight: '27px',
    padding: '0',
  },
  field: {
    height: '75.21px',
    '& span:first-of-type': {
      lineHeight: '21px',
      marginBottom: '12.54px',
      fontSize: '15px',
      fontFamily: 'SourceSansPro-SemiBold',
    },
  },
  labelSelector: {
    lineHeight: '21px !important',
    marginBottom: '12.54px !important',
    fontSize: '15px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  inputSelector: {
    height: '41px',
  },
  dateField: {
    '& span': {
      color: 'red',
      lineHeight: '21px',
      marginBottom: '12.54px',
    },
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  content: {
    padding: '0 63px 71px 30px',
  },
  uploadedFileContainer: {
    border: '1px solid #E8E8E8',
    padding: '8px 13px 8px 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    margin: '0 0 10px',
  },
  uploadedFileName: {
    color: '#231F20',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'SourceSansPro-Medium',
    margin: '0 auto 0 0',
  },
  uploadedFileClearIcon: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    padding: '0',
    margin: '0 0 0 20px',
  },
  singleItem: {
    width: '290px',
  },
}));

const transactionInitialValues = {
  initialValue: {
    company: '',
    transactionType: '',
    reference: '',
    date: '',
    description: '',
    note: '',
    number: '',
    TransactionDocuments: [] as IPersistDocument[],
  },
  initialError: {
    company: '',
    reference: '',
    date: '',
    description: '',
    note: '',
  },
  initialTouched: {
    company: false,
    reference: false,
    date: false,
    description: false,
    note: false,
  },
};

const TransactionPopUpForm: React.FC<ITransactionFormPopUpProps> = ({
  transactionId,
  data,
  transactionDetailsLoading,
  onSuccess,
  onClose,
}) => {
  const tenant = useAppSelector((state) => state.tenant);
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [lovs, setLovs] = useState<{
    companies: Record<string, string>;
    account: Record<string, string>;
    currencies: Record<string, { id: string; symbol: string }>;
    transactionTypes: Record<string, string>;
  }>({
    companies: {},
    account: {},
    currencies: {},
    transactionTypes: {},
  });
  const [pageState, onPageStateUpdate] = useState<ITransactionFormState>(
    getTransactionPageFormState()
  );
  const [systemCurrency, setSystemCurrency] = useState<string>('');
  const [systemSecondaryCurrency, setSystemSecondaryCurrency] =
    useState<string>('');
  const [systemSecondaryCurrencyCode, setSystemSecondaryCurrencyCode] =
    useState<string>('');

  const values = pageState.values?.transaction || initialFormValues;
  const errors = pageState.errors.transaction;
  const touched = pageState.touched.transaction;

  const [accountsOfCompanyQuery] = useLazyQuery(getAccountsOfCompany());
  // const [createTransactionMutation] = useMutation(
  //   transactionId ? modifyTransaction() : createTransaction()
  // );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [supportingFiles, setSupportingFiles] = useState<File[]>([]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initialize = async () => {
    try {
      setLoading(true);
      const transactionEnums = data.enums;

      if (!transactionEnums) return;

      const newTransactionEnums = LookupToList(transactionEnums);

      const newPageState = _.cloneDeep(pageState);

      newPageState.values.transaction = {
        ...transactionInitialValues.initialValue,
      };

      newPageState.errors.transaction = {
        ...transactionInitialValues.initialError,
      };

      newPageState.touched.transaction = {
        ...transactionInitialValues.initialTouched,
      };

      if (newPageState.values.account.length === 0 && !transactionId) {
        for (let i = 0; i < 2; i++) {
          newPageState.values.account.push({
            id: null,
            accountName: '',
            description: '',
            accountDebit: 0,
            accountCredit: 0,
            systemDebit: 0,
            systemCredit: 0,
            systemSecondaryDebit: 0,
            systemSecondaryCredit: 0,
          });

          newPageState.errors.account.push({ ...initialValues.initialError });

          newPageState.touched.account.push({
            ...initialValues.initialTouched,
          });
        }
      }
      setSystemCurrency(getSystemCurrency(transactionEnums));
      setSystemSecondaryCurrency(getSystemSecondaryCurrency(transactionEnums));
      setSystemSecondaryCurrencyCode(
        getSystemSecondaryCurrencyCode(transactionEnums)
      );

      onPageStateUpdate(newPageState);
      setLovs({
        companies: newTransactionEnums['relatedCompanies'],
        account: null,
        currencies: null,
        transactionTypes: newTransactionEnums['transactionTypes'],
      });

      if (transactionId) {
        const transactionData = data.transaction;
        if (transactionData) {
          const transactionEntity =
            graphqlEntityToTransactionInfo(transactionData);
          if (transactionEntity) {
            newPageState.values.transaction.company =
              transactionEntity.RelatedCompany.Id;
            newPageState.values.transaction.transactionType =
              transactionEntity.TransactionType;
            newPageState.values.transaction.date =
              transactionEntity.TransactionDate?.toString();
            newPageState.values.transaction.description =
              transactionEntity.Description;
            newPageState.values.transaction.note = transactionEntity.Note;
            newPageState.values.transaction.reference =
              transactionEntity.ReferenceNumber;
            newPageState.values.transaction.number =
              transactionEntity.TransactionNumber;
            newPageState.values.transaction.TransactionDocuments =
              transactionEntity.TransactionDocuments;

            transactionEntity?.JournalTransactions.forEach(
              (element: IAbstractRecord) => {
                const accountCurrency: IAccountCurrency = {
                  id: element.journalTransactions_Id,
                  accountCredit: element.journalTransactions_AccountCredit,
                  accountDebit: element.journalTransactions_AccountDebit,
                  accountName: element.journalTransactions_RelatedAccount.id,
                  description: element.journalTransactions_Description,
                  systemCredit: element.journalTransactions_SystemCredit,
                  systemDebit: element.journalTransactions_SystemDebit,
                  systemSecondaryCredit:
                    element.journalTransactions_SystemSecondaryCredit,
                  systemSecondaryDebit:
                    element.journalTransactions_SystemSecondaryDebit,
                };
                newPageState.values.account.push(accountCurrency);
              }
            );

            const result = await accountsOfCompanyQuery({
              variables: {
                selectedCompanyID: transactionEntity.RelatedCompany.Id,
              },
              errorPolicy: 'all',
            });

            const accountLovs = getAccountsOfCompanyToList(result?.data);
            const currencies = getAccountsOfCompanyCurrenciesToList(
              result?.data
            );

            setLovs({
              companies: newTransactionEnums['relatedCompanies'],
              account: accountLovs,
              currencies: currencies,
              transactionTypes: newTransactionEnums['transactionTypes'],
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onFieldBlur = (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.transaction[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = async (
    fieldName: keyof ITransaction,
    value: any,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);

    if (fieldName === 'company') {
      const result = await accountsOfCompanyQuery({
        variables: { selectedCompanyID: value },
        errorPolicy: 'all',
      });

      const accountLovs = getAccountsOfCompanyToList(result?.data);
      const currencies = getAccountsOfCompanyCurrenciesToList(result?.data);

      setLovs({
        ...lovs,
        account: accountLovs,
        currencies: currencies,
      });
    }

    (newPageState.values.transaction as any)[fieldName] = value;

    if (touched) {
      newPageState.touched.transaction[fieldName] = true;
    }
    onPageStateUpdate(newPageState);
  };

  const onSubmit = async (
    values: Record<string, any>,
    totalDebit: number,
    totalCredit: number,
    accounts: IAccountCurrency[],
    deleteAccounts: string[]
  ) => {
    // const validationResult = validateProposalPageForm(data, pageState.values);

    setFormDisabled(true);
    setSubmitButtonState('loading');
    const newPageState = {
      ...pageState,
      // errors: validationResult.errors,
      // touched: validationResult.touched,
    };
    onPageStateUpdate(newPageState);

    const list: JournalTransaction[] = [];

    if (accounts) {
      accounts.forEach((element) => {
        const journalTransaction: JournalTransaction = {
          AccountCredit: element.accountCredit
            ? Number(element.accountCredit)
            : 0,
          AccountDebit: element.accountDebit ? Number(element.accountDebit) : 0,
          Id: element.id ? element.id : null,
          EnteredDescription: element.description,
          SelectedAccount: element.accountName,
          SystemCredit: element.systemCredit ? element.systemCredit : 0,
          SystemDebit: element.systemDebit ? element.systemDebit : 0,
          SystemSecondaryCredit: element.systemSecondaryCredit
            ? element.systemSecondaryCredit
            : 0,
          SystemSecondaryDebit: element.systemSecondaryDebit
            ? element.systemSecondaryDebit
            : 0,
        };

        list.push(journalTransaction);
      });
    }

    const formData = new FormData();

    const arrayOfNulls: (string | null)[] = Array.from(
      { length: supportingFiles.length },
      () => null
    );

    if (transactionId) {
      formData.append(
        'operations',
        JSON.stringify({
          query: `
                mutation modifyTransaction(
                  $CurrentTransactionID: String!
                  $ReferenceNumber: String
                  $DeletedTransactionListID: [String]
                  $PersistDocuments: [String]
                  $TotalCredit: Decimal!
                  $TotalDebit: Decimal!
                  $TransactionType: String!
                  $TransactionDate: Date!
                  $TransactionDescription: String
                  $TransactionList: [Accounting_TransactionList!]!
                  $TransactionNote: String
                  $UploadedDocuments: [Upload]
                ) {
                  accounting {
                    actions {
                      modifyTransaction(
                        CurrentTransactionID: $CurrentTransactionID
                        ReferenceNumber: $ReferenceNumber
                        TotalCredit: $TotalCredit
                        TotalDebit: $TotalDebit
                        TransactionType: $TransactionType
                        TransactionDate: $TransactionDate
                        TransactionDescription: $TransactionDescription
                        TransactionList: $TransactionList
                        TransactionNote: $TransactionNote
                        UploadedDocuments: $UploadedDocuments
                        DeletedTransactionListID: $DeletedTransactionListID
                        PersistDocuments: $PersistDocuments
                      )
                    }
                  }
                }
              `,
          variables: {
            CurrentTransactionID: transactionId,
            ReferenceNumber: values.reference,
            SelectedCompany: values.company,
            TotalCredit: totalCredit,
            TotalDebit: totalDebit,
            TransactionType: values.transactionType,
            TransactionDate: values.date,
            TransactionDescription: values.description,
            TransactionNote: values.note,
            TransactionList: list,
            DeletedTransactionListID: deleteAccounts,
            UploadedDocuments: arrayOfNulls,
            PersistDocuments: values.TransactionDocuments.map(
              (document: IPersistDocument) => {
                return document.id;
              }
            ),
          },
          //errorPolicy: 'all',
        })
      );
    } else {
      formData.append(
        'operations',
        JSON.stringify({
          query: `
                  mutation createTransaction(
                    $ReferenceNumber: String
                    $SelectedCompany: String!
                    $TotalCredit: Decimal!
                    $TotalDebit: Decimal!
                    $TransactionType: String!
                    $TransactionDate: Date!
                    $TransactionDescription: String
                    $TransactionList: [Accounting_TransactionList!]!
                    $TransactionNote: String
                    $UploadedDocuments: [Upload]
                  ) {
                    accounting {
                      actions {
                        createTransaction(
                          ReferenceNumber: $ReferenceNumber
                          SelectedCompany: $SelectedCompany
                          TotalCredit: $TotalCredit
                          TotalDebit: $TotalDebit
                          TransactionType: $TransactionType
                          TransactionDate: $TransactionDate
                          TransactionDescription: $TransactionDescription
                          TransactionList: $TransactionList
                          TransactionNote: $TransactionNote
                          UploadedDocuments: $UploadedDocuments
                        ) {
                          id
                        }
                      }
                    }
                  }
              `,
          variables: {
            ReferenceNumber: values.reference,
            SelectedCompany: values.company,
            TotalCredit: totalCredit,
            TotalDebit: totalDebit,
            TransactionType: values.transactionType,
            TransactionDate: values.date,
            TransactionDescription: values.description,
            TransactionNote: values.note,
            TransactionList: list,
            UploadedDocuments: arrayOfNulls,
          },
          //errorPolicy: 'all',
        })
      );
    }

    if (supportingFiles.length > 0) {
      const files: Record<string, string[]> = {};
      supportingFiles.forEach((file, index) => {
        files[index] = ['variables.UploadedDocuments.' + index];
      });
      formData.append('map', JSON.stringify(files));
    } else {
      // No files, so the map is an empty object
      formData.append('map', JSON.stringify({}));
    }

    supportingFiles.forEach((file, index) => {
      formData.append(index.toString(), file, file.name);
    });

    const options = {
      method: 'POST',
      headers: {
        authorization: `Bearer ${getUserToken()}` || null,
        'GraphQL-preflight': '1',
      },
      body: formData,
    };

    try {
      const response = await fetch(
        `${resolveGraphqlBaseUrl()}/graphql`,
        options
      );
      const data = await response.json();

      if (!data.errors) {
        toast.success(
          <ToastSuccessMessage>
            {transactionId
              ? 'Transaction successfully updated'
              : 'Transaction successfully created'}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setSubmitButtonState('success');
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{data.errors[0].message}</ToastErrorMessage>
        );
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    setFormDisabled(false);
  };

  const totalDebit = useMemo(
    () =>
      pageState.values?.account.reduce(
        (accumulator, current) => accumulator + Number(current.systemDebit),
        0
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageState.values?.account, loading]
  );

  const totalCredit = useMemo(
    () =>
      pageState.values?.account.reduce(
        (accumulator, current) => accumulator + Number(current.systemCredit),
        0
      ),
    [pageState.values.account, loading]
  );

  const handleUploadfile = (file: File) => {
    const sizeInMB = file.size / (1024 * 1024);
    if (sizeInMB > 10) {
      toast.error(
        <ToastErrorMessage>Max file size should be 10 MBs</ToastErrorMessage>
      );
    } else {
      const tempFiles = [...supportingFiles];
      tempFiles.push(file);
      setSupportingFiles(tempFiles);
    }
  };

  const handleDeleteFile = (index: number) => {
    const tempFiles = [...supportingFiles];
    tempFiles.splice(index, 1);
    setSupportingFiles(tempFiles);
  };

  const handleDeleteSupportingfile = (index: number) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.transaction.TransactionDocuments.splice(index, 1);
    onPageStateUpdate(newPageState);
  };

  const downloadDocument = (fileId: string, fileName: string) => {
    const url = generateDownloadLink(
      fileId,
      'Accounting-Transactions',
      transactionId,
      'Accounting-all',
      'TransactionDocuments'
    );

    downloadFileFromUrl(url, fileName);
  };

  if (loading || transactionDetailsLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className={clsx('container', classes.firstGrid)}>
        {transactionId === undefined ? (
          <div className="item">
            <NewChipsInput
              key="company"
              name="company"
              title="Company*"
              placeholder="Company"
              required
              value={values.company || ''}
              error={touched.company ? errors.company : ''}
              disabled={
                formDisabled ||
                submitButtonState === 'loading' ||
                transactionId !== undefined
              }
              items={lovs.companies}
              onBlur={() => onFieldBlur('company')}
              onChange={(v) => onFieldUpdate('company', v)}
              customStyles={{
                labelStyles: classes.labelSelector,
                inputStyles: classes.inputSelector,
              }}
            />
          </div>
        ) : (
          <></>
        )}
        {transactionId !== undefined ? (
          <div className="item">
            <EnhancedInput
              key="number"
              name="number"
              type="text"
              title="Transaction #"
              placeholder="Transaction #"
              value={values.number || ''}
              error={touched.number ? errors.number : ''}
              onBlur={(v) => onFieldBlur('number')}
              onChange={(v) => onFieldUpdate('number', v.target.value)}
              disabled={true}
              className={classes.field}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="item">
          <NewChipsInput
            key="transactionType"
            name="transactionType"
            title="Transaction Type*"
            placeholder="Transaction Type"
            required
            value={values.transactionType}
            error={touched.transactionType ? errors.transactionType : ''}
            disabled={
              formDisabled ||
              submitButtonState === 'loading' ||
              transactionId !== undefined
            }
            items={lovs?.transactionTypes}
            onBlur={() => onFieldBlur('transactionType')}
            onChange={(v) => onFieldUpdate('transactionType', v)}
            customStyles={{
              labelStyles: classes.labelSelector,
              inputStyles: classes.inputSelector,
            }}
          />
        </div>
        <div className="item">
          <EnhancedInput
            key="reference"
            name="reference"
            type="text"
            title="Reference #"
            placeholder="Reference #"
            value={values.reference || ''}
            error={touched.reference ? errors.reference : ''}
            onBlur={(v) => onFieldBlur('reference')}
            onChange={(v) => onFieldUpdate('reference', v.target.value)}
            disabled={formDisabled || submitButtonState === 'loading'}
            className={classes.field}
          />
        </div>
        <div className="item">
          <EnhancedDatePicker
            key="date"
            name="date"
            title="Transaction Date*"
            onBlur={() => onFieldBlur('date')}
            onDateChange={(e) => {
              (values.date = e.toLocaleString()),
                onFieldUpdate('date', e.toLocaleString());
            }}
            value={values.date}
            error={touched.date ? errors.date : ''}
            disabled={
              formDisabled ||
              submitButtonState === 'loading' ||
              transactionId !== undefined
            }
            maxDate={new Date()}
            canClearDate={false}
            className={classes.field}
          />
        </div>
      </div>
      <div className={classes.singleItem}>
        <EnhancedInput
          key="description"
          name="description"
          type="text"
          title="Description"
          placeholder="Description"
          value={values.description || ''}
          error={touched.description ? errors.description : ''}
          onBlur={(v) => onFieldBlur('description')}
          onChange={(v) => onFieldUpdate('description', v.target.value)}
          disabled={formDisabled || submitButtonState === 'loading'}
          className={classes.field}
        />
      </div>
      <AccountCurrency
        systemCurrency={systemCurrency}
        systemSecondaryCurrency={systemSecondaryCurrency}
        systemSecondaryCurrencyCode={systemSecondaryCurrencyCode}
        transactionDate={new Date(values.date)}
        pageState={pageState}
        onPageStateUpdate={onPageStateUpdate}
        lovs={lovs}
      />
      <div className={clsx('container', classes.documentGrid)}>
        <div className="item">
          <EnhancedUploader
            type={''}
            value={''}
            onUpload={(file) => {
              handleUploadfile(file);
            }}
            name={'supportingDocuments'}
            title={'Upload Supporting Documents'}
            customStyles={{
              labelStyles: classes.labelSelector,
              inputStyles: classes.inputSelector,
            }}
            error={
              touched.supportingDocuments ? errors.supportingDocuments : ''
            }
            disabled={formDisabled || submitButtonState === 'loading'}
            placeholder="Upload a document"
            iconUrl={`${tenant.cdnUrl}/icons/upload-field-primary.svg`}
            allowedFileTypes={[]}
          />
          {pageState.values.transaction.TransactionDocuments.map(
            (file, index) => (
              <div
                key={file.fileName}
                className={classes.uploadedFileContainer}
              >
                <span className={classes.uploadedFileName}>
                  {file.fileName}
                </span>

                <button
                  className={classes.uploadedFileClearIcon}
                  onClick={() => {
                    downloadDocument(file.id, file.fileName);
                  }}
                >
                  <Download htmlColor="#727272" fontSize="small" />
                </button>

                <button
                  className={classes.uploadedFileClearIcon}
                  onClick={() => {
                    handleDeleteSupportingfile(index);
                  }}
                >
                  <ClearIcon htmlColor="#727272" fontSize="small" />
                </button>
              </div>
            )
          )}
          {supportingFiles.map((file, index) => (
            <div key={file.name} className={classes.uploadedFileContainer}>
              <span className={classes.uploadedFileName}>{file.name}</span>
              <button
                className={classes.uploadedFileClearIcon}
                onClick={() => {
                  handleDeleteFile(index);
                }}
              >
                <ClearIcon htmlColor="#727272" fontSize="small" />
              </button>
            </div>
          ))}
        </div>
        <div className={clsx('item', classes.total)}>
          <DifferenceSection
            systemCurrency={systemCurrency}
            debit={totalDebit}
            credit={totalCredit}
          />
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <EnhancedTinyMCEEditor
          key="note"
          name="note"
          title="Note"
          placeholder="Enter a note..."
          value={values.note || ''}
          error={touched.note ? errors.note : ''}
          onBlur={() => onFieldBlur('note')}
          onChange={(name, value) => {
            onFieldUpdate('note', value);
          }}
          disabled={formDisabled || submitButtonState === 'loading'}
          customStyles={{
            labelStyles: classes.labelSelector,
            inputStyles: classes.inputSelector,
          }}
          width="100%"
        />
      </div>
      <div className={classes.buttonsContainer}>
        <EnhancedButton
          // state={submitting ? "loading" : undefined}
          state={submitButtonState}
          backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
          color="rgba(255, 255, 255, 1)"
          disabled={
            isEmpty(totalDebit) ||
            isEmpty(totalCredit) ||
            totalDebit === 0 ||
            totalCredit === 0 ||
            totalDebit != totalCredit ||
            formDisabled ||
            submitButtonState === 'loading'
          }
          onClick={() => {
            onSubmit(
              values,
              totalDebit,
              totalCredit,
              pageState.values.account,
              pageState.values.delete
            );
          }}
        >
          Submit
        </EnhancedButton>
      </div>
    </div>
  );
};

export default TransactionPopUpForm;
