import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  IDynamicDiplayView,
  IDynamicSection,
} from '../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../components/widgets/dynamic-display/DynamicWidget';
import { IPolicyPropertyDescriptionDetails } from '.';
import { Tabs, Tab, Box } from '@mui/material';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';

interface IProductionPropertyDescriptionDetailsInfoProps {
  data: IPolicyPropertyDescriptionDetails[];
}

const useStyles = makeStyles()(() => ({
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tabPanel: {
    padding: '0',
  },
  widgetPaper: {
    marginTop: '1em',
  },
}));

const ProductionPropertyDescriptionDetailsInfo: React.FC<
  IProductionPropertyDescriptionDetailsInfoProps
> = ({ data }) => {
  const { classes } = useStyles();
  const [view, setView] = useState<IDynamicDiplayView>();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    setViewData();
  }, [data]);

  const setViewData = () => {
    const dynamicView: IDynamicDiplayView = {
      name: 'PropertyDescriptionDetails',
      sections: data.map((item, index) => ({
        name: 'PropertyDescriptionDetails' + index,
        title: '',
        properties: {
          ['riskDescription' + index]: {
            multiline: false,
            value: item.riskDescription,
            name: 'riskDescription' + index,
            title: 'Risk Description',
            tooltip: false,
          },
          ['geographicalLocation' + index]: {
            multiline: false,
            value: item.geographicalLocation,
            name: 'geographicalLocation' + index,
            title: 'Geographical Location',
            tooltip: false,
          },
          ['location' + index]: {
            multiline: false,
            value: item.location,
            name: 'location' + index,
            title: 'Location',
            tooltip: false,
          },
          ['planCoverID' + index]: {
            multiline: false,
            value: item.planCoverID,
            name: 'planCoverID' + index,
            title: 'Cover',
            tooltip: false,
          },
          ['riskRate' + index]: {
            multiline: false,
            value: item.riskRate.toString(),
            name: 'riskRate' + index,
            title: 'Rate',
            tooltip: false,
          },
          ['sumInsured' + index]: {
            multiline: false,
            value: item.sumInsured.toString(),
            name: 'sumInsured' + index,
            title: 'Sum Insured',
            tooltip: false,
          },
          ['premium' + index]: {
            multiline: false,
            value: item.premium.toString(),
            name: 'premium' + index,
            title: 'Premium',
            tooltip: false,
          },
        },
      })) as IDynamicSection[],
    };

    setView(dynamicView);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (!view || !view.sections || view.sections.length === 0) {
    return <></>;
  }

  return (
    <WidgetPaper className={classes.widgetPaper}>
      <WidgetSection title="Property Description">
        <div className={classes.tabsContainer}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Property Description Tabs"
          >
            {data.map((_, index) => (
              <Tab key={index} label={`Risk ${index + 1}`} />
            ))}
          </Tabs>
        </div>
        {data.map((item, index) => (
          <TabPanel value={selectedTab} index={index} key={index}>
            <DynamicWidget
              hasFourFields={true}
              view={{
                ...view,
                sections: [view.sections![index]],
              }}
            />
          </TabPanel>
        ))}
      </WidgetSection>
    </WidgetPaper>
  );
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  const { classes } = useStyles();

  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`property-tabpanel-${index}`}
      aria-labelledby={`property-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default ProductionPropertyDescriptionDetailsInfo;
