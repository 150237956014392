import { gql } from '@apollo/client';

export function getApplicationDetails() {
  return gql`
    query getExpatApplicationDetails($id: String!) {
      Insurance {
        entities {
          application(id: $id) {
            actions {
              able_To_SendQuotes
              able_To_AcceptQuotes
              able_To_IssuePolicy
              able_To_PreviewQuotes
              able_To_PreviewPolicies
              able_To_SimulateAndSaveExpat
            }
            views {
              Insurance_all {
                properties {
                  createdBy
                  modifiedBy
                  createdOn
                  modifiedOn
                  ApplicationName
                  Status
                  StatusReason
                  EffectiveDate
                  ExpiryDate
                  IsRenewal
                  RenewalNumber
                  LineOfBusiness {
                    Title
                  }
                  AssignedAgencyID {
                    views {
                      Insurance_all {
                        properties {
                          AgencyName
                        }
                      }
                    }
                  }
                  AssignedAgentID {
                    views {
                      Insurance_all {
                        properties {
                          FirstName
                          LastName
                        }
                      }
                    }
                  }
                  ApplicationContactDetailsID {
                    views {
                      Insurance_all {
                        properties {
                          Title {
                            Id
                            Title
                          }
                          FirstName
                          MiddleName
                          LastName
                          FullName
                          Email
                          MobileNumber
                          Address {
                            Country {
                              Id
                              Title
                            }
                            City
                            Street
                            Building
                          }
                          PhoneType
                        }
                      }
                    }
                  }
                  ExpatDetailsID {
                    views {
                      Insurance_all {
                        properties {
                          ApplicationID {
                            id
                          }
                          InsuredName
                          InsuredFirstName
                          InsuredMiddleName
                          InsuredLastName
                          InsuredPlan {
                            Code
                            Title
                          }
                          InsuredDateOfBirth
                          InsuredNationality {
                            Id
                            Code
                            Title
                          }
                          InsuredGender
                          InsuredPassportNumber
                          InsuredOccupation {
                            Id
                            Code
                            Title
                          }
                          InsuredAge
                        }
                      }
                    }
                  }
                }
              }
            }
            queries {
              insurance {
                applicationQuotes {
                  insuranceQuote_Id
                  insuranceQuote_Product {
                    Code
                    Title
                  }
                  insuranceQuote_Name
                  insuranceQuote_Status
                  insuranceQuote_StatusReason
                  insuranceQuote_TotalAnnualPremium
                  insuranceQuote_PolicyID {
                    id
                    views {
                      Insurance_all {
                        properties {
                          PolicyNumber
                          FullPolicyNumber
                        }
                      }
                    }
                  }
                }
              }
            }
            queries {
              insurance {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
        lookups {
          products(Insurance_LinesOfBusiness: "Expat") {
            Id
            Title
          }
          insuredPlans {
            Id
            Title
          }
          countries {
            Id
            Title
          }
          nationalities {
            Id
            Title
          }
          titles {
            Id
            Title
          }
          occupations {
            Id
            Title
            TitleAr
          }
        }
      }
      Insurance_PhoneType: __type(name: "Insurance_PhoneType") {
        name
        enumValues {
          name
        }
      }
      Insurance_Genders: __type(name: "Insurance_Genders") {
        name
        enumValues {
          name
        }
      }
      Insurance_RenewalStatus: __type(name: "Insurance_RenewalStatus") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function calculateAction() {
  return gql`
    mutation simulateAndSaveExpat(
      $entityId: String!
      $effectiveDate: Date!
      $insuredInputs: Insurance_ExpatInsuredDetailsInputs!
      $applicationContactInputs: Insurance_CustomerInputs!
      $isRenewal: Insurance_RenewalStatus!
      $renewalNumber: String
      $product: String!
    ) {
      insurance {
        entities {
          application {
            insurance {
              simulateAndSaveExpat(
                entityId: $entityId
                effectiveDate: $effectiveDate
                insuredInputs: $insuredInputs
                applicationContactInputs: $applicationContactInputs
                isRenewal: $isRenewal
                product: $product
                renewalNumber: $renewalNumber
              ) {
                id
                views {
                  Insurance_all {
                    properties {
                      ApplicationName
                      PlanCount
                      ExpatDetailsID {
                        id
                        views {
                          Insurance_all {
                            properties {
                              InsuredName
                              InsuredFirstName
                              InsuredMiddleName
                              InsuredLastName
                              InsuredDateOfBirth
                              InsuredAge
                              InsuredNationality {
                                Id
                                Title
                              }
                              InsuredGender
                              InsuredPassportNumber
                              InsuredOccupation {
                                Id
                                Code
                                Title
                              }
                            }
                          }
                        }
                      }
                      EffectiveDate
                      LineOfBusiness {
                        Title
                      }
                      ApplicationContactDetailsID {
                        views {
                          Insurance_all {
                            properties {
                              Title {
                                Title
                              }
                              Address {
                                Country {
                                  Title
                                }
                                City
                                Street
                                Building
                              }
                              FirstName
                              MiddleName
                              LastName
                              FullName
                              Email
                              MobileNumber
                              DateOfBirth
                              Gender
                              AssignedAgencyID {
                                views {
                                  Insurance_all {
                                    properties {
                                      AgencyName
                                    }
                                  }
                                }
                              }
                              AssignedAgentID {
                                views {
                                  Insurance_all {
                                    properties {
                                      FirstName
                                      LastName
                                    }
                                  }
                                }
                              }
                              CustomerClassification
                            }
                          }
                        }
                      }
                      Status
                      ExpiryDate
                    }
                  }
                }
                queries {
                  insurance {
                    applicationQuotes {
                      insuranceQuote_TotalAnnualPremium
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function acceptQuotes() {
  return gql`
    mutation acceptQuotes($entityId: String!, $quoteIds: [String!]!) {
      insurance {
        entities {
          application {
            insurance {
              acceptQuotes(entityId: $entityId, quoteIds: $quoteIds)
            }
          }
        }
      }
    }
  `;
}

export function sendQuotes() {
  return gql`
    mutation expatSendQuotes(
      $entityId: String!
      $quoteIds: [String!]!
      $customerEmail: String
    ) {
      insurance {
        entities {
          application {
            insurance {
              sendQuotes(
                entityId: $entityId
                quoteIds: $quoteIds
                customerEmail: $customerEmail
              )
            }
          }
        }
      }
    }
  `;
}

export function issuePolicy() {
  return gql`
    mutation issueExpatPolicy($entityId: String!, $quoteIds: [String!]!) {
      insurance {
        entities {
          application {
            insurance {
              issueExpatPolicy(entityId: $entityId, quoteIds: $quoteIds) {
                Items {
                  EntityTypeId
                  EntityId
                  EntityViewId
                  PropertyId
                  File {
                    id
                    location
                    path
                    fileName
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function downloadQuote() {
  return gql`
    mutation downloadQuote(
      $entityId: String!
      $customerEmail: String!
      $quoteIds: [String!]!
      $applicationContactInputs: Insurance_CustomerInputs
    ) {
      insurance {
        entities {
          application {
            insurance {
              previewQuotes(
                entityId: $entityId
                customerEmail: $customerEmail
                quoteIds: $quoteIds
                applicationContactInputs: $applicationContactInputs
              ) {
                Items {
                  id
                  location
                  path
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function previewPolicies() {
  return gql(/* GraphQL */ `
    mutation previewPolicies($entityId: String!, $quoteIds: [String!]!) {
      insurance {
        entities {
          application {
            insurance {
              previewPolicies(entityId: $entityId, quoteIds: $quoteIds) {
                Items {
                  id
                  location
                  path
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `);
}

export function checkActiveExpatPolicy() {
  return gql`
    query checkActiveExpatPolicy(
      $effectiveDate: Date!
      $insuredPassportNumber: String!
    ) {
      Insurance {
        queries {
          CheckActiveExpatPolicy(
            effectiveDate: $effectiveDate
            insuredPassportNumber: $insuredPassportNumber
          ) {
            insurance_Policy_Id
            insurance_Policy_FullPolicyNumber
            insurance_Policy_PolicyExpiryDate
          }
        }
      }
    }
  `;
}
