import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery } from './queries';
import { mapToListingData, toLookupsData } from './utils';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { IEnhancedMenuItem } from '../../components';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import dayjs from 'dayjs';
import CustomerDrawer from '../../forms/customer-drawer/CustomerDrawer';
import AccountImportDrawer from '../../forms/account-import-drawer/AccountImportDrawer';
import Loader from '../../components/Loader';

const CustomersPage: React.FC = () => {
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [customerDrawerOpen, setCustomerDrawerOpen] = useState<boolean>(false);
  const [accountImportDrawerOpen, setAccountImportDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');

  const actions: IEnhancedMenuItem[] = [
    {
      title: '+ New Customer',
      onClick: () => {
        setChosenEditId(undefined);
        setCustomerDrawerOpen(true);
      },
      isSecondary: true,
      backgroundColor: '#000',
      color: '#fff',
    },
    {
      title: 'Import Customers',
      onClick: () => {
        setChosenEditId(undefined);
        setAccountImportDrawerOpen(true);
      },
    },
  ];

  const FILTER_SESSION_KEY = 'customersFilter';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          createdOn: [],
          company: '41',
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getCustomersLazy] = useLazyQuery(listQuery());

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoading(true);
    try {
      const result = await getCustomersLazy({
        variables: {
          SelectedCompany:
            filterV?.company && filterV?.company?.length > 0
              ? filterV?.company
              : '41',
          CreatedOnFromDate: filterV?.createdOn?.[0]
            ? dayjs(filterV.createdOn[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          CreatedOnToDate: filterV?.createdOn?.[1]
            ? dayjs(filterV.createdOn[1])
                .add(1, 'day')
                .format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          pageNumber: currentPage + 1,
          pageSize: pageSize,
        },
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;

      const newTableData = mapToListingData(data);
      setTableData({
        ...newTableData,
        pageNumber: currentPage,
        pageSize: pageSize,
      });

      const lovs = toLookupsData(data);

      const savedFilters = getFilter(FILTER_SESSION_KEY) || filterV;
      setFilterValues(savedFilters);

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterV?.company;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);

      setFilter(savedFilters, FILTER_SESSION_KEY);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handlePageChange = (page: number) => {
    loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = (pageSize: number) => {
    loadData(0, pageSize, filterValues);
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    loadData(0, tableData.pageSize, newFilters);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: '20px' }}>
      <EnhancedTable
        title="Customers"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        isServerSide
        hideToolbar
        currentPage={tableData.pageNumber}
        disableSelection
        usePagination={true}
        actions={actions}
        loader={loading}
        showCellFullData={true}
      />
      {customerDrawerOpen && (
        <CustomerDrawer
          open={customerDrawerOpen}
          onClose={() => setCustomerDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
          }}
          customerId={chosenEditId}
          customerInfo={undefined}
        />
      )}
      {accountImportDrawerOpen && (
        <AccountImportDrawer
          open={accountImportDrawerOpen}
          onClose={() => setAccountImportDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
          }}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name="Customers"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default CustomersPage;
