import { ITravelPlanRatesDrawerInfo } from '.';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const travelPlanRatesEnums: Record<string, Record<string, string>> = {};

  travelPlanRatesEnums['currencies'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  travelPlanRatesEnums['travelDestinations'] = lookupListAsRecordObject(
    data?.Core?.lookups?.travelDestinations
  );

  travelPlanRatesEnums['gender'] = enumListAsRecordObject(
    data?.Insurance_Genders?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  return travelPlanRatesEnums;
}

export function graphqlToTravelPlanRatesInfo(
  data: any
): ITravelPlanRatesDrawerInfo {
  const travelPlanRates =
    data?.PlanConfigManagement?.entities?.planSpecificTravel?.views
      ?.PlanConfigManagement_all?.properties;

  return {
    ageFrom: travelPlanRates?.AgeFrom,
    ageTo: travelPlanRates?.AgeTo,
    gender: travelPlanRates?.Gender,
    travelDurationFrom: travelPlanRates?.TravelDurationFrom,
    travelDurationTo: travelPlanRates?.TravelDurationTo,
    travelDestination: travelPlanRates?.TravelDestination?.Id,
    nbOfAdherent: travelPlanRates?.NbOfAdherent,
    premium: travelPlanRates?.Premium,
  };
}
