import { gql } from '@apollo/client';

export function getSubLineList() {
  return gql`
    query getSubLineList(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getSubLineList(
            SelectedLineId: $SelectedLineId
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_Subline_Id
              planConfigManagement_Subline_Name
              planConfigManagement_Subline_ExternalCode
              planConfigManagement_Subline_NameArabic
              planConfigManagement_Subline_createdOn
              planConfigManagement_Subline_modifiedOn
              planConfigManagement_Subline_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      NameArabic
                      Name
                      Abbreviation
                      AbbreviationArabic
                      Status
                    }
                  }
                }
              }
              planConfigManagement_Subline_Status
            }
          }
        }
      }

      PlanConfigManagement_SublineStatuses: __type(
        name: "PlanConfigManagement_SublineStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function getLineDetailsInfo() {
  return gql`
    query getLineDetailsInfo($id: String!) {
      PlanConfigManagement {
        entities {
          line(id: $id) {
            views {
              PlanConfigManagement_all {
                properties {
                  Name
                  Status
                  ExternalCode
                  Abbreviation
                  NameArabic
                  createdBy
                  createdOn
                  modifiedBy
                  modifiedOn
                }
              }
            }
            queries {
              planConfigManagement {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function activateLine() {
  return gql`
    mutation activateLine($entityId: String!) {
      planConfigManagement {
        entities {
          line {
            planConfigManagement {
              activateLine(entityId: $entityId) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function deactivateLine() {
  return gql`
    mutation deactivateLine($entityId: String!) {
      planConfigManagement {
        entities {
          line {
            planConfigManagement {
              deactivateLine(entityId: $entityId) {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getPlanList() {
  return gql`
    query getPlanList(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getPlanList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            SelectedLineId: $SelectedLineId
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_Plan_Id
              planConfigManagement_Plan_EffectiveFrom
              planConfigManagement_Plan_EffectiveTo
              planConfigManagement_Plan_PlanStatus
              planConfigManagement_Plan_createdOn
              planConfigManagement_Plan_modifiedOn
              planConfigManagement_Plan_ExternalCode
              planConfigManagement_Plan_Name
              planConfigManagement_Plan_NameArabic
              planConfigManagement_Plan_SublineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      Status
                    }
                  }
                }
              }
              planConfigManagement_Plan_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      NameArabic
                      Name
                      Abbreviation
                      AbbreviationArabic
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getClaimCoverList() {
  return gql`
    query getClaimCoverList(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getClaimCoverList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            SelectedLineId: $SelectedLineId
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_ClaimCover_Id
              planConfigManagement_ClaimCover_Name
              planConfigManagement_ClaimCover_ExternalCode
              planConfigManagement_ClaimCover_createdOn
              planConfigManagement_ClaimCover_modifiedOn
              planConfigManagement_ClaimCover_PolicyCoverID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                    }
                  }
                }
              }
              planConfigManagement_ClaimCover_NameArabic
              planConfigManagement_ClaimCover_PrintingOrder
              planConfigManagement_ClaimCover_ClaimCoverDescription
            }
          }
        }
      }
    }
  `;
}

export function getPolicyCoverList() {
  return gql`
    query getPolicyCoverList(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getPolicyCoverList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            SelectedLineId: $SelectedLineId
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_PolicyCover_Id
              planConfigManagement_PolicyCover_createdOn
              planConfigManagement_PolicyCover_modifiedOn
              planConfigManagement_PolicyCover_ExternalCode
              planConfigManagement_PolicyCover_Name
              planConfigManagement_PolicyCover_NameArabic
              planConfigManagement_PolicyCover_NameFrench
              planConfigManagement_PolicyCover_PrintingOrder
              planConfigManagement_PolicyCover_PolicyCoverDescription
              planConfigManagement_PolicyCover_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      Name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getClauseList() {
  return gql`
    query getClauseList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedLineIds: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getClauseList(
            selectedLineIds: $selectedLineIds
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_Clause_Id
              planConfigManagement_Clause_createdOn
              planConfigManagement_Clause_modifiedOn
              planConfigManagement_Clause_ExternalCode
              planConfigManagement_Clause_Name
              planConfigManagement_Clause_Description

              planConfigManagement_Clause_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      Status
                    }
                  }
                }
              }
              planConfigManagement_Clause_Status
            }
          }
        }
      }
    }
  `;
}
