import { IDocument, IFile } from '../../models/file';

export interface IPolicyDetails {
  customerName: string;
  statusReason: string;
  lineOfBusiness: string;
  plan: string;
  productCode: string;
  agencyName: string;
  agentName: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  pendingDocumentRegeneration: boolean;
  policyDocument: IFile;
  additionalDocument: IFile;
  regeneratedDocuments: IDocument[];
  additionalDocuments: IDocument[];
  userCanUploadAdditionalDocument: boolean;
}

export const initialValues: IPolicyDetails = {
  statusReason: '',
  lineOfBusiness: '',
  plan: '',
  productCode: '',
  agencyName: '',
  agentName: '',
  customerName: '',
  createdBy: '',
  createdOn: '',
  modifiedBy: '',
  modifiedOn: '',
  pendingDocumentRegeneration: false,
  policyDocument: {
    id: '',
    location: '',
    path: '',
    fileName: '',
    length: undefined,
    contentType: '',
  },
  additionalDocument: {
    id: '',
    location: '',
    path: '',
    fileName: '',
    length: undefined,
    contentType: '',
  },
  regeneratedDocuments: [],
  additionalDocuments: [],
  userCanUploadAdditionalDocument: false,
};
