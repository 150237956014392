import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { DynamicFormInputType } from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import { getError } from '../../utils/graph-utils';
import EnhancedNote from '../../components/enhanced-form/EnhancedNote';
import { updateEnvironmentSettings } from './queries';
import dayjs from 'dayjs';
import { getEnvironmentSettings } from '../settings/queries';
import { cloneDeep, isEmpty } from 'lodash';

const EnvironmentSettingsDrawer: React.FC<IEnvironmentSettingsDrawer> = ({
  open,
  minimumIssueDate,
  onSuccess,
  onClose,
}) => {
  const [booted, setBooted] = useState<boolean>(false);

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [updateEnvironmentSettingsMutation] = useMutation(
    updateEnvironmentSettings(),
    {
      refetchQueries: [getEnvironmentSettings()],
      awaitRefetchQueries: true,
    }
  );

  const [runMutation] = useMutation(getEnvironmentSettings(), {
    update(cache, { data }) {
      // Update the cache manually after the mutation
      cache.writeQuery({
        query: getEnvironmentSettings(),
        data: {
          insurance: data.insurance,
        },
      });
    },
    awaitRefetchQueries: true,
  });

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      if (!isEmpty(minimumIssueDate)) {
        updatedInputs.minimumIssueDate.value = minimumIssueDate;
      }

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const mutationResult = await updateEnvironmentSettingsMutation({
        variables: {
          issueDateReference: dayjs(data.minimumIssueDate).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
        },
      });

      if (!mutationResult.data.errors) {
        await runMutation();

        toast.success(
          <ToastSuccessMessage>
            {'Reference date updated successfully'}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
        toast.error(
          <ToastErrorMessage>{getError(mutationResult.data)}</ToastErrorMessage>
        );
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <GenericDrawer
      title={'Change Reference Date'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Update'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title=""
            noteSection={
              <EnhancedNote
                name="note"
                title="Note: "
                text="Only future dates are allowed"
                style={{ marginTop: 0 }}
              />
            }
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default EnvironmentSettingsDrawer;
