import React, { useState, useEffect } from 'react';
import AppLauncherApps from './AppLauncherApps';
import AppLauncherModules from './AppLauncherModules';
import EnhancedInput from '../enhanced-form/EnhancedInput';
import { makeStyles } from 'tss-react/mui';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import { IApplication } from '../../Shell';

interface IAppLauncherModalProps {
  open: boolean;
  closeModal: () => void;
  applications: Record<string, IApplication>;
}

const useStyles = makeStyles()(() => ({
  dialogPaper: {
    height: '100%',
  },
  dialogTitle: {
    fontFamily: 'HelveticaNeue-Regular',
    fontSize: '22px',
    lineHeight: '27px',
    borderBottom: '1px solid rgba(220, 220, 220, 0.5)',
    '& h2': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: '100%',
    },
    '&.MuiDialogTitle-root': {
      display: 'flex',
    },
    marginBottom: '15px',
  },
  serachInputContainer: {
    display: 'contents',
  },
  dialogContent: {},
  searchField: {
    display: 'inline-block',
    width: '25% !important',
    minWidth: '300px',
  },
}));

const AppLauncherModal: React.FC<IAppLauncherModalProps> = ({
  open,
  closeModal,
  applications,
}) => {
  const { classes } = useStyles();
  const fullScreen = useMediaQuery('@media only screen and (max-width: 700px)');
  const [searchKey, setSearchKey] = useState<string>('');

  useEffect(() => {
    if (!open) {
      setSearchKey('');
    }
  }, [open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = event.target.value || '';
    setSearchKey(newSearchKey);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={closeModal}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="xl"
    >
      <DialogTitle className={classes.dialogTitle}>
        <span>{'App Launcher'}</span>

        <EnhancedInput
          type="text"
          name="search"
          inline
          title=""
          placeholder={'Search'}
          classes={{ input: classes.searchField }}
          onChange={handleChange}
          onBlur={() => undefined}
          value={searchKey}
          customInputContainer={classes.serachInputContainer}
        />
      </DialogTitle>
      <DialogContent>
        <AppLauncherApps
          applications={applications}
          closeModal={closeModal}
          searchKey={searchKey}
        />
        <AppLauncherModules
          applications={applications}
          closeModal={closeModal}
          searchKey={searchKey}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AppLauncherModal;
