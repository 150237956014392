import React, { useState } from 'react';
import { EnhancedUpdatedTableFilterType } from '.';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import FilterIcon from './shared/FilterIcon';
import EnhancedDateRangePicker from '../enhanced-form/EnhancedDateRangePicker';
import NewChipsInput from '../enhanced-form/NewChipsInput';
import { MAIN_ONE_THEME, SEND_TO_BACKEND_DATE_FORMAT } from '../../constants';
import { formatDate } from '../../utils/formatting-utils';

export interface EnhancedTableToolbarProps {
  toolbarTitle?: string;
  filters: Record<string, EnhancedUpdatedTableFilterType>;
  handleFilterChange: (
    name: string,
    value: string[] | Date[],
    type: string
  ) => void;
  onFilterClear(): void;
  onFilterAction(): void;
}

const useStyles = makeStyles<{ expanded: boolean }>()(
  (theme, { expanded }) => ({
    mainContainer: {
      backgroundColor: '#F9F9F9',
    },
    filterContainer: {
      backgroundColor: '#F9F9F9',
      padding: expanded ? '11px 26px 26px' : '11px 26px 11px',
    },
    filtersTitle: {
      fontFamily: 'SourceSansPro-Bold',
      fontSize: '15px',
      lineHeight: '21px',
      color: '#344054',
      margin: '0 0 0 8px',
    },
    accordionSummary: {
      minHeight: 'unset!important',
      padding: '0',
      margin: '0',
      '& .MuiAccordionSummary-content': {
        padding: '0',
        margin: expanded ? '0 0 23px' : '0',
      },
    },
    accordionDetails: {
      padding: '0',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16px',
    },
    resetButton: {
      width: '95px',
      height: '35px',
      backgroundColor: 'white',
      marginRight: '8px',
      textTransform: 'none',
      color: MAIN_ONE_THEME.palette.primary2.lighter,
      borderRadius: '7.5px',
    },
    filterButton: {
      borderRadius: '7.5px',
      boxShadow: 'unset',
      width: '95px',
      height: '35px',
      textTransform: 'none',
      backgroundColor: '#C02D33',
      color: 'white',
      '&:hover': {
        backgroundColor: '#A02428',
      },
    },
    filterGrid: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px', // Space between fields
    },
    filterField: {
      flex: '1 1 calc(33.33% - 16px)', // 3 columns with space
      maxWidth: '435px', // Max width for each field
      '& .MuiFormControl-root': {
        backgroundColor: 'white !important',
        '& > div': {
          backgroundColor: 'inherit !important',
        },
      },
      '& input': {
        backgroundColor: 'inherit !important',
      },
      '& button': {
        backgroundColor: 'white !important',
      },
      '& .MuiAutocomplete-endAdornment': {
        width: '28px',
      },
    },
  })
);

const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({
  toolbarTitle,
  filters,
  handleFilterChange,
  onFilterClear,
  onFilterAction,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { classes } = useStyles({ expanded: expanded });

  const handleChange = () => {
    setExpanded(!expanded);
  };

  // Function to render input based on filter type
  const renderFilterInput = (filter: EnhancedUpdatedTableFilterType) => {
    const { name, type, placeholder } = filter;
    switch (type) {
      case 'daterange': {
        const value =
          filter.value && filter.value.length > 0
            ? filter.value.map((date) =>
                formatDate(date, SEND_TO_BACKEND_DATE_FORMAT)
              )
            : [];
        return (
          <EnhancedDateRangePicker
            name={name}
            title={''}
            value={value}
            placeholder={placeholder}
            onDateRangeChange={(dates) => handleFilterChange(name, dates, type)}
          />
        );
      }
      case 'select': {
        return (
          <NewChipsInput
            name={name}
            title={''}
            items={filter.options}
            placeholder={placeholder}
            values={filter.selectedValues}
            multiple
            onChange={(value) =>
              handleFilterChange(name, value as string[], type)
            }
          />
        );
      }
      default:
        return null;
    }
  };

  const FiltersComponent = (
    <Accordion
      elevation={0}
      className={classes.filterContainer}
      onChange={() => handleChange()}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <div>
          <FilterIcon />
          <span className={classes.filtersTitle}>{'Filters'}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <div className={classes.filterGrid}>
          {Object.keys(filters).map((filter) => (
            <div key={filter} className={classes.filterField}>
              {renderFilterInput(filters[filter])}
            </div>
          ))}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            className={classes.resetButton}
            onClick={onFilterClear}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            className={classes.filterButton}
            onClick={onFilterAction}
          >
            Filter
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );

  return <div className={classes.mainContainer}>{FiltersComponent}</div>;
};

export default EnhancedTableToolbar;
