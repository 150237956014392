import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { IEnhancedCommonProps } from '../..';
import { IEnhancedMenuItem } from '../table';
import EnhancedButton from '../buttons/EnhancedButton';
import EnhancedMenu from './EnhancedMenu';
import { contentFontFamilyRegular, MAIN_ONE_THEME } from '../../../constants';
import { lighten } from '@mui/material';

export interface IActionBarProps extends IEnhancedCommonProps {
  items?: IEnhancedMenuItem[];
  title?: string;
  disabled?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    position: 'relative',
  },
  menuButton: {
    position: 'relative',
    borderRadius: 3,
    minHeight: 32,
    minWidth: 80,
    border: `1px ${MAIN_ONE_THEME.palette.secondary3.main} solid`,
    boxSizing: 'border-box',
    padding: '0 15px',
    backgroundColor: '#fff',
    fontFamily: contentFontFamilyRegular,
    textTransform: 'capitalize',
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: '15px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: lighten('#fff', 0.25),
      '&:disabled': {
        backgroundColor: lighten('#fff', 0.25),
      },
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.4,
      pointerEvents: 'all',
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
}));

const SimpleActionBar: React.FC<IActionBarProps> = ({
  title = 'Actions',
  items = [],
}) => {
  const actionClasses = useStyles().classes;

  const primaryActions = items.filter((i) => i.isPrimary);

  const secondaryActions = items.filter((i) => !i.isPrimary);

  return (
    <section title={title} className={actionClasses.container}>
      {primaryActions.map((item) => (
        <EnhancedButton
          key={item.name}
          disabled={item.disabled}
          onClick={item.onClick}
          isPrimary
        >
          {item.title}
        </EnhancedButton>
      ))}
      {secondaryActions.length > 0 && (
        <EnhancedMenu
          title="Actions"
          items={secondaryActions}
          itemClassName={actionClasses.menuButton}
        />
      )}
    </section>
  );
};

export default SimpleActionBar;
