import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Protected from './Protected';
import SetPassword from './auth-pages/SetPassword';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

const Portal: React.FC = () => {
  return (
    <Routes>
      <Route path="/setpassword" element={<SetPassword />} />
      <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
      <Route path="*" element={<Protected />} />
    </Routes>
  );
};

export default Portal;
