import React from 'react';
import { IProductionDetailsSummary } from '..';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import { EnhancedDisplayType } from '../../../../../components/enhanced-form/EnhancedDisplay';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';

interface IProductionPolicyDetailsWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionPolicyDetailsWidget: React.FC<
  IProductionPolicyDetailsWidgetProps
> = ({ data }) => {
  const dynamicView: IDynamicDiplayView = {
    name: 'PolicyDetails',
    sections: [
      {
        name: 'PolicyDetails',
        title: 'Policy Details',
        properties: {
          PolicyEffectiveDate: {
            multiline: false,
            value: data.PolicyEffectiveDate,
            name: 'PolicyEffectiveDate',
            title: 'Policy Effective Date',
            tooltip: false,
            type: EnhancedDisplayType.Date,
          },
          PolicyEndDate: {
            multiline: false,
            value: data.PolicyExpiryDate,
            name: 'PolicyEndDate',
            title: 'Policy End Date',
            tooltip: false,
            type: EnhancedDisplayType.Date,
          },
          PolicyCurrency: {
            multiline: false,
            value: data?.PolicyCurrency?.Title || '',
            name: 'PolicyCurrency',
            title: 'Policy Currency',
            tooltip: false,
          },
        },
      },
    ],
  };

  return <DynamicWidget hasFourFields={false} view={dynamicView} />;
};

export default ProductionPolicyDetailsWidget;
