export function LookupToList(data: any) {
  const policyCoverEnums: Record<string, Record<string, string>> = {};
  const lines: Record<string, string> = {};

  data.PlanConfigManagement.queries.allLines.forEach(
    (element: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lines[element.planConfigManagement_Line_Id] =
        element.planConfigManagement_Line_Name;
    }
  );

  policyCoverEnums['lines'] = lines;

  return policyCoverEnums;
}

export function graphqlEntityToPolicyCoverInfo(
  data: any,
  lineId: string,
  lineName: string
): IPolicyCoverInfo {
  return {
    policyCoverId: '',
    lineId: lineId,
    lineName: lineId,
    policyCoverName:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.Name,
    policyCoverExternalCode:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.ExternalCode,
    arabicName:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.NameArabic,
    policyCoverPrintingOrder:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.PrintingOrder,
    policyCoverDescription:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.PolicyCoverDescription,
  };
}
