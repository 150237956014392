import {
  IGenericClaimCoverTableData,
  IGenericClauseTableData,
  IGenericPlanTableData,
  IGenericPolicyCoverTableData,
  ILineDetails,
} from '.';
import { IGenericSublineTableData } from '.';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import {
  capitalizeFirstLetter,
  removeHTMLTagesFromDescription,
} from '../../utils/formatting-utils';
import { steps } from './content';

export function entityToLineDetails(data: any): ILineDetails {
  const lineDetailsInfo =
    data?.PlanConfigManagement?.entities?.line?.views?.PlanConfigManagement_all
      ?.properties;

  return {
    lineName: lineDetailsInfo?.Name,
    externalCode: lineDetailsInfo?.ExternalCode,
    abbreviation: lineDetailsInfo?.Abbreviation,
    arabicName: lineDetailsInfo?.NameArabic,
    status: lineDetailsInfo?.Status,
    createdBy: `${data?.PlanConfigManagement?.entities?.line?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_firstName} ${data?.PlanConfigManagement?.entities?.line?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: lineDetailsInfo?.createdOn,
    modifiedBy: `${data?.PlanConfigManagement?.entities?.line?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_firstName} ${data?.PlanConfigManagement?.entities?.line?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: lineDetailsInfo?.modifiedOn,
  };
}

export const getLineStatus = (status: string) => {
  switch (status) {
    case 'inactive':
      return steps[0];
    case 'active':
      return steps[1];
    default:
      return steps[0];
  }
};

export function mapToLSublinesListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getSubLineList.items.forEach(
    (obj: IGenericSublineTableData, i: number) => {
      list[i] = {
        planConfigManagement_Subline_Id: obj.planConfigManagement_Subline_Id,
        planConfigManagement_Subline_Name:
          obj.planConfigManagement_Subline_Name,
        planConfigManagement_Subline_ExternalCode:
          obj.planConfigManagement_Subline_ExternalCode,
        planConfigManagement_Subline_NameArabic:
          obj.planConfigManagement_Subline_NameArabic,
        planConfigManagement_Subline_createdOn:
          obj.planConfigManagement_Subline_createdOn,
        planConfigManagement_Subline_modifiedOn:
          obj.planConfigManagement_Subline_modifiedOn,
        planConfigManagement_Subline_Status: capitalizeFirstLetter(
          obj.planConfigManagement_Subline_Status?.replaceAll('_', ' ')
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getSubLineList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getSubLineList?.paging?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getSubLineList?.paging?.totalCount,
  };
}

export function mapToPlansListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getPlanList.items.forEach(
    (obj: IGenericPlanTableData, i: number) => {
      const sublineName =
        obj.planConfigManagement_Plan_SublineID?.views?.PlanConfigManagement_all
          ?.properties?.Name;
      list[i] = {
        planConfigManagement_Plan_Id: obj.planConfigManagement_Plan_Id,
        planConfigManagement_Plan_EffectiveFrom:
          obj.planConfigManagement_Plan_EffectiveFrom,
        planConfigManagement_Plan_EffectiveTo:
          obj.planConfigManagement_Plan_EffectiveTo,
        planConfigManagement_Plan_createdOn:
          obj.planConfigManagement_Plan_createdOn,
        planConfigManagement_Plan_modifiedOn:
          obj.planConfigManagement_Plan_modifiedOn,
        planConfigManagement_Plan_ExternalCode:
          obj.planConfigManagement_Plan_ExternalCode,
        planConfigManagement_Plan_Name: obj.planConfigManagement_Plan_Name,
        planConfigManagement_Plan_NameArabic:
          obj.planConfigManagement_Plan_NameArabic,
        planConfigManagement_Plan_PlanStatus: capitalizeFirstLetter(
          obj.planConfigManagement_Plan_PlanStatus?.replaceAll('_', ' ')
        ),
        planConfigManagement_Plan_SublineID: sublineName,
        planConfigManagement_Plan_SublineStatus:
          obj.planConfigManagement_Plan_SublineID?.views?.PlanConfigManagement_all?.properties?.Status.toLowerCase(),
        planConfigManagement_Plan_SublineId:
          obj.planConfigManagement_Plan_SublineID?.id,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanList?.paging?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanList?.paging?.totalCount,
  };
}

export function mapToClaimCoversListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getClaimCoverList.items.forEach(
    (obj: IGenericClaimCoverTableData, i: number) => {
      list[i] = {
        planConfigManagement_ClaimCover_Id:
          obj.planConfigManagement_ClaimCover_Id,
        planConfigManagement_ClaimCover_Name:
          obj.planConfigManagement_ClaimCover_Name,
        planConfigManagement_ClaimCover_ExternalCode:
          obj.planConfigManagement_ClaimCover_ExternalCode,
        planConfigManagement_ClaimCover_PolicyCoverID:
          obj.planConfigManagement_ClaimCover_PolicyCoverID?.views
            ?.PlanConfigManagement_all?.properties?.Name,
        planConfigManagement_ClaimCover_NameArabic:
          obj.planConfigManagement_ClaimCover_NameArabic,
        planConfigManagement_ClaimCover_PrintingOrder:
          obj.planConfigManagement_ClaimCover_PrintingOrder,
        planConfigManagement_ClaimCover_ClaimCoverDescription:
          obj.planConfigManagement_ClaimCover_ClaimCoverDescription,
        planConfigManagement_ClaimCover_createdOn:
          obj.planConfigManagement_ClaimCover_createdOn,
        planConfigManagement_ClaimCover_modifiedOn:
          obj.planConfigManagement_ClaimCover_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getClaimCoverList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getClaimCoverList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getClaimCoverList?.paging
        ?.totalCount,
  };
}

export function mapToPolicyCoversListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getPolicyCoverList.items.forEach(
    (obj: IGenericPolicyCoverTableData, i: number) => {
      list[i] = {
        planConfigManagement_PolicyCover_Id:
          obj.planConfigManagement_PolicyCover_Id,
        planConfigManagement_PolicyCover_Name:
          obj.planConfigManagement_PolicyCover_Name,
        planConfigManagement_PolicyCover_ExternalCode:
          obj.planConfigManagement_PolicyCover_ExternalCode,
        planConfigManagement_PolicyCover_NameArabic:
          obj.planConfigManagement_PolicyCover_NameArabic,
        planConfigManagement_PolicyCover_PrintingOrder:
          obj.planConfigManagement_PolicyCover_PrintingOrder,
        planConfigManagement_PolicyCover_PolicyCoverDescription:
          obj.planConfigManagement_PolicyCover_PolicyCoverDescription,
        planConfigManagement_PolicyCover_createdOn:
          obj.planConfigManagement_PolicyCover_createdOn,
        planConfigManagement_PolicyCover_modifiedOn:
          obj.planConfigManagement_PolicyCover_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPolicyCoverList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPolicyCoverList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPolicyCoverList?.paging
        ?.totalCount,
  };
}

export function mapToClausesListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getClauseList.items.forEach(
    (obj: IGenericClauseTableData, i: number) => {
      list[i] = {
        planConfigManagement_Clause_Id: obj.planConfigManagement_Clause_Id,
        planConfigManagement_Clause_Name: obj.planConfigManagement_Clause_Name,
        planConfigManagement_Clause_ExternalCode:
          obj.planConfigManagement_Clause_ExternalCode,
        planConfigManagement_Clause_Description: removeHTMLTagesFromDescription(
          obj.planConfigManagement_Clause_Description
        ),
        planConfigManagement_Clause_Status: capitalizeFirstLetter(
          obj.planConfigManagement_Clause_Status?.replaceAll('_', ' ')
        ),
        planConfigManagement_Clause_createdOn:
          obj.planConfigManagement_Clause_createdOn,
        planConfigManagement_Clause_modifiedOn:
          obj.planConfigManagement_Clause_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getClauseList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getClauseList?.paging?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getClauseList?.paging?.totalCount,
  };
}
