import { gql } from '@apollo/client';

export function getInvoiceInfo() {
  return gql`
    query getInvoiceInfo($id: String!) {
      Accounting {
        entities {
          invoices(id: $id) {
            id
            views {
              Accounting_all {
                properties {
                  Id
                  InvoiceDocuments {
                    id
                    location
                    path
                    fileName
                    length
                    contentType
                  }
                  InvoiceNote
                  InvoiceRemainingAmount
                  createdOn
                  modifiedOn
                  createdBy
                  modifiedBy
                  RelatedCompany {
                    Id
                    Title
                    Code
                  }
                  RelatedCustomer {
                    id
                    views {
                      Accounting_all {
                        properties {
                          CustomerName
                        }
                      }
                    }
                  }
                  InvoiceNumber
                  ReferenceNumber
                  InvoiceDate
                  PaymentDate
                  InvoiceCurrency {
                    Id
                    Code
                    Title
                    Symbol
                  }
                  InvoiceTotalAmount
                  InvoiceStatus
                }
              }
            }
            queries {
              accounting {
                GetInvoiceRecords {
                  accounting_Invoices_Id
                  invoiceRecord_Id
                  invoiceRecord_InvoiceID {
                    id
                  }
                  invoiceRecord_Product {
                    id
                    views {
                      Accounting_all {
                        properties {
                          Id
                          ProductName
                        }
                      }
                    }
                  }
                  invoiceRecord_Description
                  invoiceRecord_Amount
                  invoiceRecord_SystemAmount
                  invoiceRecord_createdOn
                }
                GetCustomerDetails {
                  accounting_Invoices_Id
                  customer_BillingAddress {
                    Country {
                      Id
                      Code
                      Title
                    }
                    Zone {
                      Id
                      Code
                      Title
                    }
                    City
                    Street
                    Building
                    AddressDetails
                    AddressDetailsArabic
                  }
                  customer_BalanceDue
                  customer_createdOn
                  customer_modifiedOn
                  customer_Id
                  customer_CustomerName
                  customer_RelatedCompany {
                    Id
                    Code
                    Title
                  }
                  customer_LinkToExistingAccount
                  customer_LinkedAccount {
                    id
                    views {
                      Accounting_all {
                        properties {
                          Id
                          AccountName
                        }
                      }
                    }
                  }
                  customer_CustomerAccount {
                    id
                    views {
                      Accounting_all {
                        properties {
                          Id
                          AccountName
                        }
                      }
                    }
                  }
                  customer_CustomerName
                  customer_ReferenceNumber
                  customer_Website
                  customer_Notes
                }
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getPersonAddressList() {
  return gql`
    query getPersonAddressList(
      $selectedPersonID: String
      $currentPage: Int!
      $currentPageSize: Int!
      $Attribute: String!
      $Descending: Boolean!
    ) {
      SalesforceManagement {
        queries {
          getPersonAddressList(
            selectedPersonID: $selectedPersonID
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_PersonAddress_Id
              salesforceManagement_PersonAddress_createdOn
              salesforceManagement_PersonAddress_createdBy
              salesforceManagement_PersonAddress_modifiedOn
              salesforceManagement_PersonAddress_modifiedBy
              salesforceManagement_PersonAddress_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                    }
                  }
                }
              }
              salesforceManagement_PersonAddress_AddressType
              salesforceManagement_PersonAddress_Address {
                Country {
                  Title
                }
                Zone {
                  Title
                }
                City
                Street
                Building
                AddressDetails
                AddressDetailsArabic
              }
              salesforceManagement_PersonAddress_IsPrimary
            }
          }
        }
      }
    }
  `;
}
