import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Configuration.queries.getProviderList.items.forEach(
    (obj: IGenericProvidersTableData, i: number) => {
      list[i] = {
        id: obj.configuration_Provider_Id,
        code: obj.configuration_Provider_ProviderCode,
        name: obj.configuration_Provider_PersonID?.views
          .SalesforceManagement_all.properties.FullName,
        type: obj.configuration_Provider_Type?.Title,
        email:
          obj.configuration_Provider_PersonID?.views.SalesforceManagement_all
            .properties.Email,
        phoneNumber:
          obj.configuration_Provider_PersonID?.views.SalesforceManagement_all
            .properties.PhoneDetails?.PhoneNumber,
        status: capitalizeFirstLetter(
          obj.configuration_Provider_Status?.replaceAll('_', ' ')
        ),
        createdOn: obj.configuration_Provider_createdOn,
        modifiedOn: obj.configuration_Provider_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Configuration.queries.getProviderList?.paging?.pageSize,
    pageNumber: data.Configuration.queries.getProviderList?.paging?.pageNumber,
    totalCount: data.Configuration.queries.getProviderList?.paging?.totalCount,
  };
}

export function toLookups(data: any) {
  const lovs: Record<string, Record<string, string>> = {
    type: {},
    status: {},
  };

  const yesOrNoArray = [
    { Id: 'true', Title: 'Yes' },
    { Id: 'false', Title: 'No' },
  ];

  lovs.type = lookupListAsRecordObject(
    data?.Configuration?.lookups?.providerTypes
  );
  lovs.status = enumListAsRecordObject(
    data?.Configuration_ProviderStatuses?.enumValues
  );
  lovs.isCompany = lookupListAsRecordObject(yesOrNoArray);
  lovs.isLocal = lookupListAsRecordObject(yesOrNoArray);

  return lovs;
}
