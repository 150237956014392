import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery } from './queries';
import { mapToListingData } from './utils';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import NssfCodeRangeDrawer from '../../forms/nssf-code-range-drawer/NssfCodeRangeDrawer';
import Loader from '../../components/Loader';
import { IEnhancedTableMenuItem } from '../../components/enhanced-table';

const NssfCodeNumberRangesPage: React.FC = () => {
  const [booted, setBooted] = useState<boolean>(false);
  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  const [nssfCodeNumberRangeDrawerOpen, setNssfCodeNumberRangeDrawerOpen] =
    useState<boolean>(false);

  const FILTER_SESSION_KEY = 'nssfCodeNumberRangesFilter_';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          status: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  // Define the action within the component
  const actions: IEnhancedTableMenuItem[] = [
    {
      title: '+ New',
      onClick: () => {
        setNssfCodeNumberRangeDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: '',
      hidden: false,
    },
  ];

  const [loadDataQuery] = useLazyQuery(listQuery());

  const loadData = async (
    currentPage = tableData.pageNumber,
    currentPageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setTableLoadingState(true);
    try {
      const result = await loadDataQuery({
        variables: {
          currentPage: currentPage + 1,
          currentPageSize: currentPageSize,
          rangeStatus:
            filterV?.status && filterV?.status?.length > 0
              ? filterV.status
              : [],
        },
      });

      const newTableData = mapToListingData(result.data);
      newTableData.pageNumber = newTableData.pageNumber - 1;

      const newFilterSections = filterSectionsContent(result.data, filterV);
      setTableData(newTableData);
      setFilterSections(newFilterSections);
      setFilterValues(filterV);
      setFilter(filterV, FILTER_SESSION_KEY);
    } catch (e) {
      console.error(e);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setTableLoadingState(false);
    }
  };

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, tableData.pageSize, savedFilters);
    setBooted(true);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page: number) => {
    loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = (numberOfRecordsPerPage: number) => {
    loadData(0, numberOfRecordsPerPage, filterValues);
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    loadData(0, tableData.pageSize, newFilters);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: '20px' }}>
      <EnhancedTable
        title={'Bar Code Number Ranges'}
        name="count"
        orderByAscendingByDefault
        defaultOrderByColumn="name"
        inline={false}
        isServerSide
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        currentPage={tableData.pageNumber}
        hideToolbar
        usePagination
        disableSelection
        actions={actions}
        loader={tableLoadingState}
      />
      {nssfCodeNumberRangeDrawerOpen && (
        <NssfCodeRangeDrawer
          open={nssfCodeNumberRangeDrawerOpen}
          onClose={() => setNssfCodeNumberRangeDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
            setNssfCodeNumberRangeDrawerOpen(false);
          }}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name={'Bar Code Filter'}
        disabled={tableLoadingState}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={'Bar Code Number Ranges'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default NssfCodeNumberRangesPage;
