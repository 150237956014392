import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import { enumListAsRecordObject } from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.SalesforceManagement.queries.getPersonList.items.forEach(
    (obj: IGenericPersonsTableData, i: number) => {
      list[i] = {
        salesforceManagement_Person_Id: obj.salesforceManagement_Person_Id,
        salesforceManagement_Person_PersonCode:
          obj.salesforceManagement_Person_PersonCode,
        salesforceManagement_Person_FullName:
          obj.salesforceManagement_Person_FullName,
        salesforceManagement_Person_Title:
          obj.salesforceManagement_Person_Title?.Title,
        salesforceManagement_Person_PersonType:
          obj.salesforceManagement_Person_PersonType.toLowerCase() === 'person'
            ? 'Individual'
            : capitalizeFirstLetter(obj.salesforceManagement_Person_PersonType),
        salesforceManagement_Person_Email:
          obj.salesforceManagement_Person_Email,
        salesforceManagement_Person_PhoneDetails:
          obj.salesforceManagement_Person_PhoneDetails?.PhoneNumber,
        salesforceManagement_Person_Status: capitalizeFirstLetter(
          obj.salesforceManagement_Person_Status?.replaceAll('_', ' ')
        ),
        salesforceManagement_Person_createdOn:
          obj.salesforceManagement_Person_createdOn,
        salesforceManagement_Person_modifiedOn:
          obj.salesforceManagement_Person_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.SalesforceManagement.queries.getPersonList?.paging?.pageSize,
    pageNumber:
      data.SalesforceManagement.queries.getPersonList?.paging?.pageNumber,
    totalCount:
      data.SalesforceManagement.queries.getPersonList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    type: {},
    status: {},
  };

  lovs.status = enumListAsRecordObject(
    data?.SalesforceManagement_PersonStatuses?.enumValues
  );
  lovs.type = enumListAsRecordObject(
    data?.SalesforceManagement_PersonTypes?.enumValues
  );

  if (lovs.type['PERSON']) {
    lovs.type['PERSON'] = 'Individual';
  }

  return lovs;
}
