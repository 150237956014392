import { IBusinessPartnerDefaultCommissionInfo } from '.';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const lovs: Record<string, Record<string, string>> = {
    lines: {},
  };

  data?.SalesforceManagement?.queries?.getBusinessPartnerLinesListWithoutPagination.forEach(
    (element: {
      salesforceManagement_BusinessPartnerLine_Id: string;
      salesforceManagement_BusinessPartnerLine_LineID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
            };
          };
        };
      };
    }) => {
      lovs.lines[element.salesforceManagement_BusinessPartnerLine_Id] =
        element.salesforceManagement_BusinessPartnerLine_LineID.views.PlanConfigManagement_all.properties.Name;
    }
  );

  lovs['commissionTypes'] = enumListAsRecordObject(
    data?.Core_CommissionTypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  lovs['currencies'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  lovs['commissionApplicableOnOptions'] = lookupListAsRecordObject(
    data?.Core?.lookups?.commissionApplicableOnOptions
  );

  return lovs;
}

export function extractLines(data: any) {
  const partnerLineDictionary: Record<string, string> = {};

  const result: Record<string, string> = {};

  data?.SalesforceManagement?.queries?.getBusinessPartnerLinesListWithoutPagination.forEach(
    (element: {
      salesforceManagement_BusinessPartnerLine_Id: string;
      salesforceManagement_BusinessPartnerLine_LineID: {
        id: string;
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
            };
          };
        };
      };
    }) => {
      result[element.salesforceManagement_BusinessPartnerLine_LineID.id] =
        element.salesforceManagement_BusinessPartnerLine_LineID.views.PlanConfigManagement_all.properties.Name;

      partnerLineDictionary[
        element.salesforceManagement_BusinessPartnerLine_LineID.id
      ] = element.salesforceManagement_BusinessPartnerLine_Id;
    }
  );

  return { result, partnerLineDictionary };
}

export function extractPlans(data: any): Record<string, string> {
  const result: Record<string, string> = {};

  data?.SalesforceManagement?.queries?.GetPlanListByAssignedLines.forEach(
    (element: {
      planConfigManagement_Plan_Id: string;
      planConfigManagement_Plan_Name: string;
    }) => {
      result[element.planConfigManagement_Plan_Id] =
        element.planConfigManagement_Plan_Name;
    }
  );

  return result;
}

export function extractSelectedLine(
  selectedId: string,
  data: any
): IBusinessPartnerDefaultCommissionInfo | null {
  let selectedLineInfo: IBusinessPartnerDefaultCommissionInfo | null = null;

  if (
    data &&
    data.SalesforceManagement &&
    data.SalesforceManagement.queries &&
    data.SalesforceManagement.queries
      .getBusinessPartnerLinesListWithoutPagination
  ) {
    data.SalesforceManagement.queries.getBusinessPartnerLinesListWithoutPagination.forEach(
      (element: {
        salesforceManagement_BusinessPartnerLine_Id: string;
        salesforceManagement_BusinessPartnerLine_LineID: {
          id: string;
        };
        salesforceManagement_BusinessPartnerLine_LineCommissionType: string;
        salesforceManagement_BusinessPartnerLine_LineCommissionRate: number;
        salesforceManagement_BusinessPartnerLine_LineCommissionValue: number;
        salesforceManagement_BusinessPartnerLine_ValueCurrency: {
          Code: string;
        };
        salesforceManagement_BusinessPartnerLine_ApplicableOn: { Code: string };
      }) => {
        if (
          element.salesforceManagement_BusinessPartnerLine_LineID.id ===
          selectedId
        ) {
          selectedLineInfo = {
            selectedBusinessPartnerLineId:
              element.salesforceManagement_BusinessPartnerLine_Id,
            commissionType:
              element.salesforceManagement_BusinessPartnerLine_LineCommissionType,
            lineCommissionRate:
              element.salesforceManagement_BusinessPartnerLine_LineCommissionRate,
            lineCommissionValue:
              element.salesforceManagement_BusinessPartnerLine_LineCommissionValue,
            ValueCurrency:
              element.salesforceManagement_BusinessPartnerLine_ValueCurrency
                ?.Code,
            ApplicableOn:
              element.salesforceManagement_BusinessPartnerLine_ApplicableOn
                ?.Code,
          };
        }
      }
    );
  }

  return selectedLineInfo;
}

export function extractSelectedPlanByLine(
  selectedLine: any,
  selectedPlan: any
) {
  return {
    salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType:
      selectedLine?.commissionType,
    salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue:
      selectedLine?.LineCommissionValue,
    salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency:
      selectedLine?.ValueCurrency,
    salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate:
      selectedLine?.LineCommissionRate,
    salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn:
      selectedLine?.ApplicableOn,
    salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable:
      selectedPlan?.planConfigManagement_Plan_SpecialCommissionApplicable,
    salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType:
      selectedPlan?.planConfigManagement_Plan_SpecialCommissionType,
    salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue:
      selectedPlan?.planConfigManagement_Plan_SpecialCommissionValue,
    salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency:
      selectedPlan?.planConfigManagement_Plan_SpecialCommissionValueCurrency
        ?.Code,
    salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate:
      selectedPlan?.planConfigManagement_Plan_SpecialCommissionRate,
    salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn:
      selectedPlan?.planConfigManagement_Plan_SpecialCommissionApplicableOn
        ?.Code,
  };
}

export function getBusinessPartnerLineDetails(data: any, id: string) {
  const lines =
    data.SalesforceManagement.queries
      .getBusinessPartnerLinesListWithoutPagination;

  const line = lines.find(
    (line: any) =>
      line.salesforceManagement_BusinessPartnerLine_LineID?.id === id
  );

  if (line) {
    return {
      type: line?.salesforceManagement_BusinessPartnerLine_LineCommissionType,
      rate:
        Number(
          line?.salesforceManagement_BusinessPartnerLine_LineCommissionRate
        ) * 100,
      value: line?.salesforceManagement_BusinessPartnerLine_LineCommissionValue,
      currency: line?.salesforceManagement_BusinessPartnerLine_ValueCurrency,
      applicableOn:
        line?.salesforceManagement_BusinessPartnerLine_ApplicableOn?.Code,
    };
  }

  return null;
}
