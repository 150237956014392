import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function toLookups(data: any) {
  const policyDetailsList: Record<string, Record<string, string>> = {};
  policyDetailsList['phoneType'] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );
  policyDetailsList['renewalStatus'] = enumListAsRecordObject(
    data?.Insurance_RenewalStatus?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );
  policyDetailsList['countries'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.countries
  );
  policyDetailsList['brands'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.brands
  );
  policyDetailsList['plateCode'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.plateCodes
  );
  policyDetailsList['engineTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.engineTypes
  );

  return policyDetailsList;
}
