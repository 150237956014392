import React from 'react';
import { IActivityEntityBase } from '.';
import { ITenant } from '../redux/tenant/types';
import { parseActivityHTML } from '../utils/activity-utils';

interface IActivityTemplate {
  activity: IActivityEntityBase;
  tenant: ITenant;
}

const ActivityTemplate: React.FC<IActivityTemplate> = ({
  activity,
  tenant,
}) => {
  const body = parseActivityHTML(activity, tenant);

  return <div dangerouslySetInnerHTML={{ __html: body }} />;
};

export default ActivityTemplate;
