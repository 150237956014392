import { isEmpty, isValidNumber } from '../../../../../utils/validationUtils';
import { IProposalPageFormState } from './form';
import { IProposalDetailsSummary } from './index2';

export interface IProposalDetailsExpatPageSubmissionModel {
  entityId: string;
  planId: string;
  policyCurrency: string;
  policyEffectiveDate: string;
  policyExpiryDate: string;
  policyPersonInput: {
    personId: string;
    personAddressId?: string;
    type: string;
    percentage?: number;
    orderNumber?: number;
    ownerIsDifferent?: boolean;
    relation?: string;
    isPrincipal?: boolean;
    cardNumber?: string;
    exclusions?: string;
    remarks?: string;
    grLimitDays?: number;
    continuity?: string;
    tpaReference1?: string;
    tpaReference2?: string;
    limitOfCover?: number;
  }[];
  policyPlanCoverInput: {
    coverPremiumPercentage: number;
    coverPremiumType: string;
    coverPremiumValue: number;
    excessOnClaimAmount: number;
    excessOnClaimDays: number;
    excessOnClaimPercentage: number;
    excessOnClaimType: string;
    planCoverID: string;
  }[];
  costAndChargeInput: {
    chargesPercentage: number;
    policyCost: number;
    tpaFeesAmount: number;
    tpaFeesApplicableOn: string;
    tpaFeesPercentage: number;
    tpaFeesType: string;
  };
  travelInput: {
    destination: string;
    duration: number;
    nbOfAdherent: number;
  };
}

export function convertProposalPageStateToSubmission(
  summary: IProposalDetailsSummary,
  pageState: IProposalPageFormState
) {
  const { values } = pageState;

  const policyPersons: IProposalDetailsExpatPageSubmissionModel['policyPersonInput'] =
    [];

  if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'PAYER',
      ownerIsDifferent: values.policyPersons.payer.ownerIsDifferent,
    });
  }
  if (values.policyPersons.owner?.ownerId) {
    policyPersons.push({
      personId: values.policyPersons.owner.ownerId,
      personAddressId: values.policyPersons.owner.ownerAddress,
      type: 'OWNER',
    });
  } else if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'OWNER',
    });
  }
  if (summary.LineId.ExternalCode === '48') {
    values.policyPersons.insured.forEach((person) => {
      if (person.id) {
        policyPersons.push({
          personId: person.id,
          personAddressId: person.address || undefined,
          type: 'INSURED',
          orderNumber: isValidNumber(person.order)
            ? Number(person.order)
            : undefined,
          relation: person.isPrincipal
            ? 'PRINCIPAL'
            : person.relation || undefined,
          isPrincipal: person.isPrincipal || false,
          cardNumber: person.cardNumber || undefined,
          exclusions: person.exclusions || undefined,
          remarks: person.remarks || undefined,
          grLimitDays: isValidNumber(person.grLimitDays)
            ? Number(person.grLimitDays)
            : undefined,
          continuity: person.continuity || undefined,
          tpaReference1: person.tpaReference1 || undefined,
          tpaReference2: person.tpaReference2 || undefined,
        });
      }
    });
  }

  values.policyPersons.beneficiaries.forEach((person) => {
    if (person.id) {
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address,
        type: 'BENEFICIARY',
        percentage: isValidNumber(person.percentage)
          ? person.percentage / 100
          : 0,
      });
    }
  });

  const data: IProposalDetailsExpatPageSubmissionModel = {
    entityId: summary.Id,
    planId: summary.PlanID.Id,
    policyCurrency: summary.PolicyCurrency.Code,
    policyEffectiveDate: summary.PolicyEffectiveDate,
    policyExpiryDate: summary.PolicyExpiryDate,
    policyPersonInput: policyPersons,
    policyPlanCoverInput: values.covers
      .filter((c) => !isEmpty(c.policyCover))
      .map((cover, i) => ({
        order: i + 1,
        coverPremiumPercentage: isValidNumber(cover.coverPremiumPercentage)
          ? cover.coverPremiumPercentage / 100
          : null,
        coverPremiumType: cover.coverPremiumType,
        coverPremiumValue: isValidNumber(cover.coverPremiumValue)
          ? Number(cover.coverPremiumValue)
          : null,
        excessOnClaimAmount: isValidNumber(cover.excessOnClaimAmount)
          ? Number(cover.excessOnClaimAmount)
          : null,
        excessOnClaimDays: isValidNumber(cover.excessOnClaimDays)
          ? Number(cover.excessOnClaimDays)
          : null,
        excessOnClaimPercentage: isValidNumber(cover.excessOnClaimPercentage)
          ? cover.excessOnClaimPercentage / 100
          : null,
        excessOnClaimType: cover.excessOnClaimType,
        planCoverID: cover.policyCover,
        isEditable: cover.isEditable,
        isMain: cover.isMain,
        coverSumInsured: isValidNumber(cover.coverSumInsured)
          ? Number(cover.coverSumInsured)
          : 0,
        sumInsuredIsAdditive: cover.sumInsuredIsAdditive,
        isMandatory: cover.isMandatory,
        clauseID: cover.clauseID,
        coverEffectiveFrom: cover.coverEffectiveFrom,
        coverEffectiveTo: cover.coverEffectiveTo,
      })),
    costAndChargeInput: {
      chargesPercentage: isValidNumber(values.costs.chargesPercentage)
        ? Number(values.costs.chargesPercentage) / 100
        : 0,
      policyCost: isValidNumber(values.costs.policyCost)
        ? Number(values.costs.policyCost)
        : 0,
      tpaFeesAmount: isValidNumber(values.costs.tpaFeesAmount)
        ? Number(values.costs.tpaFeesAmount)
        : 0,
      tpaFeesApplicableOn: values.costs.tpaFeesApplicableOn,
      tpaFeesPercentage: isValidNumber(values.costs.tpaFeesPercentage)
        ? values.costs.tpaFeesPercentage / 100
        : 0,
      tpaFeesType: values.costs.tpaFeesType,
    },
    travelInput: {
      destination: values?.travelDetails?.destination,
      duration: isValidNumber(values?.travelDetails?.duration)
        ? Number(values?.travelDetails?.duration)
        : 0,
      nbOfAdherent: values?.policyPersons?.insured?.length || 0,
    },
  };

  return data;
}
