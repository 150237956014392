import { getEnviromentDetails } from './env-utils';
import { getClient } from './identity-utils';

export function resolveGraphqlBaseUrl(): string {
  const baseUrl = getEnviromentDetails().graphBaseUrl;

  return baseUrl;
}

export function resolveIdentityBaseUrl(): string {
  const baseUrl = getEnviromentDetails().identityBaseUrl;

  return baseUrl;
}

export function resolveIdentityData(): URLSearchParams {
  const { client_id, client_secret } = getClient();
  const data = new URLSearchParams();
  data.append('grant_type', 'password');
  data.append('client_id', client_id);
  data.append('scope', 'openid profile');
  data.append('client_secret', client_secret);

  return data;
}

export function resolveMainHostUrl(): string {
  const baseUrl = getEnviromentDetails().mainHostUrl;

  return baseUrl;
}
