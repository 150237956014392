import { IAbstractRecord } from '../../../../models';

const BillStatusMapper = {
  NOT_DUE: 'Not Due',
  DUE: 'Due',
  BILLED: 'Billed',
  OVERDUE: 'Overdue',
  PARTIALLY_PAID: 'Partially Paid',
  PAID: 'Paid',
};

export const transformPolicyArrayToObject = (
  dataArray: IAbstractRecord[],
  paymentDivisions: Record<string, string>
) => {
  if (!dataArray.length) {
    return {};
  }
  return dataArray?.reduce((acc, item) => {
    acc[item.policy_Id] = {
      Id: item.policy_Id,
      PolicyNumber: item.policy_PolicyNumber,
      PaymentDivisionType: paymentDivisions[item.policy_PaymentDivisionType],
      PaymentMode: item.policy_PaymentMode?.Title,
      TotalPremium: item.receivableBills_TotalPremium,
      AmountReceived: 0,
      OutstandingBalanceReceivable: item.receivableBills_OutstandingBalance,
      TotalCommission: item.payableBills_TotalCommission,
      TaxOnCommission: item.receivableBills_TaxOnCommission,
      AmountPaid: 0,
      OutstandingBalancePayable: item.payableBills_OutstandingBalance,
      OutstandingPayable:
        Number(item.receivableBills_OutstandingBalance) -
        Number(item.payableBills_OutstandingBalance),
      Currency: item.receivableBills_Currency?.Symbol,
    };
    return acc;
  }, {});
};

export const transformReceivableBillsArrayToObject = (
  dataArray: IAbstractRecord[]
) => {
  if (!dataArray?.length) {
    return {};
  }
  return dataArray
    ?.filter((bill) => bill.accounting_Bills_BillType === 'RECEIVABLE')
    .reduce((acc, item) => {
      acc[item.accounting_Bills_Id] = {
        Id: item.accounting_Bills_Id,
        BillNumber: item.accounting_Bills_BillNumber,
        DueDate: item.accounting_Bills_DueDate,
        NetPremium: item.accounting_Bills_NetPremium,
        TotalPremium: item.accounting_Bills_TotalPremium,
        AmountReceived: 0,
        OutstandingBalance: item.accounting_Bills_OutstandingBalance,
        BillStatus:
          BillStatusMapper[
            item.accounting_Bills_BillStatus as keyof typeof BillStatusMapper
          ],
        Currency: item.accounting_Bills_Currency?.Symbol,
      };
      return acc;
    }, {});
};

export const transformPayableBillsArrayToObject = (
  dataArray: IAbstractRecord[]
) => {
  if (!dataArray?.length) {
    return {};
  }
  return dataArray?.reduce((acc, item) => {
    acc[item.accounting_Bills_Id] = {
      Id: item.accounting_Bills_Id,
      BillNumber: item.accounting_Bills_BillNumber,
      DueDate: item.accounting_Bills_DueDate,
      Discount: item.accounting_Bills_Discount,
      TotalCommission: item.accounting_Bills_TotalCommission,
      AmountReceived: 0,
      OutstandingBalance: item.accounting_Bills_OutstandingBalance,
      BillStatus:
        BillStatusMapper[
          item.accounting_Bills_BillStatus as keyof typeof BillStatusMapper
        ],
      Currency: item.accounting_Bills_Currency?.Symbol,
    };
    return acc;
  }, {});
};
