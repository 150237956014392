import { IAbstractRecord } from '../../../../models';
import { IListingData } from '../../../../models/listing';

export function mapToListingData(
  data: any,
  selectedBusinessPartnerIds: string[]
): IListingData<any> {
  const list: IAbstractRecord = {};
  data.Production.queries.getInsuredList.items.forEach(
    (obj: IGenericInsuredTableData, i: number) => {
      let BusinessPartner_FullName = '';
      const assignedBPId =
        obj?.production_Insured_AssignedToBusinessPartnerID?.id;
      const linkedPartnerIds = [
        obj?.production_Insured_LinkedBusinessPartnerIDs || ([] as any),
      ]?.map((o) => o?.views?.SalesforceManagement_all?.properties?.Id);

      if (selectedBusinessPartnerIds.includes(assignedBPId)) {
        const code =
          obj.production_Insured_AssignedToBusinessPartnerID?.views
            ?.SalesforceManagement_all?.properties?.Code;
        const fullName =
          obj.production_Insured_AssignedToBusinessPartnerID?.views
            ?.SalesforceManagement_all?.properties?.PersonID?.views
            ?.SalesforceManagement_all?.properties?.FullName;
        BusinessPartner_FullName = `P: ${code} - ${fullName}`;
      } else if (linkedPartnerIds.includes(assignedBPId)) {
        const matchingLinkedBP =
          obj.production_Insured_LinkedBusinessPartnerIDs.find(
            (o) =>
              o.views.SalesforceManagement_all.properties.Id === assignedBPId
          );
        const code =
          matchingLinkedBP?.views?.SalesforceManagement_all?.properties?.Code;
        const fullName =
          matchingLinkedBP?.views?.SalesforceManagement_all?.properties
            ?.PersonID?.views?.SalesforceManagement_all?.properties?.FullName;
        BusinessPartner_FullName = `S: ${code} - ${fullName}`;
      }

      list[i] = {
        production_Insured_Id: obj.production_Insured_Id,
        production_Insured_InsuredCode: obj.production_Insured_InsuredCode,
        production_Insured_FullName: obj.production_Insured_FullName,
        BusinessPartner_FullName: BusinessPartner_FullName,
        production_Insured_Email:
          obj.production_Insured_PersonID.views.SalesforceManagement_all
            .properties.Email,
        production_Insured_PhoneNumber:
          obj.production_Insured_PhoneDetails.PhoneNumber,
        production_Insured_createdOn: obj.production_Insured_createdOn,
        production_Insured_modifiedOn: obj.production_Insured_modifiedOn,
      };
    }
  );
  return {
    pagedItems: list,
    pageSize: data.Production.queries.getInsuredList?.paging?.pageSize,
    pageNumber: data.Production.queries.getInsuredList?.paging?.pageNumber,
    totalCount: data.Production.queries.getInsuredList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    businessPartner: {},
  };

  data?.Production?.queries?.getAssignedBPListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBP_Id: string;
      salesforceManagement_AssignedBP_BusinessPartnerID: {
        id: string;
        views: {
          SalesforceManagement_all: {
            properties: {
              Code: string;
              PersonID: {
                id: string;
                views: {
                  SalesforceManagement_all: {
                    properties: {
                      FullName: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      lovs.businessPartner[
        obj?.salesforceManagement_AssignedBP_BusinessPartnerID?.id
      ] =
        obj?.salesforceManagement_AssignedBP_BusinessPartnerID?.views?.SalesforceManagement_all?.properties?.PersonID?.views?.SalesforceManagement_all?.properties?.FullName;
    }
  );

  return lovs;
}
