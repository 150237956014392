import { gql } from '@apollo/client';

export function getListForm() {
  return gql`
    query getSalesForceBusinessPartnersInfoEnums {
      SalesforceManagement_BusinessPartnerStatuses: __type(
        name: "SalesforceManagement_BusinessPartnerStatuses"
      ) {
        name
        enumValues {
          name
        }
      }

      SalesforceManagement {
        lookups {
          relatedCompanies {
            Id
            Title
          }
          businessPartnerTypes {
            Id
            Title
          }
          businessPartnerCategories {
            Id
            Title
          }
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function checkBusinessPartnerByPerson() {
  return gql`
    query checkBusinessPartnerByPerson($selectedPersonID: String!) {
      SalesforceManagement {
        queries {
          checkBusinessPartnerByPerson(selectedPersonID: $selectedPersonID) {
            salesforceManagement_BusinessPartner_Id
            salesforceManagement_BusinessPartner_PersonID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    FullName
                    PersonCode
                  }
                }
              }
            }
            salesforceManagement_BusinessPartner_Status
          }
        }
      }
    }
  `;
}

export function createBusinessPartner() {
  return gql`
    mutation createBusinessPartner(
      $businessPartnerInputs: SalesforceManagement_BusinessPartnerInputs!
    ) {
      salesforceManagement {
        actions {
          createBusinessPartner(businessPartnerInputs: $businessPartnerInputs) {
            id
          }
        }
      }
    }
  `;
}

export function updateBusinessPartne() {
  return gql`
    mutation updateBusinessPartner(
      $entityId: String!
      $businessPartnerUpdateInputs: SalesforceManagement_BusinessPartnerUpdateInputs!
    ) {
      salesforceManagement {
        entities {
          businessPartner {
            salesforceManagement {
              updateBusinessPartner(
                entityId: $entityId
                businessPartnerUpdateInputs: $businessPartnerUpdateInputs
              ) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Account
                      RelatedCompany {
                        Code
                        Title
                      }
                      Type {
                        Code
                        Title
                      }
                      Category {
                        Code
                        Title
                      }
                      IsCompany
                      IsLocal
                      IsRegistered
                      RegisterNumber
                      TaxAccount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function fetchedPersons() {
  return gql`
    query fetchedPersons($searchKeyword: String) {
      SalesforceManagement {
        queries {
          AllActivePersons(searchKeyword: $searchKeyword) {
            salesforceManagement_Person_Id
            salesforceManagement_Person_FullName
            salesforceManagement_Person_PersonCode
          }
        }
      }
    }
  `;
}
