import { gql } from '@apollo/client';
export function getAccountingEnums() {
  return gql(/* GraphQL */ `
    query getAccountingEnums {
      Accounting {
        lookups {
          accountType {
            Id
            Title
          }
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
    }
  `);
}

export function getLayer2Groups() {
  return gql(/* GraphQL */ `
    query getLayer2Groups($SelectedCompany: String) {
      Accounting {
        queries {
          GetAccountGroupLayer2ByCompany(SelectedCompany: $SelectedCompany) {
            accounting_ChartOfAccountsGroup_Id
            accounting_ChartOfAccountsGroup_AccountGroup
            accounting_ChartOfAccountsGroup_AccountGroupName
          }
        }
      }
    }
  `);
}

export function getLayer3Groups() {
  return gql(/* GraphQL */ `
    query getLayer3Groups($SelectedCompany: String, $SelectedCategory: String) {
      Accounting {
        queries {
          GetAccountGroupLayer3ByCompany(
            SelectedCompany: $SelectedCompany
            SelectedCategory: $SelectedCategory
          ) {
            accounting_ChartOfAccountsGroup_Id
            accounting_ChartOfAccountsGroup_AccountGroup
            accounting_ChartOfAccountsGroup_AccountGroupName
          }
        }
      }
    }
  `);
}

export function getLayer4Groups() {
  return gql(/* GraphQL */ `
    query getLayer4Groups($SelectedCompany: String) {
      Accounting {
        queries {
          GetAccountGroupLayer4ByCompany(SelectedCompany: $SelectedCompany) {
            accounting_ChartOfAccountsGroup_Id
            accounting_ChartOfAccountsGroup_AccountGroup
            accounting_ChartOfAccountsGroup_AccountGroupName
          }
        }
      }
    }
  `);
}

export function getAccounts() {
  return gql(
    `query GetAccounts($Type: String) {
            Accounting {
                queries {
                GetAccountsThatAreNotSubType(SelectedAccountType: $Type) {
                    accounting_ChartOfAccounts_Id,
                    accounting_ChartOfAccounts_AccountName,
                }
                }
            }
        }
        `
  );
}

export function validateName() {
  return gql(
    `query validateName($Id: String, $Name: String, $SelectedCompanyID: String) {
            Accounting {
                queries {
                EnteredAccountNameOrIDExists(Id: $Id, Name: $Name, SelectedCompanyID: $SelectedCompanyID) {
                    accounting_ChartOfAccounts_Id
                }
                }
            }
            }
        `
  );
}

export function validateNameOnUpdate() {
  return gql(
    `query validateNameUpdate($Id: String, $Name: String, $CurrentAccountID: String, $SelectedCompanyID: String) {
            Accounting {
                queries {
                UpdatedAccountNameOrIDExists(Id: $Id, Name: $Name, CurrentAccountID: $CurrentAccountID, SelectedCompanyID: $SelectedCompanyID) {
                    accounting_ChartOfAccounts_Id
                }
                }
            }
        }
        `
  );
}

export function getAccountInfo() {
  return gql(/* GraphQL */ `
    query GetAccountInfo($id: String!) {
      Accounting {
        entities {
          chartOfAccounts(id: $id) {
            id
            views {
              Accounting_all {
                properties {
                  Id
                  AccountType {
                    Id
                  }
                  AccountCurrency {
                    Id
                  }
                  createdOn
                  modifiedOn
                  AccountCompany {
                    Id
                  }
                  AccountName
                  AccountID
                  AccountDescription
                  IsSubAccount
                  ParentAccount {
                    id
                  }
                  ParentAccountLayer2 {
                    id
                    views {
                      Accounting_all {
                        properties {
                          Id
                          AccountGroup
                          AccountGroupName
                          AccountGroupLayerLevel
                        }
                      }
                    }
                  }
                  ParentAccountLayer3 {
                    id
                    views {
                      Accounting_all {
                        properties {
                          Id
                          AccountGroup
                          AccountGroupName
                          AccountGroupLayerLevel
                        }
                      }
                    }
                  }
                  ParentAccountLayer4 {
                    id
                    views {
                      Accounting_all {
                        properties {
                          Id
                          AccountGroup
                          AccountGroupName
                          AccountGroupLayerLevel
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
}

export function createAccount() {
  return gql`
    mutation createAccount(
      $EnteredID: String!
      $EnteredName: String!
      $SelectedCompany: String!
      $SelectedCurrency: String!
      $SubCategory: String!
    ) {
      accounting {
        actions {
          createAccount(
            EnteredID: $EnteredID
            EnteredName: $EnteredName
            SelectedCompany: $SelectedCompany
            SelectedCurrency: $SelectedCurrency
            SubCategory: $SubCategory
          ) {
            id
          }
        }
      }
    }
  `;
}

export function updateAccount() {
  return gql`
    mutation modifyAccount(
      $CurrentAccountID: String!
      $UpdatedName: String!
      $AccountID: String!
    ) {
      accounting {
        actions {
          modifyAccount(
            UpdatedName: $UpdatedName
            AccountID: $AccountID
            CurrentAccountID: $CurrentAccountID
          )
        }
      }
    }
  `;
}

export function CheckIfAccountIDQuery() {
  return gql`
    query checkIfAccountID($AccountID: String!) {
      Accounting {
        queries {
          CheckIfAccountIDExists(AccountID: $AccountID) {
            accounting_ChartOfAccounts_AccountName
          }
        }
      }
    }
  `;
}
