import React from 'react';
import ViewBreakdown, { IFormValues } from './ViewBreakdown';
import EnhancedModal from '../../../../../components/common/generic-modal/EnhancedModal';

interface IViewBreakdownShellProps {
  open: boolean;
  policyId: string;
  businessPartnerId: string;
  onClose: () => void;
  data: Partial<IFormValues>;
}

const ViewBreakdownShell: React.FC<IViewBreakdownShellProps> = ({
  open,
  policyId,
  businessPartnerId,
  onClose,
  data,
}) => {
  return (
    <EnhancedModal
      open={open}
      blur={true}
      onClose={onClose}
      title={`Receivables & Payables ${data.PolicyNumber}`}
    >
      <ViewBreakdown
        policyId={policyId}
        businessPartnerId={businessPartnerId}
        data={data}
      />
    </EnhancedModal>
  );
};

export default ViewBreakdownShell;
