import React from 'react';
import clsx from 'clsx';
import EnhancedCheckbox from '../enhanced-form/EnhancedCheckbox';
import { EnhancedTableHeader } from '.';
import EnhancedTableBodyCell from './EnhancedTableBodyCell';
import { ITableRowSettings } from '../widgets/listing-widget';
import Loader from '../Loader';
import { makeStyles } from 'tss-react/mui';
import { TableBody, TableCell, TableRow, lighten } from '@mui/material';
import {
  MAIN_ONE_THEME,
  contentFontFamilyRegular,
  contentFontFamilyBold,
} from '../../constants';
import { IAbstractRecord } from '../../models';

interface IEnhancedBodyProps {
  disableSelection?: boolean | DisableSelectionType;
  emptyRows: number;
  handleClick(
    name: string,
    checked: boolean,
    rowIndex?: string,
    rowId?: string
  ): void;
  handleCellValueChanged?(
    rowIndex: number,
    columnId: string,
    newValue: unknown
  ): void;
  handleBlur?(rowIndex: number, columnId: string, newValue: unknown): void;
  headers: Record<string, EnhancedTableHeader>;
  rowSettings?: ITableRowSettings;
  isSelected: (key: string) => boolean;
  rows?: IEnhancedRow[];
  currencySymbol?: string;
  dateFormat?: string;
  phoneNumberPattern?: string;
  entityName?: string;
  loader?: boolean;
  tableClasses?: {
    bodyRootClass?: string;
    cellRootClass?: string;
  };
  showCellFullData?: boolean;
}

export enum DisableSelectionType {
  'Hide' = 'Hide',
}

export interface IEnhancedRow {
  key: string;
  columns: IAbstractRecord;
  index: number;
  disabled?: boolean;
}

const useStyles = makeStyles()(() => ({
  bodyRoot: {
    '& tr:nth-of-type(odd)': {
      backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    },
  },
  rowRoot: {
    '&.Mui-selected': {
      backgroundColor: lighten(MAIN_ONE_THEME.palette.primary2.main, 0.85),
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: lighten(MAIN_ONE_THEME.palette.primary2.main, 0.85),
    },
  },
  cellRoot: {
    color: `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}  !important`,
    lineHeight: '12px !important',
    height: '34px !important',
    border: 'none !important',
    padding: '4px 2px !important',
    fontFamily: contentFontFamilyBold,
  },
  checkboxCellRoot: {
    width: '14px !important',
    padding: '0 14px 0 8px !important',
    border: 'none !important',
    height: '34px !important',
    position: 'relative !important' as 'relative',
    fontFamily: contentFontFamilyRegular,
  },
  checkboxCellRootWithMessage: {
    padding: '0 20px 0 8px !important',
  },
  noDataMessage: {
    textAlign: 'center',
    color: MAIN_ONE_THEME.palette.secondary2.main,
  },
  noDataCell: {
    color: MAIN_ONE_THEME.palette.primary1.main,
    border: 'none',
    height: '34px',
    fontSize: '14px',
    lineHeight: '12px',
    fontFamily: contentFontFamilyRegular,
  },
  errorIconStyle: {
    height: '15px',
    width: '15px',
    color: 'red',
    position: 'absolute',
    top: '10px',
    right: '8px',
    cursor: 'pointer',
  },
  paddedCellRoot: {
    padding: '0px 6px 0px 20px !important',
  },
  selectablePaddedCellRoot: {
    padding: '0px 6px 0px 60px !important',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize} !important`,
  },
}));

const EnhancedTableBody: React.FC<IEnhancedBodyProps> = ({
  emptyRows,
  handleClick,
  handleCellValueChanged,
  handleBlur,
  headers,
  isSelected,
  rows,
  currencySymbol,
  dateFormat,
  disableSelection,
  tableClasses,
  loader,
  showCellFullData,
}) => {
  const { classes } = useStyles();

  const getRootCellClassname = (headerIndex: number): string => {
    if (disableSelection && !headerIndex) {
      switch (typeof disableSelection) {
        case 'boolean':
          return disableSelection
            ? classes.paddedCellRoot
            : classes.selectablePaddedCellRoot;
        default:
          return classes.paddedCellRoot;
      }
    }
    return '';
  };

  return (
    <TableBody
      classes={{
        root: clsx(
          classes.bodyRoot,
          tableClasses ? tableClasses.bodyRootClass : undefined
        ),
      }}
    >
      {rows.length > 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.key);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.key}
              selected={isItemSelected}
              classes={{ root: classes.rowRoot }}
              onClick={() =>
                handleClick(
                  row.key,
                  !isItemSelected,
                  row.index.toString(),
                  row.columns.insurance_Subline_Id
                )
              }
            >
              {!disableSelection && (
                <TableCell
                  padding="none"
                  classes={{
                    root: clsx(classes.checkboxCellRoot),
                  }}
                >
                  <EnhancedCheckbox
                    checked={isItemSelected}
                    name={row.key}
                    onChange={() => handleClick}
                    checkboxOnly
                  />
                </TableCell>
              )}
              {Object.values(headers).map((header, headerIndex) => {
                return (
                  <EnhancedTableBodyCell
                    selected={isSelected(row.key)}
                    key={header.name + labelId}
                    row={row}
                    header={header}
                    currencySymbol={currencySymbol}
                    dateFormat={dateFormat}
                    // phoneNumberPattern={phoneNumberPattern}
                    className={clsx(
                      tableClasses
                        ? tableClasses.cellRootClass ||
                            getRootCellClassname(headerIndex)
                        : getRootCellClassname(headerIndex)
                    )}
                    showCellFullData={showCellFullData}
                    handleCellValueChanged={handleCellValueChanged}
                    handleUpdate={handleBlur}
                  />
                );
              })}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell
            rowSpan={2}
            colSpan={
              Object.keys(headers).length > 0
                ? Object.keys(headers).length + 1
                : 1
            }
            align="center"
            classes={{ root: classes.noDataCell }}
          >
            {loader ? <Loader /> : 'No Data'}
          </TableCell>
        </TableRow>
      )}
      {emptyRows > 0 && <TableRow style={{ height: 34 * emptyRows }} />}
    </TableBody>
  );
};

export default EnhancedTableBody;
