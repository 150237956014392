import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../components/common/WidgetPaper';
import { MAIN_ONE_THEME, mainFontFamilyRegular } from '../constants';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { SignoutUserAction } from '../redux/user/actions';
import { USER_ROLES } from '../redux/user/types';
import DataService from '../services/dataService';
import { isEmpty } from '../utils/validationUtils';
import { useNavigate } from 'react-router-dom';
import ShowForUser from '../components/user/ShowForUser';

const useStyles = makeStyles()(() => ({
  profileInfo: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    margin: '0 20px 0 0',
    height: ' 62px',
    maxWidth: '250px',
    fontFamily: mainFontFamilyRegular,
  },
  profileName: {
    display: 'block',
    width: '100%',
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    color: 'white',
    opacity: 0.7,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: mainFontFamilyRegular,
  },
  profileRole: {
    color: 'white',
    fontSize: MAIN_ONE_THEME.typography.small.sm1.fontSize,
    display: 'block',
    width: '100%',
    opacity: 0.7,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: mainFontFamilyRegular,
  },
  avatar: {
    float: 'right',
    height: '100%',
    minHeight: '62px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: mainFontFamilyRegular,
  },
  avatarImage: {
    height: '32px',
    width: '32px',
    borderRadius: '50%',
  },
  menu: {
    width: '100%',
    backgroundColor: 'white',
  },
  menuItem: {
    width: '100%',
    border: 0,
    cursor: 'pointer',
    minWidth: '125px',
    minHeight: '25px',
    backgroundColor: 'white',
    fontFamily: mainFontFamilyRegular,
    '&:hover': {
      backgroundColor: MAIN_ONE_THEME.palette.secondary3.main,
    },
  },
  icon: {
    color: 'white',
  },
  button: {
    backgroundColor: 'transparent',
    padding: '0 5px',
  },
  widgetPaper: {
    padding: '5px 10px 7px 10px',
    boxSizing: 'border-box',
    width: '100%',
    margin: '0',
  },
  arrowIcon: {
    position: 'absolute',
    top: '-8px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'white',
  },
}));

const ProfileSection: React.FC = () => {
  const { classes } = useStyles();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    DataService.post(
      '/api/accounts/logout',
      {},
      {
        credentials: 'include',
      },
      false
    );
    dispatch(SignoutUserAction());
    navigate('login');
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (openPopover) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const openPopover = Boolean(anchorEl);

  const redirectToSettings = () => {
    setAnchorEl(null);
    navigate('/settings/environment');
  };

  return (
    <>
      <div>
        <Button
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          className={classes.button}
          onClick={handleClick}
        >
          <span className={classes.profileInfo} style={{ marginRight: 0 }}>
            <span className={classes.profileName}>
              {`${user.info.firstName} ${
                !isEmpty(user.info.lastName) ? user.info.lastName : ''
              }`}
            </span>
            {user.userRoles && (
              <span className={classes.profileRole}>
                {USER_ROLES[user.userRoles[0] as keyof typeof USER_ROLES]}
              </span>
            )}
          </span>
          {openPopover ? (
            <ArrowDropUp color="inherit" className={classes.icon} />
          ) : (
            <ArrowDropDown color="inherit" className={classes.icon} />
          )}
        </Button>
        {openPopover && (
          <div
            style={{
              position: 'fixed',
              padding: '5px',
              left: anchorEl
                ? anchorEl.getBoundingClientRect().left + 'px'
                : 'auto',
              width: anchorEl ? anchorEl.offsetWidth + 'px' : 'auto',
            }}
          >
            <ArrowDropUp color="inherit" className={classes.arrowIcon} />
            <WidgetPaper className={classes.widgetPaper}>
              <div className={classes.menu}>
                <button
                  className={classes.menuItem}
                  type="button"
                  onClick={logOut}
                >
                  {'Log Out'}
                </button>
              </div>
              <ShowForUser
                allowedRoles={['Insurance-Admin', 'Insurance-Underwriter']}
              >
                <div className={classes.menu}>
                  <button
                    className={classes.menuItem}
                    type="button"
                    onClick={redirectToSettings}
                  >
                    {'Settings'}
                  </button>
                </div>
              </ShowForUser>
            </WidgetPaper>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileSection;
