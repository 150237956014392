import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { IListingTableFilter } from '.';
import clsx from 'clsx';
import CustomCheckboxDropdown from '../../common/custom-checkbox-dropdown/CustomCheckBoxDropdown';

interface IListingTableFilterProps {
  filters: Record<string, IListingTableFilter>;
  onFilterClick(filter: string, field: string, value: boolean): void;
  onClear(): void;
}

const useStyles = makeStyles()(() => ({
  title: {
    fontSize: '12px',
    minWidth: '125px',
    display: 'block',
    cursor: 'pointer',
    height: '100%',
  },
  boldTitle: {
    fontFamily: 'HelveticaNeue-bold',
  },
}));

const ListingTableFilter: React.FC<IListingTableFilterProps> = ({
  filters,
  onFilterClick,
  onClear,
}) => {
  const { classes } = useStyles();

  const getDropdownValue = (filter: IListingTableFilter) => {
    const { length } = filter.selectedValues;
    if (length > 0) {
      return (
        <span className={clsx(classes.title, classes.boldTitle)}>
          {filter.title} {`(+${length})`}
        </span>
      );
    }

    return (
      <span className={clsx(classes.title, classes.boldTitle)}>
        {filter.title}
      </span>
    );
  };

  return (
    filters && (
      <>
        {Object.values(filters).map((filter) =>
          filter.options.length > 0 ? (
            <CustomCheckboxDropdown
              key={filter.name}
              name={filter.name}
              options={filter.options}
              selectedOptions={filter.selectedValues}
              onOptionClick={(field, value) =>
                onFilterClick(filter.name, field, value)
              }
              onClear={onClear}
              value={getDropdownValue(filter)}
              disabled={false}
            />
          ) : (
            <></>
          )
        )}
      </>
    )
  );
};

export default ListingTableFilter;
