import { IListingData } from '../../models/listing';
import { lookupListAsRecordObject } from '../../utils/graph-utils';

const InvoiceStatus: Record<string, string> = {
  DUE: 'Due',
  NOT_DUE: 'Not Due',
  INVOICED: 'Invoiced',
  OVERDUE: 'Overdue',
  PARTIALLY_PAID: 'Partially Paid',
  PAID: 'Paid',
};

export function mapToListingData(data: any): IListingData<any> {
  const list: Record<string, IInvoicesGenericTableData> = {};
  data.Accounting.queries.InvoicesList?.items?.forEach(
    (obj: IInvoicesGenericTableData, i: number) => {
      const Status = InvoiceStatus[obj.accounting_Invoices_InvoiceStatus];
      list[i] = {
        accounting_Invoices_Id: obj.accounting_Invoices_Id,
        customer_CustomerName: obj.customer_CustomerName,
        accounting_Invoices_InvoiceDate: obj.accounting_Invoices_InvoiceDate,
        accounting_Invoices_InvoiceNumber:
          obj.accounting_Invoices_InvoiceNumber,
        accounting_Invoices_InvoiceStatus: Status,
        accounting_Invoices_InvoiceTotalAmount:
          obj.accounting_Invoices_InvoiceTotalAmount,
        accounting_Invoices_PaymentDate: obj.accounting_Invoices_PaymentDate,
        accounting_Invoices_createdOn: obj.accounting_Invoices_createdOn,
        accounting_Invoices_modifiedOn: obj.accounting_Invoices_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Accounting.queries.InvoicesList?.paging.pageSize,
    pageNumber: data.Accounting.queries.InvoicesList?.paging.pageNumber,
    totalCount: data.Accounting.queries.InvoicesList?.paging.totalCount,
  };
}

export function toLookupsData(data: any) {
  const lovs: Record<string, Record<string, string>> = {
    company: {},
    customer: {},
    status: {},
  };

  lovs.company = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.relatedCompanies
  );

  data?.Accounting?.queries?.GetCustomersOfCompany?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_Customers_Id) {
        lovs.customer[element.accounting_Customers_Id] =
          element.accounting_Customers_CustomerName;
      }

      lovs.status = InvoiceStatus;
    }
  );

  return lovs;
}
