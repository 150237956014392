import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  code: {
    name: 'code',
    title: 'Code',
    type: EnhancedTableHeaderType.Text,
  },
  currency: {
    name: 'currency',
    title: 'Currency',
    type: EnhancedTableHeaderType.Text,
  },
  rate: {
    name: 'rate',
    title: 'Rate',
    type: EnhancedTableHeaderType.Text,
  },
  effectiveFrom: {
    name: 'effectiveFrom',
    title: 'Effective From',
    type: EnhancedTableHeaderType.Date,
  },
  effectiveTo: {
    name: 'effectiveTo',
    title: 'Effective To',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        currency: {
          name: 'currency',
          title: 'Currency',
          type: FormInputTypes.chips,
          value: filterValues?.currency || [],
          placeholder: 'Currency',
          triggerUpdate: true,
          selectOptions: { ...lovs.currency },
        },
        date: {
          name: 'date',
          title: 'Date',
          type: FormInputTypes.date,
          value: filterValues.date || null,
          placeholder: 'Date',
          triggerUpdate: true,
          allowDeleteValue: true,
          canClearDate: true,
        },
      },
    },
  ];

  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+ New',
    onClick: () => undefined,
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
];
