import { lookupListAsRecordObject } from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const accountEnums: Record<string, Record<string, string>> = {};
  accountEnums['Type'] = lookupListAsRecordObject(
    data.Accounting.lookups.transferType
  );
  accountEnums['relatedCompanies'] = lookupListAsRecordObject(
    data.SalesforceManagement.lookups.relatedCompanies
  );

  return accountEnums;
}

export function EntityProductToList(data: any) {
  const lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.EnteredProductNameExists?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_Product_Id) {
        lookuplistAsRecordObject[element.accounting_Product_Id] =
          element.accounting_Product_Id;
      }
    }
  );

  return lookuplistAsRecordObject;
}

export function UpdateEntityProductToList(data: any) {
  const lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.UpdatedProductNameExists?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_Product_Id) {
        lookuplistAsRecordObject[element.accounting_Product_Id] =
          element.accounting_Product_Id;
      }
    }
  );

  return lookuplistAsRecordObject;
}

export function graphqlEntityToProductInfo(data: any): IProductInfo {
  return {
    productId: data?.Accounting?.entities?.product?.id,
    company:
      data?.Accounting?.entities?.product?.views?.Accounting_all?.properties
        ?.RelatedCompany?.Id,
    name: data?.Accounting?.entities?.product?.views?.Accounting_all?.properties
      ?.ProductName,
    description:
      data?.Accounting?.entities?.product?.views?.Accounting_all?.properties
        ?.ProductDescription,
    price:
      data?.Accounting?.entities?.product?.views?.Accounting_all?.properties
        ?.ProductPrice,
    incomeAccount:
      data?.Accounting?.entities?.product?.views?.Accounting_all?.properties
        ?.RelatedIncomeAccount?.id,
    expenseAccount:
      data?.Accounting?.entities?.product?.views?.Accounting_all?.properties
        ?.RelatedExpenseAccount?.id,
    BuySell:
      data?.Accounting?.entities?.product?.views?.Accounting_all?.properties
        ?.BuySell,
    SellThis: data?.Accounting?.entities?.product?.views?.Accounting_all
      ?.properties?.RelatedIncomeAccount?.id
      ? true
      : false,
    BuyThis: data?.Accounting?.entities?.product?.views?.Accounting_all
      ?.properties?.RelatedExpenseAccount?.id
      ? true
      : false,
  };
}
