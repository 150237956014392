import { lookupListAsRecordObject } from '../../utils/graph-utils';
export function LookupToList(data: any) {
  const accountEnums: Record<string, Record<string, string>> = {};
  accountEnums['Type'] = lookupListAsRecordObject(
    data.Accounting.lookups.accountType
  );
  accountEnums['Currency'] = lookupListAsRecordObject(
    data.Core.lookups.currencies
  );
  accountEnums['relatedCompanies'] = lookupListAsRecordObject(
    data.SalesforceManagement.lookups.relatedCompanies
  );

  return accountEnums;
}
