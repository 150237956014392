import { gql } from '@apollo/client';

export function getAssignPolicyCoverEnums() {
  return gql`
    query getAssignPolicyCoverEnums {
      Core_CoverPremiumTypes: __type(name: "Core_CoverPremiumTypes") {
        name
        enumValues {
          name
        }
      }
      Core_ExcessOnClaimTypes: __type(name: "Core_ExcessOnClaimTypes") {
        name
        enumValues {
          name
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function fetchedPolicyCovers() {
  return gql`
    query fetchedPolicyCovers(
      $selectedLineIds: [String]
      $searchKeyword: String!
    ) {
      PlanConfigManagement {
        queries {
          allPolicyCoverList(
            selectedLineIds: $selectedLineIds
            searchKeyword: $searchKeyword
          ) {
            planConfigManagement_PolicyCover_Id
            planConfigManagement_PolicyCover_createdOn
            planConfigManagement_PolicyCover_ExternalCode
            planConfigManagement_PolicyCover_Name
            planConfigManagement_PolicyCover_NameArabic
            planConfigManagement_PolicyCover_NameFrench
            planConfigManagement_PolicyCover_LineID {
              id
            }
          }
        }
      }
    }
  `;
}

export function fetchedClauses() {
  return gql`
    query fetchedClauses($selectedLineIds: [String], $searchKeyword: String!) {
      PlanConfigManagement {
        queries {
          allClauseList(
            selectedLineIds: $selectedLineIds
            searchKeyword: $searchKeyword
          ) {
            planConfigManagement_Clause_Id
            planConfigManagement_Clause_ExternalCode
            planConfigManagement_Clause_Name
          }
        }
      }
    }
  `;
}

export function getAssignedPolicyCoverInfo() {
  return gql`
    query getAssignedPolicyCoverInfo($id: String!) {
      PlanConfigManagement {
        entities {
          planCover(id: $id) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  SumInsuredIsAdditive
                  IsMandatory
                  IsEditable
                  IsMain
                  Id
                  ExcessOnClaimPercentage
                  ExcessOnClaimDays
                  ExcessOnClaimAmount
                  CoverSumInsured
                  CoverPremiumValue
                  CoverPremiumType
                  CoverPremiumPercentage
                  ClauseID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          ExternalCode
                          Name
                        }
                      }
                    }
                  }
                  ExcessOnClaimType
                  PolicyCoverID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  PlanID {
                    views {
                      PlanConfigManagement_all {
                        properties {
                          PlanCurrency {
                            Id
                            Title
                            Code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createAssignPolicyCover() {
  return gql`
    mutation createAssignPolicyCover(
      $planCoverInputs: PlanConfigManagement_PlanCoverInputs!
    ) {
      planConfigManagement {
        actions {
          createPlanCover(planCoverInputs: $planCoverInputs) {
            id
          }
        }
      }
    }
  `;
}

export function updateAssignPolicyCover() {
  return gql`
    mutation updateAssignPolicyCover(
      $entityId: String!
      $planCoverInputs: PlanConfigManagement_PlanCoverInputs!
    ) {
      planConfigManagement {
        entities {
          planCover {
            planConfigManagement {
              updatePlanCover(
                entityId: $entityId
                planCoverInputs: $planCoverInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
