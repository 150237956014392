import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery, GetProductAuditTrails } from './queries';
import {
  mapToListingData,
  toLookupsData,
  convertAuditTrailsToActivities,
} from './utils';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { IEnhanceTableHeaderClickable } from '../../components/enhanced-table';
import { toast } from 'react-toastify';
import CustomActivities from '../../activities/CustomActivities';
import { IEnhancedMenuItem } from '../../components';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
  MAIN_ONE_THEME,
} from '../../constants';
import dayjs from 'dayjs';
import ProductDrawer from '../../forms/product-drawer/ProductDrawer';
import AccountImportDrawer from '../../forms/account-import-drawer/AccountImportDrawer';
import { IActivityEntityBase } from '../../activities';
import Loader from '../../components/Loader';

interface IProductPageAuditTrailInfo {
  title: string;
  items: IActivityEntityBase[];
  loading: boolean;
}

const ProductsPage: React.FC = () => {
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [productDrawerOpen, setProductDrawerOpen] = useState<boolean>(false);
  const [accountImportDrawerOpen, setAccountImportDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');
  const actions: IEnhancedMenuItem[] = [
    {
      title: '+New Product',
      onClick: () => {
        setChosenEditId(undefined);
        setProductDrawerOpen(true);
      },
      isSecondary: true,
      backgroundColor: '#000',
      color: '#fff',
    },
  ];

  const FILTER_SESSION_KEY = 'productsFilter';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          createdOn: [],
          company: '41',
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [auditTrailInfo, setAuditTrailInfo] =
    useState<IProductPageAuditTrailInfo>();

  const [getProductAuditTrailsLazy] = useLazyQuery(GetProductAuditTrails());

  const [getProductsLazy] = useLazyQuery(listQuery());

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoading(true);
    try {
      const result = await getProductsLazy({
        variables: {
          SelectedCompany:
            filterV?.company && filterV?.company?.length > 0
              ? filterV?.company
              : '41',
          CreatedOnFromDate: filterV?.createdOn?.[0]
            ? dayjs(filterV.createdOn[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          CreatedOnToDate: filterV?.createdOn?.[1]
            ? dayjs(filterV.createdOn[1])
                .add(1, 'day')
                .format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          SelectedBuySell: filterV?.SellBuy ? filterV?.SellBuy : null,
          pageNumber: currentPage + 1,
          pageSize: pageSize,
        },
        fetchPolicy: 'no-cache',
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;

      // Update table data
      const newTableData = mapToListingData(data);
      setTableData({ ...newTableData, pageNumber: currentPage, pageSize });

      // Update filters
      const lovs = toLookupsData(data);

      const savedFilters = getFilter(FILTER_SESSION_KEY) || filterV;
      setFilterValues(savedFilters);

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterV?.company;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);

      setFilter(savedFilters, FILTER_SESSION_KEY);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, tableData.pageSize, savedFilters);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewHistoryClick = async (id: string, title: string) => {
    setAuditTrailInfo({
      items: [],
      loading: true,
      title: title,
    });

    try {
      const result = await getProductAuditTrailsLazy({
        variables: {
          id: id,
        },
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      if (result.data) {
        setAuditTrailInfo({
          items: convertAuditTrailsToActivities(result.data),
          loading: false,
          title: title,
        });
      }
    } catch (error) {
      console.error('Error loading audit trails:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handlePageChange = (page: number) => {
    loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = (numberOfRecordsPerPage: number) => {
    loadData(0, numberOfRecordsPerPage, filterValues);
  };

  (
    headers.accounting_Product_ProductName as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    setChosenEditId(payload.columns.accounting_Product_Id);
    setProductDrawerOpen(true);
  };

  (headers.viewHistory as IEnhanceTableHeaderClickable).callback = (
    payload: any
  ) => {
    const id = payload.columns.accounting_Product_Id;
    viewHistoryClick(id, payload.columns.accounting_Product_ProductName);
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    if (_.isEqual(newFilters, filterValues)) {
      // Do nothing if filters are the same
      return;
    }
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    await loadData(0, tableData.pageSize, newFilters);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: '20px' }}>
      <EnhancedTable
        title="Products"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        hideToolbar
        currentPage={tableData.pageNumber}
        disableSelection
        usePagination={true}
        actions={actions}
        loader={loading}
        showCellFullData={true}
      />
      {productDrawerOpen && (
        <ProductDrawer
          open={productDrawerOpen}
          onClose={() => setProductDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
          }}
          productId={chosenEditId}
          accountInfo={undefined}
        />
      )}
      {accountImportDrawerOpen && (
        <AccountImportDrawer
          open={accountImportDrawerOpen}
          onClose={() => setAccountImportDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
          }}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={'Products'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
      rightChildren={
        <CustomActivities
          loader={auditTrailInfo?.loading || false}
          title={auditTrailInfo?.title || ''}
          items={auditTrailInfo?.items || []}
        />
      }
      onRightCollapseClick={(v) => {
        if (auditTrailInfo) {
          setAuditTrailInfo(undefined);
        }
      }}
      config={{
        leftColumn: {
          backgroundColor: '#FFFFFF',
          collapsable: false,
          collapsed: false,
          width: 2,
        },
        mainColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: false,
          collapsed: false,
          width: auditTrailInfo ? 7 : 10,
        },
        rightColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: true,
          collapsed: !auditTrailInfo,
          width: auditTrailInfo ? 3 : 0,
        },
      }}
    />
  );
};

export default ProductsPage;
