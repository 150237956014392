import { ITransactionInfo } from '.';
import { lookupListAsRecordObject } from '../../utils/graph-utils';
import { IAccountCurrency, ITransaction, ITransactionFormState } from './form';

export function LookupToList(data: any) {
  const result: Record<string, Record<string, string>> = {};

  result['relatedCompanies'] = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.relatedCompanies
  );

  result['transactionTypes'] = lookupListAsRecordObject(
    data?.Accounting?.lookups?.transactionType,
    false,
    'Code',
    'LongTitle'
  );

  return result;
}

export function getSystemCurrency(data: any) {
  return data?.Core?.queries?.getSystemCurrencies[0]
    ?.core_SystemCurrency_BasicCurrency?.Symbol;
}

export function getSystemSecondaryCurrency(data: any) {
  return data?.Core?.queries?.getSystemCurrencies[0]
    ?.core_SystemCurrency_SecondaryCurrency?.Symbol;
}

export function getSystemSecondaryCurrencyCode(data: any) {
  return data?.Core?.queries?.getSystemCurrencies[0]
    ?.core_SystemCurrency_SecondaryCurrency?.Code;
}

export function getAccountsOfCompanyToList(data: any) {
  const lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.GetAccountsOfCompany?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_ChartOfAccounts_Id) {
        lookuplistAsRecordObject[element.accounting_ChartOfAccounts_Id] =
          element.accounting_ChartOfAccounts_AccountID +
          ' ' +
          element.accounting_ChartOfAccounts_AccountName;
      }
    }
  );

  return lookuplistAsRecordObject;
}

export function getAccountsOfCompanyCurrenciesToList(data: any) {
  const lookuplistAsRecordObject: Record<
    string,
    { id: string; symbol: string }
  > = {};

  data?.Accounting?.queries?.GetAccountsOfCompany?.forEach(
    (element: { [key: string]: any }) => {
      if (element.accounting_ChartOfAccounts_Id) {
        lookuplistAsRecordObject[element.accounting_ChartOfAccounts_Id] = {
          id: element.accounting_ChartOfAccounts_AccountCurrency.Id,
          symbol: element.accounting_ChartOfAccounts_AccountCurrency.Symbol,
        };
      }
    }
  );

  return lookuplistAsRecordObject;
}

export const getTransactionPageFormState = (
  transaction?: ITransaction,
  account: IAccountCurrency[] = [],
  deleteAccounts: string[] = []
): ITransactionFormState => ({
  values: {
    transaction: transaction || {
      company: '',
      transactionType: '',
      reference: '',
      date: '',
      description: '',
      number: '',
      note: '',
      TransactionDocuments: [],
    },
    account: account || [],
    delete: deleteAccounts || [],
  },
  errors: {
    transaction: {
      company: '',
      transactionType: '',
      reference: '',
      date: '',
      description: '',
      note: '',
    },
    account: account.map((a) => ({
      accountName: '',
      description: '',
      accountDebit: '',
      accountCredit: '',
      systemDebit: '',
      systemCredit: '',
    })),
  },
  touched: {
    transaction: {
      company: false,
      reference: false,
      date: false,
      description: false,
      note: false,
    },
    account: account.map((_a) => ({
      accountName: false,
      description: false,
      accountDebit: false,
      accountCredit: false,
      systemDebit: false,
      systemCredit: false,
    })),
  },
});

export function graphqlEntityToTransactionInfo(data: any): ITransactionInfo {
  const singleTransaction =
    data?.Accounting.entities.transactions.views?.Accounting_all?.properties;

  return {
    CurrentTransactionID: data?.Accounting.entities.transactions.id,
    ReferenceNumber: singleTransaction?.ReferenceNumber,
    TransactionType: singleTransaction?.TransactionType?.Code,
    TransactionDate: singleTransaction?.TransactionDate,
    Description: singleTransaction?.Description,
    Note: singleTransaction?.Note,
    RelatedCompany: singleTransaction?.RelatedCompany,
    TotalSystemCredit: singleTransaction?.TotalSystemCredit,
    TotalSystemDebit: singleTransaction?.TotalSystemDebit,
    TransactionNumber: singleTransaction?.TransactionNumber,
    JournalTransactions:
      data?.Accounting.entities.transactions.queries?.accounting
        ?.GetJournalTransactions,
    TransactionDocuments: singleTransaction?.TransactionDocuments || [],
  };
}
