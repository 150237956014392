import React from 'react';
import DialogFormTemplate, {
  IDialogFormTemplateProps,
} from './DialogFormTemplate';
import EnhancedDialog, { IEnhancedDialogProps } from './EnhancedDialog';

type EnhancedModalProps = IEnhancedDialogProps & IDialogFormTemplateProps;

const EnhancedModal: React.FC<EnhancedModalProps> = ({
  open,
  blur,
  onClose,
  children,
  ...props
}) => {
  return (
    <EnhancedDialog open={open} onClose={onClose} blur={blur}>
      <DialogFormTemplate onClose={onClose} {...props}>
        {children}
      </DialogFormTemplate>
    </EnhancedDialog>
  );
};

export default EnhancedModal;
