import React, { ComponentProps, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from 'tss-react/mui';
import EnhancedButton from '../components/EnhancedButton';
import ToastErrorMessage from '../components/ToastErrorMessage';
import ToastSuccessMessage from '../components/ToastSuccessMessage';
import EnhancedInput from '../components/enhanced-form/EnhancedInput';
import {
  MAIN_ONE_THEME,
  contentFontFamilyBold,
  contentFontFamilyRegular,
} from '../constants';
import AuthLayout from '../layouts/AuthBody';
import { useAppSelector } from '../redux/hooks';
import DataService from '../services/dataService';
import { isMobileDevice } from '../utils/appUtils';
import { REGEX, isEmpty } from '../utils/validationUtils';

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    backgroundColor: '#F8F8F8',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center',
    width: '100%',
  },
  logoFig: {
    margin: '0 auto',
    lineHeight: '0',
  },
  boxContainer: {
    maxWidth: '430px',
    width: 'calc(100% - 16px)',
    margin: '46px auto 0',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  greeting: {
    color: MAIN_ONE_THEME.palette.primary2.main,
    fontSize: '28px',
    lineHeight: '36px',
    textAlign: 'center',
    fontFamily: contentFontFamilyBold,
    letterSpacing: '0px',
    width: '100%',
    margin: '0',
    padding: '28px 0 0 0',
    fontWeight: 'normal',
  },
  description: {
    color: '#1E3050',
    fontFamily: contentFontFamilyRegular,
    fontSize: '16px',
    marginTop: '9px',
    borderBottom: '1px solid #F7F7F7',
    paddingBottom: '28px',
    textAlign: 'center',
    lineHeight: '20px',
  },
  submitBtn: {
    margin: '24px 0 12px 0',
    borderRadius: '8px !important',
    fontFamily: 'HelveticaNeue-Regular !important',
    fontSize: '13px !important',
    lineHeight: '15px !important',
  },
  loginButton: {
    margin: '24px auto 12px auto',
    borderRadius: '8px !important',
    fontFamily: 'HelveticaNeue-Regular !important',
    fontSize: '13px !important',
    lineHeight: '15px !important',
  },
  formContainer: {
    padding: '24px 30px',
    width: '100%',
  },
  formError: {
    color: theme.palette.error.main,
    height: 'fit-content',
    marginTop: '8px',
    boxSizing: 'border-box',
    padding: '0px 12px 0px 12px',
    fontSize: '11px',
    textAlign: 'center',
  },
  inputLabel: {
    fontFamily: 'HelveticaNeue-Regular !important',
    fontSize: '13px',
    lineHeight: '15px',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

type SetPasswordForm = {
  email: string;
  password: string;
  confirmPassword: string;
};

const SetPassword: React.FC = () => {
  const tenant = useAppSelector((state) => state.tenant);

  const loginMeta = tenant.login;
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token')?.replaceAll(' ', '+');

  const isEmailSent = !isEmpty(email);

  const form = useForm<SetPasswordForm>({
    defaultValues: {
      email: isEmailSent ? email : '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    formState: { isLoading, isSubmitting, isSubmitSuccessful, isSubmitted },
  } = form;

  const [btnState, setBtnState] =
    useState<ComponentProps<typeof EnhancedButton>['state']>();

  const showProceedToLogin = useRef(false);

  const onSubmit = async (formValues: SetPasswordForm) => {
    setBtnState('loading');
    const data = {
      email: formValues.email,
      newPassword: formValues.password,
      token,
    };
    try {
      const response = await DataService.post(
        '/api/Accounts/resetpassword',
        data
      );
      if (response.ok) {
        setBtnState('success');
        toast.success(
          <ToastSuccessMessage>Password set successfully</ToastSuccessMessage>
        );
        setTimeout(() => {
          setBtnState(undefined);
          showProceedToLogin.current = true;
        }, 500);
      } else if (response.status === 401) {
        setBtnState(undefined);
        toast.error(
          <ToastErrorMessage>
            Access Denied. Please contact system administrator
          </ToastErrorMessage>
        );
        // navigate(`/login${window.location.search}`);
      } else if (response.status === 400) {
        setBtnState(undefined);
        toast.error(
          <ToastErrorMessage>
            Cannot reset password: Token invalid or already used
          </ToastErrorMessage>
        );
      } else {
        setBtnState(undefined);
        toast.error(
          <ToastErrorMessage>Something went wrong.</ToastErrorMessage>
        );
      }
    } catch {
      setBtnState(undefined);
      toast.error(<ToastErrorMessage>Something went wrong.</ToastErrorMessage>);
    }
  };

  return (
    <AuthLayout>
      <figure className={classes.logoFig}>
        <img
          height={117}
          width={125}
          src={tenant.cdnUrl + loginMeta.logoUrl}
          alt={'UFA logo'}
        />
      </figure>
      <div className={classes.boxContainer}>
        <h1 className={classes.greeting}>Set Password</h1>
        <div className={classes.description}>Set Your Account Password</div>
        <div className={classes.formContainer}>
          {isSubmitted && isSubmitSuccessful && showProceedToLogin.current ? (
            <EnhancedButton
              type="button"
              variant="contained"
              backgroundColor={MAIN_ONE_THEME.palette.primary5.main}
              color="rgba(255, 255, 255, 1)"
              style={{ width: '108px' }}
              className={classes.loginButton}
              onClick={() => {
                if (isMobileDevice()) {
                  window.location.replace(
                    `com.ufa://login?email=${form.getValues('email')}`
                  );
                } else {
                  window.location.replace('/');
                }
              }}
            >
              Login
            </EnhancedButton>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormProvider {...form}>
                {!isEmailSent && (
                  <Controller
                    name={'email'}
                    control={form.control}
                    rules={{
                      required: 'Required',
                      pattern: {
                        value: REGEX.email,
                        message: 'Invalid format',
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <EnhancedInput
                        key={field.name}
                        title={'Email'}
                        type="text"
                        placeholder="john@example.com"
                        error={fieldState.error?.message || ''}
                        disabled={isSubmitting || isLoading}
                        customStyles={{
                          labelStyles: classes.inputLabel,
                        }}
                        {...field}
                      />
                    )}
                  />
                )}
                <Controller
                  name={'password'}
                  control={form.control}
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: REGEX.password,
                      message:
                        'Your password is weak, please check the hint above.',
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <EnhancedInput
                      key={field.name}
                      title={'New Password'}
                      type="password"
                      error={fieldState.error?.message || ''}
                      disabled={isSubmitting || isLoading}
                      customStyles={{
                        labelStyles: classes.inputLabel,
                      }}
                      includePasswordhint={true}
                      includePasswordVisibility={true}
                      {...field}
                      style={{ marginBottom: '10px' }}
                    />
                  )}
                />
                <Controller
                  name={'confirmPassword'}
                  control={form.control}
                  rules={{
                    required: 'Required',
                    validate: (value, formValues) => {
                      if (value !== formValues.password) {
                        return 'Passwords do not match';
                      }
                      return true;
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <EnhancedInput
                      key={field.name}
                      title={'Confirm Password'}
                      type="password"
                      error={fieldState.error?.message || ''}
                      disabled={isSubmitting || isLoading}
                      customStyles={{
                        labelStyles: classes.inputLabel,
                      }}
                      {...field}
                    />
                  )}
                />
                <div className={classes.footerContainer}>
                  <EnhancedButton
                    type="submit"
                    disabled={isSubmitting || isLoading}
                    variant="contained"
                    backgroundColor={MAIN_ONE_THEME.palette.primary5.main}
                    color="rgba(255, 255, 255, 1)"
                    style={{ width: '108px' }}
                    state={btnState}
                    className={classes.submitBtn}
                  >
                    SUBMIT
                  </EnhancedButton>
                </div>
              </FormProvider>
            </form>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default SetPassword;
