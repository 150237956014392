import React from 'react';
import EnhancedModal from '../../components/common/generic-modal/EnhancedModal';
import ClausePopUp from './ClausePopUp';

const ClausePopUpShell: React.FC<IClausePopUpShellProps> = ({
  clauseId,
  open,
  onClose,
  ...props
}) => {
  return (
    <EnhancedModal
      open={open}
      blur={true}
      onClose={onClose}
      title={clauseId ? 'Edit Clause' : 'New Clause'}
    >
      <ClausePopUp clauseId={clauseId} onClose={onClose} {...props} />
    </EnhancedModal>
  );
};

export default ClausePopUpShell;
