import React from 'react';
import { ITransactionPopUpProps } from '.';
import TransactionPopUpForm from './TransactionPopUpForm';
import { useQuery } from '@apollo/client';
import { getEnums, getTransactionInfo } from './queries';

const TransactionPopUp: React.FC<ITransactionPopUpProps> = ({
  transactionId,
  onSuccess,
  onClose,
}) => {
  const { data: enums } = useQuery(getEnums());
  const { data: transaction, loading } = useQuery(getTransactionInfo(), {
    variables: { id: transactionId },
    skip: !transactionId,
  });

  return (
    <TransactionPopUpForm
      onClose={onClose}
      onSuccess={onSuccess}
      transactionDetailsLoading={loading}
      data={{ enums, transaction }}
      transactionId={transactionId}
    />
  );
};

export default TransactionPopUp;
