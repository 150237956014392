import { gql } from '@apollo/client';

export function getBusinessPartnerListWithoutPagination() {
  return gql`
    query getBusinessPartnerListWithoutPagination(
      $businessPartnerStatus: [SalesforceManagement_BusinessPartnerStatuses]
    ) {
      SalesforceManagement {
        queries {
          getBusinessPartnerListWithoutPagination(
            SelectedStatuses: $businessPartnerStatus
          ) {
            salesforceManagement_BusinessPartner_Id
            salesforceManagement_BusinessPartner_PersonID {
              views {
                SalesforceManagement_all {
                  properties {
                    FullName
                    Email
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getBPPlanListByAssignedLines() {
  return gql`
    query getBPPlanListByAssignedLines(
      $selectedBusienssUserID: String!
      $selectedBusinessPartnerID: String!
      $selectedLineID: String!
    ) {
      SalesforceManagement {
        queries {
          getBPPlanListByAssignedLines(
            selectedBusienssUserID: $selectedBusienssUserID
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            selectedLineID: $selectedLineID
            SelectedStatuses: [ACTIVE]
          ) {
            salesforceManagement_BusinessPartnerPlan_Id
            salesforceManagement_BusinessPartnerPlan_ActiveFrom
            salesforceManagement_BusinessPartnerPlan_ActiveTo
            salesforceManagement_BusinessPartnerPlan_PlanID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                  }
                }
              }
            }
            salesforceManagement_BusinessPartnerPlan_Status
            salesforceManagement_BusinessPartnerPlan_PlanCommissionType
            salesforceManagement_BusinessPartnerPlan_PlanCommissionValue
            salesforceManagement_BusinessPartnerPlan_ValueCurrency {
              Title
            }
            salesforceManagement_BusinessPartnerPlan_ApplicableOn {
              Title
            }
          }
        }
      }
    }
  `;
}

export function createAssignedBPPlan() {
  return gql`
    mutation createAssignedBPPlan(
      $selectedBusinessUserID: String!
      $selectedBusinessPartnerID: String!
      $selectedBusinessPartnerLineID: String!
      $selectedBusinessPartnerPlanIDs: [String!]!
    ) {
      salesforceManagement {
        actions {
          createAssignedBPPlan(
            selectedBusinessUserID: $selectedBusinessUserID
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            selectedBusinessPartnerLineID: $selectedBusinessPartnerLineID
            selectedBusinessPartnerPlanIDs: $selectedBusinessPartnerPlanIDs
          ) {
            Items
          }
        }
      }
    }
  `;
}
