import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { DynamicFormInputType, IFormDateDynamicProps } from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useMutation } from '@apollo/client';
import { cloneDeep, isEmpty } from 'lodash';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import { IProviderDeactivateDrawerProps } from '.';
import { deactivateProvider } from './queries';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';
import dayjs from 'dayjs';
import Loader from '../../components/Loader';

const ProviderDeactivateDrawer: React.FC<IProviderDeactivateDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  providerId,
  effectiveDate,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [deactivateAction] = useMutation(deactivateProvider());
  const [booted, setBooted] = useState<boolean>(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const newEffectiveDate = new Date(effectiveDate);
      const today = new Date();

      const defaultValue = newEffectiveDate > today ? newEffectiveDate : today;

      updatedInputs.expiryDate.value = defaultValue;
      (updatedInputs.expiryDate as IFormDateDynamicProps).minDate =
        newEffectiveDate;

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variablesMutation = {
        entityId: providerId,
        expiryDate: dayjs(new Date(data.expiryDate)).format(
          SEND_TO_BACKEND_DATE_FORMAT
        ),
      };

      const res = await deactivateAction({
        variables: variablesMutation,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Provider successfully deactivated.'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          onSuccess();
          setFormDisabled(false);
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      //
    }
  };

  return (
    <GenericDrawer
      title={'Expiry Date'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ProviderDeactivateDrawer;
