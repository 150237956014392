import {
  Autocomplete,
  Chip,
  CircularProgress,
  createFilterOptions,
  FilterOptionsState,
  FormControl,
  TextField,
  Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IEnhancedFormInputBaseProps } from '.';
import { contentFontFamilyRegular, MAIN_ONE_THEME } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import CustomPaper from './CustomPaper';

export interface IEnhancedInputProps extends IEnhancedFormInputBaseProps {
  name: string;
  title: string;
  type?: string;
  value: string[] | string;
  preselectedValues?: string;
  classes?: { input?: string; wrapper?: string };
  error: string;
  onChange?: (value: any) => void;
  onBlur?: (extraValue: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  customStyles?: {
    containerStyles?: any;
    labelStyles?: any;
    inputStyles?: any;
    errorStyles?: any;
  };
  selectOptions?: Record<string, any>;
  required?: boolean;
  inline?: boolean;
  description?: string;
  loader?: boolean;
  freeSolo?: boolean;
  onBlurSaveNewData?: boolean;
  multiple?: boolean;
  limitTags?: number;
  strongStyledOption?: boolean;
  hidden?: boolean;
  itemsPerPage?: number;
}

type EnhancedChipInputProps = IEnhancedInputProps & {
  showSelectAll?: boolean;
};

const useStyles = makeStyles<{
  error: string;
  canSelectAll: boolean;
  canClearAll: boolean;
}>()((theme, { error, canSelectAll, canClearAll }) => ({
  inputContainerStyles: {
    width: '100%',
    marginBottom: '5px',
    fontFamily: contentFontFamilyRegular,
  },
  inputStyles: {
    width: '100%',
    border: '1px solid #E3E3E3',
    outline: 'none',
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontStyle: 'italic',
        opacity: '22%',
        fontFamily: contentFontFamilyRegular,
        fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
      },
    },
    '& .MuiInputBase-root': {
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
      fontFamily: contentFontFamilyRegular,
      lineHeight: '15px !important',
      minHeight: '35px !important',
      width: '100% !important',
      padding: '0 25px 0 15px!important',
      margin: '0 auto !important',
      backgroundColor: `${MAIN_ONE_THEME.palette.secondary4.main} !important`,
      outlineColor: error ? MAIN_ONE_THEME.palette.error.main : undefined,
      border: error
        ? `1px solid ${MAIN_ONE_THEME.palette.error.main} !important`
        : `0px solid ${MAIN_ONE_THEME.palette.secondary3.main} !important`,
      boxSizing: 'border-box',
      borderRadius: '5px',
      '&::before': {
        display: 'none !important',
      },
      '&::after': {
        display: 'none !important',
      },
    },
  },
  optionDesign: {
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg3.fontSize}px !important`,
    lineHeight: '15px !important',
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontFamily: contentFontFamilyRegular,
  },
  inputLabelStyles: {
    marginBottom: '5px',
  },
  inputErrorStyles: {
    minHeight: '25px',
    color: '#f44336',
    textAlign: 'left',
    fontSize: '12px',
    marginTop: '5px',
  },
  inputRedBorder: {
    border: '1px solid #f44336',
  },
  chipStyle: {
    height: 'unset',
    color: '#fff',
    backgroundColor: MAIN_ONE_THEME.palette.primary1.main,
    borderRadius: '0px',
    margin: '3px',
    '& .MuiChip-deleteIcon': {
      color: 'white !important',
    },
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    right: '0.5%',
    opacity: 1,
    height: '25px',
    width: '25px',
    fontFamily: contentFontFamilyRegular,
  },
  selectMaterial: {
    '& .MuiInput-input': {
      fontFamily: contentFontFamilyRegular,
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
      lineHeight: '15px',
      color: `${MAIN_ONE_THEME.palette.primary2.main}`,
      paddingBottom: '0px !important',
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.20)',
        fontStyle: 'italic',
        opacity: '10',
        fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
      },
    },
    '& .MuiInputBase-root:before': {
      border: 'none',
      backgroundColor: 'unset !important',
      borderRadius: '0 !important',
      borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    },
    '& .MuiInput-root': {
      height: '34px',
      paddingBottom: '0px !important',
    },
    '& .MuiInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
      },
  },
  selectError: {
    '& .MuiInputBase-root:before': {
      borderBottom: `1px solid ${MAIN_ONE_THEME.palette.error.main}`,
    },
    '& .MuiInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid ${MAIN_ONE_THEME.palette.error.main}`,
      },
    '& .MuiInputBase-root:focus': {
      borderColor: MAIN_ONE_THEME.palette.error.main,
      outlineColor: MAIN_ONE_THEME.palette.error.main,
      boxShadow: 'none',
      borderRadius: 0,
    },
    '& .MuiInputBase-root': {
      borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
      outlineColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  actionButton: {
    textDecoration: 'none',
    border: 0,
    outline: 0,
    background: 'transparent',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.7,
    },
  },
  selectAllButton: {
    pointerEvents: canSelectAll ? undefined : 'none',
    opacity: canSelectAll ? undefined : 0.4,
  },
  clearAllButton: {
    pointerEvents: canClearAll ? undefined : 'none',
    opacity: canClearAll ? undefined : 0.4,
  },
  autocompletePopper: {
    '& .MuiAutocomplete-option': {
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
    },
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: '#DDDDDD',
      '&:hover': {
        backgroundColor: '#BBBBBB',
      },
      '&:not(:hover)': {
        backgroundColor: '#DDDDDD',
      },
    },
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)!important',
      cursor: 'not-allowed',
      opacity: `0.6 !important`,
      textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.5)!important',
      opacity: `0.6 !important`,
      textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.9) !important`,
    },
  },
  paginationControls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
  },
}));

const EnhancedInlinePaginatedChipInput: React.FC<EnhancedChipInputProps> = ({
  name,
  title,
  placeholder,
  className,
  style,
  classes = {},
  value: propValue,
  value,
  preselectedValues = '',
  error,
  onChange,
  onBlur = () => undefined,
  onFocus = () => undefined,
  description,
  disabled,
  selectOptions = {},
  inline,
  loader,
  freeSolo = false,
  onBlurSaveNewData = false,
  multiple = true,
  material,
  limitTags = -1,
  showSelectAll = false,
  strongStyledOption,
  customStyles,
  hidden = false,
  itemsPerPage = 15,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const options = useMemo(() => Object.keys(selectOptions), [selectOptions]);

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<string[]>(
    Array.isArray(propValue) ? propValue : [propValue]
  );

  useEffect(() => {
    if (Array.isArray(preselectedValues) && preselectedValues.length > 0) {
      setSelectedValues(preselectedValues);
    } else if (typeof preselectedValues === 'string') {
      setSelectedValues([preselectedValues]);
    }
  }, [preselectedValues]);

  useEffect(() => {
    setTotalPages(Math.ceil(options.length / itemsPerPage));
  }, [options]);

  const visibleOptions = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return options.slice(start, start + itemsPerPage);
  }, [currentPage, itemsPerPage, options]);

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const canSelectAll = useMemo(
    () =>
      multiple &&
      showSelectAll &&
      Object.keys(selectOptions).some(
        (option) => Array.isArray(value) && !value.includes(option)
      ),
    [multiple, showSelectAll, selectOptions, value]
  );
  const canClearAll = useMemo(
    () => multiple && showSelectAll && Array.isArray(value) && value.length > 0,
    [multiple, showSelectAll, selectOptions, value]
  );
  const { classes: inputClasses } = useStyles({
    error,
    canSelectAll,
    canClearAll,
  });

  const [isInputNumeric, setIsInputNumeric] = useState(false);

  const filterOptions = createFilterOptions<string>({
    matchFrom: 'any',
    stringify: (option) => selectOptions[option] || option,
  });

  const customFilterOptions = (
    options: string[],
    state: FilterOptionsState<string>
  ) => {
    if (isInputNumeric) {
      return options;
    }

    return filterOptions(options, state);
  };

  if (!Array.isArray(value) && !isEmpty(value)) {
    value = [value];
  }
  if (!Array.isArray(preselectedValues) && !isEmpty(preselectedValues)) {
    value = [preselectedValues];
  } else {
    value = preselectedValues;
  }

  const myRef = React.createRef<HTMLInputElement>();
  const handleAutocompleteChange = (
    event: any,
    newValue: any,
    reason: string
  ) => {
    if (reason === 'selectOption') {
      const selectedLabel = newValue ? selectOptions[newValue] || newValue : '';
      setInputValue(selectedLabel);
    } else if (reason === 'clear') {
      setInputValue('');
    }

    setSelectedValues(Array.isArray(newValue) ? newValue : [newValue]);
    if (onChange) {
      onChange(Array.isArray(newValue) ? newValue : [newValue]);
    }
  };

  const handleInputChange = (
    event: any,
    newInputValue: string,
    reason: string
  ) => {
    if (reason === 'input') {
      setInputValue(newInputValue);

      const numeric = !isNaN(newInputValue as any) && newInputValue !== '';
      setIsInputNumeric(numeric);

      if (onChange) {
        onChange(newInputValue);
      }
    }
  };

  const renderInput = () => {
    return (
      <div className={inputClasses.inputContainerStyles}>
        <FormControl
          error={!!error}
          disabled={!!disabled}
          className={inputClasses.inputContainerStyles}
          size="small"
          variant="outlined"
        >
          <Autocomplete
            hidden={hidden}
            freeSolo={freeSolo}
            multiple={multiple}
            options={visibleOptions}
            className={clsx({
              [inputClasses.disabledInput]: disabled,
            })}
            classes={{ popper: inputClasses.autocompletePopper }}
            disabled={!!disabled || loader}
            disablePortal={false}
            // options={Object.keys(selectOptions)}
            filterOptions={customFilterOptions}
            onChange={handleAutocompleteChange}
            onInputChange={handleInputChange}
            inputValue={inputValue || preselectedValues}
            onFocus={(event: any) => onFocus(event)}
            onBlur={(event: any) => {
              if (onBlurSaveNewData) {
                onBlur(event.target.value);
              } else {
                onBlur('');
              }
            }}
            clearOnBlur={onBlurSaveNewData}
            value={selectedValues}
            getOptionLabel={(option) =>
              !isEmpty(option) &&
              !isEmpty(selectOptions) &&
              selectOptions[option as string]
                ? selectOptions[option as string]
                : option
            }
            isOptionEqualToValue={(option, value) => option === value}
            disableClearable={!multiple}
            selectOnFocus
            disableCloseOnSelect={multiple}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option as unknown as string}>
                <span className={inputClasses.optionDesign}>
                  {strongStyledOption ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: (
                          selectOptions[option as string] as string
                        ).replace(
                          option as string,
                          (('<strong>' + option) as string) + '</strong>'
                        ),
                      }}
                    />
                  ) : (
                    selectOptions[option as string]
                  )}
                </span>
              </li>
            )}
            renderTags={(value: readonly string[], getTagProps) =>
              Array.isArray(value) &&
              value.map((option: string, index: number) => (
                <Tooltip
                  key={option}
                  title={
                    !isEmpty(selectOptions[option])
                      ? selectOptions[option]
                      : option
                  }
                >
                  {!isEmpty(selectOptions[option]) && (
                    <Chip
                      variant="filled"
                      label={selectOptions[option]}
                      {...getTagProps({ index })}
                      key={index}
                      className={inputClasses.chipStyle}
                    />
                  )}
                </Tooltip>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: disabled
                    ? {
                        color: 'rgba(0, 0, 0, 0.5) !important',
                        opacity: 1,
                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.5) !important',
                        WebkitTextEmphasisColor:
                          'rgba(0, 0, 0, 0.5) !important',
                      }
                    : {},
                  endAdornment: (
                    <>
                      {loader ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                {...((Array.isArray(value) &&
                  value &&
                  value.length == 0 &&
                  placeholder) ||
                !Array.isArray(value)
                  ? { placeholder: `${placeholder}` }
                  : {})}
                value={inputValue}
                name={name}
                className={clsx({
                  [inputClasses.inputStyles]: !material,
                  [inputClasses.selectMaterial]: material,
                  [inputClasses.selectError]: error,
                  [inputClasses.disabledInput]: disabled,
                })}
                variant="standard"
                inputRef={myRef}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && inputValue) {
                    if (
                      freeSolo &&
                      inputValue.length &&
                      !selectedValues.includes(inputValue)
                    ) {
                      const newSelectedValues = [...selectedValues, inputValue];
                      setSelectedValues(newSelectedValues);
                      if (onChange) {
                        onChange(newSelectedValues);
                      }
                      setInputValue('');
                    } else if (
                      !freeSolo &&
                      visibleOptions.includes(inputValue)
                    ) {
                      const newSelectedValues = [...selectedValues, inputValue];
                      setSelectedValues(newSelectedValues);
                      if (onChange) {
                        onChange(newSelectedValues);
                      }
                      setInputValue('');
                    }
                  }
                }}
              />
            )}
            PaperComponent={(props) => (
              <CustomPaper
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}
              >
                {props.children}
              </CustomPaper>
            )}
            limitTags={limitTags}
          />
        </FormControl>
      </div>
    );
  };

  const onSelectAllClicked = () => {
    if (multiple) {
      if (myRef.current && document.activeElement !== myRef.current) {
        myRef.current.focus();
      }
      onChange(Object.keys(selectOptions));
    }
  };

  const onClearAllClicked = () => {
    if (multiple) {
      if (myRef.current && document.activeElement !== myRef.current) {
        myRef.current.focus();
      }
      onChange([]);
    }
  };

  const getActions = () => {
    if (!showSelectAll || !multiple) return <></>;
    return (
      <div className={inputClasses.actionsContainer}>
        <button
          type="button"
          onClick={onSelectAllClicked}
          className={clsx(
            inputClasses.actionButton,
            inputClasses.selectAllButton
          )}
        >
          Select All
        </button>
        <button
          type="button"
          onClick={onClearAllClicked}
          className={clsx(
            inputClasses.actionButton,
            inputClasses.clearAllButton
          )}
        >
          Clear All
        </button>
      </div>
    );
  };

  return inline ? (
    renderInput()
  ) : (
    <>
      {/* <p>
        Input component was rerendered {componentRerenderedTimes.current} times
      </p> */}
      <EnhancedInputsWrapper
        title={title}
        description={description}
        error={error}
        name={name}
        className={clsx(classes.wrapper, className)}
        style={style}
        actions={getActions()}
        customStyles={customStyles}
        hidden={hidden}
      >
        <div style={{ position: 'relative' }}>
          {renderInput()}
          {loader && (
            <CircularProgress
              className={inputClasses.loader}
              size={20}
              thickness={3}
            />
          )}
        </div>
      </EnhancedInputsWrapper>
    </>
  );
};

export default EnhancedInlinePaginatedChipInput;
