import { ITaxationDrawerInfo } from '.';
import { lookupListAsRecordObject } from '../../utils/graph-utils';

export function toLookups(data: any) {
  const taxationEnums: Record<string, Record<string, string>> = {};
  const line: Record<string, string> = {};

  data.PlanConfigManagement.queries.allLines.forEach(
    (element: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      line[element.planConfigManagement_Line_Id] =
        element.planConfigManagement_Line_Name;
    }
  );

  taxationEnums['line'] = line;

  taxationEnums['fixedStampCurrency'] = lookupListAsRecordObject(
    data.Core.lookups.currencies
  );

  return taxationEnums;
}

export function graphqlToTaxationInfo(data: any): ITaxationDrawerInfo {
  const taxation =
    data?.Configuration?.entities?.taxation?.views?.Configuration_all
      ?.properties;
  const line = taxation?.LineID?.views?.PlanConfigManagement_all?.properties;

  return {
    line: taxation?.LineID?.id,
    lineTitle: `${line?.ExternalCode} - ${line?.Name}`,
    fixedStampCurrency: taxation?.FixedStampCurrency?.Code,
    fixedStamp: taxation?.FixedStamp,
    proportionalStamp: parseFloat(
      (Number(taxation?.ProportionalStamp) * 100).toFixed(2)
    ),
    municipalityTax: parseFloat(
      (Number(taxation?.MunicipalityTax) * 100).toFixed(2)
    ),
    taxOnCommission: parseFloat(
      (Number(taxation?.TaxOnCommission) * 100).toFixed(2)
    ),
    reinsuranceTax: parseFloat(
      (Number(taxation?.ReinsuranceTax) * 100).toFixed(2)
    ),
    effectiveFrom: taxation?.EffectiveFrom,
    effectiveTo: taxation?.EffectiveTo,
  };
}

export function extractLines(data: any) {
  const lines: Record<string, string> = {};

  data.PlanConfigManagement.queries.allLines.forEach(
    (element: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
      planConfigManagement_Line_ExternalCode: string;
    }) => {
      lines[element.planConfigManagement_Line_Id] =
        `${element.planConfigManagement_Line_ExternalCode} - ${element.planConfigManagement_Line_Name}`;
    }
  );

  return lines;
}

export function extractLatestTaxationByLine(data: any) {
  const taxation = data?.Configuration?.queries?.getLatestTaxationByLine[0];
  return {
    fixedStampCurrency:
      taxation?.configuration_Taxation_FixedStampCurrency?.Code,
    fixedStamp: taxation?.configuration_Taxation_FixedStamp,
    proportionalStamp: `${Math.round(
      parseFloat(
        (
          Number(taxation?.configuration_Taxation_ProportionalStamp) * 100
        ).toFixed(10)
      )
    )}`,
    municipalityTax: `${Math.round(
      parseFloat(
        (
          Number(taxation?.configuration_Taxation_MunicipalityTax) * 100
        ).toFixed(10)
      )
    )}`,
    taxOnCommission: `${Math.round(
      parseFloat(
        (
          Number(taxation?.configuration_Taxation_TaxOnCommission) * 100
        ).toFixed(10)
      )
    )}`,
    reinsuranceTax: `${Math.round(
      parseFloat(
        (Number(taxation?.configuration_Taxation_ReinsuranceTax) * 100).toFixed(
          10
        )
      )
    )}`,
    effectiveFrom: taxation?.configuration_Taxation_EffectiveFrom,
  };
}
