import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';
import { enumListAsRecordObject } from '../../utils/graph-utils';

export const headers: Record<string, EnhancedTableHeader> = {
  system_User_firstName: {
    name: 'system_User_firstName',
    title: 'First Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  system_User_lastName: {
    name: 'system_User_lastName',
    title: 'Last Name',
    type: EnhancedTableHeaderType.Text,
  },
  system_User_email: {
    name: 'system_User_email',
    title: 'Email',
    type: EnhancedTableHeaderType.Text,
  },
  system_User_mobile: {
    name: 'system_User_mobile',
    title: 'Mobile Number',
    type: EnhancedTableHeaderType.Text,
  },
  system_User_roles: {
    name: 'system_User_roles',
    title: 'Role',
    type: EnhancedTableHeaderType.Text,
  },
  system_User_status: {
    name: 'system_User_status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
};

export function filterSectionsContent(
  data: any,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        role: {
          name: 'role',
          title: 'Role',
          type: FormInputTypes.chips,
          value: filterValues?.role || [],
          placeholder: 'Role',
          triggerUpdate: true,
          selectOptions: enumListAsRecordObject(
            data?.Insurance_UserRoles?.enumValues
          ),
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: enumListAsRecordObject(
            data?.Insurance_UserStatuses?.enumValues
          ),
        },
      },
    },
  ];
  return newFilterSections;
}
