import React from 'react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import { IEnhancedFormInputBaseProps } from '.';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';

export interface ISplitPrefixFormFieldProps
  extends IEnhancedFormInputBaseProps {
  value: string;
  prefixValue: string;
  inputType?: 'text' | 'number';
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const useStyles = makeStyles()(() => ({
  input: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: '15px',
    height: 35,
    width: '100%',
    padding: '0 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.5)!important',
      opacity: `0.5 !important`,
      textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
    },
    '&::placeholder': {
      fontStyle: 'italic',
      fontFamily: contentFontFamilyRegular,
      color: '#33333333',
      opacity: 1,
      fontSize: '14px',
    },
    fontFamily: contentFontFamilyRegular,
    '&:focus': {
      outline: 'none',
    },
    '::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    right: '3%',
    opacity: 1,
    height: '25px',
    width: '25px',
  },
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
  },
  inputError: {
    borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
  },
  iconContainer: {
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)!important',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)!important',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)!important',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)!important',
    },
  },
  prefixContainer: {
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    padding: '9px 9px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '60px',
    margin: '0 10px 0 0',
  },
  prefixText: {
    fontSize: '13px',
    lineHeight: '16px',
    color: '#231F20',
    fontFamily: 'SourceSansPro-Regular',
    height: '16px',
  },
  disabledPrefixContainer: {
    backgroundColor: '#f9f9f9',
    cursor: 'not-allowed',
  },
  disabledPrefixText: {
    color: 'rgba(0, 0, 0, 0.5)',
    opacity: '0.5',
  },
  errorPrefixContainer: {
    border: `1px solid ${MAIN_ONE_THEME.palette.error.main}`,
  },
}));

const SplitPrefixFormField: React.FC<ISplitPrefixFormFieldProps> = ({
  error,
  name,
  title,
  value,
  inputType = 'text',
  placeholder,
  disabled,
  material,
  loader,
  inputOnly,
  prefixValue,
  onChange,
  onFocus,
  onBlur,
}) => {
  const { classes: inputClasses } = useStyles();

  const renderInput = () => (
    <div className={inputClasses.inputContainer}>
      <div
        className={clsx(
          inputClasses.prefixContainer,
          disabled && inputClasses.disabledPrefixContainer,
          !isEmpty(error) && inputClasses.errorPrefixContainer
        )}
      >
        <span
          className={clsx(
            inputClasses.prefixText,
            disabled && inputClasses.disabledPrefixText
          )}
        >
          {prefixValue}
        </span>
      </div>
      <input
        className={clsx(inputClasses.input, {
          [inputClasses.materialInput]: material,
          [inputClasses.inputError]: error,
          [inputClasses.disabledInput]: disabled,
        })}
        title={disabled && !isEmpty(value) ? value.toString() : title}
        id={name}
        type={inputType}
        name={name}
        aria-invalid={!!error}
        aria-describedby={`errorMsg-${name}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );

  return inputOnly ? (
    renderInput()
  ) : (
    <EnhancedInputsWrapper title={title} error={error} name={name}>
      {renderInput()}
      {loader && (
        <CircularProgress
          className={inputClasses.loader}
          size={20}
          thickness={3}
        />
      )}
    </EnhancedInputsWrapper>
  );
};

export default SplitPrefixFormField;
