import {
  EnhancedHeaderInputTypes,
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';

export const steps = ['Inactive', 'Active'];

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Currency Exchange Rates',
  iconUrl: '',
  sections: [
    {
      name: 'currencyExchangeRatesSection1',
      title: '',
      properties: {
        effectiveDate: {
          value: '',
          multiline: false,
          name: 'effectiveDate',
          title: 'Effective Date',
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

export const currencyHeaders: Record<string, EnhancedTableHeader> = {
  core_CurrencyExchangeRate_CurrencyID_Code: {
    name: 'core_CurrencyExchangeRate_CurrencyID_Code',
    title: 'Code',
    type: EnhancedTableHeaderType.Text,
  },
  core_CurrencyExchangeRate_CurrencyID_Title: {
    name: 'core_CurrencyExchangeRate_CurrencyID_Title',
    title: 'Currency',
    type: EnhancedTableHeaderType.Text,
  },
  core_CurrencyExchangeRate_Rate: {
    name: 'core_CurrencyExchangeRate_Rate',
    title: 'Rate',
    type: EnhancedTableHeaderType.Input,
    inputType: EnhancedHeaderInputTypes.Decimal,
    hasBetweenValidation: false,
  },
};
