import {
  isEmpty,
  isValidEmail,
  validatePhoneNumber,
} from '../../utils/validationUtils';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';
import {
  IInsured,
  IPlan,
  initialValues,
  NO_DDUPLICATE_RELATEION_TYPES_KEYS,
} from '.';
import { steps } from './content';
import { isValidDate } from '../../utils/date-utils';
import { quote } from '../../components/custom/QuotesCards';
import { cloneDeep } from 'lodash';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

export function entityToApplicationDetails(data: any): any {
  const applicationInfo =
    data?.Insurance?.entities?.application?.views?.Insurance_all?.properties;

  const customerDetails =
    data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
      ?.ApplicationContactDetailsID?.views?.Insurance_all?.properties;

  const planDetails: Record<string, string | number>[] = [];
  data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.HealthDetailsID?.queries?.insurance?.plans?.forEach(
    (element: IPlan) => {
      planDetails.push({
        planId: element?.input_Id,
        product: element?.input_Product?.Code,
        class:
          element?.input_Product?.Code.toLowerCase() === 'optimum'
            ? element?.input_OptimumInHospitalClass?.Code
            : element?.input_StandardInHospitalClass?.Code,
        ambulatory:
          element?.input_Product?.Code.toLowerCase() === 'optimum'
            ? element?.input_OptimumAmbulatoryCoverage?.Code
            : element?.input_StandardAmbulatoryCoverage?.Code,
      });
    }
  );

  const insuredDetails: Record<string, string | number>[] = [];
  data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.HealthDetailsID?.views?.Insurance_all?.properties?.InsuredDetails?.forEach(
    (element: IInsured) => {
      insuredDetails.push({
        insuredName: element?.InsuredName,
        insuredRelation: element?.InsuredRelation,
        insuredDOB: element?.InsuredDOB,
      });
    }
  );

  return {
    applicationName: applicationInfo?.ApplicationName,
    effectiveDate: applicationInfo?.EffectiveDate,
    status: applicationInfo?.Status,
    statusReason: applicationInfo?.StatusReason,
    lineOfBusiness: applicationInfo?.LineOfBusiness?.Title,
    agencyName:
      applicationInfo?.AssignedAgencyID?.views?.Insurance_all?.properties
        ?.AgencyName,
    agentName:
      applicationInfo?.AssignedAgentID?.views?.Insurance_all?.properties
        ?.FirstName +
      ' ' +
      applicationInfo?.AssignedAgentID?.views?.Insurance_all?.properties
        ?.LastName,
    customerName:
      applicationInfo?.ApplicationContactDetailsID?.views?.Insurance_all
        ?.properties?.FullName,
    createdBy: `${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.createdBy_firstName} ${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: applicationInfo?.createdOn,
    modifiedBy: `${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.modifiedBy_firstName} ${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: applicationInfo?.modifiedOn,
    fullName: customerDetails?.FullName,
    email: customerDetails?.Email,
    mobileNumber: customerDetails?.MobileNumber,
    country: customerDetails?.Address?.Country?.Id || initialValues.country,
    city: customerDetails?.Address?.City,
    street: customerDetails?.Address?.Street,
    building: customerDetails?.Address?.Building,
    planDetails: planDetails,
    insuredDetails:
      !insuredDetails || insuredDetails.length == 0
        ? initialValues.insuredDetails
        : insuredDetails,
    title: customerDetails?.Title?.Id,
    phoneType: customerDetails?.PhoneType || initialValues.phoneType,
  };
}

export function extractLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    products: {},
    countries: {},
    insuredRelation: {},
    standardInHospitalClasses: {},
    optimumInHospitalClasses: {},
    standardAmbulatoryCoverages: {},
    optimumAmbulatoryCoverages: {},
    phoneTypes: {},
  };
  lovs['products'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.products
  );

  lovs['countries'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.countries
  );

  lovs['insuredRelation'] = enumListAsRecordObject(
    data.Insurance_InsuredRelations.enumValues
  );

  lovs['standardInHospitalClasses'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.standardInHospitalClasses
  );

  lovs['optimumInHospitalClasses'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.optimumInHospitalClasses
  );

  lovs['standardAmbulatoryCoverages'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.standardAmbulatoryCoverages
  );

  lovs['optimumAmbulatoryCoverages'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.optimumAmbulatoryCoverages
  );

  lovs['titles'] = lookupListAsRecordObject(data?.Insurance?.lookups?.titles);

  lovs['phoneTypes'] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues
  );

  return lovs;
}

export function graphqlEntityToQuoteDetails(
  data: any,
  index: number,
  currencySymbol: string,
  lob: string
): quote {
  return {
    lineOfBusiness: lob,
    id: data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
      ?.HealthDetailsID?.queries?.insurance?.plans[index]?.quote_Id,
    title:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]?.quote_Name,
    status:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]?.quote_Status,
    statusReason:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]?.quote_StatusReason,
    createdOn:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.createdOn,
    modifiedOn:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.modifiedOn,
    hasCoverage: true,
    coverageAmount:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]
        ?.quote_TotalAnnualPremium,
    coverageAmountCurrencySymbol: currencySymbol,
    premiumAmount: '0',
    premiumAmountCurrencySymbol: '0',
    product:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]?.input_Product,
    insuredDetails:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.views?.Insurance_all?.properties?.InsuredDetails,
    insuredPremiums:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]
        ?.healthquote_InsuredPremiums,
    standardInHospitalClass:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]
        ?.input_StandardInHospitalClass,
    optimumInHospitalClass:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]
        ?.input_OptimumInHospitalClass,
    standardAmbulatoryCoverage:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]
        ?.input_StandardAmbulatoryCoverage,
    optimumAmbulatoryCoverage:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.HealthDetailsID?.queries?.insurance?.plans[index]
        ?.input_OptimumAmbulatoryCoverage,
  };
}

export const checkPhoneNumber = (phoneNumber: string) => {
  if (!isEmpty(phoneNumber) && phoneNumber.length > 3) {
    return validatePhoneNumber(phoneNumber);
  }

  return true;
};

export const checkEmail = (email: string) => {
  if (!isEmpty(email)) {
    return isValidEmail(email);
  }

  return true;
};

export const getApplicationStatus = (status: string) => {
  switch (status) {
    case 'NEW':
      return steps[0];
    case 'QUOTE_PREPARATION':
      return steps[1];
    case 'QUOTE_CONFIRMATION':
      return steps[2];
    case 'CLOSED':
      return steps[3];
    default:
      return steps[0];
  }
};

export const getApplicationProgress = (status: string) => {
  switch (status) {
    case 'NEW':
      return 25;
    case 'QUOTE_PREPARATION':
      return 50;
    case 'QUOTE_CONFIRMATION':
      return 57;
    case 'CLOSED':
      return 100;
    default:
      return 0;
  }
};

export const checkDate = (date: string) => {
  return isValidDate(date);
};

export const checkSelectedRelationOnChange = (
  previousInsuredRelationValue: string,
  insuredDetailsRows: Record<string, string>[],
  lovsPerRecord: Record<string, string>[],
  index = -1
) => {
  if (insuredDetailsRows && insuredDetailsRows.length > 1 && index > -1) {
    //remove selected value from lovs lists
    lovsPerRecord = reviewListOfInsuredRelations(
      insuredDetailsRows,
      lovsPerRecord
    );

    //add relation values for the lists after updating the value
    if (
      previousInsuredRelationValue !=
      insuredDetailsRows[index]['insuredRelation']
    ) {
      return addRelationValues(
        previousInsuredRelationValue,
        insuredDetailsRows,
        lovsPerRecord
      );
    }

    return lovsPerRecord;
  } else {
    //this part runs when the user add new insured detail row
    return reviewListOfInsuredRelations(insuredDetailsRows, lovsPerRecord);
  }
};

export const clearDataForPreviousSelection = (
  selectedProduct: Record<string, string | number>,
  selectValue: string
) => {
  const clearedEntity = { ...selectedProduct };

  clearedEntity.class = null;
  clearedEntity.ambulatory = null;

  return clearedEntity;
};

export const addRelationValues = (
  previousValue: string,
  insuredDetailsRows: Record<string, string>[],
  lovsPerRecord: Record<string, string>[]
) => {
  if (
    previousValue &&
    NO_DDUPLICATE_RELATEION_TYPES_KEYS.indexOf(previousValue) > -1
  ) {
    for (let i = 0; i < insuredDetailsRows.length; i++) {
      const clonedInsuredRelationType = cloneDeep(lovsPerRecord[i]);

      clonedInsuredRelationType[previousValue] = capitalizeFirstLetter(
        previousValue.replace(' ', '_')
      );

      lovsPerRecord = [...lovsPerRecord];
      lovsPerRecord[i] = clonedInsuredRelationType;
    }
  }

  return lovsPerRecord;
};

export const reviewListOfInsuredRelations = (
  insuredDetailsRows: Record<string, string>[],
  lovsPerRecord: Record<string, string>[],
  mainLovs?: Record<string, string>
) => {
  if (mainLovs) {
    lovsPerRecord = lovsPerRecord.fill(mainLovs, 0, insuredDetailsRows.length);
  }

  for (let i = 0; i < insuredDetailsRows.length; i++) {
    const relationValue = insuredDetailsRows[i]['insuredRelation'];

    if (NO_DDUPLICATE_RELATEION_TYPES_KEYS.indexOf(relationValue) > -1) {
      lovsPerRecord.filter((lovInsuredRelation, index) => {
        if (index != i) {
          const clonedInsuredRelationType = cloneDeep(lovInsuredRelation);
          delete clonedInsuredRelationType[relationValue];

          lovsPerRecord[index] = clonedInsuredRelationType;
        }
      });
    }
  }

  return lovsPerRecord;
};
