import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import React, { createRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, MenuItem, Button } from '@mui/material';
import { MAIN_ONE_THEME, mainFontFamilyMedium } from '../constants';
import { IApplication, IAppModule } from '../Shell';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import ChevronUpIcon from '@mui/icons-material/ExpandLess';

interface INavMenuProps {
  applications: Record<string, IApplication>;
  currentApp: string | undefined;
}

const useStyles = makeStyles()(() => ({
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'normal',
    height: '100%',
  },
  currentApp: {
    height: '50%',
    minHeight: 20,
    borderRight: `1px solid ${MAIN_ONE_THEME.palette.secondary4.lighter}`,
    paddingRight: 20,
    verticalAlign: 'middle',
    display: 'flex',
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    alignItems: 'center',
    lineHeight: 20,
    fontFamily: mainFontFamilyMedium,
    color: MAIN_ONE_THEME.palette.secondary4.light,
    minWidth: 'fit-content',
  },
  link: {
    color: 'white',
    opacity: 0.7,
    fontSize: MAIN_ONE_THEME.typography.regular.reg1.fontSize,
    lineHeight: '15px',
    textDecoration: 'none',
    minWidth: 70,
    padding: '0 20px 2px 20px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'max-content',
    fontFamily: mainFontFamilyMedium,
  },
  button: {
    color: 'white',
    opacity: 0.7,
    fontSize: MAIN_ONE_THEME.typography.regular.reg1.fontSize,
    lineHeight: '15px',
    textDecoration: 'none',
    minWidth: 70,
    padding: '0 20px 2px 20px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'max-content',
    fontFamily: mainFontFamilyMedium,
    textTransform: 'none',
  },
  activeButton: {
    backgroundColor: '#D63C55',
    opacity: 1,
  },
  activeLink: {
    opacity: 1,
    background: 'rgba(255, 255, 255, 0.19)',
    borderBottom: '2px solid white',
    padding: '0 20px',
    fontFamily: mainFontFamilyMedium,
    lineHeight: '15px',
  },
  dropdown: {
    position: 'relative',
  },
  dropdownMenu: {},
  dropdownItem: {
    padding: '16px 16px',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '48px',
    fontSize: '13px',
    fontFamily: 'HelveticaNeue-Medium',
    '&:hover': {
      backgroundColor: '#D9D9D9',
      fontFamily: 'HelveticaNeue-Bold',
    },
  },
  dropdownLink: {
    color: 'black',
    textDecoration: 'none',
    width: '100%',
    height: '48px',
    padding: '16px 16px',
  },
}));

const NavMenu: React.FC<INavMenuProps> = ({ currentApp, applications }) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [elRefs, setElRefs] = useState([]);
  const [open, setOpen] = useState<number>();

  useEffect(() => {
    setElRefs((elRefs) =>
      Object.keys(applications?.[currentApp]?.modules || {}).map(
        (_, i) => elRefs[i] || createRef()
      )
    );
  }, [applications?.[currentApp]?.modules]);

  const handleDropdownOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setOpen(index);
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const renderSubModules = (
    subModules: Record<string, IAppModule>,
    appRoute: string,
    moduleRoute: string
  ) =>
    Object.keys(subModules).map((subModuleName) => (
      <MenuItem
        key={subModuleName}
        onClick={handleDropdownClose}
        className={classes.dropdownItem}
      >
        <NavLink
          to={appRoute + moduleRoute + subModules[subModuleName].route}
          className={classes.dropdownLink}
        >
          {subModules[subModuleName].title}
        </NavLink>
      </MenuItem>
    ));

  return currentApp && applications[currentApp]?.modules ? (
    <>
      <span className={classes.currentApp}>
        {applications[currentApp].title}
      </span>
      <nav className={classes.menu}>
        {Object.keys(applications[currentApp].modules)
          .filter(
            (moduleName) => !applications[currentApp].modules[moduleName].hidden
          )
          .map((moduleName, index) => {
            const module = applications[currentApp].modules[moduleName];
            return module.subModules ? (
              <div key={moduleName} className={classes.dropdown}>
                <Button
                  ref={elRefs[index]}
                  className={clsx(
                    classes.button,
                    open === index && Boolean(anchorEl)
                      ? classes.activeButton
                      : undefined
                  )}
                  onClick={(e) => handleDropdownOpen(e, index)}
                  endIcon={
                    open === index && Boolean(anchorEl) ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )
                  }
                >
                  {module.title}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open === index && Boolean(anchorEl)}
                  onClose={handleDropdownClose}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '0px 0px 7px 7px',
                      boxShadow: '0px 3px 10px #00000040',
                    },
                    '& .MuiList-root': {
                      padding: 0,
                    },
                  }}
                  PaperProps={{
                    style: {
                      width:
                        elRefs[index] && elRefs[index].current
                          ? elRefs[index].current.clientWidth
                          : undefined,
                    },
                  }}
                >
                  {renderSubModules(
                    module.subModules,
                    applications[currentApp].route,
                    module.route
                  )}
                </Menu>
              </div>
            ) : (
              <NavLink
                className={({ isActive }) =>
                  clsx(classes.link, isActive ? classes.activeLink : undefined)
                }
                key={moduleName}
                to={applications[currentApp].route + module.route}
              >
                {module.title}
              </NavLink>
            );
          })}
      </nav>
    </>
  ) : (
    <></>
  );
};

export default NavMenu;
