/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import { IProposalPageFormState } from '../../../modules/production/proposal/page/medical/form';
import { IProposalDetailsSummary } from '../../../modules/production/proposal/page/expat/index2';
import EnhancedUploader from '../../../components/enhanced-form/EnhancedUploader';
import { useAppSelector } from '../../../redux/hooks';
import { formatDate } from '../../../utils/formatting-utils';
import {
  contentFontFamilyBold,
  contentFontFamilyRegular,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../../constants';
import { excelToJson } from '../../../utils/excel-reader';
import { getKeyFromValue } from '../../../utils/helper-utils';

interface IProposalMedicalDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  onFileUpload: (data: Record<string, any>[]) => Promise<void>;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  allowPlanCoverEdits: boolean;
  status: string;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    marginTop: '1em',
  },
  section: {
    padding: '23px 0 25px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(1, 100%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '20px',
    margin: '0 0 10px',
    fontFamily: contentFontFamilyBold,
  },
  list: {
    fontSize: '16px',
    lineHeight: '20px',
    margin: '0 0 10px',
    fontFamily: contentFontFamilyRegular,
  },
}));

const ProposalMedicalGroupDetailsWidget: React.FC<
  IProposalMedicalDetailsWidgetProps
> = ({ disabledForm, lovs, onFileUpload }) => {
  const { classes } = useStyles();
  const tenant = useAppSelector((a) => a.tenant);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const initialize = async () => {};

  useEffect(() => {
    initialize();
  }, []);

  const handleUploadFile = async (file: File) => {
    const insuredData = await mapExcelDataToProductionInputs(file, lovs);
    await onFileUpload(insuredData);
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Group Medical Details "
        hasTitleSpecificDesign={false}
        classes={{
          container: classes.section,
        }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <div>
            <p className={classes.paragraph}>
              The plan selected includes the below covers:
            </p>
            <ul>
              {Object.values(lovs.policyCovers).map((cover) => (
                <li className={classes.list} key={cover}>
                  {cover}
                </li>
              ))}
            </ul>
          </div>

          <EnhancedUploader
            type={''}
            value={''}
            onUpload={(file) => {
              // handleUploadFile(file);
              handleUploadFile(file);
            }}
            name={'listOfInsured'}
            title={'List of Insured'}
            placeholder="Upload a document"
            iconUrl={`${tenant.cdnUrl}/icons/upload-field-primary.svg`}
            allowedFileTypes={[]}
            material={true}
            disabled={disabledForm}
            downloadLinks={[
              {
                downloadUrl:
                  tenant.cdnUrl + '/files/Group List of Insured.xlsx',
                displayName: 'Download Template',
              },
            ]}
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalMedicalGroupDetailsWidget;

const mapExcelDataToProductionInputs = async (
  file: File,
  lovs: Record<string, Record<string, string>>
): Promise<any[]> => {
  const excelData = await excelToJson(file);

  // Assuming data is in the first sheet
  const sheetNames = Object.keys(excelData);
  if (sheetNames.length === 0) {
    throw new Error('Excel file has no sheets');
  }
  const firstSheetName = sheetNames[0];
  const rows = excelData[firstSheetName];

  // Map Excel headers to JSON fields
  const headerMapping: Record<string, string> = {
    'Reference*': 'Reference',
    'First Name*': 'FirstName',
    'Middle Name*': 'MiddleName',
    'Last Name*': 'LastName',
    'Date of Birth*': 'DateOfBirth',
    'Gender*': 'Gender',
    'Principal Reference*': 'PrincipalReference',
    'Relation*': 'Relation',
    'Class*': 'Class',
    'Co-NSSF*': 'Nssf',
    'Level*': 'Level',
    Address: 'AddressDetails',
    Phone: 'Phone',
    'Exclusion/Limitation': 'Exclusions',
    Remarks: 'Remarks',
    Continuity: 'Continuity',
    'TPA Reference1': 'TPAReference1',
    'TPA Reference2': 'TPAReference2',
  };

  const productionInputs: any[] = [];

  for (const row of rows) {
    const productionInput: Partial<any> = {};

    for (const excelHeader in headerMapping) {
      const jsonField = headerMapping[excelHeader];
      let value = row[excelHeader];

      if (jsonField === 'Gender') {
        value = (value as string)?.toUpperCase();
      }

      if (jsonField === 'Relation') {
        value = getKeyFromValue(lovs.insuredRelations, value);
      }

      if (jsonField === 'Class') {
        value = getKeyFromValue(lovs.classes, value);
      }

      if (jsonField === 'Nssf') {
        value = getKeyFromValue(lovs.cnssOptions, value);
      }

      if (jsonField === 'Level') {
        value = getKeyFromValue(lovs.levels, value);
      }

      if (jsonField === 'Continuity') {
        value = getKeyFromValue(lovs.continuity, value);
      }

      // Handle DateOfBirth field
      if (jsonField === 'DateOfBirth') {
        if (value instanceof Date) {
          // Value is already a Date object
        } else if (typeof value === 'string') {
          const dateValue = new Date(value);
          value = isNaN(dateValue.getTime())
            ? null
            : formatDate(dateValue, SEND_TO_BACKEND_DATE_FORMAT);
        } else {
          value = null;
        }
        value = '1990-04-04';
      }

      productionInput[jsonField] = value?.toString();
    }

    productionInputs.push(productionInput);
  }

  return productionInputs;
};
