import { gql } from '@apollo/client';

export function getListForm() {
  return gql`
    query getExpatPolicyDetailsLookups {
      Insurance_PhoneType: __type(name: "Insurance_PhoneType") {
        name
        enumValues {
          name
        }
      }
      Insurance_Genders: __type(name: "Insurance_Genders") {
        name
        enumValues {
          name
        }
      }
      Insurance_RenewalStatus: __type(name: "Insurance_RenewalStatus") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          countries {
            Id
            Title
          }
          titles {
            Id
            Title
          }
          nationalities {
            Id
            Title
          }
          occupations {
            Id
            Title
            TitleAr
          }
          insuredPlans {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function updateExpatPolicy() {
  return gql`
    mutation updateExpatPolicyDetails(
      $entityId: String!
      $applicationContactInputs: Insurance_CustomerInputs!
      $insuredInputs: Insurance_ExpatInsuredDetailsInputs!
      $effectiveDate: Date!
      $isRenewal: Insurance_RenewalStatus!
      $renewalNumber: String
    ) {
      insurance {
        entities {
          policy {
            insurance {
              updateExpatPolicy(
                entityId: $entityId
                applicationContactInputs: $applicationContactInputs
                insuredInputs: $insuredInputs
                effectiveDate: $effectiveDate
                isRenewal: $isRenewal
                renewalNumber: $renewalNumber
              )
            }
          }
        }
      }
    }
  `;
}
