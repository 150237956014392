import React, { useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import {
  MAIN_ONE_THEME,
  contentFontFamilyRegular,
  mainFontFamilyRegular,
} from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import EnhancedTextArea from './EnhancedTextArea';
import { IEnhancedFormInputBaseProps } from '.';

export interface IEnhancedInputProps extends IEnhancedFormInputBaseProps {
  type: string;
  classes?: { input?: string; wrapper?: string };
  value: string | number;
  loader?: boolean;
  pattern?: string;
  inline?: boolean;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  multiline?: boolean;
  includePasswordhint?: boolean;
  includePasswordVisibility?: boolean;
  customInputContainer?: string;
  rtlContent?: boolean;
  minValue?: number;
  maxValue?: number;
  autoComplete?: string;
  hidden?: boolean;
  includeCapsLockCheck?: boolean;
  isEditable?: boolean;
  hideHeader?: boolean;
  hideError?: boolean;
  hasPrefix?: boolean;
  prefixValue?: string;
}

const useStyles = makeStyles<{ rtlContent: boolean; isEditable: boolean }>()(
  (theme, { rtlContent, isEditable }) => ({
    input: {
      fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
      lineHeight: '15px',
      height: isEditable ? 28 : 35,
      width: isEditable ? '70px' : '100%',
      padding: '0 15px',
      margin: '0 auto',
      backgroundColor: isEditable
        ? 'white'
        : MAIN_ONE_THEME.palette.secondary4.main,
      border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
      boxSizing: 'border-box',
      borderRadius: '5px',
      '&:disabled': {
        cursor: 'not-allowed',
        color: 'rgba(0, 0, 0, 0.5)!important',
        opacity: `0.5 !important`,
        textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
      },
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.20)',
        fontStyle: 'italic',
      },
      fontFamily: contentFontFamilyRegular,
      '&:focus': {
        outline: 'none',
      },
      '::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    loader: {
      position: 'absolute',
      top: 'calc(50% - 10px)',
      right: !rtlContent ? '3%' : 'initial',
      left: rtlContent ? '3%' : 'initial',
      opacity: 1,
      height: '25px',
      width: '25px',
    },
    materialInput: {
      backgroundColor: 'unset',
      border: 'none',
      borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
      color: MAIN_ONE_THEME.palette.primary2.main,
      margin: '0 auto',
      borderRadius: 0,
      padding: '10px 15px 4px 4px',
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
    },
    specificMaterial: {
      backgroundColor: 'unset',
      border: 'none',
      color: MAIN_ONE_THEME.palette.primary2.main,
      margin: '0 auto',
      borderRadius: 0,
      padding: '10px 15px 4px 4px',
      marginLeft: 'auto',
    },
    inputError: {
      borderColor: MAIN_ONE_THEME.palette.error.main,
      outlineColor: MAIN_ONE_THEME.palette.error.main,
    },
    passwordIcons: {
      color: MAIN_ONE_THEME.palette.primary1.main,
      marginLeft: '15px',
    },
    iconContainer: {
      position: 'absolute',
      top: '50%',
      right: 10,
      transform: 'translateY(-50%)',
      display: 'flex',
      alignItems: 'center',
    },
    inputContainer: {
      margin: 0,
      padding: 0,
      position: 'relative',
      flexGrow: 1,
    },
    tooltip: {
      backgroundColor: '#F7F7F7!important',
      color: 'rgba(0, 0, 0, 0.87)!important',
      maxWidth: '220px!important',
      fontSize: '12px!important',
      border: '1px solid #E1E1E1!important',
    },
    disabledInput: {
      '& .MuiInputBase-root.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.5)!important',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)!important',
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.5)!important',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)!important',
      },
    },
    eyeIcon: {
      color: 'black',
      '& .MuiSvgIcon-root': {
        width: '0.7em',
        height: '0.7em',
      },
    },
    capsLockWarning: {
      color: '#D30D2B',
      fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
      fontFamily: mainFontFamilyRegular,
    },
    prefixContainer: {
      border: '1px solid #E5E5E5',
      borderRadius: '5px',
      padding: '9px 9px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '60px',
      margin: '0 10px 0 0',
    },
    prefixText: {
      fontSize: '13px',
      lineHeight: '16px',
      color: '#231F20',
      fontFamily: 'SourceSansPro-Regular',
      height: '16px',
    },
    disabledPrefixContainer: {
      backgroundColor: '#f9f9f9',
      cursor: 'not-allowed',
    },
    disabledPrefixText: {
      color: 'rgba(0, 0, 0, 0.5)',
      opacity: '0.5',
    },
    errorPrefixContainer: {
      border: `1px solid ${MAIN_ONE_THEME.palette.error.main}`,
    },
  })
);

const EnhancedInput: React.FC<IEnhancedInputProps> = ({
  classes = {},
  className,
  error,
  name,
  style,
  title,
  description,
  value,
  placeholder,
  type,
  disabled,
  material,
  specificMaterial,
  onChange,
  onFocus,
  onBlur,
  loader,
  pattern,
  inline,
  onKeyPress,
  multiline = false,
  includePasswordhint = false,
  includePasswordVisibility = false,
  customInputContainer,
  rtlContent,
  minValue,
  maxValue,
  autoComplete = 'off',
  customStyles,
  hidden = false,
  includeCapsLockCheck = false,
  isEditable = false,
  hideHeader = false,
  hideError = false,
  hasPrefix = false,
  prefixValue = '',
}) => {
  const { classes: inputClasses } = useStyles({ rtlContent, isEditable });

  const [showPassword, setShowPassword] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === 'number' && event.key === 'e') {
      event.preventDefault();
    }
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const renderPasswordTooltipBody = () => (
    <div>
      <div>A minimum of 8 characters</div>
      <div>At least 1 upper case</div>
      <div>At least 1 lower case</div>
      <div>At least 1 number</div>
      <div>At least 1 symbol {`(@#$%&+=_)`}</div>
    </div>
  );

  const renderInput = () =>
    multiline ? (
      <EnhancedTextArea
        id={name}
        type={type}
        name={name}
        title={disabled && !isEmpty(value) ? value.toString() : title}
        value={value}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
        onFocus={(e) => onFocus(e)}
        disabled={disabled || loader}
        placeholder={placeholder}
        pattern={pattern}
        onKeyDownCapture={(e) => onKeyPress(e)}
        error={error}
        hidden={hidden}
      />
    ) : (
      <div
        className={
          customInputContainer
            ? customInputContainer
            : inputClasses.inputContainer
        }
      >
        <input
          className={clsx(inputClasses.input, classes.input, {
            [inputClasses.materialInput]: material,
            [inputClasses.inputError]: error,
            [inputClasses.specificMaterial]: specificMaterial,
            [inputClasses.disabledInput]: disabled,
          })}
          title={disabled && !isEmpty(value) ? value.toString() : title}
          id={name}
          name={name}
          aria-invalid={!!error}
          aria-describedby={`errorMsg-${name}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type={showPassword ? 'text' : type}
          disabled={disabled}
          placeholder={placeholder}
          pattern={pattern}
          hidden={hidden}
          onWheel={(e) => (e.target as any).blur()}
          onKeyDownCapture={(e) => handleKeyPress(e)}
          autoComplete={autoComplete}
          {...(type === 'number' ? { min: minValue, max: maxValue } : {})}
        />
        {type === 'password' && (
          <div className={inputClasses.iconContainer}>
            {includePasswordVisibility && (
              <IconButton
                className={inputClasses.eyeIcon}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            )}
            {includePasswordhint && (
              <Tooltip
                title={renderPasswordTooltipBody()}
                classes={{ tooltip: inputClasses.tooltip }}
              >
                <HelpIcon
                  className={inputClasses.passwordIcons}
                  width={20}
                  height={20}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );

  return inline || multiline ? (
    renderInput()
  ) : (
    <>
      <EnhancedInputsWrapper
        title={title}
        description={description}
        error={error}
        name={name}
        className={clsx(classes.wrapper, className)}
        style={style}
        customStyles={customStyles}
        hidden={hidden}
        includeCapsLockCheck={includeCapsLockCheck && isCapsLockOn}
        hideError={hideError}
        hideHeader={hideHeader}
      >
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {hasPrefix && (
              <div
                className={clsx(
                  inputClasses.prefixContainer,
                  disabled && inputClasses.disabledPrefixContainer,
                  !isEmpty(error) && inputClasses.errorPrefixContainer
                )}
              >
                <span
                  className={clsx(
                    inputClasses.prefixText,
                    disabled && inputClasses.disabledPrefixText
                  )}
                >
                  {prefixValue}
                </span>
              </div>
            )}
            {renderInput()}
          </div>
          {loader && (
            <CircularProgress
              className={inputClasses.loader}
              size={20}
              thickness={3}
            />
          )}
        </div>
      </EnhancedInputsWrapper>
    </>
  );
};

export default EnhancedInput;
