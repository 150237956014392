import { gql } from '@apollo/client';

export function getAgentLookups() {
  return gql`
    query getAgentEnums {
      Insurance_AgentTypes: __type(name: "Insurance_AgentTypes") {
        name
        enumValues {
          name
        }
      }
      Insurance_AgentStatuses: __type(name: "Insurance_AgentStatuses") {
        name
        enumValues {
          name
        }
      }
      Insurance_AgentAccesses: __type(name: "Insurance_AgentAccesses") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        queries {
          allAgencies {
            insurance_Agency_Id
            insurance_Agency_AgencyName
            insurance_Agency_AgencyType {
              Id
            }
          }
        }
        lookups {
          linesOfBusiness {
            Code
            Title
          }
        }
      }
    }
  `;
}

export function getAgentProcuctsLookups() {
  return gql`
    query getAgentProducts($lineOfBusiness: String!) {
      Insurance {
        lookups {
          products(Insurance_LinesOfBusiness: $lineOfBusiness) {
            Code
            Title
          }
        }
      }
    }
  `;
}

export function getAgentPlansLookups() {
  return gql`
    query getAgentPlansLookups($product: String!) {
      Insurance {
        lookups {
          eligiblePlans(Insurance_Products: $product) {
            Code
            Title
          }
        }
      }
    }
  `;
}

export function createAgent() {
  return gql(/* GraphQL */ `
    mutation createAgent(
      $agencyId: [String!]!
      $firstName: String!
      $lastName: String!
      $email: String!
      $mobileNumber: String!
      $agentType: Insurance_AgentTypes!
      $access: [Insurance_AgentAccesses!]!
      $agentStatus: Insurance_AgentStatuses!
      $linesOfBusiness: [String!]!
      $products: [String!]!
      $eligiblePlans: [String!]!
      $agentICCNumber: String
      $agentRegistrationNumber: String
      $agentRegistrationExpiryDate: Date
      $agentAddress: String!
      $password: String
      $ufaCode: String!
      $canViewCommission: Boolean!
    ) {
      insurance {
        actions {
          createAgent(
            agencyId: $agencyId
            firstName: $firstName
            lastName: $lastName
            email: $email
            mobileNumber: $mobileNumber
            agentType: $agentType
            access: $access
            agentStatus: $agentStatus
            linesOfBusiness: $linesOfBusiness
            products: $products
            eligiblePlans: $eligiblePlans
            agentICCNumber: $agentICCNumber
            agentRegistrationNumber: $agentRegistrationNumber
            agentRegistrationExpiryDate: $agentRegistrationExpiryDate
            agentAddress: $agentAddress
            password: $password
            ufaCode: $ufaCode
            canViewCommission: $canViewCommission
          ) {
            views {
              Insurance_all {
                properties {
                  FirstName
                  LastName
                  Email
                  MobileNumber
                  AgentType
                  AgentAccess
                  AgentStatus
                  AgentAddress
                  UFACode
                  CanViewCommission
                }
              }
            }
          }
        }
      }
    }
  `);
}

export function getAgentInfo() {
  return gql`
    query getAgentInfo($id: String!) {
      Insurance {
        entities {
          agent(id: $id) {
            views {
              Insurance_all {
                properties {
                  AgencyID {
                    id
                    views {
                      Insurance_all {
                        properties {
                          Id
                          AgencyName
                          AgencyStatus
                          AgencyType {
                            Id
                          }
                        }
                      }
                    }
                  }
                  FirstName
                  LastName
                  Email
                  MobileNumber
                  AgentType
                  AgentAccess
                  AgentStatus
                  AgentICCNumber
                  AgentRegistrationNumber
                  AgentRegistrationExpiryDate
                  AgentAddress
                  UFACode
                  CanViewCommission
                  LinesOfBusiness {
                    Code
                  }
                  Products {
                    Code
                  }
                  EligiblePlans {
                    Code
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updateAgent() {
  return gql`
    mutation updateAgent(
      $entityId: String!
      $agencyId: [String!]!
      $firstName: String!
      $lastName: String!
      $mobileNumber: String!
      $agentType: Insurance_AgentTypes!
      $access: [Insurance_AgentAccesses!]!
      $agentStatus: Insurance_AgentStatuses!
      $linesOfBusiness: [String!]!
      $products: [String!]!
      $eligiblePlans: [String!]!
      $agentICCNumber: String
      $agentRegistrationNumber: String
      $agentRegistrationExpiryDate: Date
      $agentAddress: String!
      $ufaCode: String!
      $canViewCommission: Boolean!
    ) {
      insurance {
        entities {
          agent {
            insurance {
              updateAgent(
                agencyId: $agencyId
                firstName: $firstName
                lastName: $lastName
                mobileNumber: $mobileNumber
                agentType: $agentType
                access: $access
                agentStatus: $agentStatus
                entityId: $entityId
                linesOfBusiness: $linesOfBusiness
                products: $products
                eligiblePlans: $eligiblePlans
                agentICCNumber: $agentICCNumber
                agentRegistrationNumber: $agentRegistrationNumber
                agentRegistrationExpiryDate: $agentRegistrationExpiryDate
                agentAddress: $agentAddress
                ufaCode: $ufaCode
                canViewCommission: $canViewCommission
              ) {
                views {
                  Insurance_all {
                    properties {
                      FirstName
                      LastName
                      Email
                      MobileNumber
                      AgentType
                      AgentAccess
                      AgentStatus
                      UFACode
                      CanViewCommission
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
