import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import _ from 'lodash';
import NewChipsInput from '../../../../../../components/enhanced-form/NewChipsInput';
import EnhancedInput from '../../../../../../components/enhanced-form/EnhancedInput';
import EnhancedCurrencyInput from '../../../../../../components/enhanced-form/EnhancedCurrencyInput';
import {
  IAddVoucherLOVs,
  ITransactionTableData,
  IVoucherPopupValues,
} from './types';
import {
  formattedCurrency,
  KeyOf,
  updateFormField,
  ValueOf,
} from '../../../../../../utils/helper-utils';
import {
  contentFontFamilyBold,
  MAIN_ONE_THEME,
} from '../../../../../../constants';
import Separator from '../../../../../../components/common/Separator';

const useStyles = makeStyles()(() => ({
  container: {
    boxShadow: '0px 0px 10px #DFE3EBC9',
    border: '1px solid #E8E8E8',
    marginBottom: '21px',
    marginTop: '38px',
  },
  root: {
    padding: '5px 20px',
    minHeight: '20px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '4.0fr 3.0fr 3.0fr 3.0fr 0.5fr',
    gridColumnGap: '18px',
    paddingLeft: '39px',
    paddingRight: '39px',
  },
  gridItem: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    gridColumnGap: '18px',
    height: '56px',
  },
  titlesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '4.5px',
    paddingBottom: '4.5px',
    lineHeight: '21px',
  },
  titles: {
    paddingTop: '15px',
    paddingBottom: '13px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstTitles: {
    display: 'flex',
    justifyContent: 'start',
  },
  span: {
    fontSize: '15px',
    fontFamily: 'SourceSansPro-bold',
  },
  accountCurrencyTitle: {
    backgroundColor: '#FCF5F5',
    color: '#C00020',
  },
  accountCurrencyTitles: {
    color: '#C00020',
  },
  systemCurrencyBaseTitle: {
    backgroundColor: '#F4F8EF',
    color: '#525A49',
  },
  systemCurrencyBaseTitles: {
    color: '#525A49',
  },
  systemCurrencySecondaryTitle: {
    backgroundColor: '#ECF7F5',
    color: '#586E6A',
  },
  systemCurrencySecondaryTitles: {
    color: '#586E6A',
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg4.fontSize,
    color: MAIN_ONE_THEME.palette.primary1.main,
    alignSelf: 'center',
    width: '25px',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '21px auto 25px',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingTop: '5px',
    display: 'block',
  },
  containerStyles: {
    paddingTop: 0,
    minHeight: '32px',
  },
  evenRow: {
    backgroundColor: '#F9F9F9',
  },
  oddRow: {},
  column: {
    paddingTop: 0,
    '&.MuiGrid-item': {
      paddingTop: '0 !important',
    },
  },
  field: {
    '& span:first-of-type': {
      minHeight: 0,
    },
    '& input': {
      height: '32px',
    },
  },
  labelSelector: {
    minHeight: 0,
  },
  inputSelector: {
    height: '32px',
  },
}));

interface IAccountCurrencyProps {
  title?: string;
  lovs: IAddVoucherLOVs;
  values: IVoucherPopupValues;
  setValues: (state: IVoucherPopupValues) => void;
  systemCurrency: string;
  systemSecondaryCurrency: string;
  disabled?: boolean;
}

const AccountCurrency: React.FC<IAccountCurrencyProps> = ({
  title,
  lovs,
  values,
  setValues,
  systemCurrency,
  systemSecondaryCurrency,
}) => {
  const { classes } = useStyles();

  const onFieldUpdate = (
    fieldName: KeyOf<ITransactionTableData>,
    value: ValueOf<ITransactionTableData>,
    rowIndex: number
  ) => {
    const newValues = _.cloneDeep(values);
    newValues.transactions[rowIndex] = updateFormField(
      newValues.transactions[rowIndex],
      fieldName,
      value
    );
    setValues(newValues);
  };

  const renderRow = (row: ITransactionTableData, index: number) => {
    const rowIndex = values.transactions.indexOf(row);
    const accountName = row.accountNameDisabled
      ? row.accountId + ' ' + row.accountName
      : row?.accountName;
    const accountDebit = formattedCurrency(row?.accountDebit);
    const accountCredit = formattedCurrency(row?.accountCredit);

    return (
      <div
        className={clsx(
          'container',
          classes.grid,
          rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow
        )}
        key={index}
      >
        <div className={clsx('item', classes.gridItem)}>
          <NewChipsInput
            key="accountName"
            name="accountName"
            value={accountName}
            onBlur={() => {}}
            onChange={(v: string) => {
              onFieldUpdate('accountId', v, rowIndex);
            }}
            customStyles={{
              containerStyles: classes.containerStyles,
              labelStyles: classes.labelSelector,
              inputStyles: classes.inputSelector,
            }}
            disabled={row.accountNameDisabled}
            items={lovs.accounts[rowIndex]}
            required={true}
          />
          <EnhancedInput
            type="text"
            key="description"
            name="description"
            title=""
            value={row?.description || ''}
            onBlur={() => {}}
            onChange={(v) =>
              onFieldUpdate('description', v.target.value, rowIndex)
            }
            disabled={row.descriptionDisabled}
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <EnhancedCurrencyInput
            type="number"
            key="accountDebit"
            name="accountDebit"
            title=""
            value={accountDebit}
            onBlur={() => {}}
            onChange={() => {}}
            customStyles={{ inputStyles: classes.containerStyles }}
            disabled
            className={classes.field}
            maxDecimalPercision={3}
            useCurrencyText
            currencyText={
              lovs.currenciesSymbols[row.transactionCurrency] ||
              values.systemCurrencies.primary.Symbol
            }
            currencyTextColor="#C00020"
          />
          <EnhancedCurrencyInput
            type="number"
            key="accountCredit"
            name="accountCredit"
            title=""
            value={accountCredit}
            onBlur={() => {}}
            onChange={() => {}}
            customStyles={{ inputStyles: classes.containerStyles }}
            disabled
            className={classes.field}
            maxDecimalPercision={3}
            useCurrencyText
            currencyText={
              lovs.currenciesSymbols[row.transactionCurrency] ||
              values.systemCurrencies.primary.Symbol
            }
            currencyTextColor="#C00020"
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <EnhancedCurrencyInput
            type="number"
            key="systemDebit"
            name="systemDebit"
            title=""
            value={formattedCurrency(row?.systemDebit)}
            onBlur={() => {}}
            onChange={() => {}}
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
            disabled
            useCurrencyText
            currencyText={systemCurrency}
          />
          <EnhancedCurrencyInput
            type="number"
            key="systemCredit"
            name="systemCredit"
            title=""
            value={formattedCurrency(row?.systemCredit)}
            onBlur={() => {}}
            onChange={() => {}}
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
            disabled
            useCurrencyText
            currencyText={systemCurrency}
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <EnhancedCurrencyInput
            type="number"
            key="systemSecondaryDebit"
            name="systemSecondaryDebit"
            title=""
            value={formattedCurrency(row?.systemSecondaryDebit)}
            onBlur={() => {}}
            onChange={() => {}}
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
            disabled
            useCurrencyText
            currencyText={systemSecondaryCurrency}
          />
          <EnhancedCurrencyInput
            type="number"
            key="systemSecondaryCredit"
            name="systemSecondaryCredit"
            title=""
            value={formattedCurrency(row?.systemSecondaryCredit)}
            onBlur={() => {}}
            onChange={() => {}}
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
            disabled
            useCurrencyText
            currencyText={systemSecondaryCurrency}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        {title && <div className={classes.title}>{title}</div>}
      </div>
      <Separator style={{ height: 1, marginTop: 2 }} />
      <div className={clsx('container', classes.grid)}>
        <div className="item"></div>
        <div className="item">
          <div
            className={clsx(
              classes.titlesContainer,
              classes.accountCurrencyTitle
            )}
          >
            <span className={classes.span}>Account Currency</span>
          </div>
        </div>
        <div className="item">
          <div
            className={clsx(
              classes.titlesContainer,
              classes.systemCurrencyBaseTitle
            )}
          >
            <span className={classes.span}>System Currency (Basic)</span>
          </div>
        </div>
        <div className="item">
          <div
            className={clsx(
              classes.titlesContainer,
              classes.systemCurrencySecondaryTitle
            )}
          >
            <span className={classes.span}>System Currency (Secondary)</span>
          </div>
        </div>
        <div className="item"></div>
      </div>
      <div className={clsx('container', classes.grid)}>
        <div className={clsx('item', classes.gridItem)}>
          <div className={clsx(classes.titles, classes.firstTitles)}>
            <span className={classes.span}>Account*</span>
          </div>
          <div className={clsx(classes.titles, classes.firstTitles)}>
            <span className={classes.span}>Description*</span>
          </div>
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <div className={clsx(classes.titles, classes.accountCurrencyTitles)}>
            <span className={classes.span}>Debit</span>
          </div>
          <div className={clsx(classes.titles, classes.accountCurrencyTitles)}>
            <span className={classes.span}>Credit</span>
          </div>
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <div
            className={clsx(classes.titles, classes.systemCurrencyBaseTitles)}
          >
            <span className={classes.span}>Debit</span>
          </div>
          <div
            className={clsx(classes.titles, classes.systemCurrencyBaseTitles)}
          >
            <span className={classes.span}>Credit</span>
          </div>
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <div
            className={clsx(
              classes.titles,
              classes.systemCurrencySecondaryTitles
            )}
          >
            <span className={classes.span}>Debit</span>
          </div>
          <div
            className={clsx(
              classes.titles,
              classes.systemCurrencySecondaryTitles
            )}
          >
            <span className={classes.span}>Credit</span>
          </div>
        </div>
        <div className="item"></div>
      </div>
      {values.transactions.map((v, index) => renderRow(v, index))}
    </div>
  );
};

export default AccountCurrency;
