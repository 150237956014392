import React, { useState } from 'react';
import EnhancedDatePicker from '../../../../../../../components/enhanced-form/EnhancedDatePicker';
import EnhancedChipInput from '../../../../../../../components/enhanced-form/EnhancedChipInput';
import EnhancedInput from '../../../../../../../components/enhanced-form/EnhancedInput';
import EnhancedCurrencyInput from '../../../../../../../components/enhanced-form/EnhancedCurrencyInput';
import EnhancedButton from '../../../../../../../components/EnhancedButton';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../../../components/ToastErrorMessage';
import { IListingData } from '../../../../../../../models/listing';
import { cloneDeep, isEmpty } from 'lodash';
import {
  mapToAccoutsLov,
  mapToPopupListingData,
  mapToTransactionListingData,
} from '../utils';
import { formatDate } from '../../../../../../../utils/formatting-utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  generatePVTransactionsMutation,
  getPaymentPayablesMutation,
} from '../queries';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../../../../../../constants';
import { PVPopupHeaders } from '../content';
import {
  IAddVoucherLOVs,
  ITransactionTableData,
  IVoucherPopupValues,
} from '../../shared/types';
import { getAccountsByCompanyAndCurrencyQuery } from '../../shared/queries';
import PaymentWidgetTable from '../../../../../../../forms/payment-terms/PaymentWidgetTable';
import { IEnhancedRow } from '../../../../../../../forms/payment-terms/PaymentWidgetTableBody';

interface IPVPopupFormSection {
  isEdit: boolean;
  lovs: IAddVoucherLOVs;
  setLovs: (state: IAddVoucherLOVs) => void;
  values: IVoucherPopupValues;
  setValues: (state: IVoucherPopupValues) => void;
  errors: Record<string, string>;
  setErrors: (state: Record<string, string>) => void;
  tableData: IListingData<any>;
  setTableData: (state: IListingData<any>) => void;
  setIsSubmitDisabled: (state: boolean) => void;
  businessPartnerId: string;
  classes: Record<string, string>;
  disabled: boolean;
}

const PVPopupFormSection: React.FC<IPVPopupFormSection> = ({
  isEdit,
  lovs,
  setLovs,
  values,
  setValues,
  errors,
  setErrors,
  tableData,
  setTableData,
  setIsSubmitDisabled,
  businessPartnerId,
  classes,
  disabled,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [getPaymentPayables] = useMutation(getPaymentPayablesMutation());
  const [generatePVTransactions] = useMutation(
    generatePVTransactionsMutation()
  );
  const [getAccountsByCompanyAndCurrency] = useLazyQuery(
    getAccountsByCompanyAndCurrencyQuery()
  );

  const getPaymentPayablesOfBrokers = async (
    pageValues: Record<string, any>
  ) => {
    try {
      if (isEdit) {
        return;
      }
      setLoading(true);

      const res = await getPaymentPayables({
        variables: {
          selectedCurrency: pageValues.currency ? pageValues.currency : null,
          selectedPaymentDate: pageValues.dateOfPayment
            ? formatDate(pageValues.dateOfPayment, SEND_TO_BACKEND_DATE_FORMAT)
            : null,
          currentBusinessPartnerID: businessPartnerId,
        },
        errorPolicy: 'all',
      });

      if (res.errors && res.errors.length > 0) {
        if (res.errors[0]?.extensions?.code === 'CurrencyExchangeRateNull') {
          toast.error(
            <ToastErrorMessage>{res.errors[0].message}</ToastErrorMessage>
          );
          setTableData({
            pagedItems: [],
            pageNumber: 0,
            pageSize: 10,
            totalCount: 0,
          });
        }
        return;
      }

      const data = mapToPopupListingData(
        res.data.accounting.actions.getPaymentPayables.PaymentBill,
        lovs.currenciesSymbols
      );

      setTableData({
        pagedItems: data,
        pageNumber: 0,
        pageSize: 10,
        totalCount: 0,
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    setLoading(false);
  };

  const validateTableAmountPaid = (rowIndex?: number) => {
    const newTableData = cloneDeep(tableData);

    let error: string | null = null;

    if (!isNaN(rowIndex)) {
      const row = newTableData.pagedItems[rowIndex];

      // Row-level validation: Check if the amount exceeds the outstanding amount
      if (row.amount > row.amountOutstandingCurrency) {
        error = 'Amount Paid cannot be greater than Amount Outstanding';
      }
    }

    // If no row-level error, calculate the total unallocated amount
    if (!error) {
      const sumAmountPaid = Object.keys(tableData.pagedItems).reduce(
        (total: number, key: any) => {
          return total + Number(tableData.pagedItems[key].amount);
        },
        0
      );

      const totalAmountUnAllocated = values.totalAmount - sumAmountPaid;

      // Total validation: Check if the total paid exceeds the total amount
      if (totalAmountUnAllocated < 0) {
        error =
          'Sum of all Amount Paid cannot be greater than Total Amount Paid';
      }

      if (!error) {
        setValues({
          ...values,
          totalAmountUnAllocated,
        });
      }
    }

    setErrors({
      ...errors,
      tableAmountPaid: error,
    });

    setTableData(newTableData);
  };

  const handleTableValueChange = (
    index: number,
    columnName: string,
    value: any
  ) => {
    const newTableData = cloneDeep(tableData);
    newTableData.pagedItems[index][columnName] = value;
    setTableData(newTableData);
    setIsSubmitDisabled(true);
  };

  const handleChange = (inputName: string, value: any) => {
    const newValues = cloneDeep(values);
    (newValues as any)[inputName] = value;

    if (inputName === 'dateOfPayment') {
      getPaymentPayablesOfBrokers(newValues);
    }
    setValues(newValues);
    setIsSubmitDisabled(true);
  };

  const handleAccountsUpdate = async (
    transactions: ITransactionTableData[]
  ) => {
    const newLovs = cloneDeep(lovs);

    for (let index = 0; index < transactions.length; index++) {
      const transaction = transactions[index];

      if (transaction.accountName === '--' || !transaction.accountName) {
        const res = await getAccountsByCompanyAndCurrency({
          variables: {
            selectedCompanyID: values.companyId,
            accountCurrency:
              transaction.transactionCurrency ||
              values.systemCurrencies.primary.Code,
            pageNumber: 1,
            pageSize: 99999,
          },
        });

        newLovs.accounts[index] = mapToAccoutsLov(
          res.data.Accounting.queries.GetAccountsByCompanyandCurrency.items
        );
      }
    }

    setLovs(newLovs);
  };

  const handleSubmit = async () => {
    try {
      const res = await generatePVTransactions({
        variables: {
          paymentCurrency: values.currency,
          paymentDate: formatDate(
            values.dateOfPayment,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          totalAmount: Number(values.totalAmount),
          pVList: Object.keys(tableData.pagedItems).map((key: string) => {
            return {
              PolicyID: tableData.pagedItems[key].policyId,
              PolicyNumber: tableData.pagedItems[key].policyNum,
              BillID: tableData.pagedItems[key].billId,
              BillNumber: tableData.pagedItems[key].billNum,
              DueDate: formatDate(
                tableData.pagedItems[key].dueDate,
                SEND_TO_BACKEND_DATE_FORMAT
              ),
              CommissionDue: Number(tableData.pagedItems[key].amountDue),
              AmountOutstanding: Number(
                tableData.pagedItems[key].amountOutstandingCurrency
              ),
              AmountOutstandingCurrency: Number(
                tableData.pagedItems[key].amountOutstandingCurrency
              ),
              AmountPaid: Number(tableData.pagedItems[key].amount),
            };
          }),
          currentBusinessPartnerID: businessPartnerId,
        },
      });

      const { totalDebit, totalCredit, transactions } =
        mapToTransactionListingData(
          res.data.accounting.actions.generatePVTransactions
            .PaymentTransactionList
        );

      handleAccountsUpdate(transactions);

      setValues({
        ...values,
        totalDebit,
        totalCredit,
        transactions,
      });

      setIsSubmitDisabled(false);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const dynamicRowMessage = (row: IEnhancedRow): string[] => {
    if (row && row.columns) {
      if (errors.tableAmountPaid) {
        return [errors.tableAmountPaid];
      }
    }
    return [];
  };

  const validateForm = () => {
    // Check if any of the fields are empty or invalid
    // If so, return true to disable the submit button
    const invalid =
      !values.dateOfPayment ||
      !values.currency ||
      !values.totalAmount ||
      Number(values.totalAmount) === 0 ||
      Number(values.totalAmountUnAllocated) !== 0 ||
      !isEmpty(errors.tableAmountPaid);

    return invalid;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className={classes.inputsRow}>
        <EnhancedDatePicker
          name="dateOfPayment"
          title="Date Payment was Paid*"
          value={values.dateOfPayment}
          onDateChange={(v) => {
            handleChange('dateOfPayment', v);
          }}
          onBlur={() => {
            getPaymentPayablesOfBrokers(values);
          }}
          error={errors.dateOfPayment}
          disabled={disabled}
        />
        <EnhancedChipInput
          name="currency"
          title="Currency*"
          selectOptions={lovs.currenciesSymbols}
          onChange={(v) => {
            handleChange('currency', v);
          }}
          onBlur={() => {
            getPaymentPayablesOfBrokers(values);
          }}
          value={values.currency}
          multiple={false}
          error={errors.currency}
          required
          disabled={disabled}
        />
        <EnhancedChipInput
          name="paymentMethod"
          title="Payment Method*"
          selectOptions={lovs.paymentMethods}
          onChange={(v) => {
            handleChange('paymentMethod', v);
          }}
          value={values.paymentMethod}
          multiple={false}
          error={errors.paymentMethod}
          required
          disabled={disabled}
        />
        <EnhancedInput
          name="referenceNum"
          title="Reference Number"
          type="text"
          value={values.referenceNum}
          onChange={(e) => {
            handleChange('referenceNum', e.target.value);
          }}
          onBlur={() => {}}
          error={errors.referenceNum}
          disabled={disabled}
        />
      </div>
      <div className={classes.inputsRow}>
        <EnhancedCurrencyInput
          name="totalAmount"
          title="Total Amount Paid*"
          value={values.totalAmount}
          onBlur={() => {
            validateTableAmountPaid();
          }}
          onChange={(e) => {
            handleChange('totalAmount', e.target.value);
          }}
          useCurrencyText
          currencyText={lovs.currenciesSymbols[values.currency]}
          required
          error={errors.totalAmount}
          disabled={disabled || !values.currency}
        />
        <EnhancedCurrencyInput
          name="totalAmountUnAllocated"
          title="Total Amount Unallocated*"
          value={values.totalAmountUnAllocated}
          onBlur={() => {}}
          onChange={() => {}}
          disabled
          required
          error={errors.totalAmountUnAllocated}
        />
      </div>
      <PaymentWidgetTable
        name="paymentVouchers"
        inlineTitle="Payment Vouchers"
        headers={PVPopupHeaders(
          values.currency,
          lovs.currenciesSymbols,
          errors,
          disabled
        )}
        data={tableData}
        handleCellValueChanged={(index, columnName, value) => {
          handleTableValueChange(index, columnName, value);
        }}
        handleUpdate={(index, columnName) => {
          if (columnName === 'amount') {
            validateTableAmountPaid(index);
          }
        }}
        disableSelection
        inline
        dynamicRowMessage={dynamicRowMessage}
        forceShowSelectColumn={true}
      />
      {!disabled && (
        <div className={classes.buttonRow}>
          <EnhancedButton disabled={validateForm()} type="submit">
            Generate Transactions
          </EnhancedButton>
        </div>
      )}
    </form>
  );
};

export default PVPopupFormSection;
