import React from 'react';
import { IInsuredDetails } from './index';
import { isEmpty } from 'lodash';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterLowerOthers,
} from '../../../../utils/formatting-utils';
import { IDynamicDiplayView } from '../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../components/widgets/dynamic-display/DynamicWidget';
import { EnhancedDisplayType } from '../../../../components/enhanced-form/EnhancedDisplay';

interface IInsuredDetailsWidgetProps {
  data: IInsuredDetails;
  lovs: any;
}

export const InsuredDetailsWidget: React.FC<IInsuredDetailsWidgetProps> = ({
  data,
  lovs,
}) => {
  const businessPartnerLovs: string[] = Object.keys(lovs.businessPartner);
  const dynamicView: IDynamicDiplayView = {
    name: 'InsuredDetails',
    sections: [
      {
        name: 'InsuredDetails',
        title: 'Insured Details',
        properties: {
          DateOfBirth: {
            multiline: false,
            value: data.DateOfBirth,
            name: 'DateOfBirth',
            title: 'Date Of Birth',
            tooltip: false,
            type: EnhancedDisplayType.Date,
          },
          PhoneType: {
            multiline: false,
            value: capitalizeFirstLetter(data.PhoneType),
            name: 'PhoneType',
            title: 'Phone Type',
            tooltip: false,
          },
          PhoneNumber: {
            multiline: false,
            value: data.PhoneNumber,
            name: 'PhoneNumber',
            title: 'Phone Number',
            tooltip: false,
            type: EnhancedDisplayType.IntPhoneNumber,
          },
          Nationality: {
            multiline: false,
            value: data.Nationality,
            name: 'Nationality',
            title: 'Nationality',
            tooltip: false,
          },
          Gender: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(data?.Gender || ''),
            name: 'Gender',
            title: 'Gender',
            tooltip: false,
          },
          PassportNumber: {
            multiline: false,
            value: data.PassportNumber,
            name: 'PassportNumber',
            title: 'Passport Number',
            tooltip: false,
          },
          OccupationClass: {
            multiline: false,
            value: data.OccupationClass,
            name: 'OccupationClass',
            title: 'Occupation Class',
            tooltip: false,
          },
          Profession: {
            multiline: false,
            value: data.Profession,
            name: 'Profession',
            title: 'Profession',
            tooltip: false,
          },
          AddressType: {
            multiline: false,
            value: capitalizeFirstLetter(data.AddressType),
            name: 'AddressType',
            title: 'Address Type',
            tooltip: false,
          },
          Address: {
            multiline: false,
            value: data.Address,
            name: 'Address',
            title: 'Address',
            tooltip: false,
          },
          Taxable: {
            multiline: false,
            value: data.Taxable,
            name: 'Taxable',
            title: 'Taxable',
            tooltip: false,
          },
          BusinessPartner: {
            multiline: false,
            value: !isEmpty(businessPartnerLovs)
              ? businessPartnerLovs.some(
                  (id) => id === data.AssignedToBusinessPartnerID?.id
                )
                ? `P: ${data.AssignedToBusinessPartnerID?.views?.SalesforceManagement_all?.properties?.Code} - ${data.AssignedToBusinessPartnerID?.views?.SalesforceManagement_all?.properties?.PersonID?.views?.SalesforceManagement_all?.properties?.FullName}`
                : businessPartnerLovs.some((id) =>
                      data.LinkedBusinessPartnerIDs?.some(
                        (linkedBP) =>
                          linkedBP?.views?.SalesforceManagement_all?.properties
                            ?.Id === id
                      )
                    )
                  ? `S: ${
                      data.LinkedBusinessPartnerIDs?.find((linkedBP) =>
                        businessPartnerLovs.includes(
                          linkedBP?.views?.SalesforceManagement_all?.properties
                            ?.Id
                        )
                      )?.views?.SalesforceManagement_all?.properties?.Code
                    } - ${
                      data.LinkedBusinessPartnerIDs?.find((linkedBP) =>
                        businessPartnerLovs.includes(
                          linkedBP?.views?.SalesforceManagement_all?.properties
                            ?.Id
                        )
                      )?.views?.SalesforceManagement_all?.properties?.PersonID
                        ?.views?.SalesforceManagement_all?.properties?.FullName
                    }`
                  : ''
              : '',
            name: 'BusinessPartner',
            title: 'Business Partner',
            tooltip: false,
          },
        },
      },
    ],
  };

  return <DynamicWidget hasFourFields={true} view={dynamicView} />;
};
