import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  salesforceManagement_BusinessPartner_Code: {
    name: 'salesforceManagement_BusinessPartner_Code',
    title: 'Code',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  person_FullName: {
    name: 'person_FullName',
    title: 'Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  salesforceManagement_BusinessPartner_RelatedCompany: {
    name: 'salesforceManagement_BusinessPartner_RelatedCompany',
    title: 'Related Company',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartner_Type: {
    name: 'salesforceManagement_BusinessPartner_Type',
    title: 'Type',
    type: EnhancedTableHeaderType.Text,
  },
  person_Email: {
    name: 'person_Email',
    title: 'Email',
    type: EnhancedTableHeaderType.Text,
  },
  person_PhoneDetails: {
    name: 'person_PhoneDetails',
    title: 'Phone Number',
    type: EnhancedTableHeaderType.PhoneNumber,
  },
  salesforceManagement_BusinessPartner_Status: {
    name: 'salesforceManagement_BusinessPartner_Status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartner_createdOn: {
    name: 'salesforceManagement_BusinessPartner_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  salesforceManagement_BusinessPartner_modifiedOn: {
    name: 'salesforceManagement_BusinessPartner_modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        type: {
          name: 'type',
          title: 'Type',
          type: FormInputTypes.chips,
          value: filterValues?.type || [],
          placeholder: 'Type',
          triggerUpdate: true,
          selectOptions: { ...lovs.type },
        },
        company: {
          name: 'company',
          title: 'Company',
          type: FormInputTypes.chips,
          value: filterValues?.company || [],
          placeholder: 'Company',
          triggerUpdate: true,
          selectOptions: { ...lovs.company },
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        isCompany: {
          name: 'isCompany',
          title: 'Is Company',
          type: FormInputTypes.chips,
          value: filterValues?.isCompany || [],
          placeholder: 'Is Company',
          triggerUpdate: true,
          selectOptions: { ...lovs.isCompany },
        },
        isLocal: {
          name: 'isLocal',
          title: 'Is Local',
          type: FormInputTypes.chips,
          value: filterValues?.isLocal || [],
          placeholder: 'Is Local',
          triggerUpdate: true,
          selectOptions: { ...lovs.isLocal },
        },
        isRegistered: {
          name: 'isRegistered',
          title: 'Is Registered',
          type: FormInputTypes.chips,
          value: filterValues?.isRegistered || [],
          placeholder: 'Is Registered',
          triggerUpdate: true,
          selectOptions: { ...lovs.isRegistered },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
