export function extractApplicationDrawerData(data: any) {
  const returnData: Record<string, Record<string, string>> = {};
  const agencies: Record<string, string> = {};

  data.Insurance.queries.ListAgenciesByAgent.forEach(
    (element: {
      insurance_Agency_Id: string;
      insurance_Agency_AgencyName: string;
    }) => {
      agencies[element.insurance_Agency_Id] =
        element.insurance_Agency_AgencyName;
    }
  );

  returnData['agencies'] = agencies;

  return returnData;
}
