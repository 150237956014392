import React from 'react';
import clsx from 'clsx';
import WidgetPaper from '../../common/WidgetPaper';
import WidgetSection from '../../common/WidgetSection';
import {
  capitalizeFirstLetter,
  valueCommaSeparated,
} from '../../../utils/formatting-utils';
import { MAIN_ONE_THEME, mainFontFamilyBold } from '../../../constants';
import { makeStyles } from 'tss-react/mui';
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';

export interface IRow {
  title: string;
  value: JSX.Element | string;
  show: boolean;
}
export interface ITab {
  firstRowIsHeader?: boolean;
  rows: Record<string | number, IRow[]>;
}

interface IQuotesCardWidgetProps {
  title: string;
  status: string;
  statusReason: string;
  actionTitle?: string;
  actionValue?: string;
  dateFormat: string;
  hasCoverage?: boolean;
  hasOnlyMainAmount?: boolean;
  coverageAmountCurrencySymbol?: string;
  coverageAmount?: string;
  premiumAmountCurrencySymbol?: string;
  premiumAmount?: string;
  selected?: boolean;
  isSelectable?: boolean;
  handleSelect?: (id: string) => void;
  footer?: JSX.Element;
  Id?: string;
  disabled?: boolean;
  rows?: Record<string, IRow>;
  tabs?: Record<string, ITab>;
  mainAmountTitle?: string;
  isMainAmountTitleSingleRow?: boolean;
  lineOfBusiness?: string;
}

const useStyles = makeStyles<{ selected: boolean }>()(
  (_theme, { selected }) => ({
    actions: {
      justifyContent: 'space-evenly',
      '& button': {
        margin: '5px',
      },
    },
    widgetContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      padding: '23px 0 0 0',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      overflow: 'visible',
    },
    sectionHeader: {
      alignItems: 'center',
      paddingLeft: '0',
      overflow: 'unset',
      minHeight: 'unset',
    },
    selectableSectionHeader: {
      paddingLeft: '4px',
    },
    headerTitle: {
      minHeight: '21px',
      maxWidth: '230px',
      fontSize: '18px',
      paddingLeft: '28px',
    },
    statusSection: {
      margin: '0',
    },
    subTitle: {
      color: '#A5A5A5',
      fontSize: '12px',
      lineHeight: '15px',
      textAlign: 'left',
    },
    checkbox: {
      position: 'absolute',
      marginLeft: '2px',
      width: '19px',
      height: '19px',
      cursor: 'pointer',
      backgroundColor: selected
        ? MAIN_ONE_THEME.palette.primary5.main
        : 'white',
      borderRadius: '3px',
      border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
      boxShadow: `inset 0 0 2px ${MAIN_ONE_THEME.palette.secondary2.main}`,
      '&:hover': {
        backgroundColor: MAIN_ONE_THEME.palette.secondary2.main,
      },
      '&:focus': {
        outline: 0,
      },
    },
    disabledCheckbox: {
      cursor: 'no-drop',
    },
    checkboxCheckIcon: {
      marginLeft: '6px',
      marginTop: '1px',
      width: '6px',
      height: '12px',
      border: `solid ${MAIN_ONE_THEME.palette.secondary4.main}`,
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(45deg)',
      visibility: selected ? 'visible' : 'hidden',
    },
    container: {
      display: 'flex',
      width: '100%',
      overflow: 'auto',
      justifyContent: 'flex-start',
    },
    quoteCard: {
      display: 'inline-block',
      width: '80%',
      maxWidth: '460px',
      margin: '10px 5px',
      position: 'relative',
      // height: '400px',
      minHeight: '225px',
    },
    detailContainer: {
      display: 'grid',
      justifyContent: 'normal',
      alignItems: 'center',
      alignContent: 'center',
      flexWrap: 'wrap',
      width: '100%',
      overflow: 'hidden',
    },
    mainAmountTitle: {
      color: MAIN_ONE_THEME.palette.primary5.main,
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'left',
      fontWeight: 'normal',
      margin: '0px 3px 10px 3px',
    },
    mainAmountValue: {
      color: MAIN_ONE_THEME.palette.primary5.main,
      fontSize: '20px',
      lineHeight: '20px',
      float: 'left',
      fontWeight: 'bold',
      // height: '100px',
    },
    status: {
      textAlign: 'center',
      height: '32px',
      color: 'white',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      placeItems: 'baseline',
      fontSize: '18px',
    },
    statusReason: {
      textAlign: 'right',
      height: '32px',
      color: 'white',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'right',
      placeItems: 'baseline',
      fontSize: '15px',
    },
    statusDot: {
      height: '13px',
      width: '13px',
      borderRadius: '50%',
      display: 'felx',
      margin: '0 5px 0 0',
    },
    premiumAmount: {
      color: MAIN_ONE_THEME.palette.error.main,
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: 'bold',
      float: 'right',
    },
    rowsWrapper: {
      margin: '15px 0 5px 0',
      ':last-child': {
        // margin:'5px 0 0px 0',
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '1px solid rgba(228, 228, 228, 0.79)',
      padding: '12px 0px',
      fontSize: '13px',
      color: MAIN_ONE_THEME.palette.primary5.main,
    },
    // tabs: {
    //   minHeight: '30px',
    //   height: 'auto'
    // },
    tabsRoot: {
      margin: '0 auto 20px',
    },
    indicator: {
      backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    },
    tabsFlexContainer: {
      width: '100%',
      minWidth: 'fit-content',
      margin: 'auto',
      justifyContent: 'center',
      '& .MuiTab-textColorPrimary.Mui-selected': {
        color: MAIN_ONE_THEME.palette.primary2.main,
      },
    },
    tabSubTitle: {
      color: '#A5A5A5',
      fontSize: '12px',
      lineHeight: '15px',
      textAlign: 'left',
    },
    scrollButtons: {
      width: 'fit-content',
    },
    tab: {
      // flexGrow: 1,
      // fontSize: '15px',
      // color: MAIN_ONE_THEME.palette.primary5.main,
      // padding: '5px',
      textAlign: 'center',
      font: 'normal 13px/16px HelveticaNeue-Medium',
      color: MAIN_ONE_THEME.palette.primary2.main,
      opacity: 1,
      textTransform: 'unset',
      flexGrow: 1,
    },
    activeTab: {
      borderBottom: `1px solid ${MAIN_ONE_THEME.palette.primary5.main}`,
    },
    tableCellRoot: {
      // color: MAIN_ONE_THEME.palette.primary2.main,
      // fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
      // lineHeight: '13px',
      // height: 25,
      // fontFamily: 'HelveticaNeue-Bold',
      // border: 'none',
      // padding: '4px 2px',
      // borderBottom: '1px solid rgba(0,0,0,.06)',
      fontFamily: mainFontFamilyBold,
      color: `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    },
    tableBodyRoot: {
      color: `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    },
    mainAmountTitleSingleRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);

const QuotesCardWidget: React.FC<IQuotesCardWidgetProps> = ({
  title,
  status,
  statusReason,
  actionTitle,
  actionValue,
  hasCoverage,
  coverageAmountCurrencySymbol,
  coverageAmount,
  premiumAmountCurrencySymbol,
  premiumAmount,
  isSelectable = true,
  selected = false,
  handleSelect = (id) => undefined,
  footer,
  Id,
  disabled,
  rows,
  tabs = null,
  mainAmountTitle = 'Coverage',
  isMainAmountTitleSingleRow,
  hasOnlyMainAmount = false,
  lineOfBusiness,
}) => {
  const { classes } = useStyles({ selected });

  const [activeTab, setActiveTab] = React.useState(0);

  const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderCheckbox = () => (
    <div
      className={clsx(classes.checkbox, disabled)}
      onClick={() => !disabled && handleSelect(Id)}
      onKeyDown={() => undefined}
      tabIndex={0}
      role="checkbox"
      aria-checked={selected}
    >
      <div className={classes.checkboxCheckIcon} />
    </div>
  );

  const renderValues = () => {
    return (
      <div
        className={clsx(
          isMainAmountTitleSingleRow ? classes.mainAmountTitleSingleRow : ''
        )}
      >
        {hasOnlyMainAmount && (
          <>
            <div className={classes.mainAmountTitle}></div>
            <div>
              {lineOfBusiness !== 'Expat' && Number(premiumAmount) !== 0 && (
                <span className={classes.premiumAmount}>
                  {premiumAmountCurrencySymbol}
                  {valueCommaSeparated(Number(premiumAmount).toFixed(2))}
                </span>
              )}
              {lineOfBusiness == 'Expat' && (
                <span className={classes.premiumAmount}>
                  {premiumAmountCurrencySymbol}
                  {Number(premiumAmount) != 0
                    ? valueCommaSeparated(Number(premiumAmount).toFixed(2))
                    : 0}
                </span>
              )}
            </div>
          </>
        )}
        {!hasOnlyMainAmount && (
          <>
            <div className={classes.mainAmountTitle}>{mainAmountTitle}</div>
            <div>
              <span
                className={classes.mainAmountValue}
                style={{ color: isMainAmountTitleSingleRow ? 'red' : '' }}
              >
                {coverageAmountCurrencySymbol}
                {valueCommaSeparated(Number(coverageAmount).toFixed(2))}
              </span>
              {Number(premiumAmount) != 0 && (
                <span className={classes.premiumAmount}>
                  {premiumAmountCurrencySymbol}
                  {valueCommaSeparated(Number(premiumAmount).toFixed(2))}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const getStatusColor = () => {
    switch (status.toLowerCase()) {
      case 'underwriting':
        return '#FF0000';
      case 'revised':
      case 'pending':
        return '#FFA355';
      case 'revision_sent':
      case 'sent':
        return '#32aede';
      case 'accepted':
        return 'rgb(33, 100, 26)';
      case 'closed':
        return '#1A3564';
      default:
        return '#FFA355';
    }
  };

  function renderKeyValueRow(row: {
    title: string;
    value: JSX.Element | string;
    show: boolean;
  }) {
    return row?.show ? (
      <div className={classes.row}>
        <span>{row?.title}</span>
        <span>{row?.value}</span>
      </div>
    ) : (
      <></>
    );
  }

  function renderRows(records: Record<string, IRow[]>) {
    return Object.keys(records).map((record, index) => {
      return Object.values(records[record]).map((cell, i) => {
        return renderKeyValueRow(cell);
      });
    });
  }

  function renderTable(records: Record<string, IRow[]>) {
    const tableRows = _.cloneDeep(records);

    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {Object.values(records[0]).map((cell, i) => {
                return (
                  <TableCell
                    key={cell.title}
                    classes={{
                      root: clsx(classes.tableCellRoot),
                    }}
                  >
                    {cell.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody
            classes={{
              root: clsx(classes.tableBodyRoot),
            }}
          >
            {Object.values(tableRows).map((record, i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {Object.values(record).map((cell, i) => {
                  return (
                    <TableCell
                      key={cell.title}
                      component="th"
                      scope="row"
                      classes={{
                        root: clsx(classes.tableBodyRoot),
                      }}
                    >
                      {cell.value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <WidgetPaper className={clsx(classes.quoteCard)}>
      {renderCheckbox()}
      <WidgetSection
        className={classes.widgetContainer}
        classes={{
          headerContainer: clsx(
            classes.sectionHeader,
            isSelectable ? classes.selectableSectionHeader : undefined
          ),
          container: classes.body,
          middle: classes.statusSection,
          title: classes.headerTitle,
        }}
        middle={
          <>
            <div className={classes.status} style={{ color: getStatusColor() }}>
              <p
                className={classes.statusDot}
                style={{ backgroundColor: getStatusColor() }}
              ></p>
              <span>{capitalizeFirstLetter(status.replaceAll('_', ' '))}</span>
            </div>
            <div
              className={classes.statusReason}
              style={{ color: getStatusColor() }}
            >
              <span>{statusReason}</span>
            </div>
          </>
        }
        key={Id}
        title={title}
        subtitle={
          <p className={classes.subTitle}>
            {actionTitle + ' '}
            {actionValue}
          </p>
        }
      >
        {hasCoverage && (
          <div className={classes.container}>
            <div className={classes.detailContainer}>{renderValues()}</div>
          </div>
        )}
        <div className={classes.rowsWrapper}>
          {rows != null &&
            Object.keys(rows).map((key) => {
              {
                return renderKeyValueRow(rows[key]);
              }
            })}
          {tabs != null && (
            <>
              <Box sx={{ width: '100%' }}>
                <Box>
                  <Tabs
                    onChange={handleActiveTab}
                    aria-label="basic tabs example"
                    classes={{
                      root: classes.tabsRoot,
                      indicator: classes.indicator,
                      flexContainer: classes.tabsFlexContainer,
                      scrollButtons: classes.scrollButtons,
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                  >
                    {Object.keys(tabs).map((key, index) => {
                      return (
                        <Tab
                          key={key}
                          label={key}
                          className={clsx(
                            classes.tab,
                            activeTab == index && classes.activeTab
                          )}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
                {Object.keys(tabs).map((key, index) => {
                  return (
                    <div
                      key={key}
                      role="tabpanel"
                      hidden={activeTab !== index}
                      id={`simple-tabpanel-${index}`}
                      aria-labelledby={`simple-tab-${index}`}
                    >
                      {!tabs[key].firstRowIsHeader &&
                        renderRows(tabs[key].rows)}

                      {tabs[key].firstRowIsHeader &&
                        renderTable(tabs[key].rows)}
                    </div>
                  );
                })}
              </Box>
            </>
          )}
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default QuotesCardWidget;
