import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { REGEX, isEmpty } from '../../utils/validationUtils';

export const initialValues: IUserInfo = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  role: '',
  status: 'INACTIVE',
};

export const inputs: Record<string, DynamicFormInputType> = {
  firstName: {
    name: 'firstName',
    title: 'First Name',
    type: FormInputTypes.text,
    value: initialValues.firstName,
    required: true,
  },
  lastName: {
    name: 'lastName',
    title: 'Last Name',
    type: FormInputTypes.text,
    value: initialValues.lastName,
    required: true,
  },
  email: {
    name: 'email',
    title: 'Email',
    type: FormInputTypes.text,
    value: initialValues.email,
    required: true,
    regex: REGEX.email,
    regexError: 'Invalid Email',
  },
  mobile: {
    name: 'mobile',
    title: 'Mobile Number',
    type: FormInputTypes.phoneNumber,
    value: initialValues.mobile,
    required: true,
    editCountryCode: false,
    defaultCountry: 'lb',
    disableDropDown: true,
    customFormat: { lb: '.. ......' },
  },
  role: {
    name: 'role',
    title: 'Role',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.role) ? initialValues.role : [],
    required: true,
    multiple: false,
  },
  status: {
    name: 'status',
    title: 'Status',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.status) ? initialValues.status : [],
    required: true,
    multiple: false,
  },
};
