import React from 'react';
import { NavLink } from 'react-router-dom';
import CollapsibleSection from '../common/CollapsibleSection';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { MAIN_ONE_THEME } from '../../constants';
import { IApplication } from '../../Shell';

interface IAppLauncherModulesProps {
  applications: Record<string, IApplication>;
  searchKey: string;
  closeModal: () => void;
}

const useStyles = makeStyles()((theme) => ({
  collapseContent: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  moduleLink: {
    width: 'calc(100% - 10px)',
    margin: '10px 10px 10px 0px',
    fontStyle: 'normal',
    textDecoration: 'none',
    fontFamily: 'HelveticaNeue-Regular',
    color: MAIN_ONE_THEME.palette.primary4.main,
    cursor: 'pointer',
    '@media only screen and (min-width: 700px)': {
      width: 'calc(50% - 10px)',
    },
    '@media only screen and (min-width: 1024px)': {
      width: 'calc(33% - 10px)',
    },
    '@media only screen and (min-width: 1440px)': {
      width: 'calc(20% - 10px)',
    },
    '&&:hover': {
      textDecoration: 'underline',
    },
  },
  activeLink: {
    fontWeight: 'bold',
  },
}));

const AppLauncherModules: React.FC<IAppLauncherModulesProps> = ({
  applications,
  closeModal,
  searchKey,
}) => {
  const { classes } = useStyles();

  return (
    <CollapsibleSection
      title={'All Modules'}
      className={classes.collapseContent}
      open
    >
      {Object.values(applications).map((app) =>
        Object.keys(app.modules).map(
          (moduleName) =>
            (!searchKey ||
              !searchKey.trim() ||
              app.modules[moduleName].title
                .toLowerCase()
                .includes(searchKey.toLowerCase())) && (
              // eslint-disable-next-line react/jsx-indent
              <NavLink
                key={moduleName}
                to={app.route + app.modules[moduleName].route}
                className={({ isActive }) =>
                  clsx(classes.moduleLink, { [classes.activeLink]: isActive })
                }
                onClick={closeModal}
              >
                {app.modules[moduleName].title}
              </NavLink>
            )
        )
      )}
    </CollapsibleSection>
  );
};

export default AppLauncherModules;
