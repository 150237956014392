/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Autocomplete,
  Chip,
  CircularProgress,
  createFilterOptions,
  FormControl,
  TextField,
  Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import { isArray } from 'lodash';
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IEnhancedFormInputBaseProps } from '.';
import { contentFontFamilyRegular, MAIN_ONE_THEME } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';

export interface IEnhancedInputProps extends IEnhancedFormInputBaseProps {
  name: string;
  title: string;
  type?: string;
  value: string[] | string;
  classes?: { input?: string; wrapper?: string };
  error: string;
  onChange?: (value: any) => void;
  onBlur?: (extraValue: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  disabled?: boolean;
  customStyles?: {
    containerStyles?: any;
    labelStyles?: any;
    inputStyles?: any;
    errorStyles?: any;
  };
  selectOptions?: Record<string, any>;
  required?: boolean;
  inline?: boolean;
  description?: string;
  loader?: boolean;
  freeSolo?: boolean;
  onBlurSaveNewData?: boolean;
  multiple?: boolean;
  limitTags?: number;
  strongStyledOption?: boolean;
  hidden?: boolean;
  hasSpecificStyle?: boolean;
  canClearSingleValueSelection?: boolean;
  isInlineButton?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
}

type EnhancedChipInputProps = IEnhancedInputProps & {
  showSelectAll?: boolean;
  allowDeleteValue?: boolean;
  keyToClear?: string[];
};

const useStyles = makeStyles<{
  error: string;
  canSelectAll: boolean;
  canClearAll: boolean;
  hasSpecificStyle: boolean;
  isInlineButton: boolean;
}>()(
  (
    theme,
    { error, canSelectAll, canClearAll, hasSpecificStyle, isInlineButton }
  ) => ({
    inputContainerStyles: {
      width: hasSpecificStyle ? '200px' : isInlineButton ? '500px' : '100%',
      marginBottom: '5px',
      fontFamily: contentFontFamilyRegular,
    },
    inputStyles: {
      width: '100%',
      border: '1px solid #E3E3E3',
      outline: 'none',
      backgroundColor: '#ffffff !important',
      '& .MuiInputBase-input': {
        '&::placeholder': {
          fontStyle: 'italic',
          opacity: '22%',
          fontFamily: contentFontFamilyRegular,
          fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
          backgroundColor: hasSpecificStyle ? '#FFFFFF !important' : 'none',
        },
      },
      '& .MuiInputBase-root': {
        fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
        fontFamily: contentFontFamilyRegular,
        lineHeight: '15px !important',
        minHeight: '35px !important',
        width: '100% !important',
        padding: '0 25px 0 15px!important',
        margin: '0 auto !important',
        backgroundColor: hasSpecificStyle
          ? '#FFFFFF !important'
          : `${MAIN_ONE_THEME.palette.secondary4.main} !important`,
        outlineColor: error ? MAIN_ONE_THEME.palette.error.main : undefined,
        border: error
          ? `1px solid ${MAIN_ONE_THEME.palette.error.main} !important`
          : `0px solid ${MAIN_ONE_THEME.palette.secondary3.main} !important`,
        boxSizing: 'border-box',
        borderRadius: '5px',
        '&::before': {
          display: 'none !important',
        },
        '&::after': {
          display: 'none !important',
        },
      },
    },
    optionDesign: {
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg3.fontSize}px !important`,
      lineHeight: '15px !important',
      color: MAIN_ONE_THEME.palette.primary1.main,
      fontFamily: contentFontFamilyRegular,
    },
    inputLabelStyles: {
      marginBottom: '5px',
    },
    inputErrorStyles: {
      minHeight: '25px',
      color: '#f44336',
      textAlign: 'left',
      fontSize: '12px',
      marginTop: '5px',
    },
    inputRedBorder: {
      border: '1px solid #f44336',
    },
    chipStyle: {
      height: 'unset',
      color: '#fff',
      backgroundColor: MAIN_ONE_THEME.palette.primary1.main,
      borderRadius: '0px',
      margin: '3px',
      '& .MuiChip-deleteIcon': {
        color: 'white !important',
      },
    },
    numberingStyle: {
      height: 'unset',
      color: MAIN_ONE_THEME.palette.primary1.main,
      backgroundColor: '#fff',
      borderRadius: '0px',
      margin: '3px',
      '& .MuiChip-deleteIcon': {
        color: 'white !important',
      },
    },
    loader: {
      position: 'absolute',
      top: 'calc(50% - 10px)',
      right: '0.5%',
      opacity: 1,
      height: '25px',
      width: '25px',
      fontFamily: contentFontFamilyRegular,
    },
    selectMaterial: {
      width: hasSpecificStyle ? '80%' : isInlineButton ? '45%' : '100%',
      '& .MuiInput-input': {
        fontFamily: contentFontFamilyRegular,
        fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
        lineHeight: '15px',
        color: `${MAIN_ONE_THEME.palette.primary2.main}`,
        paddingBottom: '0px !important',
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.20)',
          fontStyle: 'italic',
          opacity: '10',
          fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
        },
      },
      '& .MuiInputBase-root:before': {
        border: 'none',
        backgroundColor: 'unset !important',
        borderRadius: '0 !important',
        borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
      },
      '& .MuiInput-root': {
        height: '34px',
        paddingBottom: '0px !important',
      },
      '& .MuiInput-root.Mui-disabled:before': {
        borderBottomStyle: 'solid',
      },

      '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
        {
          borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
        },
    },
    selectError: {
      '& .MuiInputBase-root:before': {
        borderBottom: `1px solid ${MAIN_ONE_THEME.palette.error.main}`,
      },
      '& .MuiInput-root.Mui-disabled:before': {
        borderBottomStyle: 'solid',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
        {
          borderBottom: `1px solid ${MAIN_ONE_THEME.palette.error.main}`,
        },
      '& .MuiInputBase-root:focus': {
        borderColor: MAIN_ONE_THEME.palette.error.main,
        outlineColor: MAIN_ONE_THEME.palette.error.main,
        boxShadow: 'none',
        borderRadius: 0,
      },
      '& .MuiInputBase-root': {
        borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
        outlineColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
      },
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
    },
    actionButton: {
      textDecoration: 'none',
      border: 0,
      outline: 0,
      background: 'transparent',
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.7,
      },
    },
    selectAllButton: {
      pointerEvents: canSelectAll ? undefined : 'none',
      opacity: canSelectAll ? undefined : 0.4,
    },
    clearAllButton: {
      pointerEvents: canClearAll ? undefined : 'none',
      opacity: canClearAll ? undefined : 0.4,
    },
    autocompletePopper: {
      '& .MuiAutocomplete-option': {
        '&:hover': {
          backgroundColor: '#F5F5F5',
        },
      },
      '& .MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#DDDDDD',
        '&:hover': {
          backgroundColor: '#BBBBBB',
        },
        '&:not(:hover)': {
          backgroundColor: '#DDDDDD',
        },
      },
    },
    disabledInput: {
      '& .MuiInputBase-root.Mui-disabled': {
        cursor: 'not-allowed',
        color: 'rgba(0, 0, 0, 0.5)!important',
        opacity: `0.6 !important`,
        textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
      },
      '& .MuiInputBase-input.Mui-disabled': {
        cursor: 'not-allowed',
        color: 'rgba(0, 0, 0, 0.5)!important',
        opacity: `0.6 !important`,
        textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.9) !important`,
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.20)',
          fontStyle: 'italic',
          opacity: '0.3',
        },
      },
    },
  })
);

const EnhancedChipInput: React.FC<EnhancedChipInputProps> = ({
  name,
  title,
  placeholder,
  className,
  style,
  classes = {},
  value,
  error,
  onChange,
  onBlur = () => undefined,
  onFocus = () => undefined,
  description,
  disabled,
  selectOptions = {},
  inline,
  loader,
  freeSolo = false,
  onBlurSaveNewData = false,
  multiple = true,
  material,
  limitTags = -1,
  showSelectAll = false,
  strongStyledOption,
  customStyles,
  hidden = false,
  hasSpecificStyle = false,
  canClearSingleValueSelection = false,
  isInlineButton = false,
  allowDeleteValue = false,
  hasTooltip = false,
  tooltipText,
}) => {
  const canSelectAll = useMemo(
    () =>
      multiple &&
      showSelectAll &&
      Object.keys(selectOptions).some(
        (option) => Array.isArray(value) && !value.includes(option)
      ),
    [multiple, showSelectAll, selectOptions, value]
  );
  const canClearAll = useMemo(
    () => multiple && showSelectAll && Array.isArray(value) && value.length > 0,
    [multiple, showSelectAll, selectOptions, value]
  );
  const { classes: inputClasses } = useStyles({
    error,
    canSelectAll,
    canClearAll,
    hasSpecificStyle,
    isInlineButton,
  });

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
  });

  // if (!Array.isArray(value) && !isEmpty(value)) {
  //   value = [value];
  // }

  value = value || [];
  if (!Array.isArray(value)) {
    value = [value];
  }

  const handleClear = () => {
    onChange(null);
  };

  // to allow null value in case of dropdown (multiple=false)
  if (isEmpty(value) && !multiple) {
    value = [];
  }

  const myRef = React.createRef<HTMLInputElement>();
  const renderInput = () => {
    return (
      <div className={inputClasses.inputContainerStyles}>
        <FormControl
          error={!!error}
          disabled={!!disabled}
          className={inputClasses.inputContainerStyles}
          size="small"
          variant="outlined"
        >
          <Autocomplete
            hidden={hidden}
            freeSolo={freeSolo}
            multiple={multiple}
            className={clsx({
              [inputClasses.disabledInput]: disabled,
            })}
            classes={{ popper: inputClasses.autocompletePopper }}
            disabled={!!disabled || loader}
            disablePortal={false}
            options={Object.keys(selectOptions)}
            filterOptions={filterOptions}
            onChange={(event: any, newValue: any) => {
              if (myRef.current && document.activeElement !== myRef.current) {
                myRef.current.focus();
              }
              onChange(newValue);
            }}
            onFocus={() => onFocus()}
            onBlur={(event: any) => {
              if (onBlurSaveNewData) {
                onBlur(event.target.value);
              } else {
                onBlur('');
              }
            }}
            clearOnBlur={onBlurSaveNewData || !multiple}
            value={value}
            getOptionLabel={(option) => {
              const optionId = isArray(option) ? option[0] : option;

              return !isEmpty(optionId) &&
                !isEmpty(selectOptions) &&
                selectOptions[optionId]
                ? `${selectOptions[optionId]}`
                : '';
            }}
            disableClearable={!multiple}
            selectOnFocus
            disableCloseOnSelect={multiple}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option as unknown as string}>
                <span className={inputClasses.optionDesign}>
                  {strongStyledOption ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: (
                          selectOptions[option as string] as string
                        ).replace(
                          option as string,
                          (('<strong>' + option) as string) + '</strong>'
                        ),
                      }}
                    />
                  ) : (
                    selectOptions[option as string]
                  )}
                </span>
              </li>
            )}
            renderTags={(value: any, getTagProps) => {
              if (
                Array.isArray(value) &&
                value.length > 1 &&
                material &&
                isInlineButton
              ) {
                const firstItem = value[0];
                const otherItemsCount = value.length - 1;
                return (
                  <>
                    <Tooltip
                      title={
                        !isEmpty(selectOptions[firstItem])
                          ? selectOptions[firstItem]
                          : firstItem
                      }
                    >
                      <Chip
                        variant="filled"
                        label={
                          !isEmpty(selectOptions[firstItem])
                            ? selectOptions[firstItem]
                            : firstItem
                        }
                        {...getTagProps({ index: 0 })}
                        className={inputClasses.chipStyle}
                      />
                    </Tooltip>
                    <Tooltip title={`${otherItemsCount} more selected`}>
                      <Chip
                        variant="filled"
                        label={`+${otherItemsCount}`}
                        className={inputClasses.numberingStyle}
                      />
                    </Tooltip>
                  </>
                );
              } else {
                return value.map((option: string, index: number) => (
                  <Tooltip
                    key={option}
                    title={
                      !isEmpty(selectOptions[option])
                        ? selectOptions[option]
                        : option
                    }
                  >
                    {!isEmpty(selectOptions[option]) && (
                      <Chip
                        variant="filled"
                        label={
                          !isEmpty(selectOptions[option])
                            ? selectOptions[option]
                            : option
                        }
                        {...getTagProps({ index })}
                        key={index}
                        className={inputClasses.chipStyle}
                      />
                    )}
                  </Tooltip>
                ));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {canClearSingleValueSelection && !multiple && value && (
                        <Tooltip title="Clear">
                          <span
                            onClick={handleClear}
                            style={{ cursor: 'pointer' }}
                          >
                            x
                          </span>
                        </Tooltip>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  style: disabled
                    ? {
                        color: 'rgba(0, 0, 0, 0.5) !important',
                        opacity: 1,
                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.5) !important',
                        WebkitTextEmphasisColor:
                          'rgba(0, 0, 0, 0.5) !important',
                      }
                    : {},
                }}
                {...((Array.isArray(value) &&
                  value &&
                  value.length == 0 &&
                  placeholder) ||
                !Array.isArray(value)
                  ? { placeholder: `${placeholder}` }
                  : {})}
                name={name}
                className={clsx({
                  [inputClasses.inputStyles]: !material,
                  [(customStyles?.inputStyles && customStyles?.inputStyles) ||
                  inputClasses.selectMaterial]: material,
                  [inputClasses.selectError]: error,
                  [inputClasses.disabledInput]: disabled,
                })}
                variant="standard"
                inputRef={myRef}
              />
            )}
            limitTags={limitTags}
          />
        </FormControl>
      </div>
    );
  };

  const onSelectAllClicked = () => {
    if (multiple) {
      if (myRef.current && document.activeElement !== myRef.current) {
        myRef.current.focus();
      }
      onChange(Object.keys(selectOptions));
    }
  };

  const onClearAllClicked = () => {
    if (multiple) {
      if (myRef.current && document.activeElement !== myRef.current) {
        myRef.current.focus();
      }
      onChange([]);
    }
  };

  const getActions = () => {
    if (!showSelectAll || !multiple) return <></>;
    return (
      <div className={inputClasses.actionsContainer}>
        <button
          type="button"
          onClick={onSelectAllClicked}
          className={clsx(
            inputClasses.actionButton,
            inputClasses.selectAllButton
          )}
        >
          Select All
        </button>
        <button
          type="button"
          onClick={onClearAllClicked}
          className={clsx(
            inputClasses.actionButton,
            inputClasses.clearAllButton
          )}
        >
          Clear All
        </button>
      </div>
    );
  };

  return inline ? (
    renderInput()
  ) : (
    <>
      {!hidden && (
        <EnhancedInputsWrapper
          title={title}
          description={description}
          error={error}
          name={name}
          className={clsx(classes.wrapper, className)}
          style={style}
          actions={getActions()}
          customStyles={customStyles}
          hidden={hidden}
          hasTooltip={hasTooltip}
          tooltipText={tooltipText}
        >
          <div style={{ position: 'relative' }}>
            {renderInput()}
            {loader && (
              <CircularProgress
                className={inputClasses.loader}
                size={20}
                thickness={3}
              />
            )}
          </div>
        </EnhancedInputsWrapper>
      )}
    </>
  );
};

export default EnhancedChipInput;
