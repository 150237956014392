import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { MAIN_ONE_THEME } from '../constants';

interface IEnhanceIconProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title: string;
  iconUrl: string;
  hasBackground?: boolean;
  classes?: { figure?: string; image?: string };
}

const useStyles = makeStyles()(() => ({
  figure: {
    margin: 0,
    height: 35,
    minHeight: 35,
    width: 35,
    minWidth: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `${MAIN_ONE_THEME.palette.secondary4.main} 0% 0% no-repeat padding-box`,
    borderRadius: '5px',
  },
  noBackground: {
    height: 15,
    minHeight: 15,
    width: 15,
    minWidth: 15,
    background: 'unset',
  },
  image: {
    height: 15,
    minHeight: 15,
    width: 15,
    minWidth: 15,
  },
}));

const EnhancedIcon: React.FC<IEnhanceIconProps> = ({
  className,
  classes = {},
  style,
  title,
  iconUrl,
  hasBackground,
}) => {
  const { classes: iconClasses } = useStyles();

  return (
    <figure
      style={style}
      className={clsx(
        iconClasses.figure,
        { [iconClasses.noBackground]: !hasBackground },
        className,
        classes.figure
      )}
    >
      <img
        className={clsx(iconClasses.image, classes.image)}
        alt={title}
        src={iconUrl}
      />
    </figure>
  );
};

export default EnhancedIcon;
