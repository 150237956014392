/**
 * Calculates the grid width in percentage
 * @param {number} width The grid width {0,1,2,....,12}
 * @return {number} The percentage {0,........100}
 * If value is invalid (N/A) returns 0
 */
export function gridWidthToPercentage(width: number): number {
  if (isNaN(width) || width === 0) {
    return 0;
  }
  return (width / 12) * 100;
}
