import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Button,
  ClickAwayListener,
  Grow,
  lighten,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { contentFontFamilyRegular, MAIN_ONE_THEME } from '../../constants';
import { EnhancedButtonStatus } from '../EnhancedButton';

const useStyles = makeStyles<{
  backgroundColor: string;
  color: string;
  state?: EnhancedButtonStatus;
  hasImage?: boolean;
}>()((theme, { backgroundColor, color, state, hasImage }) => ({
  root: {
    position: 'relative !important' as any,
    borderRadius: '3px !important',
    minHeight: hasImage ? '30px !important' : '32px !important',
    minWidth: hasImage ? '30px !important' : '80px !important',
    border: hasImage
      ? 0
      : `1px ${MAIN_ONE_THEME.palette.secondary3.main} solid !important`,
    boxSizing: 'border-box !important' as any,
    padding: hasImage ? '0px !important' : '0 15px !important',
    backgroundColor: `${backgroundColor} !important`,
    textTransform: 'unset !important' as any,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg3.fontSize} !important`,
    lineHeight: '15px !important',
    textAlign: 'center',
    color: color
      ? `${color} !important`
      : `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    boxShadow: 'none !important',
    display: 'flex',
    flexDirection: 'row',
    width: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: `${lighten(backgroundColor, 0.25)} !important`,
      fontFamily: contentFontFamilyRegular,
      '&:disabled': {
        backgroundColor: `${lighten(backgroundColor, 0.25)} !important`,
      },
    },
    '&:disabled': {
      cursor: 'not-allowed !important',
      opacity: 0.4,
      pointerEvents: 'all !important' as any,
      fontFamily: contentFontFamilyRegular,
    },
    fontFamily: contentFontFamilyRegular,
  },
}));

export interface IEnhancedMenuItem {
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  onClick: () => void;
}

export interface IEnhancedMenuProps {
  title: string;
  items: IEnhancedMenuItem[];
  specificDesign?: boolean;
  className?: string;
}

export const SimpleEnhancedMenu: React.FC<IEnhancedMenuProps> = ({
  title,
  items,
  specificDesign = false,
  className,
}) => {
  const { classes } = useStyles({
    backgroundColor: '#fff',
    color: MAIN_ONE_THEME.palette.primary1.main,
  });

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // Close the Popper when scrolling
  React.useEffect(() => {
    const handleScroll = () => {
      setOpen(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={className}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        classes={{
          root: classes.root,
        }}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {title}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 100 }}
        placement={'bottom-end'}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  style={{ width: '150px' }}
                >
                  {items &&
                    items.length > 0 &&
                    items.map(
                      (item) =>
                        !item.hidden && (
                          <MenuItem
                            disabled={item.disabled}
                            key={item.title}
                            onClick={(e) => {
                              item.onClick();
                              handleClose(e);
                            }}
                            style={{
                              wordBreak: 'normal',
                              whiteSpace: 'normal',
                            }}
                            disableRipple
                            sx={{
                              fontFamily: contentFontFamilyRegular,
                              fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        )
                    )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default SimpleEnhancedMenu;
