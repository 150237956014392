// TODO Refactor this component to support enabling and disabling formatting
import { makeStyles } from 'tss-react/mui';
import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { isEmpty, isValidNumber } from '../../utils/validationUtils';
import {
  reduceNumberOfDecimals,
  removeNumberDelimeter,
} from '../../utils/formatting-utils';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { IEnhancedFormInputBaseProps } from '.';

export interface IEnhancedFormattedDecimalInputProps
  extends IEnhancedFormInputBaseProps,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {
  name: string;
  title: string;
  description?: string;
  error?: string;
  material?: boolean;
  maxDecimalPercision?: number;
  value: string | number;
  inline?: boolean;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputClass?: string;
  wrapperClass?: string;
  hidden?: boolean;
  minValue?: number;
  maxValue?: number;
  isEditable?: boolean;
  disabled?: boolean;
  enableFormatting?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: 13,
    lineHeight: '15px',
    height: 35,
    padding: '0 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.4,
      color: MAIN_ONE_THEME.palette.primary2.main,
    },
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.20)',
      fontStyle: 'italic',
    },
    fontFamily: contentFontFamilyRegular,
    '&:focus': {
      outline: 'none',
    },
  },
  // TODO make style common using JSS
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontFamily: contentFontFamilyRegular,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
  },
  formCurrencyIcon: {
    position: 'absolute',
    bottom: '0',
    left: '7px',
    height: '11px',
    width: '15px',
    marginTop: '2px',
    fontFamily: contentFontFamilyRegular,
  },
  currencyInput: {
    fontFamily: contentFontFamilyRegular,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
  },
  inputError: {
    borderColor: MAIN_ONE_THEME.palette.error.main,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
    fontFamily: contentFontFamilyRegular,
  },
  inlineContainer: {
    position: 'relative',
    fontFamily: contentFontFamilyRegular,
  },
  inputWrapper: {
    height: '88.6px',
  },
}));

const EnhancedFormattedDecimalInput: React.FC<
  IEnhancedFormattedDecimalInputProps
> = ({
  inline,
  className,
  inputClass,
  error,
  name,
  title,
  description,
  value,
  style,
  placeholder,
  disabled,
  maxDecimalPercision = 2,
  material,
  onChange,
  onFocus,
  onBlur,
  wrapperClass,
  customStyles,
  step,
  hidden = false,
  minValue,
  maxValue,
  isEditable = false,
  enableFormatting = true,
}) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = useState(value.toString());

  useEffect(() => {
    setInputValue(
      enableFormatting ? formatValue(value.toString()) : value.toString()
    );
  }, [value, enableFormatting]);

  const formatWithCommas = (num: string) => {
    const parts = num.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = event.currentTarget.value;
      if (enableFormatting) {
        newValue = newValue.replace(/,/g, '');
        setInputValue(formatWithCommas(newValue));
      } else {
        setInputValue(newValue);
      }

      newValue = removeNumberDelimeter(newValue);

      if (isNaN(parseFloat(newValue))) {
        newValue = '';
      }
      if (isValidNumber(minValue) && parseFloat(newValue) < minValue) {
        newValue = minValue.toString();
      }
      if (isValidNumber(maxValue) && parseFloat(newValue) > maxValue) {
        newValue = maxValue.toString();
      }
      onChange({
        ...event,
        target: {
          ...event.target,
          value: newValue,
          name: event.target.name,
        },
      });
    },
    [minValue, maxValue, onChange, enableFormatting]
  );

  const limitNumber = useCallback(
    (targetValue: string) => {
      let newValue = removeNumberDelimeter(targetValue);
      if (
        Number(maxDecimalPercision) >= 0 &&
        isValidNumber(maxDecimalPercision)
      ) {
        newValue = reduceNumberOfDecimals(
          newValue,
          Number(maxDecimalPercision)
        );
      }
      return newValue;
    },
    [maxDecimalPercision]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      let newValue = parseFloat(limitNumber(event.target.value));
      if (isValidNumber(minValue) && newValue < minValue) {
        newValue = minValue;
      }
      if (isValidNumber(maxValue) && newValue > maxValue) {
        return;
      }
      onBlur({
        ...event,
        relatedTarget: null,
        target: {
          ...event.target,
          value: isNaN(newValue) ? '' : newValue.toString(),
          name: event.target.name,
        },
      });
    },
    [limitNumber, minValue, maxValue, onChange]
  );

  const formatValue = useCallback(
    (val: string) => {
      if (enableFormatting) {
        return formatWithCommas(val);
      } else {
        return val;
      }
    },
    [enableFormatting]
  );

  const renderInput = () => {
    return (
      <>
        <input
          className={clsx(
            clsx(classes.input, inline ? className : inputClass, {
              [classes.materialInput]: material,
              [classes.currencyInput]: !material,
              [classes.inputError]: error,
            })
          )}
          style={{
            width: '100%',
          }}
          title={disabled && !isEmpty(value) ? value.toString() : title}
          id={name}
          name={name}
          aria-invalid={!!error}
          aria-describedby={`errorMsg-${name}`}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          step={step}
          hidden={hidden}
          min={minValue}
          max={maxValue}
        />
      </>
    );
  };

  return inline ? (
    <span className={classes.inlineContainer}>{renderInput()}</span>
  ) : (
    <EnhancedInputsWrapper
      title={title}
      description={description}
      error={error}
      name={name}
      className={clsx(classes.inputWrapper, className)}
      style={style}
      childrenClass={wrapperClass}
      customStyles={customStyles}
      hidden={hidden}
    >
      <div style={{ position: 'relative' }}>{renderInput()}</div>
    </EnhancedInputsWrapper>
  );
};

export default EnhancedFormattedDecimalInput;
