import React from 'react';
import clsx from 'clsx';
import HeaderShell from './HeaderShell';
import { HEADER_HEIGHT, contentFontFamilyRegular } from '../constants';
import { makeStyles } from 'tss-react/mui';

// interface ISeparatorClassProps {
//   bannerOpened: boolean;
//   separatorHeightWithBannerOpened: number;
// }

interface IPageShellProps {
  header?: JSX.Element;
  classes?: { header?: string; body?: string };
  bannerConfig?: { bannerOpened: boolean; bannerHeight: number };
  children?: React.ReactNode;
}

const useStyles = makeStyles<{
  bannerOpened: boolean;
  separatorHeightWithBannerOpened: number;
}>()((theme, { bannerOpened, separatorHeightWithBannerOpened }) => ({
  body: {
    width: '100%',
    fontFamily: contentFontFamilyRegular,
  },
  separator: {
    height: bannerOpened ? separatorHeightWithBannerOpened : HEADER_HEIGHT,
    transition: theme.transitions.create('height', {
      duration: 250,
      delay: 100,
      easing: theme.transitions.easing.easeIn,
    }),
  },
}));

const PageShell: React.FC<IPageShellProps> = ({
  header,
  classes,
  children,
  bannerConfig,
}) => {
  const { bannerOpened = false, bannerHeight = 0 } = bannerConfig || {};
  const separatorHeightWithBannerOpened = HEADER_HEIGHT + bannerHeight;
  const { classes: shellClasses } = useStyles({
    bannerOpened,
    separatorHeightWithBannerOpened,
  });

  return (
    <>
      <HeaderShell className={classes ? classes.header : ''}>
        {header}
      </HeaderShell>
      <div className={shellClasses.separator} />
      <section className={clsx(shellClasses.body, classes ? classes.body : '')}>
        {children}
      </section>
    </>
  );
};

export default PageShell;
