import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  accounting_Invoices_InvoiceNumber: {
    name: 'accounting_Invoices_InvoiceNumber',
    title: 'Invoice #',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
  customer_CustomerName: {
    name: 'customer_CustomerName',
    title: 'Customer Name',
    type: EnhancedTableHeaderType.Text,
  },
  accounting_Invoices_InvoiceDate: {
    name: 'accounting_Invoices_InvoiceDate',
    title: 'Invoice Date',
    type: EnhancedTableHeaderType.Date,
  },
  accounting_Invoices_PaymentDate: {
    name: 'accounting_Invoices_PaymentDate',
    title: 'Payment Date',
    type: EnhancedTableHeaderType.Date,
  },
  accounting_Invoices_InvoiceTotalAmount: {
    name: 'accounting_Invoices_InvoiceTotalAmount',
    title: 'Total Amount',
    type: EnhancedTableHeaderType.Currency,
  },
  accounting_Invoices_InvoiceStatus: {
    name: 'accounting_Invoices_InvoiceStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  accounting_Invoices_createdOn: {
    name: 'accounting_Invoices_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  accounting_Invoices_modifiedOn: {
    name: 'accounting_Invoices_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        company: {
          name: 'company',
          title: 'Company',
          type: FormInputTypes.chips,
          value: filterValues?.company || null,
          placeholder: 'Company',
          triggerUpdate: true,
          selectOptions: { ...lovs.company },
          multiple: false,
          canClearSingleValueSelection: false,
        },
        customer: {
          name: 'customer',
          title: 'Customer',
          type: FormInputTypes.chips,
          value: filterValues?.customer || null,
          placeholder: 'Customer',
          triggerUpdate: true,
          selectOptions: { ...lovs.customer },
          multiple: false,
          canClearSingleValueSelection: true,
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || null,
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
          multiple: true,
          canClearSingleValueSelection: true,
        },
        invoiceDate: {
          name: 'invoiceDate',
          title: 'Invoice Date',
          type: FormInputTypes.daterange,
          value: filterValues.invoiceDate || [],
          placeholder: 'Invoice Date',
          triggerUpdate: true,
        },
        paymentDate: {
          name: 'paymentDate',
          title: 'Payment Date',
          type: FormInputTypes.daterange,
          value: filterValues.paymentDate || [],
          placeholder: 'Payment Date',
          triggerUpdate: true,
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+ New Invoice',
    onClick: () => undefined,
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
];
