import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { DynamicFormInputType } from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useMutation } from '@apollo/client';
import { closeProposal } from './queries';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import _ from 'lodash';
import { getError } from '../../utils/graph-utils';

const CloseProposalDrawer: React.FC<ICloseProposalDrawerProps> = ({
  id,
  open,
  onSuccess,
  onClose,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>();
  const [userAction] = useMutation(closeProposal());

  useEffect(() => {
    setInputsForm(_.cloneDeep(inputs));
  }, []);

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setSubmitButtonState('loading');
    setFormDisabled(true);
    try {
      const variables = {
        entityId: id,
        closingReason: data.closingReason,
      };

      userAction({
        variables: variables,
        errorPolicy: 'all',
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {'Proposal closed successfully'}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState('success');
            onSuccess();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  if (!inputsForm) {
    return <></>;
  }

  return (
    <GenericDrawer
      title={'Close Proposal'}
      onClose={() => onClose()}
      isOpen={open}
    >
      <DynamicForm
        inputs={inputsForm}
        onSubmit={(values) => submitForm(values)}
        buttonText={'Submit'}
        submitButtonState={submitButtonState}
        disableForm={formDisabled}
        title="Information"
      />
    </GenericDrawer>
  );
};

export default CloseProposalDrawer;
