import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  gender: '',
  maritalStatus: '',
  fatherName: '',
  motherName: '',
  countryOfBirth: '',
  cityOfBirth: '',
  nationalityID: '',
  passportNumber: '',
  secondNationality: '',
};

export const getInputs = (
  values: IPersonAdditionalInformationInfo = initialValues,
  lovs: Record<string, Record<string, string>>,
  type: string
): Record<string, DynamicFormInputType> => {
  return {
    gender: {
      name: 'gender',
      title: 'Gender',
      type: FormInputTypes.chips,
      placeholder: 'Gender',
      value: !isEmpty(values.gender) ? values.gender : null,
      multiple: false,
      hidden: false,
      disabled: false,
      selectOptions: lovs.genders || {},
    },
    maritalStatus: {
      name: 'maritalStatus',
      title: 'Marital Status',
      placeholder: 'Marital Status',
      type: FormInputTypes.chips,
      value: !isEmpty(values.maritalStatus) ? values.maritalStatus : null,
      multiple: false,
      hidden: false,
      disabled: false,
      selectOptions: lovs.maritalStatuses || {},
    },
    fatherName: {
      name: 'fatherName',
      title: 'Father Name',
      placeholder: 'Father Name',
      type: FormInputTypes.text,
      value: values.fatherName,
      minCharacters: 2,
      maxCharacters: 100,
      hidden: false,
      disabled: false,
    },
    motherName: {
      name: 'motherName',
      title: 'Mother Name',
      placeholder: 'Mother Name',
      type: FormInputTypes.text,
      value: values.motherName,
      minCharacters: 2,
      maxCharacters: 100,
      hidden: false,
      disabled: false,
    },
    countryOfBirth: {
      name: 'countryOfBirth',
      title: 'Country of Birth',
      placeholder: 'Country of Birth',
      type: FormInputTypes.chips,
      value: !isEmpty(values.countryOfBirth) ? values.countryOfBirth : null,
      multiple: false,
      hidden: false,
      disabled: false,
      selectOptions: lovs.countries,
    },
    cityOfBirth: {
      name: 'cityOfBirth',
      title: 'City of Birth',
      placeholder: 'City of Birth',
      type: FormInputTypes.text,
      value: values.cityOfBirth,
      minCharacters: 2,
      maxCharacters: 100,
      hidden: false,
      disabled: false,
    },
    nationalityID: {
      name: 'nationalityID',
      title: 'Nationality ID',
      placeholder: 'Nationality ID',
      type: FormInputTypes.text,
      value: values.nationalityID,
      minCharacters: 2,
      maxCharacters: 100,
    },
    passportNumber: {
      name: 'passportNumber',
      title: 'Passport Number',
      type: FormInputTypes.text,
      placeholder: 'Passport Number',
      value: values.passportNumber,
      minCharacters: 2,
      maxCharacters: 100,
      hidden: false,
      disabled: false,
    },
    secondNationality: {
      name: 'secondNationality',
      title: 'Second Nationality',
      type: FormInputTypes.chips,
      placeholder: 'Second Nationality',
      value: !isEmpty(values.secondNationality)
        ? values.secondNationality
        : null,
      multiple: false,
      hidden: false,
      disabled: false,
      selectOptions: lovs.nationalities,
    },
  };
};
