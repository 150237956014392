import { gql } from '@apollo/client';

export function getSystemCurrencies() {
  return gql`
    query getSystemCurrencies {
      Core {
        queries {
          getSystemCurrencies {
            core_SystemCurrency_Id
            core_SystemCurrency_BasicCurrency {
              Code
              Title
            }
            core_SystemCurrency_SecondaryCurrency {
              Code
              Title
            }
          }
        }
      }
    }
  `;
}
