import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import { enumListAsRecordObject } from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getSubLineList.items.forEach(
    (obj: IGenericSublinesTableData, i: number) => {
      const lineName =
        obj.planConfigManagement_Subline_LineID?.views?.PlanConfigManagement_all
          ?.properties?.Name;
      list[i] = {
        planConfigManagement_Subline_Id: obj.planConfigManagement_Subline_Id,
        planConfigManagement_Subline_Name:
          obj.planConfigManagement_Subline_Name,
        planConfigManagement_Subline_LineID: lineName,
        planConfigManagement_Subline_ExternalCode:
          obj.planConfigManagement_Subline_ExternalCode,
        planConfigManagement_Subline_NameArabic:
          obj.planConfigManagement_Subline_NameArabic,
        planConfigManagement_Subline_createdOn:
          obj.planConfigManagement_Subline_createdOn,
        planConfigManagement_Subline_modifiedOn:
          obj.planConfigManagement_Subline_modifiedOn,
        planConfigManagement_Subline_Status: capitalizeFirstLetter(
          obj.planConfigManagement_Subline_Status?.replaceAll('_', ' ')
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data.PlanConfigManagement.queries.getSubLineList?.paging?.pageSize,
    pageNumber:
      data.PlanConfigManagement.queries.getSubLineList?.paging?.pageNumber,
    totalCount:
      data.PlanConfigManagement.queries.getSubLineList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    line: {},
    status: {},
  };

  data?.PlanConfigManagement?.queries?.allLines?.forEach(
    (obj: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lovs.line[obj.planConfigManagement_Line_Id] =
        obj.planConfigManagement_Line_Name;
    }
  );

  lovs.status = enumListAsRecordObject(
    data?.PlanConfigManagement_SublineStatuses?.enumValues
  );

  return lovs;
}
