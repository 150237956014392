import React, { useMemo } from 'react';
import { IDynamicDiplayView } from '../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../components/widgets/dynamic-display/DynamicWidget';

interface IMaxCreditLimitDetailsWidgetProps {
  data: Record<string, any>;
  actions?: JSX.Element;
}

const MaxCreditLimitDetailsWidget: React.FC<
  IMaxCreditLimitDetailsWidgetProps
> = ({ data, actions }) => {
  const view = useMemo(() => {
    const dynamicView: IDynamicDiplayView = {
      name: 'Personal Information',
      isCollapsible: true,
      sections: [
        {
          name: 'maximumCreditLimit',
          title: 'Max Credit Limit',
          showActionButtons: true,
          properties: {
            relatedCompany: {
              multiline: false,
              value: data?.maxCreditLimitAllowedCurrencyTitle,
              name: 'maxCreditLimitAllowedCurrency',
              title: 'Max Credit Limit Allowed (Currency)',
            },
            type: {
              multiline: false,
              value: data?.maxCreditLimitAllowedShow,
              name: 'maxCreditLimitAllowed',
              title: 'Max Credit Limit Allowed',
            },
          },
        },
      ],
    };

    return dynamicView;
  }, [data]);

  return <DynamicWidget hasFourFields={true} view={view} actions={actions} />;
};

export default MaxCreditLimitDetailsWidget;
