// TODO Refactor this component to support enabling and disabling formatting
import { makeStyles } from 'tss-react/mui';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import EnhancedIcon from '../EnhancedIcon';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { isEmpty } from '../../utils/validationUtils';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { IEnhancedFormInputBaseProps } from '.';
import { NumericFormat } from 'react-number-format';

export interface IEnhancedCurrencyInputProps
  extends IEnhancedFormInputBaseProps,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {
  name: string;
  title: string;
  description?: string;
  error?: string;
  material?: boolean;
  maxDecimalPercision?: number;
  value: string | number;
  inline?: boolean;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currencyTitle?: string;
  currencyIcon?: string;
  inputClass?: string;
  wrapperClass?: string;
  hidden?: boolean;
  useCurrencyText?: boolean;
  currencyText?: string;
  currencyTextColor?: string;
}

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: 13,
    lineHeight: '15px',
    height: 35,
    padding: '0 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.5)!important',
      opacity: `0.5 !important`,
      textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
    },
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.20)',
      fontStyle: 'italic',
    },
    fontFamily: contentFontFamilyRegular,
    '&:focus': {
      outline: 'none',
    },
  },
  // TODO make style common using JSS
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontFamily: contentFontFamilyRegular,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
  },
  currencyIcon: {
    display: 'inline-block !important',
    height: '10px',
    minWidth: '10px',
    minHeight: '12px',
    top: '10px!important',
    fontFamily: contentFontFamilyRegular,
  },
  formCurrencyIcon: {
    position: 'absolute',
    bottom: '0',
    left: '7px',
    height: '11px',
    width: '15px',
    marginTop: '2px',
    fontFamily: contentFontFamilyRegular,
  },
  currencyInput: {
    paddingLeft: '25px',
    fontFamily: contentFontFamilyRegular,
  },
  currencyInputText: {
    paddingLeft: '35px',
    fontFamily: contentFontFamilyRegular,
  },
  inputError: {
    borderColor: MAIN_ONE_THEME.palette.error.main,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
    fontFamily: contentFontFamilyRegular,
  },
  inlineContainer: {
    position: 'relative',
    '& $formCurrencyIcon': {
      height: '10px',
      width: '10px',
      display: 'block',
    },
    fontFamily: contentFontFamilyRegular,
  },
  inputWrapper: {
    height: '88.6px',
  },
  currencyText: {
    position: 'absolute',
    fontSize: '12px',
    fontFamily: contentFontFamilyRegular,
    top: '50%',
    transform: 'translateY(-50%)',
    left: '4px',
  },
}));

const EnhancedCurrencyInput: React.FC<IEnhancedCurrencyInputProps> = ({
  inline,
  className,
  inputClass,
  error,
  name,
  title,
  description,
  value,
  style,
  placeholder,
  disabled,
  maxDecimalPercision = 2,
  currencyTitle,
  currencyIcon,
  material,
  onChange,
  onFocus,
  onBlur,
  wrapperClass,
  customStyles,
  step,
  hidden = false,
  useCurrencyText = false,
  currencyText = '',
  currencyTextColor = 'inherit',
}) => {
  const { classes, cx } = useStyles();
  const showCurrency = (currencyIcon && currencyTitle) || useCurrencyText;

  // const formatValue = useCallback(() => {
  //   if (typeof value === 'number') {
  //     return valueCommaSeparated(value.toString());
  //   } else {
  //     return valueCommaSeparated(value);
  //   }
  // }, [value]);

  // const limitNumber = useCallback(
  //   (targetValue: string) => {
  //     let newValue = removeNumberDelimeter(targetValue);
  //     if (
  //       Number(maxDecimalPercision) >= 0 &&
  //       isValidNumber(maxDecimalPercision)
  //     ) {
  //       newValue = reduceNumberOfDecimals(
  //         newValue,
  //         Number(maxDecimalPercision)
  //       );
  //     }
  //     return newValue;
  //   },
  //   [maxDecimalPercision]
  // );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const newValue = event.target.value.replaceAll(',', '');

      onChange({
        ...event,
        target: {
          ...event.target,
          value: newValue,
          name: event.target.name,
        },
      });
    },
    [onChange]
  );

  const renderInput = () => {
    const combinedClassName = cx(
      classes.input,
      inline ? className : inputClass,
      material
        ? (customStyles?.inputStyles && customStyles?.inputStyles) ||
            classes.materialInput
        : undefined,
      !material && showCurrency
        ? useCurrencyText
          ? classes.currencyInputText
          : classes.currencyInput
        : undefined,
      error ? classes.inputError : undefined
    );

    return (
      <>
        {useCurrencyText ? (
          <span
            className={classes.currencyText}
            style={{ color: currencyTextColor }}
          >
            {currencyText}
          </span>
        ) : (
          currencyIcon &&
          currencyTitle && (
            <EnhancedIcon
              className={clsx(classes.currencyIcon, {
                [classes.formCurrencyIcon]: !material && showCurrency,
              })}
              classes={{ image: classes.currencyIcon }}
              title={currencyTitle}
              iconUrl={currencyIcon}
              hidden={hidden}
            />
          )
        )}
        <NumericFormat
          className={combinedClassName}
          style={{
            width: showCurrency && material ? '88%' : '100%',
          }}
          thousandSeparator=","
          decimalSeparator="."
          decimalScale={maxDecimalPercision}
          title={disabled && !isEmpty(value) ? value.toString() : title}
          id={name}
          name={name}
          aria-invalid={!!error}
          aria-describedby={`errorMsg-${name}`}
          placeholder={placeholder}
          step={step}
          onChange={(event) => {
            handleChange(event);
          }}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          hidden={hidden}
          valueIsNumericString
        />
      </>
    );
  };

  return inline ? (
    <span className={classes.inlineContainer}>{renderInput()}</span>
  ) : (
    <EnhancedInputsWrapper
      title={title}
      description={description}
      error={error}
      name={name}
      className={clsx(classes.inputWrapper, className)}
      style={style}
      childrenClass={wrapperClass}
      customStyles={customStyles}
      hidden={hidden}
    >
      {/* Linter keeps on giving us an error if we don't explicitly test for
        currencyTitle and Icon */}
      <div style={{ position: 'relative' }}>{renderInput()}</div>
    </EnhancedInputsWrapper>
  );
};

export default EnhancedCurrencyInput;
