import { gql } from '@apollo/client';
export function getAccountingEnums() {
  return gql(/* GraphQL */ `
    query getAccountingImportEnums {
      Accounting {
        lookups {
          accountType {
            Id
            Title
          }
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
    }
  `);
}

export function postFile() {
  return gql(`
    mutation ImportAccounts ($SelectedCompany: String!, $ChartOfAccountsFile: [Accounting_AccountInput!]!) {
  accounting {
    actions {
      importAccount(SelectedCompany: $SelectedCompany, ChartOfAccounts: $ChartOfAccountsFile)
    }
  }
}
  `);
}

export function getFile() {
  return gql(`
    query getFile {
  Accounting {
    queries{
      Templates {
        accounting_AccountTemplate_Id,
        accounting_AccountTemplate_ChartOfAccountsTemplate{
          id,
          location,
          path,
          fileName,
          length,
          contentType
        }
      }
    }
  }
}
  `);
}
