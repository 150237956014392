import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import PaymentVoucherFormPopup from './PaymentVoucherFormPopup';
import { IAddVoucherPopup } from '../../shared/types';
import { loadValuesQuery, getPaymentDetailsQuery } from '../../shared/queries';

const PaymentVoucherPopup: React.FC<IAddVoucherPopup> = ({
  onSuccess,
  onClose,
  isEdit = false,
  currentPaymentId,
}) => {
  const [loading, setLoading] = useState(true);
  const businessPartnerId = useParams()?.id;
  const { data: values } = useQuery(loadValuesQuery(), {
    variables: { id: businessPartnerId },
  });
  const { data: paymentDetails } = useQuery(getPaymentDetailsQuery(), {
    variables: { paymentId: currentPaymentId },
    skip: !currentPaymentId,
    onCompleted: () => {
      setLoading(false);
    },
  });

  return (
    <PaymentVoucherFormPopup
      onSuccess={onSuccess}
      onClose={onClose}
      isEdit={isEdit}
      currentPaymentId={currentPaymentId}
      detailsLoading={loading}
      data={{
        values: values,
        paymentDetails: paymentDetails?.Accounting?.queries?.GetPaymentDetails,
      }}
    />
  );
};

export default PaymentVoucherPopup;
