import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: Record<string, IPolicyNumberRangeTableData> = {};

  data.Insurance.queries.listPolicyNumberRange.items.forEach(
    (obj: IPolicyNumberRangeTableData, i: number) => {
      let reached = '';
      if (
        obj.insurance_PolicyNumberRange_RangeStatus.toLowerCase() == 'consumed'
      ) {
        reached = obj.insurance_PolicyNumberRange_RangeTo;
      } else if (
        obj.insurance_PolicyNumberRange_RangeStatus.toLowerCase() == 'active'
      ) {
        reached = obj.insurance_PolicyNumberRange_PolicyNumberReached;
      } else {
        reached = '';
      }

      list[i] = {
        insurance_PolicyNumberRange_Id: obj.insurance_PolicyNumberRange_Id,
        insurance_PolicyNumberRange_RangeFrom:
          obj.insurance_PolicyNumberRange_RangeFrom,
        insurance_PolicyNumberRange_RangeTo:
          obj.insurance_PolicyNumberRange_RangeTo,
        insurance_PolicyNumberRange_PolicyNumberReached: reached,
        insurance_PolicyNumberRange_RangeStatus: capitalizeFirstLetter(
          obj.insurance_PolicyNumberRange_RangeStatus
        ),
        insurance_PolicyNumberRange_createdOn:
          obj.insurance_PolicyNumberRange_createdOn,

        insurance_PolicyNumberRange_modifiedOn:
          obj.insurance_PolicyNumberRange_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Insurance.queries.listPolicyNumberRange?.paging?.pageSize,
    pageNumber:
      data.Insurance.queries.listPolicyNumberRange?.paging?.pageNumber,
    totalCount:
      data.Insurance.queries.listPolicyNumberRange?.paging?.totalCount,
  };
}
