import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const getHeaders = (
  navigate: (v: string) => void
): Record<string, EnhancedTableHeader> => ({
  code: {
    name: 'code',
    title: 'Code',
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload) => {
      navigate('/configuration/providers/' + payload.columns.id);
    },
  },
  name: {
    name: 'name',
    title: 'Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload) => {
      navigate('/configuration/providers/' + payload.columns.id);
    },
  },
  type: {
    name: 'type',
    title: 'Type',
    type: EnhancedTableHeaderType.Text,
  },
  email: {
    name: 'email',
    title: 'Email',
    type: EnhancedTableHeaderType.Text,
  },
  phoneNumber: {
    name: 'phoneNumber',
    title: 'Phone Number',
    type: EnhancedTableHeaderType.PhoneNumber,
  },
  status: {
    name: 'status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'modifiedOn',
    title: 'Modified on',
    type: EnhancedTableHeaderType.Date,
  },
});

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        type: {
          name: 'type',
          title: 'Type',
          type: FormInputTypes.chips,
          value: filterValues?.type || [],
          placeholder: 'Type',
          triggerUpdate: true,
          selectOptions: { ...lovs.type },
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: 'Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        isCompany: {
          name: 'isCompany',
          title: 'Is Company',
          type: FormInputTypes.chips,
          value: filterValues?.isCompany || [],
          placeholder: 'Is Company',
          triggerUpdate: true,
          selectOptions: { ...lovs.isCompany },
        },
        isLocal: {
          name: 'isLocal',
          title: 'Is Local',
          type: FormInputTypes.chips,
          value: filterValues?.isLocal || [],
          placeholder: 'Is Local',
          triggerUpdate: true,
          selectOptions: { ...lovs.isLocal },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
        effectiveDate: {
          name: 'effectiveDate',
          title: 'Effective Date',
          type: FormInputTypes.daterange,
          value: filterValues.effectiveDate || [],
          placeholder: 'Effective Date',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
