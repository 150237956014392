export function LookupToList(data: any) {
  const claimCoverEnums: Record<string, Record<string, string>> = {};
  const policiesCovers: Record<string, string> = {};
  const lines: Record<string, string> = {};

  data.PlanConfigManagement.queries.allPoliciesCovers.forEach(
    (element: {
      planConfigManagement_PolicyCover_Id: string;
      planConfigManagement_PolicyCover_Name: string;
    }) => {
      policiesCovers[element.planConfigManagement_PolicyCover_Id] =
        element.planConfigManagement_PolicyCover_Name;
    }
  );

  claimCoverEnums['policiesCovers'] = policiesCovers;

  data.PlanConfigManagement.queries.allLines.forEach(
    (element: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lines[element.planConfigManagement_Line_Id] =
        element.planConfigManagement_Line_Name;
    }
  );

  claimCoverEnums['lines'] = lines;

  return claimCoverEnums;
}

export function graphqlEntityToClaimCoverInfo(
  data: any,
  lineId: string,
  lineName: string
): IClaimCoverInfo {
  return {
    claimCoverId: '',
    lineId: lineId,
    lineName: lineId,
    policyCover:
      data?.PlanConfigManagement?.entities?.claimCover?.views
        ?.PlanConfigManagement_all?.properties?.PolicyCoverID?.id,
    claimCoverName:
      data?.PlanConfigManagement?.entities?.claimCover?.views
        ?.PlanConfigManagement_all?.properties?.Name,
    claimCoverExternalCode:
      data?.PlanConfigManagement?.entities?.claimCover?.views
        ?.PlanConfigManagement_all?.properties?.ExternalCode,
    arabicName:
      data?.PlanConfigManagement?.entities?.claimCover?.views
        ?.PlanConfigManagement_all?.properties?.NameArabic,
    claimCoverPrintingOrder:
      data?.PlanConfigManagement?.entities?.claimCover?.views
        ?.PlanConfigManagement_all?.properties?.PrintingOrder,
    claimCoverDescription:
      data?.PlanConfigManagement?.entities?.claimCover?.views
        ?.PlanConfigManagement_all?.properties?.ClaimCoverDescription,
  };
}
