import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Production.queries.getPolicyList.items.forEach(
    (obj: IGenericPoliciesTableData, i: number) => {
      const subline =
        obj.production_Policy_SublineID?.views?.PlanConfigManagement_all
          ?.properties;
      const line =
        obj.production_Policy_LineID?.views?.PlanConfigManagement_all
          ?.properties;
      const plan =
        obj.production_Policy_PlanID?.views?.PlanConfigManagement_all
          ?.properties;
      const insured =
        obj.production_Policy_InsuredID?.views?.Production_all?.properties;

      list[i] = {
        id: obj.production_Policy_Id,
        policyNumber: obj.production_Policy_PolicyNumber,
        name: obj.production_Policy_PolicyName,
        line: `${line.ExternalCode} - ${line.Name}`,
        subline: `${subline.ExternalCode} - ${subline.Name}`,
        plan: `${plan.ExternalCode} - ${plan.Name}`,
        insured: insured?.FullName,
        status: capitalizeFirstLetter(
          obj.production_Policy_Status?.replaceAll('_', ' ')
        ),
        statusReason: obj?.production_Policy_StatusReason?.Title,
        statusReasonTitle: obj?.production_Policy_StatusReason?.Title,
        effectiveDate: obj.production_Policy_PolicyEffectiveDate,
        issueDate: obj.production_Policy_PolicyIssueDate,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Production.queries.getPolicyList?.paging?.pageSize,
    pageNumber: data.Production.queries.getPolicyList?.paging?.pageNumber,
    totalCount: data.Production.queries.getPolicyList?.paging?.totalCount,
  };
}

export function dataToLovs(
  data: any,
  linesData: any,
  filterV: Record<string, any>
): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    line: {},
    subline: {},
    plan: {},
    businessPartner: {},
    insured: {},
    status: {},
    statusReason: {},
  };

  data?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_BusinessPartnerID: {
        id: string;
        views: {
          SalesforceManagement_all: {
            properties: {
              PersonID: {
                views: {
                  SalesforceManagement_all: {
                    properties: {
                      FullName: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      lovs.businessPartner[
        obj?.salesforceManagement_AssignedBPPlan_BusinessPartnerID?.id
      ] =
        obj?.salesforceManagement_AssignedBPPlan_BusinessPartnerID?.views?.SalesforceManagement_all?.properties?.PersonID?.views?.SalesforceManagement_all?.properties?.FullName;
    }
  );

  linesData?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_LineID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              Id: string;
              Name: string;
              ExternalCode: string;
            };
          };
        };
      };
    }) => {
      lovs.line[
        obj?.salesforceManagement_AssignedBPPlan_LineID?.views?.PlanConfigManagement_all?.properties?.Id
      ] =
        `${obj?.salesforceManagement_AssignedBPPlan_LineID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj?.salesforceManagement_AssignedBPPlan_LineID?.views?.PlanConfigManagement_all?.properties?.Name}`;
    }
  );

  data?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_PlanID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              SublineID: {
                id: string;
                views: {
                  PlanConfigManagement_all: {
                    properties: {
                      Name: string;
                      ExternalCode: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      lovs.subline[
        obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.SublineID?.id
      ] =
        `${obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.SublineID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.SublineID?.views?.PlanConfigManagement_all?.properties?.Name}`;
    }
  );

  data?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_PlanID: {
        id: string;
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
              ExternalCode: string;
              SublineID: {
                id: string;
                views: {
                  PlanConfigManagement_all: {
                    properties: {
                      Name: string;
                      ExternalCode: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      if (!filterV?.subline || filterV?.subline?.length === 0) {
        if (
          lovs.subline[
            obj?.salesforceManagement_AssignedBPPlan_PlanID?.views
              ?.PlanConfigManagement_all?.properties?.SublineID?.id
          ]
        ) {
          lovs.plan[obj?.salesforceManagement_AssignedBPPlan_PlanID?.id] =
            `${obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.Name}`;
        }
      } else {
        if (
          filterV?.subline?.includes(
            obj?.salesforceManagement_AssignedBPPlan_PlanID?.views
              ?.PlanConfigManagement_all?.properties?.SublineID?.id
          )
        ) {
          lovs.plan[obj?.salesforceManagement_AssignedBPPlan_PlanID?.id] =
            `${obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.Name}`;
        }
      }
    }
  );

  data?.SalesforceManagement?.queries?.AllActivePersons?.forEach(
    (obj: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      lovs.insured[obj.salesforceManagement_Person_Id] =
        `${obj.salesforceManagement_Person_PersonCode} - ${obj.salesforceManagement_Person_FullName}`;
    }
  );

  lovs.status = enumListAsRecordObject(
    data?.Production_PolicyStatuses?.enumValues
  );

  lovs.statusReason = lookupListAsRecordObject(
    data?.Production?.lookups?.policyStatusReasons
  );

  return lovs;
}
