import React, { useCallback } from 'react';
import clsx from 'clsx';
import {
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  lighten,
} from '@mui/material';
import { EnhancedTableHeader } from '../../components/enhanced-table';
import { ITableRowSettings } from '../../components/widgets/listing-widget';
import { makeStyles } from 'tss-react/mui';
import { getRowConditions } from '../../utils/table-utils';
import Error from '@mui/icons-material/Error';
import EnhancedCheckbox from '../../components/EnhancedCheckbox';
import EnhancedTableBodyCell from '../../components/enhanced-table/EnhancedTableBodyCell';

interface IPaymentWidgetTableBodyProps {
  emptyRows: number;
  handleClick(name: string, checked: boolean): void;
  handleUpdate?(rowKey: number, property: string, value: any): void;
  handleCellValueChanged?(rowKey: number, property: string, value: any): void;
  headers: Record<string, EnhancedTableHeader>;
  rowSettings?: ITableRowSettings;
  dynamicRowMessage?: (row: IEnhancedRow) => string[];
  isSelected: (key: string) => boolean;
  rows: IEnhancedRow[];
  currencySymbol?: string;
  dateFormat?: string;
  disableSelection?: boolean;
  phoneNumberPattern: string;
  forceShowSelectColumn?: boolean;
}

export interface IEnhancedRow {
  key: string;
  columns: Record<string, string>;
  index: number;
}

const useStyles = makeStyles()((theme) => ({
  bodyRoot: {
    '& tr:nth-of-type(odd)': {
      backgroundColor: '#F5F8FA',
    },
  },
  rowRoot: {
    '&.Mui-selected': {
      backgroundColor: lighten('#07295A', 0.85),
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: lighten('#07295A', 0.85),
    },
  },
  cellRoot: {
    color: theme.palette.primary.main,
    fontSize: '11px',
    lineHeight: '12px',
    height: 34,
    border: 'none',
    padding: '4px 2px',
  },
  checkboxCellRoot: {
    width: 14,
    padding: '0 14px 0 8px',
    border: 'none',
    height: 34,
    position: 'relative',
  },
  checkboxCellRootWithMessage: {
    padding: '0 20px 0 8px',
  },
  noDataMessage: {
    textAlign: 'center',
    color: '#C7CCD6',
  },
  noDataCell: {
    color: theme.palette.primary.main,
    border: 'none',
    height: '34px',
    fontSize: '14px',
    lineHeight: '12px',
  },
  errorIconStyle: {
    height: '15px',
    width: '15px',
    color: 'red',
    position: 'absolute',
    top: '10px',
    right: '8px',
    cursor: 'pointer',
  },
  paddedCellRoot: {
    padding: '0px 6px 0px 20px',
  },
}));

const PaymentWidgetTableBody: React.FC<IPaymentWidgetTableBodyProps> = ({
  emptyRows,
  handleClick,
  headers,
  isSelected,
  rows,
  currencySymbol,
  dateFormat,
  rowSettings,
  handleUpdate,
  handleCellValueChanged,
  disableSelection,
  dynamicRowMessage,
  forceShowSelectColumn,
}) => {
  const { classes } = useStyles();

  const checkIfRowHasMessage = useCallback(
    (row: IEnhancedRow) => {
      if (dynamicRowMessage) {
        const dynamcMessages = dynamicRowMessage(row);
        if (dynamcMessages && dynamcMessages.length > 0) {
          return true;
        }
      }

      if (
        isSelected(row.key) &&
        Number(row.columns.OutstandingAmount) <
          Number(row.columns.CollectedAmount)
      ) {
        return true;
      }
      if (
        !isSelected(row.key) ||
        !rowSettings ||
        !rowSettings.conditions ||
        rowSettings.conditions.length === 0
      ) {
        return false;
      }
      return getRowConditions(rowSettings, row);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, rowSettings]
  );

  const renderRow = (row: IEnhancedRow) => {
    let message = '';
    if (dynamicRowMessage) {
      const dynamcMessages = dynamicRowMessage(row);
      if (dynamcMessages && dynamcMessages.length > 0) {
        message = dynamcMessages[0];
      }
    }

    if (!message) {
      if (rowSettings && checkIfRowHasMessage(row)) {
        message = rowSettings.iconTooltip;
      }
    }
    if (message) {
      return (
        <Tooltip title={message}>
          <span>
            <Error className={classes.errorIconStyle} />
          </span>
        </Tooltip>
      );
    }

    return <></>;
  };

  return (
    <TableBody classes={{ root: classes.bodyRoot }}>
      {rows.length > 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.key);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.key}
              selected={isItemSelected}
              classes={{ root: classes.rowRoot }}
            >
              <TableCell
                padding="none"
                classes={{
                  root: clsx(
                    classes.checkboxCellRoot,
                    classes.checkboxCellRootWithMessage
                  ),
                }}
              >
                {!disableSelection && (
                  <EnhancedCheckbox
                    checked={isItemSelected}
                    name={row.key}
                    onChange={handleClick}
                    checkboxOnly
                  />
                )}
                {renderRow(row)}
              </TableCell>
              {Object.values(headers).map((header, headerIndex) => {
                return (
                  <EnhancedTableBodyCell
                    selected={isItemSelected}
                    key={header.name + labelId}
                    row={row}
                    header={header}
                    currencySymbol={currencySymbol}
                    dateFormat={dateFormat}
                    handleUpdate={handleUpdate}
                    handleCellValueChanged={handleCellValueChanged}
                    className={clsx({
                      [classes.paddedCellRoot]:
                        !(!disableSelection || forceShowSelectColumn) &&
                        !headerIndex,
                    })}
                  />
                );
              })}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell
            rowSpan={2}
            colSpan={
              Object.keys(headers).length > 0
                ? Object.keys(headers).length + 1
                : 1
            }
            align="center"
            classes={{ root: classes.noDataCell }}
          >
            No data
          </TableCell>
        </TableRow>
      )}
      {emptyRows > 0 && <TableRow style={{ height: 34 * emptyRows }} />}
    </TableBody>
  );
};

export default PaymentWidgetTableBody;
