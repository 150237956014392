import React, { useEffect, useState } from 'react';
import { IInsuredDetails, IInsuredDetailsPageProps } from '.';
import { useLazyQuery } from '@apollo/client';
import {
  // getNonSelectedPlanCover,
  getInsuredDetailsInfo,
  getPolicyList,
} from './queries';
import { useNavigate, useParams } from 'react-router-dom';

import InsuredDetailsEntityInfo from './InsuredDetailsEntityInfo';
import { GetInsuredData, dataToLovs, mapToListingData } from './utils';
import { InsuredDetailsWidget } from './InsuredDetailsWidget';
import { getHeaders } from './content';
import _ from 'lodash';
import { IListingData } from '../../../../models/listing';
import { ITableOrder, TableSortOrder } from '../../../../utils/table-utils';
import { gridWidthToPercentage } from '../../../../utils/grid-utils';
import EnhancedTable from '../../../../components/enhanced-table/EnhancedTable';
import {
  capitalizeFirstCharacter,
  capitalizeFirstLetter,
} from '../../../../utils/formatting-utils';
import WidgetSection from '../../../../components/common/WidgetSection';
import Loader from '../../../../components/Loader';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';

const InsuredDetailsPage: React.FC<IInsuredDetailsPageProps> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const entityId = params.id;
  const navigate = useNavigate();
  const headers = getHeaders(navigate);

  const [data, setData] = useState<IInsuredDetails>();
  const [booted, setBooted] = useState<boolean>(false);
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [tableOrders, setTableOrders] = useState<Record<string, ITableOrder>>({
    policy: {
      orderBy: '',
      orderDirection: 'asc',
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 2,
    totalCount: 0,
  });

  useEffect(() => {
    initialize();
  }, []);

  const [getProposalDetailsLazy] = useLazyQuery(getInsuredDetailsInfo());

  const [policyListResultQuery] = useLazyQuery(getPolicyList());

  const loadPolicyList = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders
  ) => {
    const result = await policyListResultQuery({
      variables: {
        selectedInsuredIDs: params.id,
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        Attribute: capitalizeFirstCharacter(
          tableOrders?.policy?.orderBy || 'Production_Policy_PolicyName'
        ),
        Descending: orders?.policy?.orderDirection == 'asc' ? false : true,
      },
      errorPolicy: 'all',
    });

    return result;
  };

  const initialize = async () => {
    setLoading(true);
    const result: { data: any } = await getProposalDetailsLazy({
      variables: { id: entityId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    const summaryData = GetInsuredData(result.data);

    const policyData = await loadPolicyList(0, 10);
    const mappedPolicyList = await mapToListingData(policyData.data);

    setLovs(dataToLovs(result.data));

    setTableData({ ...mappedPolicyList, pageNumber: 0 });

    setBooted(true);
    setData(summaryData);
    setLoading(false);
  };

  function handleSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.policy.orderBy = orderBy;
    newOrder.policy.orderDirection = order as any;
    handlePageChange(tableData.pageNumber, tableData.pageSize, newOrder);

    setTableOrders(newOrder);
  }

  async function handlePageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders
  ) {
    if (!loading) {
      setLoading(true);
      const result = await loadPolicyList(page, pageSize, orders);
      const mappedPolicyList = await mapToListingData(result.data);
      setTableData({
        ...mappedPolicyList,
        pageNumber: page,
        pageSize: pageSize,
      });

      setLoading(false);
    }
  }

  const renderLeftSection = () => {
    if (data) {
      return <InsuredDetailsEntityInfo data={data} />;
    }

    return <></>;
  };

  const renderMainChildren = () => {
    if (data) {
      // const isClosed = status === "closedwon" || "closedlost";

      return (
        <>
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              {/* <div style={{ width: `${gridWidthToPercentage(8)}%` }}>
                <EnhancedStepper
                  activeStep={getProposalStatus(steps, data.Status)}
                  steps={steps}
                  buttonTitle="Calculate & Save"
                  buttonOnClick={() => {}}
                  className={classes.stepper}
                />
              </div> */}
            </div>
            <div
              style={{
                marginTop: '18px',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  width: `${gridWidthToPercentage(12)}%`,
                  padding: '0px 1em 1em 0',
                }}
              >
                <InsuredDetailsWidget data={data} lovs={lovs} />
                <>
                  <WidgetSection style={{ margin: '-23px 0 0 0' }}>
                    <form>
                      <div
                        style={{
                          marginTop: '20px',
                          padding: '-23px 0 0 0 !important',
                        }}
                      >
                        <EnhancedTable
                          title={capitalizeFirstLetter('Policies')}
                          inlineTitle={capitalizeFirstLetter('Policies')}
                          name="count"
                          entityName={'Policies'}
                          entityIdColumnName={
                            'salesforceManagement_PersonAddress_Id'
                          }
                          showCellFullData={true}
                          isTitlePascalCase={true}
                          orderByAscendingByDefault
                          defaultOrderByColumn="name"
                          inline={true}
                          data={tableData}
                          headers={headers}
                          handlePageChange={(page: number) =>
                            handlePageChange(page, tableData.pageSize)
                          }
                          handleRowsPerPageChange={(pageSize: number) =>
                            handlePageChange(0, pageSize)
                          }
                          handleSort={(orderBy: string, order: string) =>
                            handleSortChange(orderBy, order)
                          }
                          currentPage={tableData.pageNumber}
                          hideToolbar={false}
                          usePagination
                          disableSelection={false}
                          actions={[]}
                          loader={loading}
                          showTablePagination={true}
                          ordering={
                            tableOrders.policy.orderDirection as TableSortOrder
                          }
                          orderingBy={tableOrders.policy.orderBy}
                        />
                      </div>
                    </form>
                  </WidgetSection>
                </>
              </div>
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };

  return !booted ? (
    <Loader />
  ) : (
    <>
      <StaticLayout
        name={'Insured Details'}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
    </>
  );
};

export default InsuredDetailsPage;
